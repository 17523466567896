import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const shellActions = createActionGroup({
  source: "Application Shell",
  events: {
    "Request Started": emptyProps(),
    "Request Completed": emptyProps(),
    /**
     * Action, die verwendet werden kann, wenn zb ein Effect keinen weiteren Effekt auslösen soll.
     * Kann anstelle von `of(EMPTY)` verwendet werden, was einen Effekt auch leichter testbar macht.
     */
    noop: props<{
      sourceAction: string;
    }>(),
  },
});
