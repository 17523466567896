import { Component, DestroyRef, OnInit, computed, input } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Store } from "@ngrx/store";
import { map, switchMap } from "rxjs";
import { Icons } from "src/app/models/icons";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "../../planungsobjekt-window.model";
import { PlanungsobjektWindowVerlinkungenFormService } from "../planungsobjekt-window-verlinkung-form.service";
import { onDemandBeziehungFormActions } from "./on-demand-beziehung-form.actions";

/**
 * Komponente zum Erstellen von OnDemand PlanungsobjektBeziehungen.
 */
@Component({
  selector: "app-on-demand-beziehung-form",
  templateUrl: "./on-demand-beziehung-form.component.html",
  styleUrls: ["./on-demand-beziehung-form.component.scss"],
  providers: [PlanungsobjektWindowVerlinkungenFormService],
})
export class OnDemandBeziehungFormComponent implements OnInit {
  input = input.required<PlanungsobjektWindowInputWithPlanungsobjekt>();
  readOnly = input.required<boolean>();

  linear = computed(() => {
    const input = this.input();
    return input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ
      ? input.planungsobjekt
      : undefined;
  });

  onDemandForm$ = this.formService.form$;
  toggleForm$ = this.formService.toggleForm$;

  protected SLIDER_LEFT = false;
  protected SLIDER_RIGHT = true;

  Icons = Icons;

  // Technische Felder. Nicht Teil der Form-Validierung
  onDemandPlanungsobjektPlanen = false;
  onMerkliste = false;

  constructor(
    private store: Store,
    private formService: PlanungsobjektWindowVerlinkungenFormService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    if (!this.input) {
      throw new Error("Window input wird benötigt");
    }
    this.store.dispatch(
      onDemandBeziehungFormActions.getInitialOnDemandBeziehungFormData({
        planungsobjektLinearId: this.input().planungsobjektId,
      }),
    );
    this.formService.form$
      .pipe(
        switchMap((form) => form.valueChanges.pipe(map(() => form.getRawValue()))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((onDemandFormValue) => {
        this.store.dispatch(
          onDemandBeziehungFormActions.setLinearOnDemandBeziehungForm({
            onDemandFormValue,
          }),
        );
      });

    this.formService.toggleForm$
      .pipe(
        switchMap((form) => form.valueChanges.pipe(map(() => form.getRawValue()))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((toggleForm) => {
        this.store.dispatch(
          onDemandBeziehungFormActions.setLinearOnDemandToggleForm({
            toggleFormValue: {
              wunschOnlineAbVsRelationalZuLinearToggle:
                toggleForm.wunschOnlineAbVsRelationalZuLinearToggle ?? false,
              wunschOnlineBisVsVerweildauerToggle:
                toggleForm.wunschOnlineBisVsVerweildauerToggle ?? false,
            },
          }),
        );
      });

    this.formService.initialize(this.input());
  }

  onSave() {
    this.store.dispatch(onDemandBeziehungFormActions.saveLinearOnDemandBeziehung());
  }
}
