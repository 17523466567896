import { Component, EventEmitter, Input, Output, TrackByFunction } from "@angular/core";
import { Icons } from "src/app/models/icons";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { PlanungsobjektWindowUseCase } from "../../planungsobjekt-window.model";

/**
 * Komponente zur Anzeige der Beziehungen eines Planungsobjekts (Reihenfolgen, Abhängigkeiten, LinearOnDemand-Beziehung).
 */
@Component({
  selector: "app-planungsobjekt-beziehungen",
  templateUrl: "./planungsobjekt-beziehungen.component.html",
  styleUrls: ["./planungsobjekt-beziehungen.component.scss"],
})
export class PlanungsobjektBeziehungenComponent {
  @Input() reihenfolgen: BeziehungDto[];
  @Input() abhaengigkeiten: BeziehungDto[];
  @Input() onDemandBeziehungen: BeziehungDto[];
  @Input({ required: true }) linearOnDemandBeziehungOnDemandObjekt:
    | PlanungsobjektOnDemandDto
    | undefined;
  @Input() hasPlanungsobjektOnDemandId = false;
  @Input() showOnDemandBeziehungForm: boolean;
  @Input({ required: true }) usecase: PlanungsobjektWindowUseCase;
  @Input({ required: true }) readOnly: boolean;

  @Output() toggleOnDemandBeziehungForm = new EventEmitter<void>();
  @Output() updateOnDemandBeziehung = new EventEmitter<void>();
  @Output() deleteBeziehung = new EventEmitter<BeziehungDto>();

  Icons = Icons;
  PlanungsobjektWindowUseCase = PlanungsobjektWindowUseCase;

  trackByBeziehungsId: TrackByFunction<BeziehungDto> = (_, item) => item.planungsobjektBeziehungId;
}
