import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map, Observable } from "rxjs";
import { RechercheApiService } from "../api/recherche/recherche.api.service";
import {
  RechercheGridFormattingOptionsVM,
  RechercheSearchQueryVM,
} from "../core/stores/recherche/recherche.model";
import { RechercheMapper } from "../models/mapper/recherche.mapper";
import { SearchResultsDto } from "../models/openapi/model/search-results-dto";

@Injectable({
  providedIn: "root",
})
export class RechercheService {
  constructor(
    private rechercheApiService: RechercheApiService,
    private location: Location,
    private router: Router,
  ) {}

  search$(query: RechercheSearchQueryVM): Observable<SearchResultsDto> {
    const body = RechercheMapper.mapToSearchQuery(query);
    return this.rechercheApiService.search$(body);
  }

  searchChildren$(childrenIds: string[]): Observable<SearchResultsDto> {
    return this.rechercheApiService.searchChildren$({ ids: childrenIds }).pipe(
      map(({ planungsobjekte, idsWithVarianten }) => {
        return { planungsobjekte, idsWithVarianten };
      }),
    );
  }

  // URL Parameter auf Basis der Suche aktualisieren
  navigateBySearch(
    query: RechercheSearchQueryVM,
    { shownColumns }: RechercheGridFormattingOptionsVM,
  ) {
    const searchQuery = RechercheMapper.mapToSearchQuery(query);

    void this.router.navigate(["recherche"], {
      queryParams: {
        ...searchQuery,
        shownColumns,
      },
    });
  }

  navigateByFormattingOptionsChanged({ shownColumns }: RechercheGridFormattingOptionsVM) {
    void this.router.navigate(["recherche"], {
      queryParams: {
        shownColumns,
      },
      queryParamsHandling: "merge",
    });
  }

  /**
   * Aktualisiere die QueryParams in der URL ohne ein Navigationsevent auszulösen oder
   * einen neuen Eintrag in der Browser-History hinzuzufügen.
   * Relevant, wenn wir die URL aktualisieren wollen, ohne dass die Komponente neu geladen wird wegen einer Navigation.
   * @param param0
   */
  updateFormattingOptionsInUrl({ shownColumns }: RechercheGridFormattingOptionsVM) {
    const url = this.router
      .createUrlTree([], {
        queryParams: {
          shownColumns: shownColumns.join(","),
        },
        queryParamsHandling: "merge",
      })
      .toString();

    this.location.go(url);
  }
}
