import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { FrontendSettings } from "./app/models/openapi/model/frontend-settings";
import { PublitFrontendSettings } from "./environments/environment";

// Lade Konfiguration über anonymen API Endpunkt
fetch("/api/configuration/frontend")
  .then((res) => {
    if (res.ok) return res.json();
    throw new Error(
      `Konnte keine Konfiguration vom Backend abfragen. Status: ${res.status}. Message: ${res.statusText}`,
    );
  })
  .then((config: FrontendSettings) => {
    if (config.production) {
      enableProdMode();
    }
    const settings = new PublitFrontendSettings(config);
    return platformBrowserDynamic([
      {
        provide: PublitFrontendSettings,
        useValue: settings,
      },
    ]).bootstrapModule(AppModule);
  })
  .catch((err) => console.error(err));
