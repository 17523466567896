import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PublitFrontendSettings } from "src/environments/environment";

interface VersionInformation {
  version: string;
  commitsAhead: number;
  latestSHA: string;
}

@Injectable({
  providedIn: "root",
})
export class VersionApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getVersionInformation$(): Observable<VersionInformation> {
    const uri: string = this.apiUrl + "version";
    return this.http.get<VersionInformation>(uri);
  }
}
