import { Component } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { RedaktionOptionsFiltered } from "src/app/models/enums/redaktion";
import { Icons } from "src/app/models/icons";
import {
  PlanungsobjektFarbenOptions,
  planungsobjektFarbgebungMap,
} from "src/app/models/viewmodels";

@Component({
  selector: "app-planungsinhalte",
  templateUrl: "./planungsinhalte.component.html",
  styleUrls: ["./planungsinhalte.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class PlanungsinhalteComponent {
  readonly form = this.fgd.form;

  readonly RedaktionOptionsFiltered = RedaktionOptionsFiltered;
  readonly planungsobjektFarbgebungMap = planungsobjektFarbgebungMap;
  readonly PlanungsobjektFarbenOptions = PlanungsobjektFarbenOptions;
  readonly Icons = Icons;

  constructor(private fgd: FormGroupDirective) {}
}
