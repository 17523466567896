import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { SVGIcon } from "@progress/kendo-svg-icons";
import { map } from "rxjs";
import { GetitWindowInput } from "src/app/core/stores/getit-window/getit-model";
import { getitWindowActions } from "src/app/core/stores/getit-window/getit-window.actions";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import { Icons } from "src/app/models/icons";
import { GetitProduktStatus } from "src/app/models/openapi/model/getit-produkt-status";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektWindowFacade } from "../../../planungsobjekt-window.facade";
import { PlanungsobjektWindowUseCase } from "../../../planungsobjekt-window.model";

@Component({
  selector: "app-produktinformationen-getit-link",
  templateUrl: "./produktinformationen-getit-link.component.html",
  styleUrl: "./produktinformationen-getit-link.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProduktinformationenGetitLinkComponent {
  private readonly store = inject(Store);
  public readonly facade = inject(PlanungsobjektWindowFacade);

  PlanungsobjektWindowUseCase = PlanungsobjektWindowUseCase;

  @Input({ required: true }) planungsobjekt: PlanungsobjektDto | null;
  @Input({ required: true }) usecase: PlanungsobjektWindowUseCase;

  readonly Icons = Icons;
  public readonly syncButtonTooltip$ = this.facade.isGetItDisabled$.pipe(
    map((disabled) =>
      disabled
        ? "Speichern Sie Ihre Änderungen, um die Synchronisation zu ermöglichen."
        : "Produktinformationen aus get.it synchronisieren",
    ),
  );

  protected readonly isPlanungFormDirty$ = this.store.select(
    planungsobjektWindowFeature.selectIsPlanungFormDirty,
  );

  getIconFromStatus(status: GetitProduktStatus | null): SVGIcon {
    if (!status) {
      return Icons.addIcon;
    }

    switch (status) {
      case GetitProduktStatus.ABGENOMMEN:
        return Icons.signatureIcon;
      case GetitProduktStatus.AUSGESTRAHLT:
        return Icons.toggleFullScreenModeIcon;
      case GetitProduktStatus.GENEHMIGT_PRODUKTIONSBEWILLIGUNG:
        return Icons.trackChangesAcceptAllIcon;
      case GetitProduktStatus.GENEHMIGT_STOFFZULASSUNG:
        return Icons.trackChangesAcceptIcon;
      case GetitProduktStatus.OFFEN:
        return Icons.kpiStatusOpenIcon;
      case GetitProduktStatus.IN_PRODUKTION:
        return Icons.filmIcon;
      default:
        return Icons.addIcon;
    }
  }

  getTooltipFromStatus(status: GetitProduktStatus | null): string {
    if (!status) {
      return "unbekannter Status";
    }

    switch (status) {
      case GetitProduktStatus.ABGENOMMEN:
        return "abgenommen";
      case GetitProduktStatus.AUSGESTRAHLT:
        return "veröffentlicht";
      case GetitProduktStatus.GENEHMIGT_PRODUKTIONSBEWILLIGUNG:
        return "genehmigt (Produktionsbewilligung)";
      case GetitProduktStatus.GENEHMIGT_STOFFZULASSUNG:
        return "genehmigt (Stoffzulassung)";
      case GetitProduktStatus.OFFEN:
        return "offen";
      case GetitProduktStatus.IN_PRODUKTION:
        return "in Produktion";
      default:
        return "unbekannter Status";
    }
  }

  mitGetitVerknuepfen() {
    const input: GetitWindowInput = {
      type: "GetitVerknuepfung",
      planungsobjektId: this.planungsobjekt?.id,
    };
    this.store.dispatch(getitWindowActions.openGetitWindow({ windowInputs: input }));
  }

  getitEntknuepfen() {
    this.store.dispatch(
      planungsobjektWindowActions.openConfirmGetitVerknuepfungAufhebenDialog({
        planungsobjekt: this.planungsobjekt!,
      }),
    );
  }
}
