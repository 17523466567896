import { Inject, Pipe, PipeTransform } from "@angular/core";
import { PureAbility } from "@casl/ability";
import { Observable } from "rxjs";
import { AppAbility } from "src/app/casl/app-ability";

// Code aus https://github.com/stalniy/casl/blob/master/packages/casl-angular/src/pipes.ts übernommen und mit eigenen Types ergänzt

@Pipe({ name: "able", pure: false })
export class AblePipe implements PipeTransform {
  private _ability: AppAbility;

  constructor(@Inject(PureAbility) ability: AppAbility) {
    this._ability = ability;
  }

  transform(...args: Parameters<AppAbility["can"]>): boolean {
    return this._ability.can(...args);
  }
}

@Pipe({ name: "ablePure" })
export class AblePurePipe implements PipeTransform {
  private _ability: AppAbility;

  constructor(@Inject(PureAbility) ability: AppAbility) {
    this._ability = ability;
  }

  // TODO: use computed signals https://github.com/angular/angular/issues/47553
  transform(...args: Parameters<AppAbility["can"]>): Observable<boolean> {
    return new Observable((s) => {
      const emit = () => s.next(this._ability.can(...args));
      emit();
      return this._ability.on("updated", emit);
    });
  }
}
