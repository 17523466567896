import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, switchMap, tap } from "rxjs";
import { Aktion } from "src/app/models/enums/aktion";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { KonkurrenzprogrammService } from "src/app/services/konkurrenzprogramm.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { ansichtActions } from "../ansicht/ansicht.actions";
import { ekWindowActions } from "../ek-window/ek-window.actions";
import { multiansichtActions } from "../multiansicht/multiansicht.actions";
import { multiAnsichtFeature } from "../multiansicht/multiansicht.reducer";
import { konkurrenzprogrammActions } from "./konkurrenzprogramm.actions";

@Injectable()
export class KonkurrenzprogrammEffects {
  handleKonkurrenzprogrammWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowActions.handleKonkurrenzprogrammWindowResult),
      map(({ result }) => {
        const konkurrenzprogrammBody = result.returnValue.value as KonkurrenzprogrammDto;
        switch (result.returnValue.action) {
          case Aktion.ERSTELLE_KONKURRENZPROGRAMM:
            return konkurrenzprogrammActions.createKonkurrenzprogramm({
              konkurrenzprogramm: konkurrenzprogrammBody,
            });

          case Aktion.BEARBEITE_KONKURRENZPROGRAMM:
            return konkurrenzprogrammActions.updateKonkurrenzprogramm({
              konkurrenzprogramm: konkurrenzprogrammBody,
            });

          case Aktion.ENTFERNE_KONKURRENZPROGRAMM:
            return konkurrenzprogrammActions.deleteKonkurrenzprogramm({
              konkurrenzprogramm: konkurrenzprogrammBody,
            });

          case Aktion.KOPIERE_KONKURRENZPROGRAMM:
            return konkurrenzprogrammActions.copyKonkurrenzprogramm({
              konkurrenzprogramm: konkurrenzprogrammBody,
              copyPattern: result.returnValue.copyPattern,
            });

          case Aktion.KONVERTIERE_EVENT:
            return konkurrenzprogrammActions.convertEventToKonkurrenzprogramm({
              konkurrenzprogramm: konkurrenzprogrammBody,
              convertedGUID: result.returnValue.convertedGUID,
            });
        }
        return ekWindowActions.closeWindow();
      }),
    );
  });

  loadKonkurrenzprogrammeForEntityUpdates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ansichtActions.updateAllEntitiesInAnsicht),
      concatLatestFrom(() => this.store.select(multiAnsichtFeature.selectMultiAnsichtViewModel)),
      switchMap(([, multiAnsichtViewModel]) => {
        return this.service
          .getKonkurrenzprogrammeByAnsichten$({
            ansichtenIds: multiAnsichtViewModel.ansichtViewModels
              .filter((avm) => avm.visible)
              .map((amv) => amv.ansichtViewModel.id),
          })
          .pipe(
            map((konkurrenzprogramme) =>
              konkurrenzprogrammActions.setAllKonkurrenzprogramme({
                konkurrenzprogramme,
              }),
            ),
          );
      }),
    );
  });

  loadKonkurrenzprogrammeByAnsichten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.setMultiansichtSuccess),
      switchMap(({ multiAnsichtViewModel }) =>
        this.service
          .getKonkurrenzprogrammeByAnsichten$({
            ansichtenIds: multiAnsichtViewModel.ansichtViewModels
              .filter((avm) => avm.visible)
              .map((amv) => amv.ansichtViewModel.id),
          })
          .pipe(
            map((konkurrenzprogramme) =>
              konkurrenzprogrammActions.loadKonkurrenzprogrammeByAnsichtenSuccess({
                konkurrenzprogramme,
              }),
            ),
          ),
      ),
    );
  });

  loadKonkurrenzprogrammeByYear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ansichtActions.loadEventsKonkurrenzprogrammeForYear),
      switchMap(({ year }) => this.service.getKonkurrenzprogrammeByYear$(year)),
      map((konkurrenzprogramme) =>
        konkurrenzprogrammActions.loadKonkurrenzprogrammeByYearSuccess({ konkurrenzprogramme }),
      ),
    );
  });

  loadKonkurrenzprogrammeByAnsicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.updateAnsichtInMultiansichtSuccess),
      switchMap(({ additionalAnsichtViewModelId }) =>
        this.service.getKonkurrenzprogrammeByAnsicht$(additionalAnsichtViewModelId),
      ),
      map((konkurrenzprogramme) =>
        konkurrenzprogrammActions.loadKonkurrenzprogrammeByAnsichtenSuccess({
          konkurrenzprogramme,
        }),
      ),
    );
  });

  createKonkurrenzprogramm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(konkurrenzprogrammActions.createKonkurrenzprogramm),
      switchMap(({ konkurrenzprogramm }) =>
        this.service.createKonkurrenzprogramm$(konkurrenzprogramm),
      ),
      map((konkurrenzprogramm) =>
        konkurrenzprogrammActions.createKonkurrenzprogrammSuccess({ konkurrenzprogramm }),
      ),
      tap(({ konkurrenzprogramm }) =>
        this.notificationService.showActionNotification(
          Aktion.ERSTELLE_KONKURRENZPROGRAMM,
          NotificationStyle.SUCCESS,
          konkurrenzprogramm.titel,
        ),
      ),
    );
  });

  updateKonkurrenzprogramm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(konkurrenzprogrammActions.updateKonkurrenzprogramm),
      switchMap(({ konkurrenzprogramm }) =>
        this.service.updateKonkurrenzprogramm$(konkurrenzprogramm),
      ),
      map((konkurrenzprogramm) =>
        konkurrenzprogrammActions.updateKonkurrenzprogrammSuccess({ konkurrenzprogramm }),
      ),
      tap(({ konkurrenzprogramm }) =>
        this.notificationService.showActionNotification(
          Aktion.BEARBEITE_KONKURRENZPROGRAMM,
          NotificationStyle.SUCCESS,
          konkurrenzprogramm.titel,
        ),
      ),
    );
  });

  deleteKonkurrenzprogramm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(konkurrenzprogrammActions.deleteKonkurrenzprogramm),
      switchMap(({ konkurrenzprogramm }) =>
        this.service.deleteKonkurrenzprogramm$(konkurrenzprogramm).pipe(
          map(() =>
            konkurrenzprogrammActions.deleteKonkurrenzprogrammSuccess({
              konkurrenzprogrammId: konkurrenzprogramm.id,
            }),
          ),
          tap(() =>
            this.notificationService.showActionNotification(
              Aktion.ENTFERNE_KONKURRENZPROGRAMM,
              NotificationStyle.SUCCESS,
              konkurrenzprogramm.titel,
            ),
          ),
        ),
      ),
    );
  });

  copyKonkurrenzprogramm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(konkurrenzprogrammActions.copyKonkurrenzprogramm),
      switchMap(({ konkurrenzprogramm, copyPattern }) =>
        this.service.copyKonkurrenzprogramm$(konkurrenzprogramm, copyPattern),
      ),
      map((konkurrenzprogramm) =>
        konkurrenzprogrammActions.copyKonkurrenzprogrammSuccess({ konkurrenzprogramm }),
      ),
      tap(({ konkurrenzprogramm }) =>
        this.notificationService.showActionNotification(
          Aktion.KOPIERE_KONKURRENZPROGRAMM,
          NotificationStyle.SUCCESS,
          konkurrenzprogramm.titel,
        ),
      ),
    );
  });

  convertEventToKonkurrenzprogramm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(konkurrenzprogrammActions.convertEventToKonkurrenzprogramm),
      switchMap(({ konkurrenzprogramm, convertedGUID }) =>
        this.service.convertEvent(konkurrenzprogramm, convertedGUID).pipe(
          map((konkurrenzprogramm) =>
            konkurrenzprogrammActions.convertEventToKonkurrenzprogrammSuccess({
              konkurrenzprogramm,
              convertedGUID,
            }),
          ),
        ),
      ),
      tap(({ konkurrenzprogramm }) =>
        this.notificationService.showActionNotification(
          Aktion.KONVERTIERE_EVENT,
          NotificationStyle.SUCCESS,
          konkurrenzprogramm.titel,
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: KonkurrenzprogrammService,
    private store: Store,
    private notificationService: CustomNotificationService,
  ) {}
}
