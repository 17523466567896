import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { MengengeruestApiService } from "../api/mengengeruest/mengengeruest.api.service";
import { mengengeruestActions } from "../core/stores/mengengeruest/mengengeruest.actions";
import { AktionEnum } from "../models/enums/aktion";
import { CreateMengengeruesteintragCommand } from "../models/openapi/model/create-mengengeruesteintrag-command";
import { MengengeruestGroupDto } from "../models/openapi/model/mengengeruest-group-dto";
import { MengengeruesteintragDto } from "../models/openapi/model/mengengeruesteintrag-dto";
import { UpdateMengengeruesteintragCommand } from "../models/openapi/model/update-mengengeruesteintrag-command";
import { AnsichtViewModel } from "../models/viewmodels/ansicht-viewmodel";
import { CustomWindowRef } from "../shared/dialogs/dialog.model";
import {
  MengengeruestWindowComponent,
  MengengeruestWindowComponentResult,
  MengengeruestWindowInput,
  MengengeruestWindowUseCase,
} from "../shared/windows/mengengeruest-window/mengengeruest-window.component";
import {
  MengengeruestEintragWindowComponent,
  MengengeruestEintragWindowCreateResult,
  MengengeruestEintragWindowInput,
  MengengeruestEintragWindowUpdateResult,
} from "../shared/windows/mengengeruesteintrag-window/mengengeruesteintrag-window.component";
import { BigWindow, MediumWindow } from "../shared/windows/window.templates";
import { CustomDialogService } from "./custom-dialog.service";
import { CustomWindowService } from "./custom-window.service";

@Injectable({
  providedIn: "root",
})
export class MengengeruestService {
  constructor(
    private mengengeruestApiService: MengengeruestApiService,
    private customWindowService: CustomWindowService,
    private customDialogService: CustomDialogService,
    private store: Store,
  ) {}

  createMengengeruesteintrag$(
    body: CreateMengengeruesteintragCommand,
  ): Observable<MengengeruesteintragDto> {
    return this.mengengeruestApiService.createMengengeruesteintrag$(body);
  }

  updateMengengeruesteintrag$(
    body: UpdateMengengeruesteintragCommand,
  ): Observable<MengengeruesteintragDto> {
    return this.mengengeruestApiService.updateMengengeruesteintrag$(body);
  }

  deleteMengengeruesteintrag$(id: string): Observable<void> {
    return this.mengengeruestApiService.deleteMengengeruestEintrag$(id);
  }

  getMengengeruesteintraegeBySendeplatzId$(
    ansichtId: string,
    sendeplatzId: string,
  ): Observable<MengengeruestGroupDto> {
    return this.mengengeruestApiService.getMengengeruesteintraegeBySendeplatzId$(
      ansichtId,
      sendeplatzId,
    );
  }

  getMengengeruesteintraegeByAnsichtId$(ansichtId: string): Observable<MengengeruestGroupDto[]> {
    return this.mengengeruestApiService.getMengengeruesteintraegeByAnsichtId$(ansichtId);
  }

  openMengengeruesteWindow(
    ansichtViewModel: AnsichtViewModel,
  ): CustomWindowRef<MengengeruestWindowComponent, MengengeruestWindowComponentResult> {
    this.store.dispatch(
      mengengeruestActions.addMengengeruestEintraegeByAnsichtId({ ansichtId: ansichtViewModel.id }),
    );

    const windowRef = this.customWindowService.open<
      MengengeruestWindowComponent,
      MengengeruestWindowComponentResult
    >({
      content: MengengeruestWindowComponent,
      title: `Mengengerüst - ${ansichtViewModel.titel} - ${ansichtViewModel.year}`,
      ...BigWindow(),
      // Verhindert das Schließen der Mengengerüsttabelle, falls der MG-Eintrag Dialog geöffnet ist
      preventClose: (
        _,
        window?: CustomWindowRef<MengengeruestWindowComponent, MengengeruestWindowComponentResult>,
      ) => !!window && window.content.instance.windowCount > 1,
    });

    const data: MengengeruestWindowInput = {
      ansichtViewModel,
      type: MengengeruestWindowUseCase.MAIN_WINDOW,
      openWindows$: this.customWindowService.openWindows$,
    };

    windowRef.content.instance.input = data;
    return windowRef;
  }

  openMengengeruestForPlanungsobjektZuweisungWindow(
    input: MengengeruestWindowInput,
  ): CustomWindowRef<MengengeruestWindowComponent, MengengeruestWindowComponentResult> {
    this.store.dispatch(
      mengengeruestActions.addMengengeruestEintraegeByAnsichtId({
        ansichtId: input.ansichtViewModel.id,
      }),
    );

    const windowRef = this.customWindowService.open<
      MengengeruestWindowComponent,
      MengengeruestWindowComponentResult
    >({
      content: MengengeruestWindowComponent,
      title: `Mengengerüst - ${input.ansichtViewModel.titel} - ${input.ansichtViewModel.year}`,
      ...BigWindow(),
    });

    windowRef.content.instance.input = input;
    return windowRef;
  }

  openMengeneruestZuweisungValidationWindow() {
    return this.customDialogService.openMengeneruestZuweisungValidationDialog();
  }

  openCreateMengengeruesteintragWindow(
    ansichtId: string,
    schemaplatzId: string,
    presetBeginnzeit: string | null,
  ) {
    const windowRef = this.customWindowService.open<
      MengengeruestEintragWindowComponent,
      MengengeruestEintragWindowCreateResult
    >({
      content: MengengeruestEintragWindowComponent,
      title: "Mengengerüsteintrag erstellen",
      ...MediumWindow(),
      htmlAttributes: {
        "data-testid": "mengengeruesteintrag-window",
      },
    });

    const data: MengengeruestEintragWindowInput = {
      action: AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG,
      ansichtId,
      schemaplatzId,
      presetBeginnzeit,
    };

    windowRef.content.instance.data = data;
    return windowRef;
  }

  openEditMengengeruesteintragWindow(mengengeruesteintrag: MengengeruesteintragDto) {
    const windowRef = this.customWindowService.open<
      MengengeruestEintragWindowComponent,
      MengengeruestEintragWindowUpdateResult
    >({
      content: MengengeruestEintragWindowComponent,
      title: "Mengengerüsteintrag bearbeiten",
      ...MediumWindow(),
      htmlAttributes: {
        "data-testid": "mengengeruesteintrag-window",
      },
    });

    const data: MengengeruestEintragWindowInput = {
      action: AktionEnum.BEARBEITE_MENGENGERUESTEINTRAG,
      mengengeruesteintrag,
    };

    windowRef.content.instance.data = data;
    return windowRef;
  }
}
