import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { map, switchMap } from "rxjs";
import { SendeplatzService } from "src/app/services/sendeplatz.service";
import { sendeplatzActions } from "./sendeplatz.actions";
import { sendeplatzWindowActions } from "./sendeplatz.window.actions";

@Injectable()
export class SendeplatzWindowEffects {
  private readonly actions$ = inject(Actions);

  private readonly sendeplatzService = inject(SendeplatzService);

  openSendeplatzWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendeplatzWindowActions.openSendeplatzWindow),
      switchMap(({ sendeplatz }) => this.sendeplatzService.openSendeplatzWindow(sendeplatz).result),
      map((windowResult) =>
        windowResult instanceof WindowCloseResult
          ? sendeplatzWindowActions.windowClosed()
          : sendeplatzActions.updateSendeplatz({ sendeplatz: windowResult.sendeplatz }),
      ),
    );
  });
}
