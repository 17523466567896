import { Injectable } from "@angular/core";
import { DialogService } from "@progress/kendo-angular-dialog";
import { Observable } from "rxjs";
import { MerklisteApiService } from "../api/merkliste/merkliste.api.service";
import { PlanungsobjektLinearApiService } from "../api/planungsobjekt/planungsobjekt-linear.api.service";
import { GUID } from "../models";
import { MerklisteDto } from "../models/openapi/model/merkliste-dto";
import { MovePlanungsobjektLinearToVorgemerktCommand } from "../models/openapi/model/move-planungsobjekt-linear-to-vorgemerkt-command";
import { OnDemandFilter } from "../models/openapi/model/on-demand-filter";
import { PlanungsobjektLinearDto } from "../models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektLinearDtoResultDto } from "../models/openapi/model/planungsobjekt-linear-dto-result-dto";
import { PlanungsobjektLinearVorgemerktErstellenCommand } from "../models/openapi/model/planungsobjekt-linear-vorgemerkt-erstellen-command";
import { PlanungsobjektLinearVorgemerktKopierenCommand } from "../models/openapi/model/planungsobjekt-linear-vorgemerkt-kopieren-command";
import { CustomDialogRef, CustomWindowRef } from "../shared/dialogs/dialog.model";
import {
  CustomDialogAction,
  StandardActionDialogComponent,
} from "../shared/dialogs/standard-action-dialog/standard-action-dialog.component";
import {
  PlanungsobjektOnDemandZuMerklisteWindowComponent,
  PlanungsobjektOnDemandZuMerklisteWindowResult,
} from "../shared/windows/planungsobjekt-ondemand-zu-merkliste-window/planungsobjekt-ondemand-zu-merkliste-window.component";
import { MediumWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";

@Injectable({
  providedIn: "root",
})
export class MerklisteService {
  constructor(
    private api: MerklisteApiService,
    private planungsobjektLinearApi: PlanungsobjektLinearApiService,
    private dialogService: DialogService,
    private customWindowService: CustomWindowService,
  ) {}

  openVerschiebePlanungsobjektLinearZuVorgemerktDialog(titel: string, merklisten: MerklisteDto[]) {
    const possibleMerklisten = merklisten.map(
      (merkliste): CustomDialogAction => ({
        ...merkliste,
        text: merkliste.name || `Merkliste ${merkliste.nummer}`,
        cssClass: "u-max-w-48 u-d-block k-text-ellipsis",
        testId: `move-to-merkliste-${merkliste.nummer}`,
      }),
    );
    const dialogRef: CustomDialogRef<StandardActionDialogComponent, MerklisteDto> =
      this.dialogService.open({
        content: StandardActionDialogComponent,
        cssClass: "window-in-foreground",
        htmlAttributes: {
          "data-testid": "move-planungsobjekt-to-merkliste-dialog",
        },
      });
    const content = dialogRef.content;
    content.instance.actions = [...possibleMerklisten, { text: "Abbrechen", isCancelAction: true }];
    content.instance.title = `Auf welche Merkliste soll "${titel}" verschoben werden?`;
    return dialogRef;
  }

  verschiebePlanungsobjektLinearZuVorgemerkt$(
    planungsobjektId: GUID,
    merkliste: MerklisteDto,
  ): Observable<PlanungsobjektLinearDtoResultDto> {
    const command: MovePlanungsobjektLinearToVorgemerktCommand = {
      ansichtId: merkliste.ansichtId,
      nummer: merkliste.nummer,
      ausspielweg: merkliste.ausspielweg,
      planungsobjektId,
    };
    return this.planungsobjektLinearApi.movePlanungsobjektLinearToVorgemerkt$(command);
  }

  getMerklistenByAnsichtId$(ansichtId: string): Observable<MerklisteDto[]> {
    return this.api.getMerklistenByAnsichtId$(ansichtId);
  }

  planungsobjektLinearVorgemerktErstellen$(
    command: PlanungsobjektLinearVorgemerktErstellenCommand,
  ): Observable<PlanungsobjektLinearDto> {
    return this.planungsobjektLinearApi.planungsobjektLinearVorgemerktErstellen$(command);
  }

  planungsobjektLinearVorgemerktKopieren$(
    command: PlanungsobjektLinearVorgemerktKopierenCommand,
  ): Observable<PlanungsobjektLinearDto> {
    return this.planungsobjektLinearApi.planungsobjektLinearVorgemerktKopieren$(command);
  }

  openVerschiebePlanungsobjektOnDemandZuVorgemerktWindow$(
    planungsobjektOnDemandId: GUID,
    planungsobjektOnDemandTitel: string,
    quickFilterPreset: OnDemandFilter | undefined,
  ) {
    const windowRef: CustomWindowRef<
      PlanungsobjektOnDemandZuMerklisteWindowComponent,
      PlanungsobjektOnDemandZuMerklisteWindowResult
    > = this.customWindowService.open({
      content: PlanungsobjektOnDemandZuMerklisteWindowComponent,
      ...MediumWindow(),
      title: `Auf welche Merkliste soll "${planungsobjektOnDemandTitel}" verschoben werden?`,
    });

    windowRef.content.instance.data = { planungsobjektOnDemandId, quickFilterPreset };
    return windowRef;
  }
}
