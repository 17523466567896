import { AfterViewInit, ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { KanalRecord } from "src/app/models/enums/kanal";
import { Icons } from "src/app/models/icons";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { TitleShortenerPipe } from "src/app/shared/pipes/title-format.pipe";
import ListenansichtFacade from "./listenansicht.facade";

@Component({
  selector: "app-listenansicht",
  templateUrl: "./listenansicht.component.html",
  styleUrls: ["./listenansicht.component.scss"],
  providers: [TitleShortenerPipe, ListenansichtFacade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListenansichtComponent implements AfterViewInit {
  public readonly facade = inject(ListenansichtFacade);
  private readonly titleService = inject(Title);
  private readonly titleFormat = inject(TitleShortenerPipe);

  Icons = Icons;

  @Input() showNotizen: boolean;
  @Input() showKonkurrenzEvents: boolean;
  @Input() ansichtViewModel: AnsichtViewModel;

  ngAfterViewInit(): void {
    const titel = this.titleFormat.transform(this.ansichtViewModel.titel);
    this.titleService.setTitle(
      `${KanalRecord[this.ansichtViewModel.kanal].bedeutung} ${this.ansichtViewModel.year} - ${titel}`,
    );
  }
}
