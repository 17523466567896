import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { take } from "rxjs";
import { PharosService } from "src/app/api/pharos/pharos.service";
import { sendeplatzActions } from "src/app/core/stores/sendeplatz/sendeplatz.actions";
import { Icons } from "src/app/models/icons";
import { PrepareTransferToPharosQuery } from "src/app/models/openapi/model/prepare-transfer-to-pharos-query";
import {
  PharosTransferTableViewModel,
  TransferToPharosViewModel,
} from "src/app/models/viewmodels/pharos-viewmodel";
import { DurationService } from "src/app/services/duration.service";

export interface PharosWindowInput {
  prepareTransferToPharosQuery: PrepareTransferToPharosQuery;
  pharosContent: PharosTransferTableViewModel;
}

@Component({
  selector: "app-pharos-export-dialog",
  templateUrl: "./pharos-export-window.component.html",
  styleUrls: ["./pharos-export-window.component.scss"],
})
export class PharosExportWindowComponent implements OnInit {
  @Input() public data: PharosWindowInput;

  sendetitelDisabled = true;
  folgenNrInfoDisabled = true;
  dachzeileDisabled = true;

  formGroup = this.initializeForm();
  transferToPharos: TransferToPharosViewModel;

  Icons = Icons;

  constructor(
    public durationService: DurationService,
    public windowRef: WindowRef,
    private pharosService: PharosService,
    private formBuilder: FormBuilder,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.initializeForm();
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
  }

  private initializeForm() {
    const folgenNrInfo =
      this.data?.pharosContent?.fromPublit.folgenNrInfo !== ""
        ? this.data?.pharosContent?.fromPublit.folgenNrInfo
        : this.data?.pharosContent?.fromPharos.folgenNrInfo;

    const laenge =
      this.durationService.isOtherDurationStringLessOrEqualSecondsAwayFromDurationString(
        this.data?.pharosContent?.fromPharos?.laenge,
        this.data?.pharosContent?.fromPublit?.laenge,
        5 * 60,
      )
        ? this.data?.pharosContent?.fromPharos?.laenge
        : this.data?.pharosContent?.fromPublit?.laenge;

    /**
     * Es wurde hier nicht das "disabled" Attribut gesetzt, da "disabled" Felder nicht validiert werden können
     * Man könnte "globale" Validatoren auf die FormGroup setzen
     */
    return this.formBuilder.group({
      planzeit: [this.data?.pharosContent?.fromPublit?.planzeit, [Validators.required]],
      laenge: [laenge, [Validators.required]],
      sendetitel: [
        this.data?.pharosContent?.fromPublit?.sendetitel,
        //TODO: { value: this.data?.pharosContent?.fromPublit?.sendetitel, disabled: this.sendetitelDisabled }
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(60),
          Validators.pattern(/[\S]/),
        ],
      ],
      redaktion: [
        this.data?.pharosContent?.fromPublit?.redaktion,
        [Validators.required, Validators.pattern(/[^NN]/)],
      ],
      folgenNrInfo: [folgenNrInfo, [Validators.maxLength(40)]],
      dachzeile: [this.data?.pharosContent?.fromPharos?.dachzeile, [Validators.maxLength(60)]],
    });
  }

  onClose(): void {
    this.windowRef.close();
  }

  onSave(): void {
    this.pharosService
      .updatePharosTransferTable$({
        sendeplatzKey: this.data.prepareTransferToPharosQuery.sendeplatzKey,
        primaereVorplanugsfassungId: this.data.pharosContent.fromPublit.primaereVorplanugsfassungId,
        sendungKey: this.data.pharosContent.fromPharos.sendungKey,
        planzeit: this.formGroup.value.planzeit,
        laenge: this.formGroup.value.laenge,
        sendetitel: this.formGroup.value.sendetitel,
        redaktion: this.formGroup.value.redaktion,
        folgenNrInfo: this.formGroup.value.folgenNrInfo,
        dachzeile: this.formGroup.value.dachzeile,
      })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.store.dispatch(
            sendeplatzActions.updateSendeplatzWithSendeplatzKey({
              sendeplatzKey: this.data.prepareTransferToPharosQuery.sendeplatzKey,
            }),
          );
        },
        error: (error: unknown) => {
          this.onClose();
          // damit wir noch zum Global Error Handler kommen und die Notification anzeigen
          throw error;
        },
      });
    this.onClose();
  }

  onRadioButtonClick(event: Event, value: string): void {
    this.formGroup.get((event.target as HTMLInputElement).name).patchValue(value);
  }

  onDachzeileClick(): void {
    const textInputValue = this.formGroup.get("dachzeile").value;
    const checkBoxValue = this.data.pharosContent.fromPharos.dachzeile;
    const element = document.getElementById("dachzeile-pharos") as HTMLInputElement;

    // CheckBox wurde gecheckt und anderer Wert in dem Textfeld als in Pharos
    if (element.checked && textInputValue !== checkBoxValue) {
      // Überschreiben
      this.formGroup.get("dachzeile").setValue(this.data.pharosContent?.fromPharos?.dachzeile);
    }
    // CheckBox wurde nicht gecheckt und gleicher Wert in dem Textfeld wie in Pharos
    else if (!element.checked && textInputValue === checkBoxValue) {
      // Inhalt aus Textfeld entfernen
      this.formGroup.get("dachzeile").setValue("");
    }
  }

  /**
   * Manuelles "disablen", da "disabled" Felder nicht validiert werden können
   * @see Kommentar oben
   */
  onSendetitelDisable(disable: boolean): void {
    this.sendetitelDisabled = disable;
  }

  onFolgenNrInfoDisable(disable: boolean): void {
    this.folgenNrInfoDisabled = disable;
  }

  onDachzeileDisable(disable: boolean): void {
    this.dachzeileDisabled = disable;
  }

  onDachzeileTextboxValueChange(): void {
    const textInputValue = this.formGroup.get("dachzeile").value;
    const checkBoxValue = this.data.pharosContent.fromPharos.dachzeile;
    const element = document.getElementById("dachzeile-pharos") as HTMLInputElement;

    if (textInputValue !== checkBoxValue) {
      element.checked = false;
    } else if (textInputValue === checkBoxValue) {
      element.checked = true;
    }
  }

  onSendetitelTextboxValueChange(textInputValue: string): void {
    this.onTextboxValueChange(
      textInputValue,
      "sendetitel",
      this.data.pharosContent.fromPublit.sendetitel,
      this.data.pharosContent.fromPharos.sendetitel,
    );
  }

  onFolgenNrInfoTextboxValueChange(textInputValue: string): void {
    this.onTextboxValueChange(
      textInputValue,
      "folgenNrInfo",
      this.data.pharosContent.fromPublit.folgenNrInfo,
      this.data.pharosContent.fromPharos.folgenNrInfo,
    );
  }

  /**
   * TODO: In FormGroup mit aufnehmen
   *
   * Hier werden HTMLInputElemente verwendet, da hier ganz genau eingestellt werden soll,
   * ob ein RadioButton ausgewählt ist oder nicht.
   * Normalerweise läuft das über das `value`-Attribut des Radiobutton.
   * Um ein RadioButton auszuwählen wird der entsprechende `value` gesetzt, des jweiligen RadioButtons gewählt.
   * Indeterminate wäre nur bei gleichen Werten möglich.
   */
  onTextboxValueChange(textInputValue: string, type: string, publit: string, pharos: string): void {
    const radioButton_publit = document.getElementById(type + "-publit") as HTMLInputElement;
    const radioButton_pharos = document.getElementById(type + "-pharos") as HTMLInputElement;

    // Indeterminate state (= Beide RadioButton sind "halb" ausgewählt)
    if (
      !(publit === textInputValue || pharos === textInputValue) ||
      (pharos === "" && publit === "")
    ) {
      radioButton_publit.checked = false;
      radioButton_pharos.checked = false;
    } else if (publit === textInputValue && textInputValue !== "") {
      radioButton_publit.checked = true;
      radioButton_pharos.checked = false;
    } else if (pharos === textInputValue && textInputValue !== "") {
      radioButton_publit.checked = false;
      radioButton_pharos.checked = true;
    }
  }
}
