import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { routerNavigatedAction } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { filter, map, switchMap } from "rxjs";
import { AnsichtenMapper } from "src/app/models/mapper/ansichten.mapper";
import { ansichtFeature } from "../ansicht/ansicht.reducer";
import { multiansichtActions } from "./multiansicht.actions";
import { multiAnsichtFeature } from "./multiansicht.reducer";

@Injectable()
export class MultiansichtEffects {
  setMultiAnsichtViewModel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter((payload) => payload.payload.routerState.url.includes("ansichtId")),
      switchMap((routerState) => {
        return this.store.select(ansichtFeature.selectAll).pipe(
          filter((allAnsichtenViewModel) => !!allAnsichtenViewModel.length),
          map((allAnsichtenViewModel) => {
            const selectedAnsichtViewModel = allAnsichtenViewModel.find(
              (vm) => vm.id === routerState.payload.routerState.root.queryParams.ansichtId,
            );
            if (!selectedAnsichtViewModel) throw new Error("Selected AnsichtViewModel not found");

            return multiansichtActions.setMultiansichtSuccess({
              multiAnsichtViewModel:
                this.ansichtenMapper.mapAnsichtViewModelToMultiAnsichtViewModel(
                  selectedAnsichtViewModel,
                  allAnsichtenViewModel,
                ),
            });
          }),
        );
      }),
    );
  });

  /**
   * Dieser Effekt wird ausgeführt, sobald ein Nutzer ein neues Jahr in der Ansicht lädt. Die jeweiligen Entitys
   * sind dann dafür verantwortlich auf die updateAnsichtInMultiansichtSuccess Action zu reagieren und die Daten aus
   * dem Backend zu laden.
   */
  updateAnsichtInMultiansichtViewModel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.updateAnsichtInMultiansicht),
      concatLatestFrom(() => this.store.select(multiAnsichtFeature.selectMultiAnsichtViewModel)),
      map(([{ year }, multiAnsichtViewModel]) => {
        if (!multiAnsichtViewModel) throw new Error("MultiAnsichtViewModel is not set");
        const additionalAnsichtViewModel = multiAnsichtViewModel.ansichtViewModels.find(
          (apv) => apv.ansichtViewModel.year === year,
        );
        if (!additionalAnsichtViewModel) throw new Error("AnsichtViewModel for year is not set");
        return multiansichtActions.updateAnsichtInMultiansichtSuccess({
          additionalAnsichtViewModelId: additionalAnsichtViewModel.ansichtViewModel.id,
          layout: multiAnsichtViewModel.layout,
        });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private ansichtenMapper: AnsichtenMapper,
  ) {}
}
