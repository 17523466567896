import { NotificationStyle } from "../openapi/model/notification-style";

const defaultNotifications = {
  erstellen: {
    Success: " wurde erfolgreich erstellt.",
    Error: " konnte nicht erstellt werden.",
  },
  bearbeiten: {
    Success: " wurde erfolgreich bearbeitet.",
    Error: " konnte nicht bearbeitet werden.",
  },
  entfernen: {
    Success: " wurde erfolgreich entfernt.",
    Error: " konnte nicht entfernt werden.",
  },
  kopieren: {
    Success: " wurde erfolgreich kopiert.",
    Error: " konnte nicht kopiert werden.",
  },
  konvertieren: {
    Success: " wurde erfolgreich konvertiert.",
    Error: " konnte nicht konvertiert werden.",
  },
};

export enum AktionResult {
  SAVE = "SAVE",
  CANCEL = "CANCEL",
  DELETE = "DELETE",
  COPY = "COPY",
}

export enum AktionEnum {
  DETAILS = "DETAILS",
  ONLINE_DETAILS = "ONLINE_DETAILS",
  ERSTELLE_EVENT = "ERSTELLE_EVENT",
  BEARBEITE_EVENT = "BEARBEITE_EVENT",
  ENTFERNE_EVENT = "ENTFERNE_EVENT",
  KOPIERE_EVENT = "KOPIERE_EVENT",
  KONVERTIERE_EVENT = "KONVERTIERE_EVENT",
  ERSTELLE_KONKURRENZPROGRAMM = "ERSTELLE_KONKURRENZPROGRAMM",
  BEARBEITE_KONKURRENZPROGRAMM = "BEARBEITE_KONKURRENZPROGRAMM",
  ENTFERNE_KONKURRENZPROGRAMM = "ENTFERNE_KONKURRENZPROGRAMM",
  KOPIERE_KONKURRENZPROGRAMM = "KOPIERE_KONKURRENZPROGRAMM",
  KONVERTIERE_KONKURRENZPROGRAMM = "KONVERTIERE_KONKURRENZPROGRAMM",
  ERSTELLE_PLANUNGSOBJEKT = "ERSTELLE_PLANUNGSOBJEKT",
  BEARBEITE_PLANUNGSOBJEKT = "BEARBEITE_PLANUNGSOBJEKT",
  GETIT_TRANSFER = "GETIT_TRANSFER",
  ENTFERNE_PLANUNGSOBJEKT = "ENTFERNE_PLANUNGSOBJEKT",
  KOPIERE_PLANUNGSOBJEKT = "KOPIERE_PLANUNGSOBJEKT",
  VERSCHIEBE_PLANUNGSOBJEKT = "VERSCHIEBE_PLANUNGSOBJEKT",
  ERSTELLE_PLANUNGSOBJEKTBEZIEHUNG = "ERSTELLE_PLANUNGSOBJEKTBEZIEHUNG",
  ENTFERNE_PLANUNGSOBJEKTBEZIEHUNG = "ENTFERNE_PLANUNGSOBJEKTBEZIEHUNG",
  ERSTELLE_VORSCHLAG = "ERSTELLE_VORSCHLAG",
  BEARBEITE_VORSCHLAG = "BEARBEITE_VORSCHLAG",
  ENTFERNE_VORSCHLAG = "ENTFERNE_VORSCHLAG",
  VORSCHLAG_ANNEHMEN_ERSETZEN = "VORSCHLAG_ANNEHMEN_ERSETZEN",
  VORSCHLAG_ANNEHMEN_ERGAENZEN = "VORSCHLAG_ANNEHMEN_ERGAENZEN",
  VORSCHLAG_ANNEHMEN_AUSWAHL = "VORSCHLAG_ANNEHMEN_AUSWAHL",
  ERSTELLE_MENGENGERUESTEINTRAG = "ERSTELLE_MENGENGERUESTEINTRAG",
  BEARBEITE_MENGENGERUESTEINTRAG = "BEARBEITE_MENGENGERUESTEINTRAG",
  ENTFERNE_MENGENGERUESTEINTRAG = "ENTFERNE_MENGENGERUESTEINTRAG",
  ABWEICHENDEN_MENGENGERUESTEINTRAG_ZUORDNEN = "ABWEICHENDEN_MENGENGERUESTEINTRAG_ZUORDNEN",
  PLANUNGSOBJEKT_ZU_MENGENGERUESTEINTRAG = "PLANUNGSOBJEKT_ZU_MENGENGERUESTEINTRAG",
  PLANUNGSOBJEKT_IN_SERIE_UMWANDELN = "PLANUNGSOBJEKT_IN_SERIE_UMWANDELN",
  PLANUNGSOBJEKT_VON_MENGENGERUESTEINTRAG_TRENNEN = "PLANUNGSOBJEKT_VON_MENGENGERUESTEINTRAG_TRENNEN",
  ENTFERNE_MENGENGERUESTEINTRAGSZUWEISUNG = "ENTFERNE_MENGENGERUESTEINTRAGSZUWEISUNG",
  PLANUNGSOBJEKT_AUF_MERKLISTE = "PLANUNGSOBJEKT_AUF_MERKLISTE",
  BEARBEITE_SENDEPLATZ = "BEARBEITE_SENDEPLATZ",
  KOPIE_HIER = "KOPIE_HIER",
  KOPIE_NAECHSTE_WOCHE = "KOPIE_NAECHSTE_WOCHE",
  NACH_PHAROS_UEBERTRAGEN = "NACH_PHAROS_UEBERTRAGEN",
  KOPIE_BENUTZERDEFINIERT = "KOPIE_BENUTZERDEFINIERT",
  SENDEPLATZ_DETAILS = "SENDEPLATZ_DETAILS",
  IN_VORSCHLAG_WANDELN = "IN_VORSCHLAG_WANDELN",
  VORSCHLAG_ANNEHMEN = "VORSCHLAG_ANNEHMEN",
  ONDEMAND_AUF_MERKLISTE = "ONDEMAND_AUF_MERKLISTE",
}

export class Aktion {
  static readonly ERSTELLE_EVENT = new Aktion(AktionEnum.ERSTELLE_EVENT, {
    entityType: "Event",
    notifications: {
      ...defaultNotifications.erstellen,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly BEARBEITE_EVENT = new Aktion(AktionEnum.BEARBEITE_EVENT, {
    entityType: "Event",
    notifications: {
      ...defaultNotifications.bearbeiten,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly ENTFERNE_EVENT = new Aktion(AktionEnum.ENTFERNE_EVENT, {
    entityType: "Event",
    notifications: {
      ...defaultNotifications.entfernen,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly KOPIERE_EVENT = new Aktion(AktionEnum.KOPIERE_EVENT, {
    entityType: "Event",
    notifications: {
      ...defaultNotifications.kopieren,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly KONVERTIERE_EVENT = new Aktion(AktionEnum.KONVERTIERE_EVENT, {
    entityType: "Konkurrenzprogramm",
    notifications: {
      ...defaultNotifications.konvertieren,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly ERSTELLE_KONKURRENZPROGRAMM = new Aktion(AktionEnum.ERSTELLE_KONKURRENZPROGRAMM, {
    entityType: "Konkurrenzprogramm",
    notifications: {
      ...defaultNotifications.erstellen,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly BEARBEITE_KONKURRENZPROGRAMM = new Aktion(
    AktionEnum.BEARBEITE_KONKURRENZPROGRAMM,
    {
      entityType: "Konkurrenzprogramm",
      notifications: {
        ...defaultNotifications.bearbeiten,
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly ENTFERNE_KONKURRENZPROGRAMM = new Aktion(AktionEnum.ENTFERNE_KONKURRENZPROGRAMM, {
    entityType: "Konkurrenzprogramm",
    notifications: {
      ...defaultNotifications.entfernen,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly KOPIERE_KONKURRENZPROGRAMM = new Aktion(AktionEnum.KOPIERE_KONKURRENZPROGRAMM, {
    entityType: "Konkurrenzprogramm",
    notifications: {
      ...defaultNotifications.kopieren,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly KONVERTIERE_KONKURRENZPROGRAMM = new Aktion(
    AktionEnum.KONVERTIERE_KONKURRENZPROGRAMM,
    {
      entityType: "Event",
      notifications: {
        ...defaultNotifications.konvertieren,
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly ERSTELLE_MENGENGERUESTEINTRAG = new Aktion(
    AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG,
    {
      entityType: "Mengengerüsteintrag",
      notifications: {
        ...defaultNotifications.erstellen,
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly BEARBEITE_MENGENGERUESTEINTRAG = new Aktion(
    AktionEnum.BEARBEITE_MENGENGERUESTEINTRAG,
    {
      entityType: "Mengengerüsteintrag",
      notifications: {
        ...defaultNotifications.bearbeiten,
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly ENTFERNE_MENGENGERUESTEINTRAG = new Aktion(
    AktionEnum.ENTFERNE_MENGENGERUESTEINTRAG,
    {
      entityType: "Mengengerüsteintrag",
      notifications: {
        ...defaultNotifications.entfernen,
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly ENTFERNE_MENGENGERUESTEINTRAGSZUWEISUNG = new Aktion(
    AktionEnum.ENTFERNE_MENGENGERUESTEINTRAGSZUWEISUNG,
    {
      entityType: "Mengengerüsteintragszuweisung",
      notifications: {
        Info: " wurde entfernt.",
      },
      style: NotificationStyle.INFO,
    },
  );

  static readonly PLANUNGSOBJEKT_AUF_MERKLISTE = new Aktion(
    AktionEnum.PLANUNGSOBJEKT_AUF_MERKLISTE,
    {
      entityType: "Programm",
      notifications: {
        Success: " wurde auf die Merkliste verschoben.",
        Error: " konnte nicht auf die Merkliste verschoben werden.",
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly PLANUNGSOBJEKT_ZU_MENGENGERUESTEINTRAG = new Aktion(
    AktionEnum.PLANUNGSOBJEKT_ZU_MENGENGERUESTEINTRAG,
    {
      entityType: "Programm",
      notifications: {
        Success: " wurde dem Mengengerüsteintrag erfolgreich zugewiesen.",
        Error: " konnte nicht dem Mengengerüsteintrag zugewiesen werden.",
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly PLANUNGSOBJEKT_IN_SERIE_UMWANDELN = new Aktion(
    AktionEnum.PLANUNGSOBJEKT_IN_SERIE_UMWANDELN,
    {
      entityType: "Serie",
      notifications: {
        Success: " in Serie umgewandelt.",
        Error: " konnte nicht in Serie umgewandelt werden.",
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly PLANUNGSOBJEKT_VON_MENGENGERUESTEINTRAG_TRENNEN = new Aktion(
    AktionEnum.PLANUNGSOBJEKT_VON_MENGENGERUESTEINTRAG_TRENNEN,
    {
      entityType: "Programm",
      notifications: {
        Success: " wurde vom Mengengerüsteintrag erfolgreich entfernt.",
        Error: " konnte nicht vom Mengengerüsteintrag entfernt werden.",
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly ERSTELLE_PLANUNGSOBJEKT = new Aktion(AktionEnum.ERSTELLE_PLANUNGSOBJEKT, {
    entityType: "Programm",
    notifications: {
      ...defaultNotifications.erstellen,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly BEARBEITE_PLANUNGSOBJEKT = new Aktion(AktionEnum.BEARBEITE_PLANUNGSOBJEKT, {
    entityType: "Programm",
    notifications: {
      ...defaultNotifications.bearbeiten,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly ERSETZE_VORSCHLAG = new Aktion(AktionEnum.VORSCHLAG_ANNEHMEN_ERSETZEN, {
    entityType: "Programm",
    notifications: {
      ...defaultNotifications.bearbeiten,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly ERGAENZE_VORSCHLAG = new Aktion(AktionEnum.VORSCHLAG_ANNEHMEN_ERGAENZEN, {
    entityType: "Programm",
    notifications: {
      ...defaultNotifications.erstellen,
    },
    style: NotificationStyle.SUCCESS,
  });

  // static readonly NACH_PHAROS_UEBERTRAGEN = new Aktion(AktionEnum.NACH_PHAROS_UEBERTRAGEN, {
  //   entityType: "Programm",
  //   notifications: {
  //     ...defaultNotifications.bearbeiten,
  //   },
  // });

  static readonly ENTFERNE_PLANUNGSOBJEKT = new Aktion(AktionEnum.ENTFERNE_PLANUNGSOBJEKT, {
    entityType: "Programm",
    notifications: {
      Success: " wurde erfolgreich ausgeplant.",
      Error: " konnte nicht ausgeplant werden.",
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly ERSTELLE_VORSCHLAG = new Aktion(AktionEnum.ERSTELLE_VORSCHLAG, {
    entityType: "Vorschlag",
    notifications: {
      ...defaultNotifications.erstellen,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly ONLINE_DETAILS = new Aktion(AktionEnum.ONLINE_DETAILS, {
    entityType: "Programm",
    notifications: {
      Error: " hat keine Online Planung.",
    },
    style: NotificationStyle.ERROR,
  });

  static readonly BEARBEITE_VORSCHLAG = new Aktion(AktionEnum.BEARBEITE_VORSCHLAG, {
    entityType: "Vorschlag",
    notifications: {
      ...defaultNotifications.bearbeiten,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly ENTFERNE_VORSCHLAG = new Aktion(AktionEnum.ENTFERNE_VORSCHLAG, {
    entityType: "Vorschlag",
    notifications: {
      ...defaultNotifications.entfernen,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly ENTFERNE_PLANUNGSOBJEKTBEZIEHUNG = new Aktion(
    AktionEnum.ENTFERNE_PLANUNGSOBJEKTBEZIEHUNG,
    {
      entityType: "Verlinkung",
      notifications: {
        Success: " wurde aufgelöst.",
        Error: " konnte nicht aufgelöst werden.",
      },
      style: NotificationStyle.SUCCESS,
    },
  );

  static readonly KOPIERE_PLANUNGSOBJEKT = new Aktion(AktionEnum.KOPIERE_PLANUNGSOBJEKT, {
    entityType: "Programm",
    notifications: {
      ...defaultNotifications.kopieren,
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly VERSCHIEBE_PLANUNGSOBJEKT = new Aktion(AktionEnum.VERSCHIEBE_PLANUNGSOBJEKT, {
    entityType: "Programm",
    notifications: {
      Success: " wurde erfolgreich verschoben.",
      Error: " konnte nicht verschoben werden.",
    },
    style: NotificationStyle.SUCCESS,
  });

  static readonly BEARBEITE_SENDEPLATZ = new Aktion(AktionEnum.BEARBEITE_SENDEPLATZ, {
    entityType: "Sendeplatz",
    notifications: {
      ...defaultNotifications.bearbeiten,
    },
    style: NotificationStyle.SUCCESS,
  });

  private constructor(
    private readonly key: AktionEnum,
    public readonly value: IAktion,
  ) {}

  get enumKey() {
    return this.key;
  }
}

export interface IAktion {
  entityType:
    | "Event"
    | "Mengengerüsteintrag"
    | "Mengengerüsteintragszuweisung"
    | "Programm"
    | "Ansicht"
    | "Konkurrenzprogramm"
    | "Sendeplatz"
    | "Verlinkung"
    | "Serie"
    | "Vorschlag";
  notifications: {
    Success?: string;
    Error?: string;
    Info?: string;
    Warning?: string;
    None?: string;
  };
  style: NotificationStyle;
  duration?: number;
  icon?: boolean;
}
