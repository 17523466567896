import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs";
import { PlanungsobjektOnDemandApiService } from "src/app/api/planungsobjekt/planungsobjekt-on-demand.api.service";
import { Aktion, AktionEnum } from "src/app/models/enums/aktion";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { MerklisteService } from "src/app/services/merkliste.service";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { merklisteActions } from "../merkliste/merkliste.actions";
import { onDemandActions } from "../on-demand/on-demand.actions";
import { planungsobjektActions } from "./planungsobjekt.actions";

@Injectable()
export class PlanungsobjektMerklisteEffects {
  /**
   * Fügt alle Planungsobjekte Linear beim Laden der Merklisten in den State hinzu.
   */
  addPlanungsobjekteLinearFromMerklisten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(merklisteActions.loadMerklistenSuccess),
      map(({ merklisten }) => {
        const planungsobjekte = merklisten.flatMap((merkliste) => merkliste.planungsobjekteLinear);
        return planungsobjektActions.upsertPlanungsobjekteFromMerklistenSuccess({
          planungsobjekte,
        });
      }),
    );
  });

  /**
   * Erstelle eine Planungsobjekt auf einer Merkliste.
   */
  createPlanungsobjektOnMerkliste$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.createPlanungsobjektOnMerkliste),
      switchMap(({ shouldCloseWindow, command, merkliste }) => {
        return this.merklisteService.planungsobjektLinearVorgemerktErstellen$(command).pipe(
          map((planungsobjekt) =>
            planungsobjektActions.createPlanungsobjektOnMerklisteSuccess({
              shouldCloseWindow,
              planungsobjekt,
              merkliste,
            }),
          ),
        );
      }),
    );
  });

  /**
   * Entferne eine Planungsobjekt auf einer Merkliste.
   */
  deletePlanungsobjektOnMerkliste$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.deletePlanungsobjektOnMerkliste),
      map(({ planungsobjektId }) =>
        planungsobjektActions.deletePlanungsobjekt({ planungsobjektId }),
      ),
    );
  });

  /**
   * Kopiere eine Planungsobjekt auf einer Merkliste.
   */
  copyPlanungsobjektOnMerkliste$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.copyPlanungsobjektOnMerkliste),
      switchMap(({ planungsobjekt }) =>
        this.merklisteService
          .planungsobjektLinearVorgemerktKopieren$({ planungsobjektId: planungsobjekt.id })
          .pipe(
            map((planungsobjekt) => {
              return planungsobjektActions.copyPlanungsobjektOnMerklisteSuccess({
                planungsobjekt,
              });
            }),
            tap((result) => {
              this.notificationService.showActionNotification(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                Aktion.KOPIERE_PLANUNGSOBJEKT,
                NotificationStyle.SUCCESS,
                result.planungsobjekt.titel,
              );
            }),
          ),
      ),
    );
  });

  /**
   * Verschiebe eine Planungsobjekt per Dialog oder Drag & Drop auf eine Merkliste.
   */
  movePlanungsobjektToMerkliste$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.movePlanungsobjektToMerkliste),
      switchMap(({ planungsobjektId, merkliste }) =>
        this.merklisteService
          .verschiebePlanungsobjektLinearZuVorgemerkt$(planungsobjektId, merkliste)
          .pipe(
            map((result) =>
              planungsobjektActions.movePlanungsobjektToMerklisteSuccess({
                planungsobjekt: result,
                merkliste,
              }),
            ),
          ),
      ),
      tap((result) => {
        this.notificationService.showActionNotification(
          AktionEnum.PLANUNGSOBJEKT_AUF_MERKLISTE,
          NotificationStyle.SUCCESS,
          result.planungsobjekt.result.titel,
        );
        this.notificationService.showNotifications(result.planungsobjekt.notifications);
      }),
    );
  });

  /**
   * Erstelle ein OnDemand Planungsobjekt im Planungskontext vorgemerkt.
   */
  createPlanungsobjektOnDemandVorgemerkt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.createPlanungsobjektOnDemandVorgemerkt),
      switchMap(({ command, merkliste }) => {
        return this.planungsobjektOnDemandApi
          .planungsobjektOnDemandVorgemerktErstellen$(command)
          .pipe(
            tap((response) => {
              this.notificationService.showNotifications(response.notifications);
            }),
            map((response) =>
              onDemandActions.createPlanungsobjektOnDemandVorgemerktSuccess({
                planungsobjektOnDemand: response.result,
                merkliste,
              }),
            ),
          );
      }),
    );
  });

  moveOnDemandToVorgemerkt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandActions.verschiebePlanungsobjektOnDemandZuVorgemerkt),
      switchMap(({ command, merkliste }) => {
        return this.planungsobjektOnDemandApi
          .planungsobjektOnDemandVerschiebenZuVorgemerkt$(command)
          .pipe(
            tap((response) => {
              this.notificationService.showNotifications(response.notifications);
            }),
            map((response) =>
              onDemandActions.verschiebePlanungsobjektOnDemandZuVorgemerktSuccess({
                planungsobjektOnDemand: response.result,
                merkliste: merkliste,
              }),
            ),
          );
      }),
    );
  });

  /**
   * Aktualisiere ein lineares Planungsobjekt im Planungskontext vorgeschlagen.
   */
  updatePlanungsobjektLinearLinearVorgemerkt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.updatePlanungsobjektOnMerkliste),
      switchMap(({ shouldCloseWindow, command }) =>
        this.service.planungsobjektLinearVorgemerktAktualisieren$(command).pipe(
          map((linear) =>
            planungsobjektActions.updatePlanungsobjektOnMerklisteSuccess({
              shouldCloseWindow,
              planungsobjekt: linear,
            }),
          ),
          tap(({ planungsobjekt }) => {
            this.notificationService.showActionNotification(
              Aktion.BEARBEITE_PLANUNGSOBJEKT,
              NotificationStyle.SUCCESS,
              planungsobjekt.titel,
            );
          }),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: PlanungsobjektService,
    private notificationService: CustomNotificationService,
    private merklisteService: MerklisteService,
    private planungsobjektOnDemandApi: PlanungsobjektOnDemandApiService,
  ) {}
}
