<ng-container
  *ngIf="{
    editieren: 'editieren' | able: 'EK'
  } as can"
>
  <div class="k-d-flex k-justify-content-between filter-header">
    <form autocomplete="off" [formGroup]="formGroup">
      <kendo-dropdownlist
        class="planungsansicht-year"
        data-testid="year-dropdown"
        fillMode="flat"
        formControlName="year"
        [data]="years$ | async"
        [valuePrimitive]="true"
        (valueChange)="loadNewYear($event)"
      >
      </kendo-dropdownlist>
    </form>
    <kendo-svg-icon
      class="ek-button icon-inactive"
      size="large"
      title="Event/Konkurrenzprogramm anlegen"
      [icon]="Icons.eventKonkurrenz"
      [ngClass]="{
        'selection-disabled': !can.editieren
      }"
      (click)="can.editieren && onOpenCreateEkWindow()"
    ></kendo-svg-icon>
  </div>
  <div *ngIf="(loadingEks$ | async) === false" class="splitter-container">
    <div class="ansichten-pane">
      <table class="kalenderansicht-table">
        <tr class="kalenderansicht-header">
          <th *ngFor="let header of ekAnsichtHeader">
            <span class="kalenderansicht-header-wochentag">{{ header }}</span>
          </th>
        </tr>
        <ng-container *ngFor="let month of eks$ | async | keyvalue">
          <tr>
            <th class="kalenderansicht-header-month" [attr.colspan]="ekAnsichtHeader.length">
              {{ getMonth(month.key) }}
            </th>
          </tr>
          <tr *ngFor="let week of month.value | keyvalue">
            <td
              *ngFor="let day of week.value"
              (dblclick)="can.editieren && onOpenCreateEkWindow(day.kalendertag)"
            >
              <div class="sendeplatz-container">
                <div class="sendeplatz-details-header">
                  <div class="sendeplatz">
                    <span>
                      <app-event-list *ngIf="day?.events" [zeitraum]="day.zeitraum">
                      </app-event-list>
                      <app-konkurrenzprogramm-list
                        *ngIf="day?.konkurrenzprogramme"
                        [zeitraum]="day.zeitraum"
                      >
                      </app-konkurrenzprogramm-list>
                    </span>
                  </div>
                </div>

                <span class="sendeplatz-date">
                  {{ day?.kalendertag | date: "dd" }}
                </span>
              </div>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</ng-container>
