import { Type } from "@progress/kendo-angular-notification";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";

export function getKendoStyleType(styleType: NotificationStyle): Type["style"] {
  switch (styleType) {
    case NotificationStyle.NONE:
      return "none";
    case NotificationStyle.SUCCESS:
      return "success";
    case NotificationStyle.WARNING:
      return "warning";
    case NotificationStyle.ERROR:
      return "error";
    case NotificationStyle.INFO:
      return "info";
    default:
      return "none";
  }
}
