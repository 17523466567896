import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GetSendeplaetzeByAnsichtenGroupedBySendetagQuery } from "src/app/models/openapi/model/get-sendeplaetze-by-ansichten-grouped-by-sendetag-query";
import { GetSendeplaetzeByAnsichtenQuery } from "src/app/models/openapi/model/get-sendeplaetze-by-ansichten-query";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzGroupDto } from "src/app/models/openapi/model/sendeplatz-group-dto";
import { SendeplatzKeyDto } from "src/app/models/openapi/model/sendeplatz-key-dto";
import { UpsertSendeplatzCommand } from "src/app/models/openapi/model/upsert-sendeplatz-command";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SendeplatzApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getSendeplatzById$(sendeplatzId: string): Observable<SendeplatzDto> {
    return this.http.get<SendeplatzDto>(this.apiUrl + "Sendeplatz/" + `${sendeplatzId}`);
  }

  getSendeplatzBySendeplatzKey$(sendeplatzKey: SendeplatzKeyDto): Observable<SendeplatzDto> {
    return this.http.post<SendeplatzDto>(
      this.apiUrl + "Sendeplatz/BySendeplatzKey/",
      sendeplatzKey,
    );
  }

  getSendeplaetzeByAnsichten$(query: GetSendeplaetzeByAnsichtenQuery): Observable<SendeplatzDto[]> {
    return this.http.post<SendeplatzDto[]>(this.apiUrl + "Sendeplatz/ByAnsichten", query);
  }

  getSendeplaetzeByAnsichtGrouped$(ansichtId: string): Observable<SendeplatzGroupDto[]> {
    return this.http.get<SendeplatzGroupDto[]>(
      this.apiUrl + "Sendeplatz/ByAnsichtGrouped/" + `${ansichtId}`,
    );
  }

  getSendeplaetzeByAnsichtenGrouped$(
    query: GetSendeplaetzeByAnsichtenGroupedBySendetagQuery,
  ): Observable<SendeplatzGroupDto[][]> {
    return this.http.post<SendeplatzGroupDto[][]>(
      this.apiUrl + "Sendeplatz/ByAnsichtenGrouped/",
      query,
    );
  }

  updateSendeplatz$(body: UpsertSendeplatzCommand): Observable<SendeplatzDto> {
    return this.http.put<SendeplatzDto>(this.apiUrl + "Sendeplatz/", body);
  }
}
