import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { CreateFormGroupArgs } from "@progress/kendo-angular-grid";
import { format } from "date-fns";
import { Observable } from "rxjs";
import planungshinweisSelectors from "src/app/core/stores/planunshinweis/planungshinweis.selectors";
import {
  PlanungshinweisStatusOptions,
  PlanungshinweisStatusRecord,
} from "src/app/models/enums/planungshinweis-status";
import { Icons } from "src/app/models/icons";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { PlanungshinweisDto } from "src/app/models/openapi/model/planungshinweis-dto";
import { PlanungshinweisStatus } from "src/app/models/openapi/model/planungshinweis-status";
import { DebugService } from "src/app/services/debug.service";
import { PlanungshinweisEditService } from "src/app/services/planungshinweis-edit.service";
import { CustomValidators } from "../../form-inputs/custom-validators";

export type PlanungshinweiseWindowInput = {
  von: Date;
  bis: Date;
  kanal: Kanal;
};

export type PlanungshinweiseWindowResult = {
  foo: string;
};

@Component({
  selector: "app-planungshinweise-window",
  templateUrl: "./planungshinweise-window.component.html",
  styleUrls: ["./planungshinweise-window.component.scss"],
})
export class PlanungshinweiseWindowComponent implements OnInit {
  @Input() public input: PlanungshinweiseWindowInput;

  planungshinweise$: Observable<PlanungshinweisDto[]>;

  formGroup: ReturnType<typeof this.createFormGroup>;

  Icons = Icons;
  PlanungshinweisStatusOptions = PlanungshinweisStatusOptions;
  PlanungshinweisStatusRecord = PlanungshinweisStatusRecord;

  constructor(
    private formBuilder: FormBuilder,
    private windowRef: WindowRef,
    private store: Store,
    protected planungshinweisEditService: PlanungshinweisEditService,
    protected debugService: DebugService,
  ) {}

  ngOnInit(): void {
    if (!this.input) {
      return;
    }

    // Kendo Grid benötigt eine gesetzte FormGroup
    this.formGroup = this.createFormGroup({
      isNew: true,
      dataItem: undefined,
      rowIndex: undefined,
      sender: undefined,
    });

    this.planungshinweise$ = this.store.select(
      planungshinweisSelectors.selectPlanungshinweiseByTimeRange(
        this.input.von,
        this.input.bis,
        this.input.kanal,
      ),
    );
  }

  /**
   * Konvertiert ein Datum in die von der publish.it API erwartete ISO-String-Formatierung
   * ohne Zeitzoneninformationen (z.B. 2022-01-04T21:45:00)
   */
  private static toPublitIsoString(date: Date): string {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss");
  }

  /**
   * Die Signatur der args ist von Kendo vorgegeben.
   */
  public createFormGroup = (args: CreateFormGroupArgs): FormGroup => {
    const item = args.isNew
      ? ({
          von: PlanungshinweiseWindowComponent.toPublitIsoString(this.input.von),
          bis: PlanungshinweiseWindowComponent.toPublitIsoString(this.input.bis),
          kanal: this.input.kanal,
          status: PlanungshinweisStatus.PROBLEMATISCH,
          notiz: "",
        } as PlanungshinweisDto)
      : (args.dataItem as PlanungshinweisDto);

    this.formGroup = this.formBuilder.group({
      von: [item.von, [Validators.required, CustomValidators.dateBefore("bis", "Bis")]],
      bis: [item.bis, [Validators.required, CustomValidators.dateAfter("von", "Von")]],
      kanal: item.kanal,
      status: item.status,
      notiz: item.notiz,
    });

    return this.formGroup;
  };

  onClose() {
    this.windowRef.close();
  }
}
