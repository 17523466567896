import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { ContextMenuSelectEvent } from "@progress/kendo-angular-menu";
import { Observable, map } from "rxjs";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { mehrfachauswahlFeature } from "src/app/core/stores/mehrfachauswahl/mehrfachauswahl.reducer";
import { merklisteActions } from "src/app/core/stores/merkliste/merkliste.actions";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import planungsobjektSelectors from "src/app/core/stores/planungsobjekt/planungsobjekt.selectors";
import { AktionEnum } from "src/app/models/enums/aktion";
import { Icons } from "src/app/models/icons";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { MerklisteKategorie } from "src/app/models/openapi/model/merkliste-kategorie";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { PlanungsobjektInteraktionService } from "src/app/services/planungsobjekt-interaktion.service";
import { droppedInNewContainer } from "src/app/utils/planungsobjekt-interaktion-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { PublitFrontendSettings } from "src/environments/environment";
import { BlockansichtDragDropService } from "../ansichten/blockansicht/blockansicht.drag-drop.service";

@Component({
  selector: "app-merkliste",
  templateUrl: "./merkliste.component.html",
  styleUrls: ["./merkliste.component.scss"],
})
export class MerklisteComponent implements OnChanges {
  @Input() merkliste: MerklisteDto;
  @Input() planungsobjekteInMehrfachauswahl: PlanungsobjektDto[];
  @ViewChild("title") titleSpan: ElementRef<HTMLSpanElement>;

  Icons = Icons;

  isBlockansicht$ = this.store.select(ansichtSelectors.selectIsBlockansicht);
  mehrfachauswahlActive$ = this.store.select(mehrfachauswahlFeature.selectMehrfachauswahlActive);
  // Mehrfachauswahl darf nicht auf Merkliste geschoben werden
  dropPredicate$ = this.mehrfachauswahlActive$.pipe(
    map((isMultipleDragging) => () => !isMultipleDragging),
  );
  planungsobjekteOnMerkliste$: Observable<PlanungsobjektDto[]>;

  Redaktion = Redaktion;
  CopyPattern = CopyPattern;
  AktionEnum = AktionEnum;
  MerklisteKategorie = MerklisteKategorie;

  isEditingName = false;
  previousName: string;
  merklisteName: string;

  constructor(
    private blockansichtDragDropService: BlockansichtDragDropService,
    private store: Store,
    protected planungsobjektInteraktionService: PlanungsobjektInteraktionService,
    protected settings: PublitFrontendSettings,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.merklisteName?.currentValue !== this.merkliste.name) {
      this.merklisteName = this.merkliste.name
        ? this.merkliste.name
        : `Merkliste ${this.merkliste.nummer}`;
    }
    if ((changes.merklisteDto?.previousValue as MerklisteDto)?.id !== this.merkliste.id) {
      this.planungsobjekteOnMerkliste$ = this.store.select(
        planungsobjektSelectors.selectPlanungsobjekteForMerklisteId(this.merkliste.id),
      );
    }
  }

  private addPlanungsobjektToMerkliste(input: HTMLInputElement) {
    // Titel muss mindestens 2 Zeichen lang sein
    const { ansichtId, nummer, ausspielweg } = this.merkliste;
    const titel = input.value.trim();
    if (titel.length <= 1) {
      input.value = "";
      return;
    }

    this.store.dispatch(
      planungsobjektActions.createPlanungsobjektOnMerkliste({
        shouldCloseWindow: true, // Uninteressant für die +-Logik, aber muss mitgeschliffen werden
        command: {
          titel,
          merklisteAnsichtId: ansichtId,
          merklisteNummer: nummer,
          merklisteAusspielweg: ausspielweg,
        },
        merkliste: this.merkliste,
      }),
    );
    input.value = "";
  }

  onPlanungsobjektInputBlur(event: Event) {
    const titel = (event.target as HTMLInputElement).value;
    if (titel.length > 1) {
      const input = event.target as HTMLInputElement;
      this.addPlanungsobjektToMerkliste(input);
    } else {
      (event.target as HTMLInputElement).value = "";
    }
  }

  movePlanungsobjektToMerkliste<T>(event: CdkDragDrop<T, T, PlanungsobjektDto>) {
    const isNewDropList = droppedInNewContainer(event);
    if (isNewDropList) {
      this.store.dispatch(
        planungsobjektActions.movePlanungsobjektToMerkliste({
          planungsobjektId: event.item.data.id,
          merkliste: this.merkliste,
        }),
      );
    }
  }

  onPlanungsobjektContextMenuSelect(event: ContextMenuSelectEvent) {
    const {
      data: { action },
    } = event.item as {
      data: {
        action: AktionEnum;
      };
    };
    const {
      data: { planungsobjekt },
    } = event.target as {
      data: {
        planungsobjekt: PlanungsobjektDto;
      };
    };

    switch (action) {
      case AktionEnum.BEARBEITE_PLANUNGSOBJEKT:
        this.openPlanungsobjektLinearWithPlanungWindow(planungsobjekt);
        break;
      case AktionEnum.KOPIE_HIER:
        this.store.dispatch(
          planungsobjektActions.copyPlanungsobjektOnMerkliste({
            planungsobjekt: planungsobjekt,
          }),
        );
        break;
    }
  }

  /**
   * Öffnet das Planungsobjekt in der Planung mit Verlinkungstab (neues Fenster)
   * Planungskontext vorgeschlagen wird dann nachgelagert betrachtet
   * @param planungsobjekt Planungsobjekt, das geöffnet werden soll
   * @returns void
   * */
  openPlanungsobjektLinearWithPlanungWindow(planungsobjekt: PlanungsobjektDto) {
    this.store.dispatch(
      planungsobjektWindowActions.openPlanungsobjektLinearWithPlanungOnSendeplatzWindow({
        linearId: planungsobjekt.id,
      }),
    );
  }

  startEditingName() {
    this.isEditingName = true;
    this.previousName = this.merklisteName;
    const selection = document.getSelection();
    selection?.selectAllChildren(this.titleSpan.nativeElement);
    setTimeout(() => this.titleSpan.nativeElement.focus());
  }

  saveName() {
    const name = this.titleSpan.nativeElement.textContent?.trim();
    if (name === this.previousName || !name?.length || name.length <= 1)
      return this.cancelEditingName();
    const { ansichtId, nummer } = this.merkliste;
    this.isEditingName = false;

    this.store.dispatch(
      merklisteActions.renameMerklisteAnsicht({ command: { ansichtId, name, nummer } }),
    );
  }

  cancelEditingName() {
    this.titleSpan.nativeElement.textContent = this.previousName;
    this.isEditingName = false;
    document.getSelection()?.empty();
  }

  /**
   * Bricht Bearbeitung des Merkliste Titels ab, wenn beim blur ein Element fokussiert wird,
   * das für die Bearbeitung des Titels nicht zuständig ist
   */
  onBlurEditingMerkliste(event: FocusEvent) {
    if (!this.isEditingName) return;
    const target = event.target as HTMLElement | null;
    const nowFocussed = event.relatedTarget as HTMLElement | null;
    const isSameParent = target?.parentElement === nowFocussed?.parentElement;

    if (!nowFocussed || !isSameParent) {
      this.cancelEditingName();
    }
  }

  onKeypressMerklisteInput(event: KeyboardEvent) {
    if (event.code === "Enter") {
      event.preventDefault();
      if (event.type === "keyup") {
        this.saveName();
      }
    }
  }

  // Wird verwendet damit beim Hovern über eine Zelle in der Ansicht zunächst eine Preview angezeigt
  // und beim zurücklegen in die Merkliste wieder ausgeblendet wird.
  onDragDropped() {
    this.blockansichtDragDropService.resetDragDropAttributes();
  }

  istMitProduktdatenbankVerknuepft = PlanungsobjektUtils.isConnectedWithProductDb;
}
