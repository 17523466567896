import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { Layout } from "src/app/models/openapi/model/layout";
import { MultiAnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

export const multiansichtActions = createActionGroup({
  source: "Multiansicht",
  events: {
    setMultiansicht: emptyProps(),
    setMultiansichtSuccess: props<{
      multiAnsichtViewModel: MultiAnsichtViewModel;
    }>(),
    updateAnsichtInMultiansicht: props<{
      year: number;
    }>(),
    updateAnsichtInMultiansichtSuccess: props<{
      additionalAnsichtViewModelId: string;
      layout: Layout;
    }>(),
  },
});
