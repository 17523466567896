/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type OnDemandFilter = "Unbekannt" | "Fiction" | "Doku" | "Show";

export const OnDemandFilter = {
  UNBEKANNT: "Unbekannt" as OnDemandFilter,
  FICTION: "Fiction" as OnDemandFilter,
  DOKU: "Doku" as OnDemandFilter,
  SHOW: "Show" as OnDemandFilter,
};
