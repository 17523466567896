<button
  kendoButton
  rounded="full"
  [disabled]="isDisabled"
  [fillMode]="fillMode"
  [size]="size"
  [svgIcon]="iconInternal"
  [themeColor]="_internalValue ? themeColor : inactiveThemeColor"
  (click)="onToggle()"
>
  <ng-content></ng-content>
</button>
