import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const listenansichtActions = createActionGroup({
  source: "Listenansicht",
  events: {
    resetActiveHeaders: emptyProps(),
    resetHeader: emptyProps(),
    toggleColumnHeaderForColumnId: props<{
      columnId: string;
    }>(),
  },
});
