<kendo-tabstrip
  class="sidebar-tabstrip"
  tabAlignment="justify"
  (tabSelect)="emitSetActiveSidebarTab($any($event.title))"
>
  <!-- Benachrichtigungen -->
  <kendo-tabstrip-tab
    title="Benachrichtigungen"
    [selected]="activeSidebarTab === SidebarTabEnum.Benachrichtigungen"
  >
    <ng-template kendoTabTitle>
      <div
        class="overshadow-kendo-tab-title u-text-primary-contrast"
        data-testid="sidebar-benachrichtigungen-tab"
        [title]="SidebarTabEnum.Benachrichtigungen"
      >
        <kendo-badge-container>
          <kendo-svg-icon [icon]="Icons.benachrichtigungenIcon"></kendo-svg-icon>
          <kendo-badge *ngIf="unreadNotificationForCurrentAnsicht" shape="dot"></kendo-badge>
        </kendo-badge-container>
      </div>
    </ng-template>
    <ng-template kendoTabContent>
      <app-benachrichtigung [benachrichtigungenComponentData]="benachrichtigungenComponentData">
      </app-benachrichtigung>
    </ng-template>
  </kendo-tabstrip-tab>
  <!-- Merkliste -->
  <kendo-tabstrip-tab title="Merkliste" [selected]="activeSidebarTab === SidebarTabEnum.Merkliste">
    <ng-template kendoTabTitle>
      <div
        class="overshadow-kendo-tab-title u-text-primary-contrast"
        data-testid="sidebar-merkliste-tab"
        [title]="SidebarTabEnum.Merkliste"
      >
        <kendo-svg-icon [icon]="Icons.merklisteIcon"></kendo-svg-icon>
      </div>
    </ng-template>
    <ng-template kendoTabContent *ngIf="activeSidebarTab === SidebarTabEnum.Merkliste">
      <!-- merklistenIndex beginnt bei 1 -->
      <app-merkliste
        *ngFor="let merkliste of merklisten; trackBy: trackMerkliste"
        [merkliste]="merkliste"
        [planungsobjekteInMehrfachauswahl]="planungsobjekteInMehrfachauswahl"
      ></app-merkliste>
    </ng-template>
  </kendo-tabstrip-tab>
  <!-- Filter -->
  <kendo-tabstrip-tab title="Filter" [selected]="activeSidebarTab === SidebarTabEnum.Filter">
    <ng-template kendoTabTitle>
      <div
        class="overshadow-kendo-tab-title u-text-primary-contrast"
        data-testid="sidebar-filter-tab"
        [title]="SidebarTabEnum.Filter"
      >
        <kendo-svg-icon [icon]="Icons.filterIcon"></kendo-svg-icon>
      </div>
    </ng-template>
    <ng-template kendoTabContent>
      <app-filter
        [layout]="selectedAnsichtViewModel.ansichtsdefinition.layout"
        [showKonkurrenzEvents]="showKonkurrenzEvents"
        [showNotizen]="showNotizen"
        (toggleShowKonkurrenzEvents)="emitToggleShowKonkurrenzEvents()"
        (toggleShowNotizen)="emitToggleShowNotizen()"
      ></app-filter>
    </ng-template>
  </kendo-tabstrip-tab>
  <!-- Weiteres -->
  <kendo-tabstrip-tab title="Weiteres" [selected]="activeSidebarTab === SidebarTabEnum.Weiteres">
    <ng-template kendoTabTitle>
      <div
        class="overshadow-kendo-tab-title u-text-primary-contrast"
        data-testid="sidebar-weiteres-tab"
        [title]="SidebarTabEnum.Weiteres"
      >
        <kendo-svg-icon [icon]="Icons.weiteresIcon"></kendo-svg-icon></div
    ></ng-template>
    <ng-template kendoTabContent>
      <app-weiteres
        [selectedAnsichtViewModel]="selectedAnsichtViewModel"
        [zoomLevel]="zoomLevel"
        (openCreateEkWindow)="emitOpenEkWindow()"
        (openMengengeruesteWindow)="emitOpenMengengeruesteWindow()"
        (selectedZoomLevelChanged)="emitSelectedZoomLevelChanged($event)"
        (triggerReportDownload)="emitTriggerReportDownload($event)"
      ></app-weiteres>
    </ng-template>
  </kendo-tabstrip-tab>
  <!-- Mehrfachauswahl -->
  <kendo-tabstrip-tab
    *ngIf="!!planungsobjekteInMehrfachauswahl.length"
    title="Mehrfachauswahl"
    [selected]="activeSidebarTab === SidebarTabEnum.Mehrfachauswahl"
  >
    <ng-template kendoTabTitle>
      <div
        class="overshadow-kendo-tab-title u-text-primary-contrast"
        [title]="SidebarTabEnum.Mehrfachauswahl"
      >
        <kendo-svg-icon [icon]="Icons.mehrfachauswahlIcon"></kendo-svg-icon>
      </div>
    </ng-template>
    <ng-template kendoTabContent>
      <app-mehrfachauswahl
        [planungsobjekteInMehrfachauswahl]="planungsobjekteInMehrfachauswahl"
      ></app-mehrfachauswahl>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
