@for (
  sendeplaetzeMonth of sendeplaetzeYear.value;
  track monthIndex;
  let monthIndex = $index;
  let firstMonth = $first
) {
  <app-kalenderansicht-sendeplatz-monat
    [ansichtViewModel]="ansichtViewModelForYear$ | async"
    [firstMonth]="firstMonth"
    [firstYear]="firstYear"
    [monthIndex]="monthIndex"
    [sendeplaetzeMonth]="sendeplaetzeMonth"
    [sendeplaetzeYearKey]="sendeplaetzeYear.key"
  >
  </app-kalenderansicht-sendeplatz-monat>
}
