import { Pipe, PipeTransform } from "@angular/core";
import { SchemaplatzDto } from "src/app/models/openapi/model/schemaplatz-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { toWochentag } from "src/app/utils/date-utils";

@Pipe({
  name: "schemaplatzBySendeplatz",
})
export class SchemaplatzBySendeplatzPipe implements PipeTransform {
  transform(sendeplatz: SendeplatzDto, ansichtViewModel: AnsichtViewModel): SchemaplatzDto {
    return ansichtViewModel.ansichtsdefinition.schemaplaetze
      .filter((schemaplatz) => schemaplatz !== undefined)
      .find(
        (schemaplatz) =>
          schemaplatz.wochentag === toWochentag(sendeplatz.kalendertag) &&
          schemaplatz.beginnzeit === sendeplatz.beginnzeit &&
          schemaplatz.laenge === sendeplatz.laenge,
      );
  }
}
