<ng-container
  *ngIf="{
    bearbeiten: 'editieren' | able: 'EK'
  } as can"
>
  <form
    autocomplete="off"
    data-testid="ek-window"
    [appDisableFormGroup]="!can.bearbeiten"
    [formGroup]="formGroup"
  >
    <div *ngIf="can.bearbeiten" class="ek-window-switch">
      Konkurrenzprogramm
      <kendo-switch
        data-testid="ek-window-switch"
        formControlName="isEvent"
        (valueChange)="onChangeSwitch()"
      ></kendo-switch>
      Event
    </div>

    <div class="flex-container">
      <app-form-field class="u-w-full" label="Titel">
        <kendo-textbox
          data-testid="ek-window-titel"
          formControlName="titel"
          minlength="2"
        ></kendo-textbox>
      </app-form-field>
    </div>

    <div class="flex-container">
      <app-select
        *ngIf="!formGroup.controls.isEvent?.value"
        class="u-w-full max-width"
        data-testid="ek-window-kategorie"
        formControlName="kategorie"
        label="Kategorie"
        [options]="KonkurrenzKategorie"
      ></app-select>
      <app-select
        *ngIf="formGroup.controls.isEvent?.value"
        class="u-w-full"
        data-testid="ek-window-kategorie"
        formControlName="kategorie"
        label="Kategorie"
        [options]="EventKategorie"
      ></app-select>

      <app-select
        *ngIf="!formGroup.controls.isEvent?.value"
        class="u-w-full max-width"
        data-testid="ek-window-senderPlattform"
        formControlName="senderPlattform"
        label="Sender/Plattform"
        [isSenderPlattform]="true"
        [options]="SenderPlattform"
        (selectionChange)="onSenderPlattformChange()"
      ></app-select>
    </div>
    <div *ngIf="isSonstiges()" class="flex-container">
      <app-form-field class="u-w-full" label="Sonstiges">
        <kendo-textbox
          data-testid="ek-window-senderPlattformSonstiges"
          formControlName="senderPlattformSonstiges"
        ></kendo-textbox>
      </app-form-field>
    </div>
    <div class="flex-container">
      <!-- Datum von -->
      <app-form-field class="u-w-full max-width" label="Datum von">
        <kendo-datepicker data-testid="ek-window-vonDatum" formControlName="vonDatum">
        </kendo-datepicker>
      </app-form-field>

      <!-- Uhrzeit von -->
      <app-rounded-timepicker
        class="u-w-full max-width"
        data-testid="ek-window-vonZeit"
        formControlName="vonZeit"
        label="Uhrzeit von"
      ></app-rounded-timepicker>
    </div>
    <div class="flex-container">
      <!-- Datum bis -->
      <app-form-field class="u-w-full max-width" label="Datum bis">
        <kendo-datepicker
          data-testid="ek-window-bisDatum"
          formControlName="bisDatum"
          placeholder=""
          [focusedDate]="formGroup.controls.bisDatum.value ?? formGroup.controls.vonDatum.value"
          [min]="formGroup.controls.vonDatum.value"
        >
        </kendo-datepicker>
      </app-form-field>

      <!-- Uhrzeit bis -->
      <app-rounded-timepicker
        class="u-w-full max-width"
        data-testid="ek-window-bisZeit"
        formControlName="bisZeit"
        label="Uhrzeit bis"
      ></app-rounded-timepicker>
    </div>
    <div *ngIf="!formGroup.controls.isEvent?.value" class="publit-checkbox">
      <input
        #abgesetzt
        data-testid="ek-window-isAbgesetzt"
        formControlName="isAbgesetzt"
        kendoCheckBox
        type="checkbox"
      />
      <kendo-label text="als abgesetzt markieren" [for]="abgesetzt"></kendo-label>
    </div>

    <app-form-field class="u-w-full" label="Notiz">
      <kendo-textarea data-testid="ek-window-notiz" formControlName="notiz" resizable="auto">
      </kendo-textarea>
    </app-form-field>
  </form>

  <div class="window-action-buttons">
    <button
      class="primary-button"
      data-testid="ek-window-speichern-button"
      kendoButton
      themeColor="primary"
      type="button"
      [disabled]="!can.bearbeiten || !formGroup.valid || formGroup.pristine"
      (click)="onOkClick()"
    >
      Speichern
    </button>
    <button
      *ngIf="data.type !== 'Create EK'"
      data-testid="ek-window-loeschen-button"
      fillMode="outline"
      kendoButton
      type="button"
      [disabled]="!can.bearbeiten"
      (click)="onRemoveClick()"
    >
      Löschen
    </button>
    <button
      data-testid="ek-window-abbrechen-button"
      fillMode="outline"
      kendoButton
      type="button"
      (click)="onClose()"
    >
      Abbrechen
    </button>
  </div>
</ng-container>
