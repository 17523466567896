<ng-container *ngIf="{} | isLoading | async; then loading; else failedAuthorization"></ng-container>
<ng-template #failedAuthorization>
  <div>
    <div class="message-area">
      <br />
      <h3>Ihr User Account verfügt über keine oder unzureichende Rollen in dieser Applikation.</h3>
      <br />
      Bitte wenden Sie sich an die zuständige Administratorin Dana Wilk (Wilk.D&#64;zdf.de).
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <app-loading-dialog title="Bitte warten..."></app-loading-dialog>
</ng-template>
