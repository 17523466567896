import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConvertEventToKonkurrenzprogrammCommand } from "src/app/models/openapi/model/convert-event-to-konkurrenzprogramm-command";
import { CopyKonkurrenzprogrammCommand } from "src/app/models/openapi/model/copy-konkurrenzprogramm-command";
import { CreateKonkurrenzprogrammCommand } from "src/app/models/openapi/model/create-konkurrenzprogramm-command";
import { GetKonkurrenzprogrammeByAnsichtenQuery } from "src/app/models/openapi/model/get-konkurrenzprogramme-by-ansichten-query";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { UpdateKonkurrenzprogrammCommand } from "src/app/models/openapi/model/update-konkurrenzprogramm-command";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class KonkurrenzprogrammApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  createKonkurrenzprogramm$(
    body: CreateKonkurrenzprogrammCommand,
  ): Observable<KonkurrenzprogrammDto> {
    return this.http.post<KonkurrenzprogrammDto>(this.apiUrl + "Konkurrenzprogramm/", body);
  }

  updateKonkurrenzprogramm$(
    id: string,
    body: UpdateKonkurrenzprogrammCommand,
  ): Observable<KonkurrenzprogrammDto> {
    return this.http.put<KonkurrenzprogrammDto>(
      this.apiUrl + "Konkurrenzprogramm/" + `${id}`,
      body,
    );
  }

  deleteKonkurrenzprogramm$(id: string): Observable<void> {
    return this.http.delete<void>(this.apiUrl + "Konkurrenzprogramm/" + `${id}`);
  }

  copyKonkurrenzprogramm$(
    id: string,
    body: CopyKonkurrenzprogrammCommand,
  ): Observable<KonkurrenzprogrammDto> {
    return this.http.post<KonkurrenzprogrammDto>(
      this.apiUrl + "Konkurrenzprogramm/" + `${id}` + "/Copy",
      body,
    );
  }

  convertEvent$(body: ConvertEventToKonkurrenzprogrammCommand): Observable<KonkurrenzprogrammDto> {
    return this.http.post<KonkurrenzprogrammDto>(this.apiUrl + "Konkurrenzprogramm/Convert", body);
  }

  getKonkurrenzprogrammeByAnsicht$(ansichtId: string): Observable<KonkurrenzprogrammDto[]> {
    return this.http.get<KonkurrenzprogrammDto[]>(
      this.apiUrl + `Konkurrenzprogramm/ByAnsicht/${ansichtId}`,
    );
  }

  getKonkurrenzprogrammeByAnsichten$(
    ansichtenIds: GetKonkurrenzprogrammeByAnsichtenQuery,
  ): Observable<KonkurrenzprogrammDto[]> {
    return this.http.post<KonkurrenzprogrammDto[]>(
      this.apiUrl + `Konkurrenzprogramm/ByAnsichten`,
      ansichtenIds,
    );
  }

  getKonkurrenzprogrammeByYear$(year: number): Observable<KonkurrenzprogrammDto[]> {
    return this.http.get<KonkurrenzprogrammDto[]>(
      this.apiUrl + `Konkurrenzprogramm/ByYear/${year}`,
    );
  }
}
