import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { PublitPermissionDto } from "src/app/models/openapi/model/publit-permission-dto";

export const permissionActions = createActionGroup({
  source: "Permissions API",
  events: {
    loadPermissions: emptyProps(),
    loadPermissionsSuccess: props<{
      permissions: PublitPermissionDto[];
    }>(),
    loadPermissionsFailure: emptyProps(),
  },
});
