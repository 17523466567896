<div
  *ngIf="{
    ansichtenViewModels: ansichtenViewModels$ | async,
    years: years$ | async,
    kanal: Kanal.ZDF
  } as vm"
  class="dashboard-wrapper"
>
  <ng-container *ngIf="vm.years && vm.years.length > 0; else noData">
    <div
      *ngFor="let year of vm.years; let index = index"
      #years
      [attr.data-testid]="'year-' + year"
    >
      <div class="button-container">
        <div>
          <span class="dashboard-year">{{ year }}</span>
          <kendo-button
            class="kalenderansicht-button"
            fillMode="solid"
            themeColor="primary"
            (click)="gotoAnsicht(year, 'Jahresansicht', vm.ansichtenViewModels)"
          >
            Jahresansicht
          </kendo-button>
        </div>
        <kendo-button [svgIcon]="Icons.download" (click)="downloadMengengeruesteintraege(year)">
          MENGENGERÜSTE
        </kendo-button>
      </div>

      <kendo-tilelayout [columns]="10" [rowHeight]="40">
        <kendo-tilelayout-item class="dashboard-header" [col]="2" [row]="1">
          <span>Mo</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="3" [row]="1">
          <span>Di</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="4" [row]="1">
          <span>Mi</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="5" [row]="1">
          <span>Do</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="6" [row]="1">
          <span>Fr</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="7" [row]="1">
          <span>Sa</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="8" [row]="1">
          <span>So</span>
        </kendo-tilelayout-item>
      </kendo-tilelayout>

      <kendo-tilelayout [columns]="10" [rowHeight]="40">
        <kendo-tilelayout-item class="dashboard-row-header" [col]="1" [row]="1" [rowSpan]="4">
          <span>Daytime</span>
        </kendo-tilelayout-item>

        <app-dashboard-ansicht
          title="Sa Morgen"
          [ansichtViewModel]="'Sa Morgen' | ansichtViewmodelForDashboard: year : vm.kanal | async"
          [position]="{ row: 1, col: 7, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="So Morgen"
          [ansichtViewModel]="'So Morgen' | ansichtViewmodelForDashboard: year : vm.kanal | async"
          [position]="{ row: 1, col: 8, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Samstag Daytime"
          [ansichtViewModel]="
            'Samstag Daytime' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 2, col: 7, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Sonntag Daytime"
          [ansichtViewModel]="
            'Sonntag Daytime' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 2, col: 8, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Serien 18.00"
          [ansichtViewModel]="
            'Serien 18.00' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 3, col: 2, rowSpan: 1, colSpan: 6 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Serien 19.25"
          [ansichtViewModel]="
            'Serien 19.25' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 4, col: 3, rowSpan: 1, colSpan: 5 }"
        ></app-dashboard-ansicht>
      </kendo-tilelayout>

      <kendo-tilelayout [columns]="10" [rowHeight]="200">
        <kendo-tilelayout-item class="dashboard-row-header" [col]="1" [row]="1">
          <span>Primetime</span>
        </kendo-tilelayout-item>

        <app-dashboard-ansicht
          title="Fernsehfilm der Woche"
          [ansichtViewModel]="
            'Fernsehfilm der Woche' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 2, rowSpan: 1, colSpan: 1 }"
          [schemaplaetze]="['20.15']"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Dienstag 20.15"
          [ansichtViewModel]="
            'Dienstag 20.15f' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 3, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Mittwoch 20.15"
          [ansichtViewModel]="
            'Mittwoch 20.15' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 4, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Donnerstag 20.15"
          [ansichtViewModel]="
            'Donnerstag 20.15' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 5, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Freitagabend Serie"
          [ansichtViewModel]="
            'Freitagabend Serie' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 6, rowSpan: 1, colSpan: 1 }"
          [schemaplaetze]="['20.15', '21.15']"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Wochenende Primetime"
          [ansichtViewModel]="
            'Wochenende Primetime' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 7, rowSpan: 1, colSpan: 2 }"
          [schemaplaetze]="['Sa 20.15', 'Sa 21.45', 'So 20.15']"
        ></app-dashboard-ansicht>
      </kendo-tilelayout>

      <kendo-tilelayout [columns]="10" [rowHeight]="200">
        <kendo-tilelayout-item class="dashboard-row-header" [col]="1" [row]="1">
          <span>Primetime 2</span>
        </kendo-tilelayout-item>

        <app-dashboard-ansicht
          title="Sonntag / Montag 22.15"
          [ansichtViewModel]="
            'Sonntag / Montag 22.15' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 2, rowSpan: 1, colSpan: 1 }"
          [schemaplaetze]="['So 22.15', 'Mo 22.15']"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Dienstag 2. Primetime"
          [ansichtViewModel]="
            'Dienstag 2. Primetime' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 3, rowSpan: 1, colSpan: 1 }"
          [schemaplaetze]="['22.15']"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Mittwoch 2. Primetime"
          [ansichtViewModel]="
            'Mittwoch 2. Primetime' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 4, rowSpan: 1, colSpan: 1 }"
          [schemaplaetze]="['22.15']"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Donnerstag 2. Primetime"
          [ansichtViewModel]="
            'Donnerstag 2. Primetime' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 5, rowSpan: 1, colSpan: 1 }"
          [schemaplaetze]="['22.15', '23.15']"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Freitag 2. Primetime"
          [ansichtViewModel]="
            'Freitag 2. Primetime' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 6, rowSpan: 1, colSpan: 1 }"
          [schemaplaetze]="['22.00', '22.30', '23.00', '00.30']"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Sonntag / Montag 22.15"
          [ansichtViewModel]="
            'Sonntag / Montag 22.15' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 8, rowSpan: 1, colSpan: 1 }"
          [schemaplaetze]="['So 22.15', 'Mo 22.15']"
        ></app-dashboard-ansicht>
      </kendo-tilelayout>

      <kendo-tilelayout [columns]="10" [rowHeight]="40">
        <app-dashboard-ansicht
          title="Kleines Fernsehspiel"
          [ansichtViewModel]="
            'Kleines Fernsehspiel' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 2, rowSpan: 2, colSpan: 1 }"
          [schemaplaetze]="['00.10']"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Spielfilmnacht"
          [ansichtViewModel]="
            'Spielfilmnacht' | ansichtViewmodelForDashboard: year : vm.kanal | async
          "
          [position]="{ row: 1, col: 7, rowSpan: 2, colSpan: 1 }"
          [schemaplaetze]="['00.30']"
        ></app-dashboard-ansicht>
      </kendo-tilelayout>
    </div>
  </ng-container>
  <ng-template #noData>
    <app-authorization-failed></app-authorization-failed>
  </ng-template>
</div>
