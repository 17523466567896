<div
  *ngIf="{
    ansichtenViewModels: ansichtenViewModels$ | async,
    years: years$ | async,
    kanal: Kanal.ZDF_NEO
  } as vm"
  class="dashboard-wrapper"
>
  <ng-container *ngIf="vm.years && vm.years.length > 0; else noData">
    <div
      *ngFor="let year of vm.years; let index = index"
      #years
      [attr.data-testid]="'year-' + year"
    >
      <div class="button-container">
        <div>
          <span class="dashboard-year">{{ year }}</span>
        </div>
      </div>
      <kendo-tilelayout [columns]="10" [rowHeight]="40">
        <kendo-tilelayout-item class="dashboard-header" [col]="2" [row]="1">
          <span>Mo</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="3" [row]="1">
          <span>Di</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="4" [row]="1">
          <span>Mi</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="5" [row]="1">
          <span>Do</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="6" [row]="1">
          <span>Fr</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="7" [row]="1">
          <span>Sa</span>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="dashboard-header" [col]="8" [row]="1">
          <span>So</span>
        </kendo-tilelayout-item>
      </kendo-tilelayout>

      <kendo-tilelayout [columns]="10" [rowHeight]="40">
        <kendo-tilelayout-item class="dashboard-row-header" [col]="1" [row]="1" [rowSpan]="4">
          <span>Daytime</span>
        </kendo-tilelayout-item>
      </kendo-tilelayout>

      <kendo-tilelayout [columns]="10" [rowHeight]="200">
        <kendo-tilelayout-item class="dashboard-row-header" [col]="1" [row]="1">
          <span>Primetime</span>
        </kendo-tilelayout-item>

        <kendo-tilelayout-item class="dashboard-row-header" [col]="1" [row]="2">
          <span>Nacht</span>
        </kendo-tilelayout-item>

        <app-dashboard-ansicht
          title="Dienstag 20.15ff"
          [ansichtViewModel]="'Di 20.15ff' | ansichtViewmodelForDashboard: year : vm.kanal | async"
          [position]="{ row: 1, col: 3, rowSpan: 2, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Mittwoch 20.15f"
          [ansichtViewModel]="'Mi 20.15f' | ansichtViewmodelForDashboard: year : vm.kanal | async"
          [position]="{ row: 1, col: 4, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Donnerstagnacht"
          [ansichtViewModel]="'Do Nacht' | ansichtViewmodelForDashboard: year : vm.kanal | async"
          [position]="{ row: 2, col: 5, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Freitagnacht"
          [ansichtViewModel]="'Fr Nacht' | ansichtViewmodelForDashboard: year : vm.kanal | async"
          [position]="{ row: 2, col: 6, rowSpan: 1, colSpan: 1 }"
        ></app-dashboard-ansicht>

        <app-dashboard-ansicht
          title="Sonntag 20.15ff"
          [ansichtViewModel]="'So 20.15ff' | ansichtViewmodelForDashboard: year : vm.kanal | async"
          [position]="{ row: 1, col: 8, rowSpan: 2, colSpan: 1 }"
        ></app-dashboard-ansicht>
      </kendo-tilelayout>
    </div>
  </ng-container>
  <ng-template #noData>
    <app-authorization-failed></app-authorization-failed>
  </ng-template>
</div>
