/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Eine Merkliste kann in verschiedenen Bereichen der Anwendung vorkommen.  Auf einer Ansicht hat sich durch die AnsichtId einen direkten Bezug zur Ansicht.  Im OnDemand Bereich gibt es keine Ansichten, da die angezeigten OnDemand Planungsobjekte über  einenen Filter/Recherche ausgewählt werden.
 */
export type MerklisteKategorie = "Unbekannt" | "Ansicht" | "Ausspielweg" | "OnDemand";

export const MerklisteKategorie = {
  UNBEKANNT: "Unbekannt" as MerklisteKategorie,
  ANSICHT: "Ansicht" as MerklisteKategorie,
  AUSSPIELWEG: "Ausspielweg" as MerklisteKategorie,
  ON_DEMAND: "OnDemand" as MerklisteKategorie,
};
