import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, filter, map, shareReplay, take } from "rxjs";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { ekWindowActions } from "src/app/core/stores/ek-window/ek-window.actions";
import { GetitWindowInput } from "src/app/core/stores/getit-window/getit-model";
import { getitWindowActions } from "src/app/core/stores/getit-window/getit-window.actions";
import mehrfachauswahlSelectors from "src/app/core/stores/mehrfachauswahl/mehrfachauswahl.selectors";
import { multiansichtActions } from "src/app/core/stores/multiansicht/multiansicht.actions";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { sendeplatzWindowActions } from "src/app/core/stores/sendeplatz/sendeplatz.window.actions";
import { sidebarFeature } from "src/app/core/stores/sidebar/sidebar.reducer";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import {
  AnsichtViewModel,
  MultiAnsichtViewModel,
} from "src/app/models/viewmodels/ansicht-viewmodel";
import { PlanungsobjektMoveViewModel } from "src/app/models/viewmodels/planungsobjekt-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import { MengengeruestService } from "src/app/services/mengengeruest.service";
import { PharosWindowService } from "src/app/services/pharos-window.service";
import { PlanungshinweiseService } from "src/app/services/planungshinweise.service";
import { PlanungsobjektInteraktionService } from "src/app/services/planungsobjekt-interaktion.service";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import { PlanungsobjektAcceptWindowInput } from "src/app/shared/windows/planungsobjekt-accept-window/planungsobjekt-accept-window.component";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";
import { isDefined } from "src/app/utils/object-utils";

@Injectable()
export default class AnsichtenFacade {
  private readonly store = inject(Store);
  private readonly planungsobjektService = inject(PlanungsobjektService);
  private readonly planungshinweiseService = inject(PlanungshinweiseService);
  private readonly pharosWindowService = inject(PharosWindowService);
  private readonly mengengeruestService = inject(MengengeruestService);
  private readonly planungsobjektInteraktionService = inject(PlanungsobjektInteraktionService);

  public readonly ansichtYearDefinition$ = this.store.select(
    ansichtSelectors.selectAnsichtYearDefinition,
  );

  public readonly displaysMultipleYears$ = this.ansichtYearDefinition$.pipe(
    map((ansichtYearDefinition) => ansichtYearDefinition?.visibleYears.length > 1),
  );

  public readonly hasPreviousAnsicht$ = this.ansichtYearDefinition$.pipe(
    map(
      (ansichtYearDefinition) =>
        ansichtYearDefinition.visibleYears[0] - 1 >= ansichtYearDefinition.possibleYears[0],
    ),
  );

  public readonly hasNextAnsicht$ = this.ansichtYearDefinition$.pipe(
    map((ansichtYearDefinition) => [
      ansichtYearDefinition.visibleYears,
      ansichtYearDefinition.possibleYears,
    ]),
    map(
      ([visibleYears, possibleYears]) =>
        visibleYears[visibleYears.length - 1] + 1 <= possibleYears[possibleYears.length - 1],
    ),
  );

  public readonly planungsobjekteInMehrfachauswahl$ = this.store.select(
    mehrfachauswahlSelectors.selectPlanungsobjekteFromIdsInMehrfachauswahl,
  );

  public readonly showKonkurrenzEvents$ = this.store.select(
    sidebarFeature.selectShowKonkurrenzEvents,
  );

  readonly selectedAnsichtViewModel$ = this.store.select(
    ansichtSelectors.selectAnsichtViewModelForAnsichtIdFromQueryParam,
  );

  public readonly isMengengeruestErlaubt$ = this.selectedAnsichtViewModel$.pipe(
    filter(isDefined),
    map((a) => a.ansichtsdefinition.isMengengeruestErlaubt),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  movePlanungsobjektInAnsicht(
    event: PlanungsobjektMoveViewModel,
    multiAnsichtViewModel: MultiAnsichtViewModel,
  ) {
    combineLatest([
      this.planungsobjekteInMehrfachauswahl$,
      this.planungsobjektInteraktionService.isMultipleDragging$,
      this.ansichtYearDefinition$,
    ])
      .pipe(take(1))
      .subscribe(
        ([planungsobjekteInMehrfachauswahl, isDraggingMultiple, ansichtYearDefinition]) => {
          const planungsobjekteToMove = isDraggingMultiple
            ? planungsobjekteInMehrfachauswahl
            : [event.planungsobjekt];

          if (planungsobjekteToMove.length > 1 && event.oldSendeplatz) {
            const { planungsobjekte, daysOffset } =
              this.planungsobjektService.movePlanungsobjekteToSendeplatz(
                planungsobjekteToMove,
                event.newSendeplatz,
                event.oldSendeplatz,
                multiAnsichtViewModel,
                ansichtYearDefinition,
              );
            // Dieser Fall kann bspw. auftreten, wenn zusätzlich eine Planungsobjekt aus der Merkliste oder Blockansicht
            // in der Listen/Kalenderansicht ausgewählt wurde. In diesem Fall wird eine Fehlernachricht für den Nutzer
            // angezeigt und eine leere Liste zurückgeliefert.
            if (!planungsobjekte.length) return;

            this.store.dispatch(
              planungsobjektActions.moveMultiplePlanungsobjekte({
                command: { daysOffset, planungsobjekte },
              }),
            );
          } else {
            const planungsobjekt = planungsobjekteToMove[0];
            if (planungsobjekt.planungskontext === Planungskontext.VORGESCHLAGEN) {
              this.store.dispatch(
                planungsobjektActions.movePlanungsobjektVorgeschlagenToVorgeplant({
                  sendeplatz: event.newSendeplatz,
                  planungsobjektId: planungsobjekt.id,
                }),
              );
            } else {
              this.store.dispatch(
                planungsobjektActions.movePlanungsobjekt({
                  planungsobjektId: planungsobjekt.id,
                  sendeplatz: event.newSendeplatz,
                }),
              );
            }
          }
        },
      );
  }

  movePlanungsobjektInVorschlagspalte(event: {
    newSendeplatz: SendeplatzDto;
    planungsobjekt: PlanungsobjektDto;
  }) {
    this.planungsobjektService.movePlanungsobjektToVorgeschlagen(
      event.newSendeplatz,
      event.planungsobjekt,
    );
  }

  openNachPharosUebertragenWindow(sendeplatz: SendeplatzDto) {
    this.pharosWindowService.onOpenNachPharosUebertragenWindow(sendeplatz);
  }

  openPlanungsobjektAcceptWindow(windowInput: PlanungsobjektAcceptWindowInput) {
    this.store.dispatch(
      planungsobjektWindowActions.openLinearOnDemandAcceptWindow({ windowInput }),
    );
  }

  openCreateEkWindow(sendeplatz?: SendeplatzDto) {
    let defaultDate: Date | null = null;
    if (sendeplatz) {
      defaultDate = KanalOffsetUtils.getDateWithTagesgrenze(
        DateFnsService.parseDateAndTimeToDateObject(sendeplatz.beginnzeit, sendeplatz.sendetag),
        sendeplatz.kanal,
      );
    }

    this.store.dispatch(
      ekWindowActions.openEkWindow({ input: { type: "Create EK", defaultDate } }),
    );
  }

  openGetitWindow(event: GetitWindowInput): void {
    this.store.dispatch(getitWindowActions.openGetitWindow({ windowInputs: event }));
  }

  openPlanungshinweiseWindow($event: { kanal: Kanal; start: Date; end: Date }) {
    this.planungshinweiseService.openPlanungshinweiseWindow($event.start, $event.end, $event.kanal);
  }

  openSendeplatzWindow(sendeplatz: SendeplatzDto) {
    this.store.dispatch(sendeplatzWindowActions.openSendeplatzWindow({ sendeplatz }));
  }

  // TODO: in eigene Komponente auslagern
  openMengengeruesteWindow(ansichtViewModel: AnsichtViewModel) {
    this.mengengeruestService
      .openMengengeruesteWindow(ansichtViewModel)
      .result.pipe(take(1))
      .subscribe();
  }

  public loadPreviousAnsicht() {
    this.loadNewAnsicht(-1);
  }

  public loadNextAnsicht() {
    this.loadNewAnsicht(1);
  }

  private loadNewAnsicht(yearOffset: number) {
    this.ansichtYearDefinition$
      .pipe(
        map((ansichtYearDefinition) => {
          let newYearToLoad =
            yearOffset < 0
              ? ansichtYearDefinition.visibleYears[0]
              : ansichtYearDefinition.visibleYears[ansichtYearDefinition.visibleYears.length - 1];
          newYearToLoad += yearOffset;
          return newYearToLoad;
        }),
        take(1),
      )
      .subscribe((newYearToLoad) => {
        this.store.dispatch(
          multiansichtActions.updateAnsichtInMultiansicht({ year: newYearToLoad }),
        );
      });
  }
}
