import { createFeature, createReducer, on } from "@ngrx/store";
import { FeatureKey } from "../feature.keys";
import { shellActions } from "./shell.actions";

export interface ShellState {
  pendingRequests: number;
}

const initialState: ShellState = {
  pendingRequests: 0,
};

export const shellFeature = createFeature({
  name: FeatureKey.Shell,
  reducer: createReducer(
    initialState,
    on(shellActions.requestStarted, (currentState): ShellState => {
      return { ...currentState, pendingRequests: currentState.pendingRequests + 1 };
    }),
    on(shellActions.requestCompleted, (currentState): ShellState => {
      return { ...currentState, pendingRequests: currentState.pendingRequests - 1 };
    }),
  ),
});
