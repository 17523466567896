import { createFeature, createReducer, on } from "@ngrx/store";
import { VariantenzeileDto } from "src/app/models/openapi/model/variantenzeile-dto";
import { PlanungsobjektWindowInput } from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { onDemandBeziehungFormActions } from "src/app/shared/windows/planungsobjekt-window/verlinkung-tab/on-demand-beziehung-form/on-demand-beziehung-form.actions";
import { planungsobjektWindowWannWoActions } from "./planungsobjekt-window-wann-wo.actions";
import {
  PlanlaengenProperties,
  extractPlanlaengenPropertiesFromWindowInput,
} from "./planungsobjekt-window-wann-wo.utils";
import { planungsobjektWindowActions } from "./planungsobjekt-window.actions";
import {
  PlanungsobjektWindowOnDemandForm,
  PlanungsobjektWindowPlanungForm,
  PlanungsobjektWindowTabEnum,
} from "./planungsobjekt-window.model";

export interface PlanungsobjektWindowState {
  input: PlanungsobjektWindowInput | null;

  formValue: PlanungsobjektWindowPlanungForm | null;
  /**
   * Speichert die Werte der PlanlaengenProperties, bevor sie geändert wurden.
   * Ist nur für die Planlängenhelper Logik relevant.
   */
  previousPlanlaengenProperties: PlanlaengenProperties | null;
  onDemandFormValue: PlanungsobjektWindowOnDemandForm | null;
  onDemandToggleFormValue: {
    wunschOnlineAbVsRelationalZuLinearToggle: boolean;
    wunschOnlineBisVsVerweildauerToggle: boolean;
  };
  /**
   * Die PlanungsobjektWindowOnDemandForm Properties entsprechen den benötigten Properties für einen
   * Vorher / Nachher Vergleich, um zu prüfen, ob sich die Werte geändert haben.
   */
  previousOnDemandFormProperties: PlanungsobjektWindowOnDemandForm | null;
  selectedTab: PlanungsobjektWindowTabEnum;
  varianten: VariantenzeileDto[];
  loadingVariants: boolean;
  /**
   * Flag, das angibt, ob das Planungsobjekt-Formular dirty ist.
   * Wird wieder pristine, wenn der Benutzer den Dialog zum Zwischenspeichern bestätigt hat. Das eigentliche Form setzen wir nicht auf pristine
   */
  isPlanungFormDirty: boolean;
}

export const initialPlanungsobjektWindowState: PlanungsobjektWindowState = {
  input: null,
  formValue: null,
  onDemandFormValue: null,
  previousPlanlaengenProperties: null,
  previousOnDemandFormProperties: null,
  selectedTab: PlanungsobjektWindowTabEnum.PLANUNG,
  varianten: [],
  loadingVariants: false,
  onDemandToggleFormValue: {
    wunschOnlineAbVsRelationalZuLinearToggle: false,
    wunschOnlineBisVsVerweildauerToggle: false,
  },
  isPlanungFormDirty: false,
};

export const planungsobjektWindowFeature = createFeature({
  name: "Planungsobjekt Window",
  reducer: createReducer(
    initialPlanungsobjektWindowState,
    on(
      planungsobjektWindowActions.openPlanungsobjektWindow,
      planungsobjektWindowActions.openPlanungsobjektWindowReadonlyForPlanungsobjekt,
      (state, { input }): PlanungsobjektWindowState => ({
        ...initialPlanungsobjektWindowState,
        input,
        formValue: null,
        previousPlanlaengenProperties: extractPlanlaengenPropertiesFromWindowInput(input),
        varianten: [],
      }),
    ),
    on(
      planungsobjektWindowActions.setPlanungsobjektWindowPlanungForm,
      (state, { formValue, isPlanungFormDirty }): PlanungsobjektWindowState => ({
        ...state,
        formValue,
        isPlanungFormDirty: isPlanungFormDirty === false ? false : true,
      }),
    ),
    on(
      planungsobjektWindowActions.changePlanungsobjektWindowTab,
      (state, { newTab }): PlanungsobjektWindowState => ({ ...state, selectedTab: newTab }),
    ),
    on(
      planungsobjektWindowActions.confirmPlanungsobjektSaveDialog,
      (state): PlanungsobjektWindowState => ({
        ...state,
        isPlanungFormDirty: false,
      }),
    ),
    on(
      onDemandBeziehungFormActions.resetOnDemandBeziehungFormValues,
      (state): PlanungsobjektWindowState => ({
        ...state,
        onDemandFormValue: initialPlanungsobjektWindowState.onDemandFormValue,
        previousOnDemandFormProperties:
          initialPlanungsobjektWindowState.previousOnDemandFormProperties,
        onDemandToggleFormValue: initialPlanungsobjektWindowState.onDemandToggleFormValue,
      }),
    ),
    on(
      planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenDebounced,
      (state): PlanungsobjektWindowState => ({
        ...state,
        loadingVariants: true,
      }),
    ),
    on(
      planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenSuccess,
      (state, { variantenzeilen }): PlanungsobjektWindowState => ({
        ...state,
        varianten: variantenzeilen,
        loadingVariants: false,
      }),
    ),
    on(
      planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenFailure,
      (state): PlanungsobjektWindowState => ({
        ...state,
        loadingVariants: false,
      }),
    ),
    on(
      planungsobjektWindowWannWoActions.updateWannBezugValueSuccess,
      (state, { wannBezugForm }): PlanungsobjektWindowState => ({
        ...state,
        formValue: state.formValue ? { ...state.formValue, ...wannBezugForm } : state.formValue,
        previousPlanlaengenProperties: wannBezugForm,
      }),
    ),
    on(
      onDemandBeziehungFormActions.setLinearOnDemandBeziehungForm,
      (state, { onDemandFormValue }): PlanungsobjektWindowState => ({
        ...state,
        previousOnDemandFormProperties: state.onDemandFormValue,
        onDemandFormValue,
      }),
    ),
    on(
      onDemandBeziehungFormActions.setLinearOnDemandToggleForm,
      (state, { toggleFormValue }): PlanungsobjektWindowState => ({
        ...state,
        onDemandToggleFormValue: toggleFormValue,
      }),
    ),
    on(
      onDemandBeziehungFormActions.updateOnDemandBeziehungFormValuesSuccess,
      (state, { onDemandFormValue }): PlanungsobjektWindowState => ({
        ...state,
        // onDemandFormValue: state.onDemandFormValue // Warum?
        //   ? { ...state.onDemandFormValue, ...onDemandFormValue }
        //   : state.onDemandFormValue,
        onDemandFormValue,
        previousOnDemandFormProperties: onDemandFormValue,
      }),
    ),
    on(
      onDemandBeziehungFormActions.getInitialOnDemandBeziehungFormDataSuccess,
      (state, { previousOnDemandFormProperties }): PlanungsobjektWindowState => ({
        ...state,
        onDemandFormValue: previousOnDemandFormProperties,
        previousOnDemandFormProperties,
        // Workaround, damit initial die Toggles korrekt gesetzt werden
        onDemandToggleFormValue: {
          wunschOnlineAbVsRelationalZuLinearToggle:
            // Check auf !== null, da 0 auch ein valider Wert ist
            previousOnDemandFormProperties.relationZuLinearInTagen !== null ||
            state.input?.planungskontext === "Vorgemerkt",
          wunschOnlineBisVsVerweildauerToggle:
            (!!previousOnDemandFormProperties.verweildauerInTagen &&
              // Verweildauer muss fachlich mindestens 1 Tag sein
              previousOnDemandFormProperties.verweildauerInTagen >= 1) ||
            state.input?.planungskontext === "Vorgemerkt",
        },
      }),
    ),
    on(
      planungsobjektWindowActions.patchPlanungsobjektWindowPlanungForm,
      (state, { formValue }): PlanungsobjektWindowState => ({
        ...state,
        // Yes Typescript, if we spread an A and a Partial<A> we will in fact get an A
        formValue: <PlanungsobjektWindowPlanungForm>{
          ...state.formValue,
          ...formValue,
        },
      }),
    ),
    // Sollte nicht vorkommen
    on(
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenFailure,
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenFailure,
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenFailure,
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenFailure,
      (state): PlanungsobjektWindowState => {
        throw new Error(
          "LinearOnDemand-Beziehung konnte nicht erstellt/aktualisiert werden. Wurde versucht beim Anlegen des Planungsobjekts die Beziehung zu erstellen/anzupassen? ",
        );
      },
    ),
  ),
});
