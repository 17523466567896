import { Component, HostBinding, Input } from "@angular/core";
import { SVGIcon } from "@progress/kendo-svg-icons";
import { Icons } from "src/app/models/icons";
import { Kanal } from "src/app/models/openapi/model/kanal";

@Component({
  selector: "app-kanal-icons",
  templateUrl: "./kanal-icons.component.html",
  styleUrls: ["./kanal-icons.component.scss"],
})
export class KanalIconsComponent {
  @Input({ required: true }) kanal: Kanal;
  @Input() tooltip?: string;

  Icons = Icons.kanalIcons;

  protected readonly iconsByKanal: Record<Kanal, SVGIcon> = {
    DreiSat: this.Icons.dreisat,
    ZDF: this.Icons.zdf,
    ZDFInfo: this.Icons.zdfInfoBlack,
    ZDFNeo: this.Icons.zdfNeoBlack,
    ZDFMediathek: this.Icons.zdfMediathek,
    Unbekannt: this.Icons.unbekannt, // Sollte eigentlich nicht vorkommen -> Fehler
  };

  /**
   * Setzt auf dem Host-Element (also auf <app-kanal-icons>) das title-Attribut,
   * welches als Tooltip dient.
   */
  @HostBinding("title")
  get title() {
    return this.tooltip ?? this.iconsByKanal[this.kanal].name;
  }
}
