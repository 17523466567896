<ng-container
  *ngIf="{
    variants: variants$ | async,
    loadingVariants: loadingVariants$ | async,
    isSendetagDateDisabled: isSendetagDateDisabled$ | async,
    validationErrors: facade.validationErrors$ | async
  } as vm"
>
  <div class="k-d-flex k-align-items-end k-gap-2">
    <app-form-field *ngIf="planungskontext !== Planungskontext.VORGEMERKT" label="Sendetag">
      <app-iso-dateonly
        data-testid="sendetag"
        formControlName="sendetag"
        [isDateDisabled]="
          usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
          usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT
            ? null
            : vm.isSendetagDateDisabled
        "
      ></app-iso-dateonly>
    </app-form-field>

    <app-form-field *ngIf="planungskontext !== Planungskontext.VORGEMERKT" label="Beginnzeit">
      <app-iso-timeonly
        clearable
        data-testid="beginnzeit"
        formControlName="beginnzeit"
      ></app-iso-timeonly>
    </app-form-field>

    <app-form-field *ngIf="planungskontext !== Planungskontext.VORGEMERKT" label="Endzeit">
      <app-iso-timeonly
        clearable
        data-testid="endzeit"
        formControlName="endzeit"
      ></app-iso-timeonly>
    </app-form-field>

    <app-masked-input
      formControlName="planlaenge"
      label="Planlänge (min:sek)"
      [dataTestId]="'planlaenge'"
    ></app-masked-input>

    <app-select
      *ngIf="
        planungskontext !== Planungskontext.VORGEMERKT &&
        (usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
          usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT)
      "
      class="variante-field"
      data-testid="variante"
      formControlName="variante"
      label="Variante"
      [loading]="vm.loadingVariants"
      [noDataText]="'Noch keine verfügbaren Varianten gefunden'"
      [options]="vm.variants"
    ></app-select>
  </div>
  @if (vm.validationErrors?.planungsobjektBlockansichtInTimeRange) {
    <kendo-formerror data-testid="formerror-timerange">
      Das einzuplanende Programm muss mindestens eine Minute im Zeitrahmen der Blockansicht liegen
    </kendo-formerror>
  }
  @if (
    vm.validationErrors?.endzeitVorBeginnzeitMitSendetagsgrenze;
    as endzeitVorBeginnzeitMitSendetagsgrenze
  ) {
    <kendo-formerror data-testid="formerror-endzeit-vor-beginnzeit">
      Die Endzeit muss nach der Beginnzeit oder der Tagesgrenze um
      {{ endzeitVorBeginnzeitMitSendetagsgrenze }} Uhr liegen
    </kendo-formerror>
  }
</ng-container>
