import { Pipe, PipeTransform } from "@angular/core";
import { ANSICHTEN_TITEL, AnsichtenTitel } from "src/app/models/enums/ansichten-titel";

@Pipe({
  name: "shortTitle",
})
export class TitleShortenerPipe implements PipeTransform {
  transform(title: string): string {
    const ansichtenTitel = title as AnsichtenTitel;
    return ANSICHTEN_TITEL[ansichtenTitel];
  }
}
