import { createSelector } from "@ngrx/store";
import { GUID } from "src/app/models";
import { sortAktivitaetEintraege, sortAktivitaeten } from "src/app/utils/sort-utils";
import planungsobjektWindowSelectors from "../planungsobjekt-window/planungsobjekt-window.selectors";
import { aktivitaetenFeature } from "./aktivitaeten.reducer";

const selectAktivitaetenByPlanungsobjekt = (planungsobjektId: GUID) =>
  createSelector(aktivitaetenFeature.selectAll, (aktivitaeten) => {
    // TODO-1520: Sortieren nach Datum
    return aktivitaeten.filter((aktivitaet) => aktivitaet.entityId === planungsobjektId);
  });

const selectAktivitaetenByPlanungsobjektInEditDialog = createSelector(
  aktivitaetenFeature.selectAll,
  planungsobjektWindowSelectors.selectPlanungsobjektId,
  (aktivitaeten, planungsobjektId) => {
    if (!aktivitaeten) {
      return [];
    }

    if (!planungsobjektId) {
      return [];
    }

    return aktivitaeten
      .filter((aktivitaet) => aktivitaet.entityId === planungsobjektId)
      .map((aktivitaet) => {
        // Zum Sortieren der Einträge benötigen wir eine Kopie des Objektes,
        // da dieses "freezed" ist und wir sonst weder ein neues Array zuweisen
        // also auch das existierende Array in-place sortieren können.
        const copy = { ...aktivitaet };
        copy.eintraege = [...copy.eintraege];
        copy.eintraege.sort(sortAktivitaetEintraege);
        return copy;
      })
      .sort(sortAktivitaeten);
  },
);

export default {
  selectAktivitaeten: aktivitaetenFeature.selectAll,
  selectAktivitaetenByPlanungsobjekt,
  selectAktivitaetenByPlanungsobjektInEditDialog,
};
