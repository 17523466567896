<ng-container
  *ngIf="{
    editieren: 'editieren' | able: ({ kanal: input.kanal } | asSubject: 'Planungshinweis')
  } as can"
>
  <ng-container *ngIf="{ planungshinweise: planungshinweise$ | async } as data">
    <kendo-grid
      [editService]="planungshinweisEditService"
      [kendoGridBinding]="data.planungshinweise"
      [kendoGridReactiveEditing]="createFormGroup"
      [loading]="{} | isLoading | async"
    >
      <kendo-grid-messages
        noRecords="Für den gegebenen Zeitraum sind keine Planungshinweise vorhanden."
      >
      </kendo-grid-messages>

      <ng-template kendoGridLoadingTemplate>
        <app-loading-dialog title=" "></app-loading-dialog>
      </ng-template>

      <ng-template kendoGridToolbarTemplate>
        <button
          data-testid="planungshinweise-neu"
          kendoGridAddCommand
          [disabled]="!can.editieren"
          [svgIcon]="Icons.addIcon"
        >
          Neu
        </button>
      </ng-template>

      <kendo-grid-column field="von" title="Von (Kalendertag)">
        <ng-template
          kendoGridEditTemplate
          let-column="column"
          let-formGroup="formGroup"
          let-isNew="isNew"
        >
          <app-iso-datetimepicker
            name="von"
            [formControl]="formGroup.get(column.field)"
          ></app-iso-datetimepicker>
          <kendo-formerror *ngIf="formGroup.get(column.field).errors?.dateBefore as dateBefore"
            >"Von" darf nicht nach "{{ dateBefore.otherLabel }}" liegen
          </kendo-formerror>
          <kendo-formerror *ngIf="formGroup.get(column.field).errors?.required"
            >"Von" ist ein Pflichtfeld
          </kendo-formerror>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.von | date: "dd.MM.yyyy HH:mm" }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="bis" title="Bis (Kalendertag)">
        <ng-template
          kendoGridEditTemplate
          let-column="column"
          let-formGroup="formGroup"
          let-isNew="isNew"
        >
          <app-iso-datetimepicker
            name="bis"
            [focusedDate]="formGroup.get(column.field)?.value ?? formGroup.get('von')?.value"
            [formControl]="formGroup.get(column.field)"
            [minDate]="formGroup.get('von')?.value"
          ></app-iso-datetimepicker>
          <kendo-formerror *ngIf="formGroup.get(column.field).errors?.dateAfter as dateAfter"
            >"Bis" darf nicht vor "{{ dateAfter.otherLabel }}" liegen
          </kendo-formerror>
          <kendo-formerror *ngIf="formGroup.get(column.field).errors?.required"
            >"Bis" ist ein Pflichtfeld
          </kendo-formerror>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.bis | date: "dd.MM.yyyy HH:mm" }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="status" title="Status">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <kendo-dropdownlist
            name="status"
            textField="text"
            valueField="value"
            [data]="PlanungshinweisStatusOptions"
            [formControl]="formGroup.get('status')"
            [valuePrimitive]="true"
          >
          </kendo-dropdownlist>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.status | enumBeschreibung: PlanungshinweisStatusRecord : false }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="notiz" title="Notiz">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <kendo-textarea
            data-testid="planungshinweise-notiz"
            name="notiz"
            resizable="auto"
            [formControl]="formGroup.get('notiz')"
          ></kendo-textarea>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column [width]="120">
        <ng-template kendoGridCellTemplate let-isNew="isNew">
          <button
            kendoGridEditCommand
            title="Bearbeiten"
            [disabled]="!can.editieren"
            [primary]="true"
            [svgIcon]="Icons.editIcon"
          ></button>
          <button
            kendoGridRemoveCommand
            title="Löschen"
            [disabled]="!can.editieren"
            [svgIcon]="Icons.trash"
          ></button>
          <button
            data-testid="planungshinweise-speichern"
            kendoGridSaveCommand
            title="Änderungen speichern"
            [disabled]="!can.editieren || formGroup.invalid"
            [primary]="true"
            [svgIcon]="Icons.checkIcon"
          ></button>
          <button
            kendoGridCancelCommand
            title="Änderungen verwerfen"
            [svgIcon]="Icons.cancel"
          ></button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>

    <ng-container *ngIf="debugService.showDebugData">
      <hr />
      <pre>{{ input | json }}</pre>
      <hr />
      <pre>{{ data.planungshinweise | json }}</pre>
    </ng-container>

    <div class="window-action-buttons">
      <button
        data-testid="planungshinweise-window-close"
        fillMode="outline"
        kendoButton
        type="button"
        (click)="onClose()"
      >
        Schließen
      </button>
    </div>
  </ng-container>
</ng-container>
