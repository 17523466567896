import { KeyValue } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  ViewChildren,
  inject,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { blockansichtActions } from "src/app/core/stores/blockansicht/blockansicht.actions";
import {
  DATE_COLUMN_WIDTH,
  GAP_COLUMN_WIDTH,
} from "src/app/core/stores/blockansicht/blockansicht.model";
import dragdropSelectors from "src/app/core/stores/dragdrop/dragdrop.selectors";
import { mehrfachauswahlFeature } from "src/app/core/stores/mehrfachauswahl/mehrfachauswahl.reducer";
import { ContextMenuItem, CustomContextMenuSelectEvent } from "src/app/models/context-menu";
import { AnsichtType } from "src/app/models/enums/ansicht-type";
import { KanalRecord } from "src/app/models/enums/kanal";
import { Icons } from "src/app/models/icons";
import { AnsichtsdefinitionDto } from "src/app/models/openapi/model/ansichtsdefinition-dto";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { DateFnsService } from "src/app/services/date-fns.service";
import { PlanungsobjektInteraktionService } from "src/app/services/planungsobjekt-interaktion.service";
import { TitleShortenerPipe } from "src/app/shared/pipes/title-format.pipe";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";
import { roundSecondsInDateToNextMinute } from "src/app/utils/time-utils";
import { PublitFrontendSettings } from "src/environments/environment";
import {
  BlockansichtDefinition,
  BlockansichtPillVM,
  BlockansichtRowDataVM,
  BlockansichtVarianteRowDataVM,
} from "../blockansicht-viewmodel";
import { BlockansichtDragDropService } from "../blockansicht.drag-drop.service";
import BlockansichtFacade from "../blockansicht.facade";
import { CellMenuCommand, RowMenuCommand, VpfMenuCommand } from "../blockansicht.model";

@Component({
  selector: "app-blockansicht-block",
  templateUrl: "./blockansicht-block.component.html",
  styleUrls: ["./blockansicht-block.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class BlockansichtBlockComponent implements AfterViewInit {
  public readonly facade = inject(BlockansichtFacade);

  @Input({ required: true }) blockansichtDefinition: BlockansichtDefinition;
  @Input({ required: true }) blockansichtDefinitionen: BlockansichtDefinition[];
  @Input() showNotizen: boolean;
  @Input() showKonkurrenzEvents: boolean;

  @ViewChildren("times") timeAnchors: QueryList<ElementRef<HTMLDivElement>>;

  get ansichtViewModel() {
    return this.blockansichtDefinition.ansichtViewModel;
  }

  get kanal() {
    return this.ansichtViewModel.kanal;
  }

  get ansichtYear() {
    return this.ansichtViewModel.year;
  }

  Icons = Icons;

  // Zusätzliche Anzahl an Spalten am Anfang und Ende der Blockansicht,
  // damit genügend Platz für das drag-to - select Rechteck bleibt.
  EXTRA_COLUMNS = 2;
  // Erste Spalte für den Sendetag
  FIRST_COLUMN = 1;

  mehrfachauswahlActive$ = this.store.select(mehrfachauswahlFeature.selectMehrfachauswahlActive);

  dateColumnWidth = DATE_COLUMN_WIDTH;
  gapColumnWidth = GAP_COLUMN_WIDTH;

  constructor(
    private titleFormat: TitleShortenerPipe,
    private titleService: Title,
    private store: Store,
    protected planungsobjektInteraktionService: PlanungsobjektInteraktionService,
    protected dragDropService: BlockansichtDragDropService,
    protected settings: PublitFrontendSettings,
  ) {}

  ngAfterViewInit(): void {
    const titel = this.titleFormat.transform(this.blockansichtDefinition.ansichtViewModel.titel);
    this.titleService.setTitle(
      `${KanalRecord[this.blockansichtDefinition.ansichtViewModel.kanal].bedeutung} ${this.ansichtYear} - ${titel}`,
    );
    this.scrollToTime(this.blockansichtDefinition.ansichtViewModel.ansichtsdefinition);
  }

  handleCreateVpf(event: {
    datumGruppe: KeyValue<string, BlockansichtRowDataVM>;
    variante: string;
    minuteOffset: number;
  }) {
    const sendetagPreset = DateFnsService.parseStringToDate(event.datumGruppe.key);
    const variantePreset = parseInt(event.variante);
    const kanalPreset = this.blockansichtDefinition.ansichtViewModel.kanal;
    const beginnzeitPreset = this.computeTimePresetWithVorgaengerPlanungsobjekt(
      event.minuteOffset,
      kanalPreset,
      sendetagPreset,
      event.datumGruppe.value.varianten[variantePreset].pillen.map((pill) => pill.planungsobjekt),
    );
    const beginnzeit = DateFnsService.formatDateAsTimeString(beginnzeitPreset);

    this.planungsobjektInteraktionService.clearSelectedPlanungsobjekt();

    this.facade.openCreatePlanungsobjektLinearWithPlanungWindow(
      event.datumGruppe.key,
      beginnzeit,
      variantePreset,
      this.blockansichtDefinition,
      this.blockansichtDefinitionen,
    );
  }

  /**
   * Berechnet die initiale Startzeit
   * anhand von vorausgehenden Planungsobjekte
   * @returns timePreset als `Date`-Objekt
   */
  private computeTimePresetWithVorgaengerPlanungsobjekt(
    minuteOffset: number,
    kanalPreset: Kanal,
    datePreset: Date,
    planungsobjekte: PlanungsobjektDto[],
  ): Date {
    const allEndzeitenOfPlanungsobjekteAsDateObjects = planungsobjekte
      .map((planungsobjekt) => {
        const beginnzeitAsDateObject = DateFnsService.parseDateAndTimeToDateObject(
          planungsobjekt.publikationsplanung.beginnzeit,
          datePreset,
        );

        const beginnzeitWithTagesgrenze = KanalOffsetUtils.getDateWithTagesgrenze(
          beginnzeitAsDateObject,
          kanalPreset,
        );

        const endzeit = DateFnsService.addSeconds(
          beginnzeitWithTagesgrenze,
          planungsobjekt.publikationsplanung.laenge,
        );

        return endzeit;
      })
      // Sonderfall, dass es mehrere Planungsobjekte in einem Block gibt,
      // damit dann die letzte Planungsobjekt zuerst unten bei `find()` gefunden wird
      .sort((date1, date2) => date2.getTime() - date1.getTime());

    const currentBlockDuration = this.blockansichtDefinition.minutesPerColumn;
    const beginnzeitBlockansicht = DateFnsService.parseDateAndTimeToDateObject(
      this.blockansichtDefinition.beginnzeit,
      datePreset,
    );
    const beginnzeitBlock = DateFnsService.addMinutes(beginnzeitBlockansicht, minuteOffset);
    const endzeitBlock = DateFnsService.addMinutes(beginnzeitBlock, currentBlockDuration);

    let endzeitOfLastPlanungsobjektInBlock = allEndzeitenOfPlanungsobjekteAsDateObjects.find(
      (endzeitDerPlanungsobjekt) =>
        DateFnsService.dateBetweenDates(endzeitDerPlanungsobjekt, beginnzeitBlock, endzeitBlock),
    );
    if (endzeitOfLastPlanungsobjektInBlock) {
      endzeitOfLastPlanungsobjektInBlock = roundSecondsInDateToNextMinute(
        endzeitOfLastPlanungsobjektInBlock,
      );
    }
    return KanalOffsetUtils.getDateWithTagesgrenze(
      endzeitOfLastPlanungsobjektInBlock ?? beginnzeitBlock,
      kanalPreset,
      true,
    );
  }

  handleClickVpf(planungsobjekt: PlanungsobjektDto, mehrfachauswahlActive: boolean) {
    this.planungsobjektInteraktionService.onPlanungsobjektClick(
      planungsobjekt,
      mehrfachauswahlActive,
    );
  }

  handleVpfContextMenuSelect(event: CustomContextMenuSelectEvent<ContextMenuItem<VpfMenuCommand>>) {
    const { data: command } = event.item;
    const {
      data: { vm },
    } = event.target as {
      data: {
        datumGruppe: KeyValue<string, BlockansichtRowDataVM>;
        vm: PlanungsobjektDto;
      };
    };
    switch (command) {
      case "details": {
        this.openPlanungsobjektLinearWindow(vm);
        break;
      }
      case "mengengeruest": {
        this.facade.openMengengeruestForPlanungsobjektDialog(vm, this.blockansichtDefinition);
        break;
      }
      case "kopieanlegen_thisweek": {
        this.facade.copyPlanungsobjekt({ planungsobjekt: vm, copyPattern: CopyPattern.NOW });
        // Muss hier leider manuell ausgeblendet werden, da sonst das Kontextmenü sich nicht automatisch ausblendet
        event.sender.hide();
        break;
      }
      case "kopieanlegen_nextweek": {
        this.facade.copyPlanungsobjekt({
          planungsobjekt: vm,
          copyPattern: CopyPattern.NEXT_WEEK,
        });
        break;
      }
      case "serie": {
        this.facade.openSerieWindow({ context: AnsichtType.Block, planungsobjekt: vm });
      }
    }
  }

  /**
   * Öffnet das Fenster zum Bearbeiten eines Planungsobjekts.
   * Explizite Methode, um das Fenster auch über das Template zu öffnen.
   */
  onBlockansichtChipDoubleClicked(planungsobjekt: PlanungsobjektDto) {
    this.openPlanungsobjektLinearWindow(planungsobjekt);
  }

  private openPlanungsobjektLinearWindow(planungsobjekt: PlanungsobjektDto) {
    this.facade.openUpdatePlanungsobjektLinearWithPlanungWindow(
      planungsobjekt,
      this.blockansichtDefinition,
      this.blockansichtDefinitionen,
    );
  }

  onHandleRowContextMenuSelect(
    event: CustomContextMenuSelectEvent<ContextMenuItem<RowMenuCommand>>,
  ) {
    const { data: command } = event.item;
    switch (command) {
      case "add_row": {
        // TODO Code so anpassen, dass ContextMenuTargetDirective genutzt wird
        const target = event.target as HTMLElement;
        const date = target.getAttribute("data-row-date");
        const maxVariante = Number(target.getAttribute("rowspan"));
        if (!date) throw new Error(`Kein Datum für Zeile gefunden: ${target.textContent}`);
        this.facade.addVarianteForDate(date, maxVariante);
        return;
      }
    }
  }

  handleCellContextMenuSelect(
    event: CustomContextMenuSelectEvent<ContextMenuItem<CellMenuCommand>>,
    datumGruppe: KeyValue<string, BlockansichtRowDataVM>,
    variante: string,
    minuteOffset: number,
  ) {
    const sendetagPreset = DateFnsService.parseStringToDate(datumGruppe.key);
    const variantePreset = parseInt(variante);
    const kanalPreset = this.kanal;
    const beginnzeitPreset = this.computeTimePresetWithVorgaengerPlanungsobjekt(
      minuteOffset,
      kanalPreset,
      sendetagPreset,
      datumGruppe.value.varianten[variantePreset].pillen.map((pill) => pill.planungsobjekt),
    );
    const beginnzeit = DateFnsService.formatDateAsTimeString(beginnzeitPreset);

    const { data: command } = event.item;
    switch (command) {
      case "create-verlinkung-details": {
        this.facade.openCreatePlanungsobjektLinearWithPlanungWindow(
          datumGruppe.key,
          beginnzeit,
          variantePreset,
          this.blockansichtDefinition,
          this.blockansichtDefinitionen,
        );
        break;
      }
      case "getit": {
        this.facade.openGetItWindow(datumGruppe, variantePreset, beginnzeit, kanalPreset);
        break;
      }
    }
  }

  scrollToTime(ansichtsdefinition: AnsichtsdefinitionDto) {
    if (ansichtsdefinition.fixpunkt) {
      const timeAnchor = this.timeAnchors.find(
        (el) => el.nativeElement.id === `time-${ansichtsdefinition.fixpunkt}`,
      );

      if (timeAnchor) {
        timeAnchor.nativeElement.scrollIntoView({
          // Scrollt soweit, dass Element links sichtbar ist
          inline: "start",
        });
      }
    }
  }

  onOpenPlanungshinweiseWindow(rowData: BlockansichtRowDataVM) {
    const kanal = this.kanal;
    this.facade.openPlanungshinweiseWindow({ kanal, start: rowData.start, end: rowData.end });
  }

  highlightSendetag$(sendetag: string) {
    return this.store.select(dragdropSelectors.selectHighlightSendeplatz(sendetag));
  }

  @HostListener("window:keydown", ["$event"])
  onKeydown(event: KeyboardEvent) {
    if (event.altKey) {
      // Shortcut: Reinzoomen (Alt+Plus)
      const zoomIn = event.key === "+";
      // Shortcut: Rauszoomen (Alt+Minus)
      const zoomOut = event.key === "-";

      if (zoomIn || zoomOut) {
        event.preventDefault();
        this.store.dispatch(blockansichtActions.setNextZoomLevel({ zoomIn }));
      }
    }
  }

  trackByDatumGruppe(index: number, item: KeyValue<string, BlockansichtRowDataVM>) {
    return item.key;
  }

  trackByVarianteGruppe(index: number, item: KeyValue<string, BlockansichtVarianteRowDataVM>) {
    return item.key;
  }

  trackByBlockansichtDto(index: number, item: BlockansichtPillVM) {
    return item.planungsobjekt.id;
  }

  trackByIndex(index: number) {
    return index;
  }
}
