import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Directive({
  selector: "[appTooltipIfEllipsis]",
})
export class TooltipIfEllipsisDirective implements AfterViewInit, OnChanges {
  @Input() appTooltipIfEllipsis: string;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    this.updateTooltip();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appTooltipIfEllipsis.currentValue !== changes.appTooltipIfEllipsis.previousValue) {
      this.updateTooltip();
    }
  }

  private updateTooltip() {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.offsetWidth < element.scrollWidth) {
        element.title = this.appTooltipIfEllipsis || element.innerHTML;
      }
    });
  }
}
