/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type PlanungsobjektBeziehungTyp = "Reihenfolge" | "Abhaengigkeit" | "LinearOnDemand";

export const PlanungsobjektBeziehungTyp = {
  REIHENFOLGE: "Reihenfolge" as PlanungsobjektBeziehungTyp,
  ABHAENGIGKEIT: "Abhaengigkeit" as PlanungsobjektBeziehungTyp,
  LINEAR_ON_DEMAND: "LinearOnDemand" as PlanungsobjektBeziehungTyp,
};
