/* tslint:disable */
/* eslint-disable */
/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Kanal = {
    UNBEKANNT: 'Unbekannt',
    ZDF: 'ZDF',
    ZDF_INFO: 'ZDFInfo',
    ZDF_NEO: 'ZDFNeo',
    DREI_SAT: 'DreiSat',
    ZDF_MEDIATHEK: 'ZDFMediathek'
} as const;
export type Kanal = typeof Kanal[keyof typeof Kanal];


export function KanalFromJSON(json: any): Kanal {
    return KanalFromJSONTyped(json, false);
}

export function KanalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Kanal {
    return json as Kanal;
}

export function KanalToJSON(value?: Kanal | null): any {
    return value as any;
}

