import { Prettify } from "@ngrx/store/src/models";
import { DeveloperError } from "src/app/models/errors/technical.error";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { DateFnsService } from "src/app/services/date-fns.service";
import { maxSecondsForMaskedInput } from "src/app/shared/form-inputs/masked-input/masked-input.component";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { NullableValues } from "src/app/utils/object-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import {
  PlanungsobjektWindowPlanungForm,
  WannBezugLinearFormData,
} from "./planungsobjekt-window.model";
import { isCreateWindow, isEditWindow, isLinearWindow } from "./planungsobjekt-window.utils";

export function calculatePlanlaengeValue(
  kanal: Kanal,
  sendetag: string,
  beginnzeit: string,
  endzeit: string,
  previousPlanlaenge: number | null,
) {
  const calculatedLaenge = PlanungsobjektUtils.updatePlanlaengeFromZeiten({
    sendetag,
    beginnzeit,
    endzeit,
    kanal,
  });

  const planlaengeSeconds = previousPlanlaenge ? previousPlanlaenge % 60 : 0;
  return calculatedLaenge + planlaengeSeconds;
}

export function calculateEndzeitValue(laengeInSeconds: number | null, beginnzeit: string) {
  if (laengeInSeconds === null) return null;
  const beginnzeitDate = DateFnsService.parseDateAndTimeToDateObject(beginnzeit);
  if (laengeInSeconds > 0 && beginnzeitDate) {
    const shouldRoundUp = laengeInSeconds % 60 > 0;
    let computedEndTime = DateFnsService.addSeconds(beginnzeitDate, laengeInSeconds);
    if (shouldRoundUp) {
      computedEndTime = DateFnsService.addMinutes(computedEndTime, 1);
    }
    return DateFnsService.formatDateAsTimeString(computedEndTime);
  }
  return null;
}

/**
 * Extrahiert die Werte für die PlanlaengenProperties aus dem WindowInput.
 * Beim Erstellen werden die Werte aus `WannPreset` übernommen.
 * Beim Bearbeiten werden die Werte aus dem Planungsobjekt übernommen.
 */
export function extractPlanlaengenPropertiesFromWindowInput(
  windowInput: PlanungsobjektWindowInputWithPlanungsobjekt,
): PlanlaengenProperties | null {
  if (isLinearWindow(windowInput)) {
    if (isEditWindow(windowInput)) {
      return {
        beginnzeit: windowInput.planungsobjekt.beginnzeit,
        endzeit: calculateEndzeitValue(
          windowInput.planungsobjekt.planlaenge,
          windowInput.planungsobjekt.beginnzeit,
        ),
        sendetag: windowInput.planungsobjekt.sendetag,
        planlaenge:
          windowInput.planungsobjekt.planlaenge ??
          windowInput.planungsobjekt.publikationsplanung?.sendeplatzLaenge ?? // Falls wir mit der Plus-Logik erstellt haben
          null,
      };
    }

    if (isCreateWindow(windowInput)) {
      return {
        beginnzeit: windowInput.wannPreset.beginnzeit,
        endzeit: null,
        sendetag: windowInput.wannPreset.sendetag,
        planlaenge:
          windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ
            ? windowInput.wannPreset.planlaenge
            : null,
      };
    }
  }

  return null;
}

export type PlanlaengenProperties = Prettify<
  NullableValues<Omit<WannBezugLinearFormData, "variante">>
>;

export function berechneSynchronisiertePlanlaengenWerte(opts: {
  prevFormValue: PlanlaengenProperties;
  nextFormValue: PlanlaengenProperties;
  kanal: Kanal;
}): PlanlaengenProperties | null {
  const foundDiff: (keyof PlanungsobjektWindowPlanungForm)[] = [];
  const throwIfMoreThanOneChange = (property: keyof PlanungsobjektWindowPlanungForm) => {
    if (foundDiff.length)
      throw new DeveloperError("Mehr als ein Feld wurde geändert", {
        foundDiff,
        property,
        prevFormValue,
        nextFormValue,
      });
    foundDiff.push(property);
  };
  const { prevFormValue, nextFormValue, kanal } = opts;
  const {
    sendetag: nextSendetag,
    beginnzeit: nextBeginnzeit,
    endzeit: nextEndzeit,
    planlaenge: nextPlanlaenge,
  } = nextFormValue;
  const {
    sendetag: prevSendetag,
    beginnzeit: prevBeginnzeit,
    endzeit: prevEndzeit,
    planlaenge: prevPlanlaenge,
  } = prevFormValue;

  // initialisiere mit vorherigen Werten
  const result: PlanlaengenProperties = {
    beginnzeit: nextBeginnzeit,
    endzeit: nextEndzeit,
    planlaenge: nextPlanlaenge,
    sendetag: nextSendetag,
  };

  // Sendetag geändert
  if (nextSendetag !== prevSendetag) {
    throwIfMoreThanOneChange("sendetag");
    if (!nextSendetag || !prevBeginnzeit) return null;
    if (prevPlanlaenge) {
      result.endzeit = calculateEndzeitValue(prevPlanlaenge, prevBeginnzeit);
    } else if (prevEndzeit) {
      result.planlaenge = calculatePlanlaengeValue(
        kanal,
        nextSendetag,
        prevBeginnzeit,
        prevEndzeit,
        prevPlanlaenge,
      );
    }
  }
  // Beginnzeit geändert
  if (nextBeginnzeit !== prevBeginnzeit) {
    throwIfMoreThanOneChange("beginnzeit");
    if (nextBeginnzeit) {
      if (prevSendetag && prevPlanlaenge) {
        result.endzeit = calculateEndzeitValue(prevPlanlaenge, nextBeginnzeit);
      }
    } else {
      result.endzeit = null;
    }
  }
  // Endzeit geändert
  if (nextEndzeit !== prevEndzeit) {
    throwIfMoreThanOneChange("endzeit");
    if (nextEndzeit && prevSendetag && prevBeginnzeit) {
      const planlaenge = calculatePlanlaengeValue(
        kanal,
        prevSendetag,
        prevBeginnzeit,
        nextEndzeit,
        prevPlanlaenge,
      );
      if (planlaenge <= maxSecondsForMaskedInput) {
        result.planlaenge = planlaenge;
      }
    }
  }
  // Planlänge geändert
  if (nextPlanlaenge !== prevPlanlaenge) {
    throwIfMoreThanOneChange("planlaenge");
    if (nextPlanlaenge && prevSendetag && prevBeginnzeit) {
      result.endzeit = calculateEndzeitValue(nextPlanlaenge, prevBeginnzeit);
    }
  }

  return foundDiff.length ? result : null;
}
