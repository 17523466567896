/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type Genre =
  | "KeinGenre"
  | "Nachrichten"
  | "Magazin"
  | "Reportage"
  | "Dokumentation"
  | "Talk"
  | "Fernsehfilm"
  | "Serie"
  | "Spielfilm"
  | "Dokumentarfilm"
  | "FactualEntertainment"
  | "Show"
  | "Buehne"
  | "ComedyKabarett"
  | "Sport"
  | "Ereignisuebertragung"
  | "Sonstiges"
  | "NochOffen";

export const Genre = {
  KEIN_GENRE: "KeinGenre" as Genre,
  NACHRICHTEN: "Nachrichten" as Genre,
  MAGAZIN: "Magazin" as Genre,
  REPORTAGE: "Reportage" as Genre,
  DOKUMENTATION: "Dokumentation" as Genre,
  TALK: "Talk" as Genre,
  FERNSEHFILM: "Fernsehfilm" as Genre,
  SERIE: "Serie" as Genre,
  SPIELFILM: "Spielfilm" as Genre,
  DOKUMENTARFILM: "Dokumentarfilm" as Genre,
  FACTUAL_ENTERTAINMENT: "FactualEntertainment" as Genre,
  SHOW: "Show" as Genre,
  BUEHNE: "Buehne" as Genre,
  COMEDY_KABARETT: "ComedyKabarett" as Genre,
  SPORT: "Sport" as Genre,
  EREIGNISUEBERTRAGUNG: "Ereignisuebertragung" as Genre,
  SONSTIGES: "Sonstiges" as Genre,
  NOCH_OFFEN: "NochOffen" as Genre,
};
