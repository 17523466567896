import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

const DEBUG_MENU = "DEBUG_MENU";

@Injectable({
  providedIn: "root",
})
export class DebugService {
  public showDebugMenu = false;

  private _showDebugData = false;
  public get showDebugData() {
    return this._showDebugData;
  }

  constructor(private httpClient: HttpClient) {
    this.showDebugMenu = this.isInDebugMode();
  }

  public toggleShowDebugData() {
    this._showDebugData = !this._showDebugData;
  }

  /**
   * Debug Optionen werden nur in der lokalen Entwicklung angeboten
   * oder wenn im Local Store der entsprechende Key gesetzt ist.
   */
  private isInDebugMode(): boolean {
    const runningLocal = window.location.host.indexOf("localhost") !== -1;
    const force = localStorage.getItem(DEBUG_MENU) === "1";
    return runningLocal || force;
  }

  public clearCache$() {
    return this.httpClient.delete("/api/tech/cache");
  }
}
