import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import {
  PlanungsobjektCreateInput,
  PlanungsobjektEditInput,
  PlanungsobjektLinearInput,
  PlanungsobjektOnDemandInput,
  PlanungsobjektWindowInput,
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
  planungsobjektWindowCreateUseCases,
  planungsobjektWindowEditUseCases,
  planungsobjektWindowLinearUseCases,
  planungsobjektWindowOnDemandUseCases,
  planungsobjektWindowReadUseCases,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { assertUnreachable } from "src/app/utils/function-utils";

/**
 * Extrahiert den Kanal aus dem übergebenen PlanungsobjektWindowInput.
 */
export function extractKanalFromWindowInput(input: PlanungsobjektWindowInputWithPlanungsobjekt) {
  switch (input.usecase) {
    case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
    case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
    case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
    case PlanungsobjektWindowUseCase.READONLY_LINEAR:
    case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
      return input.planungsobjekt.kanal;
    case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
    case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
    case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
      return input.kanal;
    default:
      assertUnreachable(input);
  }
}

export const planungsobjektFieldsFromGetit = [
  "getitId",
  "letzterGetitSync",
  "produktstatus",
  "fsk",
  "produkttitel",
  "produkttitelMultipart",
  "contentCommunities",
  "staffelnummer",
  "folgennummer",
  "gesamtfolgennummer",
  "inhaltsbeschreibung",
  "mitwirkende",
  "stofffuehrendeRedaktion",
  "genre",
] satisfies (keyof PlanungsobjektDto)[];

export type PlanungsobjektFieldsFromGetit = (typeof planungsobjektFieldsFromGetit)[number];

export const extractGetitFieldsFromPlanungsobjektDto = (
  planungsobjekt: PlanungsobjektDto,
): Pick<PlanungsobjektDto, PlanungsobjektFieldsFromGetit> => {
  return {
    getitId: planungsobjekt.getitId,
    letzterGetitSync: planungsobjekt.letzterGetitSync,
    produktstatus: planungsobjekt.produktstatus,
    fsk: planungsobjekt.fsk,
    produkttitel: planungsobjekt.produkttitel,
    produkttitelMultipart: planungsobjekt.produkttitelMultipart,
    contentCommunities: planungsobjekt.contentCommunities,
    staffelnummer: planungsobjekt.staffelnummer,
    folgennummer: planungsobjekt.folgennummer,
    gesamtfolgennummer: planungsobjekt.gesamtfolgennummer,
    inhaltsbeschreibung: planungsobjekt.inhaltsbeschreibung,
    mitwirkende: planungsobjekt.mitwirkende,
    stofffuehrendeRedaktion: planungsobjekt.stofffuehrendeRedaktion,
    genre: planungsobjekt.genre,
  };
};

export const isEditWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektEditInput => {
  return (planungsobjektWindowEditUseCases as string[]).includes(input.usecase);
};

export const isCreateWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektCreateInput => {
  return (planungsobjektWindowCreateUseCases as string[]).includes(input.usecase);
};

export const isReadOnlyWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektWindowInput => {
  return (planungsobjektWindowReadUseCases as string[]).includes(input.usecase);
};

export const isLinearWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektLinearInput => {
  return (planungsobjektWindowLinearUseCases as string[]).includes(input.usecase);
};

export const isOnDemandWindow = (
  input: PlanungsobjektWindowInput,
): input is PlanungsobjektOnDemandInput => {
  return (planungsobjektWindowOnDemandUseCases as string[]).includes(input.usecase);
};
