import { inject, Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, debounceTime, distinctUntilChanged, filter, map, of, switchMap } from "rxjs";
import { GetVariantenzeilenByPublikationKeyQuery } from "src/app/models/openapi/model/get-variantenzeilen-by-publikation-key-query";
import { BlockansichtService } from "src/app/pages/ansichten/blockansicht/blockansicht.service";
import { PlanungsobjektWindowUseCase } from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { allValuesDefined } from "src/app/utils/array-utils";
import { planungsobjektWindowWannWoActions } from "./planungsobjekt-window-wann-wo.actions";
import {
  berechneSynchronisiertePlanlaengenWerte,
  extractPlanlaengenPropertiesFromWindowInput,
} from "./planungsobjekt-window-wann-wo.utils";
import { planungsobjektWindowActions } from "./planungsobjekt-window.actions";
import { planungsobjektWindowFeature } from "./planungsobjekt-window.reducer";
import planungsobjektWindowSelectors from "./planungsobjekt-window.selectors";

@Injectable()
// Provider sollte PlanungsobjektWindowFormService enthalten
export class PlanungsobjektWindowWannWoEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly blockansichtService = inject(BlockansichtService);

  /**
   * Effekt, der die verfügbaren Variantenzeilen für das aktuell geöffnete Planungsobjekt
   * anhand der übergebenen Query lädt.
   */
  fetchAvailableVariantenzeilen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilen),
      switchMap(({ query }) =>
        this.blockansichtService.loadVariantenzeilen$(query).pipe(
          map((variantenzeilen) =>
            planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenSuccess({
              variantenzeilen,
            }),
          ),
          catchError((error: unknown) =>
            of(planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenFailure({ error })),
          ),
        ),
      ),
    );
  });

  planlaengenHelperForPlanungsobjektWindow$ = createEffect(() => {
    const isPlanlaengenhelperRequired$ = this.store
      .select(planungsobjektWindowSelectors.selectInput)
      .pipe(
        map(
          // Wird auf allen Ansichten außer OnDemand benötigt. Auf Merklisten auch nicht benötigt, da dort nur die Planlänge existiert
          (input) =>
            (input?.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ ||
              input?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
              input?.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
              input?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT) &&
            (input?.planungskontext === "Vorgeschlagen" || input?.planungskontext === "Vorgeplant"),
        ),
      );
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.setPlanungsobjektWindowPlanungForm),
      // nur Werte herausziehen, die für die Synchronisation relevant sind
      map(({ formValue: { sendetag, beginnzeit, endzeit, planlaenge } }) => ({
        sendetag,
        beginnzeit,
        endzeit,
        planlaenge,
      })),
      // dann nur weiter, wenn sich die relevanten Werte geändert haben
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
      // aktuellen Formularwert aus Store holen
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowFeature.selectPreviousPlanlaengenProperties),
        this.store.select(planungsobjektWindowSelectors.selectKanal),
        // kann erst hier unten hinzugefügt werden, da Typescript sonst Fehler geworfen hat
        // sonst wäre es sinnvoller, es direkt oben nach ofType abzufragen und zu filtern
        isPlanlaengenhelperRequired$,
      ]),
      filter(allValuesDefined),
      filter(
        ([_nextFromAction, _prevFromState, _kanal, isPlanlaengenhelperRequired]) =>
          isPlanlaengenhelperRequired,
      ),
      // neuen Wert aus Action und alten Wert aus Store vergleichen und evtl. synchronisieren
      map(([nextFromAction, prevFromState, kanal]) =>
        berechneSynchronisiertePlanlaengenWerte({
          kanal,
          nextFormValue: nextFromAction,
          prevFormValue: prevFromState,
        }),
      ),
      map((wannBezugForm) =>
        wannBezugForm
          ? planungsobjektWindowWannWoActions.updateWannBezugValueSuccess({ wannBezugForm })
          : planungsobjektWindowWannWoActions.noWannBezugChangesNecessary(),
      ),
    );
  });

  /**
   * Effekt, der die Aktualisierung der verfügbaren Varianten anstößt, wenn sich
   * die Werte für die Wann-Bezug Werte geändert haben.
   *
   * @see planungsobjektWindowWannWoActions.updateWannBezugValueSuccess
   * @see planlaengenHelperForPlanungsobjektWindow$
   */
  fetchAvailableVariantsAfterUpdateWannBezug$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowWannWoActions.updateWannBezugValueSuccess),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(planungsobjektWindowSelectors.selectKanal),
      ]),
      map(([{ wannBezugForm }, windowInput, kanal]) => {
        const { sendetag, beginnzeit, planlaenge } = wannBezugForm;

        if (!sendetag || !beginnzeit || planlaenge === null || !kanal) {
          return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenNotPossible();
        }

        const query: GetVariantenzeilenByPublikationKeyQuery = {
          planungsobjektId: windowInput?.planungsobjekt?.id ?? null,
          kanal,
          beginnzeit,
          sendetag,
          laenge: planlaenge,
        };

        return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenDebounced({ query });
      }),
    );
  });

  /**
   * Effekt, der die Aktualisierung der verfügbaren Varianten anstößt, wenn das Planungsobjekt
   * Fenster geöffnet wurde. Der Request für die verfügbaren Varianten wird anhand der Werte
   * aus dem Planungsobjekt-Fenster Input berechnet.
   */
  fetchAvailableVariantsAfterOpenPlanungsobjektWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektWindow),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectKanal)),
      map(([{ input }, kanal]) => {
        const planlaengenProperties = extractPlanlaengenPropertiesFromWindowInput(input);
        if (!planlaengenProperties)
          return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenNotPossible();

        const { sendetag, beginnzeit, planlaenge } = planlaengenProperties;

        if (!sendetag || !beginnzeit || planlaenge === null) {
          return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenNotPossible();
        }

        const query: GetVariantenzeilenByPublikationKeyQuery = {
          planungsobjektId: input?.planungsobjektId ?? null,
          kanal,
          beginnzeit,
          sendetag,
          laenge: planlaenge,
        };

        return planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilen({ query });
      }),
    );
  });

  fetchAvailableVariantenzeilenDebounced$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilenDebounced),
      debounceTime(500),
      map(({ query }) =>
        planungsobjektWindowWannWoActions.fetchAvailableVariantenzeilen({ query }),
      ),
    );
  });
}
