import { Component, Input, TrackByFunction } from "@angular/core";
import { Store } from "@ngrx/store";
import { benachrichtigungActions } from "src/app/core/stores/benachrichtigung/benachrichtigung.actions";
import { BenachrichtigungComponentVm } from "src/app/core/stores/benachrichtigung/benachrichtigung.model";
import { GUID } from "src/app/models";

@Component({
  selector: "app-benachrichtigung",
  templateUrl: "./benachrichtigung.component.html",
  styleUrls: ["./benachrichtigung.component.scss"],
})
export class BenachrichtigungComponent {
  @Input({ required: true }) benachrichtigungenComponentData: BenachrichtigungComponentVm[] = [];

  constructor(private store: Store) {}

  trackByPrioritaet: TrackByFunction<BenachrichtigungComponentVm> = (_, componentData) =>
    componentData.prioritaet;

  onRemoveBenachrichtigungen(benachrichtigungIds: GUID[]) {
    this.store.dispatch(benachrichtigungActions.verwerfenMultiple({ benachrichtigungIds }));
  }
}
