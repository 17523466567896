import { KonkurrenzprogrammKategorie } from "../openapi/model/konkurrenzprogramm-kategorie";
import { EnumMetadata, enumRecordToSelectOptions } from "./enum-base";

export const KonkurrenzKategorieRecord: Record<KonkurrenzprogrammKategorie, EnumMetadata> = {
  Dokumentation: { bedeutung: "Dokumentation" },
  Fiction: { bedeutung: "Fiction" },
  Show: { bedeutung: "Show" },
  Sport: { bedeutung: "Sport" },
  KeineKategorie: { bedeutung: "Keine Kategorie" },
};

export const KonkurrenzKategorieOptions = enumRecordToSelectOptions(KonkurrenzKategorieRecord);
