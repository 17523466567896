<form autocomplete="off" [formGroup]="formGroup">
  <div class="flex-container">
    <app-form-field class="flex-item" label="Titel">
      <kendo-textbox data-testid="titel" formControlName="titel"> </kendo-textbox>
    </app-form-field>
  </div>

  <div class="flex-container">
    <app-select
      class="three-quarter-width"
      data-testid="redaktion"
      formControlName="redaktion"
      label="Redaktion"
      [options]="RedaktionOptions"
    >
    </app-select>

    <app-masked-input
      class="quarter-width"
      label="Länge (min:sek)"
      [dataTestId]="'laenge'"
      [formControl]="formGroup.controls.laenge"
    ></app-masked-input>
  </div>

  <div class="flex-container">
    <app-select
      class="three-quarter-width"
      data-testid="genre"
      formControlName="genre"
      label="Genre"
      [options]="GenreOptions"
    ></app-select>
    <app-form-field class="quarter-width" label="Uhrzeit">
      <app-iso-timeonly data-testid="beginnzeit" formControlName="beginnzeit"></app-iso-timeonly>
    </app-form-field>
  </div>

  <div class="flex-container">
    <app-select
      class="three-quarter-width"
      data-testid="ausstrahlung"
      formControlName="ausstrahlung"
      label="Ausstrahlung"
      [options]="AusstrahlungOptions"
    ></app-select>

    <app-form-field class="quarter-width" data-testid="sendesoll" label="Sendesoll">
      <kendo-numerictextbox formControlName="sendesoll" format="n0" [min]="1" [step]="1">
      </kendo-numerictextbox>
    </app-form-field>
  </div>
  <app-form-field label="Kommentar">
    <kendo-textarea data-testid="kommentar" formControlName="kommentar" resizable="auto">
    </kendo-textarea>
  </app-form-field>
</form>
<div class="window-action-buttons">
  <button
    class="primary-button"
    kendoButton
    themeColor="primary"
    type="button"
    [disabled]="!formGroup.valid || formGroup.pristine"
    (click)="onSave()"
  >
    Speichern
  </button>
  <button fillMode="outline" kendoButton type="button" (click)="onClose()">Abbrechen</button>
</div>
