<div>
  @if (sendeplatz) {
    @if (
      {
        planen: "planen" | able: (sendeplatz | asSubject: "Sendeplatz"),
        transferToPharos: "transferNachPharos" | able: (sendeplatz | asSubject: "Sendeplatz")
      };
      as can
    ) {
      <!-- Sendeplatz Chip List -->
      <div
        class="sendeplatz-container"
        [attr.data-testid]="
          'sendeplatz-container-' + sendeplatz.kalendertag + '-' + sendeplatzIndex
        "
      >
        <div #targetContextMenu class="sendeplatz-details">
          <div
            class="sendeplatz-details-button sendeplatz-icon"
            [attr.data-testid]="'sendeplatz-details-button-' + sendeplatz.kalendertag"
            [ngStyle]="{
              'background-color':
                sendeplatz.effektivVon
                | hasPlanungshinweis: sendeplatz.effektivBis : sendeplatz.kanal
                | async
            }"
            [title]="
              sendeplatz.effektivVon
                | planungshinweisTooltip: sendeplatz.effektivBis : sendeplatz.kanal
                | async
            "
            (click)="facade.openPlanungshinweiseWindow(sendeplatz)"
          ></div>
        </div>
        <!-- Sendeplatz Kontextmenu -->
        <kendo-contextmenu
          showOn="contextmenu"
          [target]="targetContextMenu"
          (select)="facade.sendeplatzContextMenuSelect($event, sendeplatz)"
        >
          <kendo-menu-item
            text="Details Sendeplatz"
            [data]="{ action: AktionEnum.SENDEPLATZ_DETAILS }"
            [svgIcon]="Icons.information"
          >
          </kendo-menu-item>
          <kendo-menu-item
            text="Nach Pharos übertragen"
            [data]="{ action: AktionEnum.NACH_PHAROS_UEBERTRAGEN }"
            [disabled]="!can.transferToPharos"
            [svgIcon]="Icons.pharos"
          ></kendo-menu-item>
        </kendo-contextmenu>

        <div class="sendeplatz-details-header">
          <!-- Sendeplatz Details (abweichende Beginnzeit/Länge) -->
          @if (sendeplatz.abweichendeBeginnzeit || sendeplatz.abweichendeLaenge) {
            <div class="sendeplatz-times">
              {{ sendeplatz.abweichendeBeginnzeit }}
              {{ sendeplatz.abweichendeLaenge | secondsToMinutesOrEmpty: "'" }}
            </div>
          }

          <!-- Sendpelatz Chip Liste -->
          <div #sendeplatzContextMenu class="sendeplatz">
            <kendo-chip-list
              #target="kendoContextMenuTargetContainer"
              aria-label="Titel"
              cdkDropList
              kendoContextMenuTargetContainer
              [attr.data-testid]="
                'sendeplatz-droplist-' + sendeplatz.kalendertag + '-' + sendeplatzIndex
              "
              [cdkDropListData]="sendeplatz"
              [cdkDropListDisabled]="!can.planen"
              [cdkDropListEnterPredicate]="can.planen | canDropInVorgeplantSpalte | async"
              (cdkDropListDropped)="facade.movePlanungsobjektInAnsicht($event, sendeplatz)"
            >
              <app-sendeplatz
                [ansichtViewModel]="ansichtViewModel"
                [isMengengeruestErlaubt]="facade.isMengengeruestErlaubt$ | async"
                [schemaplatz]="sendeplatz | schemaplatzBySendeplatz: ansichtViewModel"
                [sendeplatz]="sendeplatz"
                [target]="target"
              >
              </app-sendeplatz>
            </kendo-chip-list>
          </div>
          <kendo-contextmenu
            [target]="sendeplatzContextMenu"
            (select)="facade.openGetitWindow(sendeplatz)"
          >
            <kendo-menu-item
              text="Produkt-ID eingeben"
              [disabled]="!can.planen"
              [svgIcon]="Icons.getit"
            ></kendo-menu-item>
          </kendo-contextmenu>
        </div>
        @if (first) {
          <span class="sendeplatz-date">
            {{ sendeplatz.kalendertag | date: "dd" }}
          </span>
        }
      </div>
    }
  }
</div>
