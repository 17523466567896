import { FSKEinstufung } from "../openapi/model/fsk-einstufung";
import { EnumMetadata, enumRecordToSelectOptions } from "./enum-base";

export const FSKEinstufungRecord: Record<FSKEinstufung, EnumMetadata> = {
  KeineAngabe: { bedeutung: "Keine Angabe" },
  FSK0: { bedeutung: "FSK 0" },
  FSK6: { bedeutung: "FSK 6" },
  FSK12: { bedeutung: "FSK 12" },
  FSK16: { bedeutung: "FSK 16" },
  FSK18: { bedeutung: "FSK 18" },
};

export const FSKEinstufungOptions = enumRecordToSelectOptions(FSKEinstufungRecord);
