import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { ansichtActions } from "src/app/core/stores/ansicht/ansicht.actions";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";

@Component({
  selector: "app-dashboard-tab",
  templateUrl: "./dashboard-tab.component.html",
  styleUrls: ["./dashboard-tab.component.scss"],
})
export class DashboardTabComponent implements OnInit {
  Kanal = Kanal;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private titleService: Title,
    private notificationService: CustomNotificationService,
  ) {}

  ngOnInit() {
    // Bei einem Aufruf von /dashboard wird mit der Variable "unknownRoute" überprüft, ob der
    // redirect durch die Wildcard Url "**" (bspw. im Fall von unbekannten URLs) ausgelöst wurde.
    // Falls ja, wird eine Warnung angezeigt.
    if (this.route.snapshot.data["unknownRoute"]) {
      this.notificationService.showNotification(
        `Die angegebene URL  "/${this.route.snapshot.url
          .map((o) => o.path)
          .join("/")}" konnte nicht gefunden werden.`,
        NotificationStyle.WARNING,
      );
    }
    this.store.dispatch(ansichtActions.loadAnsichten());
    this.titleService.setTitle("publish.it");
  }
}
