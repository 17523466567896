/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ZoomLevel = "Level1" | "Level2" | "Level3" | "Level4" | "Level5";

export const ZoomLevel = {
  LEVEL1: "Level1" as ZoomLevel,
  LEVEL2: "Level2" as ZoomLevel,
  LEVEL3: "Level3" as ZoomLevel,
  LEVEL4: "Level4" as ZoomLevel,
  LEVEL5: "Level5" as ZoomLevel,
};
