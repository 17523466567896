<ng-container
  *ngIf="{
    umbenennen: ('umbenennen' | able: (merkliste | asSubject: 'Merkliste')),
    beitragen: ('beitragen' | able: (merkliste | asSubject: 'Merkliste'))
  } as can"
>
  <div
    *ngIf="{
      mehrfachauswahlActive: mehrfachauswahlActive$ | async
    } as vm"
    class="merkliste-container"
  >
    <fieldset [attr.data-testid]="'merkliste-' + merkliste.nummer">
      <legend>
        <span
          #title
          class="merkliste-title"
          [appTooltipIfEllipsis]="isEditingName ? '' : merklisteName"
          [attr.contenteditable]="isEditingName"
          [attr.role]="isEditingName ? 'input' : null"
          [class.wrap]="isEditingName"
          (blur)="onBlurEditingMerkliste($event)"
          (keydown)="onKeypressMerklisteInput($event)"
          (keyup)="onKeypressMerklisteInput($event)"
          (keyup.escape)="cancelEditingName()"
        >
          {{ merklisteName }}
        </span>
        <button
          *ngIf="can.umbenennen"
          fillMode="flat"
          kendoButton
          rounded="full"
          [attr.aria-label]="isEditingName ? 'Änderung speichern' : merklisteName + ' umbenennen'"
          [svgIcon]="isEditingName ? Icons.checkIcon : Icons.editIcon"
          title="{{ isEditingName ? 'Bestätigen' : 'Merkliste umbenennen' }}"
          (blur)="onBlurEditingMerkliste($event)"
          (click)="isEditingName ? saveName() : startEditingName()"
        ></button>
        <button
          *ngIf="isEditingName"
          aria-label="Umbenennung verwerfen"
          fillMode="flat"
          kendoButton
          rounded="full"
          title="Abbrechen"
          [svgIcon]="Icons.xIcon"
          (blur)="onBlurEditingMerkliste($event)"
          (click)="cancelEditingName()"
        ></button>
      </legend>
      <kendo-chip-list
        #target="kendoContextMenuTargetContainer"
        aria-label="Titel"
        cdkDropList
        class="merkliste-chip-list"
        kendoContextMenuTargetContainer
        [cdkDropListData]="merkliste"
        [cdkDropListEnterPredicate]="dropPredicate$ | async"
        (cdkDropListDropped)="movePlanungsobjektToMerkliste($event)"
      >
        <!-- TODO: app-planungsobjekt (Planungsobjektschip) verwenden um Merklistenchips daran anzugleichen -->
        <kendo-chip
          *ngFor="let planungsobjekt of planungsobjekteOnMerkliste$ | async"
          cdkDrag
          rounded="full"
          [attr.data-testid]="'planungsobjekt-' + planungsobjekt.id"
          [cdkDragData]="planungsobjekt"
          [cdkDragDisabled]="planungsobjekteInMehrfachauswahl.length > 1 || !can.beitragen"
          [kendoContextMenuTarget]="{ planungsobjekt }"
          [ngClass]="{
            'u-font-style-italic': !istMitProduktdatenbankVerknuepft(planungsobjekt)
          }"
          [ngStyle]="planungsobjekt.id | planungsobjektStyle | async"
          [title]="planungsobjekt.notiz"
          (cdkDragDropped)="onDragDropped()"
          (click)="
            vm.mehrfachauswahlActive
              ? planungsobjektInteraktionService.selectItem(
                  planungsobjekt.id,
                  planungsobjekteInMehrfachauswahl,
                  true
                )
              : planungsobjektInteraktionService.onPlanungsobjektClick(
                  planungsobjekt,
                  vm.mehrfachauswahlActive
                )
          "
          (dblclick)="openPlanungsobjektLinearWithPlanungWindow(planungsobjekt)"
        >
          {{ planungsobjekt.titel }}
          {{
            planungsobjekt.redaktion && planungsobjekt.redaktion !== Redaktion.KEINE_REDAKTION
              ? " (" + planungsobjekt.redaktion + ")"
              : ""
          }}
          <kendo-svg-icon
            *ngIf="planungsobjekt.highlight"
            class="chip-icon icon-xxs"
            [icon]="Icons.star"
          ></kendo-svg-icon>

          <kendo-svg-icon
            *ngIf="planungsobjekt.planungsobjektOnDemandId"
            class="chip-icon icon-xs"
            title="Online-Publikation verlinkt"
            [attr.data-testid]="planungsobjekt.titel + '-onDemand-verlinkt'"
            [icon]="Icons.onDemandBeziehung"
          ></kendo-svg-icon>

          <!-- Wenn von der Merkliste bei gesetzer Länge in die Blockansicht verschoben wird -->
          <ng-container *ngIf="(isBlockansicht$ | async) && planungsobjekt?.planlaenge > 0">
            <app-planungsobjekt-on-blockansicht-chip
              *cdkDragPreview
              [planungsobjekt]="planungsobjekt"
              [style.width.px]="planungsobjekt.id | chipWidthOnMerkliste | async"
            >
            </app-planungsobjekt-on-blockansicht-chip>
          </ng-container>
        </kendo-chip>
        <input
          *ngIf="can.beitragen"
          #planungsobjektInput
          class="merkliste-input"
          placeholder="+"
          title="Titel hinzufügen"
          [attr.data-testid]="'merkliste-input-' + merkliste.nummer"
          (blur)="onPlanungsobjektInputBlur($event)"
          (keyup.enter)="planungsobjektInput.blur()"
        />
        <kendo-contextmenu
          showOn="contextmenu"
          [target]="target"
          (select)="onPlanungsobjektContextMenuSelect($event)"
        >
          <kendo-menu-item
            text="Details"
            [data]="{ action: AktionEnum.BEARBEITE_PLANUNGSOBJEKT }"
            [svgIcon]="Icons.information"
          ></kendo-menu-item>
          <kendo-menu-item
            text="Kopie anlegen"
            [data]="{
              action: AktionEnum.KOPIE_HIER
            }"
            [disabled]="!can.beitragen"
            [svgIcon]="Icons.copy"
          >
          </kendo-menu-item>
        </kendo-contextmenu>
      </kendo-chip-list>
    </fieldset>
  </div>
</ng-container>
