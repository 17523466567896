<ng-container *ngFor="let sendeplatzGroupZeile of sendeplatzGroupZeilen">
  <ng-container
    *ngFor="
      let sendeplatz of sendeplatzGroupZeile.sendeplaetze;
      index as index;
      first as firstDayOfSchemaplatz
    "
  >
    <ng-container
      *ngIf="{
        can: {
          planen: 'planen' | able: (sendeplatz | asSubject: 'Sendeplatz'),
          transferToPharos: 'transferNachPharos' | able: (sendeplatz | asSubject: 'Sendeplatz'),
          beitragen: Role.PLANUNGSOBJEKT_BEITRAGEN | able: 'Permission',
          ek: 'editieren' | able: 'EK'
        },
        dummy: sendeplatzGroupZeile.dummies && sendeplatzGroupZeile.dummies[index]
      } as vm"
    >
      <!-- Sendetagsdatumspalte -->
      <ng-container *ngIf="firstDayOfSchemaplatz">
        <td *ngIf="vm.dummy" data-testid="no-date-column"></td>
        <td
          *ngIf="sendeplatz"
          class="date-column"
          [attr.data-testid]="'date-column-' + sendeplatz?.sendetag"
          [ngClass]="{
            'date-column-drag-preview': highlightSendetag$(sendeplatz.sendetag) | async
          }"
        >
          {{ sendeplatzGroupZeile.sendetagDatum | date: "dd.MM." }}
        </td>
      </ng-container>

      <!-- Sendetagsspalte -->
      <td *ngIf="vm.dummy" data-testid="no-sendetag"></td>
      <td
        *ngIf="sendeplatz"
        app-listenansicht-sendeplatz-vorgeplant
        data-testid="sendetag"
        [ansicht]="ansicht"
        [sendeplatz]="sendeplatz"
        [sendeplatzGroupZeileSendeplaetze]="sendeplatzGroupZeile.sendeplaetze"
        [sendeplatzIndex]="index"
      ></td>

      <!-- Notizen Spalte für eingeplante Planungsobjekte -->
      <td
        *ngIf="showNotizen"
        app-listenansicht-sendeplatz-notizen
        [planungskontext]="Planungskontext.VORGEPLANT"
        [sendeplatz]="sendeplatz"
        [showSendeplatzNotizen]="true"
      ></td>

      <!-- Vorschlagspalte -->
      <ng-container
        *ngIf="
          ansichtHeaders
            | showVorschlaegeOrVorschlaegeNotizen
              : sendeplatz ?? vm.dummy
              : ListenansichtHeaderEnum.VORSCHLAEGE
        "
      >
        <td *ngIf="vm.dummy"></td>
        <td
          *ngIf="sendeplatz"
          [attr.data-testid]="'vorschlagspalte-td-' + sendeplatz.sendetag"
          (dblclick)="(vm.can.beitragen || vm.can.planen) && onLinearChipDoubleClicked(sendeplatz)"
        >
          <!-- Vorschläge Chip List -->
          <div
            #targetVorschlagContextMenu
            cdkDropList
            class="vorschlaege-container vorschlaege"
            [cdkDropListData]="sendeplatz"
            [cdkDropListDisabled]="!(vm.can.planen || vm.can.beitragen)"
            [cdkDropListEnterPredicate]="canDropPlanungsobjektInVorschlagspaltePredicate | async"
            (cdkDropListDropped)="facade.movePlanungsobjektInVorschlagsSpalte($event)"
            (cdkDropListEntered)="facade.setHoveredSendeplatz(sendeplatz.sendetag)"
          >
            <app-planungsobjekt-vorschlag-list
              [planungsobjekteInVorschlagspalte]="
                planungsobjekteInVorschlagspalte$(sendeplatz) | async
              "
              [sendeplatz]="sendeplatz"
            >
            </app-planungsobjekt-vorschlag-list>
          </div>
          <kendo-contextmenu
            [target]="targetVorschlagContextMenu"
            (select)="onVorgeschlagenContextMenuSelect($event, sendeplatz, true)"
          >
            <kendo-menu-item
              *ngIf="settings.features.onDemandPlanung"
              text="Erstellen über Details"
              [data]="AktionEnum.ERSTELLE_PLANUNGSOBJEKT"
              [svgIcon]="Icons.information"
            ></kendo-menu-item>
            <kendo-menu-item
              text="Produkt-ID eingeben"
              [data]="AktionEnum.GETIT_TRANSFER"
              [disabled]="!(vm.can.planen || vm.can.beitragen)"
              [svgIcon]="Icons.getit"
            ></kendo-menu-item>
          </kendo-contextmenu>
        </td>
      </ng-container>

      <!-- Notizen Spalte für Planungsobjekte in Vorschlagspalte -->
      <td
        *ngIf="
          ansichtHeaders
            | showVorschlaegeOrVorschlaegeNotizen
              : sendeplatz ?? vm.dummy
              : ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN
        "
        app-listenansicht-sendeplatz-notizen
        [planungskontext]="Planungskontext.VORGESCHLAGEN"
        [sendeplatz]="sendeplatz"
        [showSendeplatzNotizen]="false"
      ></td>

      <!-- E&K Spalte -->
      <ng-container *ngIf="firstDayOfSchemaplatz && (facade.showKonkurrenzEvents$ | async)">
        <td *ngIf="vm.dummy" data-testid="no-ek"></td>
        <td
          *ngIf="sendeplatz"
          class="event-konkurrenz-column"
          data-testid="ek"
          (dblclick)="vm.can.ek && facade.openCreateEkWindow(sendeplatz)"
        >
          <div class="event-konkurrenz-container">
            <app-event-list data-testid="events" [sendeplaetze]="sendeplatzGroupZeile.sendeplaetze">
            </app-event-list>

            <app-konkurrenzprogramm-list [sendeplaetze]="sendeplatzGroupZeile.sendeplaetze">
            </app-konkurrenzprogramm-list>
          </div>
        </td>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
