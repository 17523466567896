import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { OnDemandFilter } from "src/app/models/openapi/model/on-demand-filter";
import { RenameMerklisteForAnsichtCommand } from "src/app/models/openapi/model/rename-merkliste-for-ansicht-command";
import { RenameMerklisteForOnDemandCommand } from "src/app/models/openapi/model/rename-merkliste-for-on-demand-command";
import { MultiAnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

export const merklisteActions = createActionGroup({
  source: "Merkliste API",
  events: {
    loadMerklistenAnsicht: props<{ multiAnsichtViewModel: MultiAnsichtViewModel }>(),
    loadMerklistenOnDemand: props<{ filter: OnDemandFilter }>(),
    loadMerklistenSuccess: props<{
      merklisten: MerklisteDto[];
    }>(),
    renameMerklisteAnsicht: props<{ command: RenameMerklisteForAnsichtCommand }>(),
    renameMerklisteOnDemand: props<{ command: RenameMerklisteForOnDemandCommand }>(),
    renameMerklisteSuccess: props<{ merkliste: MerklisteDto }>(),
    updateMerklisteOnBlockansicht: emptyProps(),
    updateMerklisteOnBlockansichtSuccess: props<{ merkliste: MerklisteDto }>(),
  },
});
