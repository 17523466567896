import { inject, NgModule } from "@angular/core";
import { CanActivateFn, Router, RouterModule, Routes } from "@angular/router";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { filter, map } from "rxjs";
import { AnsichtenComponent } from "./pages/ansichten/ansichten.component";
import { DashboardTabComponent } from "./pages/dashboard-tab/dashboard-tab.component";
import { EkAnsichtComponent } from "./pages/events-konkurenz-ansicht/ek-ansicht.component";
import { AuthorizationFailedComponent } from "./pages/fehler/authorization-failed.component";
import { RechercheComponent } from "./pages/recherche/recherche.component";
import { AuthorizationService } from "./services/authorization.service";
import { isDefined } from "./utils/object-utils";

const hasAnyPermission: CanActivateFn = (_route, _state) => {
  const router = inject(Router);
  const auth = inject(AuthorizationService);
  return auth.permissions$.pipe(
    // Warten bis Permissions geladen sind, im initialen Store State sind sie undefined
    filter(isDefined),
    map((permissions) => {
      if (permissions.length === 0) {
        return router.createUrlTree(["/failedAuthorization"]);
      }
      return true;
    }),
  );
};

const routes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    component: DashboardTabComponent,
    canActivate: [hasAnyPermission],
    runGuardsAndResolvers: "always",
  },
  {
    path: "ansichten",
    component: AnsichtenComponent,
    canActivate: [hasAnyPermission],
    runGuardsAndResolvers: "always",
  },
  {
    path: "ek-ansicht",
    component: EkAnsichtComponent,
    canActivate: [hasAnyPermission],
    runGuardsAndResolvers: "always",
  },
  {
    path: "recherche",
    title: "Recherche",
    component: RechercheComponent,
    canActivate: [hasAnyPermission],
    runGuardsAndResolvers: "always",
  },
  {
    path: "failedAuthorization",
    component: AuthorizationFailedComponent,
    pathMatch: "prefix",
  },
  {
    path: "vod-ansichten",
    loadChildren: () => import("./on-demand/on-demand.module").then((m) => m.OnDemandModule),
  },
  { path: "**", component: DashboardTabComponent, data: { unknownRoute: true } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" }),
    StoreRouterConnectingModule.forRoot(),
  ],
})
export class AppRoutingModule {}
