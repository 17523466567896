import { Wochentag } from "../models/openapi/model/wochentag";

const dayOfWeekToWochentag = {
  0: Wochentag.SONNTAG,
  1: Wochentag.MONTAG,
  2: Wochentag.DIENSTAG,
  3: Wochentag.MITTWOCH,
  4: Wochentag.DONNERSTAG,
  5: Wochentag.FREITAG,
  6: Wochentag.SAMSTAG,
} satisfies Record<number, Wochentag>;

export function toWochentag(kalendertag: string | Date): Wochentag {
  const date = kalendertag instanceof Date ? kalendertag : new Date(kalendertag);
  const dayOfWeek = date.getDay();
  const wochentag = dayOfWeekToWochentag[dayOfWeek as keyof typeof dayOfWeekToWochentag];
  if (!wochentag) {
    throw new Error(`Wochentag für '${date.toISOString()}' konnte nicht ermittelt werden`);
  }

  return wochentag;
}

/**
 * Methode, die den eine Kurzform des Wochentags zurückgibt, z.B. "Mo" für Montag.
 */
export function toWochentagShort(kalendertag: string | Date): string {
  const wochentag = toWochentag(kalendertag);
  return wochentag.substring(0, 2);
}

export function toDayOfWeek(wochentag: Wochentag): number {
  if (wochentag === Wochentag.SONNTAG) {
    return 0;
  }
  if (wochentag === Wochentag.MONTAG) {
    return 1;
  }
  if (wochentag === Wochentag.DIENSTAG) {
    return 2;
  }
  if (wochentag === Wochentag.MITTWOCH) {
    return 3;
  }
  if (wochentag === Wochentag.DONNERSTAG) {
    return 4;
  }
  if (wochentag === Wochentag.FREITAG) {
    return 5;
  }
  if (wochentag === Wochentag.SAMSTAG) {
    return 6;
  }
  throw new Error(`DayOfWeek für '${wochentag}' konnte nicht ermittelt werden`);
}

export function firstDayOfCurrentYear(opts?: { plus: number }): Date {
  const currentYear = new Date(Date.now()).getFullYear();
  const year = currentYear + (opts?.plus ?? 0);
  return new Date(year, 0, 1);
}

export function lastDayOfCurrentYear(opts?: { plus: number }): Date {
  const currentYear = new Date(Date.now()).getFullYear();
  const year = currentYear + (opts?.plus ?? 0);
  return new Date(year, 11, 31);
}

/**
 * Methode die ein Date-Objekt clont.
 * Ohne diese Methode würde das Date-Objekt nur referenziert und ggf. verändert werden.
 */
export function cloneDate(date: Date): Date {
  return new Date(date.getTime());
}

/**
 * Methode, die das Default-Jahr für die Navigation zurückgibt.
 */
export function getDefaultYear(): number {
  return new Date().getFullYear() + 1;
}

/**
 * Methode, die das Jahr zurückgibt, zu dem das Dashboard scrollen soll.
 */
export function getDashboardYear(): number {
  const referenceDate = new Date();
  // PUBLIT-973 Die Mitte des Jahres 30.06. dient als Anhaltspunkt, ob zum gleichen oder
  // nächsten Jahr gescrollt werden soll. Wird als String im Format YYYY-MM-DD übergeben,
  // damit ein neues UTC und kein lokales Datum erstellt wird.
  const middleOfYear = new Date(`${referenceDate.getFullYear().toString()}-06-30`);
  const yearToScrollTo =
    referenceDate.toISOString() < middleOfYear.toISOString()
      ? referenceDate.getFullYear()
      : referenceDate.getFullYear() + 1;
  return yearToScrollTo;
}
