import { createFeature, createReducer, on } from "@ngrx/store";
import { FeatureKey } from "../feature.keys";
import { dragDropActions } from "./dragdrop.actions";

export type DragDropState = {
  hoveredSendeplatz: string | undefined;
};

const initialState: DragDropState = {
  hoveredSendeplatz: undefined,
};

export const dragDropFeature = createFeature({
  name: FeatureKey.DragDrop,
  reducer: createReducer(
    initialState,
    on(dragDropActions.dragHover, (state, payload): DragDropState => {
      return { ...state, hoveredSendeplatz: payload.sendetag };
    }),
    on(dragDropActions.dragEnd, (state): DragDropState => {
      return { ...state, hoveredSendeplatz: undefined };
    }),
  ),
});
