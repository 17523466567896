import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzDtoResultDto } from "src/app/models/openapi/model/sendeplatz-dto-result-dto";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import { FeatureKey } from "../feature.keys";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { sendeplatzActions } from "./sendeplatz.actions";
export interface SendeplatzState extends EntityState<SendeplatzDto> {
  loading: boolean;
}

export const sendeplatzAdapter: EntityAdapter<SendeplatzDto> = createEntityAdapter<SendeplatzDto>({
  selectId: SendeplatzUtils.createSendeplatzKey,
});

export const initialSendeplatzState: SendeplatzState = sendeplatzAdapter.getInitialState({
  loading: false,
});

export const sendeplatzFeature = createFeature({
  name: FeatureKey.Sendeplatz,
  reducer: createReducer(
    initialSendeplatzState,
    on(
      sendeplatzActions.updateSendeplatzSuccess,
      planungsobjektActions.createPlanungsobjektOnSendeplatzSuccess,
      planungsobjektActions.movePlanungsobjektSuccess,
      planungsobjektActions.copyPlanungsobjektSuccess, // Damit die Default-Sendeplätze überschrieben werden
      (state, { sendeplatz }): SendeplatzState => {
        const isSendeplatzDtoResultDto = (
          planungsobjekt: SendeplatzDtoResultDto | SendeplatzDto,
        ): planungsobjekt is SendeplatzDtoResultDto => {
          return "notifications" in planungsobjekt && "result" in planungsobjekt;
        };

        const changes = isSendeplatzDtoResultDto(sendeplatz) ? sendeplatz.result : sendeplatz;
        return sendeplatzAdapter.updateOne(
          { id: SendeplatzUtils.createSendeplatzKey(changes), changes },
          state,
        );
      },
    ),
    on(
      sendeplatzActions.updateSendeplaetzeByAnsichtenSuccess,
      planungsobjektActions.planungsobjektZuSerieUmwandelnSendeplatzSuccess, // Damit die Default-Sendeplätze überschrieben werden
      (state, { sendeplaetze }): SendeplatzState => {
        return sendeplatzAdapter.upsertMany(sendeplaetze, state);
      },
    ),
  ),
  extraSelectors: ({ selectSendeplatzState }) => ({
    ...sendeplatzAdapter.getSelectors(selectSendeplatzState),
  }),
});
