import { DefaultUrlSerializer, Params, UrlTree } from "@angular/router";

const defaultSerialize = DefaultUrlSerializer.prototype.serialize;
const defaultParse = DefaultUrlSerializer.prototype.parse;

export class ShortenedUrlSerializer extends DefaultUrlSerializer {
  serialize(tree: UrlTree): string {
    const path = super.serialize(tree);
    const pathStr = path.split("#")[0].split("?")[0];
    const fragment = tree.fragment;
    const fragmentStr = fragment ? `#${fragment}` : "";
    const queryParams = tree.queryParams;

    // Convert array query params to comma-separated format
    let queryString = Object.keys(queryParams)
      .map((key) => {
        const value = queryParams[key] as string;

        if (Array.isArray(value)) {
          const nonEmptyValues = value.filter((v) => v !== "");
          return nonEmptyValues.length ? `${key}=${nonEmptyValues.join(",")}` : null;
        }

        return value ? `${key}=${value}` : null;
      })
      .filter((query) => query !== null)
      .join("&");

    if (queryString) {
      queryString = `?${queryString}`;
    }

    return `${pathStr}${queryString}${fragmentStr}`;
  }

  parse(url: string): UrlTree {
    const urlTree = super.parse(url);

    // Process query parameters to convert 'name=value1,value2' to 'name=value1&name=value2'
    const queryParams = urlTree.queryParams;
    const newQueryParams: Params = {};

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key] as string;
      if (!value) {
        continue;
      }
      if (value.includes(",")) {
        newQueryParams[key] = value.split(","); // Split comma-separated values into an array
      } else {
        newQueryParams[key] = value;
      }
    }

    urlTree.queryParams = newQueryParams;
    return urlTree;
  }
}
