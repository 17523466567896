import { Pipe, inject, type PipeTransform } from "@angular/core";
import { ProduktDbService } from "src/app/services/produktDb.service";

@Pipe({ name: "getitProgrammeitemLink" })
export class GetitProgrammeitemLinkPipe implements PipeTransform {
  private readonly produktDbService = inject(ProduktDbService);

  transform(getitId: string) {
    return this.produktDbService.programmeItemLink(getitId);
  }
}
