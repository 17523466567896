<app-form-field [control]="ngControl?.control" [label]="label">
  <div class="k-d-flex">
    <kendo-timepicker
      [attr.data-testid]="dataTestId"
      [disabled]="isDisabled"
      [steps]="{ minute: 5 }"
      [value]="viewValue"
      (valueChange)="onChange($event)"
    >
    </kendo-timepicker>
    <button
      *ngIf="clearable && !isDisabled"
      class="clear-button"
      fillMode="flat"
      kendoButton
      rounded="full"
      size="small"
      title="Feld leeren"
      [disabled]="isDisabled || !viewValue"
      [svgIcon]="Icons.xIcon"
      (click)="onClear()"
    ></button>
  </div>
</app-form-field>
