/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type NotificationStyle = "None" | "Success" | "Warning" | "Error" | "Info";

export const NotificationStyle = {
  NONE: "None" as NotificationStyle,
  SUCCESS: "Success" as NotificationStyle,
  WARNING: "Warning" as NotificationStyle,
  ERROR: "Error" as NotificationStyle,
  INFO: "Info" as NotificationStyle,
};
