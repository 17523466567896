import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs";
import { Aktion } from "src/app/models/enums/aktion";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import { planungsobjektActions } from "./planungsobjekt.actions";

/**
 * Effect für alle Aktionen im Bezug auf Planungsobjekte im Kontext Vorschlag.
 */
@Injectable()
export class PlanungsobjektVorgeschlagenEffects {
  /**
   * Verschiebt eine Planungsobjekt vom Kontext "Merkliste" oder "Vorgeplant" in die Vorschlagspalte.
   * Oder verschiebt eine Planungsobjekt die Bereits in der Vorschlagspalte ist, auf einen anderen Sendeplatz.
   */
  movePlanungsobjektToVorschlagspalte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.movePlanungsobjektToVorschlagspalte),
      switchMap(({ command }) =>
        this.service.movePlanungsobjektLinearToVorgeschlagen$(command).pipe(
          map((result) => {
            const planungsobjekt = result.result.vorschlaege.find(
              (planungsobjekt) => planungsobjekt.id === command.id,
            );
            if (planungsobjekt === undefined) {
              throw new Error(`Planungsobjekt mit ID ${command.id} konnte nicht gefunden werden.`);
            }
            return planungsobjektActions.movePlanungsobjektToVorschlagspalteSuccess({
              notifications: result.notifications,
              planungsobjekt,
              sendeplatz: result.result,
            });
          }),
          tap(({ notifications }) => {
            // TODO: Zeigt aktuell noch eine falsche Benachrichtigung an: "(...) auf 07.01. um 22:15 eingeplant."
            // Vom Wording sollte man das Wort "Vorgeplant" im Kontext von Vorschlägen vermeiden.
            this.notificationService.showNotifications(notifications);
          }),
        ),
      ),
    );
  });

  /**
   * Verschiebt eine Planungsobjekt vom Kontext "Vorschlag" in die "Vorgeplant" Spalte.
   */
  movePlanungsobjektVorgeschlagenToVorgeplant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.movePlanungsobjektVorgeschlagenToVorgeplant),
      switchMap(({ planungsobjektId, sendeplatz }) =>
        this.service
          .movePlanungsobjektLinearToVorgeplantSendeplatz$({
            id: planungsobjektId,
            sendeplatzKey: SendeplatzUtils.getSendeplatzKey(sendeplatz),
          })
          .pipe(
            map((response) => {
              // TOOD-1503 + Im nächsten Schritt eventuell so umbauen, dass einfach das Planungsobjekt in der
              // Response geschickt wird und kein kompletter Sendeplatz
              const planungsobjekt = response.result.planungsobjekte.find(
                (planungsobjekt) => planungsobjekt.id === planungsobjektId,
              );
              if (planungsobjekt === undefined) {
                throw new Error(
                  `Planungsobjekt with ID ${planungsobjektId} not found in response.`,
                );
              }
              return planungsobjektActions.movePlanungsobjektVorgeschlagenToVorgeplantSuccess({
                sendeplatz,
                notifications: response.notifications,
                planungsobjekt: planungsobjekt,
              });
            }),
            tap(({ notifications }) => {
              this.notificationService.showNotifications(notifications);
            }),
          ),
      ),
    );
  });

  /**
   * Ersetze eine existierende Planungsobjekt mit einer Planungsobjekt mit dem Kontext "Vorschlag".
   */
  replacePlanungsobjektWithVorschlag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.replacePlanungsobjektWithVorschlag),
      switchMap(({ command, sendeplatz }) =>
        this.service.planungsobjektLinearVorgeschlagenErsetzenZuVorgeplantSendeplatz$(command).pipe(
          map((result) =>
            planungsobjektActions.replacePlanungsobjektWithVorschlagSuccess({
              notifications: result.notifications,
              planungsobjekt: result.result,
              sendeplatz,
            }),
          ),
          tap((action) => {
            this.notificationService.showActionNotification(
              Aktion.BEARBEITE_PLANUNGSOBJEKT,
              NotificationStyle.SUCCESS,
              action.planungsobjekt.titel,
            );
            this.notificationService.showNotifications(action.notifications);
          }),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: PlanungsobjektService,
    private notificationService: CustomNotificationService,
  ) {}
}
