<div class="k-d-flex">
  <kendo-timepicker
    format="HH:mm"
    [disabled]="isDisabled"
    [steps]="{ minute: 5 }"
    [value]="internalValue"
    (blur)="_onTouched()"
    (valueChange)="onChange($event)"
  ></kendo-timepicker>

  <button
    *ngIf="clearable && !isDisabled"
    class="clear-button"
    fillMode="flat"
    kendoButton
    rounded="full"
    size="small"
    title="Feld leeren"
    type="button"
    [disabled]="isDisabled || !internalValue"
    [svgIcon]="Icons.xIcon"
    (click)="onChange(null)"
  ></button>
</div>
