<div
  class="sendung-item"
  [ngClass]="{
    'sendung-item-variante': planungsobjekt.publikationsplanung?.variante > 0,
    'u-font-style-italic': !istMitProduktdatenbankVerknuepft(planungsobjekt)
  }"
  [ngStyle]="styles$ | async"
>
  <!-- Mengengerüst -->
  <kendo-svg-icon
    *ngIf="!planungsobjekt.mengengeruesteintragId && isMengengeruestErlaubt"
    class="chip-icon icon-xxs"
    data-testid="mg-icon"
    style="margin-left: 0.3em"
    title="Mengengerüst-Zuordnung fehlt"
    [icon]="Icons.balance"
  ></kendo-svg-icon>

  <!-- Titel -->
  <span class="chip-text">
    {{ planungsobjekt.titel }}
  </span>

  <!-- Redaktion -->
  <span *ngIf="planungsobjekt.redaktion !== Redaktion.KEINE_REDAKTION" class="redaktion"
    >({{ planungsobjekt.redaktion }})</span
  >

  <!-- Highlight -->
  <kendo-svg-icon
    *ngIf="planungsobjekt.highlight"
    class="chip-icon icon-xxs"
    [icon]="Icons.star"
  ></kendo-svg-icon>

  <!-- OnDemand Beziehung -->
  <!-- Das Icon sieht in der Größe etwas gequetscht aus,
    Dana hatte sich in diesem speziellen Fall daher einen kleinen horizontalen Abstand gewünscht  -->
  <kendo-svg-icon
    *ngIf="planungsobjekt.planungsobjektOnDemandId"
    class="chip-icon icon-xxs"
    style="margin-left: 3px"
    title="Online-Publikation verlinkt"
    [attr.data-testid]="planungsobjekt.titel + '-onDemand-verlinkt'"
    [icon]="Icons.onDemandBeziehung"
  ></kendo-svg-icon>
</div>
