import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConvertKonkurrenzprogrammToEventCommand } from "src/app/models/openapi/model/convert-konkurrenzprogramm-to-event-command";
import { CopyEventCommand } from "src/app/models/openapi/model/copy-event-command";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { GetEventsByAnsichtenQuery } from "src/app/models/openapi/model/get-events-by-ansichten-query";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EventApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  createEvent$(body: EventDto): Observable<EventDto> {
    return this.http.post<EventDto>(this.apiUrl + "Event/", body);
  }

  updateEvent$(id: string, body: EventDto): Observable<EventDto> {
    return this.http.put<EventDto>(this.apiUrl + "Event/" + `${id}`, body);
  }

  deleteEvent$(id: string): Observable<void> {
    return this.http.delete<void>(this.apiUrl + "Event/" + `${id}`);
  }

  copyEvent$(id: string, body: CopyEventCommand): Observable<EventDto> {
    return this.http.post<EventDto>(this.apiUrl + "Event/" + `${id}` + "/Copy", body);
  }

  convertKonkurrenzprogramm$(body: ConvertKonkurrenzprogrammToEventCommand): Observable<EventDto> {
    return this.http.post<EventDto>(this.apiUrl + "Event/Convert", body);
  }

  getEventsByAnsicht$(ansichtId: string): Observable<EventDto[]> {
    return this.http.get<EventDto[]>(this.apiUrl + `Event/ByAnsicht/${ansichtId}`);
  }

  getEventsByAnsichten$(ansichtenIds: GetEventsByAnsichtenQuery): Observable<EventDto[]> {
    return this.http.post<EventDto[]>(this.apiUrl + `Event/ByAnsichten`, ansichtenIds);
  }

  getEventsByYear$(year: number): Observable<EventDto[]> {
    return this.http.get<EventDto[]>(this.apiUrl + `Event/ByYear/${year}`);
  }
}
