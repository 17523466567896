import { Component, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject, first, shareReplay, startWith, takeUntil } from "rxjs";
import { ansichtFeature } from "src/app/core/stores/ansicht/ansicht.reducer";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { getDashboardYear } from "src/app/utils/date-utils";
import { Kanal } from "tests/common/generated/api";

@Component({
  selector: "app-dashboard-zdfneo",
  templateUrl: "./dashboard-zdfneo.component.html",
  styleUrls: ["./dashboard-zdfneo.component.scss"],
})
export class DashboardZDFneoComponent {
  private readonly destroy$ = new Subject<void>();
  readonly ansichtenViewModels$ = this.store.select(ansichtFeature.selectAll);
  readonly years$ = this.store.select(ansichtSelectors.selectAnsichtViewModelYearsZDFNeo);
  @ViewChildren("years") yearAnchors: QueryList<ElementRef<HTMLDivElement>>;
  Kanal = Kanal;

  constructor(
    private router: Router,
    private store: Store,
  ) {}

  ngAfterViewInit(): void {
    this.yearAnchors.changes
      .pipe(
        startWith(undefined),
        shareReplay({ bufferSize: 1, refCount: true }),
        takeUntil(this.destroy$),
      )
      .subscribe((_) => {
        this.scrollToNextYear();
      });
  }

  private scrollToNextYear() {
    const yearToScrollTo = getDashboardYear();

    this.years$.pipe(first(), takeUntil(this.destroy$)).subscribe((years) => {
      if (!years.includes(yearToScrollTo)) {
        return;
      }

      const nextYearAnchor = this.yearAnchors.find(
        (el) => el.nativeElement.getAttribute("data-testid") === `year-${yearToScrollTo}`,
      );

      if (nextYearAnchor) {
        // Then scroll into view
        nextYearAnchor.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      // Setze den queryParameter in der Url nach dem Scrollvorgang, damit dieser für zukünftige Operationen z.B.
      // in der E&K Ansicht zur Verfügung steht
      void this.router.navigate([], {
        queryParams: { year: yearToScrollTo },
        queryParamsHandling: "merge",
        replaceUrl: true,
      });
    });
  }
}
