import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import blockansichtSelectors from "src/app/core/stores/blockansicht/blockansicht.selectors";

@Pipe({
  name: "chipWidthOnMerkliste",
})
export class ChipWidthOnMerklistePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(planungsobjektId: string): Observable<number | null> {
    return this.store.select(blockansichtSelectors.selectBlockansichtChipWidth(planungsobjektId));
  }
}
