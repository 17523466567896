import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { PlanungsobjektLinearVm } from "../../planungsobjekt/planungsobjekt-linear.model";
import { PlanungsobjektOnDemandVm } from "../on-demand.model";

export class OnDemandMapper {
  static mapPlanungsobjektOnDemandToVm(
    onDemand: PlanungsobjektOnDemandDto,
  ): PlanungsobjektOnDemandVm {
    return {
      ...onDemand,
      createdAt: new Date(onDemand.createdAt),
      modifiedAt: new Date(onDemand.modifiedAt),
      onlineAb: new Date(onDemand.onlineAb),
      onlineBis: new Date(onDemand.onlineBis),
      contentCommunitiesKey: onDemand.contentCommunities
        ? [...onDemand.contentCommunities].sort().join(",")
        : "",
    };
  }

  static mapPlanungsobjektLinearToVm(planungsobjekt: PlanungsobjektDto): PlanungsobjektLinearVm {
    const {
      discriminator,
      abhaengigkeiten,
      contentCommunities,
      createdAt,
      createdBy,
      genre,
      farbgebung,
      highlight,
      id,
      modifiedAt,
      modifiedBy,
      nachfolger,
      notiz,
      planungskontext,
      publikationsplanung,
      redaktion,
      titel,
      vorgaenger,
      mengengeruesteintragId,
      merklisteId,
      planlaenge,
      produktlaenge,
      beziehungenIds,
      planungsobjektOnDemandId,
      fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      beziehungenEingehend,
      beziehungenAusgehend,
      getitId,
      produktstatus,
      produkttitel,
      produkttitelMultipart,
      letzterGetitSync,
    } = planungsobjekt;
    if (!publikationsplanung) {
      throw new Error("publikationsplanung is missing");
    }
    const {
      beginnzeit,
      bis,
      kalendertag,
      kanal,
      laenge,
      sendetag,
      von,
      sendeplatzAbweichendeBeginnzeit,
      sendeplatzAbweichendeLaenge,
      sendeplatzBeginnzeit,
      sendeplatzLaenge,
      variante,
    } = publikationsplanung;
    return {
      discriminator,
      abhaengigkeiten,
      beginnzeit,
      bis,
      contentCommunities,
      createdAt: new Date(createdAt),
      createdBy,
      farbgebung,
      genre,
      highlight,
      id,
      kalendertag,
      kanal,
      modifiedAt: new Date(modifiedAt),
      modifiedBy,
      nachfolger,
      notiz,
      planungskontext,
      publikationsplanung,
      redaktion,
      sendetag,
      titel,
      von,
      vorgaenger,
      mengengeruesteintragId,
      merklisteId,
      planlaenge,
      produktlaenge,
      sendeplatzAbweichendeBeginnzeit,
      sendeplatzAbweichendeLaenge,
      sendeplatzBeginnzeit,
      sendeplatzLaenge,
      variante,
      beziehungenIds,
      planungsobjektOnDemandId,
      fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion,
      fsk,
      staffelnummer,
      folgennummer,
      gesamtfolgennummer,
      inhaltsbeschreibung,
      mitwirkende,
      beziehungenEingehend,
      beziehungenAusgehend,
      getitId,
      produktstatus,
      produkttitel,
      produkttitelMultipart,
      letzterGetitSync,
    };
  }
}
