import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { KonkurrenzprogrammApiService } from "../api/konkurrenzprogramm/konkurrenzprogramm.api.service";
import { ConvertEventToKonkurrenzprogrammCommand } from "../models/openapi/model/convert-event-to-konkurrenzprogramm-command";
import { CopyKonkurrenzprogrammCommand } from "../models/openapi/model/copy-konkurrenzprogramm-command";
import { CopyPattern } from "../models/openapi/model/copy-pattern";
import { CreateKonkurrenzprogrammCommand } from "../models/openapi/model/create-konkurrenzprogramm-command";
import { GetKonkurrenzprogrammeByAnsichtenQuery } from "../models/openapi/model/get-konkurrenzprogramme-by-ansichten-query";
import { KonkurrenzprogrammDto } from "../models/openapi/model/konkurrenzprogramm-dto";
import { UpdateKonkurrenzprogrammCommand } from "../models/openapi/model/update-konkurrenzprogramm-command";
import { DateFnsService } from "./date-fns.service";

@Injectable({
  providedIn: "root",
})
export class KonkurrenzprogrammService {
  constructor(
    private konkurrenzprogrammApiService: KonkurrenzprogrammApiService,
    private logger: NGXLogger,
  ) {}

  createKonkurrenzprogramm$(
    konkurrenzprogrammViewModel: KonkurrenzprogrammDto,
  ): Observable<KonkurrenzprogrammDto> {
    const konkurrenzprogrammDto = this.transformKonkurrenzprogrammToCreateCommand(
      konkurrenzprogrammViewModel,
    );
    return this.konkurrenzprogrammApiService.createKonkurrenzprogramm$(konkurrenzprogrammDto);
  }

  updateKonkurrenzprogramm$(
    konkurrenzprogrammViewModel: KonkurrenzprogrammDto,
  ): Observable<KonkurrenzprogrammDto> {
    const konkurrenzprogrammDto = this.transformKonkurrenzprogrammToUpdateCommand(
      konkurrenzprogrammViewModel,
    );
    return this.konkurrenzprogrammApiService.updateKonkurrenzprogramm$(
      konkurrenzprogrammDto.id,
      konkurrenzprogrammDto,
    );
  }

  deleteKonkurrenzprogramm$(konkurrenzprogramm: KonkurrenzprogrammDto): Observable<void> {
    return this.konkurrenzprogrammApiService.deleteKonkurrenzprogramm$(konkurrenzprogramm.id);
  }

  copyKonkurrenzprogramm$(
    konkurrenzprogramm: KonkurrenzprogrammDto,
    copyPattern: CopyPattern,
  ): Observable<KonkurrenzprogrammDto> {
    const konkurrenzprogrammDto: CopyKonkurrenzprogrammCommand = {
      konkurrenzprogrammId: konkurrenzprogramm.id,
      copyPattern: copyPattern,
    };
    return this.konkurrenzprogrammApiService.copyKonkurrenzprogramm$(
      konkurrenzprogramm.id,
      konkurrenzprogrammDto,
    );
  }

  convertEvent(toKonkurrenzprogramm: KonkurrenzprogrammDto, convertedGUID: string) {
    const convertCommand: ConvertEventToKonkurrenzprogrammCommand = {
      eventId: convertedGUID,
      titel: toKonkurrenzprogramm.titel,
      vonDatum: toKonkurrenzprogramm.vonDatum,
      vonZeit: toKonkurrenzprogramm.vonZeit,
      bisZeit: toKonkurrenzprogramm.bisZeit,
      bisDatum: toKonkurrenzprogramm.bisDatum,
      senderPlattform: toKonkurrenzprogramm.senderPlattform,
      senderPlattformSonstiges: toKonkurrenzprogramm.senderPlattformSonstiges,
      notiz: toKonkurrenzprogramm.notiz,
      pressetextLink: toKonkurrenzprogramm.pressetextLink,
      kategorie: toKonkurrenzprogramm.kategorie,
      isAbgesetzt: toKonkurrenzprogramm.isAbgesetzt,
    };
    return this.konkurrenzprogrammApiService.convertEvent$(convertCommand);
  }

  /**
   * Transformiert die Eingabedaten aus der Konkurrenzprogramm Maske des Nutzers und wandelt es
   * in ein Dto für das Backend.
   * @param kp Dto mit den Eingaben des Nutzers
   * @returns KonkurrenzprogrammDto, welches an das Backend gesendet wird
   */
  transformKonkurrenzprogrammToCreateCommand(
    kp: KonkurrenzprogrammDto,
  ): CreateKonkurrenzprogrammCommand {
    let vonDatum = kp.vonDatum;
    vonDatum =
      typeof vonDatum === "string" ? vonDatum : DateFnsService.formatDateAsString(vonDatum);
    let bisDatum = kp.bisDatum ?? vonDatum;
    bisDatum =
      typeof bisDatum === "string" ? bisDatum : DateFnsService.formatDateAsString(bisDatum);

    this.logger.info("KonkurrenzprogrammViewModel vor Transformation: ", kp);

    const konkurrenzprogrammDto: CreateKonkurrenzprogrammCommand = {
      ...kp,
      vonDatum: vonDatum,
      bisDatum: bisDatum,
      senderPlattformSonstiges: kp.senderPlattformSonstiges ?? "",
      notiz: kp.notiz ?? "",
      pressetextLink: kp.pressetextLink ?? "",
    };
    this.logger.info("Transformiertes KonkurrenzprogrammDto: ", konkurrenzprogrammDto);
    return konkurrenzprogrammDto;
  }
  /**
   * Transformiert die Eingabedaten aus der Konkurrenzprogramm Maske des Nutzers und wandelt es
   * in ein Dto für das Backend.
   * @param kp Dto mit den Eingaben des Nutzers
   * @returns KonkurrenzprogrammDto, welches an das Backend gesendet wird
   */
  transformKonkurrenzprogrammToUpdateCommand(
    kp: KonkurrenzprogrammDto,
  ): UpdateKonkurrenzprogrammCommand {
    let vonDatum = kp.vonDatum;
    vonDatum =
      typeof vonDatum === "string" ? vonDatum : DateFnsService.formatDateAsString(vonDatum);
    let bisDatum = kp.bisDatum ?? vonDatum;
    bisDatum =
      typeof bisDatum === "string" ? bisDatum : DateFnsService.formatDateAsString(bisDatum);

    this.logger.info("KonkurrenzprogrammViewModel vor Transformation: ", kp);

    const konkurrenzprogrammDto: UpdateKonkurrenzprogrammCommand = {
      ...kp,
      vonDatum: vonDatum,
      bisDatum: bisDatum,
      senderPlattformSonstiges: kp.senderPlattformSonstiges ?? "",
      notiz: kp.notiz ?? "",
      pressetextLink: kp.pressetextLink ?? "",
    };
    this.logger.info("Transformiertes KonkurrenzprogrammDto: ", konkurrenzprogrammDto);
    return konkurrenzprogrammDto;
  }

  getKonkurrenzprogrammeByAnsicht$(ansichtId: string) {
    return this.konkurrenzprogrammApiService.getKonkurrenzprogrammeByAnsicht$(ansichtId);
  }

  getKonkurrenzprogrammeByAnsichten$(ansichtenIds: GetKonkurrenzprogrammeByAnsichtenQuery) {
    return this.konkurrenzprogrammApiService.getKonkurrenzprogrammeByAnsichten$(ansichtenIds);
  }

  getKonkurrenzprogrammeByYear$(year: number) {
    return this.konkurrenzprogrammApiService.getKonkurrenzprogrammeByYear$(year);
  }
}
