import { LOCALE_ID, NgModule } from "@angular/core";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { ExcelModule, GridModule } from "@progress/kendo-angular-grid";
import { IconsModule } from "@progress/kendo-angular-icons";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { IntlModule } from "@progress/kendo-angular-intl";
import { MessageService as KendoMessageService } from "@progress/kendo-angular-l10n";
import { LabelModule } from "@progress/kendo-angular-label";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { MenusModule } from "@progress/kendo-angular-menu";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { PopupModule } from "@progress/kendo-angular-popup";
import { MessageService } from "./services/message.service";

import "@progress/kendo-angular-intl/locales/de/all";
@NgModule({
  imports: [
    ButtonsModule,
    DateInputsModule,
    DialogsModule,
    DropDownsModule,
    IconsModule,
    IndicatorsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    MenusModule,
    NotificationModule,
    IntlModule,
    GridModule,
    ExcelModule,
    PopupModule,
  ],
  exports: [
    ButtonsModule,
    DateInputsModule,
    DialogsModule,
    DropDownsModule,
    IconsModule,
    IndicatorsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    MenusModule,
    NotificationModule,
    IntlModule,
    GridModule,
    ExcelModule,
    PopupModule,
  ],

  providers: [
    { provide: LOCALE_ID, useValue: "de" },
    MessageService,
    { provide: KendoMessageService, useClass: MessageService },
  ],
})
export class KendoModule {}
