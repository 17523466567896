import { NotificationSettings } from "@progress/kendo-angular-notification";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { SideEffectNotificationDto } from "src/app/models/openapi/model/side-effect-notification-dto";

type NotificationSettingsWithoutContent = Omit<NotificationSettings, "content">;

/**
 * Flüchtige Benachrichtigung die entweder aus dem Frontend heraus
 * getriggert werden (z.B. nach einem erfolgreichen API Request) oder durch
 * das Backend im ResponseDto als Teil der Antwort enthalten sein können.
 */
export const defaultNotification: NotificationSettingsWithoutContent = {
  animation: { type: "fade", duration: 100 },
  type: { icon: false },
  position: { horizontal: "right", vertical: "top" },
  closeTitle: "Benachrichtigung schließen",
  hideAfter: 3000,
};

/**
 * Flüchtige Benachrichtigungen die aus dem Backend heraus über den WebSocket
 * empfangen werden und den Benutzer darüber informieren, dass ein anderer Benutzer
 * Änderungen gemacht hat die ein Neuladen der aktuellen Seite (Ansicht oder auch
 * On Demand Recherche) erfordern.
 */
export const reloadNotificationSettings: NotificationSettingsWithoutContent = {
  animation: { type: "fade", duration: 100 },
  position: { horizontal: "center", vertical: "bottom" },
  type: { icon: false },
  hideAfter: 0,
  closable: true,
};

/**
 * Standard-Benachrichtigungen für bestimmte `NotificationStyle`
 *
 * z.B.: _Eine Fehler-Benachrichtigung soll immer manuell geschlossen werden_
 */
export const defaultNotificationSettings: Record<
  NotificationStyle,
  NotificationSettingsWithoutContent
> = {
  None: { ...defaultNotification },
  Error: { ...defaultNotification, closable: true, hideAfter: 0 },
  Success: { ...defaultNotification },
  Warning: { ...defaultNotification },
  Info: { ...defaultNotification },
};

/**
 * Verschiedene Typen für die Benachrichtigungstemplates
 */
export enum TemplateType {
  // errors
  saveError = "saveError",
  // success
  saveSuccess = "saveSuccess",
  // Wenn VPFs in einer Mehrfachauswahl aus der Ansicht verschoben werden würden
  moveOutOfAnsicht = "moveOutOfAnsicht",
  // Verlinkungen wurden entfernt
  removedVerlinkungen = "removedVerlinkungen",
  // ...
}

/**
 * Vorgefertigte Benachrichtigungstemplates
 *
 * z.B.: _Beim erfolgreichen Speichern soll immer dieselbe Benachrichtigung angezeigt werden_
 */
export const templates: Record<TemplateType, SideEffectNotificationDto> = {
  saveError: {
    type: NotificationStyle.ERROR,
    nachricht: "Die Daten konnten nicht gespeichert werden! %1",
  },
  saveSuccess: {
    type: NotificationStyle.SUCCESS,
    nachricht: "Die Daten konnten erfolgreich gespeichert werden!",
  },
  moveOutOfAnsicht: {
    type: NotificationStyle.WARNING,
    nachricht:
      "Programme können nicht umgeplant werden, da mindestens ein Programm außerhalb des derzeit angezeigten Zeitbereichs geschoben werden würde",
  },
  removedVerlinkungen: {
    type: NotificationStyle.INFO,
    nachricht:
      "Verlinkungen zum ausgeplanten Programm wurden aufgelöst. Verlinkte Programme wurden nicht ausgeplant.",
  },
};
