export const VALID_ANSICHTEN_TITEL = [
  "Jahresansicht",
  "Wochenende Primetime",
  "Fernsehfilm der Woche",
  "Dienstag 20.15f",
  "Mittwoch 20.15",
  "Donnerstag 20.15",
  "Freitagabend Serie",
  "Sonntag / Montag 22.15",
  "Dienstag 2. Primetime",
  "Mittwoch 2. Primetime",
  "Donnerstag 2. Primetime",
  "Freitag 2. Primetime",
  "Serien 18.00",
  "Serien 19.25",
  "Samstag Daytime",
  "Sonntag Daytime",
  "Spielfilmnacht",
  "Kleines Fernsehspiel",
  "Sa Morgen",
  "So Morgen",
  "Di 20.15ff",
  "Mi 20.15f",
  "Do Nacht",
  "Fr Nacht",
  "So 20.15ff",
] as const;

export type AnsichtenTitel = (typeof VALID_ANSICHTEN_TITEL)[number];
export const ANSICHTEN_TITEL: Record<AnsichtenTitel, string> = {
  Jahresansicht: "JAHR 20.15 +",
  "Wochenende Primetime": "WE PRIME",
  "Fernsehfilm der Woche": "MO 20.15",
  "Dienstag 20.15f": "DI 20.15f",
  "Mittwoch 20.15": "MI 20.15",
  "Donnerstag 20.15": "DO 20.15",
  "Freitagabend Serie": "FR 20.15f",
  "Sonntag / Montag 22.15": "SO&MO 22.15",
  "Dienstag 2. Primetime": "DI 22.15ff",
  "Mittwoch 2. Primetime": "MI 22.15ff",
  "Donnerstag 2. Primetime": "DO 22.15ff",
  "Freitag 2. Primetime": "FR 22.15ff",
  "Serien 18.00": "SERIEN 18.00",
  "Serien 19.25": "SERIEN 19.25",
  "Samstag Daytime": "SA DAYTIME",
  "Sonntag Daytime": "SO DAYTIME",
  Spielfilmnacht: "FILMNACHT",
  "Kleines Fernsehspiel": "DkF",
  "Sa Morgen": "SA MORGEN",
  "So Morgen": "SO MORGEN",
  "Di 20.15ff": "DI 20.15ff",
  "Mi 20.15f": "MI 20.15f",
  "Do Nacht": "DO NACHT",
  "Fr Nacht": "FR NACHT",
  "So 20.15ff": "SO 20.15ff",
};
