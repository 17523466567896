import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { forkJoin, map, of, switchMap } from "rxjs";
import { DurationService } from "src/app/services/duration.service";
import { SendeplatzService } from "src/app/services/sendeplatz.service";
import { sendeplatzActions } from "../sendeplatz/sendeplatz.actions";
import { durationActions } from "./duration.actions";

@Injectable()
export class DurationWindowEffects {
  openUnifyDurationDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(durationActions.openUnifyDurationDialog),
      switchMap(
        ({ groupsMitMengengeruest, sendeplatzLaengeInSeconds, planungsobjektLaengeInSeconds }) =>
          this.durationService.openUnifyDurationDialog({
            groupsMitMengengeruest,
            sendeplatzLaengeInSeconds,
            planungsobjektLaengeInSeconds,
          }).result,
      ),
      map((result) => durationActions.handleUnifyDurationDialogResult({ result })),
    );
  });

  handleUnifyDurationDialogResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(durationActions.handleUnifyDurationDialogResult),
      switchMap(({ result }) => {
        if (result instanceof DialogCloseResult) return of(durationActions.dialogClosed());
        if (!result.data) throw new Error("No data in result");
        const updates = result.data.map((sendeplatz) =>
          this.sendeplatzService.updateSendeplatz$(sendeplatz),
        );
        return forkJoin(updates).pipe(
          map((sendeplaetze) =>
            sendeplatzActions.updateSendeplaetzeByAnsichtenSuccess({
              sendeplaetze,
            }),
          ),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private durationService: DurationService,
    private sendeplatzService: SendeplatzService,
  ) {}
}
