import { createActionGroup, props } from "@ngrx/store";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { MultiAnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

export const konkurrenzprogrammActions = createActionGroup({
  source: "Konkurrenzprogramme API",
  events: {
    loadKonkurrenzprogrammeByAnsichten: props<{
      multiAnsichtViewModel: MultiAnsichtViewModel;
    }>(),
    loadKonkurrenzprogrammeByAnsichtenSuccess: props<{
      konkurrenzprogramme: KonkurrenzprogrammDto[];
    }>(),
    loadKonkurrenzprogrammeByYearSuccess: props<{
      konkurrenzprogramme: KonkurrenzprogrammDto[];
    }>(),
    createKonkurrenzprogramm: props<{
      konkurrenzprogramm: KonkurrenzprogrammDto;
    }>(),
    createKonkurrenzprogrammSuccess: props<{
      konkurrenzprogramm: KonkurrenzprogrammDto;
    }>(),
    updateKonkurrenzprogramm: props<{
      konkurrenzprogramm: KonkurrenzprogrammDto;
    }>(),
    updateKonkurrenzprogrammSuccess: props<{
      konkurrenzprogramm: KonkurrenzprogrammDto;
    }>(),
    deleteKonkurrenzprogramm: props<{
      konkurrenzprogramm: KonkurrenzprogrammDto;
    }>(),
    deleteKonkurrenzprogrammSuccess: props<{
      konkurrenzprogrammId: string;
    }>(),
    copyKonkurrenzprogramm: props<{
      konkurrenzprogramm: KonkurrenzprogrammDto;
      copyPattern: CopyPattern;
    }>(),
    copyKonkurrenzprogrammSuccess: props<{
      konkurrenzprogramm: KonkurrenzprogrammDto;
    }>(),
    convertEventToKonkurrenzprogramm: props<{
      konkurrenzprogramm: KonkurrenzprogrammDto;
      convertedGUID: string;
    }>(),
    convertEventToKonkurrenzprogrammSuccess: props<{
      konkurrenzprogramm: KonkurrenzprogrammDto;
      convertedGUID: string;
    }>(),
    upsertKonkurrenzprogrammeForBlockansicht: props<{
      konkurrenzprogramme: KonkurrenzprogrammDto[];
    }>(),
    setAllKonkurrenzprogramme: props<{
      konkurrenzprogramme: KonkurrenzprogrammDto[];
    }>(),
  },
});
