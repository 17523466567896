import { Component } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { tap } from "rxjs";
import { ansichtActions } from "src/app/core/stores/ansicht/ansicht.actions";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import routerSelectors from "src/app/core/stores/router/router.selectors";
import { ReloadNotificationService } from "src/app/shared/notifications/reload-notification.service";

@Component({
  selector: "app-year-dropdown",
  templateUrl: "./year-dropdown.component.html",
  styleUrls: ["./year-dropdown.component.scss"],
})
export class YearDropdownComponent {
  readonly years$ = this.store.select(ansichtSelectors.selectAnsichtPossibleYears);
  readonly selectedYear$ = this.store
    .select(routerSelectors.selectYearFromQueryParamOrDefault)
    .pipe(
      tap(() => {
        this.notificationService.destroyBroadcastNotificationIfExists();
      }),
    );

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: ReloadNotificationService,
  ) {
    this.route.queryParamMap
      .pipe(
        takeUntilDestroyed(),
        tap((params) => this.checkForAvailableParams(params)),
      )
      .subscribe();
  }

  protected onYearChange(year: number) {
    this.store.dispatch(ansichtActions.navigateCurrentAnsichtToYear({ year }));
  }

  private checkForAvailableParams(params: ParamMap) {
    // Verhindert, dass beim Aufruf von '/ansichten' eine leere Ansicht angezeigt wird.
    // Sollte in Zukunft über das 'app-routing.module.ts' gelöst werden. Hierfür müssten ggf. die Routen
    // angepasst werden (Routen Parameter anstatt optionaler Query Parameter?).
    if (params.keys.length === 0) void this.router.navigate(["dashboard"]);
  }
}
