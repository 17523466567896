import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ZoomLevel } from "src/app/models/openapi/model/zoom-level";

export const blockansichtActions = createActionGroup({
  source: "Blockansicht",
  events: {
    setCurrentZoomLevel: props<{
      zoomLevel: ZoomLevel;
    }>(),
    setNextZoomLevel: props<{
      zoomIn: boolean;
    }>(),
    getNextZoomLevel: props<{ zoomIn: boolean }>(),
    resetZoomLevel: emptyProps(),
    addManuelleVariantenzeile: props<{ sendetag: string; maxVariante: number }>(),
    removeManuelleVariantenzeile: props<{ sendetag: string; variante: number }>(),
  },
});
