<kendo-datepicker
  [disabled]="isDisabled"
  [disabledDates]="isDateDisabled"
  [focusedDate]="focusedDate | toDate"
  [max]="maxDate | toDate"
  [min]="minDate | toDate"
  [value]="internalValue"
  (blur)="_onTouched()"
  (valueChange)="onChange($event)"
></kendo-datepicker>
