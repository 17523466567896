<kendo-chip-list
  #target="kendoContextMenuTargetContainer"
  class="vorschlag-liste"
  kendoContextMenuTargetContainer
>
  <ng-container *ngFor="let planungsobjektInVorschlagspalte of planungsobjekteInVorschlagspalte">
    <app-planungsobjekt-vorschlag
      [planungsobjektInVorschlagspalte]="planungsobjektInVorschlagspalte"
      [sendeplatz]="sendeplatz"
      (doubleClicked)="openPlanungsobjektLinearWithPlanungWindow($event)"
    >
    </app-planungsobjekt-vorschlag>

    <kendo-contextmenu
      showOn="contextmenu"
      [target]="target"
      (popupOpen)="onVorschlagContextMenuOpen($event)"
      (select)="onPlanungsobjektContextMenuSelect($event)"
    >
      <kendo-menu-item
        *ngIf="settings.features.onDemandPlanung"
        text="Details"
        [data]="{ action: AktionEnum.BEARBEITE_PLANUNGSOBJEKT }"
        [svgIcon]="Icons.information"
      ></kendo-menu-item>
      <kendo-menu-item
        text="Kopie anlegen"
        [data]="{
          action: AktionEnum.KOPIE_HIER
        }"
        [svgIcon]="Icons.copy"
      ></kendo-menu-item>
    </kendo-contextmenu>
  </ng-container>
</kendo-chip-list>
