import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { TitleShortenerPipe } from "src/app/shared/pipes/title-format.pipe";
import BlockansichtFacade from "./blockansicht.facade";

@Component({
  selector: "app-blockansicht",
  templateUrl: "./blockansicht.component.html",
  styleUrls: ["./blockansicht.component.scss"],
  providers: [TitleShortenerPipe, BlockansichtFacade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockansichtComponent {
  public readonly facade = inject(BlockansichtFacade);

  @Input() showNotizen: boolean;
  @Input() showKonkurrenzEvents: boolean;
  @Input() ansichtViewModel: AnsichtViewModel;
}
