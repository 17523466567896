import { FrontendAuthConfiguration } from "src/app/models/openapi/model/frontend-auth-configuration";
import { FrontendFeatureToggles } from "src/app/models/openapi/model/frontend-feature-toggles";
import { FrontendSettings } from "src/app/models/openapi/model/frontend-settings";

/**
 * Klasse, die sämtliche FrontendSettings beinhaltet.
 * Wird in main.ts als Provider Token verwendet und kann dadurch überall injected werden.
 */
export class PublitFrontendSettings implements FrontendSettings {
  production: boolean;
  apiUrl: string;
  auth: FrontendAuthConfiguration;
  logoUrl: string;
  features: FrontendFeatureToggles;
  produktDbBaseUrl: string;

  constructor(settings: FrontendSettings) {
    Object.assign(this, settings);
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
