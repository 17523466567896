/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Farben definiert in:  https://jira.zdf.de/jira/browse/PUBLIT-165
 */
export type PlanungsobjektFarbgebung =
  | "Keine"
  | "Grau"
  | "Gruen"
  | "Gelb"
  | "Orange"
  | "Lila"
  | "Blau"
  | "Rosa"
  | "Petrol"
  | "Dunkelblau";

export const PlanungsobjektFarbgebung = {
  KEINE: "Keine" as PlanungsobjektFarbgebung,
  GRAU: "Grau" as PlanungsobjektFarbgebung,
  GRUEN: "Gruen" as PlanungsobjektFarbgebung,
  GELB: "Gelb" as PlanungsobjektFarbgebung,
  ORANGE: "Orange" as PlanungsobjektFarbgebung,
  LILA: "Lila" as PlanungsobjektFarbgebung,
  BLAU: "Blau" as PlanungsobjektFarbgebung,
  ROSA: "Rosa" as PlanungsobjektFarbgebung,
  PETROL: "Petrol" as PlanungsobjektFarbgebung,
  DUNKELBLAU: "Dunkelblau" as PlanungsobjektFarbgebung,
};
