import { WindowSettings } from "@progress/kendo-angular-dialog";

const smallWidth = () => window.innerWidth / 3;
const mediumWidth = () => window.innerWidth / 2;
const bigWidth = () => window.innerWidth / 1.5;

const smallHeight = () => window.innerHeight / 5;
const mediumHeight = () => window.innerHeight / 4;
const bigHeight = () => window.innerHeight / 3.5;
/**
 * Kleines Fenster
 */
export const SmallWindow = ({
  dataTestId = null,
}: { dataTestId?: string | null } = {}): WindowSettings => {
  const htmlAttributes: { [key: string]: string } = {};

  if (dataTestId !== null) {
    htmlAttributes["data-testid"] = dataTestId;
  }

  htmlAttributes["tabindex"] = "0";
  return {
    top: window.innerHeight / 7,
    left: (window.innerWidth - smallWidth()) / 2,
    width: smallWidth(),
    minHeight: smallHeight(),
    cssClass: "max-window-height",
    htmlAttributes,
  };
};
/**
 * Mittelbreites Fenster
 */
export const MediumWindow = ({
  dataTestId = null,
}: { dataTestId?: string | null } = {}): WindowSettings => {
  const htmlAttributes: { [key: string]: string } = {};

  if (dataTestId !== null) {
    htmlAttributes["data-testid"] = dataTestId;
  }

  htmlAttributes["tabindex"] = "0";

  return {
    top: window.innerHeight / 7,
    left: (window.innerWidth - mediumWidth()) / 2,
    width: mediumWidth(),
    minHeight: mediumHeight(),
    cssClass: "max-window-height",
    htmlAttributes,
  };
};

/**
 * Großes Fenster
 */
export const BigWindow = ({
  dataTestId = null,
}: { dataTestId?: string | null } = {}): WindowSettings => {
  const htmlAttributes: { [key: string]: string } = {};

  if (dataTestId !== null) {
    htmlAttributes["data-testid"] = dataTestId;
  }

  htmlAttributes["tabindex"] = "0";

  return {
    top: window.innerHeight / 7,
    left: (window.innerWidth - bigWidth()) / 2,
    width: bigWidth(),
    minHeight: bigHeight(),
    cssClass: "max-window-height",
    htmlAttributes,
  };
};
