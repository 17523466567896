<ng-container
  *ngIf="{
    control: control$ | async,
    isInvalid: isInvalid$ | async,
    isRequired: isRequired$ | async
  } as vm"
>
  <label class="like-floating-label" [for]="for">
    <div class="k-d-flex k-align-items-center k-justify-content-between">
      <span
        class="label-text"
        [ngClass]="{
          'u-text-error': vm.isInvalid && vm.control?.touched
        }"
        >{{ text }}{{ vm.isRequired ? " *" : "" }}</span
      >
      <span
        *ngIf="loading"
        class="k-i-loading u-text-primary-contrast"
        data-testid="loading-indicator"
      ></span>
      <ng-content select="[showAfterLabel]"></ng-content>
    </div>
    <!-- zeige den Inhalt an, der von außen mitgegeben wird -->
    <ng-content></ng-content>
  </label>
</ng-container>
