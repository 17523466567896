import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "../planungsobjekt-window.model";

@Component({
  selector: "app-verlinkung-tab",
  templateUrl: "./verlinkung-tab.component.html",
  styleUrls: ["./verlinkung-tab.component.scss"],
})
export class VerlinkungTabComponent {
  @Input({ required: true }) input: PlanungsobjektWindowInputWithPlanungsobjekt;
  /**
   * Planungsobjekt wird über Selektoren geladen, damit immer der aktuelle Stand sichtbar ist.
   */
  @Input({ required: true }) currentPlanungsobjekt: PlanungsobjektDto | undefined;

  @Input({ required: true }) hasPlanungsobjektOnDemandId = false;
  @Input({ required: true }) reihenfolgen: BeziehungDto[];
  @Input({ required: true }) abhaengigkeiten: BeziehungDto[];
  @Input({ required: true }) onDemandBeziehungen: BeziehungDto[];
  @Input({ required: true }) linearOnDemandBeziehungOnDemandObjekt:
    | PlanungsobjektOnDemandDto
    | undefined;
  @Input({ required: true }) readOnly = false;

  @Output() deleteBeziehung = new EventEmitter<BeziehungDto>();

  PlanungsobjektWindowUseCase = PlanungsobjektWindowUseCase;
  showOnDemandBeziehung = false;

  onToggleOnDemandBeziehungForm() {
    this.showOnDemandBeziehung = !this.showOnDemandBeziehung;
  }
}
