import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { take, tap } from "rxjs";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import metadatenActions from "src/app/core/stores/metadaten/metadaten.actions";
import metadatenSelectors from "src/app/core/stores/metadaten/metadaten.selectors";
import permissionsSelectors from "src/app/core/stores/permissions/permissions.selectors";
import { Icons } from "src/app/models/icons";
import { AnsichtsdefinitionDto } from "src/app/models/openapi/model/ansichtsdefinition-dto";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { PublitPermissionDto } from "src/app/models/openapi/model/publit-permission-dto";
import { Role } from "src/app/models/openapi/model/role";
import { DebugService } from "src/app/services/debug.service";
import { CustomNotificationService } from "../../notifications/custom-notification.service";

@Component({
  templateUrl: "./information-window.component.html",
  styleUrls: ["./information-window.component.scss"],
})
export class InformationWindowComponent implements OnInit {
  protected buildVersion$ = this.store.select(metadatenSelectors.selectVersionInformationFormatted);
  protected permissions$ = this.store.select(permissionsSelectors.selectPermissionsAll);
  protected ansichtsdefinitionen$ = this.store.select(ansichtSelectors.selectAnsichtsdefinitionen);

  Role = Role;
  Icons = Icons;

  constructor(
    private store: Store,
    private debugService: DebugService,
    private notificationService: CustomNotificationService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(metadatenActions.loadVersionInformation());
  }

  // Eigentlich wollen wir Funktionsaufrufe in Templates vermeiden,
  // an dieser Stelle ist die Performance jedoch nicht relevant da der
  // Codepfad nur durchlaufen wird, wenn der Info-Dialog geöffnet ist
  getGeltungsbereiche(
    permission: PublitPermissionDto,
    ansichtsdefinitionen: AnsichtsdefinitionDto[],
  ) {
    if (permission.geltungsbereiche === undefined || permission.geltungsbereiche === null) {
      return "";
    }

    const geltungsbereiche =
      permission.role === Role.PLANUNGSOBJEKT_ZDF_PLANEN ||
      permission.role === Role.PLANUNGSOBJEKT_ZDF_NEO_PLANEN
        ? permission.geltungsbereiche.map((ansichtsdefinitionId) =>
            this.getAnsichtsdefinitionName(ansichtsdefinitionId, ansichtsdefinitionen),
          )
        : permission.geltungsbereiche;

    const list = geltungsbereiche.join(", ");
    return ` (${list})`;
  }

  getAnsichtsdefinitionName(
    ansichtsdefinitionId: string,
    ansichtsdefinitionen: AnsichtsdefinitionDto[],
  ) {
    const ansichtsdefinition = ansichtsdefinitionen.find(
      (ansichtsdefinition) => ansichtsdefinition.id === ansichtsdefinitionId,
    );
    return ansichtsdefinition?.name ?? ansichtsdefinitionId;
  }

  join(values: string[]) {
    return values.join(", ");
  }

  clearCache() {
    this.debugService
      .clearCache$()
      .pipe(
        take(1),
        tap(() => {
          this.notificationService.showNotification(
            "Der Cache im Backend wurde erfolgreich geleert.",
            NotificationStyle.SUCCESS,
          );
        }),
      )
      .subscribe();
  }
}
