import { Component, EventEmitter, Input, Output, TrackByFunction, ViewChild } from "@angular/core";
import {
  CellClickEvent,
  ColumnComponent,
  ColumnVisibilityChangeEvent,
  GridDataResult,
} from "@progress/kendo-angular-grid";
import { ContextMenuComponent, ContextMenuSelectEvent } from "@progress/kendo-angular-menu";
import {
  ActiveGridColumnProperties,
  GRID_ROW_HEIGHT,
  GridResultColumnFields,
  RechercheGridResultColumn,
  RechercheSearchResultVM,
} from "src/app/core/stores/recherche/recherche.model";
import { FSKEinstufungRecord } from "src/app/models/enums/fsk-einstufung";
import { GenreRecord } from "src/app/models/enums/genre";
import { PlanungskontextRecord } from "src/app/models/enums/planungskontext";
import { ProduktStatusRecord } from "src/app/models/enums/produktstatus";
import { RedaktionRecord } from "src/app/models/enums/redaktion";
import { StofffuehrendeRedaktionRecord } from "src/app/models/enums/stofffuehrende-redaktion";
import { Icons } from "src/app/models/icons";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { planungsobjektFarbgebungMap } from "src/app/models/viewmodels";
import { ColorMapToReturnValue } from "src/app/shared/pipes/color-map-to-ng-style/color-map-to-ng-style.pipe";

@Component({
  selector: "app-search-results-details-grid",
  templateUrl: "./search-results-details-grid.component.html",
  styleUrl: "./search-results-details-grid.component.scss",
})
export class SearchResultsDetailsGridComponent {
  @Input({ required: true }) activeRechercheColumns: Partial<
    Record<RechercheGridResultColumn, ActiveGridColumnProperties>
  >;
  @Input({ required: true }) rechercheShownGridColumnsInOrder: ActiveGridColumnProperties[];
  @Input({ required: true }) data: GridDataResult;
  @Input({ required: true }) pageSize: number | undefined;
  @Input({ required: true }) skip: number | undefined;
  @Output() resultSelected = new EventEmitter<RechercheSearchResultVM>();
  @Output() toggleGridColumn = new EventEmitter<RechercheGridResultColumn[]>();

  gridRowHeight = GRID_ROW_HEIGHT;

  readonly RedaktionRecord = RedaktionRecord;
  readonly PlanungskontextRecord = PlanungskontextRecord;
  readonly GenreRecord = GenreRecord;
  readonly Icons = Icons;
  readonly ColorMapToReturnValue = ColorMapToReturnValue;
  readonly Planungskontext = Planungskontext;
  readonly planungsobjektFarbgebungMap = planungsobjektFarbgebungMap;
  readonly GridResultColumnFields = GridResultColumnFields;
  readonly ProduktStatusRecord = ProduktStatusRecord;
  readonly StofffuehrendeRedaktionRecord = StofffuehrendeRedaktionRecord;
  readonly FSKEinstufungRecord = FSKEinstufungRecord;

  selectedRow: RechercheSearchResultVM | null;

  @ViewChild("gridContextMenu")
  public gridContextMenu: ContextMenuComponent;

  trackByField: TrackByFunction<ActiveGridColumnProperties> = (_, item) => item.field;
  /**
   * Kendo bietet nur ein Click Event an, in welchem wir Informationen über die aktuell ausgewählte Zeile erhalten.
   * Diese muss kurz zwischengespeichert und im eigentlich DoubleClick/ContextMenuSelect-Event ausgewertet werden.
   * https://www.telerik.com/forums/kendo-grid-row-double-single-click-event
   */
  onRowClick(event: CellClickEvent) {
    if (event.type === "click" || event.type == "contextmenu") {
      this.selectedRow = event.dataItem as RechercheSearchResultVM;
    }

    // Basierend auf:
    // https://www.telerik.com/kendo-angular-ui/components/menus/contextmenu/data-bound-components/#toc-grid
    if (event.type === "contextmenu") {
      const originalEvent = event.originalEvent as PointerEvent;
      originalEvent.preventDefault();
      this.gridContextMenu.show({
        left: originalEvent.pageX,
        top: originalEvent.pageY,
      });
    }
  }

  onRowDoubleClick() {
    this.showDetails();
  }

  onContexMenuSelect(_: ContextMenuSelectEvent) {
    // Da wir aktuell nur eine Aktion im Kontext Menü anbieten ist eine Unterscheidung aktuell nicht nötig
    this.showDetails();
  }

  private showDetails() {
    if (this.selectedRow) {
      this.resultSelected.emit(this.selectedRow);
      this.selectedRow = null;
    }
  }

  onToggleGridColumn(event: ColumnVisibilityChangeEvent) {
    const fields = event.columns
      .filter((column): column is ColumnComponent => column instanceof ColumnComponent)
      .map((column) => column.field as RechercheGridResultColumn);
    this.toggleGridColumn.emit(fields);
  }
}
