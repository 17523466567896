import { Component, inject } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { Store } from "@ngrx/store";
import planungsobjektWindowWannWoSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window-wann-wo.selectors";

@Component({
  selector: "app-wann-bezug-on-demand",
  templateUrl: "./wann-bezug-on-demand.component.html",
  styleUrls: ["./wann-bezug-on-demand.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class WannBezugOnDemandComponent {
  private readonly store = inject(Store);

  readonly onlineAb$ = this.store.select(planungsobjektWindowWannWoSelectors.selectOnlineAb);
  readonly onlineBis$ = this.store.select(planungsobjektWindowWannWoSelectors.selectOnlineBis);
}
