import { Pipe, PipeTransform } from "@angular/core";

/**
 * Transforms a number (which will be interpreted as seconds) into a duration in format MMM:SS.
 * e.g.: 120 -> 02:00, 98 -> 01:38
 */
@Pipe({
  name: "duration",
})
export class DurationPipe implements PipeTransform {
  static transform(value: string | number | undefined | null, nrOfMinuteDigits: number): string {
    if (value === undefined || value === null || (typeof value === "string" && !value)) {
      return "";
    }

    const numberValue = typeof value === "string" ? parseInt(value, 10) : value;

    if (isNaN(numberValue) || numberValue < 0) {
      throw new Error(
        `DurationPipe muss mit einer natürlichen, positiven Zahl befüllt werden. Bekommen: ${value}`,
      );
    }
    const minutes = Math.floor(numberValue / 60);
    const seconds = numberValue % 60;
    const minutesAsString = minutes.toString().padStart(nrOfMinuteDigits, "0");
    const secondsAsString = seconds.toString().padStart(2, "0");
    return `${minutesAsString}:${secondsAsString}`;
  }

  static transformBack(text: string | null | undefined): number {
    if (!text) {
      return 0;
    }

    text = text.replace(/ /g, "0").replace(":", "");
    const textLength = text.length;

    if (textLength <= 2) {
      return parseInt(text, 10);
    }

    const minutes = parseInt(text.substring(0, textLength - 2));
    const seconds = parseInt(text.substring(textLength - 2));

    const transformed = minutes * 60 + seconds;
    return transformed;
  }

  static getMinutes(text: string): number {
    if (!text) {
      return 0;
    }

    text = text.replace(/ /g, "0").replace(":", "");
    const textLength = text.length;

    if (textLength <= 2) {
      return parseInt(text, 10);
    }

    return parseInt(text.substring(0, textLength - 2));
  }

  transform(value: string | number | null | undefined, nrOfMinuteDigits = 2): string {
    return DurationPipe.transform(value, nrOfMinuteDigits);
  }
}
