import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LogRequest } from "src/app/models/openapi/model/log-request";
import { LogResponse } from "src/app/models/openapi/model/log-response";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/shared/interceptors/http-loading.interceptor";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LogApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  createLog$(body: LogRequest): Observable<LogResponse> {
    return this.http.post<LogResponse>(`${this.apiUrl}Log/Frontend`, body, {
      context: new HttpContext().set(SKIP_LOADING_INTERCEPTOR, true),
    });
  }
}
