import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { debounceTime, Subject } from "rxjs";
import { ZOOM_LEVELS, ZOOM_LEVELS_LIST } from "src/app/models/enums/zoom-level";
import { ZoomLevel } from "src/app/models/openapi/model/zoom-level";

@Component({
  selector: "app-zoom-slider",
  templateUrl: "./zoom-slider.component.html",
  styleUrls: ["./zoom-slider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomSliderComponent implements OnInit {
  @Input() selectedZoomLevel: ZoomLevel;
  @Output() selectedZoomLevelChanged = new EventEmitter<ZoomLevel>();

  destroyRef = inject(DestroyRef);
  protected zoomLevels = ZOOM_LEVELS;
  protected zoomSliderChangedSubject = new Subject<number>();

  ngOnInit(): void {
    // Workaround um eine DebounceTime für den valueChange des Kendo-Sliders festzulegen
    this.zoomSliderChangedSubject
      .pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe((zoomLevel) => {
        this.selectedZoomLevelChanged.emit(ZOOM_LEVELS_LIST[zoomLevel].key);
      });
  }

  getTitleInMinutesForTicks(value: number) {
    // Workaround, um korrekte Tooltips mit Minutenwerten zu setzen
    return `${ZOOM_LEVELS_LIST[value].minutesPerBlock}min`;
  }
}
