import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs";
import { MerklisteApiService } from "src/app/api/merkliste/merkliste.api.service";
import { multiansichtActions } from "../multiansicht/multiansicht.actions";
import { merklisteActions } from "./merkliste.actions";

@Injectable()
export class MerklisteEffects {
  loadMerklisten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.setMultiansichtSuccess),
      map(({ multiAnsichtViewModel }) => {
        return merklisteActions.loadMerklistenAnsicht({ multiAnsichtViewModel });
      }),
    );
  });

  loadMerklistenSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(merklisteActions.loadMerklistenAnsicht),
      switchMap(({ multiAnsichtViewModel }) => {
        const ansichtId = multiAnsichtViewModel.ansichtViewModels.find(
          (ansichtPublikationViewModel) => ansichtPublikationViewModel.primary,
        )?.ansichtViewModel.id;
        if (!ansichtId) {
          throw new Error("Für die Merkliste konnte keine primäre Ansicht gefunden werden.");
        }
        return this.apiService
          .getMerklistenByAnsichtId$(ansichtId)
          .pipe(map((merklisten) => merklisteActions.loadMerklistenSuccess({ merklisten })));
      }),
    );
  });

  loadMerklistenOnDemand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(merklisteActions.loadMerklistenOnDemand),
      switchMap(({ filter }) => {
        return this.apiService
          .getMerklistenByOnDemand$(filter)
          .pipe(map((merklisten) => merklisteActions.loadMerklistenSuccess({ merklisten })));
      }),
    );
  });

  renameMerkliste$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(merklisteActions.renameMerklisteAnsicht),
      switchMap(({ command }) => {
        return this.apiService
          .renameMerklisteAnsicht$(command)
          .pipe(map((merkliste) => merklisteActions.renameMerklisteSuccess({ merkliste })));
      }),
    );
  });

  renameMerklisteOnDemand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(merklisteActions.renameMerklisteOnDemand),
      switchMap(({ command }) => {
        return this.apiService
          .renameMerklisteOnDemand$(command)
          .pipe(map((merkliste) => merklisteActions.renameMerklisteSuccess({ merkliste })));
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private apiService: MerklisteApiService,
  ) {}
}
