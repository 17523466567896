import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AdditionalFilter, FilterEnum } from "src/app/core/stores/recherche/recherche.model";

export const AdditionalFiltersRecord = {
  [FilterEnum.TITEL]: { text: "Titelsuche", columnKey: "titel", type: FilterEnum.TITEL },
  [FilterEnum.GENRE]: { text: "PLP Genre", columnKey: "genre", type: FilterEnum.GENRE },
  [FilterEnum.PLANUNGSKONTEXT]: {
    text: "Planungskontext",
    columnKey: "planungskontext",
    type: FilterEnum.PLANUNGSKONTEXT,
  },
  [FilterEnum.CONTENT_COMMUNITY]: {
    text: "Zielgruppe",
    columnKey: "contentCommunities",
    type: FilterEnum.CONTENT_COMMUNITY,
  },
} satisfies Partial<Record<FilterEnum, AdditionalFilter>>;

@Component({
  selector: "app-additional-filters",
  templateUrl: "./additional-filters.component.html",
  styleUrls: ["./additional-filters.component.scss"],
})
export class AdditionalFiltersComponent {
  @Input() selectedFilters: FilterEnum[] = [];
  @Output() selectionChange = new EventEmitter<FilterEnum[]>();

  /**
   * Liste der verfügbaren Filter für Kendo MultiSelect
   */
  readonly additionalFilters: AdditionalFilter[] = Object.values(AdditionalFiltersRecord);

  valueChange(filters: FilterEnum[]) {
    this.selectionChange.emit(filters);
  }
}
