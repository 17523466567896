import { Pipe, PipeTransform } from "@angular/core";
import { EventKategorieRecord } from "src/app/models/enums/event-kategorie";
import { KonkurrenzKategorieRecord } from "src/app/models/enums/konkurrenz-kategorie";
import { EventKategorie } from "src/app/models/openapi/model/event-kategorie";
import { KonkurrenzprogrammKategorie } from "src/app/models/openapi/model/konkurrenzprogramm-kategorie";

@Pipe({
  name: "ekCssClass",
})
export class EkCssClassPipe implements PipeTransform {
  EventKategorieRecord = EventKategorieRecord;
  KonkurrenzKategorieRecord = KonkurrenzKategorieRecord;

  transform(kategorie?: EventKategorie | KonkurrenzprogrammKategorie | null): string {
    {
      if (kategorie in KonkurrenzKategorieRecord) {
        return this.getKonkurrenzClass(kategorie as KonkurrenzprogrammKategorie);
      } else if (kategorie in EventKategorieRecord)
        return this.getEventClass(kategorie as EventKategorie);
      else {
        return "";
      }
    }
  }

  private getKonkurrenzClass(kategorie: KonkurrenzprogrammKategorie): string {
    switch (kategorie) {
      case KonkurrenzprogrammKategorie.FICTION:
        return "fiction";
      case KonkurrenzprogrammKategorie.SHOW:
        return "show";
      case KonkurrenzprogrammKategorie.SPORT:
        return "sport";
      default:
        return "";
    }
  }

  private getEventClass(kategorie: EventKategorie): string {
    switch (kategorie) {
      case EventKategorie.FEIERTAG:
        return "event-label-feiertag";
      case EventKategorie.GEDENKTAG:
        return "event-label-gedenktag";
      case EventKategorie.THEMENTAG_WOCHE:
        return "event-label-thementag";
      case EventKategorie.SPORTVERANSTALTUNG:
        return "event-label-sportveranstaltung";
      case EventKategorie.GESELLSCHAFTLICHES_EREIGNIS:
        return "event-label-gesellschaftliches-ereignis";
      case EventKategorie.POLITISCHES_EREIGNIS:
        return "event-label-politisches-ereignis";
      case EventKategorie.KEINE_KATEGORIE:
        return "";
      default:
        return "";
    }
  }
}
