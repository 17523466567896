import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReportApiService } from "../api/report/report.api.service";
import { Kanal } from "../models/openapi/model/kanal";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private reportApiService: ReportApiService) {}

  getReportByAnsicht(ansichtId: string) {
    this.reportApiService.getReportByAnsicht$(ansichtId).subscribe({
      next: (resp: HttpResponse<Blob>) => {
        if (resp.body == null) {
          throw Error("Fehler beim Report Generieren. Leere Antwort vom Server erhalten.");
        }
        const blob: Blob = new Blob([resp.body], { type: "application/vnd.ms-excel" });
        const regExpFilename = /filename="?\b(?<filename>.*)\b"?;/;
        const filename: string =
          regExpFilename.exec(resp?.headers?.get("content-disposition") ?? "null")?.groups
            ?.filename ?? "Report.xls";

        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadURL;
        link.download = filename;
        link.click();
      },
      error: () => {
        throw new Error("Report Generierung fehlgeschlagen.");
      },
    });
  }

  getReportWithMengengeruesteintraegenByYear(ausspielweg: Kanal, year: number) {
    this.reportApiService.getReportWithMengengeruesteintraegenByYear$(ausspielweg, year).subscribe({
      next: (resp: HttpResponse<Blob>) => {
        if (resp.body == null) {
          throw Error("Fehler beim Report Generieren. Leere Antwort vom Server erhalten.");
        }
        const blob: Blob = new Blob([resp.body], { type: "application/vnd.ms-excel" });
        const regExpFilename = /filename="?\b(?<filename>.*)\b"?;/;
        const filename: string =
          regExpFilename.exec(resp?.headers?.get("content-disposition") ?? "null")?.groups
            ?.filename ?? "Report.xls";

        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadURL;
        link.download = filename;
        link.click();
      },
      error: () => {
        throw new Error("Report Generierung fehlgeschlagen.");
      },
    });
  }
}
