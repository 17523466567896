<span data-testid="reload-notification"
  >Diese {{ this.page === AppPage.Ansicht ? "Ansicht" : "Planung" }} wurde durch einen anderen
  Nutzer verändert</span
>

<span class="ansicht-update-button">
  <kendo-badge-container>
    <button
      class="update-button"
      data-testid="reload-notification-update"
      kendoButton
      rounded="full"
      size="small"
      (click)="updatePageClick($event)"
    >
      {{ this.page === AppPage.Ansicht ? "Ansicht" : "Planung" }} aktualisieren
    </button>
    <kendo-badge
      *ngIf="notificationCounter$ | async as counter"
      class="notification-badge"
      rounded="full"
      >{{ counter }}</kendo-badge
    >
  </kendo-badge-container>
</span>
