/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type Redaktion =
  | "KeineRedaktion"
  | "C"
  | "CI"
  | "CPM"
  | "CS"
  | "CT"
  | "CW"
  | "CZE"
  | "PK"
  | "PKJ"
  | "PKM"
  | "PS"
  | "PSF"
  | "PUS"
  | "PUW"
  | "NN";

export const Redaktion = {
  KEINE_REDAKTION: "KeineRedaktion" as Redaktion,
  C: "C" as Redaktion,
  CI: "CI" as Redaktion,
  CPM: "CPM" as Redaktion,
  CS: "CS" as Redaktion,
  CT: "CT" as Redaktion,
  CW: "CW" as Redaktion,
  CZE: "CZE" as Redaktion,
  PK: "PK" as Redaktion,
  PKJ: "PKJ" as Redaktion,
  PKM: "PKM" as Redaktion,
  PS: "PS" as Redaktion,
  PSF: "PSF" as Redaktion,
  PUS: "PUS" as Redaktion,
  PUW: "PUW" as Redaktion,
  NN: "NN" as Redaktion,
};
