import { Planungskontext } from "../openapi/model/planungskontext";
import { EnumMetadata, enumRecordToSelectOptions } from "./enum-base";

export const PlanungskontextRecord: Record<Planungskontext, EnumMetadata> = {
  Vorgeplant: { bedeutung: "Vorgeplant" },
  Vorgeschlagen: { bedeutung: "Vorgeschlagen" },
  Vorgemerkt: { bedeutung: "Vorgemerkt" },
  Unbekannt: { bedeutung: "Unbekannt" },
};

export const PlanungskontextOptions = enumRecordToSelectOptions(PlanungskontextRecord);

export const PlanungskontextOptionsFiltered = enumRecordToSelectOptions(
  PlanungskontextRecord,
).filter(
  (planungskontext) =>
    planungskontext.value === Planungskontext.VORGEPLANT ||
    planungskontext.value === Planungskontext.VORGESCHLAGEN,
);
