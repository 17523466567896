import { Component, forwardRef, HostBinding, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ButtonFillMode, ButtonSize, ButtonThemeColor } from "@progress/kendo-angular-buttons";
import { starIcon, starOutlineIcon, SVGIcon } from "@progress/kendo-svg-icons";
import { AbstractControlValueAccessor } from "../abstract-control-value-accessor";

const iconMap = {
  star: {
    active: starIcon,
    inactive: starOutlineIcon,
  },
} satisfies Record<string, { active: SVGIcon; inactive: SVGIcon }>;

export type IconName = keyof typeof iconMap;

@Component({
  selector: "app-icon-toggle",
  templateUrl: "./icon-toggle.component.html",
  styleUrls: ["./icon-toggle.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconToggleComponent),
      multi: true,
    },
  ],
})
export class IconToggleComponent extends AbstractControlValueAccessor<boolean> {
  @Input() icon?: IconName;
  @Input() activeIcon?: SVGIcon;
  @Input() inactiveIcon?: SVGIcon;
  @Input() themeColor: ButtonThemeColor = "primary";
  @Input() inactiveThemeColor: ButtonThemeColor = "base";
  @Input() fillMode: ButtonFillMode = "clear";
  @Input() size: ButtonSize = "medium";
  /**
   * Möglichkeit, die Tooltips für die beiden Zustände zu setzen.
   */
  @Input() tooltips?: { active: string; inactive: string };

  @HostBinding("attr.title") title: string | null = null;

  _internalValue = false;
  get internalValue(): boolean {
    return this._internalValue;
  }
  set internalValue(value: boolean) {
    this._internalValue = value;

    if (this.icon && this.iconMap[this.icon]) {
      this.iconInternal = this.iconMap[this.icon][value ? "active" : "inactive"];
    } else if (this.activeIcon && this.inactiveIcon) {
      this.iconInternal = value ? this.activeIcon : this.inactiveIcon;
    } else {
      throw new Error("IconToggleComponent: No icon provided");
    }

    this.title = this.tooltips?.[this.internalValue ? "active" : "inactive"] ?? null;
  }

  iconInternal: SVGIcon | null = null;

  readonly iconMap = iconMap;

  writeValue(value: boolean): void {
    this.internalValue = value;
  }

  onToggle() {
    this.internalValue = !this.internalValue;
    this._onChange(this.internalValue);
  }
}
