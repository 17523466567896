import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { GUID } from "src/app/models";
import { OnDemandFilter } from "src/app/models/openapi/model/on-demand-filter";

export const merklisteWindowActions = createActionGroup({
  source: "Merkliste Window",
  events: {
    openMovePlanungsobjektLinearToMerklisteDialog: props<{
      planungsobjektLinearId: GUID;
      planunsobjektLinearTitel: string;
    }>(),
    openMovePlanungsobjektOnDemandToMerklisteDialog: props<{
      planungsobjektOnDemandId: GUID;
      planungsobjektOnDemandTitel: string;
      quickFilterPreset: OnDemandFilter | undefined;
    }>(),
    dialogClosed: emptyProps(),
    error: props<{ errorMessage: string }>(),
  },
});
