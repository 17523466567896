import { CdkDragEnter } from "@angular/cdk/drag-drop";
import { KeyValue } from "@angular/common";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { isWithinInterval } from "date-fns";
import { TimeCellGroup, TimeCellKey } from "src/app/core/stores/blockansicht/blockansicht.model";
import { dragDropActions } from "src/app/core/stores/dragdrop/dragdrop.actions";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";
import { NotificationStyle } from "../../../models/openapi/model/notification-style";
import { PlanungsobjektDto } from "../../../models/openapi/model/planungsobjekt-dto";
import { DateFnsService } from "../../../services/date-fns.service";
import { CustomNotificationService } from "../../../shared/notifications/custom-notification.service";
import { PlanungsobjektUtils } from "../../../utils/planungsobjekt.utils";
import {
  BlockansichtDragData,
  BlockansichtDroplistData,
  BlockansichtRowDataVM,
} from "./blockansicht-viewmodel";

@Injectable({
  providedIn: "root",
})
export class BlockansichtDragDropService {
  private enteredTimeCell: TimeCellKey;
  private timeCellOccupied: boolean;
  private partialTimeCell: number | Date;

  constructor(
    private notificationService: CustomNotificationService,
    private store: Store,
  ) {}

  resetDragDropAttributes(): void {
    this.enteredTimeCell = undefined;
    this.timeCellOccupied = undefined;
    this.store.dispatch(dragDropActions.dragEnd());
  }

  sendetagPreviewed(sendetag: string): boolean {
    if (!this.enteredTimeCell) return false;
    return sendetag === this.enteredTimeCell.sendetag;
  }

  canDrop(
    datumGruppe: KeyValue<string, BlockansichtRowDataVM>,
    variante: number,
    beginnzeit: string,
  ): boolean {
    return (
      !this.timeCellOccupied &&
      this.enteredTimeCell &&
      this.enteredTimeCell.sendetag === datumGruppe.value.sendetag &&
      this.enteredTimeCell.variante === variante &&
      this.enteredTimeCell.beginnzeit === beginnzeit
    );
  }

  canNotDrop(
    datumGruppe: KeyValue<string, BlockansichtRowDataVM>,
    variante: number,
    beginnzeit: string,
  ): boolean {
    return (
      this.timeCellOccupied &&
      this.enteredTimeCell &&
      this.enteredTimeCell.sendetag === datumGruppe.value.sendetag &&
      this.enteredTimeCell.variante === variante &&
      this.enteredTimeCell.beginnzeit === beginnzeit
    );
  }

  isDraggingMultiplePlanungsobjekte(
    datumGruppe: KeyValue<string, BlockansichtRowDataVM>,
    variante: number,
    beginnzeit: string,
    numberOfPlanungsobjekteInMehrfachauswahl: number,
  ): boolean {
    return (
      numberOfPlanungsobjekteInMehrfachauswahl > 1 &&
      this.enteredTimeCell &&
      this.enteredTimeCell.sendetag === datumGruppe.value.sendetag &&
      this.enteredTimeCell.variante === variante &&
      this.enteredTimeCell.beginnzeit === beginnzeit
    );
  }

  /**
   * Setzt die Variablen timeCellOccupied und enteredTimeCell bei einem Hover über einer neuen TimeCell.
   * Die Variablen werden verwendet, um das Styling der drag Preview (farbige Umrandung einer Zelle) korrekt anzuzeigen.
   * @param event wird ausgelöst, sobald ein cdkDragItem eine cdkDropList betritt
   */
  onDragEnter(event: CdkDragEnter<TimeCellGroup, PlanungsobjektDto>, ansichtKanal: Kanal): void {
    this.timeCellOccupied = !!this.checkForOccupiedTimeCell(
      event.item.data,
      event.container.data,
      ansichtKanal,
    );
    this.enteredTimeCell = event.container.data.timeCellKey;

    // enteredTimeCell ist nicht gesetzt wenn auf die Merkliste verschoben wird.
    if (this.enteredTimeCell) {
      this.store.dispatch(
        dragDropActions.dragHover({ sendetag: event.container.data.timeCellKey.sendetag }),
      );
    }
  }

  /**
   * Diese Methode dient als Gegenstück zur Methode onDragEnter, da diese kein Event auslöst
   * wenn eine Planungsobjekt von einer Merkliste in die Ansicht verschoben wird. isPlanungsobjekt
   * muss an dieser Stelle verwendet werden, da für CdkDragEnter der eigentlich korrekte Datentyp
   * PlanungsobjektDto | TimeCellGroup nicht für das Drag Item definiert werden kann.
   * @param event
   */
  onDropListEnter(
    event: CdkDragEnter<BlockansichtDroplistData, BlockansichtDragData>,
    ansichtKanal: Kanal,
  ): void {
    const droppedInTimeCellGroup = (
      planungsobjekt: TimeCellGroup | MerklisteDto,
    ): planungsobjekt is TimeCellGroup => {
      return "datumGruppe" in planungsobjekt && "timeCellKey" in planungsobjekt;
    };

    const planungsobjekt = PlanungsobjektUtils.isPlanungsobjekt<TimeCellGroup>(event.item.data);

    const timeCellGroup = droppedInTimeCellGroup(event.container.data)
      ? event.container.data
      : undefined;

    if (timeCellGroup && planungsobjekt) {
      this.timeCellOccupied = !!this.checkForOccupiedTimeCell(
        planungsobjekt,
        timeCellGroup,
        ansichtKanal,
      );
      this.enteredTimeCell = timeCellGroup.timeCellKey;
      this.store.dispatch(
        dragDropActions.dragHover({ sendetag: timeCellGroup.timeCellKey.sendetag }),
      );
    }
  }

  /**
   * Überprüft ob die Planungsobjekt in der Zelle abgelegt werden kann, ohne dabei mit
   * einer anderen Planungsobjekt zu überlappen.
   * @param dragItem
   * @param dropContainer
   * @param  ansichtKanal der Kanal welche die Tagesgrenze vorgibt
   * @returns die Planungsobjekt mit der eine Überschneidung aufgetreten ist.
   */
  checkForOccupiedTimeCell(
    dragItem: PlanungsobjektDto,
    dropContainer: TimeCellGroup | MerklisteDto,
    ansichtKanal = Kanal.ZDF,
  ): PlanungsobjektDto | undefined {
    const droppedInMerkliste = (
      planungsobjekt: TimeCellGroup | MerklisteDto,
    ): planungsobjekt is MerklisteDto => {
      return "name" in planungsobjekt && "ansichtId" in planungsobjekt;
    };

    // Der Datentyp des Drop Container ist MerklisteDto, wenn eine Pille auf die Merkliste verschoben werden soll.
    if (droppedInMerkliste(dropContainer)) {
      return undefined;
    }

    // Beim Verschieben von der Merkliste ist keine Planlänge gesetzt. Als Fallback wird hier 1 Sekunde! verwendet,
    // damit ein minimales Interval erstellt und für den Vergleich der Intervalle benutzt werden kann.
    const movedPlanungsobjekt: PlanungsobjektDto = {
      ...dragItem,
      planlaenge: dragItem.planlaenge ?? 1,
      publikationsplanung: {
        ...dragItem.publikationsplanung,
        beginnzeit: dropContainer.timeCellKey.beginnzeit,
        sendetag: dropContainer.datumGruppe.key,
      },
    };
    const initialPlanungsobjekt: Interval = this.createIntervalWithTagesgrenze(
      movedPlanungsobjekt,
      ansichtKanal,
    );

    if (dropContainer.timeCellKey.variante === null) {
      return undefined;
    }

    const planungsobjekteInNewVariantenZeile = dropContainer.datumGruppe.value.varianten[
      dropContainer.timeCellKey.variante
    ].pillen.map((pill) => pill.planungsobjekt);

    const planungsobjektInTheWay = planungsobjekteInNewVariantenZeile.find((planungsobjekt) => {
      // Prüfe nicht die gedraggte Planungsobjekt
      if (planungsobjekt.id === dragItem.id) return false;

      // Erstell das Interval für die Planungsobjekt, welche mit dem DragItem verglichen werden soll.
      const potentialNeighbor = this.createIntervalWithTagesgrenze(planungsobjekt, ansichtKanal);

      // Überprüft ob die gedraggte Planungsobjekt über einer "teilweise belegte Zellen" hovert
      const isHoveredOverPartialTimeCell = this.checkForPartialTimeCell(
        potentialNeighbor,
        dropContainer,
        ansichtKanal,
      );

      // Wenn über eine "teilweise belegte Zellen" gehovert wird muss das Interval der Planungsobjekt, um den
      // Offset der Zelle angepasst werden.
      if (isHoveredOverPartialTimeCell) {
        initialPlanungsobjekt.start = this.partialTimeCell;
        initialPlanungsobjekt.end = DateFnsService.addSeconds(
          initialPlanungsobjekt.start,
          movedPlanungsobjekt.planlaenge,
        );
      }

      // Überprüft ob die Planungsobjekt mit dem DragItem überlappt.
      const hasNeighborOverlap = DateFnsService.areIntervalsOverlapping(
        initialPlanungsobjekt,
        potentialNeighbor,
      );
      return !isHoveredOverPartialTimeCell && hasNeighborOverlap;
    });
    return planungsobjektInTheWay;
  }

  /**
   * Updatet die nötigen Publikationsplanungsinformationen, um die Pille zu verschieben.
   * Handelt es sich um die initiale Zelle oder wird das Ablegen durch einen andere Planungsobjekt
   * blockiert wird eine Warnung ausgegeben, dass die Änderung nicht durchgeführt wurde. Die Pille wird
   * im Anschluss wieder an ihren Ursprungsort verschoben.
   * @param drag
   */
  dropIfPossibleCdk(
    draggedPlanungsobjekt: PlanungsobjektDto,
    dropContainer: TimeCellGroup,
    ansichtKanal: Kanal,
  ): void {
    const initiallyOnMerkliste = PlanungsobjektUtils.isOnMerkliste(draggedPlanungsobjekt);

    let newTimeCellPosition: boolean;
    if (!initiallyOnMerkliste) {
      const initialTimeCellKey: TimeCellKey = {
        beginnzeit: draggedPlanungsobjekt.publikationsplanung.beginnzeit,
        variante: draggedPlanungsobjekt.publikationsplanung.variante,
        sendetag: draggedPlanungsobjekt.publikationsplanung.sendetag,
      };
      newTimeCellPosition = initialTimeCellKey !== dropContainer.timeCellKey;
    }

    // Handelt es sich bei dem Drop Container, um eine teilweise belegt Zelle entspricht die Beginnzeit der
    // Planungsobjekt der Beginnzeit der teilweise belegten Zelle.
    const timeCellKey = this.isDropContainerPartialTimeCell(dropContainer, ansichtKanal)
      ? {
          ...dropContainer.timeCellKey,
          beginnzeit: DateFnsService.formatDateAsTimeString(this.partialTimeCell),
        }
      : dropContainer.timeCellKey;

    const updatedPlanungsobjekt = {
      ...draggedPlanungsobjekt,
      publikationsplanung: {
        ...draggedPlanungsobjekt.publikationsplanung,
        ...timeCellKey,
      },
    };

    // Beim Verschieben muss eine Planlänge gesetzt sein. Dies ist bspw. bei Planungsobjekte auf der
    // Merkliste nicht immer der Fall.
    if (initiallyOnMerkliste && !draggedPlanungsobjekt.planlaenge) {
      this.notificationService.showNotification(
        `Aufgrund der fehlenden Länge kann der Inhalt nicht eingeplant werden.`,
        NotificationStyle.WARNING,
      );
    }

    if ((initiallyOnMerkliste && draggedPlanungsobjekt.planlaenge) || newTimeCellPosition) {
      const planungsobjektInTheWay = this.checkForOccupiedTimeCell(
        updatedPlanungsobjekt,
        dropContainer,
        ansichtKanal,
      );
      planungsobjektInTheWay
        ? this.notificationService.showNotification(
            `Änderung konnte nicht vorgenommen werden,
              da der gewünschte Zeitbereich schon durch das Programm "${planungsobjektInTheWay.titel}" belegt ist.`,
            NotificationStyle.WARNING,
          )
        : this.store.dispatch(
            planungsobjektActions.movePlanungsobjektInBlockansicht({
              planungsobjekt: updatedPlanungsobjekt,
            }),
          );
    }

    this.resetDragDropAttributes();
    this.partialTimeCell = undefined;
  }

  private createIntervalWithTagesgrenze(planungsobjekt: PlanungsobjektDto, ansichtKanal: Kanal) {
    const sendetagPreset = DateFnsService.parseDateAndTimeToDateObject(
      planungsobjekt.publikationsplanung.beginnzeit,
      planungsobjekt.publikationsplanung.sendetag,
    );

    const adjustedDate = KanalOffsetUtils.getDateWithTagesgrenze(
      sendetagPreset,
      planungsobjekt.publikationsplanung.kanal ?? ansichtKanal,
    );

    const interval = DateFnsService.createInterval(
      planungsobjekt.publikationsplanung.beginnzeit,
      planungsobjekt.planlaenge,
      adjustedDate,
    );

    return interval;
  }

  private isDropContainerPartialTimeCell(
    dropContainer: TimeCellGroup,
    ansichtKanal: Kanal,
  ): boolean {
    const timeCellDate = new Date(
      `${dropContainer.timeCellKey.sendetag}T${dropContainer.timeCellKey.beginnzeit}:00`,
    );
    const kalendertagTimeCell = KanalOffsetUtils.transformSendezeitpunktToKalenderzeitpunkt(
      timeCellDate,
      ansichtKanal,
    );
    const adjustedEnd = DateFnsService.addMinutes(
      kalendertagTimeCell,
      dropContainer.minutesPerColumn,
    );
    const adjustedInterval: Interval = { start: timeCellDate, end: adjustedEnd };
    return isWithinInterval(this.partialTimeCell, adjustedInterval);
  }

  /**
   *
   * @param potentialNeighbor
   * @param dropContainer
   * @param ansichtKanal
   * @returns
   */
  private checkForPartialTimeCell(
    potentialNeighbor: Interval,
    dropContainer: TimeCellGroup,
    ansichtKanal = Kanal.ZDF,
  ): boolean {
    const adjustedEnd = DateFnsService.subMinutes(
      potentialNeighbor.end,
      dropContainer.minutesPerColumn,
    );
    const adjustedInterval: Interval = { start: adjustedEnd, end: potentialNeighbor.end };

    const timeCellDate = new Date(
      `${dropContainer.timeCellKey.sendetag}T${dropContainer.timeCellKey.beginnzeit}:00`,
    );
    const kalendertagTimeCell = KanalOffsetUtils.transformSendezeitpunktToKalenderzeitpunkt(
      timeCellDate,
      ansichtKanal,
    );

    // Wenn die Endzeit des Intervals dem Ende der TimeCell entspricht, ragt die Pille nicht
    // aus der letzten Zelle heraus
    if (kalendertagTimeCell.valueOf() === adjustedInterval.end.valueOf()) {
      this.partialTimeCell = null;
      return false;
    }

    if (isWithinInterval(kalendertagTimeCell, adjustedInterval)) {
      this.partialTimeCell = adjustedInterval.end;
      return true;
    }

    return false;
  }
}
