<kendo-dialog-titlebar>{{ title }}</kendo-dialog-titlebar>
<div>
  <ng-container *ngIf="content">{{ content }}</ng-container>

  <div class="window-action-buttons">
    <button
      *ngFor="let action of actions"
      kendoButton
      [attr.data-testid]="action.testId"
      [fillMode]="action.fillMode ?? 'outline'"
      [themeColor]="action.themeColor ?? 'base'"
      (click)="action.isCancelAction ? dialogRef.close() : dialogRef.close(action)"
    >
      <p [appTooltipIfEllipsis]="action.tooltip || action.text" [ngClass]="action.cssClass">
        {{ action.text }}
      </p>
    </button>
  </div>
</div>
