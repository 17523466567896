import { formatString } from "./string-utils";

/**
 * no-op Funktion, die z.B. für das Vorinitilisieren von Callbacks verwendet werden kann
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

/**
 * Utility function that makes sure a certain part of code can't be reached.
 * This is mainly useful to make sure that a switch-case has a 'case' for every
 * possible value of the switched property.
 * @example
 * switch (productType) {
 *  case "tiered":
 *    this.handleTieredProduct();
 *    break;
 *  case "multiPart":
 *    this.handleMultiPartProduct();
 *    break;
 *  // if this case isn't added the below 'assertUnreachable' will result in a typescript error
 *  case "singleProduct":
 *    // don't do anything in this case - shouldn't happen as singleProduct only results in navigation
 *    break;
 *  default:
 *    assertUnreachable(productType);
 *  }
 */
export function assertUnreachable(value: never, messageTemplate?: string): never {
  const errorMsg = messageTemplate
    ? formatString(messageTemplate, [value])
    : `Unreachable value: ${JSON.stringify(value as unknown)}`;
  throw new Error(errorMsg);
}
