import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PublitPermissionDto } from "src/app/models/openapi/model/publit-permission-dto";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/shared/interceptors/http-loading.interceptor";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BenutzerApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getBenutzerPermissions$(): Observable<PublitPermissionDto[]> {
    return this.http.get<PublitPermissionDto[]>(`${this.apiUrl}Benutzer/Permissions`, {
      context: new HttpContext().set(SKIP_LOADING_INTERCEPTOR, true), //Skip, um Probleme mit dem kendoDialogContainer zu vermeiden.
    });
  }
}
