import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subject, Subscription, debounceTime } from "rxjs";

/**
 * Konfiguration der DebounceTime
 * ```html
 * <div
 *    (click.double)="onDoubleClick()"
 *    (click.single)="onSingleClick()"
 *    [clickDebounceTime]="500"
 * >
 * </div>
 * ```
 *
 * @link [Medium Blogpost](https://javascript.plainenglish.io/stop-the-horrible-clash-between-single-and-double-clicks-in-angular-5798ce90fd1a)
 */

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[click.single],[click.double]",
})
export class ClickDoubleDirective implements OnInit, OnDestroy {
  @Input() clickDebounceTime = 300;
  @Output("click.double") doubleClick = new EventEmitter<MouseEvent>();
  @Output("click.single") singleClick = new EventEmitter<MouseEvent>();

  private clicksSubject = new Subject<MouseEvent>();
  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.clicksSubject
      .pipe(debounceTime(this.clickDebounceTime))
      .subscribe((event) => {
        if (event.type === "click") {
          this.singleClick.emit(event);
        } else {
          this.doubleClick.emit(event);
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("click", ["$event"])
  clickEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.clicksSubject.next(event);
  }

  @HostListener("dblclick", ["$event"])
  doubleClickEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.clicksSubject.next(event);
  }
}
