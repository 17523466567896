import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import planungsobjektSelectors from "src/app/core/stores/planungsobjekt/planungsobjekt.selectors";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";

@Component({
  selector: "app-planungsobjekt-vorgeschlagen-badge",
  templateUrl: "./planungsobjekt-vorgeschlagen-badge.component.html",
  styleUrls: ["./planungsobjekt-vorgeschlagen-badge.component.scss"],
})
export class PlanungsobjektVorgeschlagenBadgeComponent implements OnInit {
  @Input() sendeplatz: SendeplatzDto;

  anyPlanungsobjektOnVorschlagspalte: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.anyPlanungsobjektOnVorschlagspalte = this.store.select(
      planungsobjektSelectors.selectAnyPlanungsobjekteInVorschlagspalteForSendeplatz(
        this.sendeplatz,
      ),
    );
  }
}
