import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { PureAbility } from "@casl/ability";
import { DragToSelectModule } from "ngx-drag-to-select";
import { AppAbility } from "../casl/app-ability";
import { lambdaMatcher } from "../casl/casl-utils";
import { KendoModule } from "../kendo.module";
import { AngularModule } from "./angular/angular/angular.module";
import { BenachrichtigungKategorieComponent } from "./components/benachrichtigung/benachrichtigung-kategorie/benachrichtigung-kategorie.component";
import { BenachrichtigungComponent } from "./components/benachrichtigung/benachrichtigung.component";
import { KanalIconsComponent } from "./components/kanal-icons/kanal-icons.component";
import { KonkurrenzprogrammIconsComponent } from "./components/konkurrenzprogramm-icons/konkurrenzprogramm-icons.component";
import { LoadingOverlayComponent } from "./components/loading-overlay/loading-overlay.component";
import { LoadingDialogComponent } from "./dialogs/loading-dialog/loading-dialog.component";
import { StandardActionDialogComponent } from "./dialogs/standard-action-dialog/standard-action-dialog.component";
import { ClickDoubleDirective } from "./directives/click-double.directive";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { DisableFormGroupDirective } from "./directives/disable-formgroup.directive";
import { FormEnablementDirective } from "./directives/form-enablement.directive";
import { TooltipIfEllipsisDirective } from "./directives/tooltip-if-ellipsis.directive";
import { IconToggleComponent } from "./form-inputs/icon-toggle/icon-toggle.component";
import { InputLabelComponent } from "./form-inputs/input-label/input-label.component";
import { IsoDateonlyComponent } from "./form-inputs/iso-dateonly/iso-dateonly.component";
import { IsoDateTimePickerComponent } from "./form-inputs/iso-datetimepicker/iso-datetimepicker.component";
import { IsoTimeonlyComponent } from "./form-inputs/iso-timeonly/iso-timeonly.component";
import { MaskedInputComponent } from "./form-inputs/masked-input/masked-input.component";
import { MultiselectComponent } from "./form-inputs/multiselect/multiselect.component";
import { RoundedTimepickerComponent } from "./form-inputs/rounded-timepicker/rounded-timepicker.component";
import { SelectComponent } from "./form-inputs/select/select.component";
import { FormFieldErrorComponent } from "./forms/form-error/form-field-error.component";
import { FormFieldComponent } from "./forms/form-field/form-field.component";
import { HttpLoadingInterceptor } from "./interceptors/http-loading.interceptor";
import { RequestHeaderInterceptor } from "./interceptors/request-header.interceptor";
import { ReloadNotificationComponent } from "./notifications/reload-notification/reload-notification.component";
import { AblePipe, AblePurePipe } from "./pipes/able.pipe";
import { AnsichtViewmodelForDashboardPipe } from "./pipes/ansicht-viewmodel-for-dashboard.pipe";
import { AsSubjectPipe } from "./pipes/as-subject.pipe";
import { CanDropInVorgeplantSpaltePipe } from "./pipes/can-drop-in-vorgeplant-spalte.pipe";
import { CdkDragDisabledPipe } from "./pipes/cdk-drag-disabled.pipe";
import { CdkDropListEnterPredicateWrapperPipe } from "./pipes/cdk-drop-list-enter-predicate-wrapper.pipe";
import { ChipWidthOnMerklistePipe } from "./pipes/chip-width-on-merkliste.pipe";
import { ColorMapToNgStylePipe } from "./pipes/color-map-to-ng-style/color-map-to-ng-style.pipe";
import { PlanungsobjektColorMapToNgStylePipe } from "./pipes/color-map-to-ng-style/planungsobjekt-color-map-to-ng-style.pipe";
import { ParseDatePipe } from "./pipes/date-format.pipe";
import { DurationPipe } from "./pipes/duration.pipe";
import { EkCssClassPipe } from "./pipes/ek-css-class.pipe";
import { EKTooltipPipe } from "./pipes/ek-tooltip.pipe";
import { EnumBeschreibungPipe } from "./pipes/enum-beschreibung.pipe";
import { GetitProgrammeitemLinkPipe } from "./pipes/getit-programmeitem-link.pipe";
import { HasPlanungshinweisPipe } from "./pipes/has-planungshinweis.pipe";
import { IsLoadingPipe } from "./pipes/is-loading.pipe";
import { MapByKeyPipe } from "./pipes/map-by-key.pipe";
import { MehrfachauswahlTooltipPipe } from "./pipes/mehrfachauswahl-tooltip.pipe";
import { MonthByIndexPipe } from "./pipes/month-by-index.pipe";
import { OrderByPipe } from "./pipes/order-by.pipe";
import { PlanungshinweisSingleLineNotesPipe } from "./pipes/planungshinweis-single-line-notes.pipe";
import { PlanungshinweisTooltipPipe } from "./pipes/planungshinweis-tooltip.pipe";
import { PlanungsobjektStylePipe } from "./pipes/planungsobjekt-style.pipe";
import { PlanungsobjektTooltipPipe } from "./pipes/planungsobjekt-tooltip.pipe";
import { RowDataPipe } from "./pipes/row-data.pipe";
import { SchemaplatzBySendeplatzPipe } from "./pipes/schemaplatz-by-sendeplatz.pipe";
import { SecondsToMinutesOrEmptyPipe } from "./pipes/seconds-to-minutes-or-empty.pipe";
import { SendeplatzOverlappingPipe } from "./pipes/sendeplatz-overlapping.pipe";
import { ShowVorschlaegePipe } from "./pipes/show-vorschlaege.pipe";
import { SingleLineNotesPipe } from "./pipes/single-line-notes.pipe";
import { TestIdPipe } from "./pipes/test-id.pipe";
import { TitleShortenerPipe } from "./pipes/title-format.pipe";
import { ToDatePipe } from "./pipes/to-date.pipe";
import { ZdfTitelByBeginnzeitPipe } from "./pipes/zdf-titel-by-beginnzeit.pipe";
import { ZdfTitelBySchemaplatzPipe } from "./pipes/zdf-titel-by-schemaplatz.pipe";
import { SerienWindowComponent } from "./windows/serien-window/serien-window.component";

/**
 * unexportierte Komponenten -- z.B. Dialoge, die über Service erzeugt werden und nicht im Template genutzt werden
 */
const UNEXPORTED_COMPONENTS = [StandardActionDialogComponent];

/**
 * exportierte Komponenten -- so ziemlich alle anderen
 */
const EXPORTED_COMPONENTS = [
  ReloadNotificationComponent,
  LoadingDialogComponent,
  SelectComponent,
  MultiselectComponent,
  MaskedInputComponent,
  RoundedTimepickerComponent,
  SerienWindowComponent,
  KanalIconsComponent,
  KonkurrenzprogrammIconsComponent,
  IsoDateTimePickerComponent,
  IsoDateonlyComponent,
  IsoTimeonlyComponent,
  InputLabelComponent,
  FormFieldErrorComponent,
  FormFieldComponent,
  IconToggleComponent,
  BenachrichtigungComponent,
  BenachrichtigungKategorieComponent,
  LoadingOverlayComponent,
];

const DIRECTIVES = [
  TooltipIfEllipsisDirective,
  ClickDoubleDirective,
  DisableFormGroupDirective,
  ClickOutsideDirective,
  FormEnablementDirective,
];

const PIPES = [
  ParseDatePipe,
  PlanungsobjektStylePipe,
  PlanungsobjektTooltipPipe,
  ZdfTitelBySchemaplatzPipe,
  ZdfTitelByBeginnzeitPipe,
  SingleLineNotesPipe,
  TitleShortenerPipe,
  EKTooltipPipe,
  SendeplatzOverlappingPipe,
  MehrfachauswahlTooltipPipe,
  RowDataPipe,
  SchemaplatzBySendeplatzPipe,
  EnumBeschreibungPipe,
  MonthByIndexPipe,
  ColorMapToNgStylePipe,
  PlanungsobjektColorMapToNgStylePipe,
  OrderByPipe,
  EkCssClassPipe,
  SecondsToMinutesOrEmptyPipe,
  ShowVorschlaegePipe,
  AnsichtViewmodelForDashboardPipe,
  MapByKeyPipe,
  AsSubjectPipe,
  CdkDropListEnterPredicateWrapperPipe,
  CanDropInVorgeplantSpaltePipe,
  DurationPipe,
  ChipWidthOnMerklistePipe,
  TestIdPipe,
  HasPlanungshinweisPipe,
  PlanungshinweisTooltipPipe,
  IsLoadingPipe,
  PlanungshinweisSingleLineNotesPipe,
  CdkDragDisabledPipe,
  ToDatePipe,
  GetitProgrammeitemLinkPipe,
  AblePipe,
  AblePurePipe,
];

const REEXPORTED_MODULES = [
  AngularModule,
  KendoModule,
  DragDropModule,
  DragToSelectModule.forRoot(),
];

@NgModule({
  declarations: [UNEXPORTED_COMPONENTS, EXPORTED_COMPONENTS, PIPES, DIRECTIVES],
  exports: [REEXPORTED_MODULES, EXPORTED_COMPONENTS, PIPES, DIRECTIVES],
  imports: [CommonModule, REEXPORTED_MODULES],
  providers: [
    SendeplatzOverlappingPipe,
    ColorMapToNgStylePipe,
    PlanungsobjektColorMapToNgStylePipe,
    PlanungsobjektStylePipe,
  ],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpLoadingInterceptor,
          multi: true,
        },
        {
          // interceptor to add the client's connectionId to the request Header
          provide: HTTP_INTERCEPTORS,
          useClass: RequestHeaderInterceptor,
          multi: true,
        },
        {
          provide: AppAbility,
          useValue: new AppAbility([], { conditionsMatcher: lambdaMatcher }),
        },
        { provide: PureAbility, useExisting: AppAbility },
      ],
    };
  }
}
