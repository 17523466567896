import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { of } from "rxjs";
import { merklisteActions } from "src/app/core/stores/merkliste/merkliste.actions";
import { createMerklisteKey } from "src/app/core/stores/merkliste/merkliste.reducer";
import merklisteSelectors from "src/app/core/stores/merkliste/merkliste.selectors";
import { GUID } from "src/app/models";
import { AktionEnum } from "src/app/models/enums/aktion";
import { OnDemandFilterOptions } from "src/app/models/enums/on-demand-filter";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { OnDemandFilter } from "src/app/models/openapi/model/on-demand-filter";

export interface PlanungsobjektOnDemandZuMerklisteWindowInput {
  planungsobjektOnDemandId: GUID;
  quickFilterPreset: OnDemandFilter | undefined;
}

export type PlanungsobjektOnDemandZuMerklisteWindowResult = {
  action: AktionEnum.ONDEMAND_AUF_MERKLISTE;
  planungsobjektId: GUID;
  merkliste: MerklisteDto;
};

@Component({
  selector: "app-planungsobjekt-ondemand-zu-merkliste-window",
  templateUrl: "./planungsobjekt-ondemand-zu-merkliste-window.component.html",
  styleUrls: ["./planungsobjekt-ondemand-zu-merkliste-window.component.scss"],
})
export class PlanungsobjektOnDemandZuMerklisteWindowComponent implements OnInit {
  @Input() public data: PlanungsobjektOnDemandZuMerklisteWindowInput;

  protected onDemandFilter: OnDemandFilter = OnDemandFilter.FICTION;
  protected onDemandFilterOptions = OnDemandFilterOptions;
  protected merklisten$ = of([] as MerklisteDto[]);

  constructor(
    private windowRef: WindowRef,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if (this.data.quickFilterPreset !== undefined) {
      this.onDemandFilter = this.data.quickFilterPreset;
    }
    this.onFilterChange();
  }

  onFilterChange() {
    this.store.dispatch(merklisteActions.loadMerklistenOnDemand({ filter: this.onDemandFilter }));
    this.merklisten$ = this.store.select(
      merklisteSelectors.selectMerklistenByOnDemandFilter(this.onDemandFilter),
    );
  }

  onClose(): void {
    this.windowRef.close();
  }

  onVerschieben(merkliste: MerklisteDto) {
    const dialogResult: PlanungsobjektOnDemandZuMerklisteWindowResult = {
      action: AktionEnum.ONDEMAND_AUF_MERKLISTE,
      planungsobjektId: this.data.planungsobjektOnDemandId,
      merkliste,
    };
    this.windowRef.close(dialogResult);
  }

  trackMerkliste(_index: number, merkliste: MerklisteDto) {
    return createMerklisteKey(merkliste);
  }
}
