import { Pipe, PipeTransform } from "@angular/core";
import { secondsToMinutes } from "date-fns";
import { roundSecondsToNextMinute } from "src/app/utils/time-utils";

@Pipe({
  name: "secondsToMinutesOrEmpty",
})
export class SecondsToMinutesOrEmptyPipe implements PipeTransform {
  transform(seconds: number | null | undefined, postfix = ""): string {
    return seconds === null || seconds === undefined
      ? ""
      : `${secondsToMinutes(roundSecondsToNextMinute(seconds))}${postfix}`;
  }
}
