import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReportApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getReportByAnsicht$(ansichtId: string): Observable<HttpResponse<Blob>> {
    const uri: string = this.apiUrl + "Report/ByAnsicht/" + `${ansichtId}`;
    return this.http.get(uri, { observe: "response", responseType: "blob" });
  }

  getReportWithMengengeruesteintraegenByYear$(
    ausspielweg: Kanal,
    year: number,
  ): Observable<HttpResponse<Blob>> {
    const uri: string = `${this.apiUrl}Report/MengengeruesteintraegeByYear/${year}/${ausspielweg}`;
    return this.http.get(uri, { observe: "response", responseType: "blob" });
  }
}
