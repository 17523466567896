<ng-container
  *ngIf="{
    form: onDemandForm$ | async,
    toggleForm: toggleForm$ | async,
    can: {
      beitragen: 'PlanungsobjektBeitragen' | able: 'Permission'
    }
  } as vm"
>
  <form *ngIf="vm.form" data-testid="vorschlaege-details-dialog" [formGroup]="vm.form">
    <div class="flex-container full-width">
      <div class="flex-container-vert over-third-width">
        <kendo-formfield class="full-width" showErrors="initial">
          <kendo-formerror
            *ngIf="vm.form.controls.wunschOnlineAb.errors?.valueBefore as valueBefore"
            >"Wunsch online ab" darf nicht vor "{{ valueBefore.otherLabel }}" liegen
          </kendo-formerror>
          <app-form-field class="full-width" label="Wunsch online ab">
            <app-iso-dateonly
              data-testid="wunsch-online-ab"
              formControlName="wunschOnlineAb"
              [appDisableFormControl]="onMerkliste || readOnly()"
              [attr.sendetag]="linear()?.sendetag"
              [focusedDate]="vm.form.controls.wunschOnlineAb.value ?? linear()?.sendetag"
            ></app-iso-dateonly> </app-form-field
        ></kendo-formfield>

        <app-form-field class="u-col-3" label="Online ab Zeit">
          <app-iso-timeonly
            clearable
            data-testid="online-ab-zeit"
            formControlName="onlineAbZeit"
            [appDisableFormControl]="onMerkliste || readOnly()"
          ></app-iso-timeonly>
        </app-form-field>
        <label class="select-option">
          <input
            data-testid="reihenfolge-checkbox"
            formControlName="reihenfolgeHerstellen"
            kendoCheckBox
            type="checkbox"
          />
          <span>Reihenfolge beachten</span>
        </label>
      </div>
      <form *ngIf="vm.toggleForm" [formGroup]="vm.toggleForm">
        <div class="publikation-toggle">
          <kendo-switch
            class="wunsch-online-switch"
            data-testid="wunschonlineab-relationalzulinear-toggle"
            formControlName="wunschOnlineAbVsRelationalZuLinearToggle"
            size="large"
          ></kendo-switch>
        </div>
      </form>
      <div class="flex-container-vert over-third-width">
        <kendo-formfield class="full-width" showErrors="initial">
          <kendo-formerror
            *ngIf="
              vm.form.controls.relationZuLinearInTagen.errors
                ?.valueBeforeWithReferenceKey as valueBeforeWithReferenceKey
            "
            >"Wunsch online ab" darf nicht nach "{{ valueBeforeWithReferenceKey.otherLabel }}"
            liegen
          </kendo-formerror>
          <kendo-formerror *ngIf="vm.form.controls.relationZuLinearInTagen.errors?.valueBefore"
            >"Relative Tage liegen nicht im angegebenen Zeitbereich."
          </kendo-formerror>
          <kendo-formerror *ngIf="vm.form.controls.relationZuLinearInTagen.errors?.valueAfter"
            >"Relative Tage liegen nicht im angegebenen Zeitbereich."
          </kendo-formerror>
          <app-form-field class="full-width" label="Relativ zu linear*">
            <kendo-numerictextbox
              data-testid="relationalzulinear-textbox"
              formControlName="relationZuLinearInTagen"
              format="n0"
              [selectOnFocus]="false"
              [step]="1"
              [decimals]="0"
            >
            </kendo-numerictextbox>
          </app-form-field>
        </kendo-formfield>
        <div class="flex-container">
          <kendo-formfield class="half-width">
            <kendo-formerror *ngIf="vm.form.controls.minDistanz.errors?.valueBefore as valueBefore"
              >"Frühestens größer als spätestens oder relativ zu linear"
            </kendo-formerror>
            <app-form-field class="half-width" label="frühestens">
              <kendo-numerictextbox
                data-testid="mindistanz-textbox"
                formControlName="minDistanz"
                format="n0"
                [selectOnFocus]="false"
                [step]="1"
                [decimals]="0"
              >
              </kendo-numerictextbox>
            </app-form-field>
          </kendo-formfield>
          <kendo-formfield class="half-width">
            <kendo-formerror *ngIf="vm.form.controls.maxDistanz.errors?.valueAfter as valueAfter"
              >"Spätestens kleiner als frühestens oder relativ zu linear"
            </kendo-formerror>
            <app-form-field class="half-width" label="spätestens">
              <kendo-numerictextbox
                data-testid="maxdistanz-textbox"
                formControlName="maxDistanz"
                format="n0"
                [selectOnFocus]="false"
                [step]="1"
                [decimals]="0"
              >
              </kendo-numerictextbox>
            </app-form-field>
          </kendo-formfield>
        </div>
        <label class="select-option">
          <input
            kendoCheckBox
            onclick="return false;"
            type="checkbox"
            [checked]="
              vm.toggleForm?.controls?.wunschOnlineAbVsRelationalZuLinearToggle?.value ===
              SLIDER_RIGHT
            "
            [disabled]="
              vm.toggleForm?.controls?.wunschOnlineAbVsRelationalZuLinearToggle?.value ===
                SLIDER_LEFT || readOnly()
            "
          />
          <span>Abhängigkeit</span>
        </label>
      </div>
      <kendo-svg-icon
        class="information-text"
        size="large"
        title="+/- X Tage"
        [icon]="Icons.information"
      ></kendo-svg-icon>
    </div>

    <div class="flex-container align-on-baseline full-width">
      <kendo-formfield class="over-third-width" showErrors="initial">
        <kendo-formerror *ngIf="vm.form.controls.wunschOnlineBis.errors?.valueAfter as valueAfter"
          >"Wunsch online bis" darf nicht vor "{{ valueAfter.otherLabel }}" liegen
        </kendo-formerror>
        <app-form-field class="full-width" label="Wunsch online bis">
          <app-iso-dateonly
            data-testid="wunsch-online-bis"
            formControlName="wunschOnlineBis"
            [appDisableFormControl]="onMerkliste || readOnly()"
            [attr.sendetag]="linear()?.sendetag"
            [focusedDate]="
              vm.form.controls.wunschOnlineBis.value ?? vm.form.controls.wunschOnlineAb.value
            "
            [minDate]="vm.form.controls.wunschOnlineAb.value"
          ></app-iso-dateonly>
        </app-form-field>
      </kendo-formfield>
      <form *ngIf="vm.toggleForm" [formGroup]="vm.toggleForm">
        <div>
          <kendo-switch
            class="wunsch-online-switch"
            data-testid="wunschonlinebis-verweildauer-toggle"
            formControlName="wunschOnlineBisVsVerweildauerToggle"
            size="large"
          ></kendo-switch>
        </div>
      </form>
      <kendo-formfield class="over-third-width" showErrors="initial">
        <app-form-field class="full-width" label="Verweildauer">
          <kendo-numerictextbox
            data-testid="verweildauer-textbox"
            formControlName="verweildauerInTagen"
            format="n0"
            [min]="1"
            [selectOnFocus]="false"
            [step]="1"
            [decimals]="0"
          >
          </kendo-numerictextbox>
        </app-form-field>
      </kendo-formfield>
      <kendo-svg-icon
        class="information-text"
        size="large"
        title="+ X Tage"
        [icon]="Icons.information"
      ></kendo-svg-icon>
    </div>

    <div class="flex-container information-text k-font-italic">
      <kendo-label
        text="Alle Angaben sind unter Vorbehalt, da unter Umständen weder eine Prüfung der Rechte, noch eine Materialzulieferung stattgefunden hat"
      >
      </kendo-label>
    </div>
    <div class="k-d-flex k-justify-content-end">
      <button
        data-testid="beziehung-speichern-button"
        fillMode="solid"
        kendoButton
        themeColor="primary"
        title="Speichern"
        [disabled]="readOnly() || !vm.can.beitragen || vm.form.invalid || vm.form.pristine"
        [svgIcon]="Icons.checkIcon"
        (click)="onSave()"
      ></button>
    </div>
  </form>
</ng-container>
