import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AktualisierenPlanungsobjekteOnDemandPlanungskontextCommand } from "src/app/models/openapi/model/aktualisieren-planungsobjekte-on-demand-planungskontext-command";
import { GetPlanungsobjekteOnDemandInZeitraumMitVerknuepftenLinearenQuery } from "src/app/models/openapi/model/get-planungsobjekte-on-demand-in-zeitraum-mit-verknuepften-linearen-query";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { PlanungsobjektOnDemandDtoIEnumerableResultDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto-i-enumerable-result-dto";
import { PlanungsobjektOnDemandDtoResultDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto-result-dto";
import { PlanungsobjektOnDemandVerschiebenZuVorgemerktCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-verschieben-zu-vorgemerkt-command";
import { PlanungsobjektOnDemandVerschiebenZuVorgeplantCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-verschieben-zu-vorgeplant-command";
import { PlanungsobjektOnDemandVerschiebenZuVorgeschlagenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-verschieben-zu-vorgeschlagen-command";
import { PlanungsobjektOnDemandVorgemerktAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgemerkt-aktualisieren-command";
import { PlanungsobjektOnDemandVorgemerktErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgemerkt-erstellen-command";
import { PlanungsobjektOnDemandVorgeplantAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgeplant-aktualisieren-command";
import { PlanungsobjektOnDemandVorgeplantErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgeplant-erstellen-command";
import { PlanungsobjektOnDemandVorgeschlagenAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgeschlagen-aktualisieren-command";
import { PlanungsobjektOnDemandVorgeschlagenErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-on-demand-vorgeschlagen-erstellen-command";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";
import { PublitFrontendSettings } from "src/environments/environment";

/**
 * Verwaltet PlanungsobjektOnDemandDto.
 */
@Injectable({ providedIn: "root" })
export class PlanungsobjektOnDemandApiService {
  private readonly apiUrl = this.settings.apiUrl;
  private readonly basePath = "PlanungsobjektOnDemand";

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  planungsobjektOnDemandVorgemerktErstellen$(
    command: PlanungsobjektOnDemandVorgemerktErstellenCommand,
  ) {
    return this.http.post<PlanungsobjektOnDemandDtoResultDto>(
      `${this.apiUrl}${this.basePath}/Vorgemerkt`,
      command,
    );
  }

  getOnDemandById$(onDemandId: string) {
    return this.http.get<PlanungsobjektOnDemandDto>(`${this.apiUrl}${this.basePath}/${onDemandId}`);
  }

  getOnDemandInZeitraumMitVerknuepftenLinearen$(
    query: GetPlanungsobjekteOnDemandInZeitraumMitVerknuepftenLinearenQuery,
  ) {
    return this.http.post<PlanungsobjekteDto>(`${this.apiUrl}${this.basePath}/ByDateRange`, query);
  }

  aktualisierenPlanungsobjekteOnDemandPlanungskontext$(
    command: AktualisierenPlanungsobjekteOnDemandPlanungskontextCommand,
  ) {
    return this.http.put<PlanungsobjektOnDemandDtoIEnumerableResultDto>(
      `${this.apiUrl}${this.basePath}/Update/Planungskontext`,
      command,
    );
  }

  planungsobjektOnDemandVorgeplantErstellen$(
    command: PlanungsobjektOnDemandVorgeplantErstellenCommand,
  ) {
    return this.http.post<PlanungsobjektOnDemandDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant`,
      command,
    );
  }

  planungsobjektOnDemandVorgeplantAktualisieren$(
    command: PlanungsobjektOnDemandVorgeplantAktualisierenCommand,
  ) {
    return this.http.put<PlanungsobjektOnDemandDtoResultDto>(
      `${this.apiUrl}${this.basePath}/Vorgeplant/${command.id}`,
      command,
    );
  }

  planungsobjektOnDemandVorgeschlagenErstellen$(
    command: PlanungsobjektOnDemandVorgeschlagenErstellenCommand,
  ) {
    return this.http.post<PlanungsobjektOnDemandDto>(
      `${this.apiUrl}${this.basePath}/Vorgeschlagen`,
      command,
    );
  }

  planungsobjektOnDemandVorgeschlagenAktualisieren$(
    command: PlanungsobjektOnDemandVorgeschlagenAktualisierenCommand,
  ) {
    return this.http.put<PlanungsobjektOnDemandDtoResultDto>(
      `${this.apiUrl}${this.basePath}/Vorgeschlagen/${command.id}`,
      command,
    );
  }

  planungsobjektOnDemandVorgemerktAktualisieren$(
    command: PlanungsobjektOnDemandVorgemerktAktualisierenCommand,
  ) {
    return this.http.put<PlanungsobjektOnDemandDto>(
      `${this.apiUrl}${this.basePath}/Vorgemerkt/${command.id}`,
      command,
    );
  }

  // TODO-1488: Methode ist auch in PlanungsobjektApiService vorhanden
  planungsobjektOnDemandVerschiebenZuVorgemerkt$(
    command: PlanungsobjektOnDemandVerschiebenZuVorgemerktCommand,
  ) {
    return this.http.put<PlanungsobjektOnDemandDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.planungsobjektId}/Verschieben/Vorgemerkt`,
      command,
    );
  }

  planungsobjektOnDemandVerschiebenZuVorgeschlagen$(
    command: PlanungsobjektOnDemandVerschiebenZuVorgeschlagenCommand,
  ) {
    return this.http.put<PlanungsobjektOnDemandDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.planungsobjektOnDemandId}/Verschieben/Vorgeschlagen`,
      command,
    );
  }

  planungsobjektOnDemandVerschiebenZuVorgeplant$(
    command: PlanungsobjektOnDemandVerschiebenZuVorgeplantCommand,
  ) {
    return this.http.put<PlanungsobjektOnDemandDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.planungsobjektOnDemandId}/Verschieben/Vorgeplant`,
      command,
    );
  }
}
