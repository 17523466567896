import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { GUID } from "src/app/models";
import { BenachrichtigungDto } from "src/app/models/openapi/model/benachrichtigung-dto";
import { MultiAnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

export const benachrichtigungActions = createActionGroup({
  source: "Benachrichtigung API",
  events: {
    loadBenachrichtigungen: emptyProps(),
    loadBenachrichtigungenSuccess: props<{
      benachrichtigungen: BenachrichtigungDto[];
    }>(),
    loadBenachrichtigungenError: props<{
      error: unknown;
    }>(),
    loadBenachrichtigungenByAnsicht: props<{ multiAnsichtViewModel: MultiAnsichtViewModel }>(),
    loadBenachrichtigungenByZeitraum: props<{
      von: Date;
      bis: Date;
    }>(),
    benachrichtigungFromSocket: props<{
      benachrichtigung: BenachrichtigungDto;
      requiresReload: boolean;
    }>(),

    verwerfenMultiple: props<{
      benachrichtigungIds: GUID[];
    }>(),
    verwerfenMultipleSuccess: props<{
      benachrichtigungIds: GUID[];
    }>(),
    // Wird aktuell nicht mehr genutzt, da wir Benachrichtigungen neu laden und damit automatisch als gelesen markieren
    markAsConfirmed: props<{
      benachrichtigungIds: string[];
    }>(),
    // Wird aktuell nicht mehr genutzt, da wir Benachrichtigungen neu laden und damit automatisch als gelesen markieren
    markAsConfirmedForIntervals: props<{
      intervals: { start: Date; end: Date }[];
    }>(),
  },
});
