import { createSelector } from "@ngrx/store";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { SendeplatzKeyDto } from "src/app/models/openapi/model/sendeplatz-key-dto";
import { EventViewModel } from "src/app/models/viewmodels/event-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import routerSelectors from "../router/router.selectors";
import { eventFeature, eventViewModelComparer } from "./event.reducer";

const selectEventViewModels = createSelector(eventFeature.selectAll, (events) => {
  return events.map(
    (event: EventDto): EventViewModel => ({
      ...event,
      zeitraum: {
        start: DateFnsService.parseDateAndTimeToDateObject(event.vonZeit, event.vonDatum),
        end: DateFnsService.parseDateAndTimeToDateObject(event.bisZeit, event.bisDatum),
      },
    }),
  );
});

const selectEventsForSendeplatz = (sendeplaetzeKeys: SendeplatzKeyDto[]) => {
  return createSelector(selectEventViewModels, (eventViewModels) => {
    const groupedEventViewModels: EventViewModel[] = [];
    // Wenn kein Sendeplaetze übergeben wurde oder dieser undefined ist wird ein leeres Array zurückgegeben
    if (!Array.isArray(sendeplaetzeKeys)) return groupedEventViewModels;

    for (const sendeplatz of sendeplaetzeKeys) {
      if (!sendeplatz) continue;
      const von = DateFnsService.parseDateAndTimeToDateObject(
        sendeplatz.beginnzeit,
        new Date(sendeplatz.kalendertag),
      );
      const bis = DateFnsService.addSeconds(von, sendeplatz.laenge);
      const interval = { start: von, end: bis };
      for (const eventViewModel of eventViewModels) {
        if (DateFnsService.areIntervalsOverlapping(interval, eventViewModel.zeitraum))
          groupedEventViewModels.push(eventViewModel);
      }
    }
    // Sortiere und entferne Duplikate
    return [...new Set(groupedEventViewModels.sort(eventViewModelComparer))];
  });
};

const selectEventsForInterval = (interval: { start: Date; end: Date }) => {
  return createSelector(selectEventViewModels, (eventViewModels) => {
    let groupedEventViewModels: EventViewModel[] = [];
    if (!interval) return groupedEventViewModels;
    groupedEventViewModels = eventViewModels.filter((event) => {
      return DateFnsService.areIntervalsOverlapping(interval, event.zeitraum);
    });

    return [...new Set(groupedEventViewModels.sort(eventViewModelComparer))];
  });
};

const selectHasEventForSendeplaetze = (sendeplaetzeKeys: SendeplatzKeyDto[]) => {
  return createSelector(selectEventsForSendeplatz(sendeplaetzeKeys), (events) => {
    return !!events;
  });
};

const selectEventsForYear = createSelector(
  routerSelectors.selectYearFromQueryParamOrDefault,
  eventFeature.selectAll,
  (year, events) =>
    events.filter(
      (event) =>
        DateFnsService.parseDateAndTimeToDateObject(event.vonZeit, event.vonDatum).getFullYear() ===
        year,
    ),
);

export default {
  selectEventsForSendeplatz,
  selectHasEventForSendeplaetze,
  selectEventsForYear,
  selectEventsForInterval,
};
