import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs";
import { VersionApiService } from "src/app/api/version/version.api.service";
import metadatenActions from "./metadaten.actions";

@Injectable()
export class MetadatenEffects {
  loadVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(metadatenActions.loadVersionInformation),
      switchMap(() => this.api.getVersionInformation$()),
      map((version) =>
        metadatenActions.loadVersionInformationSuccess({ versionInformation: version }),
      ),
      // TODO: searchFailure triggern?
    );
  });

  constructor(
    private actions$: Actions,
    private api: VersionApiService,
  ) {}
}
