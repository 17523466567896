import { Injectable } from "@angular/core";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProduktDbService {
  constructor(private settings: PublitFrontendSettings) {}

  public programmeItemLink(getitId: string): string {
    return `${this.settings.produktDbBaseUrl}/products/ProgrammeItem/${getitId}`;
  }
}
