import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, map, take } from "rxjs";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { dragDropActions } from "src/app/core/stores/dragdrop/dragdrop.actions";
import { listenansichtActions } from "src/app/core/stores/listenansicht/listenansicht.actions";
import listenansichtSelectors from "src/app/core/stores/listenansicht/listenansicht.selectors";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzKeyDto } from "src/app/models/openapi/model/sendeplatz-key-dto";
import { PlanungsobjektMoveViewModel } from "src/app/models/viewmodels/planungsobjekt-viewmodel";
import { SendeplatzDroplistData } from "src/app/models/viewmodels/sendeplatz-viewmodel";
import { PlanungsobjektAcceptWindowInput } from "src/app/shared/windows/planungsobjekt-accept-window/planungsobjekt-accept-window.component";
import { PlanungsobjektWindowUseCase } from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { isDefined } from "src/app/utils/object-utils";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import { Kanal } from "tests/common/generated/api";
import AnsichtenFacade from "../ansichten.facade";

@Injectable()
export default class ListenansichtFacade {
  private readonly store = inject(Store);
  private readonly ansichtenFacade = inject(AnsichtenFacade);

  public readonly listenAnsicht$ = this.store.select(ansichtSelectors.selectListenansicht);

  public readonly hasPreviousAnsicht$ = this.ansichtenFacade.hasPreviousAnsicht$;

  public readonly hasNextAnsicht$ = this.ansichtenFacade.hasNextAnsicht$;

  public readonly isMengengeruestErlaubt$ = this.ansichtenFacade.isMengengeruestErlaubt$;

  public readonly displaysMultipleYears$ = this.ansichtenFacade.displaysMultipleYears$;

  public readonly multiansichtSendeplatzGruppen$ = this.listenAnsicht$.pipe(
    map((listenansicht) => listenansicht?.sendeplaetze),
    filter(isDefined),
  );

  public readonly multiAnsichtViewModel$ = this.listenAnsicht$.pipe(
    map((listenansicht) => listenansicht?.multiAnsichtViewModel),
    filter(isDefined),
  );

  public readonly ansichtHeaders$ = this.store.select(
    listenansichtSelectors.selectVisibleListenansichtHeader,
  );

  public readonly showKonkurrenzEvents$ = this.ansichtenFacade.showKonkurrenzEvents$;

  endDragging() {
    this.store.dispatch(dragDropActions.dragEnd());
  }

  loadPreviousAnsicht() {
    this.ansichtenFacade.loadPreviousAnsicht();
  }
  loadNextAnsicht() {
    this.ansichtenFacade.loadNextAnsicht();
  }

  openCreateEkWindow(sendeplatz: SendeplatzDto) {
    this.ansichtenFacade.openCreateEkWindow(sendeplatz);
  }

  planungsobjektErstellenVorgeschlagen(sendeplatzKey: SendeplatzKeyDto) {
    this.openCreatePlanungsobjektLinearVorgeschlagenWithPlanungWindow(sendeplatzKey);
  }

  openCreatePlanungsobjektLinearVorgeschlagenWithPlanungWindow(sendeplatzKey: SendeplatzKeyDto) {
    this.store.dispatch(
      planungsobjektWindowActions.openPlanungsobjektWindow({
        input: {
          usecase: PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ,
          planungskontext: Planungskontext.VORGESCHLAGEN,
          planungsobjektId: null,
          kanal: sendeplatzKey.kanal,
          wannPreset: {
            sendetag: sendeplatzKey.sendetag,
            beginnzeit: sendeplatzKey.beginnzeit,
            planlaenge: sendeplatzKey.laenge,
          },
        },
      }),
    );
  }

  openPlanungshinweiseWindow($event: { kanal: Kanal; start: Date; end: Date }) {
    this.ansichtenFacade.openPlanungshinweiseWindow($event);
  }

  openPlanungsobjektAcceptWindow(windowInput: PlanungsobjektAcceptWindowInput) {
    this.ansichtenFacade.openPlanungsobjektAcceptWindow(windowInput);
  }

  movePlanungsobjektInAnsicht(event: PlanungsobjektMoveViewModel) {
    this.multiAnsichtViewModel$.pipe(take(1)).subscribe((multiAnsichtViewModel) => {
      this.ansichtenFacade.movePlanungsobjektInAnsicht(event, multiAnsichtViewModel);
    });
  }

  movePlanungsobjektInVorschlagsSpalte(
    event: CdkDragDrop<SendeplatzDto, SendeplatzDroplistData, PlanungsobjektDto>,
  ) {
    this.ansichtenFacade.movePlanungsobjektInVorschlagspalte({
      newSendeplatz: event.container.data,
      planungsobjekt: event.item.data,
    });
  }

  openNachPharosUebertragenWindow(sendeplatz: SendeplatzDto) {
    this.ansichtenFacade.openNachPharosUebertragenWindow(sendeplatz);
  }

  openSendeplatzWindow(sendeplatz: SendeplatzDto) {
    this.ansichtenFacade.openSendeplatzWindow(sendeplatz);
  }

  openGetitWindow(sendeplatz: SendeplatzDto, isVorschlag: boolean = false) {
    const sendeplatzKey = SendeplatzUtils.getSendeplatzKey(sendeplatz);
    this.ansichtenFacade.openGetitWindow({
      type: "Sendeplatz",
      kanal: sendeplatzKey.kanal,
      kalendertag: sendeplatzKey.kalendertag,
      beginnzeit: sendeplatzKey.beginnzeit,
      laenge: sendeplatzKey.laenge,
      isVorschlag: isVorschlag,
    });
  }

  setHoveredSendeplatz(sendetag: string) {
    this.store.dispatch(dragDropActions.dragHover({ sendetag: sendetag }));
  }

  toggleColumnHeaderForId(columnId: string): void {
    this.store.dispatch(listenansichtActions.toggleColumnHeaderForColumnId({ columnId }));
  }
}
