import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDtoResultDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto-result-dto";
import { DateFnsService } from "src/app/services/date-fns.service";
import { onDemandBeziehungFormActions } from "src/app/shared/windows/planungsobjekt-window/verlinkung-tab/on-demand-beziehung-form/on-demand-beziehung-form.actions";
import { compareString } from "src/app/utils/string-utils";
import { ansichtActions } from "../ansicht/ansicht.actions";
import { FeatureKey } from "../feature.keys";
import { planungsobjektActions } from "./planungsobjekt.actions";

export interface PlanungsobjektState extends EntityState<PlanungsobjektDto> {
  loading: boolean;
  selectedPlanungsobjektId: string | null;
}

export const planungsobjektAdapter: EntityAdapter<PlanungsobjektDto> =
  createEntityAdapter<PlanungsobjektDto>();

export const initialPlanungsobjektState: PlanungsobjektState =
  planungsobjektAdapter.getInitialState({
    loading: false,
    selectedPlanungsobjektId: null,
  });

export const planungsobjektComparer = (a1: PlanungsobjektDto, a2: PlanungsobjektDto): number => {
  let planungsobjektCompare = 0;
  if (a1.mengengeruesteintragId === null) planungsobjektCompare = 1;
  if (a2.mengengeruesteintragId === null) planungsobjektCompare = -1;
  if (a1.mengengeruesteintragId === a2.mengengeruesteintragId) planungsobjektCompare = 0;
  if (planungsobjektCompare !== 0) return planungsobjektCompare;
  return compareString(a1.titel, a2.titel);
};

export const planungsobjektFeature = createFeature({
  name: FeatureKey.Planungsobjekt,
  reducer: createReducer(
    initialPlanungsobjektState,
    on(
      planungsobjektActions.loadPlanungsobjekteForAnsichten,
      ansichtActions.updateAllEntitiesInAnsicht,
      (currentState): PlanungsobjektState => ({ ...currentState, loading: true }),
    ),
    on(
      planungsobjektActions.getPlanungsobjektByIdSuccess,
      (state, { planungsobjekt }): PlanungsobjektState => {
        return planungsobjektAdapter.upsertOne(planungsobjekt, state);
      },
    ),
    on(
      planungsobjektActions.loadPlanungsobjekteForAnsichtenSuccess,
      planungsobjektActions.upsertPlanungsobjekteFromMerklistenSuccess,
      planungsobjektActions.upsertPlanungsobjekteByAnsichtenSuccess,
      (state, { planungsobjekte }): PlanungsobjektState => {
        return planungsobjektAdapter.upsertMany(planungsobjekte, {
          ...state,
          loading: false,
        });
      },
    ),
    on(
      planungsobjektActions.setAllPlanungsobjekte,
      (state, { planungsobjekte }): PlanungsobjektState => {
        return planungsobjektAdapter.setAll(planungsobjekte, {
          ...state,
          loading: false,
        });
      },
    ),
    on(
      planungsobjektActions.createPlanungsobjektOnSendeplatzSuccess,
      planungsobjektActions.createPlanungsobjektOnBlockansichtSuccess,
      planungsobjektActions.createPlanungsobjektOnMerklisteSuccess,
      planungsobjektActions.createPlanungsobjektLinearVorgeschlagenSuccess,
      planungsobjektActions.copyPlanungsobjektSuccess,
      planungsobjektActions.copyPlanungsobjektOnMerklisteSuccess,
      planungsobjektActions.copyPlanungsobjektOnBlockansichtSuccess,
      (state, { planungsobjekt }): PlanungsobjektState => {
        return planungsobjektAdapter.addOne(planungsobjekt, state);
      },
    ),
    on(
      planungsobjektActions.updatePlanungsobjektOnSendeplatzSuccess,
      planungsobjektActions.updatePlanungsobjektOnMerklisteSuccess,
      planungsobjektActions.updatePlanungsobjektOnBlockansichtSuccess,
      planungsobjektActions.updatePlanungsobjektLinearVorgeschlagenSuccess,
      planungsobjektActions.movePlanungsobjektSuccess,
      planungsobjektActions.movePlanungsobjektInBlockansichtSuccess,
      planungsobjektActions.movePlanungsobjektToMerklisteSuccess,
      planungsobjektActions.replacePlanungsobjektWithVorschlagSuccess,
      planungsobjektActions.movePlanungsobjektVorgeschlagenToVorgeplantSuccess,
      planungsobjektActions.movePlanungsobjektToVorschlagspalteSuccess,
      (state, { planungsobjekt }): PlanungsobjektState => {
        const isPlanungsobjektDtoResultDto = (
          planungsobjekt: PlanungsobjektLinearDtoResultDto | PlanungsobjektDto,
        ): planungsobjekt is PlanungsobjektLinearDtoResultDto => {
          return "notifications" in planungsobjekt && "result" in planungsobjekt;
        };
        const changes = isPlanungsobjektDtoResultDto(planungsobjekt)
          ? planungsobjekt.result
          : planungsobjekt;

        return planungsobjektAdapter.updateOne({ id: changes.id, changes }, state);
      },
    ),
    on(
      planungsobjektActions.assignPlanungsobjektToMengengeruestSuccess,
      (state, { planungsobjekt, mengengeruesteintragId }): PlanungsobjektState => {
        return planungsobjektAdapter.updateOne(
          { id: planungsobjekt.id, changes: { mengengeruesteintragId } },
          state,
        );
      },
    ),
    on(
      planungsobjektActions.moveMultiplePlanungsobjekteSuccess,
      (state, { planungsobjekte, daysOffset }): PlanungsobjektState => {
        const planungsobjekteFromState = planungsobjekte.map(
          (planungsobjekt) => state.entities[planungsobjekt.id],
        );
        const updatedPlanungsobjekte = planungsobjekteFromState.map((planungsobjekt) => ({
          id: planungsobjekt?.id,
          changes: {
            ...planungsobjekt,
            publikationsplanung: {
              ...planungsobjekt?.publikationsplanung,
              kalendertag: DateFnsService.formatDateAsString(
                DateFnsService.addDays(planungsobjekt.publikationsplanung.kalendertag, daysOffset),
              ),
            },
          },
        }));
        return planungsobjektAdapter.updateMany(updatedPlanungsobjekte, state);
      },
    ),
    on(
      planungsobjektActions.getPlanungsobjekteByIdsSuccess,
      planungsobjektActions.moveMultiplePlanungsobjektInBlockansichtSuccess,
      (state, { planungsobjekte }): PlanungsobjektState => {
        return planungsobjektAdapter.updateMany(
          planungsobjekte.map((planungsobjekt) => {
            return { id: planungsobjekt.id, changes: planungsobjekt };
          }),
          state,
        );
      },
    ),
    on(
      planungsobjektActions.deletePlanungsobjektSuccess,
      planungsobjektActions.replacePlanungsobjektWithVorschlag,
      (state, actionProperty): PlanungsobjektState => {
        // Für die planungsobjektActions.replacePlanungsobjektWithVorschlag muss die planungsobjektId aus
        // dem command entnommen werden.
        const planungsobjektId =
          actionProperty.type === planungsobjektActions.deletePlanungsobjektSuccess.type
            ? actionProperty.planungsobjektId
            : actionProperty.command.planungsobjektId;

        return planungsobjektAdapter.removeOne(planungsobjektId, state);
      },
    ),
    on(
      planungsobjektActions.planungsobjektZuSerieUmwandelnBlockSuccess,
      planungsobjektActions.planungsobjektZuSerieUmwandelnSendeplatzSuccess,
      (state, { newPlanungsobjekte }): PlanungsobjektState => {
        return planungsobjektAdapter.addMany(newPlanungsobjekte, state);
      },
    ),
    on(
      planungsobjektActions.deleteMultiplePlanungsobjekteSuccess,
      (state, { planungsobjekteIds }): PlanungsobjektState => {
        return planungsobjektAdapter.removeMany(planungsobjekteIds, state);
      },
    ),
    on(
      planungsobjektActions.selectPlanungsobjektInAnsicht,
      (state, { planungsobjektId }): PlanungsobjektState => {
        return {
          ...state,
          selectedPlanungsobjektId: planungsobjektId ?? null,
        };
      },
    ),
    on(
      planungsobjektActions.deselectPlanungsobjektInAnsicht,
      (state, { planungsobjektId }): PlanungsobjektState => {
        // Wenn keine planungsobjektId übergeben wird, oder die aktuell ausgewählte PlanungsobjektId mit der
        // übergebenen übereinstimmt, wird die aktuell ausgewählte Planungsobjekt auf null gesetzt
        if (!planungsobjektId || planungsobjektId === state.selectedPlanungsobjektId) {
          return {
            ...state,
            selectedPlanungsobjektId: null,
          };
        }
        return state;
      },
    ),
    on(
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess,
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenSuccess,
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenSuccess,
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenSuccess,
      (state, { planungsobjekte }): PlanungsobjektState => {
        // Kann eigentlich nicht sein das du an dieser Stelle kein PlanungsobjektLinear hast
        return planungsobjektAdapter.upsertOne(planungsobjekte.linear[0], state);
      },
    ),
    on(
      onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess,
      planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess,
      (state, { planungsobjekte }): PlanungsobjektState => {
        return planungsobjektAdapter.upsertMany(planungsobjekte.linear, state);
      },
    ),
  ),
  extraSelectors: ({ selectPlanungsobjektState }) => ({
    ...planungsobjektAdapter.getSelectors(selectPlanungsobjektState),
  }),
});
