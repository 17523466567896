import { Injectable } from "@angular/core";
import {
  createColumnId,
  ListenansichtColumnHeader,
  ListenansichtHeaderEnum,
} from "src/app/core/stores/listenansicht/listenansicht.reducer";
import { DateFnsService } from "src/app/services/date-fns.service";
import { groupByPredicateToMap } from "src/app/utils/array-utils";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";
import { isDefined } from "src/app/utils/object-utils";
import { compareString } from "src/app/utils/string-utils";
import { Layout } from "../openapi/model/layout";
import { SendeplatzDto } from "../openapi/model/sendeplatz-dto";
import { Wochentag } from "../openapi/model/wochentag";
import { AnsichtViewModel, MultiAnsichtViewModel } from "../viewmodels/ansicht-viewmodel";
import { SendeplatzViewModel } from "../viewmodels/sendeplatz-viewmodel";

@Injectable({
  providedIn: "root",
})
export class ListenansichtMapper {
  /**
   * Transformiert zusammengehörige Schemaplatztupel in ein Array der dimension n∑+1,
   * bei dem jedes Element einer Zeile der Ansicht entspricht
   * @param sendeplaetzeGroups Sendeplätze pro Sendetag
   * @param ansichtsdefinition AnsichtsdefinitionDto
   */
  static mapSendeplaetzeToVmForListenansicht(
    sendeplaetze: Map<number, SendeplatzDto[]>,
    multiAnsichtViewModel: MultiAnsichtViewModel,
  ): Record<number, SendeplatzViewModel[][]> {
    const multiListenansichtSendeplaetzeByYear: Record<number, SendeplatzViewModel[][]> = {};

    const primaryAnsichtViewModel = multiAnsichtViewModel.ansichtViewModels.find(
      (ansichtViewModel) => ansichtViewModel.primary,
    )?.ansichtViewModel;

    if (primaryAnsichtViewModel?.ansichtsdefinition.layout !== Layout.LISTE)
      return multiListenansichtSendeplaetzeByYear;

    const schemaplaetze = primaryAnsichtViewModel.ansichtsdefinition.schemaplaetze;

    for (const visibleAnsicht of multiAnsichtViewModel.ansichtViewModels.filter(
      (ansicht) => ansicht.visible,
    )) {
      const year = visibleAnsicht.ansichtViewModel.year;

      const sendeplaetzeByYear = sendeplaetze.get(year) ?? [];
      const filteredSendeplaetze = sendeplaetzeByYear
        .map((sendeplatz) => {
          // Der Kalendertag des Sendeplatzes entspricht dem Wochentag des Schemaplatzes
          const sendeplatzBeginnzeit = DateFnsService.parseDateAndTimeToDateObject(
            sendeplatz.beginnzeit,
            sendeplatz.kalendertag,
          );
          const foundSchemaplatz = schemaplaetze.find(
            (schemaplatz) =>
              DateFnsService.getWeekday(sendeplatzBeginnzeit) === schemaplatz.wochentag &&
              sendeplatz.beginnzeit === schemaplatz.beginnzeit,
          );
          if (foundSchemaplatz) return sendeplatz;
          return null;
        })
        .filter(isDefined);

      const groupedBySendetag = groupByPredicateToMap(
        filteredSendeplaetze,
        (sendeplatz) => sendeplatz.sendetag,
      );

      const schemaplatzViewModelsBySchemaplatzLaenge: SendeplatzViewModel[][] = [];
      let sendeplatzIndex = 0;
      let filledSchemaplaetze = false;
      let sendeplaetzeWithSchemaplatzLaenge: SendeplatzViewModel[] = [];
      let sendeplatzGroupIndex = 0;
      for (const [sendetag, sendeplatzGroup] of groupedBySendetag.entries()) {
        const wochentage: Wochentag[] = [];

        // Füge leere Sendeplätze hinzu, wenn die Sendeplätze nicht mit den Schemaplätzen übereinstimmen.
        if (!filledSchemaplaetze && schemaplaetze.length > 1) {
          for (let i = 0; i < schemaplaetze.length - 1; i++) {
            // Hier kann immer auf das erste Element zugegriffen werden, da die Sendeplätze nach dem Sendetag gruppiert sind.
            const sendetagWeekday = DateFnsService.getWeekday(
              new Date(sendeplatzGroup[0].sendetag),
            );
            const schemaplatzWeekday = schemaplaetze[i].wochentag;
            if (sendetagWeekday != schemaplatzWeekday) {
              sendeplatzIndex++;
              if (!wochentage.includes(schemaplatzWeekday)) {
                wochentage.push(schemaplatzWeekday);
                const weekdayOfSchemaplatz = schemaplaetze.filter(
                  (sp) => sp.wochentag === schemaplatzWeekday,
                );

                const dummySendeplatzViewModel: SendeplatzViewModel = {
                  sendeplaetze: [...new Array<null>(weekdayOfSchemaplatz.length).fill(null)],
                  sendetagDatum: null,
                  dummies: weekdayOfSchemaplatz.map((sp) => ({
                    wochentag: sp.wochentag,
                    beginnzeit: sp.beginnzeit,
                  })),
                };
                sendeplaetzeWithSchemaplatzLaenge.push(dummySendeplatzViewModel);
              }
            }
          }
          filledSchemaplaetze = true;
        }

        // Füge die Sendeplätze hinzu
        sendeplaetzeWithSchemaplatzLaenge.push({
          sendeplaetze: sendeplatzGroup,
          sendetagDatum: new Date(sendetag),
        });

        // Inkrementiere den Index nach jedem Befüllen
        sendeplatzGroupIndex++;

        // Füge eine neue Zeile hinzu, wenn die Sendeplätze im schemaplatzTupelCache der Schemaplatzlänge entsprechen.
        sendeplatzIndex += sendeplatzGroup.length;
        if (
          sendeplatzIndex % schemaplaetze.length === 0 ||
          sendeplatzGroupIndex == groupedBySendetag.size
        ) {
          schemaplatzViewModelsBySchemaplatzLaenge.push(sendeplaetzeWithSchemaplatzLaenge);
          sendeplaetzeWithSchemaplatzLaenge = [];
        }
      }
      multiListenansichtSendeplaetzeByYear[year] = schemaplatzViewModelsBySchemaplatzLaenge;
    }
    return multiListenansichtSendeplaetzeByYear;
  }

  /**
   * Erstelle Listenansicht-Header auf Basis von Schemaplätzen in der Ansichtsdefinition. Entscheidend ist hierbei, die
   * Reihenfolge in der das Array gefüllt wird. Die Reihenfolge folgt dem Schema: Datum - Titel (Wochentag + Uhrzeit) -
   * Events & Konkurrenz (nur für den ersten Schemaplatz eines Wochentags).
   */
  static generateListenansichtHeader(
    ansichtViewModel: AnsichtViewModel | undefined,
  ): ListenansichtColumnHeader[] {
    const headers: ListenansichtColumnHeader[] = [];
    if (!ansichtViewModel) return headers;
    const schemaplaetze = [...ansichtViewModel.ansichtsdefinition.schemaplaetze].sort((a, b) => {
      if (a.wochentag != b.wochentag) {
        return 0;
      } else {
        return compareString(a.titel, b.titel);
      }
    });
    const wochentage: Wochentag[] = []; // Hilfs-Array für Fallunterscheidung bei gleichen Wochentagen
    for (const schemaplatz of schemaplaetze) {
      const wochentag = KanalOffsetUtils.getSendewochentag(
        schemaplatz.wochentag,
        schemaplatz.beginnzeit,
        ansichtViewModel.kanal,
      );
      if (!wochentage.includes(wochentag)) {
        headers.push({
          schemaplatzId: schemaplatz.id,
          title: ListenansichtHeaderEnum.DATUM,
          columnId: createColumnId(
            ListenansichtHeaderEnum.DATUM,
            wochentag,
            schemaplatz.beginnzeit,
          ),
          columnType: ListenansichtHeaderEnum.DATUM,
        });
      }

      // Vorgeplant Spalte
      headers.push({
        schemaplatzId: schemaplatz.id,
        title: `${wochentag} ${schemaplatz.beginnzeit}`,
        columnId: createColumnId(
          ListenansichtHeaderEnum.SCHEMAPLATZ,
          wochentag,
          schemaplatz.beginnzeit,
        ),
        columnType: ListenansichtHeaderEnum.SCHEMAPLATZ,
      });
      // Notizen von Vorgeplant/Sendeplatz
      headers.push({
        schemaplatzId: schemaplatz.id,
        title: ListenansichtHeaderEnum.NOTIZEN,
        columnId: createColumnId(
          ListenansichtHeaderEnum.NOTIZEN,
          wochentag,
          schemaplatz.beginnzeit,
        ),
        columnType: ListenansichtHeaderEnum.NOTIZEN,
      });
      // Vorschlag Spalte
      headers.push({
        schemaplatzId: schemaplatz.id,
        title: ListenansichtHeaderEnum.VORSCHLAEGE,
        columnId: createColumnId(
          ListenansichtHeaderEnum.VORSCHLAEGE,
          wochentag,
          schemaplatz.beginnzeit,
        ),
        columnType: ListenansichtHeaderEnum.VORSCHLAEGE,
      });

      // Notizen von Vorschlag/Sendeplatz
      headers.push({
        schemaplatzId: schemaplatz.id,
        title: ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN,
        columnId: createColumnId(
          ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN,
          wochentag,
          schemaplatz.beginnzeit,
        ),
        columnType: ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN,
      });

      // Zeige Events & Konkurrenzprogramme nur nach dem ersten Schemaplatz eines Wochentags
      if (!wochentage.includes(wochentag)) {
        wochentage.push(wochentag);
        headers.push({
          // Schemaplatz ist hier weniger wichtig, da die Spalte sich auf mehrere Schemaplätze beziehen kann
          schemaplatzId: schemaplatz.id,
          title: ListenansichtHeaderEnum.EVENTS_KONKURRENZ,
          columnId: createColumnId(
            ListenansichtHeaderEnum.EVENTS_KONKURRENZ,
            wochentag,
            schemaplatz.beginnzeit,
          ),
          columnType: ListenansichtHeaderEnum.EVENTS_KONKURRENZ,
        });
      }
    }
    return headers;
  }
}
