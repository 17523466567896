import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";

export const planungsobjektBeziehungWindowActions = createActionGroup({
  source: "Planungsobjekt Beziehung Window",
  events: {
    closeWindow: emptyProps(),
    openDeletePlanungsobjektBeziehungWindow: props<{
      planungsobjekt: PlanungsobjektDto;
      beziehung: BeziehungDto;
    }>(),
    updateCurrentBeziehungForm: props<{ updateOnDemandBeziehung: boolean }>(),
  },
});
