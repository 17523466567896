import { ZoomLevel } from "../openapi/model/zoom-level";

export const ZOOM_LEVELS: Record<ZoomLevel, { value: number; minutesPerBlock: number }> = {
  Level1: { value: 0, minutesPerBlock: 30 },
  Level2: { value: 1, minutesPerBlock: 15 },
  Level3: { value: 2, minutesPerBlock: 10 },
  Level4: { value: 3, minutesPerBlock: 5 },
  Level5: { value: 4, minutesPerBlock: 1 },
};

export const ZOOM_LEVELS_LIST = Object.entries(ZOOM_LEVELS).map(
  ([key, { value, minutesPerBlock }]): {
    value: number;
    minutesPerBlock: number;
    key: ZoomLevel;
  } => ({
    key: key as ZoomLevel,
    value,
    minutesPerBlock,
  }),
);
