<div class="sub-header">
  <label>Soll die bestehende Planung ergänzt oder ersetzt werden?</label>
</div>
<div class="window-action-buttons">
  <button fillMode="outline" kendoButton type="button" (click)="onClose()">Abbrechen</button>
  <button
    data-testid="ergaenzen"
    fillMode="outline"
    kendoButton
    type="button"
    (click)="onErgaenzenClick()"
  >
    Ergänzen
  </button>
  <kendo-panelbar class="ersetzen-panelbar">
    <kendo-panelbar-item title="ERSETZEN" [expanded]="true">
      <ng-template kendoPanelBarContent>
        <ng-container *ngIf="data.vorgeplantePlanungsobjekte">
          <div class="margin-5">
            <button
              *ngFor="let planungsobjekt of data.vorgeplantePlanungsobjekte"
              class="margin-5"
              kendoButton
              type="button"
              [attr.data-testid]="'ersetzen-' + planungsobjekt.titel"
              [fillMode]="'outline'"
              [themeColor]="'base'"
              [title]="
                cutTitle(planungsobjekt.titel).length < planungsobjekt.titel.length
                  ? planungsobjekt.titel
                  : ''
              "
              (click)="onErsetzenClick(planungsobjekt.id)"
            >
              <span class="button-title">{{ cutTitle(planungsobjekt.titel) }}</span>
            </button>
          </div>
        </ng-container>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</div>
