import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ansichtActions } from "src/app/core/stores/ansicht/ansicht.actions";
import { onDemandActions } from "src/app/core/stores/on-demand/on-demand.actions";
import { AppPage } from "src/app/models/enums/app-page";
import { ReloadNotificationService } from "../reload-notification.service";

@Component({
  templateUrl: "./reload-notification.component.html",
  styleUrls: ["./reload-notification.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReloadNotificationComponent {
  @Input() page: AppPage;
  @Output() updatePage = new EventEmitter<void>();

  AppPage = AppPage;

  constructor(
    private notificationService: ReloadNotificationService,
    private store: Store,
  ) {}

  get notificationCounter$(): Observable<number> {
    return this.notificationService.benachrichtigungCounter$;
  }

  updatePageClick(event: Event): void {
    event.preventDefault();
    this.updatePage.emit();

    // Sollten solche Unterscheidungen öfters nötig sein,
    // könnten wir auch eine "Meta Action" einführen, welche
    // prüft in welchem Bereich wir uns befinden und entsprechende
    // Aktionen auslöst.

    if (this.page === AppPage.Ansicht) {
      this.store.dispatch(ansichtActions.updateAllEntitiesInAnsicht());
    }

    if (this.page === AppPage.OnDemand) {
      this.store.dispatch(onDemandActions.loadOnDemand());
    }
  }
}
