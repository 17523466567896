import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LinearOnDemandBeziehungVorgemerktAktualisierenCommand } from "src/app/models/openapi/model/linear-on-demand-beziehung-vorgemerkt-aktualisieren-command";
import { LinearOnDemandBeziehungVorgemerktErstellenCommand } from "src/app/models/openapi/model/linear-on-demand-beziehung-vorgemerkt-erstellen-command";
import { LinearOnDemandBeziehungVorgeschlagenAktualisierenCommand } from "src/app/models/openapi/model/linear-on-demand-beziehung-vorgeschlagen-aktualisieren-command";
import { LinearOnDemandBeziehungVorgeschlagenVorgeplantErstellenCommand } from "src/app/models/openapi/model/linear-on-demand-beziehung-vorgeschlagen-vorgeplant-erstellen-command";
import { LinearOnDemandDto } from "src/app/models/openapi/model/linear-on-demand-dto";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LinearOnDemandApiService {
  private apiUrl = this.settings.apiUrl;
  private readonly linearOnDemandUrl = `${this.apiUrl}LinearOnDemand`;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getLinearOnDemandByLinearId$(linearId: string): Observable<LinearOnDemandDto> {
    return this.http.get<LinearOnDemandDto>(`${this.linearOnDemandUrl}/${linearId}`);
  }

  getLinearOnDemandByOnDemandId$(onDemandId: string): Observable<LinearOnDemandDto> {
    return this.http.get<LinearOnDemandDto>(`${this.linearOnDemandUrl}/ByOnline/${onDemandId}`);
  }

  linearOnDemandBeziehungVorgeschlagenVorgeplantErstellen$(
    command: LinearOnDemandBeziehungVorgeschlagenVorgeplantErstellenCommand,
  ) {
    return this.http.post<PlanungsobjekteDto>(
      `${this.linearOnDemandUrl}/VorgeschlagenVorgeplant`,
      command,
    );
  }

  linearOnDemandBeziehungVorgeschlagenVorgeplantAktualisieren$(
    command: LinearOnDemandBeziehungVorgeschlagenAktualisierenCommand,
  ) {
    return this.http.put<PlanungsobjekteDto>(
      `${this.linearOnDemandUrl}/VorgeschlagenVorgeplant/${command.planungsobjektLinearId}`,
      command,
    );
  }

  linearOnDemandBeziehungVorgemerktErstellen$(
    command: LinearOnDemandBeziehungVorgemerktErstellenCommand,
  ) {
    return this.http.post<PlanungsobjekteDto>(`${this.linearOnDemandUrl}/Vorgemerkt`, command);
  }

  linearOnDemandBeziehungVorgemerktAktualisieren$(
    command: LinearOnDemandBeziehungVorgemerktAktualisierenCommand,
  ) {
    return this.http.put<PlanungsobjekteDto>(
      `${this.linearOnDemandUrl}/Vorgemerkt/${command.planungsobjektLinearId}`,
      command,
    );
  }
}
