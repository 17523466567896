import { Injectable } from "@angular/core";
import { AbilityBuilder } from "@casl/ability";
import { Kanal } from "../models/openapi/model/kanal";
import { MerklisteDto } from "../models/openapi/model/merkliste-dto";
import { MerklisteKategorie } from "../models/openapi/model/merkliste-kategorie";
import { PlanungsobjektDto } from "../models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektOnDemandDto } from "../models/openapi/model/planungsobjekt-on-demand-dto";
import { PublitPermissionDto } from "../models/openapi/model/publit-permission-dto";
import { Role } from "../models/openapi/model/role";
import { SchemaplatzDto } from "../models/openapi/model/schemaplatz-dto";
import { SendeplatzDto } from "../models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "../models/viewmodels/ansicht-viewmodel";
import { AppAbility } from "./app-ability";
import { lambdaMatcher } from "./casl-utils";
import {
  canBearbeitenPlanungsobjekt,
  canBeitragenMerkliste,
  canBeitragenMerklisteOnDemand,
  canBeitragenPlanungsobjektOnDemand,
  canPlanenOnCurrentAnsicht,
  canPlanenOnSchemaplatz,
  canPlanenOnSendeplatz,
  filterAnsichtsdefinitionen,
  roleHasPlanenPermissionForKanal,
} from "./permission-checks";

@Injectable({
  providedIn: "root",
})
export class AppAbilityService {
  constructor(private ability: AppAbility) {}

  update(permissions: PublitPermissionDto[], ansichten: AnsichtViewModel[]) {
    const { can, build, rules } = new AbilityBuilder<AppAbility>(AppAbility);
    // new AbilityBuilder<PureAbility<[CaslActions, CaslSubjects], MatchConditions>>(
    //   PureAbility as AbilityClass<AppAbility>
    // );

    if (permissions === undefined || ansichten === undefined) {
      this.ability.update([]);
      return;
    }

    const ansichtsdefinitionen = filterAnsichtsdefinitionen(ansichten);

    // Wohin damit?
    // Das wäre eigentlich der neue Value für die Angular Dependency Injection,
    // aber diese wird einfach nur am Ende mit update() aktualisiert.
    // Dabei können jedoch nur neue rules übergeben werden, Options wie der
    // Conditions Matcher oder Subject Resolver können nicht aktualisiert werden
    // und müssen daher schon im App Module gesetzt werden.
    const _whereToPut = build({
      conditionsMatcher: lambdaMatcher,
    });

    //
    // Achtung!
    //
    // Bei der Verwendung von MatchConditions (Lambdas zur Auswertung der Berechtigung) gibt es ein
    // interessantes Verhalten von CASL. Auch wenn keine Argumente / Parameter im Lambda verwendet werden,
    // muss die Berechtigung mit irgendeinem Objekt (also z.B. subject('Ansicht', {})) aufgerufen werden.
    // Wenn man dies nicht macht, wird die Berechtigungsfunktion nicht ausgeführt und die Berechtigung
    // wird zu true ausgewertet. Ein super Default Verhalten.
    // Dies betrifft sowohl die Verwendung im Template (pipe) als auch in Komponenten (Service).
    // Eventuell hat das mit einem internen Caching zu tun?
    // Oder ist ein Argument einfach immer Pflicht für den ConditionsMatcher?
    //
    // Dies besondere Verhalten betrifft aktuell:
    //
    // ❌ 'planen' | able: 'Ansicht'
    // ✅ 'planen' | able: ({} | asSubject: 'Ansicht')
    // ❌ this.ability.can('planen', 'Ansicht')
    // ✅ this.ability.can('planen', asSubject('Ansicht', {}))

    // ❌ 'beitragen' | able: 'Merkliste'
    // ✅ 'beitragen' | able: ({} | asSubject: 'Merkliste')
    // ❌ this.ability.can('beitragen', 'Merkliste')
    // ✅ this.ability.can('beitragen', asSubject('Merkliste', {}))

    for (const permission of permissions) {
      // Ermöglicht bei Bedarf die direkte Abfrage von Berechtigungen,
      // wir können keine primitive types verwenden, daher muss der Geltungsbereich
      // in ein weiteres Objekt verpackt werden
      can(permission.role, "Permission", (params: { geltungsbereich: string }) => {
        // Wenn es sich um eine Berechtigung ohne Geltungsbereich handelt, ist sie erfüllt wenn vergeben:
        // Role.TECHNISCHER_ADMINISTRATOR | able: 'Permission',
        // Andernfalls muss ein Geltungsbereich als Subject übergeben werden:
        // Role.PLANUNGSOBJEKT_ZDF_LESEN | able: ({ geltungsbereich: '2021' } | asSubject: 'Permission')
        return (
          permission.geltungsbereiche === null ||
          permission.geltungsbereiche === undefined ||
          permission.geltungsbereiche.includes(params.geltungsbereich)
        );
      });

      // Die Berechtigung Lesen wird aktuell nicht verwendet, da aus dem Backend nur das
      // geliefert wird, was gelesen werden darf. Der Geltungsbereich (Jahre) könnte
      // dazu verwendet werden, um z.B. das maximale Jahr einzugrenzen, in dem ein
      // Event/Konkurrenzprogramm erstellt werden darf.
      if (permission.role === Role.PLANUNGSOBJEKT_ZDF_LESEN) {
        // can("lesen", "Planungsobjekt");
      }

      if (
        permission.role === Role.PLANUNGSOBJEKT_ZDF_PLANEN ||
        permission.role === Role.PLANUNGSOBJEKT_ZDF_NEO_PLANEN
      ) {
        can("planen", "Ansicht", () => {
          // console.log("planen.Ansicht?");
          return canPlanenOnCurrentAnsicht(ansichten, permission.geltungsbereiche);
        });

        can("planen", "Sendeplatz", (sendeplatz: SendeplatzDto) => {
          if (!roleHasPlanenPermissionForKanal(sendeplatz.kanal, permission.role)) {
            return false;
          }

          return canPlanenOnSendeplatz(
            ansichtsdefinitionen,
            permission.geltungsbereiche,
            sendeplatz,
          );
        });

        can("planen", "Schemaplatz", (schemaplatz: SchemaplatzDto) => {
          return canPlanenOnSchemaplatz(
            ansichtsdefinitionen,
            permission.geltungsbereiche,
            schemaplatz,
          );
        });

        // Weitere Berechtigungen müssen zusammen mit der Berechtigung Beitragen geprüft werden
      }

      //Einzelberechtigungen ohne Verteilebenen
      if (permission.role === Role.PLANUNGSOBJEKT_ZDF_MEDIATHEK_PLANEN) {
        can("planen", "OnDemand");
      }

      // Einzelberechtigungen ohne Verteilebenen
      if (permission.role === Role.PLANUNGSOBJEKT_BEITRAGEN) {
        can("umbenennen", "Merkliste", (merkliste: MerklisteDto) => {
          // Globale Ausspielweg Merklisten können niemals umbenannt werden
          return merkliste.kategorie !== MerklisteKategorie.AUSSPIELWEG;
        });
        // Weitere Berechtigungen müssen zusammen mit der Berechtigung Planen geprüft werden
      }

      // Einzelberechtigungen ohne Verteilebenen
      if (permission.role === Role.EVENT_KONKURRENZPROGRAMM_EDITIEREN) {
        // Editieren fasst hier erstellen/bearbeiten/löschen zusammen,
        // da es sich um eine Einzelberechtigung handelt macht ein feingranulares
        // Aufteilen der Berechtigung in "erstellen", "bearbeiten", "löschen" keinen Sinn
        can("editieren", "EK");
      }

      // Verteilebenen beinhaltet Kanäle/Ausspielwege für welche die Berechtigung gilt
      if (permission.role === Role.MENGENGERUEST_EDITIEREN) {
        can("editieren", "Mengengeruest", (params: { kanal: Kanal }) => {
          return permission.geltungsbereiche.includes(params.kanal);
        });
      }

      // Verteilebenen beinhaltet Kanäle/Ausspielwege für welche die Berechtigung gilt
      if (permission.role === Role.PHAROS_TRANSFER_DURCHFUEHREN) {
        can("transferNachPharos", "Sendeplatz", (sendeplatz: SendeplatzDto) => {
          return permission.geltungsbereiche.includes(sendeplatz.kanal);
        });
      }

      // Verteilebenen beinhaltet Kanäle/Ausspielwege für welche die Berechtigung gilt
      if (permission.role === Role.PLANUNGSHINWEIS_EDITIEREN) {
        can("editieren", "Planungshinweis", (params: { kanal: Kanal }) => {
          return permission.geltungsbereiche.includes(params.kanal);
        });
      }

      // Einzelberechtigungen ohne Verteilebenen
      // "manage" und "all" sind spezielle Schlüssel in CASL und entsprechen allen Subjects & Actions
      // https://casl.js.org/v6/en/guide/intro#basics
      if (permission.role === Role.TECHNISCHER_ADMINISTRATOR) {
        // can("manage", "all");
      }
    }

    // Ob eine Planungsobjekt bearbeitet werden kann, muss unter Berücksichtigung
    // der Rollen Planen und Beitragen geprüft werden.
    const permissionPlanenZdf = permissions.find(
      (permission) => permission.role === Role.PLANUNGSOBJEKT_ZDF_PLANEN,
    );
    const permissionBeitragen = permissions.find(
      (permission) => permission.role === Role.PLANUNGSOBJEKT_BEITRAGEN,
    );
    const permissionPlanenZdfMediathek = permissions.find(
      (permission) => permission.role === Role.PLANUNGSOBJEKT_ZDF_MEDIATHEK_PLANEN,
    );
    const permissionPlanenZdfNeo = permissions.find(
      (permission) => permission.role === Role.PLANUNGSOBJEKT_ZDF_NEO_PLANEN,
    );

    can("bearbeiten", "Planungsobjekt", (planungsobjekt: PlanungsobjektDto) => {
      let permissionForKanal;
      switch (planungsobjekt.kanal) {
        case Kanal.ZDF:
          permissionForKanal = permissionPlanenZdf;
          break;
        case Kanal.ZDF_NEO:
          permissionForKanal = permissionPlanenZdfNeo;
          break;
      }

      return canBearbeitenPlanungsobjekt(
        planungsobjekt,
        permissionBeitragen,
        permissionForKanal,
        ansichten,
      );
    });

    can("beitragen", "PlanungsobjektOnDemand", (planungsobjekt: PlanungsobjektOnDemandDto) => {
      return canBeitragenPlanungsobjektOnDemand(
        planungsobjekt,
        permissionBeitragen,
        permissionPlanenZdfMediathek,
      );
    });

    can("beitragen", "Merkliste", (merkliste: MerklisteDto) => {
      // console.log("beitragen.Merkliste?", merkliste);
      if (merkliste.kategorie === MerklisteKategorie.ANSICHT) {
        return (
          canBeitragenMerkliste(permissionBeitragen, permissionPlanenZdf, ansichten) ||
          canBeitragenMerkliste(permissionBeitragen, permissionPlanenZdfNeo, ansichten)
        );
      }

      return canBeitragenMerklisteOnDemand(permissionBeitragen, permissionPlanenZdfMediathek);
    });

    this.ability.update(rules);
  }
}
