import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { format } from "date-fns";
import { Observable, map } from "rxjs";
import planungshinweisSelectors from "src/app/core/stores/planunshinweis/planungshinweis.selectors";
import { PlanungshinweisStatusRecord } from "src/app/models/enums/planungshinweis-status";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { PlanungshinweisDto } from "src/app/models/openapi/model/planungshinweis-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzOverlappingPipe } from "./sendeplatz-overlapping.pipe";

@Pipe({
  name: "planungshinweisTooltip",
})
export class PlanungshinweisTooltipPipe implements PipeTransform {
  constructor(
    private store: Store,
    private sendeplatzOverlappingPipe: SendeplatzOverlappingPipe,
  ) {}

  transform(
    start: Date | string,
    end: Date | string,
    kanal: Kanal,
    sendeplatz?: SendeplatzDto,
    sendeplaetze?: (SendeplatzDto | null)[],
  ): Observable<string> {
    const overlappingLabel =
      sendeplaetze && this.sendeplatzOverlappingPipe.transform(sendeplatz, sendeplaetze)
        ? "\nÜberlappung mit anderem Sendeplatz"
        : "";

    const von = new Date(start);
    const bis = new Date(end);
    return this.store
      .select(planungshinweisSelectors.selectPlanungshinweiseByTimeRange(von, bis, kanal))
      .pipe(
        map((planungshinweise) => {
          const tooltipContent = planungshinweise
            .map((planungshinweis) => this.toTooltip(planungshinweis))
            .join(" || ");

          return (tooltipContent + overlappingLabel).trim();
        }),
      );
  }

  private toTooltip(planungshinweis: PlanungshinweisDto): string {
    const status = PlanungshinweisStatusRecord[planungshinweis.status].bedeutung;
    const von = new Date(planungshinweis.von);
    const bis = new Date(planungshinweis.bis);
    return `${status} von ${format(von, "dd.MM.yyyy HH:mm")} bis ${format(
      bis,
      "dd.MM.yyyy HH:mm",
    )} ${planungshinweis.notiz}`;
  }
}
