<tr>
  <th
    class="kalenderansicht-header-month"
    [attr.colspan]="headerLength$ | async"
    [attr.data-testid]="'year-divider-' + sendeplaetzeYearKey + '-' + (monthIndex | monthByIndex)"
    [ngClass]="{ 'load-previous-year-empty-space': !firstYear || !firstMonth }"
  >
    @if (firstYear && firstMonth) {
      <button
        class="next-year-button k-px-2 k-p-0"
        data-testid="previous-year-arrow-button"
        fillMode="flat"
        kendoButton
        size="none"
        title="Lade Ansicht für vorheriges Jahr"
        [disabled]="(facade.hasPreviousAnsicht$ | async) === false"
        [svgIcon]="Icons.arrowUpIcon"
        (click)="facade.loadPreviousAnsicht()"
      ></button>
    }

    {{ monthIndex | monthByIndex }} -
    {{ sendeplaetzeYearKey }}
  </th>
</tr>
@for (sendeplatzWeek of sendeplaetzeMonth; track $index) {
  <app-kalenderansicht-sendeplatz-woche
    [ansichtViewModel]="ansichtViewModel"
    [sendeplatzWeek]="sendeplatzWeek"
  >
  </app-kalenderansicht-sendeplatz-woche>
}
