import { PlanungshinweisStatus } from "../openapi/model/planungshinweis-status";
import { FarbgebungObject } from "../viewmodels";
import { enumFarbgebungToSelectOptions, EnumMetadata } from "./enum-base";

export const PlanungshinweisStatusMap: Record<
  PlanungshinweisStatus,
  FarbgebungObject<PlanungshinweisStatus>
> = {
  KeinStatus: {
    enum: PlanungshinweisStatus.KEIN_STATUS,
    name: "kein Status",
    background: "#85888C",
    color: "#FFFFFF",
    borderColor: null,
  },
  Problematisch: {
    enum: PlanungshinweisStatus.PROBLEMATISCH,
    name: "problematisch",
    background: "#EB0025",
    color: "#FFFFFF",
    borderColor: null,
  },
};

export const PlanungshinweisStatusRecord: Record<PlanungshinweisStatus, EnumMetadata> = {
  KeinStatus: { bedeutung: "kein Status" },
  Problematisch: { bedeutung: "problematisch" },
};

export const PlanungshinweisStatusOptions = enumFarbgebungToSelectOptions(PlanungshinweisStatusMap);
