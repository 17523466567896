import { Component, Input } from "@angular/core";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektWindowUseCase, Publikationsart } from "../planungsobjekt-window.model";

@Component({
  selector: "app-planung-tab",
  templateUrl: "./planung-tab.component.html",
  styleUrls: ["./planung-tab.component.scss"],
})
export class PlanungTabComponent {
  @Input({ required: true }) planungskontext: Planungskontext;
  @Input({ required: true }) usecase: PlanungsobjektWindowUseCase;
  @Input({ required: true }) publikationsart: Publikationsart;
  @Input({ required: true }) kanal: Kanal;
  @Input({ required: true }) planungsobjekt: PlanungsobjektDto | null;
}
