import { entriesOf } from "src/app/utils/object-utils";
import { FarbgebungObject } from "../viewmodels";

export interface SelectOption<T> {
  text: string;
  value: T;
  disabled?: boolean;
}

export interface EnumMetadata {
  bedeutung: string;
  zusatz?: string;
  short?: string;
}

export function enumRecordToSelectOptions<T extends string>(
  record: Record<T, EnumMetadata>,
): SelectOption<T>[] {
  return entriesOf(record).map(
    ([key, { bedeutung, zusatz, short }]): SelectOption<T> => ({
      text: (short ? `${short} - ${bedeutung}` : bedeutung) + (zusatz ? " " + zusatz : ""),
      value: key,
    }),
  );
}

export function enumRecordToSelectOptionsPrefereShort<T extends string>(
  record: Record<T, EnumMetadata>,
): SelectOption<T>[] {
  return entriesOf(record).map(
    ([key, { bedeutung, short }]): SelectOption<T> => ({
      text: short ? short : bedeutung,
      value: key,
    }),
  );
}

export function enumFarbgebungToSelectOptions<T extends string, E extends string>(
  record: Record<T, FarbgebungObject<E>>,
): SelectOption<T>[] {
  return entriesOf(record).map(
    ([key, { name }]): SelectOption<T> => ({
      text: name,
      value: key,
    }),
  );
}

export const toSelectOptionRecord = <T extends string>(enumObject: SelectOption<T>[]) => {
  return enumObject.reduce(
    (acc, value) => {
      acc[value.value] = value;
      return acc;
    },
    {} as Record<string, SelectOption<T>>,
  );
};
