import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { routerRequestAction } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { catchError, filter, map, of, switchMap, tap } from "rxjs";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";
import { SearchResultsDto } from "src/app/models/openapi/model/search-results-dto";
import { RechercheService } from "src/app/services/recherche.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { allValuesDefined } from "src/app/utils/array-utils";
import { filterAngularNavigationEvent } from "src/app/utils/observable.utils";
import { filterByUrlFromNavigationEvent, filterNavigatedToPage } from "src/app/utils/route.utils";
import { Planungskontext } from "tests/common/generated/api";
import { notificationActions } from "../notification/notification.actions";
import { planungsobjektWindowActions } from "../planungsobjekt-window/planungsobjekt-window.actions";
import { rechercheActions } from "./recherche.actions";
import { rechercheFeature } from "./recherche.reducer";
import rechercheSelectors from "./recherche.selectors";
import {
  extractRechercheGridFormattingOptionsVMFromParams,
  extractRecherchePageQueryParamsFromUrlParams,
} from "./recherche.utils";

const resultIds = (planungsobjekte: PlanungsobjekteDto) => [
  ...planungsobjekte.linear.map((po) => po.id),
  ...planungsobjekte.onDemand.map((po) => po.id),
];

@Injectable()
export class RechercheEffects {
  loadSearchResults$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rechercheActions.search, rechercheActions.searchByBrowserNavigation),
      concatLatestFrom(() =>
        this.store.select(rechercheSelectors.selectSearchQueryAndFormattingParams),
      ),
      map(([action, params]) => ({ ...action, ...params })),
      filter(allValuesDefined),
      tap(
        ({ type, query, formattingOptions }) =>
          // Wenn die Suche durch eine Browser-Navigation ausgelöst wurde, dann
          // soll nur die Suche gestartet werden, da die URL bereits die QueryParams enthält.
          type === rechercheActions.search.type &&
          this.service.navigateBySearch(query, formattingOptions),
      ),
      switchMap(({ query }) =>
        this.service.search$(query).pipe(
          map(({ planungsobjekte, idsWithVarianten }: SearchResultsDto) =>
            rechercheActions.searchSuccess({
              results: planungsobjekte,
              idsWithVarianten: idsWithVarianten,
              // Bei einer regulären Suche sollten wir eigentlich keine Kinder laden  / aktuelle überschreiben?
              isChild: [],
              // Bei einer regulären Suche laden wir nur Eltern
              isParent: resultIds(planungsobjekte),
            }),
          ),
          catchError((error: unknown) => {
            // Ohne throw kommen wir nicht mehr in den Error Handler im
            // Loading Interceptor und zeigen daher auch keine Error Notification an
            // throw error;
            return of(
              notificationActions.showNotification({
                message: "Fehler beim Laden der Ergebnisse",
                notificationType: "Error",
              }),
            );
          }),
        ),
      ),
    );
  });

  searchChildren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rechercheActions.searchChildren),
      filter(allValuesDefined),
      switchMap(({ childrenIds }) => {
        return this.service.searchChildren$(childrenIds).pipe(
          map(({ planungsobjekte, idsWithVarianten }: SearchResultsDto) => {
            const action = rechercheActions.searchChildrenSuccess({
              results: planungsobjekte,
              idsWithVarianten: idsWithVarianten,
              isChild: resultIds(planungsobjekte),
            });

            const linear = planungsobjekte.linear.filter(
              (po) => po.planungskontext !== Planungskontext.VORGEMERKT,
            );
            const onDemand = planungsobjekte.onDemand.filter(
              (po) => po.planungskontext !== Planungskontext.VORGEMERKT,
            );

            // Wir haben vorgemerkte Planungsobjekte gefunden, die nicht gesucht werden sollen
            if (
              linear.length !== planungsobjekte.linear.length ||
              onDemand.length !== planungsobjekte.onDemand.length
            ) {
              this.notificationService.showNotification(
                "Vorgemerkte Planungsobjekte sollen nicht gesucht werden.",
                "Error",
              );

              action.results = {
                linear,
                onDemand,
              };
            }
            return action;
          }),
          catchError((error: unknown) => {
            // Ohne throw kommen wir nicht mehr in den Error Handler im
            // Loading Interceptor und zeigen daher auch keine Error Notification an
            // throw error;
            return of(
              notificationActions.showNotification({
                message: "Fehler beim Laden der Ergebnisse",
                notificationType: "Error",
              }),
            );
          }),
        );
      }),
    );
  });

  updateFormattingOptions$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          rechercheActions.toggleGridColumns,
          rechercheActions.reorderRechercheColumns,
          rechercheActions.updateQuery,
        ),
        concatLatestFrom(() =>
          this.store.select(rechercheSelectors.selectSearchQueryAndFormattingParams),
        ),
        tap(([_, formattingParams]) =>
          this.service.updateFormattingOptionsInUrl(formattingParams.formattingOptions),
        ),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect, der auf Browser-Navigation-Events reagiert und die Recherche-Query und die
   * Formatierungsoptionen aus der URL extrahiert und die Suche startet.
   */
  handleBrowserNavigation$ = createEffect(() => {
    return this.actions$.pipe(
      filterNavigatedToPage("recherche"),
      map(({ url }) => {
        const { queryParams } = this.router.parseUrl(url);
        const query = extractRecherchePageQueryParamsFromUrlParams(queryParams);
        const formattingOptions = extractRechercheGridFormattingOptionsVMFromParams(queryParams);
        return { query, formattingOptions };
      }),
      filter(allValuesDefined),
      map(({ query, formattingOptions }) =>
        rechercheActions.searchByBrowserNavigation({
          query,
          shownColumns: formattingOptions.shownColumns ?? [],
        }),
      ),
    );
  });

  /**
   * Wenn der Nutzer die Recherche-Seite über den Link in der Navigation aufruft (also
   * "/recherche" ohne Query-Parameter), dann soll die Suche zurückgesetzt werden.
   */
  handleImperativeNavigation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerRequestAction),
      map((event) => event.payload.event),
      filterByUrlFromNavigationEvent("recherche"),
      filterAngularNavigationEvent(),
      map((event) => {
        const { queryParams } = this.router.parseUrl(event.url);
        return extractRecherchePageQueryParamsFromUrlParams(queryParams);
      }),
      filter((query): query is null => !query),
      concatLatestFrom(() => this.store.select(rechercheSelectors.selectSearchQueryVM)),
      map(() => rechercheActions.searchReset()),
    );
  });

  openRechercheResultDetails$ = createEffect(() => {
    // TODO: Effect testen
    return this.actions$.pipe(
      ofType(rechercheActions.openRechercheResultDetails),
      concatLatestFrom(() => this.store.select(rechercheFeature.selectResults)),
      map(([{ result }, results]) => {
        const isLinear = results.linear.some((linear) => linear.id === result.id);
        const isOnDemand = results.onDemand.some((onDemand) => onDemand.id === result.id);

        const planungsobjektType = isLinear ? "linear" : isOnDemand ? "ondemand" : null;

        return planungsobjektType === null
          ? notificationActions.showNotification({
              message: "Planungsobjekt nicht gefunden",
              notificationType: "Error",
            })
          : planungsobjektWindowActions.openPlanungsobjektWindowReadonlyForId({
              planungsobjektId: result.id,
              planungsobjektType,
            });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private service: RechercheService,
    private notificationService: CustomNotificationService,
    private router: Router,
  ) {}
}
