import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { AktivitaetDto } from "src/app/models/openapi/model/aktivitaet-dto";
import { FeatureKey } from "../feature.keys";
import { aktivitaetenActions } from "./aktivitaeten.actions";

export type AktivitaetenState = EntityState<AktivitaetDto>;

export const aktivitaetenAdapter: EntityAdapter<AktivitaetDto> = createEntityAdapter<AktivitaetDto>(
  {},
);

export const initialState: AktivitaetenState = aktivitaetenAdapter.getInitialState({});

export const aktivitaetenFeature = createFeature({
  name: FeatureKey.Aktivitaeten,
  reducer: createReducer(
    initialState,
    on(
      aktivitaetenActions.loadAktivitaetenForPlanungsobjekt,
      (_currentState): AktivitaetenState => {
        return initialState;
      },
    ),
    on(
      aktivitaetenActions.loadAktivitaetenForPlanungsobjektSuccess,
      (currentState, { aktivitaeten }): AktivitaetenState => {
        // Da wir Aktivitäten immer nur für ein Objekt in einem Fenster anzeigen,
        // werden mit setAll() alle alten Aktivitäten gelöscht und durch neu geladene ersetzt
        return aktivitaetenAdapter.setAll(aktivitaeten, currentState);
      },
    ),
  ),
  extraSelectors: ({ selectAktivitaetenState }) => ({
    ...aktivitaetenAdapter.getSelectors(selectAktivitaetenState),
  }),
});
