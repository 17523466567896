/**
 * Platzhalter im `text` werden aufgelöst
 * @param text String mit Platzhaltern `%1`, `%2`, ...
 * @param args Werte für die Platzhalter in `text`
 * @returns `text` mit aufgelösten Platzhaltern aus `args`
 */
export function formatString(text: string, args?: string[]): string {
  return text.replace(/%(\d+)/g, function (match, number: number) {
    number -= 1;
    return args && typeof args[number] !== "undefined" ? args[number] : "";
  });
}

/**
 * Verewndet um die Sortierung von Strings zu vereinheitlichen
 */
export function compareString(a: string, b: string, options?: Intl.CollatorOptions): number {
  const collator = new Intl.Collator("de", options);
  return collator.compare(a, b);
}
