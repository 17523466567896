import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { FeatureKey } from "../feature.keys";
import { mengengeruestActions } from "./mengengeruest.actions";

export interface MengengeruestEintragState extends EntityState<MengengeruesteintragDto> {
  loading: boolean;
}

export const mengengeruestEintragAdapter: EntityAdapter<MengengeruesteintragDto> =
  createEntityAdapter<MengengeruesteintragDto>();

export const initialMengengeruestEintragState: MengengeruestEintragState =
  mengengeruestEintragAdapter.getInitialState({
    loading: false,
  });

export const mengengeruestEintragFeature = createFeature({
  name: FeatureKey.Mengengeruest,
  reducer: createReducer(
    initialMengengeruestEintragState,
    on(
      mengengeruestActions.addMengengeruestEintraegeByAnsichtIdSuccess,
      (state, { mengengeruestEintraege }): MengengeruestEintragState => {
        return mengengeruestEintragAdapter.upsertMany(mengengeruestEintraege, state);
      },
    ),
    on(
      mengengeruestActions.createMengengeruestEintragSuccess,
      (state, { mengengeruestEintrag }): MengengeruestEintragState => {
        return mengengeruestEintragAdapter.addOne(mengengeruestEintrag, state);
      },
    ),
    on(
      mengengeruestActions.updateMengengeruestEintragSuccess,
      (state, { mengengeruestEintrag }): MengengeruestEintragState => {
        return mengengeruestEintragAdapter.updateOne(
          {
            id: mengengeruestEintrag.id,
            changes: mengengeruestEintrag,
          },
          state,
        );
      },
    ),
    on(
      mengengeruestActions.deleteMengengeruestEintragSuccess,
      (state, { mengengeruestEintragId }): MengengeruestEintragState => {
        return mengengeruestEintragAdapter.removeOne(mengengeruestEintragId, state);
      },
    ),
  ),
  extraSelectors: ({ selectMengengeruestState }) => ({
    ...mengengeruestEintragAdapter.getSelectors(selectMengengeruestState),
  }),
});
