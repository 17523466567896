import { Injectable } from "@angular/core";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { PlanungsobjektDto } from "../models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "../models/openapi/model/sendeplatz-dto";
import { CustomDialogAction } from "../shared/dialogs/standard-action-dialog/standard-action-dialog.component";
import { CustomDialogService } from "./custom-dialog.service";
import { DateFnsService } from "./date-fns.service";

export type UnifyDurationDialogInput = {
  planungsobjekt: PlanungsobjektDto;
  sendeplatz: SendeplatzDto;
  overrideHasMengengeruest?: boolean;
  planlaengeInSeconds?: number;
};

/**
 * Das Ergebnis des Dialogs zur Abfrage, ob Längen angeglichen werden sollen.
 */
export type UnifyDurationDialogResult = CustomDialogAction<SendeplatzDto[]> | DialogCloseResult;

@Injectable({
  providedIn: "root",
})
export class DurationService {
  defaultDeviationSeconds = 5 * 60;

  constructor(private readonly customdialogService: CustomDialogService) {}

  /**
   * Die andere Dauer ist weniger als +/- Sekunden von der Dauer entfernt
   *
   * @param duration Dauer in `'mm:ss'`, wobei `'mm'` nicht auf 60 begrenzt ist
   * @param otherDuration Andere Dauer in `'mm:ss'`, wobei `'mm'` nicht auf 60 begrenzt ist
   * @param seconds Sekunden der maximalen Abweichung (immer positiv)
   * @returns `true` wenn die andere Dauer weniger als +/- Sekunden von der Dauer entfernt ist
   */
  isOtherDurationStringLessOrEqualSecondsAwayFromDurationString(
    duration: string,
    otherDuration: string,
    seconds?: number,
  ): boolean {
    const durationInSeconds = DateFnsService.parseDurationStringToSeconds(duration);
    const otherDurationInSeconds = DateFnsService.parseDurationStringToSeconds(otherDuration);
    return this.isOtherDurationLessOrEqualSecondsAwayFromDuration(
      durationInSeconds,
      otherDurationInSeconds,
      seconds,
    );
  }

  /**
   * Die andere Dauer ist weniger als +/- Sekunden von der Dauer entfernt
   *
   * @param duration Dauer in Sekunden
   * @param otherDuration Dauer in Sekunden
   * @param seconds Sekunden der maximalen Abweichung (immer positiv)
   * @returns `true` wenn die andere Dauer weniger als +/- Sekunden von der Dauer entfernt ist
   */
  isOtherDurationLessOrEqualSecondsAwayFromDuration(
    duration: number,
    otherDuration: number,
    seconds?: number,
  ): boolean {
    return Math.abs(duration - otherDuration) <= (seconds ?? this.defaultDeviationSeconds);
  }

  /**
   * Prüft, ob in {@link groups} eine Planungsobjekt mit Mengengerüst existiert, deren Länge sich von ihrem Sendeplatz
   * unterscheidet. Eine einzige Nichtübereinstummung der Längen reicht aus damit der Dialog ausgespielt wird.
   * @param groups Die Gruppen, die geprüft werden sollen
   */
  checkUnifyDuration(groups: UnifyDurationDialogInput[]) {
    // Alle ohne Mengengerüst herausfiltern
    const groupsMitMengengeruest = groups.filter(
      (group) => group.overrideHasMengengeruest ?? !!group.planungsobjekt.mengengeruesteintragId,
    );

    for (let i = 0; i < groupsMitMengengeruest.length; i++) {
      const group = groupsMitMengengeruest[i];

      // Publikationsplanung.Laenge ist immer gesetzt, falls die Planungsobjekt aus dem Backend geladen wurde
      const planungsobjektLaengeInSeconds =
        group.planlaengeInSeconds ?? group.planungsobjekt.planlaenge;

      const sendeplatzLaengeInSeconds =
        group.sendeplatz?.abweichendeLaenge ?? group.sendeplatz?.laenge ?? 0;

      if (
        group.sendeplatz &&
        planungsobjektLaengeInSeconds &&
        !this.isOtherDurationLessOrEqualSecondsAwayFromDuration(
          sendeplatzLaengeInSeconds,
          planungsobjektLaengeInSeconds,
        )
      ) {
        return {
          groupsMitMengengeruest,
          sendeplatzLaengeInSeconds,
          planungsobjektLaengeInSeconds,
        };
      }
    }

    return null;
  }

  /**
   * Öffnet einen Dialog zur Abfrage, ob Längen angeglichen werden sollen.
   */
  openUnifyDurationDialog({
    groupsMitMengengeruest,
    sendeplatzLaengeInSeconds,
    planungsobjektLaengeInSeconds,
  }: {
    groupsMitMengengeruest: UnifyDurationDialogInput[];
    sendeplatzLaengeInSeconds: number;
    planungsobjektLaengeInSeconds: number;
  }) {
    const moreThanOneEntry = groupsMitMengengeruest.length > 1;

    const title = "Unterschiedliche Länge für Sendeplatz und Programm";
    const content = moreThanOneEntry
      ? `Die Längen der Sendeplätze und der Programme unterscheiden sich. Sollen sie überschrieben werden?`
      : `Soll die Länge des Sendeplatzes (${
          sendeplatzLaengeInSeconds / 60
        } min.) mit der Länge des Programms (${
          planungsobjektLaengeInSeconds / 60
        } min.) überschrieben werden?`;
    const actions: CustomDialogAction<SendeplatzDto[]>[] = [
      {
        text: `Sendeplatzlänge${moreThanOneEntry ? "n" : ""} überschreiben`,
        fillMode: "solid",
        themeColor: "primary",
        data: groupsMitMengengeruest.map((group) => ({
          ...group.sendeplatz,
          abweichendeLaenge: group.planlaengeInSeconds ?? group.planungsobjekt.planlaenge,
        })),
      },
      {
        text: `Länge${moreThanOneEntry ? "n" : ""} nicht überschreiben`,
        fillMode: "outline",
        isCancelAction: true,
      },
    ];

    const dialogRef = this.customdialogService.openStandardActionDialog({
      title,
      content,
      actions,
    });

    return dialogRef;
  }
}
