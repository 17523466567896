import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { map, switchMap } from "rxjs";
import { Aktion } from "src/app/models/enums/aktion";
import { CustomDialogService } from "src/app/services/custom-dialog.service";
import { beziehungActions } from "../beziehung/beziehung.actions";
import { planungsobjektBeziehungWindowActions } from "./planungsobjekt.beziehung.window.actions";

@Injectable()
export class PlanungsobjektBeziehungWindowEffects {
  openDeletePlanungsobjektBeziehungWindowOld$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektBeziehungWindowActions.openDeletePlanungsobjektBeziehungWindow),
      switchMap(({ beziehung, planungsobjekt }) =>
        this.customDialogService
          .openDeleteDialog(
            beziehung.titel,
            Aktion.ENTFERNE_PLANUNGSOBJEKTBEZIEHUNG,
            undefined,
            `Du bist dabei die Verlinkung "${beziehung.titel}" aufzulösen.`,
          )
          .result.pipe(
            map((dialogResult) => {
              if (dialogResult instanceof DialogCloseResult)
                return planungsobjektBeziehungWindowActions.closeWindow();
              if (dialogResult.action === Aktion.ENTFERNE_PLANUNGSOBJEKTBEZIEHUNG) {
                return beziehungActions.removeBeziehung({
                  planungsobjekt,
                  beziehung,
                });
              }
              return planungsobjektBeziehungWindowActions.closeWindow();
            }),
          ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private customDialogService: CustomDialogService,
  ) {}
}
