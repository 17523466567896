import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DialogCloseResult, WindowCloseResult } from "@progress/kendo-angular-dialog";
import { map, switchMap } from "rxjs";
import { Aktion } from "src/app/models/enums/aktion";
import { CustomDialogService } from "src/app/services/custom-dialog.service";
import { MengengeruestService } from "src/app/services/mengengeruest.service";
import { MengengeruestWindowUseCase } from "src/app/shared/windows/mengengeruest-window/mengengeruest-window.component";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { mengengeruestActions } from "./mengengeruest.actions";
import { mengengeruestWindowActions } from "./mengengeruest.window.actions";

@Injectable()
export class MengengeruestWindowEffects {
  private readonly actions$ = inject(Actions);
  private readonly customDialogService = inject(CustomDialogService);
  private readonly mengengeruestService = inject(MengengeruestService);

  openDeleteMengengeruesteintragDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.openDeleteMengengeruesteintragDialog),
      switchMap(({ mengengeruesteintrag }) =>
        this.customDialogService
          .openDeleteDialog(
            mengengeruesteintrag.titel,
            Aktion.ENTFERNE_MENGENGERUESTEINTRAG,
            undefined,
            `Du bist dabei den Mengengerüsteintrag "${mengengeruesteintrag.titel}" zu löschen.`,
          )
          .result.pipe(
            map((dialogResult) => {
              if (dialogResult instanceof DialogCloseResult)
                return mengengeruestWindowActions.closeWindow();
              if (dialogResult.action === Aktion.ENTFERNE_MENGENGERUESTEINTRAG) {
                return mengengeruestActions.deleteMengengeruestEintrag({
                  mengengeruestEintragId: mengengeruesteintrag.id,
                });
              }
              return mengengeruestWindowActions.closeWindow();
            }),
          ),
      ),
    );
  });

  openMengengeruestWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.openMengengeruestWindow),
      switchMap(
        (action) =>
          this.mengengeruestService.openMengengeruesteWindow(action.ansichtViewModel).result,
      ),
      map((result) =>
        mengengeruestWindowActions.handleMengengeruestWindowResult({
          result,
        }),
      ),
    );
  });

  openMengengeruestZuweisungWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.openMengengeruestZuweisungWindow),
      switchMap(
        (action) =>
          this.mengengeruestService.openMengengeruestForPlanungsobjektZuweisungWindow(action.input)
            .result,
      ),
      map((result) =>
        mengengeruestWindowActions.handleMengengeruestWindowResult({
          result,
        }),
      ),
    );
  });

  handleMengengeruestWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.handleMengengeruestWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return mengengeruestWindowActions.closeWindow();

        // Wenn sich die mengengeruesteintragId verändert hat, dann wurde ein Mengengerüst entweder zugewiesen oder entfernt
        if (
          result.zugewiesenePlanungsobjekt.mengengeruesteintragId !== result.previousMengengeruestId
        ) {
          return planungsobjektActions.assignPlanungsobjektToMengengeruest({
            sendeplatz:
              result.type === MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ
                ? result.sendeplatz
                : undefined,
            planungsobjekt: result.zugewiesenePlanungsobjekt,
            mengengeruesteintragId: result.zugewiesenePlanungsobjekt.mengengeruesteintragId,
          });
        } else {
          return mengengeruestWindowActions.closeWindow();
        }
      }),
    );
  });

  openCreateMengengeruestEintragWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.openCreateMengengeruestEintragWindow),
      switchMap(
        (action) =>
          this.mengengeruestService.openCreateMengengeruesteintragWindow(
            action.ansichtId,
            action.schemaplatzId,
            action.presetBeginnzeit,
          ).result,
      ),
      map((result) =>
        mengengeruestWindowActions.handleCreateMengengeruestEintragWindowResult({
          result,
        }),
      ),
    );
  });

  handleCreateMengengeruestEintragWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.handleCreateMengengeruestEintragWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return mengengeruestWindowActions.closeWindow();

        return mengengeruestActions.createMengengeruestEintrag({
          createMengengeruestEintragCommand: result.mengengeruesteintrag,
        });
      }),
    );
  });

  openEditMengengeruestEintragWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.openEditMengengeruestEintragWindow),
      switchMap(
        (action) =>
          this.mengengeruestService.openEditMengengeruesteintragWindow(
            action.oldMengengeruesteintrag,
          ).result,
      ),
      map((result) =>
        mengengeruestWindowActions.handleEditMengengeruestEintragWindowResult({
          result,
        }),
      ),
    );
  });

  handleEditMengengeruestEintragWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestWindowActions.handleEditMengengeruestEintragWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return mengengeruestWindowActions.closeWindow();

        return mengengeruestActions.updateMengengerustEintrag({
          updateMengengeruestEintragCommand: result.mengengeruesteintrag,
        });
      }),
    );
  });
}
