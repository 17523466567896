import { ClientLogLevel } from "../models/openapi/model/client-log-level";

export interface RequestData {
  payload: string;
  url: string;
  urlWithParams: string;
  method: string;
}

export class HttpRequestError extends Error {
  message: string;
  level: ClientLogLevel;
  status?: number;
  requestData?: RequestData;

  constructor(message: string, level: ClientLogLevel, status?: number, requestData?: RequestData) {
    super(message);
    this.message = message;
    this.status = status;
    this.level = level;
    this.requestData = requestData;
    this.name = HttpRequestError.name;
    Object.setPrototypeOf(this, HttpRequestError.prototype);
  }
}
