import { createSelector } from "@ngrx/store";
import { Role } from "src/app/models/openapi/model/role";
import { YearDropdownItem } from "src/app/on-demand/components/on-demand-header/on-demand-header.component";
import { permissionFeature } from "./permissions.reducer";

const selectPermissionsAll = permissionFeature.selectPermissions;

const selectPermissionForRole = (role: Role) => {
  return createSelector(permissionFeature.selectPermissionState, (state) =>
    state.permissions?.find((permission) => permission.role === role),
  );
};

const selectGeltungsbereichYearsForOnDemand = createSelector(
  selectPermissionForRole(Role.PLANUNGSOBJEKT_ZDF_LESEN),
  (permissions): YearDropdownItem[] => {
    if (!permissions || !permissions.geltungsbereiche) return [];

    const years: YearDropdownItem[] = permissions.geltungsbereiche.map((year) => {
      return {
        text: year,
        value: {
          von: new Date(`${year}-01-01`),
          bis: new Date(`${year}-12-31`),
        },
      };
    });
    return years;
  },
);

export default {
  selectPermissionsAll,
  selectPermissionForRole,
  selectGeltungsbereichYearsForOnDemand,
};
