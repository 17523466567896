import { Kanal } from "../openapi/model/kanal";
import { EnumMetadata, enumRecordToSelectOptions } from "./enum-base";

export const KanalRecord = {
  Unbekannt: { bedeutung: "Unbekannt" },
  ZDF: { bedeutung: "ZDF" },
  ZDFInfo: { bedeutung: "ZDFinfo" },
  ZDFNeo: { bedeutung: "ZDFneo" },
  DreiSat: { bedeutung: "3sat" },
  ZDFMediathek: { bedeutung: "ZDFmediathek" },
} as const satisfies Record<Kanal, EnumMetadata>;

export const KanalOptions = enumRecordToSelectOptions(KanalRecord);
