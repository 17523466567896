import { ContentCommunity } from "../openapi/model/content-community";
import { EnumMetadata, enumRecordToSelectOptions } from "./enum-base";

export const ContentCommunityRecord: Record<ContentCommunity, EnumMetadata> = {
  CC1: { bedeutung: "CC1" },
  CC2: { bedeutung: "CC2" },
  CC3: { bedeutung: "CC3" },
  CC4: { bedeutung: "CC4" },
  CC5: { bedeutung: "CC5" },
  CC6: { bedeutung: "CC6" },
  CCKids1: { bedeutung: "CC Kids 1" },
  CCKids2: { bedeutung: "CC Kids 2" },
  CCKids3: { bedeutung: "CC Kids 3" },
};

export const ContentCommunityOptions = enumRecordToSelectOptions(ContentCommunityRecord);
