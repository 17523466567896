<div class="slider">
  <kendo-slider
    dragHandleTitle="Zoomen"
    [max]="4"
    [min]="0"
    [showButtons]="false"
    [title]="getTitleInMinutesForTicks"
    [value]="zoomLevels[selectedZoomLevel].value"
    (valueChange)="zoomSliderChangedSubject.next($event)"
  ></kendo-slider>
  <span class="slider-text">{{ zoomLevels[selectedZoomLevel].minutesPerBlock }} Min.</span>
</div>
