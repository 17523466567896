/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Bei Änderungen unbedingt auch Zdf.Publit.Vorplanung.Domain.Comparer.SenderPlattformComparer anpassen.
 */
export type SenderPlattform =
  | "Sonstiges"
  | "ARD"
  | "RTL"
  | "Pro7"
  | "Sat1"
  | "Vox"
  | "RTL2"
  | "Kabel1"
  | "Eurosport"
  | "Netflix"
  | "AmazonPrime"
  | "RTLPlus"
  | "Joyn"
  | "Sky"
  | "MagentaTV"
  | "DAZN"
  | "DisneyPlus";

export const SenderPlattform = {
  SONSTIGES: "Sonstiges" as SenderPlattform,
  ARD: "ARD" as SenderPlattform,
  RTL: "RTL" as SenderPlattform,
  PRO7: "Pro7" as SenderPlattform,
  SAT1: "Sat1" as SenderPlattform,
  VOX: "Vox" as SenderPlattform,
  RTL2: "RTL2" as SenderPlattform,
  KABEL1: "Kabel1" as SenderPlattform,
  EUROSPORT: "Eurosport" as SenderPlattform,
  NETFLIX: "Netflix" as SenderPlattform,
  AMAZON_PRIME: "AmazonPrime" as SenderPlattform,
  RTL_PLUS: "RTLPlus" as SenderPlattform,
  JOYN: "Joyn" as SenderPlattform,
  SKY: "Sky" as SenderPlattform,
  MAGENTA_TV: "MagentaTV" as SenderPlattform,
  DAZN: "DAZN" as SenderPlattform,
  DISNEY_PLUS: "DisneyPlus" as SenderPlattform,
};
