import { Component, Input } from "@angular/core";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { GUID } from "src/app/models";

export interface PlanungsobjektGetitVerknuepfungAufhebenWindowInput {
  planungsobjektId: GUID;
}

export type PlanungsobjektGetitVerknuepfungAufhebenWindowResult = {
  planungsobjektId: GUID;
  produktEingenschaftenBeibehalten: boolean;
};

@Component({
  selector: "app-planungsobjekt-getit-verknuepfung-aufheben-window",
  templateUrl: "./planungsobjekt-getit-verknuepfung-aufheben-window.component.html",
  styleUrls: ["./planungsobjekt-getit-verknuepfung-aufheben-window.component.scss"],
})
export class PlanungsobjektGetitVerknuepfungAufhebenWindowComponent {
  @Input() public data: PlanungsobjektGetitVerknuepfungAufhebenWindowInput;

  protected produktEingenschaftenBeibehalten: boolean = false;

  constructor(private windowRef: WindowRef) {}

  toggleProduktEingenschaftenBeibehalten() {
    this.produktEingenschaftenBeibehalten = !this.produktEingenschaftenBeibehalten;
  }

  onCancel(): void {
    this.windowRef.close();
  }

  onConfirm() {
    const dialogResult: PlanungsobjektGetitVerknuepfungAufhebenWindowResult = {
      planungsobjektId: this.data.planungsobjektId,
      produktEingenschaftenBeibehalten: this.produktEingenschaftenBeibehalten,
    };
    this.windowRef.close(dialogResult);
  }
}
