import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { filter, map } from "rxjs";
import { FilterEnum, RechercheSearchQueryVM } from "src/app/core/stores/recherche/recherche.model";
import { ContentCommunityOptions } from "src/app/models/enums/content-community";
import { GenreOptions } from "src/app/models/enums/genre";
import { KanalOptions } from "src/app/models/enums/kanal";
import { PlanungskontextOptions } from "src/app/models/enums/planungskontext";
import { RedaktionShortOptions } from "src/app/models/enums/redaktion";
import { ContentCommunity } from "src/app/models/openapi/model/content-community";
import { Genre } from "src/app/models/openapi/model/genre";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { DateFnsService } from "src/app/services/date-fns.service";
import { CustomValidators } from "src/app/shared/form-inputs/custom-validators";
import { SearchFormFilterObject } from "../filter-chip-list/filter-chip-list.component";

export type SearchForm = typeof SearchFormComponent.prototype.searchForm;
@Component({
  selector: "app-search-form",
  templateUrl: "./search-form.component.html",
  styleUrls: ["./search-form.component.scss"],
})
export class SearchFormComponent implements OnChanges {
  @Input() isFirstSearch: boolean;
  @Input() isSearchFormDirty: boolean;
  @Input() query: RechercheSearchQueryVM;
  @Input() activeAdditionalFilters: FilterEnum[];
  @Output() searchRequested = new EventEmitter<void>();
  @Output() updateQuery = new EventEmitter<RechercheSearchQueryVM>();
  @Output() removeAdditionalFilter = new EventEmitter<FilterEnum>();
  @Output() updateAdditionalFilters = new EventEmitter<FilterEnum[]>();

  private updatingFromInput = false;

  activeAdditionalFiltersRecord: Record<FilterEnum, boolean>;

  FilterEnum = FilterEnum;

  RedaktionOptions = RedaktionShortOptions.filter((redaktion) => redaktion.value !== Redaktion.NN);
  KanalOptions = KanalOptions.filter(
    (kanal) =>
      kanal.value === Kanal.ZDF ||
      kanal.value === Kanal.ZDF_MEDIATHEK ||
      kanal.value == Kanal.ZDF_NEO,
  );
  GenreOptions = GenreOptions;
  PlanungskontextOptions = PlanungskontextOptions.filter(
    (planungskontext) =>
      planungskontext.value === Planungskontext.VORGEPLANT ||
      planungskontext.value === Planungskontext.VORGESCHLAGEN,
  );
  ContentCommunityOptions = ContentCommunityOptions;

  searchForm = this.initializeSearchForm();

  constructor(private formBuilder: NonNullableFormBuilder) {
    this.searchForm.valueChanges
      .pipe(
        map(() => this.searchForm.getRawValue()), // getRawValue() gibt die Werte zurück, die in den FormControls gespeichert sind
        filter(() => !this.updatingFromInput), // Wenn gerade über ngOnChanges die Werte aus dem Store in die FormControls geschrieben werden, soll nicht erneut ein Update ausgelöst werden
        filter(() => this.searchForm.valid && this.searchForm.dirty), // Wenn die Form nicht valid ist oder nicht dirty ist, soll nicht erneut ein Update ausgelöst werden
        takeUntilDestroyed(),
      )
      .subscribe((value) => {
        this.updateQuery.emit(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.query?.currentValue) {
      // markiere Zustand als "aktualisiert von außen", um zu verhindern, dass die Pipe im valueChanges-Subscriber ausgelöst wird
      this.updatingFromInput = true;
      // aktualisiere Formularwerte, wenn die Query geändert wurde
      this.searchForm.patchValue(this.query);
      this.updatingFromInput = false;
    }
    if (changes.activeAdditionalFilters?.currentValue) {
      this.activeAdditionalFiltersRecord = Object.values(FilterEnum).reduce(
        (acc, filter) => ({
          ...acc,
          [filter]: this.activeAdditionalFilters.includes(filter),
        }),
        {} as Record<FilterEnum, boolean>,
      );
    }
  }

  onClickSearch() {
    this.searchRequested.emit();
  }

  onFilterSelectionChange(selectedFilters: FilterEnum[]) {
    this.updateAdditionalFilters.emit(selectedFilters);
  }

  onRemoveFilter(value: FilterEnum) {
    this.removeAdditionalFilter.emit(value);
  }

  onRemoveQueryProperty(event: SearchFormFilterObject) {
    const { key, value } = event;
    const currentQuery: RechercheSearchQueryVM = this.searchForm.getRawValue();
    const currentValue: string[] = currentQuery[key];
    if (Array.isArray(currentValue)) {
      const newValue = currentValue.filter((item) => item !== value);
      this.updateQuery.emit({
        ...currentQuery,
        [key]: newValue,
      });
      this.searchForm.markAsDirty();
    }
  }

  private initializeSearchForm() {
    const von = new Date();
    const bis = DateFnsService.addMonths(von, 6);

    const formGroup = this.formBuilder.group({
      // TODO Validators entfernen, da nur ein Workaround, damit das Form bei unvollständigem Datum (Jahr)
      // invalid ist und damit der emit des valueChanges-Subscribers nicht ausgelöst wird
      sendetagVon: [von, [Validators.required, CustomValidators.minDate(new Date(1, 0, 1900))]],
      sendetagBis: [bis, [Validators.required, CustomValidators.minDate(new Date(1, 0, 1900))]],
      highlightsOnly: [false],
      kanaele: [[Kanal.ZDF], Validators.required],
      redaktionen: [[] as Redaktion[]],
      genres: [[] as Genre[]],
      planungskontext: [[] as Planungskontext[]],
      titel: [""],
      contentCommunities: [[] as ContentCommunity[]],
    });

    return formGroup;
  }
}

// http://localhost:5040/recherche?sendetagBis=2023-02-11&sendetagVon=2023-01-01&kanaele=ZDF&kanaele=ZDFMediathek&redaktionen=CS&highlightsOnly=true&genres=Magazin&planungskontext=Eingeplant&titel=spannend&shownColumns=redaktion&shownColumns=genre&shownColumns=planungskontext&shownColumns=onlineAb&shownColumns=onlineBis
// http://localhost:5040/recherche?sendetagBis=2023-02-11&sendetagVon=2023-01-01&kanaele=ZDF&kanaele=ZDFMediathek&redaktionen=CS&highlightsOnly=true&genres=Magazin&planungskontext=Eingeplant&titel=spannend&shownColumns=redaktion&shownColumns=genre&shownColumns=planungskontext&shownColumns=onlineAb&shownColumns=onlineBis
// http://localhost:5040/recherche?sendetagBis=2023-02-11&sendetagVon=2023-01-01&kanaele=ZDF&kanaele=ZDFMediathek&redaktionen=CS&highlightsOnly=true&genres=Magazin&planungskontext=Eingeplant&titel=spannend&shownColumns=redaktion&shownColumns=genre&shownColumns=planungskontext&shownColumns=onlineAb&shownColumns=onlineBis
