import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { KeyValue } from "@angular/common";
import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { differenceInSeconds } from "date-fns";
import { Observable, map, take } from "rxjs";
import { blockansichtActions } from "src/app/core/stores/blockansicht/blockansicht.actions";
import { TimeCellGroup } from "src/app/core/stores/blockansicht/blockansicht.model";
import blockansichtSelectors from "src/app/core/stores/blockansicht/blockansicht.selectors";
import { ekWindowActions } from "src/app/core/stores/ek-window/ek-window.actions";
import { GetitWindowInput } from "src/app/core/stores/getit-window/getit-model";
import { getitWindowActions } from "src/app/core/stores/getit-window/getit-window.actions";
import { mengengeruestWindowActions } from "src/app/core/stores/mengengeruest/mengengeruest.window.actions";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { serienWindowActions } from "src/app/core/stores/serien-window/serien.window.actions";
import { AnsichtType } from "src/app/models/enums/ansicht-type";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { DateFnsService } from "src/app/services/date-fns.service";
import { MengengeruestWindowUseCase } from "src/app/shared/windows/mengengeruest-window/mengengeruest-window.component";
import { PlanungsobjektWindowUseCase } from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";
import AnsichtenFacade from "../ansichten.facade";
import { BlockansichtDefinition, BlockansichtRowDataVM } from "./blockansicht-viewmodel";
import { BlockansichtDragDropService } from "./blockansicht.drag-drop.service";

@Injectable()
export default class BlockansichtFacade {
  private readonly store = inject(Store);
  private readonly ansichtenFacade = inject(AnsichtenFacade);
  private readonly dragDropService = inject(BlockansichtDragDropService);

  public readonly blockansichtDefinitionen$ = this.store.select(
    blockansichtSelectors.selectBlockansichtDefinitionen,
  );

  public readonly columns$ = this.store.select(blockansichtSelectors.selectBlockansichtColumns);
  public readonly rows$ = this.store.select(blockansichtSelectors.selectBlockansichtDataVM);

  public readonly numberOfPlanungsobjekteInMehrfachauswahl$ =
    this.ansichtenFacade.planungsobjekteInMehrfachauswahl$.pipe(
      map((planungsobjekte) => planungsobjekte.length),
    );

  public readonly displaysMultipleYears$ = this.ansichtenFacade.displaysMultipleYears$;

  public readonly hasPreviousAnsicht$ = this.ansichtenFacade.hasPreviousAnsicht$;

  public readonly hasNextAnsicht$ = this.ansichtenFacade.hasNextAnsicht$;

  isFirstAnsicht$(year: number): Observable<boolean> {
    return this.ansichtenFacade.ansichtYearDefinition$.pipe(
      map((ansichtYearDefinition) => year === ansichtYearDefinition.visibleYears[0]),
    );
  }

  isLastAnsicht$(year: number): Observable<boolean> {
    return this.ansichtenFacade.ansichtYearDefinition$.pipe(
      map(
        (ansichtYearDefinition) =>
          year ===
          ansichtYearDefinition.visibleYears[ansichtYearDefinition.visibleYears.length - 1],
      ),
    );
  }

  addVarianteForDate(date: string, maxVariante: number) {
    this.store.dispatch(
      blockansichtActions.addManuelleVariantenzeile({ sendetag: date, maxVariante }),
    );
  }

  loadPreviousAnsicht() {
    this.ansichtenFacade.loadPreviousAnsicht();
  }

  loadNextAnsicht() {
    this.ansichtenFacade.loadNextAnsicht();
  }

  openPlanungshinweiseWindow($event: { kanal: Kanal; start: Date; end: Date }) {
    this.ansichtenFacade.openPlanungshinweiseWindow($event);
  }

  openGetItWindow(
    datumGruppe: KeyValue<string, BlockansichtRowDataVM>,
    variantePreset: number,
    beginnzeit: string,
    kanalPreset: Kanal,
  ) {
    const windowInputs: GetitWindowInput = {
      type: "Blockansicht",
      kanal: kanalPreset,
      sendetag: datumGruppe.key,
      beginnzeit,
      laenge: 0,
      variante: variantePreset,
      isVorschlag: false,
    };

    this.store.dispatch(getitWindowActions.openGetitWindow({ windowInputs }));
  }

  openSerieWindow(evt: { context: AnsichtType; planungsobjekt: PlanungsobjektDto }) {
    this.store.dispatch(serienWindowActions.openSerienWindow(evt));
  }

  openMengengeruestForPlanungsobjektDialog(
    vm: PlanungsobjektDto,
    blockansichtDefinition: BlockansichtDefinition,
  ) {
    // Blockansichten haben nur einen Schemaplatz
    const schemaplatz = blockansichtDefinition.ansichtViewModel.ansichtsdefinition.schemaplaetze[0];

    const planungsobjekt: PlanungsobjektDto = {
      ...vm,
      createdAt: "",
      createdBy: "",
      modifiedAt: "",
      modifiedBy: "",
      vorgaenger: [],
      nachfolger: [],
      abhaengigkeiten: [],
      publikationsplanung: {
        von: vm.publikationsplanung.von,
        bis: vm.publikationsplanung.bis,
        kanal: vm.publikationsplanung.kanal,
        kalendertag: vm.publikationsplanung.kalendertag,
        sendetag: vm.publikationsplanung.sendetag,
        beginnzeit: vm.publikationsplanung.beginnzeit,
        laenge: vm.publikationsplanung.laenge,
        variante: vm.publikationsplanung.variante,
        sendeplatzAbweichendeBeginnzeit: null,
        sendeplatzAbweichendeLaenge: null,
        sendeplatzBeginnzeit: null,
        sendeplatzLaenge: null,
      },
    };
    this.store.dispatch(
      mengengeruestWindowActions.openMengengeruestZuweisungWindow({
        input: {
          type: MengengeruestWindowUseCase.ZUWEISUNG_BLOCKANSICHT,
          ansichtViewModel: blockansichtDefinition.ansichtViewModel,
          planungsobjekt,
          schemaplatz,
        },
      }),
    );
  }

  openEventKonkurrenzprogrammErstellenWindow(sendetag: string) {
    this.store.dispatch(
      ekWindowActions.openEkWindow({
        input: { type: "Create EK", defaultDate: new Date(sendetag) },
      }),
    );
  }

  /**
   * Öffnet das Planungsobjekt in der Planung mit Verlinkungstab (neues Fenster)
   * @param planungsobjekt Planungsobjekt, das geöffnet werden soll
   * @returns void
   * */
  openUpdatePlanungsobjektLinearWithPlanungWindow(
    planungsobjekt: PlanungsobjektDto,
    blockansichtDefinition: BlockansichtDefinition,
    blockansichtDefinitionen: BlockansichtDefinition[],
  ) {
    this.store.dispatch(
      planungsobjektWindowActions.openPlanungsobjektLinearWithPlanungOnBlockansichtWindow({
        linearId: planungsobjekt.id,
        blockansichtDefinition,
        blockansichtDefinitionen,
      }),
    );
  }

  openCreatePlanungsobjektLinearWithPlanungWindow(
    sendetag: string,
    beginnzeit: string,
    variante: number,
    blockansichtDefinition: BlockansichtDefinition,
    blockansichtDefinitionen: BlockansichtDefinition[],
  ) {
    this.store.dispatch(
      planungsobjektWindowActions.openPlanungsobjektWindow({
        input: {
          usecase: PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT,
          planungskontext: Planungskontext.VORGEPLANT,
          planungsobjektId: null,
          blockansichtDefinition,
          kanal: blockansichtDefinition.ansichtViewModel.kanal,
          wannPreset: {
            sendetag,
            beginnzeit,
            variante,
          },
          blockansichtDefinitionen,
        },
      }),
    );
  }

  copyPlanungsobjekt(event: { planungsobjekt: PlanungsobjektDto; copyPattern: CopyPattern }) {
    this.store.dispatch(
      planungsobjektActions.copyPlanungsobjektOnBlockansicht({
        planungsobjektId: event.planungsobjekt.id,
        copyPattern: event.copyPattern,
      }),
    );
  }

  movePlanungsobjektInAnsicht(
    event: CdkDragDrop<TimeCellGroup, TimeCellGroup, PlanungsobjektDto>,
    kanal: Kanal,
  ): void {
    this.ansichtenFacade.planungsobjekteInMehrfachauswahl$
      .pipe(take(1))
      .subscribe((planungsobjekte) => {
        if (planungsobjekte.length > 1) {
          this.moveMultiplePlanungsobjektInAnsicht(event, kanal, planungsobjekte);
        } else {
          this.moveSinglePlanungsobjektInAnsicht(event, kanal);
        }
      });
  }

  private moveMultiplePlanungsobjektInAnsicht(
    event: CdkDragDrop<TimeCellGroup, TimeCellGroup, PlanungsobjektDto>,
    kanal: Kanal,
    planungsobjekteInMehrfachauswahl: PlanungsobjektDto[],
  ) {
    // Vorhergehende Zelle und ihre Container Daten
    const {
      beginnzeit: previousBeginnzeit,
      sendetag: previousSendetag,
      variante: previousVariante,
    } = event.previousContainer.data.timeCellKey;
    const previousZulu = DateFnsService.createZuluDate(previousSendetag, previousBeginnzeit);

    // Neue Zelle und ihre Container Daten
    const {
      beginnzeit: currentBeginnzeit,
      sendetag: currentSendetag,
      variante: currentVariante,
    } = event.container.data.timeCellKey;
    let currentZulu = DateFnsService.createZuluDate(currentSendetag, currentBeginnzeit);

    // Prüfe ob die Planungsobjekt von nach Mitternacht, vor Mitternacht verschoben wurde
    // Bspw. 01:00 Uhr -> 23:00 Uhr
    const movedBeforeMidnight =
      KanalOffsetUtils.isBetweenMidnightToTagesgrenze(previousBeginnzeit, kanal) &&
      KanalOffsetUtils.isBetweenTagesgrenzeToMidnight(currentBeginnzeit, kanal);

    // Prüfe ob die Planungsobjekt von vor Mitternacht, nach Mitternacht verschoben wurde
    // Bspw. 23:00 Uhr -> 01:00 Uhr
    const movedAfterMidnight =
      KanalOffsetUtils.isBetweenTagesgrenzeToMidnight(previousBeginnzeit, kanal) &&
      KanalOffsetUtils.isBetweenMidnightToTagesgrenze(currentBeginnzeit, kanal);

    if (movedBeforeMidnight) {
      currentZulu = DateFnsService.addDays(currentZulu, -1);
    } else if (movedAfterMidnight) {
      currentZulu = DateFnsService.addDays(currentZulu, 1);
    }

    const secondsOffset = differenceInSeconds(currentZulu, previousZulu);
    // Wenn die Planungsobjekte in der gleichen Zelle verschoben werden, dann soll keine Move Operation
    // ausgeführt werden.
    if (secondsOffset === 0 && previousVariante === currentVariante) return;

    const planungsobjekteIds = planungsobjekteInMehrfachauswahl.map(
      (planungsobjekt) => planungsobjekt.id,
    );

    this.store.dispatch(
      planungsobjektActions.moveMultiplePlanungsobjektInBlockansicht({
        command: {
          planungsobjekteIds,
          secondsOffset,
        },
      }),
    );
  }

  private moveSinglePlanungsobjektInAnsicht(
    event: CdkDragDrop<TimeCellGroup, TimeCellGroup, PlanungsobjektDto>,
    kanal: Kanal,
  ) {
    this.dragDropService.dropIfPossibleCdk(event.item.data, event.container.data, kanal);
  }
}
