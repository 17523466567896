import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { takeUntil } from "rxjs";
import { Aktion, AktionResult } from "src/app/models/enums/aktion";
import { EventKategorieOptions } from "src/app/models/enums/event-kategorie";
import { KonkurrenzKategorieOptions } from "src/app/models/enums/konkurrenz-kategorie";
import { SenderPlattformOptions } from "src/app/models/enums/sender-plattform";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { EventKategorie } from "src/app/models/openapi/model/event-kategorie";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { KonkurrenzprogrammKategorie } from "src/app/models/openapi/model/konkurrenzprogramm-kategorie";
import { SenderPlattform } from "src/app/models/openapi/model/sender-plattform";
import { DateFnsService } from "src/app/services/date-fns.service";
import { CustomValidators } from "src/app/shared/form-inputs/custom-validators";

/**
 * TODO: Aufsplitten in einzelne Use Cases
 */
export type EKWindowInput =
  | {
      type: "Create EK";
      defaultDate: Date | null;
    }
  | {
      type: "Konkurrenzprogramm";
      konkurrenzEvent: KonkurrenzprogrammDto;
      asNewCopy: boolean;
    }
  | {
      type: "Event";
      konkurrenzEvent: EventDto;
      asNewCopy: boolean;
    };

export interface EKWindowResult {
  result: AktionResult;
  returnValue: {
    action: Aktion;
    value: KonkurrenzprogrammDto | EventDto;
    isEvent: boolean;
    copyPattern: CopyPattern;
    convertedGUID?: string;
  };
}

@Component({
  templateUrl: "./ek-window.component.html",
  styleUrls: ["./ek-window.component.scss"],
})
export class EKWindowComponent implements OnInit, AfterViewInit {
  @Input() data: EKWindowInput;

  KonkurrenzKategorie = KonkurrenzKategorieOptions;
  EventKategorie = EventKategorieOptions;
  SenderPlattform = SenderPlattformOptions;

  kategorieValue: KonkurrenzprogrammKategorie | EventKategorie | null;
  senderPlattformValue: SenderPlattform | null;
  formGroup: ReturnType<typeof this.initForm>;

  // Wir das Event beim Speichern in ein Konkurrenzprogramm konvertiert (oder umgekehrt)
  toConvert = false;

  constructor(
    private windowRef: WindowRef,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    const { type } = this.data;
    this.kategorieValue = type !== "Create EK" ? this.data.konkurrenzEvent.kategorie : null;

    this.senderPlattformValue =
      this.data.type === "Konkurrenzprogramm" ? this.data.konkurrenzEvent?.senderPlattform : null;

    this.formGroup = this.initForm();

    this.formGroup.controls.isEvent.valueChanges
      .pipe(takeUntil(this.windowRef.result))
      .subscribe((isEvent) => {
        if (isEvent) {
          this.removeKonkurrenzprogrammValidators(["vonZeit", "bisZeit", "senderPlattform"]);
        } else {
          this.addKonkurrenzprogrammValidators(["vonZeit", "bisZeit", "senderPlattform"]);
        }
      });
    this.formGroup.controls.isEvent.updateValueAndValidity();
  }

  ngAfterViewInit() {
    //Reset der Kategorie, wenn Event-Toggle betätigt wird. AfterViewInit, damit der Listener nicht initial triggert
    this.formGroup.controls.isEvent.valueChanges
      .pipe(takeUntil(this.windowRef.result))
      .subscribe(() => {
        this.formGroup.controls.kategorie.setValue(null);
      });
  }

  private initForm() {
    const konkurrenzEvent = this.getKonkurrenzEvent();
    return this.formBuilder.group({
      isEvent: this.data.type === "Event" ? true : false,
      id: konkurrenzEvent?.id,
      titel: [konkurrenzEvent?.titel ?? "", [Validators.required, Validators.minLength(2)]],
      vonDatum: [
        this.data.type !== "Create EK"
          ? this.data.konkurrenzEvent.vonDatum
            ? DateFnsService.parseStringToDate(this.data.konkurrenzEvent.vonDatum)
            : null
          : this.data.defaultDate,
        [Validators.required, CustomValidators.valueBefore("bisDatum", "Datum bis")],
      ],
      vonZeit: [
        konkurrenzEvent?.vonZeit
          ? DateFnsService.parseDateAndTimeToDateObject(konkurrenzEvent?.vonZeit)
          : null,
        Validators.required,
      ],
      bisDatum: [this.getBisDatum(), CustomValidators.valueAfter("vonDatum", "Datum von")],
      bisZeit: [
        konkurrenzEvent?.bisZeit
          ? DateFnsService.parseDateAndTimeToDateObject(konkurrenzEvent?.bisZeit)
          : null,
        Validators.required,
      ],
      senderPlattform: [this.senderPlattformValue, [Validators.required]],
      senderPlattformSonstiges: [
        (konkurrenzEvent as KonkurrenzprogrammDto)?.senderPlattformSonstiges || "",
      ],
      notiz: [konkurrenzEvent?.notiz ?? ""],
      pressetextLink: [(konkurrenzEvent as KonkurrenzprogrammDto)?.pressetextLink || ""],
      kategorie: [this.kategorieValue],
      isAbgesetzt: [(konkurrenzEvent as KonkurrenzprogrammDto)?.isAbgesetzt || false],
    });
  }

  private addKonkurrenzprogrammValidators(
    controlNames: ("vonZeit" | "bisZeit" | "senderPlattform")[],
  ) {
    controlNames.forEach((controlName) => {
      this.formGroup.controls[controlName].addValidators(Validators.required);
      this.formGroup.controls[controlName].updateValueAndValidity();
    });
  }

  private removeKonkurrenzprogrammValidators(
    controlNames: ("vonZeit" | "bisZeit" | "senderPlattform")[],
  ) {
    controlNames.forEach((controlName) => {
      this.formGroup.controls[controlName].removeValidators(Validators.required);
      this.formGroup.controls[controlName].updateValueAndValidity();
    });
  }

  private getBisDatum(): Date | null {
    const konkurrenzEvent = this.getKonkurrenzEvent();
    if (konkurrenzEvent?.bisDatum) {
      if (konkurrenzEvent?.vonDatum == konkurrenzEvent?.bisDatum) {
        return null;
      }
      return DateFnsService.parseStringToDate(konkurrenzEvent?.bisDatum);
    }
    return null;
  }

  onClose(): void {
    this.windowRef.close();
  }

  onChangeSwitch(): void {
    if (this.getKonkurrenzEvent()) {
      this.toConvert = !this.toConvert;
    }
  }

  onOkClick(): void {
    let action: Aktion;
    let value: KonkurrenzprogrammDto | EventDto;
    let isEvent = false;
    let convertedGUID: string;
    const vonDatum = this.formGroup.controls.vonDatum.value
      ? DateFnsService.formatDateAsString(this.formGroup.controls.vonDatum?.value)
      : null;
    const vonZeit = this.formGroup.controls.vonZeit.value
      ? DateFnsService.formatDateAsTimeString(this.formGroup.controls.vonZeit?.value)
      : null;
    const bisDatum = this.formGroup.controls.bisDatum.value
      ? DateFnsService.formatDateAsString(this.formGroup.controls.bisDatum?.value)
      : null;
    const bisZeit = this.formGroup.controls.bisZeit.value
      ? DateFnsService.formatDateAsTimeString(this.formGroup.controls.bisZeit?.value)
      : null;

    if (this.formGroup.controls.isEvent.value) {
      if (this.data.type === "Create EK" || this.data.asNewCopy) {
        action = Aktion.ERSTELLE_EVENT;
      } else {
        if (this.toConvert) {
          action = Aktion.KONVERTIERE_KONKURRENZPROGRAMM;
          convertedGUID = this.data.konkurrenzEvent.id;
        } else {
          action = Aktion.BEARBEITE_EVENT;
        }
      }
      isEvent = true;
      value = { ...(this.formGroup.value as unknown as EventDto) };
    } else {
      if (this.data.type === "Create EK" || this.data.asNewCopy) {
        action = Aktion.ERSTELLE_KONKURRENZPROGRAMM;
      } else {
        if (this.toConvert) {
          action = Aktion.KONVERTIERE_EVENT;
          convertedGUID = this.data.konkurrenzEvent.id;
        } else {
          action = Aktion.BEARBEITE_KONKURRENZPROGRAMM;
        }
      }
      // TODO: Typing korrigieren
      value = { ...(this.formGroup.value as unknown as KonkurrenzprogrammDto) };
    }

    const output: EKWindowResult = {
      result: AktionResult.SAVE,
      returnValue: {
        action: action,
        value: { ...value, vonDatum, vonZeit, bisDatum, bisZeit },
        isEvent: isEvent,
        // CopyPattern muss übergeben werden, da required wird aber an der Stelle eigentlich nicht verwendet
        copyPattern: CopyPattern.NOW,
        convertedGUID: convertedGUID,
      },
    };
    this.windowRef.close(output);
  }

  onRemoveClick(): void {
    const resultData = this.generateRemoveEKPayload();
    this.windowRef.close(resultData);
  }

  private generateRemoveEKPayload(): EKWindowResult {
    let action: Aktion;
    let value: KonkurrenzprogrammDto | EventDto;
    let isEvent = false;
    if (this.formGroup.controls.isEvent.value) {
      action = Aktion.ENTFERNE_EVENT;
      isEvent = true;
      // TODO: Typing korrigieren
      value = { ...(this.formGroup.value as unknown as EventDto) };
    } else {
      action = Aktion.ENTFERNE_KONKURRENZPROGRAMM;
      // TODO: Typing korrigieren
      value = { ...(this.formGroup.value as unknown as KonkurrenzprogrammDto) };
    }
    return {
      result: AktionResult.DELETE,
      returnValue: {
        action: action,
        isEvent: isEvent,
        value: value,
        // CopyPattern muss übergeben werden da required, wird aber an der Stelle eigentlich nicht verwendet
        copyPattern: CopyPattern.NOW,
      },
    };
  }

  isSonstiges(): boolean {
    return (
      this.formGroup.get("senderPlattform")?.value === "Sonstiges" &&
      !this.formGroup.get("isEvent")?.value
    );
  }

  onSenderPlattformChange(): void {
    if (this.formGroup.get("senderPlattform")?.value !== "Sonstiges")
      this.formGroup.get("senderPlattformSonstiges")?.setValue("");
  }

  getKonkurrenzEvent(): KonkurrenzprogrammDto | EventDto | undefined {
    return this.data.type !== "Create EK" ? this.data.konkurrenzEvent : undefined;
  }
}
