<span class="notification-header">
  <kendo-svg-icon class="category-icon" [icon]="icon" [title]="iconTitle"> </kendo-svg-icon>
  <button
    *ngIf="benachrichtigungen.length > 0"
    class="k-button-xs"
    kendoButton
    size="none"
    themeColor="primary"
    [svgIcon]="Icons.xIcon"
    (click)="removeBenachrichtigungen(benachrichtigungen)"
  >
    Alle verwerfen
  </button>
</span>

<ul>
  <li
    *ngFor="let benachrichtigung of benachrichtigungen; trackBy: trackById"
    [attr.data-testid]="'notification-' + benachrichtigung.prioritaet"
  >
    <kendo-badge-container>
      <div class="notification-body" data-testid="notification-body">
        {{ benachrichtigung.nachricht }}
      </div>
      <div class="notification-footer">
        <div class="timestamp">{{ benachrichtigung.timestamp | date: "short" }}</div>
        <button
          class="k-button-xs"
          fillMode="solid"
          kendoButton
          size="none"
          themeColor="primary"
          (click)="removeBenachrichtigungen([benachrichtigung])"
        >
          Verwerfen
        </button>
      </div>
      <kendo-badge *ngIf="!benachrichtigung.confirmed" shape="dot"></kendo-badge>
    </kendo-badge-container>
  </li>
</ul>
