import { Component, OnDestroy, OnInit, ViewChild, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { DrawerComponent, TabStripScrollableSettings } from "@progress/kendo-angular-layout";
import { SelectContainerComponent } from "ngx-drag-to-select";
import { Subject, delay, filter, takeUntil, tap } from "rxjs";
import { ansichtActions } from "src/app/core/stores/ansicht/ansicht.actions";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import benachrichtigungSelectors from "src/app/core/stores/benachrichtigung/benachrichtigung.selectors";
import { blockansichtActions } from "src/app/core/stores/blockansicht/blockansicht.actions";
import { blockansichtFeature } from "src/app/core/stores/blockansicht/blockansicht.reducer";
import merklisteSelectors from "src/app/core/stores/merkliste/merkliste.selectors";
import { sidebarActions } from "src/app/core/stores/sidebar/sidebar.actions";
import { sidebarFeature } from "src/app/core/stores/sidebar/sidebar.reducer";
import { SidebarTabEnum } from "src/app/models/enums/ansichten-sidebar";
import { Icons } from "src/app/models/icons";
import { Layout } from "src/app/models/openapi/model/layout";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { ZoomLevel } from "src/app/models/openapi/model/zoom-level";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { PlanungsobjektInteraktionService } from "src/app/services/planungsobjekt-interaktion.service";
import { ReportService } from "src/app/services/report.service";
import { SendeplatzService } from "src/app/services/sendeplatz.service";
import AnsichtenFacade from "./ansichten.facade";
import { BlockansichtDefinition } from "./blockansicht/blockansicht-viewmodel";
import { BlockansichtService } from "./blockansicht/blockansicht.service";

@Component({
  selector: "app-ansichten",
  templateUrl: "./ansichten.component.html",
  styleUrls: ["./ansichten.component.scss"],
  providers: [AnsichtenFacade],
})
export class AnsichtenComponent implements OnInit, OnDestroy {
  @ViewChild("sidebarDrawer") public sidebarDrawer: DrawerComponent;
  @ViewChild("selectContainer") public selectContainer: SelectContainerComponent;

  private readonly destroy$ = new Subject<void>();
  public readonly facade = inject(AnsichtenFacade);

  Icons = Icons;
  Layout = Layout;

  readonly merklisten$ = this.store.select(
    merklisteSelectors.selectMerklistenForAnsichtIdFromQueryParam,
  );

  readonly ansichtViewModelsForYear$ = this.store.select(
    ansichtSelectors.selectAnsichtViewModelFilteredByYearAndKanal,
  );

  readonly loading$ = this.store.select(ansichtSelectors.selectAnsichtLoadingStates);
  readonly zoomLevel$ = this.store.select(blockansichtFeature.selectZoomLevel);

  readonly sidebarExpanded$ = this.store.select(sidebarFeature.selectSidebarExpanded);
  readonly showNotizen$ = this.store.select(sidebarFeature.selectShowNotizen);
  readonly showSidebar$ = this.store.select(sidebarFeature.selectSidebarExpanded);
  readonly activeSidebarTab$ = this.store.select(sidebarFeature.selectActiveTab);

  tabScrollSettings: TabStripScrollableSettings = {
    enabled: true,
    scrollButtons: "visible",
    buttonScrollSpeed: 300,
    mouseScrollSpeed: 40,
  };

  unreadNotificationForCurrentAnsicht$ = this.store.select(
    benachrichtigungSelectors.selectBenachrichtigungenAnyNotConfirmed,
  );
  benachrichtigungenComponentData$ = this.store.select(
    benachrichtigungSelectors.selectBenachrichtigungenForPrioritaeten,
  );

  constructor(
    protected planungsobjektInteraktionService: PlanungsobjektInteraktionService,
    protected sendeplatzService: SendeplatzService,
    protected blockansichtService: BlockansichtService,
    private reportService: ReportService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(ansichtActions.loadAnsichten());

    this.sidebarExpanded$
      .pipe(
        // wait for layout to settle
        filter((expanded) => expanded && !!this.selectContainer),
        delay(1000),
        // update select container for correct select box positioning after layout settles
        tap(() => this.selectContainer.update()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setActiveSidebarTab(event: SidebarTabEnum) {
    this.store.dispatch(sidebarActions.setActiveSidebarTab({ newTab: event }));
  }

  toggleShowKonkurrenzEvents() {
    this.store.dispatch(sidebarActions.toggleKonkurrenzEvents());
  }

  toggleShowNotizen() {
    this.store.dispatch(sidebarActions.toggleNotizen());
  }

  setCurrentZoomLevel(zoomLevel: ZoomLevel): void {
    this.store.dispatch(blockansichtActions.setCurrentZoomLevel({ zoomLevel }));
  }

  triggerReportDownload(ansichtId: string): void {
    // TODO: über NgRx lösen
    this.reportService.getReportByAnsicht(ansichtId);
  }

  expandSidebar() {
    this.store.dispatch(sidebarActions.expandSidebar({}));
  }

  trackByAnsichtViewModel(idx: number, item: AnsichtViewModel) {
    return item.id;
  }

  trackByBlockansichtDefinition(idx: number, item: BlockansichtDefinition) {
    return item.ansichtViewModel.id;
  }

  onSelectionStarted() {
    this.planungsobjektInteraktionService.startSelection();
  }

  onSelectionEnded(
    selectedPlanungsobjekte: PlanungsobjektDto[],
    planungsobjekteInMehrfachauswahl: PlanungsobjektDto[],
  ) {
    this.planungsobjektInteraktionService.endSelection(
      selectedPlanungsobjekte.map((v) => v.id),
      planungsobjekteInMehrfachauswahl,
    );

    this.selectContainer.clearSelection();
  }

  onItemSelected(
    selectedPlanungsobjekt: PlanungsobjektDto,
    planungsobjekteIdsInMehrfachauswahl: PlanungsobjektDto[],
  ) {
    this.planungsobjektInteraktionService.selectItem(
      selectedPlanungsobjekt.id,
      planungsobjekteIdsInMehrfachauswahl,
    );
  }

  onItemDeselected(selectedPlanungsobjekt: PlanungsobjektDto) {
    this.planungsobjektInteraktionService.deselectItem(selectedPlanungsobjekt.id);
  }
}
