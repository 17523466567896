import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Icons } from "src/app/models/icons";
import { Layout } from "src/app/models/openapi/model/layout";
import { ZoomLevel } from "src/app/models/openapi/model/zoom-level";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

@Component({
  selector: "app-weiteres",
  templateUrl: "./weiteres.component.html",
  styleUrls: ["./weiteres.component.scss"],
})
export class WeiteresComponent {
  @Input({ required: true }) zoomLevel: ZoomLevel;
  @Input({ required: true }) selectedAnsichtViewModel: AnsichtViewModel;

  @Output() triggerReportDownload = new EventEmitter<string>();
  @Output() selectedZoomLevelChanged = new EventEmitter<ZoomLevel>();
  @Output() openMengengeruesteWindow = new EventEmitter<void>();
  @Output() openCreateEkWindow = new EventEmitter<void>();

  Icons = Icons;

  Layout = Layout;

  emitTriggerReportDownload(ansichtId: string) {
    this.triggerReportDownload.emit(ansichtId);
  }

  emitSelectedZoomLevelChanged(zoomLevel: ZoomLevel) {
    this.selectedZoomLevelChanged.emit(zoomLevel);
  }

  emitOpenMengengeruesteWindow() {
    this.openMengengeruesteWindow.emit();
  }

  emitCreateEkWindow() {
    this.openCreateEkWindow.emit();
  }
}
