<td data-testid="sendeplatz">
  @for (
    sendeplatz of sendeplatzDayViewModel.sendeplaetze;
    track sendeplatzIndex;
    let sendeplatzIndex = $index;
    let first = $first
  ) {
    <app-kalenderansicht-sendeplatz
      [ansichtViewModel]="ansichtViewModel"
      [first]="first"
      [sendeplatz]="sendeplatz"
      [sendeplatzIndex]="sendeplatzIndex"
    >
    </app-kalenderansicht-sendeplatz>
  }

  <!-- E&K -->
  @if (facade.showKonkurrenzEvents$ | async) {
    <span class="konkurrenz-area">
      <app-event-list
        [attr.data-testid]="sendeplatzDayViewModel.sendetagDatum | date: 'yyyy-MM-dd'"
        [sendeplaetze]="sendeplatzDayViewModel.sendeplaetze"
      >
      </app-event-list>
      <app-konkurrenzprogramm-list [sendeplaetze]="sendeplatzDayViewModel.sendeplaetze">
      </app-konkurrenzprogramm-list>
    </span>
  }
</td>
