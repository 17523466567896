<!-- TODO: Das in ein eigenes Template packen, falls es dieses Feature in einen Kendo release schafft:
          https://feedback.telerik.com/kendo-angular-ui/1627028-add-templates-for-custom-content-before-and-after-kendo-tabstrips-f-e-kendopretabcontent-and-kendoposttabcontent -->
<kendo-dropdownlist
  class="planungsansicht-year"
  data-testid="year-dropdown"
  fillMode="flat"
  [data]="years$ | async"
  [listHeight]="1000"
  [value]="selectedYear$ | async"
  [valuePrimitive]="true"
  (valueChange)="onYearChange($event)"
>
</kendo-dropdownlist>
