<div>
  Durch das Aufheben der Verknüpfung werden keine weiteren Aktualisierungen auf Basis der
  Produktdatenbank vorgenommen und gegebenenfalls Daten gelöscht. Soll die Verknüpfung aufgehoben
  werden?
</div>
<div class="window-action-buttons">
  <button
    class="k-button-group-stretched"
    fillMode="outline"
    kendoButton
    type="button"
    (click)="onConfirm()"
  >
    Ja
  </button>
  <button
    class="k-button-group-stretched"
    fillMode="solid"
    kendoButton
    themeColor="primary"
    type="button"
    (click)="onCancel()"
  >
    Nein
  </button>
</div>
<form autocomplete="off">
  <div class="publit-checkbox checkbox" style="justify-content: flex-start">
    <input
      #produktEigenschaftenBeibehaltenInput
      kendoCheckBox
      type="checkbox"
      [checked]="this.produktEingenschaftenBeibehalten"
      (change)="toggleProduktEingenschaftenBeibehalten()"
    />
    <kendo-label
      class="u-text-white"
      text="Aktuelle Werte bei produktspezifischen Feldern beibehalten"
      [for]="produktEigenschaftenBeibehaltenInput"
    ></kendo-label>
  </div>
</form>
