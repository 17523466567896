import { Component, ContentChild, Input } from "@angular/core";
import { AbstractControl, FormControlDirective, FormControlName } from "@angular/forms";
import { LabelDirective } from "@progress/kendo-angular-label";

/**
 * Komponente, die über ihren Content ein Formularfeld rendert.
 * Wenn im Content eine FormControlName oder FormControlDirective vorhanden ist,
 * können automatisch die Validierungsfehler ausgelesen und über die
 * {@link FormFieldErrorComponent} angezeigt werden.
 */
@Component({
  selector: "app-form-field",
  templateUrl: "./form-field.component.html",
  styleUrls: ["./form-field.component.scss"],
})
export class FormFieldComponent {
  @Input() label?: string;
  /**
   * Ob ein Ladeindikator angezeigt werden soll.
   */
  @Input() loading = false;
  /**
   * Das Feld, für das das Label ist. Sollte im Idealfall eine Referenz auf ein Element
   * sein, das im Template via #variable definiert wurde.
   */
  @Input() for: LabelDirective["for"];
  /**
   * Das Formularfeld, für das die Fehler angezeigt werden sollen.
   * Wenn nicht gesetzt, wird versucht, das Formularfeld über {@link ContentChild} zu finden.
   */
  @Input() control?: AbstractControl;

  @ContentChild(FormControlName) formControlName?: FormControlName;
  @ContentChild(FormControlDirective) formControlDirective?: FormControlDirective;

  get formControl() {
    return (
      this.control ?? this.formControlName?.control ?? this.formControlDirective?.control ?? null
    );
  }
}
