import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { AnsichtType } from "src/app/models/enums/ansicht-type";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SerienWindowResult } from "src/app/shared/windows/serien-window/serien-window.component";

export const serienWindowActions = createActionGroup({
  source: "Serien Window",
  events: {
    openSerienWindow: props<{
      context: AnsichtType;
      planungsobjekt: PlanungsobjektDto;
    }>(),
    handleSerienWindowResult: props<{
      result: SerienWindowResult | WindowCloseResult;
    }>(),
    closeWindow: emptyProps(),
  },
});
