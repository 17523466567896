import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { SendeplatzViewModel } from "src/app/models/viewmodels/sendeplatz-viewmodel";
import KalenderansichtFacade from "../kalenderansicht.facade";

@Component({
  selector: "app-kalenderansicht-sendeplatz-tag",
  templateUrl: "./kalenderansicht-sendeplatz-tag.component.html",
  styleUrl: "./kalenderansicht-sendeplatz-tag.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KalenderansichtSendeplatzTagComponent {
  public readonly facade = inject(KalenderansichtFacade);

  @Input({ required: true }) sendeplatzDayViewModel: SendeplatzViewModel;
  @Input({ required: true }) ansichtViewModel: AnsichtViewModel;
}
