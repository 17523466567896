<div
  *ngIf="validationErrorMessages$ | async as validationErrorMessages"
  aria-label="Validierungsfehler"
  role="tooltip"
  [title]="validationErrorMessages"
>
  <kendo-svg-icon
    class="error-info-icon"
    size="small"
    themeColor="error"
    [icon]="Icons.information"
  ></kendo-svg-icon>
</div>
