/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Anwendungsinterne Repräsentation der publish.it Rollen aus:  https://jira.zdf.de/confluence/pages/viewpage.action?pageId=161127185#ErweitertesRollen&RechteKonzept-ZBSFunktionen
 */
export type Role =
  | "Unbekannt"
  | "PlanungsobjektZDFLesen"
  | "PlanungsobjektZDFPlanen"
  | "PlanungsobjektBeitragen"
  | "EventKonkurrenzprogrammEditieren"
  | "PharosTransferDurchfuehren"
  | "MengengeruestEditieren"
  | "PlanungshinweisEditieren"
  | "PlanungsobjektZDFMediathekLesen"
  | "PlanungsobjektZDFMediathekPlanen"
  | "PlanungsobjektZDFNeoLesen"
  | "PlanungsobjektZDFNeoPlanen"
  | "EventKonkurrenzprogrammLesen"
  | "TechnischerAdministrator";

export const Role = {
  UNBEKANNT: "Unbekannt" as Role,
  PLANUNGSOBJEKT_ZDF_LESEN: "PlanungsobjektZDFLesen" as Role,
  PLANUNGSOBJEKT_ZDF_PLANEN: "PlanungsobjektZDFPlanen" as Role,
  PLANUNGSOBJEKT_BEITRAGEN: "PlanungsobjektBeitragen" as Role,
  EVENT_KONKURRENZPROGRAMM_EDITIEREN: "EventKonkurrenzprogrammEditieren" as Role,
  PHAROS_TRANSFER_DURCHFUEHREN: "PharosTransferDurchfuehren" as Role,
  MENGENGERUEST_EDITIEREN: "MengengeruestEditieren" as Role,
  PLANUNGSHINWEIS_EDITIEREN: "PlanungshinweisEditieren" as Role,
  PLANUNGSOBJEKT_ZDF_MEDIATHEK_LESEN: "PlanungsobjektZDFMediathekLesen" as Role,
  PLANUNGSOBJEKT_ZDF_MEDIATHEK_PLANEN: "PlanungsobjektZDFMediathekPlanen" as Role,
  PLANUNGSOBJEKT_ZDF_NEO_LESEN: "PlanungsobjektZDFNeoLesen" as Role,
  PLANUNGSOBJEKT_ZDF_NEO_PLANEN: "PlanungsobjektZDFNeoPlanen" as Role,
  EVENT_KONKURRENZPROGRAMM_LESEN: "EventKonkurrenzprogrammLesen" as Role,
  TECHNISCHER_ADMINISTRATOR: "TechnischerAdministrator" as Role,
};
