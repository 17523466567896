import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { map, switchMap } from "rxjs";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import { serienWindowActions } from "./serien.window.actions";
import { extractToSerieCommandAction } from "./serien.window.effects.utils";

@Injectable()
export class SerienWindowEffects {
  openSerienWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(serienWindowActions.openSerienWindow),
      switchMap(
        (action) =>
          this.planungsobjektService.openZuSerieUmwandelnWindow(
            action.context,
            action.planungsobjekt,
          ).result,
      ),
      map((result) =>
        serienWindowActions.handleSerienWindowResult({
          result,
        }),
      ),
    );
  });

  handleSerienWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(serienWindowActions.handleSerienWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return serienWindowActions.closeWindow();
        return extractToSerieCommandAction(result.context, result);
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private planungsobjektService: PlanungsobjektService,
  ) {}
}
