import { entriesOf } from "src/app/utils/object-utils";
import { Redaktion } from "../openapi/model/redaktion";
import {
  EnumMetadata,
  enumRecordToSelectOptions,
  enumRecordToSelectOptionsPrefereShort,
  SelectOption,
} from "./enum-base";

export const RedaktionRecord = Object.freeze<Record<Redaktion, EnumMetadata>>({
  KeineRedaktion: { bedeutung: "Keine Redaktion" },
  C: { bedeutung: "Chefredaktion", short: "C" },
  CI: { bedeutung: "HR Politik & Zeitgeschehen", short: "CI" },
  CPM: { bedeutung: "Frontal 21", short: "CPM" },
  CS: { bedeutung: "HR Sport", short: "CS" },
  CT: { bedeutung: "HR Aktuelles", short: "CT" },
  CW: { bedeutung: "HR WIRSSUM", short: "CW" },
  CZE: { bedeutung: "PB Info, Gesellschaft & Leben", short: "CZE" },
  PK: { bedeutung: "HR Geschichte und Wissenschaft", short: "PK" },
  PKJ: { bedeutung: "HR Kinder und Jugend", short: "PKJ" },
  PKM: { bedeutung: "HR Kultur", short: "PKM" },
  PS: { bedeutung: "HR Fernsehfilm/Serie I", short: "PS" },
  PSF: { bedeutung: "HR International Fiktion", short: "PSF" },
  PUS: { bedeutung: "HR Show", short: "PUS" },
  PUW: { bedeutung: "HR Fernsehfilm/Serie II", short: "PUW" },
  NN: { bedeutung: "", short: "NN" },
});

export const RedaktionOptions = enumRecordToSelectOptions(RedaktionRecord);

export const RedaktionOptionsFiltered = enumRecordToSelectOptions(RedaktionRecord).filter(
  (redaktion) => redaktion.value !== Redaktion.NN && redaktion.value !== Redaktion.KEINE_REDAKTION,
);

export const RedaktionShortOptions = enumRecordToSelectOptionsPrefereShort(RedaktionRecord);

export const redaktionListToOptions = (redaktionList: Redaktion[]): SelectOption<Redaktion>[] => {
  const filteredEntries = entriesOf(RedaktionRecord).filter(([key]) => redaktionList.includes(key));

  const filteredRecord = Object.fromEntries(filteredEntries) as Record<Redaktion, EnumMetadata>;
  return enumRecordToSelectOptions(filteredRecord);
};
