<h4>
  {{ data.prepareTransferToPharosQuery.sendeplatzKey.kalendertag | date: "EEEE, dd.MM.yyyy" }}
</h4>
<form [formGroup]="formGroup">
  <table class="pharos-table">
    <thead>
      <tr class="header">
        <th class="m-col">Attribut</th>
        <th class="l-col">Pharos aktuell</th>
        <th class="l-col">publish.it</th>
        <th class="xl-col">Pharos neu</th>
      </tr>
    </thead>
    <tbody>
      <!-- 1. Zeile: Planzeit -->
      <tr>
        <td>Planzeit*</td>
        <td>
          <input
            class="space-right"
            id="planzeit-pharos"
            kendoCheckBox
            name="planzeit"
            type="radio"
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPharos?.planzeit)"
          />
          <label
            *ngIf="data.pharosContent?.fromPharos?.planzeit"
            class="label-for label-rearange-text"
            for="planzeit-pharos"
            >{{ data.pharosContent?.fromPharos?.planzeit }}</label
          >
        </td>
        <td>
          <input
            class="space-right"
            id="planzeit-publit"
            kendoCheckBox
            name="planzeit"
            type="radio"
            [checked]="true"
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPublit?.planzeit)"
          />
          <label
            *ngIf="data.pharosContent?.fromPublit?.planzeit"
            class="label-for label-rearange-text"
            for="planzeit-publit"
            >{{ data.pharosContent?.fromPublit?.planzeit }}</label
          >
        </td>
        <td>
          <kendo-formfield showErrors="initial" showHints="initial">
            <kendo-textbox
              class="input-in-table"
              formControlName="planzeit"
              id="planzeit-pharosNeu"
              [disabled]="true"
              [readonly]="true"
            ></kendo-textbox>
          </kendo-formfield>
        </td>
      </tr>
      <!-- 2. Zeile: Länge -->
      <tr>
        <td>Länge*</td>
        <td>
          <input
            class="space-right"
            id="laenge-pharos"
            kendoCheckBox
            name="laenge"
            type="radio"
            [checked]="
              durationService.isOtherDurationStringLessOrEqualSecondsAwayFromDurationString(
                data?.pharosContent?.fromPharos?.laenge,
                data?.pharosContent?.fromPublit?.laenge,
                5 * 60
              )
            "
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPharos?.laenge)"
          />
          <label
            *ngIf="data.pharosContent?.fromPharos?.laenge"
            class="label-for label-rearange-text"
            for="laenge-pharos"
            >{{ data.pharosContent?.fromPharos?.laenge }}
          </label>
        </td>
        <td>
          <input
            class="space-right"
            id="laenge-publit"
            kendoCheckBox
            name="laenge"
            type="radio"
            [checked]="
              !durationService.isOtherDurationStringLessOrEqualSecondsAwayFromDurationString(
                data?.pharosContent?.fromPharos?.laenge,
                data?.pharosContent?.fromPublit?.laenge,
                5 * 60
              )
            "
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPublit?.laenge)"
          />
          <label
            *ngIf="data.pharosContent?.fromPublit?.laenge"
            class="label-for label-rearange-text"
            for="laenge-publit"
            >{{ data.pharosContent?.fromPublit?.laenge }}
          </label>
        </td>
        <td>
          <kendo-formfield showErrors="initial" showHints="initial">
            <kendo-textbox
              class="input-in-table"
              formControlName="laenge"
              id="laenge-pharosNeu"
              [disabled]="true"
              [readonly]="true"
            ></kendo-textbox>
          </kendo-formfield>
        </td>
      </tr>
      <!-- 3. Zeile: Sendetitel -->
      <tr>
        <td>Sendetitel*</td>
        <td>
          <input
            class="space-right"
            id="sendetitel-pharos"
            kendoCheckBox
            name="sendetitel"
            type="radio"
            [checked]="false"
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPharos?.sendetitel)"
          />
          <label
            *ngIf="data.pharosContent?.fromPharos?.sendetitel"
            class="label-for label-rearange-text"
            for="sendetitel-pharos"
            >{{ data.pharosContent?.fromPharos?.sendetitel }}</label
          >
        </td>
        <td>
          <input
            class="space-right"
            id="sendetitel-publit"
            kendoCheckBox
            name="sendetitel"
            type="radio"
            [checked]="true"
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPublit?.sendetitel)"
          />
          <label
            *ngIf="data.pharosContent?.fromPharos?.sendetitel"
            class="label-for label-rearange-text"
            for="sendetitel-publit"
            >{{ data.pharosContent?.fromPublit?.sendetitel }}</label
          >
        </td>
        <td>
          <div class="edit-layout">
            <kendo-formfield showErrors="initial" showHints="initial">
              <kendo-textbox
                class="input-in-table"
                data-testid="sendetitel-field"
                formControlName="sendetitel"
                id="sendetitel-pharosNeu"
                showErrorIcon="initial"
                [disabled]="sendetitelDisabled"
                (blur)="onSendetitelDisable(true)"
                (valueChange)="onSendetitelTextboxValueChange($event)"
              ></kendo-textbox>
              <kendo-formerror *ngIf="formGroup.controls.sendetitel.errors?.required"
                >Sendetitel darf nicht leer sein</kendo-formerror
              >
              <kendo-formerror *ngIf="formGroup.controls.sendetitel.errors?.minlength"
                >Sendetitel muss aus mindestens 2 Zeichen bestehen</kendo-formerror
              >
              <kendo-formerror *ngIf="formGroup.controls.sendetitel.errors?.maxlength"
                >Maximal 60 Zeichen</kendo-formerror
              >
              <kendo-formerror *ngIf="formGroup.controls.sendetitel.errors?.pattern"
                >Sendetitel darf nicht nur aus Leerzeichen bestehen</kendo-formerror
              >
            </kendo-formfield>
            <button
              class="space-between-buttons right"
              data-testid="edit-sendetitel-button"
              fillMode="flat"
              kendoButton
              title="Bearbeiten"
              [svgIcon]="Icons.editIcon"
              (click)="onSendetitelDisable(false)"
            ></button>
          </div>
        </td>
      </tr>
      <!-- 4. Zeile: Redaktion -->
      <tr>
        <td>Redaktion*</td>
        <td>
          <input
            class="space-right"
            id="redaktion-pharos"
            kendoCheckBox
            name="redaktion"
            type="radio"
            [checked]="false"
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPharos?.redaktion)"
          />
          <label
            *ngIf="data.pharosContent?.fromPharos?.redaktion"
            class="label-for label-rearange-text"
            for="redaktion-pharos"
            >{{ data.pharosContent?.fromPharos?.redaktion }}</label
          >
        </td>
        <td>
          <input
            class="space-right"
            id="redaktion-publit"
            kendoCheckBox
            name="redaktion"
            type="radio"
            [checked]="true"
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPublit?.redaktion)"
          />
          <label
            *ngIf="data.pharosContent?.fromPublit?.redaktion"
            class="label-for label-rearange-text"
            for="redaktion-publit"
            >{{ data.pharosContent?.fromPublit?.redaktion }}</label
          >
        </td>
        <td>
          <kendo-formfield showErrors="initial" showHints="initial">
            <kendo-textbox
              class="input-in-table"
              formControlName="redaktion"
              id="redaktion-pharosNeu"
              showErrorIcon="initial"
              [disabled]="true"
              [readonly]="true"
            ></kendo-textbox>
            <kendo-formerror>Bitte Redaktion prüfen!</kendo-formerror>
          </kendo-formfield>
        </td>
      </tr>
      <!-- 5. Zeile: FolgenNr/Info -->
      <tr>
        <td>FolgenNr/Info</td>
        <td>
          <input
            class="space-right"
            id="folgenNrInfo-pharos"
            kendoCheckBox
            name="folgenNrInfo"
            type="radio"
            [checked]="
              data.pharosContent?.fromPublit?.folgenNrInfo === '' &&
              data.pharosContent?.fromPharos?.folgenNrInfo !== ''
            "
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPharos?.folgenNrInfo)"
          />
          <label
            *ngIf="data.pharosContent?.fromPharos?.folgenNrInfo"
            class="label-for label-rearange-text"
            for="folgenNrInfo-pharos"
            >{{ data.pharosContent?.fromPharos?.folgenNrInfo }}</label
          >
        </td>
        <td>
          <input
            class="space-right"
            id="folgenNrInfo-publit"
            kendoCheckBox
            name="folgenNrInfo"
            type="radio"
            [checked]="data.pharosContent?.fromPublit?.folgenNrInfo !== ''"
            (click)="onRadioButtonClick($event, data.pharosContent?.fromPublit?.folgenNrInfo)"
          />
          <label
            *ngIf="data.pharosContent?.fromPublit?.folgenNrInfo"
            class="label-for label-rearange-text"
            for="folgenNrInfo-publit"
            >{{ data.pharosContent?.fromPublit?.folgenNrInfo }}</label
          >
        </td>
        <td>
          <div class="edit-layout">
            <kendo-formfield showErrors="initial" showHints="initial">
              <kendo-textbox
                class="input-in-table"
                data-testid="folgennummer-field"
                formControlName="folgenNrInfo"
                id="folgenNrInfo-pharosNeu"
                showErrorIcon="initial"
                [disabled]="folgenNrInfoDisabled"
                (blur)="onFolgenNrInfoDisable(true)"
                (valueChange)="onFolgenNrInfoTextboxValueChange($event)"
              ></kendo-textbox>
              <kendo-formerror>Maximal 40 Zeichen</kendo-formerror>
            </kendo-formfield>

            <button
              class="space-between-buttons right"
              data-testid="edit-folgennummer-button"
              fillMode="flat"
              kendoButton
              title="Bearbeiten"
              [svgIcon]="Icons.editIcon"
              (click)="onFolgenNrInfoDisable(false)"
            ></button>
          </div>
        </td>
      </tr>
      <!-- 6. Zeile: Dachzeile -->
      <tr>
        <td>Dachzeile</td>
        <td>
          <!-- Hier wird eine CheckBox verwendet,
            da nur ein Feld zum checken da ist -->
          <input
            class="space-right"
            data-testid="dachzeile-pharos"
            id="dachzeile-pharos"
            kendoCheckBox
            name="dachzeile"
            type="checkbox"
            [checked]="data.pharosContent?.fromPharos?.dachzeile !== ''"
            (click)="onDachzeileClick()"
          />
          <label
            *ngIf="data.pharosContent?.fromPharos?.dachzeile"
            class="label-for label-rearange-text"
            for="dachzeile-pharos"
            >{{ data.pharosContent?.fromPharos?.dachzeile }}</label
          >
        </td>
        <!-- Keine Dachzeile bei Publish.it -->
        <td></td>
        <td>
          <div class="edit-layout">
            <kendo-formfield showErrors="initial" showHints="initial">
              <kendo-textbox
                class="input-in-table"
                data-testid="dachzeile-pharosNeu"
                formControlName="dachzeile"
                id="dachzeile-pharosNeu"
                [disabled]="dachzeileDisabled"
                (blur)="onDachzeileDisable(true)"
                (valueChange)="onDachzeileTextboxValueChange()"
              ></kendo-textbox>
              <kendo-formerror>Maximal 60 Zeichen</kendo-formerror>
            </kendo-formfield>
            <button
              class="space-between-buttons right"
              fillMode="flat"
              kendoButton
              title="Bearbeiten"
              [svgIcon]="Icons.editIcon"
              (click)="onDachzeileDisable(false)"
            ></button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>

<div class="window-action-buttons">
  <button
    data-testid="export-button"
    kendoButton
    themeColor="primary"
    [disabled]="!formGroup.valid"
    (click)="onSave()"
  >
    Nach Pharos übertragen
  </button>
  <button kendoButton (click)="onClose()">Abbrechen</button>
</div>
