<div class="k-d-flex-col">
  <kendo-grid
    scrollable="none"
    [kendoGridBinding]="aktivitaeten"
    [loading]="{} | isLoading | async"
  >
    <kendo-grid-messages noRecords="Es existieren keine Aktivitäten"> </kendo-grid-messages>

    <ng-template kendoGridLoadingTemplate>
      <app-loading-dialog title=" "></app-loading-dialog>
    </ng-template>

    <kendo-grid-column field="eintraege" title="Änderungen">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="grid-table">
          <ng-container *ngFor="let eintrag of dataItem.eintraege">
            <div class="cell">{{ eintrag.eigenschaft }}:</div>
            <div class="cell">{{ eintrag.vorherigerWert }} → {{ eintrag.aktuellerWert }}</div>
          </ng-container>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="benutzer" title="Benutzer" [width]="140"> </kendo-grid-column>
    <kendo-grid-column field="createdAt" title="Zeitstempel" [width]="140">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.createdAt | date: "dd.MM.yyyy HH:mm" }}</span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <ng-container *ngIf="debugService.showDebugData">
    <pre>{{ aktivitaeten | json }}</pre>
    <hr />
  </ng-container>
</div>
