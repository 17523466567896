<ng-container *ngIf="{ onlineAb: onlineAb$ | async, onlineBis: onlineBis$ | async } as vm">
  <div class="u-grid-container">
    <app-form-field class="u-col-3" label="Online ab">
      <app-iso-dateonly data-testid="online-ab" formControlName="onlineAb"></app-iso-dateonly>
    </app-form-field>

    <app-form-field class="u-col-3" label="Online ab Zeit">
      <app-iso-timeonly
        clearable
        data-testid="online-ab-zeit"
        formControlName="onlineAbZeit"
      ></app-iso-timeonly>
    </app-form-field>

    <app-form-field class="u-col-3" label="Online bis">
      <app-iso-dateonly
        data-testid="online-bis"
        formControlName="onlineBis"
        [focusedDate]="vm.onlineBis ?? vm.onlineAb"
        [minDate]="vm.onlineAb"
      ></app-iso-dateonly>
    </app-form-field>

    <app-masked-input
      class="u-col-3"
      formControlName="planlaenge"
      label="Planlänge (min:sek)"
      [dataTestId]="'planlaenge'"
    ></app-masked-input>
  </div>
</ng-container>
