import { createFeature, createReducer, on } from "@ngrx/store";
import { Wochentag } from "src/app/models/openapi/model/wochentag";
import { FeatureKey } from "../feature.keys";
import { listenansichtActions } from "./listenansicht.actions";

export type ListenansichtColumnHeader = {
  title: string;
  schemaplatzId: string;
  /**
   * Setzt sich zusammen aus ListenansichtHeaderEnum, Wochentag und Beginnzeit
   * z.B. "Vorschläge-Montag-00:00"
   */
  columnId: string;
  columnType: ListenansichtHeaderEnum;
};

export enum ListenansichtHeaderEnum {
  DATUM = "Datum",
  EVENTS_KONKURRENZ = "Events & Konkurrenz",
  NOTIZEN = "Notizen",
  VORSCHLAEGE = "Vorschläge",
  // Muss sich im Namen von den Sendeplatz Notizen unterscheiden,
  // da einige Funktionen auf dem Namen der Spalte basieren.
  VORSCHLAEGE_NOTIZEN = "Notizen ",
  SCHEMAPLATZ = "Schemaplatz",
}

export const createColumnId = (
  headerEnum: ListenansichtHeaderEnum,
  wochentag: Wochentag,
  beginnzeit: string,
): string => [headerEnum, wochentag, beginnzeit].join("-");

export type ListenansichtState = {
  /**
   * Die aktuell sichtbaren Spalten.
   * Wird derzeit nur für Vorschlagspalte/VorschlagNotiz verwendet.
   *
   * Notizen/EK werden global über die Sidebar gesteuert.
   */
  visibleColumns: { [columnId: string]: boolean };
};

export const initialListenansichtState: ListenansichtState = {
  visibleColumns: {},
};

export const listenansichtFeature = createFeature({
  name: FeatureKey.Listenansicht,
  reducer: createReducer(
    initialListenansichtState,
    on(
      listenansichtActions.resetActiveHeaders,
      (currentState): ListenansichtState => ({
        ...currentState,
        visibleColumns: {},
      }),
    ),
    on(
      listenansichtActions.toggleColumnHeaderForColumnId,
      (currentState, { columnId }): ListenansichtState => {
        const activeHeaders = currentState.visibleColumns;
        const wasActive = !!activeHeaders[columnId];
        const [column, kalendertag, beginnzeit] = columnId.split("-");
        const newActiveHeaders = {
          ...activeHeaders,
          [columnId]: !wasActive,
        };
        const hidesVorschlaegeSpalte = column === ListenansichtHeaderEnum.VORSCHLAEGE && wasActive;
        // Wenn die Vorschläge Spalte ausgeblendet wird, muss auch die Vorschläge Notiz Spalte ausgeblendet werden
        if (hidesVorschlaegeSpalte) {
          const vorschlaegeNotizColumnId = createColumnId(
            ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN,
            kalendertag as Wochentag,
            beginnzeit,
          );
          newActiveHeaders[vorschlaegeNotizColumnId] = false;
        }
        return {
          ...currentState,
          visibleColumns: newActiveHeaders,
        };
      },
    ),
  ),
});
