/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type Layout = "Kalender" | "Liste" | "Block";

export const Layout = {
  KALENDER: "Kalender" as Layout,
  LISTE: "Liste" as Layout,
  BLOCK: "Block" as Layout,
};
