import { Wochentag } from "src/app/models/openapi/model/wochentag";

/**
 * Validierungsnachrichten für die verschiedenen Validatoren.
 */
const validationErrorMessages: Record<string, string | ((value: any) => string)> = {
  required: "Wird benötigt.",
  distinctRecordValues: "Werte müssen unterschiedlich sein.",
  minLength: (minLength: number) => `Muss aus mindestens ${minLength} Zeichen bestehen.`,
  minLengthTrimmed: (minLength: number) => `Muss aus mindestens ${minLength} Zeichen bestehen.`,
  maxLength: (maxLength: number) => `Darf maximal aus ${maxLength} Zeichen bestehen.`,
  pattern: (patternMessage: string) => patternMessage,
  maxExceeded: (maxExceededMessage: string) => maxExceededMessage,
  valueBefore: (valueBefore: { otherLabel: string }) =>
    `Darf nicht nach "${valueBefore.otherLabel}" liegen.`,
  dateBefore: (dateBefore: { otherLabel: string }) =>
    `Datum muss vor dem Datum in "${dateBefore.otherLabel}" liegen.`,
  dateAfter: (dateAfter: { otherLabel: string }) =>
    `Datum muss nach dem Datum in "${dateAfter.otherLabel}" liegen.`,
  valueAfter: (valueAfter: { otherLabel: string }) =>
    `Darf nicht vor "${valueAfter.otherLabel}" liegen`,
  valueBeforeWithReferenceKey: (valueBefore: { otherLabel: string }) =>
    `Darf nicht nach "${valueBefore.otherLabel}" liegen.`,
  /**
   * Validator von Kendo Date/Timepicker, wenn das Datum nicht vollständig ist.
   */
  incompleteDate: "Unvollständiges Datum.",
  minDate: (minDate: Date) => `Muss nach dem ${minDate.toLocaleDateString()} liegen.`,
  wochentage: (wochentag: Wochentag[]) => `Muss ein ${wochentag[0]} oder ${wochentag[1]} sein.`,
  minError: (min: number) => `Muss mindestens ${min} sein.`,
  minlength: (minLength: number) => `Muss mindestens ${minLength} Zeichen lang sein.`,
};

/**
 * Erzeugt für einen gegebenen Key und Wert die passende Validierungsnachricht.
 */
export const getValidationErrorMessage = (key: string, value: any): string => {
  const message = validationErrorMessages[key];
  if (!message) {
    throw new Error(`Für Validierungsfehler "${key}" ist keine Nachricht definiert.`);
  }
  return typeof message === "function" ? message(value) : message;
};
