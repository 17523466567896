import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlanungsobjektBeziehungApiService } from "../api/planungsobjektbeziehung/planungsobjekt-beziehung.api.service";
import { BeziehungDto } from "../models/openapi/model/beziehung-dto";
import { CreateBeziehungAbhaengigkeitCommand } from "../models/openapi/model/create-beziehung-abhaengigkeit-command";
import { CreateBeziehungReihenfolgeCommand } from "../models/openapi/model/create-beziehung-reihenfolge-command";
import { UnitResultDto } from "../models/openapi/model/unit-result-dto";

@Injectable({
  providedIn: "root",
})
export class PlanungsobjektBeziehungService {
  constructor(private planungsobjektBeziehungApiService: PlanungsobjektBeziehungApiService) {}

  getBeziehungenForPlanungsobjekt$(planungsobjektId: string): Observable<BeziehungDto[]> {
    return this.planungsobjektBeziehungApiService.getBeziehungenForPlanungsobjekt$(
      planungsobjektId,
    );
  }

  createReihenfolgeForMarkedPlanungsobjekte$(body: CreateBeziehungReihenfolgeCommand) {
    return this.planungsobjektBeziehungApiService.createReihenfolgeForMarkedPlanungsobjekte$(body);
  }

  createAbhaengigkeitForMarkedPlanungsobjekte$(body: CreateBeziehungAbhaengigkeitCommand) {
    return this.planungsobjektBeziehungApiService.createAbhaengigkeitForMarkedPlanungsobjekte$(
      body,
    );
  }

  deletePlanungsobjektBeziehung$(planungsobjektBeziehungId: string): Observable<UnitResultDto> {
    return this.planungsobjektBeziehungApiService.deletePlanungsobjektBeziehung$(
      planungsobjektBeziehungId,
    );
  }
}
