import { KeyValue } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from "@angular/core";
import { Observable, filter, map } from "rxjs";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { SendeplatzViewModel } from "src/app/models/viewmodels/sendeplatz-viewmodel";
import KalenderansichtFacade from "../kalenderansicht.facade";

@Component({
  selector: "app-kalenderansicht-sendeplatz-jahr",
  templateUrl: "./kalenderansicht-sendeplatz-jahr.component.html",
  styleUrl: "./kalenderansicht-sendeplatz-jahr.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KalenderansichtSendeplatzJahrComponent implements OnChanges {
  public readonly facade = inject(KalenderansichtFacade);

  @Input({ required: true }) sendeplaetzeYear: KeyValue<string, SendeplatzViewModel[][][]>;
  @Input({ required: true }) firstYear: boolean;

  ansichtViewModelForYear$: Observable<AnsichtViewModel>;

  ngOnChanges(): void {
    this.ansichtViewModelForYear$ = this.facade.multiAnsichtViewModel$.pipe(
      map((a) => {
        const year = parseInt(this.sendeplaetzeYear.key);
        return a.ansichtViewModels.find((x) => x.ansichtViewModel.year === year)?.ansichtViewModel;
      }),
      filter((a): a is AnsichtViewModel => !!a),
    );
  }
}
