<ng-container
  *ngFor="let item of benachrichtigungenComponentData; let last = last; trackBy: trackByPrioritaet"
>
  <app-benachrichtigung-kategorie
    [benachrichtigungen]="item.benachrichtigungen"
    [icon]="item.icon"
    [iconTitle]="item.iconTitle"
    (removeBenachrichtigungenEvent)="onRemoveBenachrichtigungen($event)"
  >
  </app-benachrichtigung-kategorie>
  <hr *ngIf="!last" />
</ng-container>
