import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

export const ansichtActions = createActionGroup({
  source: "Ansichten API",
  events: {
    loadAnsichten: emptyProps(),
    loadAnsichtenSuccess: props<{
      ansichten: AnsichtViewModel[];
    }>(),
    loadAnsichtenFailure: emptyProps(),
    // TODO: Wurde an dieser Stelle gemacht um eine gemeinsame Action für E&K aufzurufen. Sollte aber in
    // Zukunft vielleicht in eigene ActionGroup überführt werden?
    loadEventsKonkurrenzprogrammeForYear: props<{
      year: number;
    }>(),
    // Wird verwendet um alle Entities Planungsobjekte, Events, Konkurrenzprogramme, Sendeplätze (bei Listen und -
    // Kalenderansicht), Planungshinweise (bei Blockansichten) zu aktualisieren. Das ist notwendig, da Änderungen durch andere
    // Nutzer derzeit nicht auf die einzelnen Entities zurückgeführt werden können.
    updateAllEntitiesInAnsicht: emptyProps(),

    // Wird im YearDropdown Verwendet, um eine Ansicht zu einem bestimmten Jahr zu springen.
    navigateCurrentAnsichtToYear: props<{
      year: number;
    }>(),
  },
});
