import { createSelector } from "@ngrx/store";
import { metadatenFeature } from "./metadaten.reducer";

const selectVersionInformationFormatted = createSelector(
  metadatenFeature.selectVersion,
  metadatenFeature.selectCommitsAhead,
  (version, commitsAhead) => version + (commitsAhead > 0 ? `+ ${commitsAhead}` : ""),
);

export default {
  selectVersionInformationFormatted,
};
