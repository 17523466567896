import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { SenderPlattform } from "src/app/models/openapi/model/sender-plattform";
import { KonkurrenzprogrammViewModel } from "src/app/models/viewmodels/konkurrenzprogramm-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import { ansichtActions } from "../ansicht/ansicht.actions";
import { eventActions } from "../event/event.actions";
import { FeatureKey } from "../feature.keys";
import { konkurrenzprogrammActions } from "./konkurrenzprogramm.actions";

export interface KonkurrenzprogrammState extends EntityState<KonkurrenzprogrammDto> {
  loading: boolean;
}

export const senderPlattformOrder = [
  SenderPlattform.ARD,
  SenderPlattform.EUROSPORT,
  SenderPlattform.KABEL1,
  SenderPlattform.PRO7,
  SenderPlattform.RTL,
  SenderPlattform.RTL2,
  SenderPlattform.SAT1,
  SenderPlattform.VOX,
  SenderPlattform.AMAZON_PRIME,
  SenderPlattform.DAZN,
  SenderPlattform.DISNEY_PLUS,
  SenderPlattform.JOYN,
  SenderPlattform.MAGENTA_TV,
  SenderPlattform.NETFLIX,
  SenderPlattform.RTL_PLUS,
  SenderPlattform.SKY,
  SenderPlattform.SONSTIGES,
];

export const konkurrenzprogrammViewModelComparer = (
  a1: KonkurrenzprogrammViewModel,
  a2: KonkurrenzprogrammViewModel,
): number => {
  const senderPlattformPosition1 = senderPlattformOrder.indexOf(a1.senderPlattform);
  const senderPlattformPosition2 = senderPlattformOrder.indexOf(a2.senderPlattform);
  const senderPlattformCompare = senderPlattformPosition1 - senderPlattformPosition2;
  if (senderPlattformCompare !== 0) return senderPlattformCompare;

  const secondsA1 = DateFnsService.parseDateAndTimeToDateObject(a1.vonZeit, a1.vonDatum).getTime();
  const secondsA2 = DateFnsService.parseDateAndTimeToDateObject(a2.vonZeit, a2.vonDatum).getTime();

  return secondsA1 - secondsA2;
};

export const konkurrenzprogrammAdapter: EntityAdapter<KonkurrenzprogrammDto> =
  createEntityAdapter<KonkurrenzprogrammDto>();

export const initialState: KonkurrenzprogrammState = konkurrenzprogrammAdapter.getInitialState({
  loading: false,
});

export const konkurrenzprogrammFeature = createFeature({
  name: FeatureKey.Konkurrenzprogramm,
  reducer: createReducer(
    initialState,
    on(
      konkurrenzprogrammActions.loadKonkurrenzprogrammeByAnsichtenSuccess,
      (currentState, { konkurrenzprogramme }) =>
        konkurrenzprogrammAdapter.upsertMany(konkurrenzprogramme, currentState),
    ),
    on(
      konkurrenzprogrammActions.loadKonkurrenzprogrammeByYearSuccess,
      (currentState, { konkurrenzprogramme }) =>
        konkurrenzprogrammAdapter.upsertMany(konkurrenzprogramme, {
          ...currentState,
          loading: false,
        }),
    ),
    on(
      ansichtActions.loadEventsKonkurrenzprogrammeForYear,
      ansichtActions.updateAllEntitiesInAnsicht,
      (currentState): KonkurrenzprogrammState => ({ ...currentState, loading: true }),
    ),
    on(
      konkurrenzprogrammActions.createKonkurrenzprogrammSuccess,
      konkurrenzprogrammActions.copyKonkurrenzprogrammSuccess,
      konkurrenzprogrammActions.convertEventToKonkurrenzprogrammSuccess,
      (currentState, { konkurrenzprogramm }) =>
        konkurrenzprogrammAdapter.addOne(konkurrenzprogramm, currentState),
    ),
    on(
      konkurrenzprogrammActions.updateKonkurrenzprogrammSuccess,
      (currentState, { konkurrenzprogramm }) =>
        konkurrenzprogrammAdapter.updateOne(
          { id: konkurrenzprogramm.id, changes: konkurrenzprogramm },
          currentState,
        ),
    ),
    on(
      konkurrenzprogrammActions.deleteKonkurrenzprogrammSuccess,
      (currentState, { konkurrenzprogrammId }) =>
        konkurrenzprogrammAdapter.removeOne(konkurrenzprogrammId, currentState),
    ),
    on(eventActions.convertKonkurrenzprogrammToEventSuccess, (currentState, { convertedGUID }) =>
      konkurrenzprogrammAdapter.removeOne(convertedGUID, currentState),
    ),
    on(
      konkurrenzprogrammActions.setAllKonkurrenzprogramme,
      (currentState, { konkurrenzprogramme }) =>
        konkurrenzprogrammAdapter.setAll(konkurrenzprogramme, { ...currentState, loading: false }),
    ),
  ),
  extraSelectors: ({ selectKonkurrenzprogrammState }) => ({
    ...konkurrenzprogrammAdapter.getSelectors(selectKonkurrenzprogrammState),
  }),
});
