import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { PlanungshinweisDto } from "src/app/models/openapi/model/planungshinweis-dto";
import { FeatureKey } from "../feature.keys";
import { planungshinweisActions } from "./planungshinweis.actions";

export type PlanungshinweisState = EntityState<PlanungshinweisDto>;

export const planungshinweisAdapter: EntityAdapter<PlanungshinweisDto> =
  createEntityAdapter<PlanungshinweisDto>();

export const initialState: PlanungshinweisState = planungshinweisAdapter.getInitialState({});

export const planungshinweisFeature = createFeature({
  name: FeatureKey.Planungshinweis,
  reducer: createReducer(
    initialState,
    on(
      planungshinweisActions.loadPlanungshinweiseByAnsichtSuccess,
      (currentState, { planungshinweise }) =>
        planungshinweisAdapter.upsertMany(planungshinweise, currentState),
    ),
    // Wird die Ansicht komplett neu geladen, müssen wir alle Planungshinweise neu setzen,
    // da ein anderer Benutzer eventuell einen Planungshinweis gelöscht hat, was wir mit einem
    // UpsertMany nicht mitbekommen würden
    on(
      planungshinweisActions.loadPlanungshinweiseByAnsichtenSuccess,
      (currentState, { planungshinweise }) =>
        planungshinweisAdapter.setAll(planungshinweise, currentState),
    ),
    on(planungshinweisActions.createPlanungshinweisSuccess, (currentState, { planungshinweis }) =>
      planungshinweisAdapter.upsertOne(planungshinweis, currentState),
    ),
    on(planungshinweisActions.updatePlanungshinweisSuccess, (currentState, { planungshinweis }) =>
      planungshinweisAdapter.upsertOne(planungshinweis, currentState),
    ),
    on(planungshinweisActions.deletePlanungshinweisSuccess, (currentState, { planungshinweis }) =>
      planungshinweisAdapter.removeOne(planungshinweis.id, currentState),
    ),
  ),
  extraSelectors: ({ selectPlanungshinweisState }) => ({
    ...planungshinweisAdapter.getSelectors(selectPlanungshinweisState),
  }),
});
