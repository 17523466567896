import { Component, Input, OnChanges } from "@angular/core";
import { minutesToSeconds } from "date-fns";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { roundSecondsToNextMinute } from "src/app/utils/time-utils";

/**
 * Ab wie viel Minuten Unterschied (inklusive) soll der Badge angezeigt werden.
 */
const SHOW_BADGE_OFFSET_MINUTES = 5;

@Component({
  selector: "app-abweichende-laenge-badge, [app-abweichende-laenge-badge]",
  templateUrl: "./abweichende-laenge-badge.component.html",
  styleUrls: ["./abweichende-laenge-badge.component.scss"],
})
export class AbweichendeLaengeBadgeComponent implements OnChanges {
  @Input() sendeplatz: SendeplatzDto;
  @Input() planungsobjekt: PlanungsobjektDto;

  isVisible = false;

  ngOnChanges(): void {
    this.isVisible = AbweichendeLaengeBadgeComponent.showBadge(
      this.sendeplatz,
      this.planungsobjekt,
      SHOW_BADGE_OFFSET_MINUTES,
    );
  }

  private static showBadge(
    sendeplatz: SendeplatzDto,
    planungsobjekt: PlanungsobjektDto,
    showBadgeOffsetInMinutes: number,
  ): boolean {
    // Nur wenn eine individuelle Länge gesetzt ist, kann es eine Abweichung geben
    if (!planungsobjekt.planlaenge) {
      return false;
    }

    return (
      AbweichendeLaengeBadgeComponent.absoluteOffsetInSeconds(
        sendeplatz,
        planungsobjekt.planlaenge,
      ) >= minutesToSeconds(showBadgeOffsetInMinutes)
    );
  }

  /**
   * Achtung, für die Länge der Planungsobjekt nicht planungsobjekt.publikationsplanung.laenge verwenden.
   * Diese ist bei Planungsobjekte auf Listen/Kalenderansichten auf die (abweichende) Länge des Sendeplatzes gesetzt,
   * wenn keine individuelle Länge an der Planungsobjekt gesetzt ist.
   */
  private static absoluteOffsetInSeconds(
    sendeplatz: SendeplatzDto,
    planungsobjektLaenge: number,
  ): number {
    // Falls eine abweichende Sendeplatzlänge gesetzt ist, diese verwenden, sonst die Standardlänge des Sendeplatzes
    const sendeplatzLaenge = sendeplatz.abweichendeLaenge ?? sendeplatz.laenge;
    const offsetInSeconds = sendeplatzLaenge - planungsobjektLaenge;
    return roundSecondsToNextMinute(Math.abs(offsetInSeconds));
  }
}
