import { AnsichtType } from "src/app/models/enums/ansicht-type";
import { SerienWindowResult } from "src/app/shared/windows/serien-window/serien-window.component";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";

export function extractToSerieCommandAction(
  context: AnsichtType,
  serienWindowResult: SerienWindowResult,
) {
  switch (context) {
    case AnsichtType.Block:
      return planungsobjektActions.planungsobjektZuSerieUmwandelnBlock({
        planungsobjekt: serienWindowResult.params.planungsobjekt,
        command: {
          planungsobjektId: serienWindowResult.params.planungsobjekt.id,
          folgenNummerAb: serienWindowResult.params.folgennummerAb,
          folgenNummerBis: serienWindowResult.params.folgennummerBis,
          staffelNummer: serienWindowResult.params.staffelnummer,
          anzahlFolgen: serienWindowResult.params.anzahlFolgen,
          reihenfolgeVerlinken: serienWindowResult.params.reihenfolgeVerlinken,
          auszulassendeDaten: serienWindowResult.params.auszulassendeDaten,
        },
      });

    case AnsichtType.Liste:
      return planungsobjektActions.planungsobjektZuSerieUmwandelnSendeplatz({
        planungsobjekt: serienWindowResult.params.planungsobjekt,
        command: {
          planungsobjektId: serienWindowResult.params.planungsobjekt.id,
          folgenNummerAb: serienWindowResult.params.folgennummerAb,
          folgenNummerBis: serienWindowResult.params.folgennummerBis,
          staffelNummer: serienWindowResult.params.staffelnummer,
          anzahlFolgen: serienWindowResult.params.anzahlFolgen,
          reihenfolgeVerlinken: serienWindowResult.params.reihenfolgeVerlinken,
          auszulassendeDaten: serienWindowResult.params.auszulassendeDaten,
        },
      });
  }
}
