import { Ability, AbilityClass, PureAbility } from "@casl/ability";
import { Role } from "../models/openapi/model/role";
import { ActionWithSubject } from "./casl-utils";

type PlanenAbility = ActionWithSubject<
  "planen",
  "Ansicht" | "Sendeplatz" | "Schemaplatz" | "OnDemand"
>;

type BeitragenAbility = ActionWithSubject<"beitragen", "Merkliste" | "PlanungsobjektOnDemand">;

type EditierenAbility = ActionWithSubject<"editieren", "EK" | "Mengengeruest" | "Planungshinweis">;

type BearbeitenAbility = ActionWithSubject<"bearbeiten", "Planungsobjekt">;

type UmbenennenAbility = ActionWithSubject<"umbenennen", "Merkliste">;

type PharosTransferAbility = ActionWithSubject<"transferNachPharos", "Sendeplatz">;

type PermissionAbility = ActionWithSubject<Role, "Permission">;

// https://casl.js.org/v6/en/package/casl-angular#type-script-support
// https://casl.js.org/v6/en/advanced/typescript#application-ability
export type AppAbility = PureAbility<
  // ForcedSubject: https://stackoverflow.com/a/75188325/2664568
  // damit z.B. AppAbility.can("bearbeiten", asSubject("Planungsobjekt", windowInput.planungsobjekt)) funktioniert
  | PermissionAbility
  | PlanenAbility
  | BeitragenAbility
  | EditierenAbility
  | BearbeitenAbility
  | UmbenennenAbility
  | PharosTransferAbility
>;
// Eigentlich PureAbility, aber dann kommt es zu einer Cyclic Dependency
export const AppAbility = Ability as AbilityClass<AppAbility>;
