import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { ContextMenuEvent, ContextMenuPopupEvent } from "@progress/kendo-angular-menu";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { AktionEnum } from "src/app/models/enums/aktion";
import { Icons } from "src/app/models/icons";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { PublitFrontendSettings } from "src/environments/environment";

@Component({
  selector: "app-planungsobjekt-vorschlag-list",
  templateUrl: "./planungsobjekt-vorgeschlagen-list.component.html",
  styleUrls: ["./planungsobjekt-vorgeschlagen-list.component.scss"],
})
export class PlanungsobjektVorgeschlagenListComponent {
  @Input() planungsobjekteInVorschlagspalte: PlanungsobjektDto[];
  @Input() sendeplatz: SendeplatzDto;

  protected AktionEnum = AktionEnum;
  protected Icons = Icons;

  constructor(
    private store: Store,
    protected settings: PublitFrontendSettings,
  ) {}

  onVorschlagContextMenuOpen(event: ContextMenuPopupEvent) {
    if (event.originalEvent instanceof PointerEvent) {
      event.originalEvent.stopPropagation();
    }
  }

  onPlanungsobjektContextMenuSelect(event: ContextMenuEvent) {
    const {
      data: { action: actionEnum },
    } = event.item as {
      data: {
        action: AktionEnum;
      };
    };

    const { data: planungsobjekt } = event.target as {
      data: PlanungsobjektDto;
    };

    switch (actionEnum) {
      case AktionEnum.BEARBEITE_PLANUNGSOBJEKT:
        this.openPlanungsobjektLinearWithPlanungWindow(planungsobjekt);
        break;
      case AktionEnum.KOPIE_HIER:
        this.store.dispatch(
          planungsobjektActions.copyPlanungsobjekt({
            planungsobjekt,
            copyPattern: CopyPattern.NOW,
          }),
        );
        break;
    }
  }

  /**
   * Öffnet das Planungsobjekt in der Planung mit Verlinkungstab (neues Fenster)
   * @param planungsobjekt Planungsobjekt, das geöffnet werden soll
   * @returns void
   * */
  openPlanungsobjektLinearWithPlanungWindow(planungsobjekt: PlanungsobjektDto) {
    this.store.dispatch(
      planungsobjektWindowActions.openPlanungsobjektLinearWithPlanungOnSendeplatzWindow({
        linearId: planungsobjekt.id,
      }),
    );
  }
}
