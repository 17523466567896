/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type Wochentag =
  | "Montag"
  | "Dienstag"
  | "Mittwoch"
  | "Donnerstag"
  | "Freitag"
  | "Samstag"
  | "Sonntag";

export const Wochentag = {
  MONTAG: "Montag" as Wochentag,
  DIENSTAG: "Dienstag" as Wochentag,
  MITTWOCH: "Mittwoch" as Wochentag,
  DONNERSTAG: "Donnerstag" as Wochentag,
  FREITAG: "Freitag" as Wochentag,
  SAMSTAG: "Samstag" as Wochentag,
  SONNTAG: "Sonntag" as Wochentag,
};
