import { SVGIcon } from "@progress/kendo-svg-icons";
import { Icons } from "src/app/models/icons";
import { BenachrichtigungPrioritaet } from "src/app/models/openapi/model/benachrichtigung-prioritaet";
import { BenachrichtigungVm } from "src/app/models/viewmodels/benachrichtigung-viewmodel";

export type BenachrichtigungComponentData = {
  prioritaet: BenachrichtigungPrioritaet;
  icon: SVGIcon;
  iconTitle: string;
};

export type BenachrichtigungComponentVm = BenachrichtigungComponentData & {
  benachrichtigungen: BenachrichtigungVm[];
};

export const benachrichtigungKategorien: BenachrichtigungComponentData[] = [
  {
    prioritaet: BenachrichtigungPrioritaet.WICHTIG,
    icon: Icons.warning,
    iconTitle: "Wichtige Benachrichtigungen",
  },
  {
    prioritaet: BenachrichtigungPrioritaet.WENIGER_WICHTIG,
    icon: Icons.information,
    iconTitle: "Weniger wichtige Benachrichtigungen",
  },
];
