import { createSelector } from "@ngrx/store";
import { ListenansichtMapper } from "src/app/models/mapper/listenansicht.mapper";
import ansichtSelectors from "../ansicht/ansicht.selectors";
import { sidebarFeature } from "../sidebar/sidebar.reducer";
import { listenansichtFeature, ListenansichtHeaderEnum } from "./listenansicht.reducer";

const selectListenansichtHeader = createSelector(
  ansichtSelectors.selectAnsichtViewModelForAnsichtIdFromQueryParam,
  (ansichtViewModel) => ListenansichtMapper.generateListenansichtHeader(ansichtViewModel),
);

const selectVisibleListenansichtHeader = createSelector(
  selectListenansichtHeader,
  listenansichtFeature.selectVisibleColumns,
  sidebarFeature.selectShowKonkurrenzEvents,
  sidebarFeature.selectShowNotizen,
  (headers, activeHeaders, showKonkurrenzEvents, showNotizen) => {
    return headers.filter((header) => {
      // EK Spalte nur anzeigen, wenn Konkurrenz-Events aktiviert sind
      if (header.title === ListenansichtHeaderEnum.EVENTS_KONKURRENZ) {
        return showKonkurrenzEvents;
      }

      // Notizen Spalte nur anzeigen, wenn Notizen aktiviert sind
      if (header.title === ListenansichtHeaderEnum.NOTIZEN) {
        return showNotizen;
      }

      // Vorschläge + Vorschläge Notizen Spalte nur anzeigen, wenn die Spalte aktiviert ist
      const isVorschlaegeSpalteOrVorschlaegeNotizSpalte =
        header.title === ListenansichtHeaderEnum.VORSCHLAEGE ||
        header.title === ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN;
      if (isVorschlaegeSpalteOrVorschlaegeNotizSpalte) return !!activeHeaders[header.columnId];

      // Alle anderen Spalten anzeigen
      return true;
    });
  },
);

export default {
  selectVisibleListenansichtHeader,
};
