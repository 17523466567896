<ng-container
  *ngIf="{
    beitragen:
      (!hasPlanungsobjektOnDemandId && ('PlanungsobjektBeitragen' | able: 'Permission')) ||
      ('beitragen'
        | able: (linearOnDemandBeziehungOnDemandObjekt | asSubject: 'PlanungsobjektOnDemand'))
  } as can"
>
  <div class="flex-container">
    <span class="k-font-bold" data-testid="verlinkungen-titel">
      {{
        !!reihenfolgen.length || !!abhaengigkeiten.length || !!onDemandBeziehungen.length
          ? "Reihenfolgen und Abhängigkeiten"
          : "Es existieren noch keine Verlinkungen"
      }}
    </span>
    <kendo-button
      data-testid="toggle-onDemand-beziehung-form-button"
      fillMode="flat"
      [disabled]="
        readOnly ||
        !can.beitragen ||
        hasPlanungsobjektOnDemandId ||
        usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
      "
      [svgIcon]="showOnDemandBeziehungForm ? Icons.xIcon : Icons.addIcon"
      [title]="hasPlanungsobjektOnDemandId ? 'Ist bereits verlinkt' : 'Verlinkte Planung anlegen'"
      (click)="toggleOnDemandBeziehungForm.emit()"
    ></kendo-button>
  </div>

  <div
    *ngIf="!!reihenfolgen.length || !!abhaengigkeiten.length || !!onDemandBeziehungen.length"
    class="k-mb-3"
    data-testid="verlinkungen"
  >
    <ng-container *ngFor="let reihenfolge of reihenfolgen; trackBy: trackByBeziehungsId">
      <span class="beziehung-row">
        <span class="beziehung-text">Reihenfolge {{ reihenfolge.titel }}</span>
        <button
          class="beziehung-delete-button"
          fillMode="flat"
          kendoButton
          size="small"
          title="Verlinkung löschen"
          type="button"
          [disabled]="readOnly"
          [svgIcon]="Icons.xIcon"
          (click)="deleteBeziehung.emit(reihenfolge)"
        ></button>
      </span>
    </ng-container>

    <ng-container *ngFor="let abhaengigkeit of abhaengigkeiten; trackBy: trackByBeziehungsId">
      <span class="beziehung-row">
        <span class="beziehung-text"> Abhängigkeit: {{ abhaengigkeit.titel }}</span>
        <button
          class="beziehung-delete-button"
          fillMode="flat"
          kendoButton
          size="small"
          title="Verlinkung löschen"
          type="button"
          [disabled]="readOnly"
          [svgIcon]="Icons.xIcon"
          (click)="deleteBeziehung.emit(abhaengigkeit)"
        ></button>
      </span>
    </ng-container>
    <!-- Aktuell zeigen wir Reihenfolge / Abhängigkeiten-Beziehungen zwischen Linear und OnDemand-Planungsobjekte nicht an, sondern nur die Hauptbeziehung -->
    <ng-container *ngFor="let linearOnDemand of onDemandBeziehungen; trackBy: trackByBeziehungsId">
      <span class="beziehung-row">
        <span class="beziehung-text">
          <kendo-svg-icon
            [icon]="
              PlanungsobjektWindowUseCase.EDIT_ONDEMAND === usecase ? Icons.linear : Icons.onDemand
            "
            [title]="
              PlanungsobjektWindowUseCase.EDIT_ONDEMAND === usecase
                ? 'Verlinkung zu einer linearen Publikation'
                : 'Verlinkung zu einer OnDemand-Publikation'
            "
          ></kendo-svg-icon>
          <span class="beziehung-text-with-icon" data-testid="onDemand-beziehung-text">
            {{ linearOnDemand.titel }}</span
          >
        </span>
        <span>
          <button
            data-testid="edit-onDemand-beziehung-button"
            fillMode="flat"
            kendoButton
            size="small"
            title="Verlinkung bearbeiten"
            type="button"
            [disabled]="readOnly || !can.beitragen"
            [svgIcon]="Icons.editIcon"
            (click)="updateOnDemandBeziehung.emit()"
          ></button>
          <button
            data-testid="delete-onDemand-beziehung-button"
            fillMode="flat"
            kendoButton
            size="small"
            title="Verlinkung löschen"
            type="button"
            [disabled]="readOnly || !can.beitragen"
            [svgIcon]="Icons.xIcon"
            (click)="deleteBeziehung.emit(linearOnDemand)"
          ></button>
        </span>
      </span>
    </ng-container>
  </div>
</ng-container>
