import { Component, inject, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { Store } from "@ngrx/store";
import planungsobjektWindowWannWoSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window-wann-wo.selectors";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektWindowFacade } from "../../../planungsobjekt-window.facade";
import { PlanungsobjektWindowUseCase } from "../../../planungsobjekt-window.model";

@Component({
  selector: "app-wann-bezug-linear",
  templateUrl: "./wann-bezug-linear.component.html",
  styleUrls: ["./wann-bezug-linear.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class WannBezugLinearComponent {
  @Input({ required: true }) usecase: PlanungsobjektWindowUseCase;
  @Input({ required: true }) planungskontext: Planungskontext | undefined;

  readonly PlanungsobjektWindowUseCase = PlanungsobjektWindowUseCase;
  readonly Planungskontext = Planungskontext;

  private readonly store = inject(Store);
  protected readonly facade = inject(PlanungsobjektWindowFacade);

  readonly variants$ = this.store.select(planungsobjektWindowWannWoSelectors.selectVariants);

  readonly loadingVariants$ = this.store.select(planungsobjektWindowFeature.selectLoadingVariants);

  readonly isSendetagDateDisabled$ = this.store.select(
    planungsobjektWindowWannWoSelectors.selectSendetagIsDateDisabled,
  );
}
