import { Pipe, PipeTransform } from "@angular/core";
import { PlanungshinweisDto } from "src/app/models/openapi/model/planungshinweis-dto";

@Pipe({
  name: "planungshinweisSingleLineNotes",
})
export class PlanungshinweisSingleLineNotesPipe implements PipeTransform {
  transform(planungshinweise: PlanungshinweisDto[]): string {
    const planungshinweisWithNotizen = planungshinweise.filter(
      (planungshinweis) => !!planungshinweis.notiz,
    );

    // Kein Planungshinweis hat eine Notiz, dann nichts anzeigen
    // Kein Planungshinweis, dann nichts anzeigen (indirekt mitgeprüft)
    if (planungshinweisWithNotizen.length == 0) return "";

    // "||" nur am Anfang oder am Ende, wenn der erste oder letzte Planungshinweis eine leere Notiz hat
    return planungshinweise.reduce(
      (previousValue, planungsobjekt, i) =>
        previousValue +
        // Drei Leerzeichen für VPF mit leeren Notizen
        ` ${planungsobjekt.notiz ? planungsobjekt.notiz : "\xa0\xa0\xa0"} ${
          i < planungshinweise.length - 1 ||
          (i == planungshinweise.length - 1 && !planungsobjekt.notiz)
            ? "||"
            : ""
        }`,
      planungshinweise[0].notiz ? "" : "||",
    );
  }
}
