<ng-container
  *ngIf="{
    planen: ('planen' | able: (sendeplatz | asSubject: 'Sendeplatz')),
    transferToPharos: ('transferNachPharos' | able: (sendeplatz | asSubject: 'Sendeplatz'))
  } as can"
>
  <ng-container
    *ngIf="{
      vorgeplant: planungsobjekte$ | async
    } as data"
  >
    <div class="sendeplatz-container">
      <div #targetContextMenu class="sendeplatz-details">
        <div
          class="sendeplatz-details-button sendeplatz-icon"
          [attr.data-testid]="'sendeplatz-details-button-' + sendeplatz.kalendertag"
          [ngStyle]="{
            'background-color':
              sendeplatz.effektivVon
              | hasPlanungshinweis: sendeplatz.effektivBis : sendeplatz.kanal
              | async
          }"
          [title]="
            sendeplatz.effektivVon
              | planungshinweisTooltip
                : sendeplatz.effektivBis
                : sendeplatz.kanal
                : sendeplatz
                : sendeplatzGroupZeileSendeplaetze
              | async
          "
          (click)="onOpenPlanungshinweiseWindow()"
        >
          <span
            *ngIf="sendeplatz | areSendeplaetzeOverlapping: sendeplatzGroupZeileSendeplaetze"
            class="overlapping-icon"
            >!</span
          >
        </div>
        <div
          class="sendeplatz-details-labels"
          [attr.data-testid]="'sendeplatz-details-' + sendeplatz.kalendertag"
        >
          <div>{{ sendeplatz.abweichendeBeginnzeit }}</div>
          <div>{{ sendeplatz.abweichendeLaenge | secondsToMinutesOrEmpty: "'" }}</div>
        </div>
      </div>
      <!-- Sendeplatz Kontextmenü -->
      <!-- Achtung: Hier kann direkt über (select) das Event emittiert werden, da in diesem Fall lediglich ein Menu Item selektiert werden kann. -->
      <kendo-contextmenu
        showOn="contextmenu"
        [target]="targetContextMenu"
        (select)="openSendePlatzKontextmenu($event, sendeplatz)"
      >
        <kendo-menu-item
          text="Details Sendeplatz"
          [data]="{ action: AktionEnum.SENDEPLATZ_DETAILS }"
          [svgIcon]="Icons.information"
        >
        </kendo-menu-item>
        <kendo-menu-item
          text="Nach Pharos übertragen"
          [data]="{ action: AktionEnum.NACH_PHAROS_UEBERTRAGEN }"
          [disabled]="!can.transferToPharos"
          [svgIcon]="Icons.pharos"
        ></kendo-menu-item>
      </kendo-contextmenu>

      <!-- Sendeplatz Chip List -->
      <kendo-badge-container class="sendeplatz">
        <span #sendeplatzContextMenu>
          <kendo-chip-list
            #target="kendoContextMenuTargetContainer"
            aria-label="Titel"
            cdkDropList
            kendoContextMenuTargetContainer
            [attr.data-testid]="
              'sendeplatz-droplist-' + sendeplatz.kalendertag + '-' + sendeplatzIndex
            "
            [cdkDropListData]="sendeplatz"
            [cdkDropListDisabled]="!can.planen"
            [cdkDropListEnterPredicate]="can.planen | cdkDropListEnterPredicateWrapper"
            (cdkDropListDropped)="planungsobjektEinplanen($event, sendeplatz, data.vorgeplant)"
            (cdkDropListEntered)="facade.setHoveredSendeplatz(sendeplatz.sendetag)"
          >
            <app-sendeplatz
              [ansichtViewModel]="ansicht"
              [isMengengeruestErlaubt]="ansicht.ansichtsdefinition.isMengengeruestErlaubt"
              [schemaplatz]="sendeplatz | schemaplatzBySendeplatz: ansicht"
              [sendeplatz]="sendeplatz"
              [target]="target"
            >
            </app-sendeplatz>
          </kendo-chip-list>
          <app-planungsobjekt-vorgeschlagen-badge
            [sendeplatz]="sendeplatz"
          ></app-planungsobjekt-vorgeschlagen-badge>
        </span>
      </kendo-badge-container>
      <kendo-contextmenu
        [target]="sendeplatzContextMenu"
        (select)="facade.openGetitWindow(sendeplatz)"
      >
        <kendo-menu-item
          text="Produkt-ID eingeben"
          [disabled]="!can.planen"
          [svgIcon]="Icons.getit"
        ></kendo-menu-item>
      </kendo-contextmenu>
    </div>
  </ng-container>
</ng-container>
