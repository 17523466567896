<kendo-floatinglabel class="u-w-full" text="Weitere Filter">
  <kendo-multiselect
    class="u-w-full"
    data-testid="recherche-additional-filter-select"
    kendoMultiSelectSummaryTag
    textField="text"
    valueField="type"
    [autoClose]="false"
    [checkboxes]="true"
    [clearButton]="false"
    [data]="additionalFilters"
    [value]="selectedFilters"
    [valuePrimitive]="true"
    (valueChange)="valueChange($event)"
  >
    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
      {{ dataItems.length }} Filter ausgewählt
    </ng-template>
  </kendo-multiselect>
</kendo-floatinglabel>
