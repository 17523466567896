<colgroup>
  <ng-container *ngFor="let ansichtHeader of ansichtInitHeaders; trackBy: trackByColumnId">
    <col [ngClass]="{ date: ansichtHeader.title === ListenansichtHeader.DATUM }" />
  </ng-container>
</colgroup>
<tr>
  <ng-container
    *ngFor="
      let ansichtHeader of ansichtInitHeaders;
      trackBy: trackByColumnId;
      first as first;
      index as index
    "
  >
    <th
      class="listenansicht-header dts-select-exclude"
      [attr.data-testid]="
        ansichtHeader.title === ListenansichtHeader.VORSCHLAEGE
          ? 'vorschlagspalte-' + ansichtHeader.schemaplatzId
          : null
      "
    >
      <ng-container *ngIf="first; else default">
        <button
          class="u-w-full dts-select-exclude"
          data-testid="previous-year-arrow-button"
          fillMode="flat"
          kendoButton
          size="none"
          title="Lade Ansicht für vorheriges Jahr"
          [disabled]="(facade.hasPreviousAnsicht$ | async) === false"
          [svgIcon]="Icons.arrowUpIcon"
          (click)="facade.loadPreviousAnsicht()"
        ></button>
      </ng-container>
      <ng-template #default>
        <div class="schemaplatz-with-vorschlagspalte-button">
          <span>{{
            ansichtHeader.title !== ListenansichtHeader.DATUM ? ansichtHeader.title : ""
          }}</span>
          <!-- index bezieht sich auf die Spalte mit den Planungsobjekte im Kontext Vorgeplant -->
          <kendo-button
            *ngIf="ansichtHeader.columnType === ListenansichtHeader.SCHEMAPLATZ"
            button
            class="header-button"
            fillMode="clear"
            size="none"
            title="Vorschläge ein/ausblenden"
            [attr.data-testid]="'vorschlagspalte-button-' + ansichtHeader.schemaplatzId"
            [svgIcon]="Icons.buttonIcon"
            [themeColor]="
              visibleHeadersByColumnId[ansichtHeader.columnId][ListenansichtHeader.VORSCHLAEGE]
                ? 'primary'
                : 'base'
            "
            (click)="toggleColumnById(ansichtHeader.columnId, ListenansichtHeader.VORSCHLAEGE)"
          ></kendo-button>
          <!-- index bezieht sich auf die Spalte mit den Planungsobjekte im Kontext Vorschlag -->
          <kendo-button
            *ngIf="ansichtHeader.columnType === ListenansichtHeader.VORSCHLAEGE"
            button
            class="header-button"
            fillMode="clear"
            size="none"
            title="Notizen ein/ausblenden"
            [attr.data-testid]="'vorschlagspalte-notizen-button-' + ansichtHeader.schemaplatzId"
            [svgIcon]="Icons.notizenIcon"
            [themeColor]="
              visibleHeadersByColumnId[ansichtHeader.columnId][
                ListenansichtHeader.VORSCHLAEGE_NOTIZEN
              ]
                ? 'primary'
                : 'base'
            "
            (click)="
              toggleColumnById(ansichtHeader.columnId, ListenansichtHeader.VORSCHLAEGE_NOTIZEN)
            "
          ></kendo-button>
        </div>
      </ng-template>
    </th>
  </ng-container>
</tr>
