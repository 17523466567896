import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AktivitaetDto } from "src/app/models/openapi/model/aktivitaet-dto";
import { DebugService } from "src/app/services/debug.service";

@Component({
  selector: "app-aktivitaeten-tab",
  templateUrl: "./aktivitaeten-tab.component.html",
  styleUrls: ["./aktivitaeten-tab.component.scss"],
})
export class AktivitaetenTabComponent implements OnInit {
  @Input({ required: true }) aktivitaeten: AktivitaetDto[];

  @Output() loadAktivitaetenForPlanungsobjekt = new EventEmitter<void>();

  constructor(protected debugService: DebugService) {}

  ngOnInit(): void {
    this.loadAktivitaetenForPlanungsobjekt.emit();
  }
}
