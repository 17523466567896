import { Component, Input } from "@angular/core";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { AktionEnum } from "src/app/models/enums/aktion";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";

export interface PlanungsobjektAcceptWindowInput {
  sendeplatz: SendeplatzDto;
  linearId: string;
  linearTitel: string;
  vorgeplantePlanungsobjekte: PlanungsobjektDto[];
}

export type PlanungsobjektAcceptWindowResult =
  | {
      action: AktionEnum.VORSCHLAG_ANNEHMEN_ERSETZEN;
      linearId: string;
      planungsobjektId: string;
      sendeplatz: SendeplatzDto;
    }
  | {
      action: AktionEnum.VORSCHLAG_ANNEHMEN_ERGAENZEN;
      sendeplatz: SendeplatzDto;
      linearId: string;
    };

@Component({
  selector: "app-planungsobjekt-accept-window",
  templateUrl: "./planungsobjekt-accept-window.component.html",
  styleUrls: ["./planungsobjekt-accept-window.component.scss"],
})
export class PlanungsobjektAcceptWindowComponent {
  @Input() public data: PlanungsobjektAcceptWindowInput;

  constructor(private windowRef: WindowRef) {}

  onErsetzenClick(planungsobjektId: string) {
    const dialogResult: PlanungsobjektAcceptWindowResult = {
      action: AktionEnum.VORSCHLAG_ANNEHMEN_ERSETZEN,
      linearId: this.data.linearId,
      sendeplatz: this.data.sendeplatz,
      planungsobjektId,
    };
    this.windowRef.close(dialogResult);
  }

  onErgaenzenClick() {
    const dialogResult: PlanungsobjektAcceptWindowResult = {
      action: AktionEnum.VORSCHLAG_ANNEHMEN_ERGAENZEN,
      linearId: this.data.linearId,
      sendeplatz: this.data.sendeplatz,
    };
    this.windowRef.close(dialogResult);
  }

  cutTitle(title: string) {
    return title.length > 25 ? title.substring(0, 23) + "..." : title;
  }

  onClose(): void {
    this.windowRef.close();
  }
}
