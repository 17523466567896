import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { SendeplatzApiService } from "../api/sendeplatz/sendeplatz.api.service";
import { GetSendeplaetzeByAnsichtenQuery } from "../models/openapi/model/get-sendeplaetze-by-ansichten-query";
import { SendeplatzDto } from "../models/openapi/model/sendeplatz-dto";
import { SendeplatzKeyDto } from "../models/openapi/model/sendeplatz-key-dto";
import { UpsertSendeplatzCommand } from "../models/openapi/model/upsert-sendeplatz-command";
import {
  SendeplatzWindowComponent,
  SendeplatzWindowResult,
} from "../shared/windows/sendeplatz-window/sendeplatz-window.component";
import { SmallWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";

@Injectable({
  providedIn: "root",
})
export class SendeplatzService {
  constructor(
    private sendeplatzApiService: SendeplatzApiService,
    private customWindowService: CustomWindowService,
    private store: Store,
  ) {}

  getSendeplatzById$(sendeplatzId: string): Observable<SendeplatzDto> {
    return this.sendeplatzApiService.getSendeplatzById$(sendeplatzId);
  }

  getSendeplatzBySendeplatzKey$(sendeplatzKey: SendeplatzKeyDto): Observable<SendeplatzDto> {
    return this.sendeplatzApiService.getSendeplatzBySendeplatzKey$(sendeplatzKey);
  }

  updateSendeplatz$(sendeplatz: SendeplatzDto): Observable<SendeplatzDto> {
    const sendeplatzDto = this.mapSendeplatzToUpsertCommand(sendeplatz);
    return this.sendeplatzApiService.updateSendeplatz$(sendeplatzDto);
  }

  getSendeplaetzeByAnsichten$(query: GetSendeplaetzeByAnsichtenQuery) {
    return this.sendeplatzApiService.getSendeplaetzeByAnsichten$(query);
  }

  openSendeplatzWindow(sendeplatz: SendeplatzDto) {
    const windowRef = this.customWindowService.open<
      SendeplatzWindowComponent,
      SendeplatzWindowResult
    >({
      content: SendeplatzWindowComponent,
      title: "Sendeplatz",
      ...SmallWindow({ dataTestId: "sendeplatz-window" }),
    });

    windowRef.content.instance.sendeplatz = sendeplatz;
    return windowRef;
  }

  private mapSendeplatzToUpsertCommand(sendeplatz: SendeplatzDto): UpsertSendeplatzCommand {
    const command: UpsertSendeplatzCommand = {
      abweichendeBeginnzeit: sendeplatz.abweichendeBeginnzeit,
      abweichendeLaenge: sendeplatz.abweichendeLaenge,
      notiz: sendeplatz.notiz,
      sendeplatzKey: {
        beginnzeit: sendeplatz.beginnzeit,
        kanal: sendeplatz.kanal,
        laenge: sendeplatz.laenge,
        kalendertag: sendeplatz.kalendertag,
        sendetag: sendeplatz.sendetag,
      },
    };
    return command;
  }
}
