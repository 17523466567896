<kendo-chip
  cdkDrag
  class="planungsobjekt-chip"
  rounded="full"
  [attr.data-testid]="'vorschlag-' + planungsobjektInVorschlagspalte.titel"
  [cdkDragData]="planungsobjektInVorschlagspalte"
  [kendoContextMenuTarget]="planungsobjektInVorschlagspalte"
  [ngClass]="{
    'u-font-style-italic': !istMitProduktdatenbankVerknuepft(planungsobjektInVorschlagspalte)
  }"
  [ngStyle]="planungsobjektInVorschlagspalte.id | planungsobjektStyle | async"
  [title]="planungsobjektInVorschlagspalte.notiz"
  (dblclick)="onDoubleClick($event, planungsobjektInVorschlagspalte)"
>
  <app-abweichende-laenge-badge
    [planungsobjekt]="planungsobjektInVorschlagspalte"
    [sendeplatz]="sendeplatz"
  ></app-abweichende-laenge-badge>
  {{ planungsobjektInVorschlagspalte.titel }}
  {{
    planungsobjektInVorschlagspalte.redaktion &&
    planungsobjektInVorschlagspalte.redaktion !== Redaktion.KEINE_REDAKTION
      ? " (" + planungsobjektInVorschlagspalte.redaktion + ")"
      : ""
  }}

  <kendo-svg-icon
    *ngIf="planungsobjektInVorschlagspalte.highlight"
    class="chip-icon icon-xxs"
    [icon]="Icons.star"
  ></kendo-svg-icon>

  <kendo-svg-icon
    *ngIf="planungsobjektInVorschlagspalte.planungsobjektOnDemandId"
    class="chip-icon icon-xs"
    title="Online-Publikation verlinkt"
    [attr.data-testid]="planungsobjektInVorschlagspalte.titel + '-onDemand-verlinkt'"
    [icon]="Icons.onDemandBeziehung"
  ></kendo-svg-icon>
</kendo-chip>
