import { ComponentRef, Injectable } from "@angular/core";
import {
  NotificationComponent,
  NotificationRef,
  NotificationService,
  Type,
} from "@progress/kendo-angular-notification";
import { BehaviorSubject, take } from "rxjs";
import { AppPage } from "src/app/models/enums/app-page";
import { BenachrichtigungDto } from "src/app/models/openapi/model/benachrichtigung-dto";
import { BenachrichtigungPrioritaet } from "src/app/models/openapi/model/benachrichtigung-prioritaet";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { CustomNotificationService } from "./custom-notification.service";
import { reloadNotificationSettings } from "./notification.templates";
import { getKendoStyleType } from "./notification.utils";
import { ReloadNotificationComponent } from "./reload-notification/reload-notification.component";

@Injectable({
  providedIn: "root",
})
export class ReloadNotificationService {
  private _latestBenachrichtigung: BenachrichtigungDto | null = null;
  private _latestAppPage: AppPage | null = null;

  private _activeNotification: NotificationRef | null = null;

  private _benachrichtigungCounter = new BehaviorSubject<number>(0);
  benachrichtigungCounter$ = this._benachrichtigungCounter.asObservable();

  constructor(
    private notificationService: NotificationService,
    private customNotificationService: CustomNotificationService,
  ) {}

  private getKendoNotificationComponent(): NotificationComponent {
    return this._activeNotification.notification.instance;
  }

  private getReloadNotificationComponent(): ReloadNotificationComponent {
    const componentRef = this._activeNotification
      .content as ComponentRef<ReloadNotificationComponent>;
    return componentRef.instance;
  }

  /**
   * Benachrichtigung, die den Anwender auffordert die aktuelle Daten (Ansicht oder OnDemand) zu aktualisieren.
   * @param benachrichtigung
   * @param page
   */
  showReloadNotification(benachrichtigung: BenachrichtigungDto, page: AppPage) {
    const notificationType = this.getNotificationType(benachrichtigung);

    // Öffne ein neue Benachrichtigung nur wenn noch keine Benachrichtigung angezeigt wird
    if (!this._activeNotification) {
      this._activeNotification = this.notificationService.show({
        ...reloadNotificationSettings,
        content: ReloadNotificationComponent,
        type: notificationType,
        cssClass: `publit-notification-container`,
      });
      this.subscribeReloadNotificationAction();
    }

    // Ändert ggf. den Stil der Benachrichtigung (Wichtig / Weniger Wichtig),
    // dies darf nur geschehen, wenn die neue Benachrichtigung eine höhere Priorität hat
    if (this.isHigherPriority(benachrichtigung)) {
      this.updateNotificationType(notificationType);
    }

    // Soll die aktuelle Ansicht oder OnDemand Recherche neu geladen werden?
    // Theoretisch nicht nötig, da wir bei einem Wechsel der Seite (z.B. von Ansicht zu OnDemand)
    // die Reload Notification zerstören und dann ggf. neu setzten
    this.updateAppPage(page);

    // Speichere die Priorität zum Vergleich für Folgebenachrichtigungen
    this._latestBenachrichtigung = benachrichtigung;

    // Inkrementiere den Notification Counter bei jeder neuen Benachrichtigung für die Anzeige in der Reload Notification
    this._benachrichtigungCounter.next(this._benachrichtigungCounter.value + 1);
  }

  private getNotificationType(benachrichtigung: BenachrichtigungDto): Type {
    switch (benachrichtigung.prioritaet) {
      case BenachrichtigungPrioritaet.WICHTIG:
        return { style: getKendoStyleType(NotificationStyle.WARNING) };
      case BenachrichtigungPrioritaet.WENIGER_WICHTIG:
        return { style: getKendoStyleType(NotificationStyle.INFO) };
      default:
        return { style: getKendoStyleType(NotificationStyle.NONE) };
    }
  }

  private isHigherPriority(benachrichtigung: BenachrichtigungDto): boolean {
    return (
      this._latestBenachrichtigung !== null &&
      this._latestBenachrichtigung.prioritaet === BenachrichtigungPrioritaet.WENIGER_WICHTIG &&
      benachrichtigung.prioritaet === BenachrichtigungPrioritaet.WICHTIG
    );
  }

  private subscribeReloadNotificationAction() {
    const reloadNotificationComponent = this.getReloadNotificationComponent();

    // Ist das denn wirklich notwendig?
    // Die Reload Benachrichtigung wird doch sowieso geschlossen, wenn wir die Daten neu laden
    // (siehe Verwendungen von destroyBroadcastNotificationIfExists)
    // Wir müssten nur feststellen, ob der Reload Button geklickt wurde um die Daten neu zu laden,
    // denn nur in diesem Fall wollen wir auch die Notification anzeigen
    reloadNotificationComponent.updatePage
      .pipe(take(1))
      .subscribe(() => this.destroyBroadcastNotificationIfExists(true));
  }

  destroyBroadcastNotificationIfExists(showUpdateNotification = false) {
    if (this._activeNotification) {
      // Zerstört unsere Reload Benachrichtigung Component
      // muss das wirklich explizit aufgerufen werden oder reicht es, wenn wir den Container zerstören?
      this._activeNotification.content.destroy();
      // Versteckt den Kendo Notification Container
      // passiert leider zeitverzögert, daher wird der Container noch kurz angezeigt
      this._activeNotification.hide();

      // TODO - Wollen wir das nicht eher in der Reload Notification Component machen?
      // diese entscheidet ja auch welche Action ausgeführt wird.
      if (showUpdateNotification) {
        if (this._latestAppPage === AppPage.Ansicht) {
          this.customNotificationService.showNotification(
            "Ansicht wurde gesamtheitlich aktualisiert!",
            NotificationStyle.INFO,
          );
        } else if (this._latestAppPage === AppPage.OnDemand) {
          this.customNotificationService.showNotification(
            "Planung wurde gesamtheitlich aktualisiert!",
            NotificationStyle.INFO,
          );
        }
      }
    }

    this.resetState();
  }

  /**
   * Ändert den Typen (Wichtig / Weniger Wichtig) einer bereits existierenden Reload Benachrichtigung.
   */
  private updateNotificationType(type: Type) {
    const notificationComponent = this.getKendoNotificationComponent();
    const currentType = notificationComponent.type;
    notificationComponent.type = { ...currentType, ...type };
  }

  // TODO 1403 - Wollen wir nicht einfach direkt die passende Aktion dispatchen?
  private updateAppPage(appPage: AppPage) {
    this._latestAppPage = appPage;
    const reloadNotificationComponent = this.getReloadNotificationComponent();
    reloadNotificationComponent.page = appPage;
  }

  private resetState() {
    this._activeNotification = null;
    this._latestBenachrichtigung = null;
    this._latestAppPage = null;
    // Resette den Notification Counter, wenn der Nutzer die Ansicht updated
    this._benachrichtigungCounter.next(0);
  }
}
