@for (
  blockansichtDefinition of facade.blockansichtDefinitionen$ | async;
  track blockansichtDefinition.ansichtViewModel.id
) {
  <app-blockansicht-block
    [blockansichtDefinition]="blockansichtDefinition"
    [blockansichtDefinitionen]="facade.blockansichtDefinitionen$ | async"
    [showKonkurrenzEvents]="showKonkurrenzEvents"
    [showNotizen]="showNotizen"
  ></app-blockansicht-block>
}
