import { AfterViewInit, ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AktionEnum } from "src/app/models/enums/aktion";
import { KanalRecord } from "src/app/models/enums/kanal";
import { Icons } from "src/app/models/icons";
import { SendeplatzViewModel } from "src/app/models/viewmodels/sendeplatz-viewmodel";
import { TitleShortenerPipe } from "src/app/shared/pipes/title-format.pipe";
import { AnsichtViewModel } from "../../../models/viewmodels/ansicht-viewmodel";
import KalenderansichtFacade from "./kalenderansicht.facade";

@Component({
  selector: "app-kalenderansicht",
  templateUrl: "./kalenderansicht.component.html",
  styleUrls: ["./kalenderansicht.component.scss"],
  providers: [TitleShortenerPipe, KalenderansichtFacade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KalenderansichtComponent implements AfterViewInit {
  public readonly facade = inject(KalenderansichtFacade);

  PREVIOUS_YEAR_OFFSET = -1;
  NEXT_YEAR_OFFSET = 1;

  @Input() ansichtViewModel: AnsichtViewModel;
  @Input() kalenderAnsichtenSendeplaetze: Record<number, SendeplatzViewModel[][][]>;

  // Kontextmenu
  AktionEnum = AktionEnum;
  Icons = Icons;

  constructor(
    private titleService: Title,
    private titleFormat: TitleShortenerPipe,
  ) {}

  ngAfterViewInit(): void {
    const titel = this.titleFormat.transform(this.ansichtViewModel.titel);
    this.titleService.setTitle(
      `${KanalRecord[this.ansichtViewModel.kanal].bedeutung} ${this.ansichtViewModel.year} - ${titel}`,
    );
  }
}
