import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Icons } from "src/app/models/icons";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";

@Component({
  selector: "app-planungsobjekt-vorschlag",
  templateUrl: "./planungsobjekt-vorgeschlagen.component.html",
  styleUrls: ["./planungsobjekt-vorgeschlagen.component.scss"],
})
export class PlanungsobjektVorgeschlagenComponent {
  @Input() sendeplatz: SendeplatzDto;
  @Input() planungsobjektInVorschlagspalte: PlanungsobjektDto;
  @Output() doubleClicked = new EventEmitter<PlanungsobjektDto>();

  Redaktion = Redaktion;
  Icons = Icons;

  onDoubleClick(event: MouseEvent, planungsobjekt: PlanungsobjektDto) {
    event.stopPropagation();
    this.doubleClicked.emit(planungsobjekt);
  }

  istMitProduktdatenbankVerknuepft = PlanungsobjektUtils.isConnectedWithProductDb;
}
