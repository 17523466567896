import { addDays, addSeconds, areIntervalsOverlapping, parse } from "date-fns";
import de from "date-fns/locale/de";
import { BenachrichtigungDto } from "src/app/models/openapi/model/benachrichtigung-dto";
import { ContentCommunity } from "src/app/models/openapi/model/content-community";
import { EntityType } from "src/app/models/openapi/model/entity-type";
import { Genre } from "src/app/models/openapi/model/genre";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { SchemaplatzDto } from "src/app/models/openapi/model/schemaplatz-dto";
import { Wochentag } from "src/app/models/openapi/model/wochentag";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { toDayOfWeek, toWochentag } from "src/app/utils/date-utils";
import { isDefined } from "src/app/utils/object-utils";

export function isBenachrichtigungForEntities(
  benachrichtigung: BenachrichtigungDto,
  entityTypes: EntityType[],
) {
  const entityTypesInBenachrichtigung = getScopeEntities(benachrichtigung);
  return entityTypesInBenachrichtigung.some((entityType) => entityTypes.includes(entityType));
}

function getScopeEntities(benachrichtigung: BenachrichtigungDto): EntityType[] {
  const entityTypes = benachrichtigung.scopes.map((scope) => scope.entityType);
  // Doppelte Einträge entfernen
  // https://stackoverflow.com/a/43046408/1010496
  return [...new Set(entityTypes)];
}

export function isBenachrichtigungForPlanungskontext(
  benachrichtigung: BenachrichtigungDto,
  planungskontexte: Planungskontext[],
) {
  const planungskontexteInBenachrichtigung = getScopePlanungskontexte(benachrichtigung);
  return planungskontexteInBenachrichtigung.some((planungskontext) =>
    planungskontexte.includes(planungskontext),
  );
}

function getScopePlanungskontexte(benachrichtigung: BenachrichtigungDto): Planungskontext[] {
  const planungskontexte = benachrichtigung.scopes
    .map((scope) => scope.planungskontext)
    .filter(isDefined);
  return [...new Set(planungskontexte)];
}

export function isBenachrichtigungForAusspielweg(
  benachrichtigung: BenachrichtigungDto,
  ausspielweg: Kanal,
) {
  const ausspielwegeInBenachrichtigung = getScopeAusspielwege(benachrichtigung);
  return ausspielwegeInBenachrichtigung.has(ausspielweg);
}

function getScopeAusspielwege(benachrichtigung: BenachrichtigungDto): Set<Kanal> {
  const ausspielwege = benachrichtigung.scopes.map((scope) => scope.ausspielweg).filter(isDefined);
  return new Set(ausspielwege);
}

export function isBenachrichtigungForRedaktion(
  benachrichtigung: BenachrichtigungDto,
  redaktionen: Redaktion[],
) {
  const redaktionenInBenachrichtigung = getScopeRedaktionen(benachrichtigung);
  return redaktionenInBenachrichtigung.some((redaktion) => redaktionen.includes(redaktion));
}

function getScopeRedaktionen(benachrichtigung: BenachrichtigungDto): Redaktion[] {
  const redaktionen = benachrichtigung.scopes.map((scope) => scope.redaktion).filter(isDefined);
  return [...new Set(redaktionen)];
}

export function isBenachrichtigungForGenre(benachrichtigung: BenachrichtigungDto, genres: Genre[]) {
  const genresInBenachrichtigung = getScopeGenres(benachrichtigung);
  return genresInBenachrichtigung.some((genre) => genres.includes(genre));
}

function getScopeGenres(benachrichtigung: BenachrichtigungDto): Genre[] {
  const genres = benachrichtigung.scopes.map((scope) => scope.genre).filter(isDefined);
  return [...new Set(genres)];
}

export function isBenachrichtigungForZielgruppe(
  benachrichtigung: BenachrichtigungDto,
  contentCommunities: ContentCommunity[],
) {
  const zielgruppeInBenachrichtigung = getScopeZielgruppe(benachrichtigung);
  return zielgruppeInBenachrichtigung.some((zielgruppe) => contentCommunities.includes(zielgruppe));
}

function getScopeZielgruppe(benachrichtigung: BenachrichtigungDto): ContentCommunity[] {
  const zielgruppen = benachrichtigung.scopes
    .map((scope) => scope.contentCommunities)
    .filter(isDefined);
  return [...new Set(...zielgruppen)];
}

export function isBenachrichtigungInIntervals(
  benachrichtigung: BenachrichtigungDto,
  intervals: { start: Date; end: Date }[],
) {
  if (!intervals.length) {
    return false;
  }

  const intervalsInBenachrichtigung = getScopeIntervals(benachrichtigung);
  return intervalsInBenachrichtigung.some((intervalBenachrichtigung) =>
    intervals.some((interval) => areIntervalsOverlapping(intervalBenachrichtigung, interval)),
  );
}

export function isBenachrichtigungInAnsichtLinear(
  benachrichtigung: BenachrichtigungDto,
  ausspielweg: Kanal,
  intervals: { start: Date; end: Date }[],
) {
  // Benachrichtigungen für OnDemand Planungsobjekte werden generell gefiltert
  if (isBenachrichtigungForEntities(benachrichtigung, [EntityType.PLANUNGSOBJEKT_ON_DEMAND])) {
    return false;
  }

  // Benachrichtigungen für lineare Planungsobjekte werden nur angezeigt, wenn der Ausspielweg passt
  if (
    isBenachrichtigungForEntities(benachrichtigung, [EntityType.PLANUNGSOBJEKT_LINEAR]) &&
    !isBenachrichtigungForAusspielweg(benachrichtigung, ausspielweg)
  ) {
    return false;
  }

  const benachrichtigungIntervals = getScopeIntervals(benachrichtigung);
  return benachrichtigungIntervals.some((benachrichtigungInterval) =>
    intervals.some((interval) => areIntervalsOverlapping(benachrichtigungInterval, interval)),
  );
}

export function getScopeIntervals(benachrichtigung: BenachrichtigungDto) {
  const intervals: { start: Date; end: Date }[] = [];

  for (const scope of benachrichtigung.scopes) {
    const start = scope.von ? new Date(scope.von) : null;
    const end = scope.bis ? new Date(scope.bis) : null;
    if (start && end) {
      intervals.push({ start, end });
    }
  }

  return intervals;
}

/**
 * Berechnet aus von/bis der Ansicht und den Schemaplätzen der Ansichtsdefinition
 * konkrete Zeitbereiche, welche den verschiedenen entsprechen. Für eine Ansicht mit
 * einem Schemaplatz ergeben sich normalerweise 52 konkrete Zeitbereiche.
 *
 * Die Berechnung ist nicht aufwendig, aber theoretisch muss sie für jede Ansicht nur
 * ein einziges Mal durchgeführt werden, da sich das Ergebnis nicht ändert.
 */
export function getSendeplatzIntervals(ansicht: AnsichtViewModel) {
  const intervals: { start: Date; end: Date }[] = [];

  const firstDate = new Date(ansicht.zeitraum.start);
  const lastDate = new Date(ansicht.zeitraum.end);
  const schemaplaetzeByWochentag = groupSchemaplaetzeByWochentag(
    ansicht.ansichtsdefinition.schemaplaetze,
  );

  for (let date = firstDate; date <= lastDate; date = addDays(date, 1)) {
    const wochentag = toWochentag(date);
    for (const schemaplatz of schemaplaetzeByWochentag[wochentag]) {
      const start = parse(schemaplatz.beginnzeit, "HH:mm", date, { locale: de });
      const end = addSeconds(start, schemaplatz.laenge);
      intervals.push({ start, end });
    }
  }

  return intervals;
}

/**
 * Gruppierung könnte pro Ansicht zwischengespeichert werden, da sich die Schemaplätze
 * nach dem erstmaligen Abrufen vom Backend nicht mehr ändern.
 */
function groupSchemaplaetzeByWochentag(
  schemaplaetze: SchemaplatzDto[],
): Record<Wochentag, SchemaplatzDto[]> {
  const schemaplaetzeByWochentag: Record<Wochentag, SchemaplatzDto[]> = {
    Montag: [],
    Dienstag: [],
    Mittwoch: [],
    Donnerstag: [],
    Freitag: [],
    Samstag: [],
    Sonntag: [],
  };

  schemaplaetze.forEach((schemaplatz) => {
    schemaplaetzeByWochentag[schemaplatz.wochentag].push(schemaplatz);
  });

  return schemaplaetzeByWochentag;
}

// ---- Experimental ----

/**
 * Alternativer Ansatz zu konkreten Sendeplatz Intervallen:
 * Jeder Wochentag korrespondiert mit irgendeinem gemeinsamen Datum das auf dem Entsprechenden Wochentag liegt.
 * z.B. Jeder Montag ist der 01.01.2001, jeder Dienstag ist der 02.01.2001, etc.
 * Dadurch können Overlap Vergleich zwischen (nicht absoluten) Schemaplätzen und (absoluten) Planungsobjekt Daten durchgeführt werden
 */
export function getRelativeSchemaplatzIntervals(schemaplaetze: SchemaplatzDto[]) {
  return schemaplaetze.map((schemaplatz) => {
    const dayOfWeek = toDayOfWeek(schemaplatz.wochentag);
    // ...
  });
}

export function stringToInterval(fromTimeStr: string, toTimeStr: string) {
  const from = stringToDate(fromTimeStr);
  const to = stringToDate(toTimeStr);

  // Wenn Zeitintervalle über eine Tagesgrenze hinaus gehen, z.B.
  // 20:15:00 - 02:00:00
  // muss das Enddatum um einen Tag erhöht werden
  if (to.getTime() > from.getTime()) {
    // does it wrap?
    to.setDate(to.getDate() + 1);
  }

  return { start: from, end: to };
}

export function fromLengthToInterval(fromTimeStr: string, lengthInSeconds: number) {
  const from = stringToDate(fromTimeStr);
  const to = addSeconds(from, lengthInSeconds);
  return { start: from, end: to };
}

export function stringToDate(timeStr: string): Date {
  const [hours, minutes, seconds] = timeStr.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds, 0);
  return date;
}
