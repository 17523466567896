import { Injectable } from "@angular/core";
import { Kanal } from "../models/openapi/model/kanal";
import {
  PlanungshinweiseWindowComponent,
  PlanungshinweiseWindowResult,
} from "../shared/windows/planunshinweise-window/planungshinweise-window.component";
import { BigWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";

@Injectable({
  providedIn: "root",
})
export class PlanungshinweiseService {
  constructor(private customWindowService: CustomWindowService) {}

  openPlanungshinweiseWindow(von: Date, bis: Date, kanal: Kanal) {
    const windowRef = this.customWindowService.open<
      PlanungshinweiseWindowComponent,
      PlanungshinweiseWindowResult
    >({
      content: PlanungshinweiseWindowComponent,
      title: "Planungshinweise",
      ...BigWindow(),
    });

    windowRef.content.instance.input = {
      von,
      bis,
      kanal,
    };

    // windowRef.result.subscribe();
    return windowRef;
  }
}
