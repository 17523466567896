import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { BlockansichtApiService } from "src/app/api/ansichten/blockansicht.api.service";
import { PlanungsobjektLinearApiService } from "src/app/api/planungsobjekt/planungsobjekt-linear.api.service";
import { BlockansichtMapper } from "src/app/models/mapper/blockansicht.mapper";
import { GetVariantenzeilenByPublikationKeyQuery } from "src/app/models/openapi/model/get-variantenzeilen-by-publikation-key-query";
import { MovePlanungsobjekteLinearToVorgeplantBlockCommand } from "src/app/models/openapi/model/move-planungsobjekte-linear-to-vorgeplant-block-command";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDtoResultDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto-result-dto";
import { PlanungsobjektLinearVorgeplantBlockAktualisierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-aktualisieren-command";
import { PlanungsobjektLinearVorgeplantBlockErstellenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-erstellen-command";
import { PlanungsobjektLinearVorgeplantBlockKopierenCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-block-kopieren-command";
import { VariantenzeileDto } from "src/app/models/openapi/model/variantenzeile-dto";

@Injectable({
  providedIn: "root",
})
export class BlockansichtService {
  constructor(
    private api: BlockansichtApiService,
    private planungsobjektLinearApi: PlanungsobjektLinearApiService,
    private blockansichtMapper: BlockansichtMapper,
    private store: Store,
  ) {}

  getPlanungsobjekteByBlockansichtenIds$(ids: string[]): Observable<PlanungsobjektDto[]> {
    return this.planungsobjektLinearApi.getPlanungsobjekteLinearByAnsichtenBlock$({
      ansichtenIds: ids,
    });
  }

  loadVariantenzeilen$(
    query: GetVariantenzeilenByPublikationKeyQuery,
  ): Observable<VariantenzeileDto[]> {
    return this.api.getVariantenzeilenByPublikationKey$(query);
  }

  createPlanungsobjektLinearVorgeplantBlock$(planungsobjekt: PlanungsobjektDto) {
    const command =
      this.blockansichtMapper.mapPlanungsobjektToCreatePlanungsobjektLinearVorgeplantBlockCommand(
        planungsobjekt,
      );
    return this.planungsobjektLinearApi.planungsobjektLinearVorgeplantBlockErstellen$(command);
  }

  createPlanungsobjektLinearVorgeplantBlockNewWindow$(
    command: PlanungsobjektLinearVorgeplantBlockErstellenCommand,
  ) {
    return this.planungsobjektLinearApi.planungsobjektLinearVorgeplantBlockErstellen$(command);
  }

  updatePlanungsobjektOnBlockansicht$(
    planungsobjekt: PlanungsobjektDto,
  ): Observable<PlanungsobjektLinearDtoResultDto> {
    const command =
      this.blockansichtMapper.mapPlanungsobjektToUpdatePlanungsobjektLinearVorgeplantBlockCommand(
        planungsobjekt,
      );
    return this.planungsobjektLinearApi.planungsobjektLinearVorgeplantBlockAktualisieren$(command);
  }

  updatePlanungsobjektOnBlockansichtNewWindow$(
    command: PlanungsobjektLinearVorgeplantBlockAktualisierenCommand,
  ): Observable<PlanungsobjektLinearDtoResultDto> {
    return this.planungsobjektLinearApi.planungsobjektLinearVorgeplantBlockAktualisieren$(command);
  }

  movePlanungsobjektToBlockansicht$(vm: PlanungsobjektDto) {
    const command =
      this.blockansichtMapper.mapPlanungsobjektToMovePlanungsobjektLinearToVorgeplantBlockCommand(
        vm,
      );
    return this.planungsobjektLinearApi.movePlanungsobjektLinearToVorgeplantBlock$(command);
  }

  movePlanungsobjekteToVorgeplantBlock$(
    command: MovePlanungsobjekteLinearToVorgeplantBlockCommand,
  ) {
    return this.planungsobjektLinearApi.movePlanungsobjekteLinearToVorgeplantBlock$(command);
  }

  planungsobjektLinearVorgeplantBlockKopieren$(
    body: PlanungsobjektLinearVorgeplantBlockKopierenCommand,
  ) {
    return this.planungsobjektLinearApi.planungsobjektLinearVorgeplantBlockKopieren$(body);
  }
}
