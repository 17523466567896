import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Icons } from "src/app/models/icons";
import { DateFnsService } from "src/app/services/date-fns.service";
import { AbstractControlValueAccessor } from "../abstract-control-value-accessor";

@Component({
  selector: "app-iso-timeonly",
  templateUrl: "./iso-timeonly.component.html",
  styleUrls: ["./iso-timeonly.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IsoTimeonlyComponent),
      multi: true,
    },
  ],
})
export class IsoTimeonlyComponent extends AbstractControlValueAccessor<string> {
  @Input({ transform: coerceBooleanProperty }) clearable = false;

  readonly Icons = Icons;

  internalValue: Date | null;

  onChange(value: Date | null) {
    this.internalValue = value;
    const _value = DateFnsService.formatDateAsTimeStringOrNull(value);
    this._onChange(_value);
  }

  writeValue(value: string | null): void {
    const date =
      value === null || value === "" ? null : DateFnsService.parseDateAndTimeToDateObject(value);
    this.internalValue = date;
  }
}
