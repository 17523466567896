import { Pipe, PipeTransform } from "@angular/core";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { MengengeruestViewModel } from "src/app/models/viewmodels/mengengeruest-viewmodel";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";

@Pipe({
  name: "zdfTitelBySchemaplatz",
})
export class ZdfTitelBySchemaplatzPipe implements PipeTransform {
  transform(singleMengengeruestTable: MengengeruestViewModel, ansichtViewModel: AnsichtViewModel) {
    return (
      KanalOffsetUtils.getSendewochentag(
        singleMengengeruestTable.schemaplatz.wochentag,
        singleMengengeruestTable.schemaplatz.beginnzeit,
        ansichtViewModel.kanal,
      ) +
      " " +
      singleMengengeruestTable.schemaplatz.beginnzeit.replace(":", ".")
    );
  }
}
