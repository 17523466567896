import { createSelector } from "@ngrx/store";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Layout } from "src/app/models/openapi/model/layout";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { Wochentag } from "src/app/models/openapi/model/wochentag";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { MengengeruestViewModel } from "src/app/models/viewmodels/mengengeruest-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import { groupBy } from "src/app/utils/array-utils";
import { cloneDate } from "src/app/utils/date-utils";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";
import { isDefined } from "src/app/utils/object-utils";
import {
  compareByIsBetweenMidnightToTagesgrenze,
  compareBySendewochentag,
  compareMengengeruestEintraege,
  compareSchemaplaetzeByBeginnzeit,
} from "src/app/utils/sort-utils";
import { mengengeruestEintragFeature } from "./mengengeruest.reducer";

// Gruppiere alle Mengengerüst Einträge nach AnsichtId und SchemaplatzId in Records
const selectGroupedMengengeruestEintraegeAsRecords = (ansichtViewModel: AnsichtViewModel) => {
  return createSelector(mengengeruestEintragFeature.selectAll, (mengengeruestEintraege) => {
    const schemaplaetzeRecords: Record<string, MengengeruesteintragDto[]> = {};
    ansichtViewModel.ansichtsdefinition.schemaplaetze.forEach((schemaplatz) => {
      schemaplaetzeRecords[schemaplatz.id] = mengengeruestEintraege
        .filter(
          (mengengeruesteintrag) =>
            mengengeruesteintrag.schemaplatzId === schemaplatz.id &&
            mengengeruesteintrag.ansichtId === ansichtViewModel.id,
        )
        .sort(compareMengengeruestEintraege(ansichtViewModel.kanal));
    });
    return schemaplaetzeRecords;
  });
};

/**
// Vor allem relevant für die Blockansicht, für die vorher alle Einträge in einer Mengengerüsttabelle gruppiert waren
// Gruppiert Mengengerüesteintrage nach Beginnzeit als eigene Tabellen und sortiert sie danach.
// Schemaplatz ist dabei immer der selbe für jedes MengengerüstViewModel
// https://jira.zdf.de/jira/browse/PUBLIT-1410
 */

const selectMengengeruestViewModelsByBeginnzeit = (ansichtViewModel: AnsichtViewModel) => {
  return createSelector(
    selectGroupedMengengeruestEintraegeAsRecords(ansichtViewModel),
    (mengengeruestEintraegeRecords) => {
      const [schemaplatz] = ansichtViewModel.ansichtsdefinition.schemaplaetze;
      if (!schemaplatz) {
        console.error(
          `Konnte keinen Schemaplatz für Ansicht mit Id ${ansichtViewModel.id} finden.`,
        );
        return [];
      } else if (!mengengeruestEintraegeRecords[schemaplatz.id]) {
        console.error(`Auf Basis des Schemaplatzes konnten keine Tabellen angelegt werden.`);
        return [];
      }

      // Sollte nur ein Record sein. Der ist aber schon korrekt nach Beginnzeit sortiert
      // -> Zerlege den Record in einzelne Records mit Beginnzeit als Key
      const mengengeruestEintrageRecordsByBeginnzeit = groupBy(
        mengengeruestEintraegeRecords[schemaplatz.id],
        (eintrag: MengengeruesteintragDto) =>
          eintrag.beginnzeit && eintrag.beginnzeit !== schemaplatz.beginnzeit // Schemaplatzbeginnzeit und leere Beginnzeit in einer Tabelle
            ? eintrag.beginnzeit
            : "",
      );

      const beginnzeiten = Object.keys(mengengeruestEintrageRecordsByBeginnzeit);

      if (beginnzeiten.length < 1) {
        // Keine Einträge -> Leeres MengengerüstViewModel zurückgeben. Passiert nichtmehr implizit, weil wir nach Beginnzeiten in Einträgen gruppieren.
        return [
          {
            titel: schemaplatz.titel,
            schemaplatz,
            mengengeruesteintraege: [],
            sendesollSumme: 0,
            sendeplanSumme: 0,
            betrachteteTageSumme: calculateRelevantDaysSum(ansichtViewModel, schemaplatz.wochentag),
          },
        ] satisfies MengengeruestViewModel[];
      }

      const mengengeruestViewModels: MengengeruestViewModel[] = beginnzeiten
        .map((beginnzeit) => {
          const sendesollSumme = mengengeruestEintrageRecordsByBeginnzeit[beginnzeit].reduce(
            (sum, current) => sum + current.sendesoll,
            0,
          );
          const sendeplanSumme = mengengeruestEintrageRecordsByBeginnzeit[beginnzeit].reduce(
            (sum, current) => sum + current.sendeplan,
            0,
          );

          // Leere Beginnzeit abfangen. In diesem Fall editieren wir nicht den Schemaplatz
          const hasDefaultBeginnzeit = beginnzeit === "" || beginnzeit === schemaplatz.beginnzeit;
          // Da die Beginnzeit nichtmehr aus dem Schemaplatzes gezogen wird, kann es sein das Wochentag und Beginnzeit nicht miteinander abgestimmt sind.
          // Beispiel: Schema-Wochtentag: Dienstag, Schema-Beginnzeit: 21:30, Gesetzte Beginnzeit: 01:00 -> Kalkulierter Wochentag muss Mittwoch sein
          const wochentag = hasDefaultBeginnzeit
            ? schemaplatz.wochentag
            : KanalOffsetUtils.calculateSchemaKalendertag(
                schemaplatz.wochentag,
                schemaplatz.beginnzeit,
                beginnzeit,
              );

          const mengengeruestViewModel: MengengeruestViewModel = {
            schemaplatz: hasDefaultBeginnzeit
              ? schemaplatz
              : { ...schemaplatz, beginnzeit, wochentag },
            mengengeruesteintraege: mengengeruestEintrageRecordsByBeginnzeit[beginnzeit],
            titel: schemaplatz.titel,
            sendesollSumme,
            sendeplanSumme,
            betrachteteTageSumme: calculateRelevantDaysSum(ansichtViewModel, wochentag),
          };

          return mengengeruestViewModel;
        })
        .filter(isDefined);
      return mengengeruestViewModels.sort(
        compareMengengeruestViewModels(
          ansichtViewModel.kanal,
          ansichtViewModel.ansichtsdefinition.layout,
        ),
      );
    },
  );
};

const selectMengengeruestViewModels = (ansichtViewModel: AnsichtViewModel) => {
  return createSelector(
    selectGroupedMengengeruestEintraegeAsRecords(ansichtViewModel),
    (mengengeruestEintraegeRecords) => {
      const mengengeruestViewModels: MengengeruestViewModel[] = Object.keys(
        mengengeruestEintraegeRecords,
      )
        .map((key) => {
          const schemaplatz = ansichtViewModel.ansichtsdefinition.schemaplaetze.find(
            // Funktioniert weil der Key die SchemaplatzId ist. Die Alternative ist auf den ersten Eintrag zu prüfen, was
            // fehlschlägt, wenn der erste Eintrag nicht existiert
            (schemaplatz) => schemaplatz.id === key,
          );
          if (!schemaplatz) {
            console.error(`Konnte keinen Schemaplatz mit Id ${key} finden.`);
            return null;
          }

          const sendesollSumme = mengengeruestEintraegeRecords[key].reduce(
            (sum, current) => sum + current.sendesoll,
            0,
          );
          const sendeplanSumme = mengengeruestEintraegeRecords[key].reduce(
            (sum, current) => sum + current.sendeplan,
            0,
          );

          const mengengeruestViewModel: MengengeruestViewModel = {
            schemaplatz,
            mengengeruesteintraege: mengengeruestEintraegeRecords[key],
            titel: schemaplatz.titel,
            sendesollSumme,
            sendeplanSumme,
            betrachteteTageSumme: calculateRelevantDaysSum(ansichtViewModel, schemaplatz.wochentag),
          };
          return mengengeruestViewModel;
        })
        .filter(isDefined);
      return mengengeruestViewModels.sort(
        compareMengengeruestViewModels(
          ansichtViewModel.kanal,
          ansichtViewModel.ansichtsdefinition.layout,
        ),
      );
    },
  );
};

export const compareMengengeruestViewModels =
  (kanal: Kanal, layout: Layout) => (a: MengengeruestViewModel, b: MengengeruestViewModel) => {
    const sendewochentagSortOrder = compareBySendewochentag(kanal, layout)(
      a.schemaplatz,
      b.schemaplatz,
    );
    if (sendewochentagSortOrder !== 0) {
      return sendewochentagSortOrder;
    }

    const isBetweenMidnightToTagesgrenzeSortOrder = compareByIsBetweenMidnightToTagesgrenze(kanal)(
      a.schemaplatz,
      b.schemaplatz,
    );
    if (isBetweenMidnightToTagesgrenzeSortOrder !== 0) {
      // Schemaplätze die zwischen 0:00 und Tagesgrenze liegen sollen immer zuletzt angezeigt werden
      // Bsp: 22:30, 23:00, 23:30, 0:30
      return isBetweenMidnightToTagesgrenzeSortOrder;
    }

    return compareSchemaplaetzeByBeginnzeit(a.schemaplatz, b.schemaplatz);
  };

function calculateRelevantDaysSum(
  ansichtViewModel: AnsichtViewModel,
  wochentag: Wochentag,
): number {
  let sumRelevantDays = 0;
  let startDate = cloneDate(ansichtViewModel.zeitraum.start);
  while (startDate <= ansichtViewModel.zeitraum.end) {
    // Ist das derzeitige startDatum ein für uns relevanter Wochentag?
    if (wochentag) {
      if (DateFnsService.tageChecker[wochentag](startDate)) {
        sumRelevantDays++;
      }
    }
    startDate = DateFnsService.addDays(startDate, 1);
  }
  return sumRelevantDays;
}

export default {
  selectMengengeruestViewModels,
  selectMengengeruestViewModelsByBeginnzeit,
};
