<div class="sidebar-selection-row" (click)="emitToggleShowKonkurrenzEvents()">
  <kendo-svg-icon
    class="sidebar-icon"
    data-testid="sidebar-ek"
    [icon]="Icons.ekIcon"
    [ngClass]="showKonkurrenzEvents ? 'icon-active' : 'icon-inactive'"
  ></kendo-svg-icon>
  E/K Spalten ein-/ausblenden
</div>
<div
  class="sidebar-selection-row"
  [ngClass]="{
    'selection-disabled': layout === Layout.KALENDER
  }"
  (click)="emitToggleShowNotizen()"
>
  <kendo-svg-icon
    class="sidebar-icon"
    data-testid="sidebar-notizen"
    [icon]="Icons.notizenIcon"
    [ngClass]="
      layout === Layout.KALENDER
        ? 'selection-disabled'
        : showNotizen
          ? 'icon-active'
          : 'icon-inactive'
    "
  ></kendo-svg-icon>
  Notizen ein-/ausblenden
</div>
