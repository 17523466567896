import { GetitProduktStatus } from "../openapi/model/getit-produkt-status";
import { EnumMetadata, enumRecordToSelectOptions } from "./enum-base";

export const ProduktStatusRecord: Record<GetitProduktStatus, EnumMetadata> = {
  Abgenommen: { bedeutung: "Abgenommen" },
  Ausgestrahlt: { bedeutung: "Ausgestrahlt" },
  GenehmigtProduktionsbewilligung: { bedeutung: "Genehmigt: Produktionsbewilligung" },
  GenehmigtStoffzulassung: { bedeutung: "Genehmigt: Stoffzulassung" },
  Inaktiv: { bedeutung: "Inaktiv" },
  InProduktion: { bedeutung: "InProduktion" },
  Offen: { bedeutung: "Offen" },
};

export const ProduktStatusOptions = enumRecordToSelectOptions(ProduktStatusRecord);
