import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject, first, shareReplay, startWith, takeUntil } from "rxjs";
import { ansichtFeature } from "src/app/core/stores/ansicht/ansicht.reducer";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { Icons } from "src/app/models/icons";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { ReportService } from "src/app/services/report.service";
import { getDashboardYear } from "src/app/utils/date-utils";

@Component({
  selector: "app-dashboard-zdf",
  templateUrl: "./dashboard-zdf.component.html",
  styleUrls: ["./dashboard-zdf.component.scss"],
})
export class DashboardZDFComponent implements AfterViewInit {
  private readonly destroy$ = new Subject<void>();
  readonly ansichtenViewModels$ = this.store.select(ansichtFeature.selectAll);
  readonly years$ = this.store.select(ansichtSelectors.selectAnsichtViewModelYearsZDF);
  @ViewChildren("years") yearAnchors: QueryList<ElementRef<HTMLDivElement>>;

  Kanal = Kanal;
  Icons = Icons;

  contextMenuItemsDashboard = [
    {
      text: "Mengengerüst",
      svgIcon: Icons.balance,
    },
  ];

  constructor(
    private router: Router,
    private store: Store,
    private reportService: ReportService,
  ) {}

  ngAfterViewInit(): void {
    this.yearAnchors.changes
      .pipe(
        startWith(undefined),
        shareReplay({ bufferSize: 1, refCount: true }),
        takeUntil(this.destroy$),
      )
      .subscribe((_) => {
        this.scrollToNextYear();
      });
  }

  private scrollToNextYear() {
    const yearToScrollTo = getDashboardYear();

    this.years$.pipe(first(), takeUntil(this.destroy$)).subscribe((years) => {
      if (!years.includes(yearToScrollTo)) {
        return;
      }

      const nextYearAnchor = this.yearAnchors.find(
        (el) => el.nativeElement.getAttribute("data-testid") === `year-${yearToScrollTo}`,
      );

      if (nextYearAnchor) {
        // Then scroll into view
        nextYearAnchor.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      // Setze den queryParameter in der Url nach dem Scrollvorgang, damit dieser für zukünftige Operationen z.B.
      // in der E&K Ansicht zur Verfügung steht
      void this.router.navigate([], {
        queryParams: { year: yearToScrollTo },
        queryParamsHandling: "merge",
        replaceUrl: true,
      });
    });
  }

  gotoAnsicht(year: number, title: string, ansichtenViewModels: AnsichtViewModel[] | null): void {
    const ansichtViewModel = ansichtenViewModels?.find(
      (vm) => vm.titel === title && vm.year === year,
    );

    if (ansichtViewModel) {
      void this.router.navigate(["ansichten"], {
        queryParams: { ansichtId: ansichtViewModel.id, year: year, kanal: Kanal.ZDF },
      });
    }
  }

  downloadMengengeruesteintraege(year: number) {
    this.reportService.getReportWithMengengeruesteintraegenByYear(Kanal.ZDF, year);
  }
}
