import { Injectable } from "@angular/core";
import {
  WindowCloseResult,
  WindowRef,
  WindowService,
  WindowSettings,
} from "@progress/kendo-angular-dialog";
import { BehaviorSubject, merge, takeUntil } from "rxjs";
import { CustomWindowRef } from "../shared/dialogs/dialog.model";
import { DebugWindowComponent } from "../shared/windows/debug-window/debug-window.component";
import {
  EKWindowComponent,
  EKWindowInput,
  EKWindowResult,
} from "../shared/windows/ek-window/ek-window.component";
import { InformationWindowComponent } from "../shared/windows/information-window/information-window.component";
import { MediumWindow, SmallWindow } from "../shared/windows/window.templates";

@Injectable({
  providedIn: "root",
})
export class CustomWindowService {
  private openWindowsSubject = new BehaviorSubject<WindowRef[]>([]);
  openWindows$ = this.openWindowsSubject.asObservable();

  constructor(private windowService: WindowService) {}

  /**
   * Öffnet den gemeinsamen Dialog für Konkurrenzprogramme und Events
   * @param isEvent
   * @param konkurrenzEvent
   */
  openKonkurrenzWindow(input: EKWindowInput) {
    const windowRef: CustomWindowRef<EKWindowComponent, EKWindowResult> = this.open({
      content: EKWindowComponent,
      title: "Event / Konkurrenzprogramm",
      ...SmallWindow(),
    });

    windowRef.content.instance.data = input;
    return windowRef;
  }

  open<T, U extends WindowCloseResult = WindowCloseResult>(
    settings: WindowSettings,
  ): CustomWindowRef<T, U> {
    const windowRef = this.windowService.open(settings);
    this.subscribeToWindowChanges(windowRef);
    this.addNewOpenWindow(windowRef);
    windowRef.window.onDestroy(() => {
      this.removeOpenWindow(windowRef);
    });
    return windowRef;
  }

  /**
   * Subscribt sich auf Veränderungen des geöffneten Fensters und setzt diese ggf. zurück.
   * @param windowRef
   */
  private subscribeToWindowChanges(windowRef: WindowRef) {
    const windowContainer = windowRef.window.instance;
    const initialWinDimensions = {
      top: windowContainer.top,
      left: windowContainer.left,
      bottom: window.innerHeight,
      right: window.innerWidth,
      height: windowContainer.height,
      width: windowContainer.width,
    };
    const windowChanges$ = merge(windowContainer.dragEnd, windowContainer.resizeEnd);
    windowChanges$.pipe(takeUntil(windowRef.result)).subscribe({
      next: () => {
        if (windowContainer.top < 0) windowContainer.setOffset("top", initialWinDimensions.top);
        if (windowContainer.left < 0) windowContainer.setOffset("left", initialWinDimensions.left);

        if (initialWinDimensions.bottom < windowContainer.top + initialWinDimensions.top)
          windowContainer.setOffset("top", initialWinDimensions.top);
        if (initialWinDimensions.right < windowContainer.left + initialWinDimensions.left)
          windowContainer.setOffset("left", initialWinDimensions.left);

        if (windowContainer.width < initialWinDimensions.width)
          windowContainer.setDimension("width", initialWinDimensions.width);
        if (windowContainer.height < initialWinDimensions.height)
          windowContainer.setDimension("width", initialWinDimensions.height);
      },
    });
  }

  openVersionWindow() {
    this.open({
      content: InformationWindowComponent,
      title: "Informationen",
      ...SmallWindow(),
    });
  }

  openDebugWindow() {
    this.open({
      content: DebugWindowComponent,
      title: "🐛 Debug",
      ...MediumWindow(),
    });
  }

  addNewOpenWindow(newWindow: WindowRef) {
    this.openWindowsSubject.next([...this.openWindowsSubject.value, newWindow]);
  }

  removeOpenWindow(window: WindowRef) {
    const filteredWindows = this.openWindowsSubject.value.filter(
      (old) => old.window !== window.window,
    );
    this.openWindowsSubject.next(filteredWindows);
  }
}
