import { Component, EventEmitter, Input, Output, TrackByFunction } from "@angular/core";
import { SVGIcon } from "@progress/kendo-svg-icons";
import { GUID } from "src/app/models";
import { Icons } from "src/app/models/icons";
import { BenachrichtigungDto } from "src/app/models/openapi/model/benachrichtigung-dto";
import { BenachrichtigungVm } from "src/app/models/viewmodels/benachrichtigung-viewmodel";

@Component({
  selector: "app-benachrichtigung-kategorie",
  templateUrl: "./benachrichtigung-kategorie.component.html",
  styleUrls: ["./benachrichtigung-kategorie.component.scss"],
})
export class BenachrichtigungKategorieComponent {
  @Input({ required: true }) benachrichtigungen: BenachrichtigungVm[];
  @Input({ required: true }) iconTitle: string;
  @Input({ required: true }) icon: SVGIcon;
  @Output() removeBenachrichtigungenEvent = new EventEmitter<GUID[]>();
  Icons = Icons;

  trackById: TrackByFunction<BenachrichtigungVm> = (_index, benachrichtigung) =>
    benachrichtigung.id;

  removeBenachrichtigungen(benachrichtigungen: BenachrichtigungDto[]) {
    this.removeBenachrichtigungenEvent.emit(
      benachrichtigungen.map((benachrichtigung) => benachrichtigung.id),
    );
  }
}
