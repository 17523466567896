import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { debounceTime, filter, map, ReplaySubject, withLatestFrom } from "rxjs";
import planungsobjektWindowWannWoSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window-wann-wo.selectors";
import { calculateEndzeitValue } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window-wann-wo.utils";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import { ContentCommunity } from "src/app/models/openapi/model/content-community";
import { FSKEinstufung } from "src/app/models/openapi/model/fsk-einstufung";
import { Genre } from "src/app/models/openapi/model/genre";
import { GetitProduktStatus } from "src/app/models/openapi/model/getit-produkt-status";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektFarbgebung } from "src/app/models/openapi/model/planungsobjekt-farbgebung";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { StofffuehrendeRedaktion } from "src/app/models/openapi/model/stofffuehrende-redaktion";
import { assertUnreachable } from "src/app/utils/function-utils";
import { isDefined } from "src/app/utils/object-utils";
import { CustomValidators } from "../../form-inputs/custom-validators";
import { maxSecondsForMaskedInput } from "../../form-inputs/masked-input/masked-input.component";
import {
  PlanungsobjektWindowInput,
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "./planungsobjekt-window.model";

/**
 * Controls, die für die Planungsinhalte sowohl für OnDemand als auch Linear gelten. (FIXME @Dana korrekt?)
 */
const planungsinhalteControls = {
  titel: ["", [Validators.required, CustomValidators.minLengthTrimmed(2)]],
  redaktion: [null as Redaktion | null],
  genre: [Genre.KEIN_GENRE],
  notiz: [""],
  highlight: [false],
  contentCommunities: [[] as ContentCommunity[]],
  farbgebung: [PlanungsobjektFarbgebung.KEINE],
  fruehesteVeroeffentlichung: [null as string | null],
  stofffuehrendeRedaktion: [null as StofffuehrendeRedaktion | null],
  fsk: [null as FSKEinstufung | null],
  staffelnummer: [null as number | null],
  folgennummer: [null as number | null],
  gesamtfolgennummer: [
    null as string | null,
    CustomValidators.patternWithCustomError(
      /^(?:\d+(?:\.\d+)?)?$/,
      "Gültiges Eingabeformat: X oder X.X (X=Ziffer)",
    ),
  ],
  inhaltsbeschreibung: [null as string | null],
  mitwirkende: [null as string | null],
};

/**
 * Wann-Bezug Controls, die für alle UseCases gelten.
 */
const wannBezugAllgemeinControls = {
  planlaenge: [
    null as number | null,
    [Validators.min(1), Validators.max(maxSecondsForMaskedInput)],
  ],
};

/**
 * Wann-Bezug Controls, die nur für OnDemand gelten.
 */
const wannBezugOnDemandControls = {
  onlineAb: [null as string | null, CustomValidators.valueBefore("onlineBis", "Online bis")],
  onlineAbZeit: [null as string | null],
  onlineBis: [null as string | null, CustomValidators.valueAfter("onlineAb", "Online ab")],
};

/**
 * Wann-Bezug-Controls, die nur für Linear gelten.
 */
const wannBezugLinearControls = {
  sendetag: [null as string | null],
  beginnzeit: [null as string | null],
  endzeit: [null as string | null],
  variante: [null as number | null],
};

/**
 * Uhrzeit, die als Default für das Feld "Online ab Zeit" gesetzt wird, sofern das Planungsobjekt neu erstellt wird.
 */
const DEFAULT_ONLINE_AB_ZEIT = "10:00";

/**
 * GetIt-Controls, die zwar nicht veränderbar sind, aber dennoch wichtig für ngrx sind.
 * So wird beim Verknüpfen eines Planungsobjekts mit Getit die Form nicht mehr dirty
 */
const getItInfoControls = {
  getitId: [null as string | null],
  letzterGetitSync: [null as string | null],
  produktstatus: [null as GetitProduktStatus | null],
  produkttitel: [null as string | null],
  produkttitelMultipart: [null as string | null],
};

@Injectable()
export class PlanungsobjektWindowPlanungFormService {
  private readonly fb = inject(FormBuilder);
  private readonly store = inject(Store);
  private readonly destroyRef = inject(DestroyRef);

  private formForUseCaseSubject = new ReplaySubject<ReturnType<typeof this.createFormForUseCase>>(
    1,
  );
  readonly form$ = this.formForUseCaseSubject.asObservable();
  private form: ReturnType<typeof this.createFormForUseCase>;

  initialize(windowInput: PlanungsobjektWindowInputWithPlanungsobjekt) {
    const form = this.createFormForUseCase(windowInput);
    this.form = form;
    this.formForUseCaseSubject.next(form);

    this.store.dispatch(
      planungsobjektWindowActions.setPlanungsobjektWindowPlanungForm({
        formValue: this.form.getRawValue(),
        isPlanungFormDirty: false,
      }),
    );
  }

  canSave() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }
    return true;
  }

  private createFormForUseCase(windowInput: PlanungsobjektWindowInputWithPlanungsobjekt) {
    const form = this.fb.group({
      ...planungsinhalteControls,
      ...wannBezugAllgemeinControls,
      ...wannBezugOnDemandControls,
      ...wannBezugLinearControls,
      ...getItInfoControls,
    });

    const isVorschlag = windowInput.planungskontext === Planungskontext.VORGESCHLAGEN;
    const isVorgeplant = windowInput.planungskontext === Planungskontext.VORGEPLANT;

    this.setFormValidators(form, windowInput, isVorschlag, isVorgeplant);
    this.presetFormValue(form, windowInput);
    this.setupVariantenSubscription(form, windowInput);
    this.setupPlanlaengenSyncSubscription(form, windowInput);
    this.setupOnDemandPropertiesSyncSubscription(form, windowInput);
    this.setupFormToStoreSyncSubscription(form);
    this.setupStoreToFormSyncSubscription(form);

    return form;
  }

  /**
   * Subscribed auf die Planlängen-Änderungen, um die Formularfelder zu aktualisieren.
   */
  setupPlanlaengenSyncSubscription(
    form: ReturnType<typeof this.createFormForUseCase>,
    windowInput: PlanungsobjektWindowInput,
  ) {
    // Planlängen relevante Updates (ehemals Planlängenhelper-Updates) und Cross-Field-Validation
    if (
      windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ ||
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
      windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT
    ) {
      this.store
        .select(planungsobjektWindowWannWoSelectors.selectPlanlaengenSyncProperties)
        .pipe(
          filter(isDefined),
          filter((storeValue) => {
            const { sendetag, beginnzeit, endzeit, planlaenge } = form.getRawValue();
            return (
              sendetag !== storeValue.sendetag ||
              beginnzeit !== storeValue.beginnzeit ||
              endzeit !== storeValue.endzeit ||
              planlaenge !== storeValue.planlaenge
            );
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((props) => {
          form.patchValue(props);
        });
    }
  }

  /**
   * Subscribed auf die OnDemand-Änderungen auf dem Verlinkungstab, um diese auf dem Planungstab anzugleichen.
   */
  setupOnDemandPropertiesSyncSubscription(
    form: ReturnType<typeof this.createFormForUseCase>,
    windowInput: PlanungsobjektWindowInput,
  ) {
    // Planlängen relevante Updates (ehemals Planlängenhelper-Updates) und Cross-Field-Validation
    if (
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND ||
      windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_ONDEMAND
    ) {
      this.store
        .select(planungsobjektWindowFeature.selectFormValue)
        .pipe(
          filter(isDefined),
          filter((storeValue) => {
            const { onlineAb, onlineAbZeit, onlineBis } = form.getRawValue();
            return (
              onlineAb !== storeValue.onlineAb ||
              onlineAbZeit !== storeValue.onlineAbZeit ||
              onlineBis !== storeValue.onlineBis
            );
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((props) => {
          form.patchValue(props);
        });
    }
  }

  /**
   * Subscribed auf die Varianten-Änderungen auf Blockansichten, um die Formularfelder zu aktualisieren.
   */
  setupVariantenSubscription(
    form: ReturnType<typeof this.createFormForUseCase>,
    windowInput: PlanungsobjektWindowInput,
  ) {
    // Blockansicht-spezifisches Verhalten
    if (
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
      windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT
    ) {
      this.store
        .select(planungsobjektWindowWannWoSelectors.selectAvailableVariant)
        .pipe(
          filter(
            (availableVariant): availableVariant is number | null =>
              availableVariant !== undefined && availableVariant !== form.controls.variante.value,
          ),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((variante) => {
          form.controls.variante.patchValue(variante);
          form.controls.variante.markAsTouched();
        });
    }
  }

  /**
   * Subscribed auf die Formularänderungen, um die Werte im Store zu speichern.
   */
  setupFormToStoreSyncSubscription(form: typeof this.form) {
    form.valueChanges
      .pipe(
        // wir machen hier ein debounceTime, damit wir nicht in einer Endlosschleife landen
        // Sonst kann es passieren, dass eine Action einen Selector triggert, der eine Action trigger, der einen Selector triggert, ...
        debounceTime(0),
        // es gibt aktuell Validatoren, die `updateValueAndValidity` aufrufen, wenn das Formular noch pristine ist
        // Das Ausführen der Validatoren soll jedoch das Formular nicht initial als "dirty" im Store markieren,
        // was als Resultat der `setPlanungsobjektWindowPlanungForm` Aktion passiert
        filter(() => !form.pristine),
        withLatestFrom(this.store.select(planungsobjektWindowFeature.selectFormValue)),
        filter(
          ([_, valueFromStore]) =>
            JSON.stringify(form.getRawValue()) !== JSON.stringify(valueFromStore),
        ),
        map(() => form.getRawValue()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((formValue) => {
        this.store.dispatch(
          planungsobjektWindowActions.setPlanungsobjektWindowPlanungForm({
            formValue,
          }),
        );
      });
  }

  /**
   * Aktualisiert das Form, wenn der Store aktualisiert wird.
   */
  setupStoreToFormSyncSubscription(form: typeof this.form) {
    this.store
      .select(planungsobjektWindowFeature.selectFormValue)
      .pipe(
        // wir machen hier ein debounceTime, damit wir nicht in einer Endlosschleife landen
        // Sonst kann es passieren, dass eine Action einen Selector triggert, der eine Action trigger, der einen Selector triggert, ...
        debounceTime(0),
        filter(isDefined),
        // @todo wir brauchen mal ein echtes DeepEqual...
        filter((formValue) => JSON.stringify(form.getRawValue()) !== JSON.stringify(formValue)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((formValue) => {
        this.form.patchValue(formValue, { emitEvent: false, onlySelf: true });
      });
  }

  /**
   * Belegt die Werte der Formularfelder vor, abhängig vom UseCase.
   */
  presetFormValue(
    form: ReturnType<typeof this.createFormForUseCase>,
    windowInput: PlanungsobjektWindowInputWithPlanungsobjekt,
  ) {
    const newFormValue: Partial<typeof form.value> = {
      ...windowInput.planungsobjekt,
    };
    // Befüllen oder Vorbelegen von Feldern
    // ...wenn das Planungsobjekt editiert wird
    if (
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND ||
      windowInput.usecase === PlanungsobjektWindowUseCase.READONLY_ONDEMAND ||
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
      windowInput.usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR
    ) {
      // ...wenn das Planungsobjekt über die + Logik auf dem Sendeplatz erstellt wurde ist die Planlänge null,
      // daher wird die Planlänge aus der SendeplatzLänge der Publikationsplanung übernommen
      if (
        !newFormValue.planlaenge &&
        windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ
      ) {
        newFormValue.planlaenge = windowInput.planungsobjekt.sendeplatzLaenge ?? null;
      }
      // ...wenn Planlänge und Beginnzeit existiert, wird die Endzeit automatisch berechnet
      const planlaenge = newFormValue.planlaenge;
      const beginnzeit = newFormValue.beginnzeit;
      if (planlaenge && beginnzeit)
        newFormValue.endzeit = calculateEndzeitValue(planlaenge, beginnzeit) ?? null;
      // ...oder die default OnlineAb Zeit für Create_OnDemand
    } else if (windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_ONDEMAND) {
      newFormValue.onlineAbZeit = DEFAULT_ONLINE_AB_ZEIT;
      // ... oder Vorbelegung beim Erstellen auf Blockansichten -> Zukünftig auch für Listenansichten
    } else if (
      windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
      windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ
    ) {
      Object.assign(newFormValue, windowInput.wannPreset);
    } else {
      assertUnreachable(windowInput);
    }
    form.patchValue(newFormValue);
  }

  /**
   * Setzt die Validatoren für die Formularfelder abhängig vom UseCase und Planungskontext.
   */
  setFormValidators(
    form: ReturnType<typeof this.createFormForUseCase>,
    windowInput: PlanungsobjektWindowInput,
    isVorschlag: boolean,
    isVorgeplant: boolean,
  ) {
    form.controls.redaktion.addValidators(isVorschlag ? [Validators.required] : []);

    //Wann-Bezug OnDemand Pflichtfelder
    if (
      windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_ONDEMAND ||
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
    ) {
      form.controls.onlineAb.addValidators(
        isVorschlag || isVorgeplant ? [Validators.required] : [],
      );
      form.controls.onlineBis.addValidators(
        isVorschlag || isVorgeplant ? [Validators.required] : [],
      );
    }
    // Wann-Bezug Linear Pflichtfelder: Sendetag, Beginnzeit, Endzeit, Planlaenge
    else if (
      windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
      windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT
    ) {
      // Sind auf Blockansichten editierbar und required
      form.controls.sendetag.addValidators(
        isVorschlag || isVorgeplant ? [Validators.required] : [],
      );
      form.controls.beginnzeit.addValidators(
        isVorschlag || isVorgeplant ? [Validators.required] : [],
      );
      form.controls.endzeit.addValidators(isVorschlag || isVorgeplant ? [Validators.required] : []);
      form.controls.variante.addValidators(isVorgeplant ? [Validators.required] : []);
      form.addValidators(
        CustomValidators.planungsobjektBlockansichtInTimeRange(
          windowInput.blockansichtDefinition,
          windowInput.blockansichtDefinitionen,
          "sendetag",
          "beginnzeit",
          "planlaenge",
        ),
      );
      form.addValidators(
        CustomValidators.endzeitVorBeginnzeitMitSendetagsgrenze(
          "beginnzeit",
          "endzeit",
          windowInput.blockansichtDefinition.ansichtViewModel.kanal,
        ),
      );
    } else {
      // Die Felder sollten nur auf Blockansichten editiert werden können. Sind also nicht Teil der Validation
      form.controls.sendetag.disable();
      form.controls.beginnzeit.disable();
      form.controls.endzeit.disable();
    }

    form.controls.planlaenge.addValidators(
      (windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ ||
        windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
        windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
        windowInput.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT) &&
        (isVorschlag || isVorgeplant)
        ? [Validators.required]
        : [],
    );
  }
}
