import { ContentCommunity } from "src/app/models/openapi/model/content-community";
import { Genre } from "src/app/models/openapi/model/genre";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { DateFnsService } from "src/app/services/date-fns.service";
import { asArray, asSingleValueOrNull } from "src/app/utils/array-utils";
import { toEnumArray } from "src/app/utils/enum-utils";
import { entriesOf } from "src/app/utils/object-utils";
import {
  FilterEnum,
  GridColumnProperties,
  GridResultColumnFields,
  RechercheGridFormattingOptionsVM,
  RechercheGridResultColumn,
  RechercheSearchQueryVM,
} from "./recherche.model";
import { RechercheState } from "./recherche.reducer";

export const extractRecherchePageQueryParamsFromUrlParams = (params: {
  [key: string]: string | string[];
}): RechercheSearchQueryVM | null => {
  const sendetagVon = asSingleValueOrNull(params.sendetagVon);
  const sendetagBis = asSingleValueOrNull(params.sendetagBis);
  const kanaele = asArray(params.kanaele);
  const redaktionen = asArray(params.redaktionen);
  const highlightsOnly = params.highlightsOnly === "true" ? true : false;
  const genres = asArray(params.genres);
  const planungskontext = asArray(params.planungskontext);
  const titel = asSingleValueOrNull(params.titel) ?? "";
  const contentCommunities = asArray(params.contentCommunities);

  if (!sendetagVon || !sendetagBis) return null;

  if (
    [
      sendetagBis,
      sendetagVon,
      kanaele,
      redaktionen,
      highlightsOnly,
      genres,
      planungskontext,
      titel,
    ].every((el) => el === null || (Array.isArray(el) && el.length === 0))
  )
    return null;

  const queryParamsByUrl: RechercheSearchQueryVM = {
    sendetagBis: DateFnsService.parseStringToDate(sendetagBis),
    sendetagVon: DateFnsService.parseStringToDate(sendetagVon),
    kanaele: kanaele ? toEnumArray(kanaele, Object.values(Kanal)) : [],
    redaktionen: toEnumArray(redaktionen, Object.values(Redaktion)),
    highlightsOnly,
    genres: toEnumArray(genres, Object.values(Genre)),
    planungskontext: toEnumArray(planungskontext, Object.values(Planungskontext)),
    titel,
    contentCommunities: toEnumArray(contentCommunities, Object.values(ContentCommunity)),
  };
  return queryParamsByUrl;
};

export const extractRechercheGridFormattingOptionsVMFromParams = (params: {
  [key: string]: string | string[];
}): RechercheGridFormattingOptionsVM => {
  const shownColumns = toEnumArray(params.shownColumns, Object.values(GridResultColumnFields));

  return {
    shownColumns,
  };
};

export const getQueryFromState = (state: RechercheState): RechercheSearchQueryVM | null =>
  !state.von || !state.bis || state.kanaeleSelected.length < 1
    ? null
    : {
        sendetagVon: state.von,
        sendetagBis: state.bis,
        kanaele: state.kanaeleSelected,
        redaktionen: state.redaktionenSelected,
        highlightsOnly: state.highlightsOnly,
        planungskontext: state.planungskontexteSelected,
        titel: state.titelFilter,
        genres: state.genreSelected,
        contentCommunities: state.contentCommunitiesSelected,
      };

/**
 * Prüft anhand der Query welche Ergebnisspalten zusätzlich angezeigt werden sollen.
 *
 * Folgende Fälle werden berücksichtigt:
 * - Redaktionen, Genres und Planungskontexte: Wenn mehr als ein Wert ausgewählt
 * wurde, wird die jeweilige Spalte angezeigt.
 * - ZDF Mediathek: Wenn der Kanal ZDF Mediathek ausgewählt wurde, werden die Spalten
 * "Online ab", "Online ab Zeit" und "Online bis" angezeigt.
 *
 * Das Togglen wird nur für die initiale Suche durchgeführt, da die Spaltenauswahl sonst
 * bei jeder Änderung der Suchparameter zurückgesetzt wird.
 *
 * @param firstChange Gibt an, ob es sich um die erste Suche handelt.
 * @param searchQueryVM Die Query, anhand derer die Spalten bestimmt werden.
 * @returns Die zusätzlich anzuzeigenden Spalten.
 */
export function determineToggledColumnsFromQuery(
  firstChange: boolean,
  searchQueryVM: RechercheSearchQueryVM,
): RechercheGridResultColumn[] {
  const additionalColumnsToShow: RechercheGridResultColumn[] = [];
  if (!firstChange) return additionalColumnsToShow;

  for (const [key, queryValue] of entriesOf(searchQueryVM)) {
    // Wenn eine Multiselect mit mehr als einem Wert befüllt wird, dann soll zusätzlich die Spalte in den Ergebnissen
    // angezeigt werden
    if (Array.isArray(queryValue) && queryValue.length > 1) {
      // "genres" und "redaktionen" existieren in den GridResultColumnFields anders als in der URL
      // nur als "genre" und "redaktion"
      switch (key) {
        case "genres":
          additionalColumnsToShow.push("genre");
          break;
        case "redaktionen":
          additionalColumnsToShow.push("redaktion");
          break;
        case "planungskontext":
          additionalColumnsToShow.push("planungskontext");
          break;
        case "contentCommunities":
          additionalColumnsToShow.push("contentCommunities");
      }
    }

    additionalColumnsToShow.push(GridResultColumnFields.KANAL);

    if (key === "kanaele" && containsLinearAusspielweg(queryValue)) {
      additionalColumnsToShow.push(
        GridResultColumnFields.SENDETAG,
        GridResultColumnFields.ZEIT,
        GridResultColumnFields.TITEL,
      );
    }
    if (key === "kanaele" && containsOnDemandAusspielweg(queryValue)) {
      additionalColumnsToShow.push(
        GridResultColumnFields.ONLINEAB,
        GridResultColumnFields.ONLINEABZEIT,
        GridResultColumnFields.ONLINEBIS,
        GridResultColumnFields.TITEL,
      );
    }
  }
  return Array.from(new Set(additionalColumnsToShow));
}

/**
 * Prüft anhand der Query, welche zusätzlichen Filter aktiv sind.
 * @param query Die Query, anhand derer die aktiven Filter bestimmt werden.
 * @returns Die aktiven Filter.
 */
export function determineActiveAdditionalFiltersFromQuery(
  query: RechercheSearchQueryVM,
): FilterEnum[] {
  const activeFilters: FilterEnum[] = [];
  if (query.genres?.length) activeFilters.push(FilterEnum.GENRE);
  if (query.planungskontext?.length) activeFilters.push(FilterEnum.PLANUNGSKONTEXT);
  if (query.titel) activeFilters.push(FilterEnum.TITEL);
  return activeFilters;
}

const lineareKanaele = [Kanal.ZDF, Kanal.ZDF_NEO];
const onDemandKaenaele = [Kanal.ZDF_MEDIATHEK];
const columnsToAlwaysShow: RechercheGridResultColumn[] = [
  GridResultColumnFields.KANAL,
  GridResultColumnFields.TITEL,
];

export const containsLinearAusspielweg = (kanaeleSelected: Kanal[]): boolean =>
  kanaeleSelected.some((k) => lineareKanaele.includes(k));
export const containsOnDemandAusspielweg = (kanaeleSelected: Kanal[]): boolean =>
  kanaeleSelected.some((k) => onDemandKaenaele.includes(k));

export function rechercheHiddenConditions(
  shownColumns: RechercheGridResultColumn[],
  column: RechercheGridResultColumn,
  kanaeleSelected: Kanal[],
): boolean {
  // Diese sollten niemals hidden sein.
  if (columnsToAlwaysShow.includes(column)) {
    return false;
  }

  if (containsLinearAusspielweg(kanaeleSelected)) {
    if (column === GridResultColumnFields.SENDETAG) {
      return false;
    }
  }

  // Diese Suche beinhaltet mind. 1 onDemand Ausspielweg
  if (containsOnDemandAusspielweg(kanaeleSelected)) {
    if (column === GridResultColumnFields.ONLINEAB) {
      return false;
    }
  }

  // Alle anderen Spalten werden angezeigt, wenn sie aktiviert sind
  return shownColumns.find((c) => c === column) === undefined;
}

export function rechercheCheckDisabledConditions(
  column: RechercheGridResultColumn,
  kanaeleSelected: Kanal[],
): boolean {
  // Diese lassen sich niemals deaktivieren.
  if (columnsToAlwaysShow.includes(column)) {
    return true;
  }

  if (containsLinearAusspielweg(kanaeleSelected)) {
    if (column === GridResultColumnFields.SENDETAG) {
      return true;
    }
  }

  if (containsOnDemandAusspielweg(kanaeleSelected)) {
    if (column === GridResultColumnFields.ONLINEAB) {
      return true;
    }
  }

  return false;
}

export function sortColumnsByFieldOrder(
  columns: GridColumnProperties[],
  fieldOrder: RechercheGridResultColumn[],
): GridColumnProperties[] {
  const fieldOrderMap = new Map<RechercheGridResultColumn, number>();
  fieldOrder.forEach((field, index) => {
    fieldOrderMap.set(field, index);
  });

  return columns.slice().sort((a, b) => {
    const indexA = fieldOrderMap.get(a.field);
    const indexB = fieldOrderMap.get(b.field);

    if (indexA !== undefined && indexB !== undefined) {
      return indexA - indexB;
    }

    if (indexA !== undefined) {
      return -1;
    }

    if (indexB !== undefined) {
      return 1;
    }

    return 0;
  });
}

/**
 * Fügt die Planungsobjekte aus upsert zu current hinzu. Falls ein Planungsobjekt bereits in current existiert, wird es
 * in Place ersetzt und nicht hinten angehängt, damit die Sortierung erhalten bleibt.
 */
export function upsertManyForPlanungsobjekte(
  current: PlanungsobjekteDto,
  upsert: PlanungsobjekteDto,
): PlanungsobjekteDto {
  const linear = [...current.linear];
  const onDemand = [...current.onDemand];

  for (const linearElement of upsert.linear) {
    const existingIndex = linear.findIndex(
      (currentElement) => currentElement.id === linearElement.id,
    );
    if (existingIndex !== -1) {
      linear[existingIndex] = linearElement;
    } else {
      linear.push(linearElement);
    }
  }

  for (const onDemandElement of upsert.onDemand) {
    const existingIndex = onDemand.findIndex(
      (currentElement) => currentElement.id === onDemandElement.id,
    );
    if (existingIndex !== -1) {
      onDemand[existingIndex] = onDemandElement;
    } else {
      onDemand.push(onDemandElement);
    }
  }

  return { linear, onDemand };
}
