import { FormControl, FormGroup } from "@angular/forms";
import { FarbgebungObject } from ".";
import { enumFarbgebungToSelectOptions } from "../enums/enum-base";
import { ContentCommunity } from "../openapi/model/content-community";
import { FSKEinstufung } from "../openapi/model/fsk-einstufung";
import { Genre } from "../openapi/model/genre";
import { PlanungsobjektDto } from "../openapi/model/planungsobjekt-dto";
import { PlanungsobjektFarbgebung } from "../openapi/model/planungsobjekt-farbgebung";
import { Redaktion } from "../openapi/model/redaktion";
import { SendeplatzDto } from "../openapi/model/sendeplatz-dto";
import { StofffuehrendeRedaktion } from "../openapi/model/stofffuehrende-redaktion";

export const planungsobjektFarbgebungMap: Record<
  PlanungsobjektFarbgebung,
  FarbgebungObject<PlanungsobjektFarbgebung>
> = {
  Keine: {
    enum: PlanungsobjektFarbgebung.KEINE,
    name: "keine",
    background: "#272C31",
    color: "white",
    borderColor: "#85888C",
  },
  Grau: {
    enum: PlanungsobjektFarbgebung.GRAU,
    name: "grau",
    background: "#85888C",
    color: "white",
    borderColor: "#F3F3F3",
  },
  Gruen: {
    enum: PlanungsobjektFarbgebung.GRUEN,
    name: "grün",
    background: "#D1F59A",
    color: "black",
    borderColor: "#5DD701",
  },
  Gelb: {
    enum: PlanungsobjektFarbgebung.GELB,
    name: "gelb",
    background: "#FFFFCC",
    color: "black",
    borderColor: "#F6C403",
  },
  Lila: {
    enum: PlanungsobjektFarbgebung.LILA,
    name: "lila",
    background: "#C19AB7",
    color: "black",
    borderColor: "#9A088B",
  },
  Blau: {
    enum: PlanungsobjektFarbgebung.BLAU,
    name: "hellblau",
    background: "#ADEBFF",
    color: "black",
    borderColor: "#0857DF",
  },
  Rosa: {
    enum: PlanungsobjektFarbgebung.ROSA,
    name: "rosa",
    background: "#F391A3",
    color: "white",
    borderColor: "#CD235F",
  },
  Orange: {
    enum: PlanungsobjektFarbgebung.ORANGE,
    name: "orange",
    background: "#FA7D19",
    color: "white",
    borderColor: "#BA0005",
  },
  Petrol: {
    enum: PlanungsobjektFarbgebung.PETROL,
    name: "petrol",
    background: "#006666",
    color: "white",
    borderColor: "#9FFFD0",
  },
  Dunkelblau: {
    enum: PlanungsobjektFarbgebung.DUNKELBLAU,
    name: "dunkelblau",
    background: "#192F6A",
    color: "white",
    borderColor: "#00E6F5",
  },
};

export const PlanungsobjektFarbenOptions = enumFarbgebungToSelectOptions(
  planungsobjektFarbgebungMap,
);

export interface PlanungsobjektMoveViewModel {
  planungsobjekt: PlanungsobjektDto;
  newSendeplatz: SendeplatzDto;
  oldSendeplatz?: SendeplatzDto;
}

export type PlanungsobjektOnSendeplatzFormGroup = FormGroup<{
  titel: FormControl<string>;
  redaktion: FormControl<Redaktion | null>;
  planlaenge: FormControl<number | null>;
  genre: FormControl<Genre | null>;
  notiz: FormControl<string>;
  highlight: FormControl<boolean>;
  contentCommunities: FormControl<Array<ContentCommunity>>;
  farbgebung: FormControl<PlanungsobjektFarbgebung>;
  stofffuehrendeRedaktion: FormControl<StofffuehrendeRedaktion | null>;
  fsk: FormControl<FSKEinstufung | null>;
  folgennummer: FormControl<number | null>;
  staffelnummer: FormControl<number | null>;
  gesamtfolgennummer: FormControl<string | null>;
  inhaltsbeschreibung: FormControl<string | null>;
  mitwirkende: FormControl<string | null>;
  fruehesteVeroeffentlichung: FormControl<string | null>;
}>;
