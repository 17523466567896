import { DestroyRef, Injectable, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { ReplaySubject, debounceTime, filter } from "rxjs";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { CustomValidators } from "src/app/shared/form-inputs/custom-validators";
import { hasDefinedValues, isDefined } from "src/app/utils/object-utils";
import { PlanungsobjektWindowInput } from "../planungsobjekt-window.model";
import { onDemandBeziehungFormActions } from "./on-demand-beziehung-form/on-demand-beziehung-form.actions";

const onDemandBeziehungControls = {
  wunschOnlineAb: [
    null as string | null,
    CustomValidators.valueBefore("wunschOnlineBis", "Online bis"),
  ],
  onlineAbZeit: [null as string | null],
  wunschOnlineBis: [
    null as string | null,
    CustomValidators.valueAfter("wunschOnlineAb", "Online ab"),
  ],
  reihenfolgeHerstellen: [false],
  relationZuLinearInTagen: [
    null as number | null,
    [
      CustomValidators.valueBeforeWithReferenceKey(
        "wunschOnlineBis",
        "Wunsch online bis",
        "wunschOnlineAb",
      ),
      CustomValidators.valueBefore("maxDistanz", "spätestens"),
      CustomValidators.valueAfter("minDistanz", "frühestens"),
    ],
  ],
  minDistanz: [
    null as number | null,
    [
      CustomValidators.valueBefore("maxDistanz", "spätestens"),
      CustomValidators.valueBefore("relationZuLinearInTagen", "Relativ zu linear*"),
    ],
  ],
  maxDistanz: [
    null as number | null,
    CustomValidators.valueAfter("relationZuLinearInTagen", "Relativ zu linear*"),
  ],
  verweildauerInTagen: [null as number | null],
};

const DEFAULT_ONLINE_AB_ZEIT = "10:00";

/**
 * Service, der das Formular für die Verlinkung von Planungsobjekten bereitstellt. Ist Teil der OnDemand-Beziehung, da
 * der Service nur solange existiert, wie die OnDemand-Beziehung-Component besteht.
 */
@Injectable()
export class PlanungsobjektWindowVerlinkungenFormService {
  private readonly fb = inject(FormBuilder);
  private readonly store = inject(Store);
  private readonly destroyRef = inject(DestroyRef);

  private SLIDER_LEFT = false;
  private SLIDER_RIGHT = true;

  private formForUseCaseSubject = new ReplaySubject<ReturnType<typeof this.createFormForUseCase>>(
    1,
  );
  private toggleFormSubject = new ReplaySubject<ReturnType<typeof this.createToggleForm>>(1);

  readonly form$ = this.formForUseCaseSubject.asObservable();
  readonly toggleForm$ = this.toggleFormSubject.asObservable();

  onDemandFormValue$ = this.store.select(planungsobjektWindowFeature.selectOnDemandFormValue);
  onDemandToggleFormValue$ = this.store.select(
    planungsobjektWindowFeature.selectOnDemandToggleFormValue,
  );

  /*
   * Resette den Store, wenn der Service zerstört wird
   **/
  private unregisterFn = this.destroyRef.onDestroy(() =>
    this.store.dispatch(onDemandBeziehungFormActions.resetOnDemandBeziehungFormValues()),
  );

  initialize(windowInput: PlanungsobjektWindowInput) {
    const form = this.createFormForUseCase(windowInput);
    this.formForUseCaseSubject.next(form);

    this.toggleFormSubject.next(
      this.setupToggleForm(
        windowInput,
        form.controls.relationZuLinearInTagen.value,
        form.controls.verweildauerInTagen.value,
      ),
    );
  }

  private createFormForUseCase(windowInput: PlanungsobjektWindowInput) {
    const form = this.fb.group({
      ...onDemandBeziehungControls,
    });

    const isOnMerkliste = windowInput.planungskontext === Planungskontext.VORGEMERKT;

    this.handleFormControlValues(form);
    this.handleFormControlDisabledState(isOnMerkliste, form);
    this.handleFormControlValidators(isOnMerkliste, form);
    this.handleFormGroupValidators(isOnMerkliste, form);

    return form;
  }

  private setupToggleForm(
    input: PlanungsobjektWindowInput,
    relationZuLinearInTagen: number | null | undefined,
    verweildauerInTagen: number | null | undefined,
  ) {
    const form = this.createToggleForm(
      input.planungskontext === Planungskontext.VORGEMERKT,
      relationZuLinearInTagen,
      verweildauerInTagen,
    );

    this.handleToggleFormValues(form);

    return form;
  }

  /**
 *  Nicht Teil der Form-Validierung. Haben aber trotzdem relevanten State und können disabled sein.
/** */
  private createToggleForm(
    isOnMerkliste: boolean,
    relationZuLinearInTagen: number | null | undefined,
    verweildauerInTagen: number | null | undefined,
  ) {
    return this.fb.group({
      wunschOnlineAbVsRelationalZuLinearToggle: [
        {
          value:
            relationZuLinearInTagen || relationZuLinearInTagen == 0 || isOnMerkliste
              ? this.SLIDER_RIGHT
              : this.SLIDER_LEFT,
          disabled: isOnMerkliste,
        },
      ],
      wunschOnlineBisVsVerweildauerToggle: [
        {
          value: verweildauerInTagen || isOnMerkliste ? this.SLIDER_RIGHT : this.SLIDER_LEFT,
          disabled: isOnMerkliste,
        },
      ],
    });
  }

  private handleFormControlValues(form: ReturnType<typeof this.createFormForUseCase>) {
    this.onDemandFormValue$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((onDemandFormValue) => {
        if (onDemandFormValue && hasDefinedValues(onDemandFormValue)) {
          form.patchValue(onDemandFormValue, { emitEvent: false });
        } else {
          // Wenn kein Wert vorhanden ist, dann wird nur der Default-Wert für onlineAbZeit gesetzt
          form.controls.onlineAbZeit.setValue(DEFAULT_ONLINE_AB_ZEIT, { emitEvent: false });
        }
      });
  }

  private handleToggleFormValues(form: ReturnType<typeof this.createToggleForm>) {
    this.onDemandToggleFormValue$
      .pipe(filter(isDefined), takeUntilDestroyed(this.destroyRef))
      .subscribe((toggleFormValue) => {
        if (toggleFormValue) {
          form.patchValue(toggleFormValue, { emitEvent: false });
        }
      });
  }

  private handleFormControlDisabledState(
    isOnMerkliste: boolean,
    form: ReturnType<typeof this.createFormForUseCase>,
  ) {
    const { SLIDER_LEFT } = this;
    const { controls } = form;

    this.onDemandToggleFormValue$
      .pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe((toggleForm) => {
        const { wunschOnlineAbVsRelationalZuLinearToggle, wunschOnlineBisVsVerweildauerToggle } =
          toggleForm;

        // enable/disable/reset mit `emitEvent: false`, damit nicht bei jedem Wert `valueChanges` emitiert
        if (wunschOnlineAbVsRelationalZuLinearToggle === SLIDER_LEFT) {
          controls.wunschOnlineAb.enable({ emitEvent: false });
          controls.relationZuLinearInTagen.disable({ emitEvent: false });
          controls.relationZuLinearInTagen.reset(null, { emitEvent: false });
          controls.minDistanz.disable({ emitEvent: false });
          controls.maxDistanz.disable({ emitEvent: false });
          controls.minDistanz.reset(null, { emitEvent: false });
          controls.maxDistanz.reset(null, { emitEvent: false });
        } else {
          controls.relationZuLinearInTagen.enable({ emitEvent: false });
          controls.minDistanz.enable({ emitEvent: false });
          controls.maxDistanz.enable({ emitEvent: false });
          controls.wunschOnlineAb.disable({ emitEvent: false });
        }

        if (wunschOnlineBisVsVerweildauerToggle === SLIDER_LEFT) {
          controls.wunschOnlineBis.enable({ emitEvent: false });
          controls.verweildauerInTagen.reset(null, { emitEvent: false });
          controls.verweildauerInTagen.disable({ emitEvent: false });
        } else {
          controls.verweildauerInTagen.enable({ emitEvent: false });
          controls.wunschOnlineBis.disable({ emitEvent: false });
        }

        // Nach enable/disable muss die Validität neu geprüft werden, da emitEvent: false gesetzt ist
        form.updateValueAndValidity();
      });
  }

  private handleFormControlValidators(
    isOnMerkliste: boolean,
    form: ReturnType<typeof this.createFormForUseCase>,
  ) {
    if (isOnMerkliste) {
      form.controls.relationZuLinearInTagen.setValidators(Validators.required);
      form.controls.verweildauerInTagen.setValidators(Validators.required);
    }
  }

  private handleFormGroupValidators(
    isOnMerkliste: boolean,
    form: ReturnType<typeof this.createFormForUseCase>,
  ) {
    form.setValidators([
      ...(isOnMerkliste
        ? []
        : [
            CustomValidators.atLeastOnePerGroup([
              ["wunschOnlineAb", "relationZuLinearInTagen"],
              ["wunschOnlineBis", "verweildauerInTagen"],
            ]),
          ]),
      //CustomValidators.relativMinMax(),
    ]);
  }
}
