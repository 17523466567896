import { Component, EventEmitter, Output } from "@angular/core";
import { Icons } from "src/app/models/icons";

@Component({
  selector: "app-additional-filter-wrapper",
  templateUrl: "./additional-filter-wrapper.component.html",
  styleUrls: ["./additional-filter-wrapper.component.scss"],
})
export class AdditionalFilterWrapperComponent {
  @Output() removeFilter = new EventEmitter<void>();

  Icons = Icons;
}
