<span
  *ngFor="let event of events$ | async"
  #target="kendoContextMenuTargetContainer"
  class="events-container"
  kendoContextMenuTargetContainer
>
  <app-event
    [attr.data-testid]="'event-' + event.titel"
    [event]="event"
    [kendoContextMenuTarget]="event"
    (eventDblClicked)="openEventKonkurrenzprogrammWindow($event)"
  >
  </app-event>
  <kendo-contextmenu
    showOn="contextmenu"
    [target]="target"
    (select)="onKonkurrenzContextMenuSelect($event, true)"
  >
    <kendo-menu-item
      text="Details"
      [data]="{
        action: AktionEnum.DETAILS
      }"
      [svgIcon]="Icons.information"
    ></kendo-menu-item>
    <kendo-menu-item
      text="Kopie anlegen"
      [data]="{
        action: AktionEnum.KOPIE_HIER,
        copyPattern: CopyPattern.NOW,
        systemAction: Aktion.KOPIERE_EVENT
      }"
      [disabled]="('editieren' | able: 'EK') === false"
      [svgIcon]="Icons.copy"
    >
      <kendo-menu-item
        text="diese Woche"
        [data]="{
          action: AktionEnum.KOPIE_HIER,
          copyPattern: CopyPattern.NOW,
          systemAction: Aktion.KOPIERE_EVENT
        }"
        [svgIcon]="Icons.copyThisWeek"
      ></kendo-menu-item>
      <kendo-menu-item
        text="nächste Woche"
        [data]="{
          action: AktionEnum.KOPIE_NAECHSTE_WOCHE,
          copyPattern: CopyPattern.NEXT_WEEK,
          systemAction: Aktion.KOPIERE_EVENT
        }"
        [svgIcon]="Icons.copyNextWeek"
      ></kendo-menu-item>
      <kendo-menu-item
        text="benutzerdefiniert"
        [data]="{
          action: AktionEnum.KOPIE_BENUTZERDEFINIERT,
          copyPattern: CopyPattern.BENUTZERDEFINIERT
        }"
        [svgIcon]="Icons.hand"
      ></kendo-menu-item>
    </kendo-menu-item>
  </kendo-contextmenu>
</span>
