<div class="top-bar">
  @if (planungsobjekt && planungsobjekt.getitId) {
    <kendo-button
      fillMode="flat"
      title="get.it Verknüpfung aufheben"
      [disabled]="
        usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR ||
        usecase === PlanungsobjektWindowUseCase.READONLY_ONDEMAND ||
        (facade.isGetItDisabled$ | async)
      "
      [svgIcon]="Icons.getitUnlink"
      (click)="getitEntknuepfen()"
    >
    </kendo-button>

    <div class="k-flex-grow">
      <div class="k-font-bold">{{ planungsobjekt.produkttitel }}</div>
      <div class="k-font-italic k-font-size-sm">{{ planungsobjekt.produkttitelMultipart }}</div>
    </div>

    <div class="k-d-flex k-gap-2 k-align-items-center">
      <kendo-svg-icon
        [icon]="getIconFromStatus(planungsobjekt.produktstatus)"
        [title]="getTooltipFromStatus(planungsobjekt.produktstatus)"
      ></kendo-svg-icon>
      <div>
        <a
          class="light-blue-link k-text-underline"
          rel="noopener noreferrer"
          target="_blank"
          [href]="planungsobjekt.getitId | getitProgrammeitemLink"
          >get.it ID: {{ planungsobjekt.getitId }}</a
        >
        <span *ngIf="planungsobjekt.produktlaenge" class="k-ml-2"
          >Produktlänge {{ planungsobjekt.produktlaenge | duration }}</span
        >
        <div class="k-font-italic k-font-size-sm">
          zuletzt synchronisiert am
          {{ planungsobjekt.letzterGetitSync ?? "" | date: "dd.MM.yyyy HH:mm" }} Uhr
          <button
            class="k-ml-2 getitSyncButton"
            kendoButton
            size="small"
            [disabled]="facade.isGetItDisabled$ | async"
            [svgIcon]="Icons.getitSync"
            [title]="syncButtonTooltip$ | async"
            (click)="facade.updateProduktInformationenFromGetit(planungsobjekt)"
          ></button>
        </div>
      </div>
    </div>
    <kendo-svg-icon
      class="k-ml-2"
      size="large"
      [icon]="Icons.information"
      [title]="
        (isPlanungFormDirty$ | async)
          ? 'Metadaten aus Produktdatenbank (Änderungen müssen erst gespeichert werden)'
          : 'Metadaten aus Produktdatenbank'
      "
    ></kendo-svg-icon>
  } @else {
    <div class="k-d-flex justify-content-between k-align-items-center">
      <kendo-button
        fillMode="flat"
        [disabled]="
          usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR ||
          usecase === PlanungsobjektWindowUseCase.READONLY_ONDEMAND ||
          (facade.isGetItDisabled$ | async)
        "
        [svgIcon]="Icons.getitLink"
        (click)="mitGetitVerknuepfen()"
      >
        Mit Produktdatenbank verknüpfen
      </kendo-button>
      <kendo-svg-icon
        class="k-ml-2"
        size="large"
        [icon]="Icons.information"
        [title]="
          (isPlanungFormDirty$ | async)
            ? 'Metadaten aus Produktdatenbank (Änderungen müssen erst gespeichert werden)'
            : 'Metadaten aus Produktdatenbank'
        "
      ></kendo-svg-icon>
    </div>
  }
</div>
