import { Injectable } from "@angular/core";
import { format } from "date-fns";
import {
  ExportableRechercheSearchResultVM,
  RechercheSearchQueryVM,
  RechercheSearchResultVM,
} from "src/app/core/stores/recherche/recherche.model";
import { DateFnsService } from "src/app/services/date-fns.service";
import { DurationPipe } from "src/app/shared/pipes/duration.pipe";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";
import { PlanungsobjektLinearDto } from "../openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "../openapi/model/planungsobjekt-on-demand-dto";
import { PlanungsobjekteDto } from "../openapi/model/planungsobjekte-dto";
import { SearchPlanungsobjekteQuery } from "../openapi/model/search-planungsobjekte-query";

@Injectable({
  providedIn: "root",
})
export class RechercheMapper {
  static mapRechercheSearchResultVM(
    searchResults: RechercheSearchResultVM[],
  ): ExportableRechercheSearchResultVM[] {
    const result: ExportableRechercheSearchResultVM[] = searchResults.map((searchResult) => ({
      id: searchResult.id,
      sendetag: searchResult.sendetag,
      zeit:
        (searchResult.zeit?.von ? format(searchResult.zeit?.von, "HH:mm") : "") +
        " - " +
        (searchResult.zeit?.bis ? format(searchResult.zeit?.bis, "HH:mm") : ""),
      onlineAb: searchResult.onlineAb,
      onlineAbZeit: searchResult.onlineAbZeit,
      onlineBis: searchResult.onlineBis,
      titel: searchResult.highlight ? searchResult.titel.concat(" *") : searchResult.titel,
      redaktion: searchResult.redaktion,
      mengengeruest: searchResult.mengengeruest,
      highlight: searchResult.highlight,
      genre: searchResult.genre,
      hasVariante: searchResult.hasVariante,
      planlaenge: searchResult.planlaenge
        ? DurationPipe.transform(Number(searchResult.planlaenge), 3)
        : "",
      notiz: searchResult.notiz,
      farbgebung: searchResult.farbgebung,
      contentCommunities: searchResult.contentCommunities.join(", "),
      planungskontext: searchResult.planungskontext,
      variante: searchResult.variante,
      kanal: KanalOffsetUtils.kanalToReadableString(searchResult.kanal), //z.B. ZDFNeo -> ZDFneo
      fruehesteVeroeffentlichung: searchResult.fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion: searchResult.stofffuehrendeRedaktion,
      fsk: searchResult.fsk,
      staffelnummer: searchResult.staffelnummer,
      folgennummer: searchResult.folgennummer,
      gesamtfolgennummer: searchResult.gesamtfolgennummer,
      inhaltsbeschreibung: searchResult.inhaltsbeschreibung,
      mitwirkende: searchResult.mitwirkende,
      getitId: searchResult.getitId,
      produkttitel: searchResult.produkttitelMultipart // Für den Export muss der produktitelMultipart ebenfalls ausgespielt werden
        ? searchResult.produkttitelMultipart + " " + searchResult.produkttitel
        : searchResult.produkttitel,
      produkttitelMultipart: searchResult.produkttitelMultipart,
      produktstatus: searchResult.produktstatus,
      produktlaenge: searchResult.produktlaenge,
    }));

    return result;
  }

  static mapToSearchQuery(query: RechercheSearchQueryVM): SearchPlanungsobjekteQuery {
    return {
      ...query,
      titel: query.titel || null,
      sendetagVon: DateFnsService.formatDateAsString(query.sendetagVon),
      sendetagBis: DateFnsService.formatDateAsString(query.sendetagBis),
    };
  }

  static mapPlanungsobjekteToSearchResults(
    planungsobjekte: PlanungsobjekteDto | null,
    idsWithVarianten: string[],
    expandedIds: string[],
    resultIds: string[],
  ): RechercheSearchResultVM[] {
    if (!planungsobjekte) {
      return [];
    }
    const resultIdSet = new Set(resultIds);

    const linearResults = planungsobjekte.linear
      .filter((plo) => resultIdSet.has(plo.id))
      .map((planungsobjekt) =>
        this.mapLinearToSearchResult(
          planungsobjekt,
          idsWithVarianten,
          expandedIds,
          this.getOnDemandChildrenIds(planungsobjekt),
          planungsobjekte,
        ),
      );

    const onDemandResults = planungsobjekte.onDemand
      .filter((plo) => resultIdSet.has(plo.id))
      .map((planungsobjekt) =>
        this.mapOnDemandToSearchResult(
          planungsobjekt,
          idsWithVarianten,
          expandedIds,
          this.getLinearChildrenIds(planungsobjekt),
          planungsobjekte,
        ),
      );

    const result = [...linearResults, ...onDemandResults];
    return result;
  }

  private static mapLinearToSearchResult(
    linear: PlanungsobjektLinearDto,
    idsWithVarianten: string[],
    isExpanded: string[],
    childrenIds: string[],
    planungsobjekte: PlanungsobjekteDto = { linear: [], onDemand: [] },
  ): RechercheSearchResultVM {
    const von = linear.publikationsplanung
      ? DateFnsService.parseDateAndTimeToDateObject(linear.beginnzeit, linear.kalendertag)
      : null;
    const bis = von && linear.planlaenge ? DateFnsService.addSeconds(von, linear.planlaenge) : null;
    const zeit = von && bis ? { von, bis } : null;
    const sendetag = new Date(linear.sendetag);
    const hasVariante = idsWithVarianten.includes(linear.id);
    return {
      id: linear.id,
      isExpanded: isExpanded.some((expandedId) => expandedId === linear.id),
      childrenIds,
      children: childrenIds
        .map((childId) => planungsobjekte.onDemand.find((plo) => plo.id === childId))
        .filter((plo) => !!plo)
        .map((child) => this.mapOnDemandToSearchResult(child, idsWithVarianten, [], [])),
      sendetag,
      zeit,
      onlineAb: null,
      onlineAbZeit: null,
      onlineBis: null,
      titel: linear.titel,
      redaktion: linear.redaktion,
      mengengeruest: !!linear.mengengeruesteintragId,
      highlight: linear.highlight,
      genre: linear.genre,
      planungskontext: linear.planungskontext,
      hasVariante,
      planlaenge: linear.planlaenge || null,
      farbgebung: linear.farbgebung,
      contentCommunities: linear.contentCommunities,
      notiz: linear.notiz,
      variante: linear.variante,
      kanal: linear.kanal,
      fruehesteVeroeffentlichung: linear.fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion: linear.stofffuehrendeRedaktion,
      fsk: linear.fsk,
      staffelnummer: linear.staffelnummer,
      folgennummer: linear.folgennummer,
      gesamtfolgennummer: linear.gesamtfolgennummer,
      inhaltsbeschreibung: linear.inhaltsbeschreibung,
      mitwirkende: linear.mitwirkende,
      getitId: linear.getitId,
      produkttitel: linear.produkttitel,
      produkttitelMultipart: linear.produkttitelMultipart,
      produktstatus: linear.produktstatus,
      produktlaenge: linear.produktlaenge,
    };
  }

  private static mapOnDemandToSearchResult(
    onDemand: PlanungsobjektOnDemandDto,
    isOverlappingIds: string[],
    isExpanded: string[],
    childrenIds: string[],
    planungsobjekte: PlanungsobjekteDto = { linear: [], onDemand: [] },
  ): RechercheSearchResultVM {
    const onlineAb = onDemand.onlineAb ? DateFnsService.parseStringToDate(onDemand.onlineAb) : null;
    const onlineAbZeit = onDemand.onlineAbZeit;
    const onlineBis = onDemand.onlineBis
      ? DateFnsService.parseStringToDate(onDemand.onlineBis)
      : null;
    const hasVariante = isOverlappingIds.includes(onDemand.id);
    return {
      id: onDemand.id,
      isExpanded: isExpanded.some((expandedId) => expandedId === onDemand.id),
      childrenIds,
      children: childrenIds
        .map((childId) => planungsobjekte.linear.find((plo) => plo.id === childId))
        .filter((plo) => !!plo)
        .map((child) => this.mapLinearToSearchResult(child, isOverlappingIds, [], [])),
      sendetag: null,
      zeit: null,
      onlineAb,
      onlineAbZeit,
      onlineBis,
      titel: onDemand.titel,
      redaktion: onDemand.redaktion,
      mengengeruest: !!onDemand.mengengeruesteintragId,
      highlight: onDemand.highlight,
      genre: onDemand.genre,
      planungskontext: onDemand.planungskontext,
      hasVariante,
      planlaenge: onDemand.planlaenge || null,
      farbgebung: onDemand.farbgebung,
      contentCommunities: onDemand.contentCommunities,
      notiz: onDemand.notiz,
      variante: null,
      kanal: onDemand.kanal,
      fruehesteVeroeffentlichung: onDemand.fruehesteVeroeffentlichung,
      stofffuehrendeRedaktion: onDemand.stofffuehrendeRedaktion,
      fsk: onDemand.fsk,
      staffelnummer: onDemand.staffelnummer,
      folgennummer: onDemand.folgennummer,
      gesamtfolgennummer: onDemand.gesamtfolgennummer,
      inhaltsbeschreibung: onDemand.inhaltsbeschreibung,
      mitwirkende: onDemand.mitwirkende,
      getitId: onDemand.getitId,
      produkttitel: onDemand.produkttitel,
      produkttitelMultipart: onDemand.produkttitelMultipart,
      produktstatus: onDemand.produktstatus,
      produktlaenge: onDemand.produktlaenge,
    };
  }

  /**
   * Gibt die Ids der Kinder zurück. Lineare Planungsobjekte haben OnDemand Kinder über eine ausgehende LinearOnDemand Beziehung.
   * Analog umgekehrt.
   */
  static getLinearChildrenIds(planungsobjekt: PlanungsobjektOnDemandDto): string[] {
    return planungsobjekt.beziehungenEingehend
      .filter((beziehung) => beziehung.typ === "LinearOnDemand")
      .map((beziehung) => beziehung.vonId);
  }

  static getOnDemandChildrenIds(planungsobjekt: PlanungsobjektLinearDto): string[] {
    return planungsobjekt.beziehungenAusgehend
      .filter((beziehung) => beziehung.typ === "LinearOnDemand")
      .map((beziehung) => beziehung.zuId);
  }

  /**
   * Flattet die Suchergebnisse, sodass alle Suchergebnisse (Parent und Children) untereinander in einer Liste sind.
   * Wird für den Excel-Export benötigt.
   */
  static flattenSearchResults(searchResults: RechercheSearchResultVM[]): RechercheSearchResultVM[] {
    const result: RechercheSearchResultVM[] = [];
    searchResults.forEach((searchResult) => {
      result.push(searchResult);
      if (searchResult.isExpanded) result.push(...this.flattenSearchResults(searchResult.children));
    });
    return result;
  }
}
