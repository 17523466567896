import { Pipe, PipeTransform } from "@angular/core";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { MengengeruestViewModel } from "src/app/models/viewmodels/mengengeruest-viewmodel";
import { KanalOffsetUtils } from "src/app/utils/kanal-offset-utils";

@Pipe({
  name: "zdfTitelByBeginnzeit",
})
/**
 * Für die Benennung von Mengengerüsttabellen auf Blockansichten
 */
export class ZdfTitelByBeginnzeitPipe implements PipeTransform {
  transform(singleMengengeruestTable: MengengeruestViewModel, ansichtViewModel: AnsichtViewModel) {
    const abBeginnzeitTitel =
      singleMengengeruestTable.schemaplatz.wochentag +
      " ab " +
      singleMengengeruestTable.schemaplatz.beginnzeit;
    const [anyEintrag] = singleMengengeruestTable.mengengeruesteintraege;
    if (!anyEintrag) {
      return abBeginnzeitTitel;
    }

    const [ansichtBeginnzeit] = ansichtViewModel.ansichtsdefinition.schemaplaetze;
    const beginnzeit = anyEintrag.beginnzeit;
    if (!beginnzeit || beginnzeit === ansichtBeginnzeit.beginnzeit) {
      return abBeginnzeitTitel;
    }

    return (
      KanalOffsetUtils.getSendewochentag(
        singleMengengeruestTable.schemaplatz.wochentag,
        beginnzeit,
        ansichtViewModel.kanal,
      ) +
      " " +
      beginnzeit.replace(":", ".")
    );
  }
}
