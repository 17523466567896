import { entriesOf } from "src/app/utils/object-utils";
import { Genre } from "../openapi/model/genre";
import { EnumMetadata, enumRecordToSelectOptions, SelectOption } from "./enum-base";

export const GenreRecord = Object.freeze<Record<Genre, EnumMetadata>>({
  KeinGenre: {
    bedeutung: "Kein Genre",
    short: "0",
  },
  Nachrichten: {
    bedeutung: "Nachrichten",
    short: "010",
  },
  Magazin: {
    bedeutung: "Magazin",
    short: "020",
  },
  Reportage: {
    bedeutung: "Reportage",
    short: "030",
  },
  Dokumentation: {
    bedeutung: "Dokumentation",
    short: "040",
  },
  Talk: {
    bedeutung: "Talk",
    short: "050",
  },
  Fernsehfilm: {
    bedeutung: "Fernsehfilm",
    zusatz: "(inkl. Mehrteiler, Reihen)",
    short: "060",
  },
  Serie: {
    bedeutung: "Serie",
    zusatz: "(inkl. Sitcom)",
    short: "070",
  },
  Spielfilm: {
    bedeutung: "Spielfilm",
    short: "080",
  },
  Dokumentarfilm: {
    bedeutung: "Dokumentarfilm",
    short: "090",
  },
  FactualEntertainment: {
    bedeutung: "Factual Entertainment",
    short: "100",
  },
  Show: {
    bedeutung: "Show",
    zusatz: "(inkl. Musik-, Spielshow, Nummernsendung, Quiz)",
    short: "110",
  },
  Buehne: {
    bedeutung: "Bühne",
    short: "120",
  },
  ComedyKabarett: {
    bedeutung: "Comedy/Kabarett",
    short: "130",
  },
  Sport: {
    bedeutung: "Sport",
    short: "140",
  },
  Ereignisuebertragung: {
    bedeutung: "Ereignisübertragung",
    short: "150",
  },
  Sonstiges: {
    bedeutung: "Sonstiges",
    short: "160",
  },
  NochOffen: {
    bedeutung: "noch offen",
    short: "170",
  },
});

/**
 * Option for "Kein Genre", die im Dropdown einen leeren Eintrag erzeugt
 */
export const KeinGenreOption: SelectOption<Genre> = {
  text: "",
  value: Genre.KEIN_GENRE,
};

export const GenreOptionsFiltered = enumRecordToSelectOptions(GenreRecord).filter(
  (genre) => genre.value !== Genre.KEIN_GENRE,
);

export const GenreOptions = enumRecordToSelectOptions(GenreRecord);

export const genreListToOptions = (genreList: Genre[]): SelectOption<Genre>[] => {
  const filteredEntries = entriesOf(GenreRecord).filter(([key]) => genreList.includes(key));

  const filteredRecord = Object.fromEntries(filteredEntries) as Record<Genre, EnumMetadata>;
  return enumRecordToSelectOptions(filteredRecord);
};
