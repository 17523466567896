<div class="u-grid-container">
  <app-form-field class="u-col-12" label="Planungstitel">
    <!-- TODO formcontrol zu planungstitel ändern nachdem DTO und Commands angepasst wurden -->
    <div class="k-d-flex k-align-items-center">
      <kendo-textbox data-testid="planungstitel" formControlName="titel"></kendo-textbox>
      <app-icon-toggle
        data-testid="highlight"
        formControlName="highlight"
        icon="star"
        title="Highlight"
        [tooltips]="{ active: 'Highlight: ja', inactive: 'Highlight: nein' }"
      ></app-icon-toggle>
    </div>
  </app-form-field>

  <app-input-label class="u-col-12" text="Notiz">
    <kendo-textarea data-testid="notiz" formControlName="notiz" resizable="auto"></kendo-textarea>
  </app-input-label>

  <!-- TODO formcontrol redaktion -> planendeRedaktion nachdem DTO und Commands angepasst wurden -->
  <app-select
    class="u-col-4"
    data-testid="planende-redaktion"
    formControlName="redaktion"
    label="Planende Redaktion"
    [options]="RedaktionOptionsFiltered"
  >
  </app-select>

  <app-select
    class="u-col-4"
    data-testid="farbgebung"
    formControlName="farbgebung"
    label="Farbe"
    [farbgebungEnumStyleMap]="planungsobjektFarbgebungMap"
    [options]="PlanungsobjektFarbenOptions"
  >
  </app-select>

  <app-input-label class="u-col-4" text="früheste Veröffentlichung">
    <div class="k-d-flex k-align-items-center">
      <app-iso-dateonly
        data-testid="frueheste-veroeffentlichung"
        formControlName="fruehesteVeroeffentlichung"
      ></app-iso-dateonly>
      <kendo-svg-icon
        class="k-ml-2"
        title="voraussichtlich frühestmöglicher Termin nach Endabnahme eines sendefähigen Materials"
        [icon]="Icons.information"
      ></kendo-svg-icon>
    </div>
  </app-input-label>
</div>
