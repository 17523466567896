import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { EventApiService } from "../api/event/event.api.service";
import { ConvertKonkurrenzprogrammToEventCommand } from "../models/openapi/model/convert-konkurrenzprogramm-to-event-command";
import { CopyEventCommand } from "../models/openapi/model/copy-event-command";
import { CopyPattern } from "../models/openapi/model/copy-pattern";
import { EventDto } from "../models/openapi/model/event-dto";
import { GetEventsByAnsichtenQuery } from "../models/openapi/model/get-events-by-ansichten-query";
import { DateFnsService } from "./date-fns.service";

@Injectable({
  providedIn: "root",
})
export class EventService {
  constructor(
    private eventApiService: EventApiService,
    private logger: NGXLogger,
  ) {}

  createEvent$(event: EventDto): Observable<EventDto> {
    const eventDto = this.transformEventViewModelToDto(event);
    return this.eventApiService.createEvent$(eventDto);
  }

  updateEvent$(event: EventDto): Observable<EventDto> {
    const eventDto = this.transformEventViewModelToDto(event);
    return this.eventApiService.updateEvent$(eventDto.id, eventDto);
  }

  deleteEvent$(event: EventDto): Observable<void> {
    return this.eventApiService.deleteEvent$(event.id);
  }

  copyEvent$(event: EventDto, copyPattern: CopyPattern): Observable<EventDto> {
    const eventDto: CopyEventCommand = {
      eventId: event.id,
      copyPattern: copyPattern,
    };
    return this.eventApiService.copyEvent$(event.id, eventDto);
  }

  convertKonkurrenzprogramm$(event: EventDto, convertedGUID: string): Observable<EventDto> {
    const convertCommand: ConvertKonkurrenzprogrammToEventCommand = {
      konkurrenzprogrammId: convertedGUID,
      titel: event.titel,
      vonDatum: event.vonDatum,
      vonZeit: event.vonZeit,
      bisDatum: event.bisDatum,
      bisZeit: event.bisZeit,
      kategorie: event.kategorie,
      notiz: event.notiz,
    };
    return this.eventApiService.convertKonkurrenzprogramm$(convertCommand);
  }

  transformEventViewModelToDto(eventViewModel: EventDto): EventDto {
    const vonZeit = eventViewModel.vonZeit ?? "00:00";
    const bisZeit = eventViewModel.bisZeit ?? "23:59";
    const vonDatum =
      typeof eventViewModel.vonDatum === "string"
        ? eventViewModel.vonDatum
        : DateFnsService.formatDateAsString(eventViewModel.vonDatum);

    // Wenn kein Wert für bisDatum angegeben ist setze vonDatum für bisDatum
    let bisDatum = eventViewModel.bisDatum ?? eventViewModel.vonDatum;
    bisDatum =
      typeof bisDatum === "string" ? bisDatum : DateFnsService.formatDateAsString(bisDatum);

    this.logger.info("EventViewModel vor Transformation: ", eventViewModel);
    const eventDto: EventDto = {
      ...eventViewModel,
      vonDatum: vonDatum,
      vonZeit: vonZeit,
      bisZeit: bisZeit,
      bisDatum: bisDatum,
    };
    this.logger.info("Transformiertes EventDto: ", eventDto);
    return eventDto;
  }

  getEventsByAnsichten$(ansichtenIds: GetEventsByAnsichtenQuery) {
    return this.eventApiService.getEventsByAnsichten$(ansichtenIds);
  }

  getEventsByAnsicht$(ansichtenId: string) {
    return this.eventApiService.getEventsByAnsicht$(ansichtenId);
  }

  getEventsByYear$(year: number) {
    return this.eventApiService.getEventsByYear$(year);
  }
}
