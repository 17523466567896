import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { map, of, switchMap } from "rxjs";
import { AktionEnum, AktionResult } from "src/app/models/enums/aktion";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { CustomWindowService } from "src/app/services/custom-window.service";
import { EKWindowResult } from "src/app/shared/windows/ek-window/ek-window.component";
import { ekWindowActions } from "./ek-window.actions";

@Injectable()
export class EkWindowEffects {
  openEkWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowActions.openEkWindow),
      switchMap(({ input }) => this.customWindowService.openKonkurrenzWindow(input).result),
      map((result) =>
        ekWindowActions.handleEkWindowResult({
          result,
        }),
      ),
    );
  });

  handleEkFromContextMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowActions.handleEkFromContextMenu),
      switchMap(({ viewModel, event, isEvent, isZdfProgramm }) => {
        const { action, copyPattern, systemAction } = event;
        switch (action) {
          case AktionEnum.DETAILS:
            if (!isZdfProgramm)
              return this.customWindowService.openKonkurrenzWindow(
                isEvent
                  ? {
                      type: "Event",
                      konkurrenzEvent: viewModel as EventDto,
                      asNewCopy: false,
                    }
                  : {
                      type: "Konkurrenzprogramm",
                      konkurrenzEvent: viewModel as KonkurrenzprogrammDto,
                      asNewCopy: false,
                    },
              ).result;
            break;
          case AktionEnum.KOPIE_HIER:
          case AktionEnum.KOPIE_NAECHSTE_WOCHE: {
            const data: EKWindowResult = {
              result: AktionResult.COPY,
              returnValue: {
                action: systemAction,
                value: viewModel,
                isEvent,
                copyPattern,
              },
            };
            return of(data);
          }
          case AktionEnum.KOPIE_BENUTZERDEFINIERT: {
            return this.customWindowService.openKonkurrenzWindow(
              isEvent
                ? {
                    type: "Event",
                    konkurrenzEvent: viewModel as EventDto,
                    asNewCopy: true,
                  }
                : {
                    type: "Konkurrenzprogramm",
                    konkurrenzEvent: viewModel as KonkurrenzprogrammDto,
                    asNewCopy: true,
                  },
            ).result;
          }
        }
        return of({} as WindowCloseResult);
      }),
      map((result) => {
        return ekWindowActions.handleEkWindowResult({
          result,
        });
      }),
    );
  });

  handleEkWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ekWindowActions.handleEkWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return ekWindowActions.closeWindow();

        return result.returnValue.isEvent
          ? ekWindowActions.handleEventWindowResult({ result })
          : ekWindowActions.handleKonkurrenzprogrammWindowResult({ result });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private customWindowService: CustomWindowService,
  ) {}
}
