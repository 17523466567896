import { RouterEvent } from "@angular/router";
import { catchError, filter, Observable, startWith, tap } from "rxjs";
import { isBrowserNavigationEvent } from "./route.utils";

export function catchSwitchMapError<T>(errorAction: (error: any) => any) {
  return (source$: Observable<T>) =>
    source$.pipe(
      catchError((error: unknown, innerSource$) =>
        innerSource$.pipe(startWith(errorAction(error))),
      ),
    );
}

/**
 * Filtert Events, die durch den Angular-Router ausgelöst wurden.
 */
export const filterAngularNavigationEvent =
  <T extends RouterEvent>() =>
  (source$: Observable<T>) =>
    source$.pipe(filter((event) => !isBrowserNavigationEvent(event)));

export function stopEvent<T extends Event>(opts?: {
  preventDefault?: boolean;
  stopPropagation?: boolean;
}) {
  const { preventDefault = true, stopPropagation = true } = opts ?? {};
  return (source$: Observable<T>) =>
    source$.pipe(
      tap((event) => {
        if (preventDefault) event.preventDefault();
        if (stopPropagation) event.stopPropagation();
      }),
    );
}
