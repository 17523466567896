import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, delay, distinctUntilChanged, of, switchMap } from "rxjs";
import shellSelectors from "src/app/core/stores/shell/shell.selectors";

@Pipe({
  name: "isLoading",
})
export class IsLoadingPipe implements PipeTransform {
  constructor(private store: Store) {}

  /**
   * Pipe, die true zurückgibt, wenn die Anwendung lädt.
   *
   * Kann optional das true verzögern, um ein Flackern zu vermeiden,
   * indem als zweiter Parameter die Zeit in Millisekunden übergeben wird,
   * nach der true zurückgegeben wird.
   * @param _foo ungenutzt
   * @param delayMillis die Zeit, nach dem die Pipe im Falle von isLoading true zurückgibt, um ein Flackern zu vermeiden
   * @returns Observable, das true zurückgibt, wenn die Anwendung lädt
   */
  transform(_foo: object, delayMillis: number = 0): Observable<boolean> {
    return this.store.select(shellSelectors.isLoading).pipe(
      distinctUntilChanged(),
      // delay the first value to avoid flickering
      // when the loading state is changed quickly
      switchMap((isLoading) =>
        isLoading ? (delayMillis ? of(true).pipe(delay(delayMillis)) : of(true)) : of(false),
      ),
    );
  }
}
