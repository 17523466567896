import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY, catchError, combineLatest, filter, map, switchMap, tap } from "rxjs";
import { Aktion } from "src/app/models/enums/aktion";
import { Layout } from "src/app/models/openapi/model/layout";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { BlockansichtService } from "src/app/pages/ansichten/blockansicht/blockansicht.service";
import { PlanungsobjektInteraktionService } from "src/app/services/planungsobjekt-interaktion.service";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import { ansichtActions } from "../ansicht/ansicht.actions";
import { mengengeruestActions } from "../mengengeruest/mengengeruest.actions";
import { multiansichtActions } from "../multiansicht/multiansicht.actions";
import { multiAnsichtFeature } from "../multiansicht/multiansicht.reducer";
import { sendeplatzActions } from "../sendeplatz/sendeplatz.actions";
import { planungsobjektActions } from "./planungsobjekt.actions";
import planungsobjektSelectors from "./planungsobjekt.selectors";

@Injectable()
export class PlanungsobjektEffects {
  /**
   * Effect für das Laden aller Entities innerhalb der Ansicht. Dies ist nötig um bei einer Änderung eines anderen
   * Nutzers die Ansicht vollständig aktualisieren zu können.
   */
  loadPlanungsobjekteForEntityUpdates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ansichtActions.updateAllEntitiesInAnsicht),
      concatLatestFrom(() => this.store.select(multiAnsichtFeature.selectMultiAnsichtViewModel)),
      switchMap(([, multiAnsichtViewModel]) => {
        if (!multiAnsichtViewModel) return EMPTY;
        const ansichtenIds = multiAnsichtViewModel.ansichtViewModels
          .filter((ansichtPublikationViewModel) => ansichtPublikationViewModel.visible)
          .map((ansichtPublikationViewModel) => ansichtPublikationViewModel.ansichtViewModel.id);

        const request$ =
          multiAnsichtViewModel.layout === Layout.BLOCK
            ? this.blockansichtService.getPlanungsobjekteByBlockansichtenIds$(ansichtenIds)
            : this.service.getPlanungsobjekteByAnsichtenIds$({ ansichtenIds });

        return request$.pipe(
          map((planungsobjekte) =>
            planungsobjektActions.setAllPlanungsobjekte({
              planungsobjekte,
            }),
          ),
        );
      }),
    );
  });

  /**
   * Lade alle Planungsobjekte für die Kalender/-Listenansicht eines neuen Jahres.
   */
  loadPlanungsobjekteByAnsicht$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.updateAnsichtInMultiansichtSuccess),
      filter(({ layout }) => layout !== Layout.BLOCK),
      switchMap(({ additionalAnsichtViewModelId }) => {
        return this.service.getPlanungsobjekteByAnsichtId$(additionalAnsichtViewModelId).pipe(
          map((planungsobjekte) =>
            planungsobjektActions.loadPlanungsobjekteForAnsichtenSuccess({
              planungsobjekte,
            }),
          ),
        );
      }),
    );
  });

  /**
   * Lade alle Planungsobjekte für mehrere Ansichten und füge diese in den State hinzu. Wird nur beim initialien
   * Aufrufen einer Ansicht durch die Action setMultiansichtSuccess ausgelöst.
   */
  loadPlanungsobjekteByAnsichten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(multiansichtActions.setMultiansichtSuccess),
      map(({ multiAnsichtViewModel }) => {
        const visibleAnsicht = multiAnsichtViewModel.ansichtViewModels.filter(
          (ansichtPublikationViewModel) => ansichtPublikationViewModel.visible,
        );
        const ansichtenIds = visibleAnsicht.map(
          (ansichtPublikationViewModel) => ansichtPublikationViewModel.ansichtViewModel.id,
        );
        return planungsobjektActions.loadPlanungsobjekteForAnsichten({
          ansichtenIds,
          layout: multiAnsichtViewModel.layout,
        });
      }),
    );
  });

  /**
   * Ruft je nach Layout den passenden Endpunkt für das Laden der Planungsobjekte auf. Wird nur beim initialien
   * Aufrufen einer Ansicht durch die Action loadPlanungsobjekteForAnsichten ausgelöst.
   */
  loadPlanungsobjekteByAnsichtenSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.loadPlanungsobjekteForAnsichten),
      filter(({ ansichtenIds }) => ansichtenIds.length > 0),
      switchMap(({ ansichtenIds, layout }) => {
        if (layout === Layout.BLOCK) {
          return this.blockansichtService.getPlanungsobjekteByBlockansichtenIds$(ansichtenIds).pipe(
            map((planungsobjekte) =>
              planungsobjektActions.loadPlanungsobjekteForAnsichtenSuccess({
                planungsobjekte,
              }),
            ),
          );
        } else {
          return this.service.getPlanungsobjekteByAnsichtenIds$({ ansichtenIds }).pipe(
            map((planungsobjekte) =>
              planungsobjektActions.loadPlanungsobjekteForAnsichtenSuccess({
                planungsobjekte,
              }),
            ),
          );
        }
      }),
    );
  });

  getPlanungsobjekteLinearAndOnDemandById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.getPlanungsobjekteLinearAndOnDemandById),
      switchMap(({ planungsobjektId }) => {
        return this.service.getPlanungsobjekteById$(planungsobjektId).pipe(
          map((planungsobjekte) =>
            planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess({
              planungsobjekte,
            }),
          ),
        );
      }),
    );
  });

  /**
   * Erstelle eine Planungsobjekt auf einem Sendeplatz.
   */
  createPlanungsobjektOnSendeplatz$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.createPlanungsobjektOnSendeplatz),
      switchMap(({ shouldCloseWindow, titel, sendeplatzKey }) => {
        return this.service
          .planungsobjektLinearVorgeplantSendeplatzErstellen$({ titel, sendeplatzKey })
          .pipe(
            map((sendeplatz: SendeplatzDto) =>
              planungsobjektActions.createPlanungsobjektOnSendeplatzSuccess({
                shouldCloseWindow,
                planungsobjekt: sendeplatz.planungsobjekte[sendeplatz.planungsobjekte.length - 1],
                sendeplatz,
              }),
            ),
          );
      }),
    );
  });

  createPlanungsobjektWithGetitId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.createPlanungsobjektWithGetitId),
      switchMap(({ command }) => {
        return this.service.planungsobjektLinearVorgeplantErstellenMitGetit$(command).pipe(
          map((planungsobjekt) =>
            planungsobjektActions.createPlanungsobjektWithGetitIdSuccess({
              planungsobjekt,
            }),
          ),
        );
      }),
    );
  });

  createPlanungsobjektWithGetitIdSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.createPlanungsobjektWithGetitIdSuccess),
      map(({ planungsobjekt }) => {
        if (PlanungsobjektUtils.isOnBlockansicht(planungsobjekt)) {
          return planungsobjektActions.createPlanungsobjektOnBlockansichtSuccess({
            shouldCloseWindow: true, // nicht relevant
            planungsobjekt,
          });
        } else {
          return sendeplatzActions.loadSendeplatzWithSendeplatzKey({
            planungsobjekt,
          });
        }
      }),
    );
  });

  verknuepfePlanungsobjektMitGetit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.verknuepfePlanungsobjektMitGetit),
      switchMap(({ command }) => {
        return this.service.verknuepfePlanungsobjektMitGetit$(command).pipe(
          map((planungsobjekte) =>
            planungsobjektActions.verknuepfePlanungsobjektMitGetitSuccess({
              planungsobjekte,
            }),
          ),
        );
      }),
    );
  });

  synchronizePlanungsobjektWithGetit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.synchronisierePlanungsobjektMitGetit),
      switchMap(({ command }) => {
        return this.service.synchronizePlanungsobjektMitGetit$(command).pipe(
          map((planungsobjekte) =>
            planungsobjektActions.synchronisierePlanungsobjektMitGetitSuccess({
              planungsobjekte,
            }),
          ),
          // TODO: Fehlerbehandlung auf diese Art verschleiert Validierungs- und Berechtigungsfehler
          // catchError(() => {
          //   this.notificationService.showErrorNotification(
          //     "Fehler beim Synchronisieren mit Getit. Bitte versuchen Sie es später erneut.",
          //   );
          //   return EMPTY;
          // }),
        );
      }),
    );
  });

  /**
   * Update ein Planungsobjekt auf einem Sendeplatz.
   */
  updatePlanungsobjektOnSendeplatz$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        planungsobjektActions.updatePlanungsobjektOnSendeplatz,
        planungsobjektActions.updatePlanungsobjektOnSendeplatzNewWindow,
      ),
      switchMap(({ shouldCloseWindow, command }) =>
        this.service.planungsobjektLinearVorgeplantSendeplatzAktualisieren$(command).pipe(
          map((planungsobjekt) =>
            planungsobjektActions.updatePlanungsobjektOnSendeplatzSuccess({
              shouldCloseWindow,
              planungsobjekt,
            }),
          ),
        ),
      ),
      tap(({ planungsobjekt }) =>
        this.notificationService.showActionNotification(
          Aktion.BEARBEITE_PLANUNGSOBJEKT,
          NotificationStyle.SUCCESS,
          planungsobjekt.titel,
        ),
      ),
    );
  });

  /**
   * Wird aufgerufen, wenn ein Planungsobjekt erfolgreich über das Speichern im Planungstab erstellt wurde.
   */
  createLinearVorgeschlagen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.createPlanungsobjektLinearVorgeschlagen),
      // Hole die aktuelle LinearOnDemand Beziehung und füge sie dem Command hinzu.
      switchMap(({ shouldCloseWindow, command }) => {
        return this.service
          .createLinearVorgeschlagen$({
            ...command,
          })
          .pipe(
            tap((linear) => {
              this.notificationService.showActionNotification(
                Aktion.ERSTELLE_VORSCHLAG,
                NotificationStyle.SUCCESS,
                linear.titel,
              );
            }),
            map((planungsobjekt) =>
              planungsobjektActions.createPlanungsobjektLinearVorgeschlagenSuccess({
                shouldCloseWindow,
                planungsobjekt,
              }),
            ),
          );
      }),
    );
  });

  /**
   * Wird aufgerufen, wenn ein Planungsobjekt erfolgreich über das Speichern im Planungstab aktualisiert wurde.
   */
  updateLinearVorgeschlagen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.updatePlanungsobjektLinearVorgeschlagen),
      // Hole die aktuelle LinearOnDemand Beziehung und füge sie dem Command hinzu.
      switchMap(({ shouldCloseWindow, command }) => {
        return this.service
          .updateLinearVorgeschlagen$({
            ...command,
          })
          .pipe(
            tap((linear) => {
              this.notificationService.showActionNotification(
                Aktion.BEARBEITE_VORSCHLAG,
                NotificationStyle.SUCCESS,
                linear.titel,
              );
            }),
            map((planungsobjekt) =>
              planungsobjektActions.updatePlanungsobjektLinearVorgeschlagenSuccess({
                shouldCloseWindow,
                planungsobjekt,
              }),
            ),
          );
      }),
    );
  });

  /**
   * Entferne eine Planungsobjekt.
   */
  deletePlanungsobjekt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.deletePlanungsobjekt),
      switchMap(({ planungsobjektId }) =>
        this.service.deletePlanungsobjekt$(planungsobjektId).pipe(
          tap(({ notifications }) => this.notificationService.showNotifications(notifications)),
          map(() => planungsobjektActions.deletePlanungsobjektSuccess({ planungsobjektId })),
          tap(() =>
            this.planungsobjektInteraktionService.removePlanungsobjektInMehrfachauswahl(
              planungsobjektId,
            ),
          ),
        ),
      ),
    );
  });

  /**
   * Kopiere eine Planungsobjekt.
   */
  copyPlanungsobjekt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.copyPlanungsobjekt),
      switchMap(({ planungsobjekt, copyPattern }) =>
        this.service.planungsobjektLinearVorgeplantSendeplatzKopieren$({
          planungsobjektId: planungsobjekt.id,
          copyPattern,
        }),
      ),
      map((sendeplatz) => {
        const lastCreatedPlanungsobjekt = SendeplatzUtils.getLatestPlanungsobjekt(
          sendeplatz.result,
        );
        return planungsobjektActions.copyPlanungsobjektSuccess({
          planungsobjekt: lastCreatedPlanungsobjekt,
          sendeplatz,
        });
      }),
      tap(({ planungsobjekt, sendeplatz }) => {
        this.notificationService.showActionNotification(
          Aktion.KOPIERE_PLANUNGSOBJEKT,
          NotificationStyle.SUCCESS,
          planungsobjekt.titel,
        );
        this.notificationService.showNotifications(sendeplatz.notifications);
      }),
    );
  });

  movePlanungsobjektToSendeplatz$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.movePlanungsobjekt),
      switchMap(({ planungsobjektId, sendeplatz }) =>
        this.service
          .movePlanungsobjektLinearToVorgeplantSendeplatz$({
            sendeplatzKey: SendeplatzUtils.getSendeplatzKey(sendeplatz),
            id: planungsobjektId,
          })
          .pipe(
            map((response) => {
              const movedPlanungsobjekt = response.result.planungsobjekte.find(
                (v) => v.id === planungsobjektId,
              );
              return planungsobjektActions.movePlanungsobjektSuccess({
                planungsobjekt: movedPlanungsobjekt,
                sendeplatz: response,
                notifications: response.notifications,
              });
            }),
            tap(({ notifications }) => {
              this.notificationService.showNotifications(notifications);
            }),
          ),
      ),
    );
  });

  //TODO: Sollte eventuell in mehrfachauswahl.effect.ts ausgelagert werden
  deleteMultiplePlanungsobjekte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.deleteMultiplePlanungsobjekte),
      switchMap(({ planungsobjekte }) => {
        const planungsobjekteIds = planungsobjekte.map((planungsobjekt) => planungsobjekt.id);
        const deleteRequests = planungsobjekte.map((planungsobjekt) =>
          this.service.deletePlanungsobjekt$(planungsobjekt.id).pipe(
            tap(() =>
              this.planungsobjektInteraktionService.removePlanungsobjektInMehrfachauswahl(
                planungsobjekt.id,
              ),
            ),
            tap(() =>
              this.notificationService.showActionNotification(
                Aktion.ENTFERNE_PLANUNGSOBJEKT,
                NotificationStyle.SUCCESS,
                planungsobjekt.titel,
              ),
            ),
          ),
        );
        return combineLatest([...deleteRequests]).pipe(
          map(() => {
            // Diese Action führt derzeit noch zu einem neuen Effect (updateMerklisteOnBlockansicht$) in der
            // merkliste.effect.ts
            return planungsobjektActions.deleteMultiplePlanungsobjekteSuccess({
              planungsobjekteIds,
              shouldRealodMerklisten: planungsobjekte.some((planungsobjekt) =>
                PlanungsobjektUtils.isOnMerkliste(planungsobjekt),
              ),
            });
          }),
          tap(() => {
            this.planungsobjektInteraktionService.clearPlanungsobjekteInMehrfachauswahl();
          }),
        );
      }),
    );
  });

  //TODO: Sollte eventuell in mehrfachauswahl.effect.ts ausgelagert werden
  moveMultiplePlanungsobjekte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.moveMultiplePlanungsobjekte),
      switchMap(({ command }) => {
        const planungsobjekteIds = command.planungsobjekte.map(
          (planungsobjekt) => planungsobjekt.id,
        );
        return this.service
          .moveMultiplePlanungsobjekte$({
            planungsobjekte: planungsobjekteIds,
            daysOffset: command.daysOffset,
          })
          .pipe(
            map((sendeplaetze) => {
              return planungsobjektActions.moveMultiplePlanungsobjekteSuccess({
                planungsobjekte: command.planungsobjekte,
                daysOffset: command.daysOffset,
                sendeplaetze: sendeplaetze,
              });
            }),
            tap(({ sendeplaetze }) => {
              this.notificationService.showNotifications(sendeplaetze.notifications);
            }),
          );
      }),
    );
  });

  /**
   * Weise die Planungsobjekt einem Mengengerüst zu.
   */
  assignPlanungsobjektToMengengeruest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.assignPlanungsobjektToMengengeruest),
      switchMap(({ sendeplatz, planungsobjekt, mengengeruesteintragId }) =>
        this.service
          .planungsobjektLinearVorgeplantZuweisenZuMengengeruest$({
            planungsobjektId: planungsobjekt.id,
            mengengeruesteintragId,
          })
          .pipe(
            map(() =>
              planungsobjektActions.assignPlanungsobjektToMengengeruestSuccess({
                sendeplatz,
                planungsobjekt,
                mengengeruesteintragId,
              }),
            ),
            tap(({ planungsobjekt, mengengeruesteintragId }) => {
              const action = mengengeruesteintragId
                ? Aktion.PLANUNGSOBJEKT_ZU_MENGENGERUESTEINTRAG
                : Aktion.PLANUNGSOBJEKT_VON_MENGENGERUESTEINTRAG_TRENNEN;

              this.notificationService.showActionNotification(
                action,
                NotificationStyle.SUCCESS,
                planungsobjekt.titel,
              );
            }),
          ),
      ),
    );
  });

  /**
   * Wandelt eine Planungsobjekt in eine Serie um. Die neuen Planungsobjekte werden aus den Sendeplätzen, aus
   * der Response ermittelt.
   */
  zuSerieUmwandelnSendeplatz$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.planungsobjektZuSerieUmwandelnSendeplatz),
      switchMap(({ command }) =>
        this.service.planungsobjektLinearVorgeplantSendeplatzZuSerieUmwandeln$(command).pipe(
          map((sendeplaetze) => {
            return planungsobjektActions.planungsobjektZuSerieUmwandelnSendeplatzSuccess({
              planungsobjektId: command.planungsobjektId,
              newPlanungsobjekte: sendeplaetze.map(
                (sendeplatz) =>
                  sendeplatz.planungsobjekte.sort(
                    PlanungsobjektUtils.sortPlanungsobjektAscByCreatedAt,
                  )[0],
              ),
              sendeplaetze,
            });
          }),
        ),
      ),
    );
  });

  /**
   * Wandelt eine Planungsobjekt in eine Serie um. Die neuen Planungsobjekte werden aus den Sendeplätzen, aus
   * der Response ermittelt.
   */
  zuSerieUmwandeln$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.planungsobjektZuSerieUmwandelnBlock),
      switchMap(({ command }) =>
        this.service.planungsobjektLinearVorgeplantBlockZuSerieUmwandeln$(command).pipe(
          map((planungsobjekte) => {
            return planungsobjektActions.planungsobjektZuSerieUmwandelnBlockSuccess({
              planungsobjektId: command.planungsobjektId,
              newPlanungsobjekte: planungsobjekte,
            });
          }),
        ),
      ),
    );
  });

  /**
   * Wird aufgerufen, wenn eine Planungsobjekt zu einer Serie umgewandelt wurde, um die ursprünglich umgewandelte
   * Planungsobjekt ebenfalls neu zu laden. Dies ist nötig da sich der Name ggf. verändert hat.
   */
  updatePlanungsobjektFromSerieUmwandeln$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        planungsobjektActions.planungsobjektZuSerieUmwandelnBlockSuccess,
        planungsobjektActions.planungsobjektZuSerieUmwandelnSendeplatzSuccess,
      ),
      switchMap((action) => {
        return this.service.getPlanungsobjektById$(action.planungsobjektId).pipe(
          map((planungsobjekt) => {
            return planungsobjektActions.getPlanungsobjektByIdSuccess({
              planungsobjekt,
            });
          }),
          tap(({ planungsobjekt }) => {
            this.notificationService.showActionNotification(
              Aktion.PLANUNGSOBJEKT_IN_SERIE_UMWANDELN,
              NotificationStyle.SUCCESS,
              planungsobjekt.titel,
            );
          }),
        );
      }),
    );
  });

  /**
   * Effekt zum aktualiseren aller Planungsobjekte die durch das Löschen eines Mengengerüsteintrag betroffen sind.
   * Somit wird sichergestellt, dass das Mengengerüst Icon nicht mehr angezeigt wird.
   */
  removeMengengeruesteintragsIdForPlanungsobjekte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mengengeruestActions.deleteMengengeruestEintragSuccess),
      switchMap(({ mengengeruestEintragId }) =>
        this.store.select(
          planungsobjektSelectors.selectPlanungsobjekteByMengengeruesteintragId(
            mengengeruestEintragId,
          ),
        ),
      ),
      map((planungsobjekte) => {
        return planungsobjektActions.getPlanungsobjekteByIds({
          planungsobjekteIds: planungsobjekte.map((planungsobjektf) => planungsobjektf.id),
        });
      }),
    );
  });

  planungsobjektGetitVerknuepfungAufheben$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.entknuepfePlanungsobjektVonGetit),
      switchMap(({ planungsobjektId, produktEingenschaftenBeibehalten }) =>
        this.service
          .planungsobjektEntknuepfenVonGetit$(planungsobjektId, produktEingenschaftenBeibehalten)
          .pipe(
            map((planungsobjekte) =>
              planungsobjektActions.entknuepfePlanungsobjektVonGetitSuccess({
                planungsobjekte,
              }),
            ),
            catchError(() => {
              this.notificationService.showErrorNotification(
                "Fehler beim Aufheben der Verknüpfung mit get.it. Bitte versuchen Sie es später erneut.",
              );
              return EMPTY;
            }),
          ),
      ),
    );
  });

  updatePlanungsobjektAfterGetitOperation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        planungsobjektActions.verknuepfePlanungsobjektMitGetitSuccess,
        planungsobjektActions.synchronisierePlanungsobjektMitGetitSuccess,
        planungsobjektActions.entknuepfePlanungsobjektVonGetitSuccess,
      ),
      map(({ planungsobjekte }) => {
        return planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess({
          planungsobjekte: planungsobjekte.result,
        });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private service: PlanungsobjektService,
    private notificationService: CustomNotificationService,
    private planungsobjektInteraktionService: PlanungsobjektInteraktionService,
    private blockansichtService: BlockansichtService,
  ) {}
}
