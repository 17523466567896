import { Component, Input, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import dragdropSelectors from "src/app/core/stores/dragdrop/dragdrop.selectors";
import {
  ListenansichtColumnHeader,
  ListenansichtHeaderEnum,
} from "src/app/core/stores/listenansicht/listenansicht.reducer";
import planungsobjektSelectors from "src/app/core/stores/planungsobjekt/planungsobjekt.selectors";
import sendeplatzSelectors from "src/app/core/stores/sendeplatz/sendeplatz.selectors";
import { ContextMenuItem, CustomContextMenuSelectEvent } from "src/app/models/context-menu";
import { AktionEnum } from "src/app/models/enums/aktion";
import { Icons } from "src/app/models/icons";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { Role } from "src/app/models/openapi/model/role";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { SendeplatzViewModel } from "src/app/models/viewmodels/sendeplatz-viewmodel";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import { PublitFrontendSettings } from "src/environments/environment";
import ListenansichtFacade from "../listenansicht.facade";

@Component({
  selector: "app-listenansicht-row, [app-listenansicht-row]",
  templateUrl: "./listenansicht-row.component.html",
  styleUrls: ["./listenansicht-row.component.scss"],
})
export class ListenansichtRowComponent {
  public readonly facade = inject(ListenansichtFacade);

  @Input() ansicht: AnsichtViewModel;
  @Input() sendeplatzGroupZeilen: SendeplatzViewModel[];
  @Input() showNotizen: boolean;
  @Input() showKonkurrenzEvents: boolean;
  @Input() ansichtHeaders: ListenansichtColumnHeader[];
  @Input() isMultipleDragging: boolean;

  AktionEnum = AktionEnum;
  Planungskontext = Planungskontext;
  Icons = Icons;
  Role = Role;
  ListenansichtHeaderEnum = ListenansichtHeaderEnum;

  canDropPlanungsobjektInVorschlagspaltePredicate = this.store.select(
    sendeplatzSelectors.selectCanDropPlanungsobjektInVorschlagspaltePredicate,
  );

  constructor(
    protected store: Store,
    protected settings: PublitFrontendSettings,
  ) {}

  highlightSendetag$(sendetag: string) {
    return this.store.select(dragdropSelectors.selectHighlightSendeplatz(sendetag));
  }

  planungsobjekteInVorschlagspalte$(sendeplatz: SendeplatzDto) {
    return this.store.select(
      planungsobjektSelectors.selectPlanungsobjekteForSendeplatzKeyPlanungskontext(
        SendeplatzUtils.getSendeplatzKey(sendeplatz),
        Planungskontext.VORGESCHLAGEN,
      ),
    );
  }

  onLinearChipDoubleClicked(sendeplatz: SendeplatzDto) {
    const sendeplatzKey = SendeplatzUtils.getSendeplatzKey(sendeplatz);
    this.facade.planungsobjektErstellenVorgeschlagen(sendeplatzKey);
  }

  onVorgeschlagenContextMenuSelect(
    event: CustomContextMenuSelectEvent<ContextMenuItem<AktionEnum>>,
    sendeplatz: SendeplatzDto,
    isVorgeschlagen: boolean,
  ) {
    const { data: command } = event.item;

    const sendeplatzKey = SendeplatzUtils.getSendeplatzKey(sendeplatz);

    switch (command) {
      case AktionEnum.ERSTELLE_PLANUNGSOBJEKT:
        this.facade.planungsobjektErstellenVorgeschlagen(sendeplatzKey);
        break;
      case AktionEnum.GETIT_TRANSFER:
        this.facade.openGetitWindow(sendeplatz, isVorgeschlagen);
        break;
    }
  }
}
