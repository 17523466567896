import { Injectable } from "@angular/core";
import {
  eachDayOfInterval,
  endOfDay,
  format,
  getDaysInMonth,
  isLastDayOfMonth,
  isSameDay,
} from "date-fns";
import { DateFnsService } from "src/app/services/date-fns.service";
import { wochentagProgrammwocheOrder } from "src/app/utils/kanal-offset-utils";
import { AnsichtDto } from "../openapi/model/ansicht-dto";
import { EventDto } from "../openapi/model/event-dto";
import { KonkurrenzprogrammDto } from "../openapi/model/konkurrenzprogramm-dto";
import { Wochentag } from "../openapi/model/wochentag";
import {
  AnsichtPublikationViewModel,
  AnsichtViewModel,
  MultiAnsichtViewModel,
} from "../viewmodels/ansicht-viewmodel";
import {
  EkGroupDayViewModel,
  EkGroupMonthViewModel,
  EkGroupWeekViewModel,
} from "../viewmodels/ek-group-viewmodel";

@Injectable({
  providedIn: "root",
})
export class AnsichtenMapper {
  mapAnsichtViewModelToMultiAnsichtViewModel(
    selectedAnsichtViewModel: AnsichtViewModel,
    relatedAnsichtenViewModels: AnsichtViewModel[],
  ): MultiAnsichtViewModel {
    const primaryAnsichtPublikationViewModel: AnsichtPublikationViewModel = {
      ansichtViewModel: selectedAnsichtViewModel,
      primary: true,
      visible: true,
    };
    const relatedPublikationViewModel: AnsichtPublikationViewModel[] = relatedAnsichtenViewModels
      .filter(
        (vm) =>
          vm.ansichtsdefinition.id === selectedAnsichtViewModel.ansichtsdefinition.id &&
          vm.zeitraum !== selectedAnsichtViewModel.zeitraum,
      )
      .map((vm) => {
        return {
          ansichtViewModel: vm,
          primary: false,
          visible: false,
        };
      });

    const multiAnsichtViewModel: MultiAnsichtViewModel = {
      ansichtViewModels: [primaryAnsichtPublikationViewModel, ...relatedPublikationViewModel].sort(
        (pvm1, pvm2) => pvm1.ansichtViewModel.year - pvm2.ansichtViewModel.year,
      ),
      layout: selectedAnsichtViewModel.ansichtsdefinition.layout,
    };
    return multiAnsichtViewModel;
  }

  /**
   * Transformiere die Daten aus dem Backend in das für die Tabellen benötigte "flache" Datenmodell.
   * @param ansichten die aus dem Backend geladen wurden
   * @returns Liste der Ansichten als ViewModels
   */
  static mapAnsichtenDtosToAnsichtViewModel(ansichten: AnsichtDto[]): AnsichtViewModel[] {
    return ansichten.map((ansicht) => AnsichtenMapper.mapAnsichtDtoToAnsichtViewModel(ansicht));
  }

  static mapAnsichtDtoToAnsichtViewModel(ansicht: AnsichtDto): AnsichtViewModel {
    const vonDatum = DateFnsService.parseStringToDate(ansicht.vonDatum);
    const bisDatum = endOfDay(new Date(DateFnsService.parseStringToDate(ansicht.bisDatum))); //letzter Tag des Jahres, 23:59
    const ansichtVM: AnsichtViewModel = {
      titel: ansicht.name,
      kanal: ansicht.kanal,
      id: ansicht.id,
      ansichtsdefinition: ansicht.ansichtsdefinition,
      zeitraum: { start: vonDatum, end: bisDatum },
      year: vonDatum.getFullYear(),
    };
    return ansichtVM;
  }

  static mapEksToEkGroupMonthViewModel(
    year: number,
    eks: { events: EventDto[]; konkurrenzprogramme: KonkurrenzprogrammDto[] },
  ): EkGroupMonthViewModel {
    let ekGroupWeeks: EkGroupDayViewModel[] = [];
    const ekGroupMonths: EkGroupMonthViewModel = {};

    const everyDayInYear = eachDayOfInterval({
      start: new Date(year, 0, 1),
      end: new Date(year, 11, 31),
    });

    const sortedWochentage = Object.keys(wochentagProgrammwocheOrder).map((wochentag) => {
      return wochentag as Wochentag;
    });

    // Gruppierung der Kalendertage nach Monat und füge die E&Ks ein
    for (let dayInYearIndex = 0; dayInYearIndex < everyDayInYear.length; dayInYearIndex++) {
      let kalendertag = everyDayInYear[dayInYearIndex];
      const firstWochentagIndex = sortedWochentage.findIndex(
        (s: Wochentag) => s === DateFnsService.getWeekday(kalendertag),
      );

      // Berechne die Anzahl der Leereinträge die am Anfang des Monats benötigt werden
      const weekdayOffset = firstWochentagIndex % sortedWochentage.length;
      for (let firstWeekIndex = 0; firstWeekIndex < weekdayOffset; firstWeekIndex++) {
        // Fügt Leereinträge an den Anfang der 1. Woche des Monats
        ekGroupWeeks.push(null);
      }

      // Berechne die Anzahl der Kalenderwochen im Monat abhängig von der Anzahl
      // der Tage und der anzuzeigenden Wochentage + möglichem weekdayOffset
      const calendarWeeks = Math.ceil(
        (getDaysInMonth(new Date(year, kalendertag.getMonth())) + weekdayOffset) /
          sortedWochentage.length,
      );

      // Anzahl der Wochentage, die in der 1. Woche nach dem 1. Tag noch hinzugefügt werden müssen,
      // falls vorher ein weekdayOffset errechnet wurde
      let weekdayLimit = sortedWochentage.length - weekdayOffset;

      // Iterieren über Kalenderwochen pro Monat
      const weekRecords: EkGroupWeekViewModel = {};
      for (let weekIndex = 0; weekIndex < calendarWeeks; weekIndex++) {
        // Iterieren über die Wochentage minus Leereinträge für die erste Woche
        for (let weekdayIndex = 0; weekdayIndex < weekdayLimit; weekdayIndex++) {
          kalendertag = everyDayInYear[dayInYearIndex];
          const relevantKonkurrenzprogramme = eks.konkurrenzprogramme.filter((konkurrenzprogramm) =>
            isSameDay(kalendertag, DateFnsService.parseStringToDate(konkurrenzprogramm.vonDatum)),
          );
          const relevantEvents = eks.events.filter((event) =>
            isSameDay(kalendertag, DateFnsService.parseStringToDate(event.vonDatum)),
          );

          // Fügt die E&K Gruppen in die Wochen-Arrays ein
          const ekGroupDay: EkGroupDayViewModel = {
            zeitraum: {
              start: DateFnsService.parseDateAndTimeToDateObject("00:00", kalendertag),
              end: DateFnsService.parseDateAndTimeToDateObject("23:59", kalendertag),
            },
            kalendertag: everyDayInYear[dayInYearIndex],
            events: relevantEvents,
            konkurrenzprogramme: relevantKonkurrenzprogramme,
          };
          ekGroupWeeks.push(ekGroupDay);

          // Nur für die erste Woche eines Monats muss die Menge der Tage mit dem Offset angepasst werden.
          // Bei den anderen Wochen im Monat wird die komplette Anzahl der Wochentage genutzt werden.
          if (weekIndex !== 0) weekdayLimit = sortedWochentage.length;

          // Ist der letzte Tag eines Monats erreicht, wird ggf. die Woche mit Leereinträgen aufgefüllt
          if (isLastDayOfMonth(kalendertag)) {
            const paddingNumber = sortedWochentage.length - ekGroupWeeks.length;
            Array.from({ ...ekGroupWeeks, length: paddingNumber });
            break;
          } else {
            // Erhöhe den Index mit jedem Durchlauf außer am letzten Tag, da sonst durch die übergeordnete
            // for-Schleife 2x eine 1 addiert wird
            dayInYearIndex = dayInYearIndex + 1;
          }
        }
        const calenderWeekKey = Object.keys(weekRecords).length + 1;
        weekRecords[calenderWeekKey] = ekGroupWeeks;
        ekGroupWeeks = [];
      }
      const calenderMonthAsKey = format(everyDayInYear[dayInYearIndex], "MM");
      ekGroupMonths[calenderMonthAsKey] = weekRecords;
    }
    return ekGroupMonths;
  }
}
