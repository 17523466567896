import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Interval } from "date-fns";
import { areIntervalsOverlapping } from "date-fns/fp";
import { DeveloperError } from "src/app/models/errors/technical.error";
import { BlockansichtMapper } from "src/app/models/mapper/blockansicht.mapper";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { BlockansichtDefinition } from "src/app/pages/ansichten/blockansicht/blockansicht-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import { KanalOffsetUtils, kanalTagesgrenzen } from "src/app/utils/kanal-offset-utils";
import { isDefined } from "src/app/utils/object-utils";

export class CustomValidators {
  /**
   * Validator, der prüft, ob der Wert des Formularfelds:
   * 1. inhaltlich vor dem anderen Formularfeld liegt
   * 2. nicht leer ist, wenn der Wert des anderen Formularfelds gesetzt ist
   * @param formField Anderes Formularfeld, welches inhaltlich **danach** liegt
   */
  static valueBefore =
    (key: string, label: string): ValidatorFn =>
    (thisControl) => {
      const otherControl = thisControl.parent?.get(key);
      if (!otherControl) return null;

      return isDefined(thisControl.value) &&
        isDefined(otherControl.value) &&
        thisControl.value > otherControl.value
        ? { valueBefore: { otherLabel: label } }
        : null;
    };
  /**
   * Analog zu ValueBefore, aber mit der Möglichkeit nicht den eigenen Wert eines Controls zu vergleichen, sondern einem anderen ReferenceControl
   * @param referenceKey Anderes Formularfeld, auf welches referenziert wird und welches für before/after Vergleich herangezogen wird
   */
  static valueBeforeWithReferenceKey =
    (key: string, label: string, referenceKey: string): ValidatorFn =>
    (thisControl) => {
      const otherControl = thisControl.parent?.get(key);
      const referenceControl = thisControl.parent?.get(referenceKey);
      if (!otherControl || !referenceControl) return null;

      return referenceControl.value &&
        otherControl.value &&
        thisControl.value &&
        referenceControl.value > otherControl.value
        ? { valueBeforeWithReferenceKey: { otherLabel: label } }
        : null;
    };

  /**
   * Validator, der prüft, ob der Wert des Formularfelds:
   * 1. inhaltlich nach dem anderen Formularfeld liegt
   * 2. wenn der Wert des anderen Formularfelds leer ist, nicht gesetzt ist
   * @param formField Anderes Formularfeld, welches inhaltlich **davor** liegt
   */
  static valueAfter =
    (key: string, label: string): ValidatorFn =>
    (thisControl) => {
      const otherControl = thisControl.parent?.get(key);
      if (!otherControl) return null;

      return thisControl.value && otherControl.value && thisControl.value < otherControl.value
        ? { valueAfter: { otherLabel: label } }
        : null;
    };

  static minLengthTrimmed =
    (minLengthTrimmed: number): ValidatorFn =>
    (control) => {
      if (typeof control.value !== "string" || !control.value) return null;
      const trimmed = control.value.trim();
      return trimmed?.length < minLengthTrimmed ? { minLengthTrimmed } : null;
    };

  /**
   * Validator, der prüft, ob die Werte des Objekts des Formularwertes unterschiedlich sind
   * @param control muss ein FormGroup sein, dessen Wert ein Record sein muss, der nur primitive Werte enthält
   * @returns null, wenn alle Werte des Records unterschiedlich sind, sonst ein Objekt mit dem Schlüssel "distinctRecordValues"
   * @throws {DeveloperError} wenn das Control kein FormGroup ist, der Wert des Controls kein Objekt ist oder der Wert des Controls nicht-primitive Werte enthält
   */
  static distinctRecordValues: ValidatorFn = (control) => {
    if (!(control instanceof FormGroup)) {
      throw new DeveloperError("distinctRecordValuesValidatorFn: control is not a FormGroup", {
        control,
      });
    }
    const value = control.value as unknown;

    if (!value) return null;

    if (typeof value !== "object") {
      throw new DeveloperError("distinctRecordValuesValidatorFn: value is not an object", {
        value,
      });
    }

    const objectValues = Object.values(value);

    const containsComplexValues = objectValues.some((v) => v !== null && typeof v === "object");
    if (containsComplexValues) {
      throw new DeveloperError("distinctRecordValuesValidatorFn: value contains complex values", {
        value,
      });
    }

    const containsDuplicateValues = new Set(objectValues).size !== objectValues.length;
    if (containsDuplicateValues) {
      return { distinctRecordValues: "duplicate" };
    }

    return null;
  };

  static minDate =
    (date: Date): ValidatorFn =>
    (control) => {
      const controlDate = control.value instanceof Date ? control.value : null;
      return controlDate && controlDate >= date ? null : { minDate: date };
    };

  /**
   * Validiert, ob der Datumswert eines Feldes vor dem Datumswert eines anderen Feldes liegt.
   */
  static dateBefore =
    (key: string, label: string): ValidatorFn =>
    (thisControl) => {
      const otherControl = thisControl.parent?.get(key);
      if (!otherControl) return null;
      if (!otherControl.pending && otherControl.invalid) {
        thisControl.markAsPending();
        otherControl.updateValueAndValidity();
      }

      const value: unknown = thisControl.value;
      const otherValue: unknown = otherControl.value;

      if (value === null || otherValue === null) return null;

      if (typeof value !== "string" && !(value instanceof Date)) {
        throw new DeveloperError("dateBeforeValidatorFn: value is not a string or Date");
      }
      if (typeof otherValue !== "string" && !(otherValue instanceof Date)) {
        throw new DeveloperError("dateBeforeValidatorFn: otherValue is not a string or Date");
      }

      const _date = typeof value === "string" ? new Date(value) : value;
      const _otherDate = typeof otherValue === "string" ? new Date(otherValue) : otherValue;

      if (isNaN(_date.getTime()) || isNaN(_otherDate.getTime())) {
        throw new DeveloperError(
          `dateBeforeValidatorFn: value "${String(value)}" or otherValue "${String(otherValue)}" is not a valid Date `,
        );
      }

      return _date < _otherDate ? null : { dateBefore: { otherLabel: label } };
    };

  /**
   * Validiert, ob der Datumswert eines Feldes nach dem Datumswert eines anderen Feldes liegt.
   */
  static dateAfter =
    (key: string, label: string): ValidatorFn =>
    (thisControl) => {
      const otherControl = thisControl.parent?.get(key);
      if (!otherControl) return null;
      if (!otherControl.pending && otherControl.invalid) {
        thisControl.markAsPending();
        otherControl.updateValueAndValidity();
      }

      const value: unknown = thisControl.value;
      const otherValue: unknown = otherControl.value;

      if (value === null || otherValue === null) return null;

      if (typeof value !== "string" && !(value instanceof Date)) {
        throw new DeveloperError("dateAfterValidatorFn: value is not a string or Date");
      }
      if (typeof otherValue !== "string" && !(otherValue instanceof Date)) {
        throw new DeveloperError("dateAfterValidatorFn: otherValue is not a string or Date");
      }

      const _date = typeof value === "string" ? new Date(value) : value;
      const _otherDate = typeof otherValue === "string" ? new Date(otherValue) : otherValue;

      if (isNaN(_date.getTime()) || isNaN(_otherDate.getTime())) {
        throw new DeveloperError(
          `dateAfterValidatorFn: value "${String(value)}" or otherValue "${String(otherValue)}" is not a valid Date `,
        );
      }

      return _date > _otherDate ? null : { dateAfter: { otherLabel: label } };
    };

  /**
   * Validiert, ob der Wert des Formularfelds einer bestimmten RegExp entspricht und gibt eine
   * benutzerdefinierte Fehlermeldung zurück, die dann im Formular angezeigt werden kann.
   *
   * @param pattern Muster, welches der Wert des Formularfelds erfüllen muss
   * @param errorMessage Fehlermeldung, die zurückgegeben wird, wenn das Muster nicht erfüllt ist
   */
  static patternWithCustomError(pattern: RegExp, errorMessage: string): ValidatorFn {
    return (control): { [key: string]: any } | null => {
      if (!control.value || typeof control.value !== "string") return null;
      const valid = pattern.test(control.value);
      return valid ? null : { pattern: errorMessage };
    };
  }

  /**
   * Überprüft, ob mindestens ein Formularfeld in jeder Gruppe einen Wert hat.
   * @param controlGroups Eine Gruppe von FormContols, wobei lediglich ein FormControl einen Wert haben darf.
   * @returns Ein Validierungsfehler, wenn mindestens eine Gruppe keine Werte hat; ansonsten null.
   */
  static atLeastOnePerGroup(controlGroups: string[][]): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const formGroup = group as FormGroup;

      const groupWithAtLeastOneValue = controlGroups.map((controlNames) =>
        controlNames.some((controlName) => !!formGroup.get(controlName)?.value),
      );

      return groupWithAtLeastOneValue.every((hasValue) => hasValue)
        ? null
        : { requiredControls: true };
    };
  }

  static relativMinMax(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const formGroup = group as FormGroup;
      console.log(formGroup);
      const relativValue = formGroup.get("relationZuLinearInTagen")?.value as number;
      const minValue = formGroup.get("minDistanz")?.value as number;
      const maxValue = formGroup.get("maxDistanz")?.value as number;

      return { relationZuLinearInTagen: true, minDistanz: true, maxDistanz: true };
    };
  }

  /**
   * Validiert, dass sich das Planungsobjekt mindestens 1 Minute mit dem Zeitinterval überschneidet.
   * Anfang und Ende werden dabei über die Formularfelder beginnzeit und laenge definiert.
   * @param blockansichtDefinition Die BlockansichtDefinition, die die Zeitintervalle enthält
   * @param sendetagKey Der Key des Sendetag-Formularfelds
   * @param beginnzeitKey Der Key des Beginnzeit-Formularfelds
   * @param laengeKey Der Key des Laenge-Formularfelds
   *
   */
  static planungsobjektBlockansichtInTimeRange(
    blockansichtDefinition: BlockansichtDefinition,
    blockansichtDefinitionen: BlockansichtDefinition[],
    sendetagKey: string,
    beginnzeitKey: string,
    laengeKey: string,
  ): ValidatorFn {
    if (!blockansichtDefinition.timeRange) {
      throw new DeveloperError("planungsobjektBlockansichtInTimeRange: timeRange is not defined");
    }

    return (group: AbstractControl): ValidationErrors | null => {
      if (!(group instanceof FormGroup)) {
        throw new DeveloperError(
          "planungsobjektBlockansichtInTimeRange: group is not a FormGroup",
          {
            group,
          },
        );
      }

      const beginnzeitControl = group.get(beginnzeitKey);
      const laengeControl = group.get(laengeKey);
      const sendetagControl = group.get(sendetagKey);

      if (!beginnzeitControl || !laengeControl || !sendetagControl) {
        throw new DeveloperError(
          "planungsobjektBlockansichtInTimeRange: beginnzeitControl / endzeitControl / sendetag is not defined",
          {
            beginnzeitControl,
            beginnzeitKey,
            laengeControl,
            endzeitKey: laengeKey,
          },
        );
      }
      const beginnzeit: unknown = beginnzeitControl.value;
      const laenge: unknown = laengeControl.value;
      const sendetag: unknown = sendetagControl.value;

      if (!beginnzeit || !laenge || !sendetag) return null;

      if (typeof beginnzeit !== "string") {
        throw new DeveloperError(
          "planungsobjektBlockansichtInTimeRange: beginnzeit is not a string",
          { beginnzeit },
        );
      }

      if (typeof laenge !== "number") {
        throw new DeveloperError("planungsobjektBlockansichtInTimeRange: laenge is not a number", {
          laenge,
        });
      }

      if (typeof sendetag !== "string") {
        throw new DeveloperError(
          "planungsobjektBlockansichtInTimeRange: sendetag is not a string",
          {
            sendetag,
          },
        );
      }

      const sendetagDate = DateFnsService.parseStringToDate(sendetag);
      const wannBezugInterval: Interval = DateFnsService.createInterval(
        beginnzeit,
        laenge,
        KanalOffsetUtils.getKalendertagForSendetag(
          sendetagDate,
          beginnzeit,
          blockansichtDefinition.ansichtViewModel.kanal,
        ),
      );

      // Die DateRange ist gültig, wenn sich das wannBezugInterval mit einem der BlockansichtIntervals überschneidet
      const blockansichtIntervals: Interval[] =
        BlockansichtMapper.getIntervalsForBlockansichten(blockansichtDefinitionen);

      const isWithinDateRange = blockansichtIntervals.some((interval) =>
        areIntervalsOverlapping(interval, wannBezugInterval),
      );

      return isWithinDateRange ? null : { planungsobjektBlockansichtInTimeRange: true };
    };
  }

  /**
   * Validiert, ob die Beginnzeit vor der Endzeit liegt und falls nicht, ob die Endzeit vor der Tagesgrenze des Kanals liegt.
   * @param beginnzeitKey Key des Beginnzeit-Formularfelds
   * @param endzeitKey Key des Endzeit-Formularfelds
   * @param kanal der Kanal, für den die Tagesgrenze gelten soll
   */
  static endzeitVorBeginnzeitMitSendetagsgrenze(
    beginnzeitKey: string,
    endzeitKey: string,
    kanal: Kanal,
  ): ValidatorFn {
    const kanalTagesgrenze = kanalTagesgrenzen[kanal];
    if (!kanalTagesgrenze) {
      throw new DeveloperError("endzeitVorBeginnzeitMitSendetagsgrenze: Kanal has no Tagesgrenze", {
        kanal,
      });
    }
    return (group): ValidationErrors | null => {
      if (!(group instanceof FormGroup)) {
        throw new DeveloperError(
          "endzeitVorBeginnzeitMitSendetagsgrenze: group is not a FormGroup",
          {
            group,
          },
        );
      }

      const beginnzeitControl = group.get(beginnzeitKey);
      const endzeitControl = group.get(endzeitKey);

      if (!beginnzeitControl || !endzeitControl) {
        throw new DeveloperError(
          "endzeitVorBeginnzeitMitSendetagsgrenze: beginnzeitControl or endzeitControl is not defined",
          {
            beginnzeitControl,
            beginnzeitKey,
            endzeitControl,
            endzeitKey,
          },
        );
      }

      const beginnzeit: unknown = beginnzeitControl.value;
      const endzeit: unknown = endzeitControl.value;

      if (!beginnzeit || !endzeit) return null;

      if (typeof beginnzeit !== "string") {
        throw new DeveloperError(
          "endzeitVorBeginnzeitMitSendetagsgrenze: beginnzeit is not a string",
          { beginnzeit },
        );
      }

      if (typeof endzeit !== "string") {
        throw new DeveloperError(
          "endzeitVorBeginnzeitMitSendetagsgrenze: endzeit is not a string",
          {
            endzeit,
          },
        );
      }

      // korrekt, wenn Beginnzeit < Endzeit
      if (beginnzeit < endzeit) return null;

      if (beginnzeit < kanalTagesgrenze && endzeit > beginnzeit) return null;

      if (endzeit < kanalTagesgrenze && beginnzeit > kanalTagesgrenze) return null;

      return { endzeitVorBeginnzeitMitSendetagsgrenze: kanalTagesgrenze };
    };
  }
}
