<div class="sidebar-selection-row" (click)="emitTriggerReportDownload(selectedAnsichtViewModel.id)">
  <kendo-svg-icon data-testid="sidebar-ansicht-download" [icon]="Icons.download"></kendo-svg-icon>

  Ansicht herunterladen
</div>
<div
  class="sidebar-selection-row"
  [ngClass]="{
    'selection-disabled': !selectedAnsichtViewModel.ansichtsdefinition.isMengengeruestErlaubt
  }"
  (click)="emitOpenMengengeruesteWindow()"
>
  <kendo-svg-icon data-testid="sidebar-mengengeruest" [icon]="Icons.balance"></kendo-svg-icon>
  Mengengerüst einsehen/bearbeiten
</div>

<div
  class="sidebar-selection-row"
  data-testid="sidebar-ek-anlegen-div"
  [ngClass]="{
    'selection-disabled': ('editieren' | able: 'EK') === false
  }"
  (click)="emitCreateEkWindow()"
>
  <kendo-svg-icon
    data-testid="sidebar-ek-anlegen-button"
    [icon]="Icons.eventKonkurrenz"
  ></kendo-svg-icon>
  Event/Konkurrenzprogramm anlegen
</div>

<!-- Zoomslider -->
<div
  *ngIf="selectedAnsichtViewModel.ansichtsdefinition.layout === Layout.BLOCK"
  class="sidebar-selection-row no-button"
>
  <kendo-svg-icon [icon]="Icons.zoom"></kendo-svg-icon>
  <app-zoom-slider
    [selectedZoomLevel]="zoomLevel"
    (selectedZoomLevelChanged)="emitSelectedZoomLevelChanged($event)"
  >
  </app-zoom-slider>
</div>
