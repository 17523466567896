import { Component, EventEmitter, Input, Output } from "@angular/core";
import { EventDto } from "src/app/models/openapi/model/event-dto";

@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"],
})
export class EventComponent {
  @Input() event: EventDto;
  @Output() eventDblClicked = new EventEmitter<{
    event: MouseEvent;
    isEvent: boolean;
    konkurrenzEvent: EventDto;
  }>();

  protected dblClick(event: MouseEvent, isEvent: boolean, konkurrenzEvent: EventDto) {
    this.eventDblClicked.emit({ event, isEvent, konkurrenzEvent });
  }
}
