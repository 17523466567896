import { createSelector } from "@ngrx/store";
import { AppAbility } from "src/app/casl/app-ability";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { CanEditPlanungsobjektWindowPipe } from "src/app/shared/windows/planungsobjekt-window/can-edit-planungsobjekt-window.pipe";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
  useCasesWithPlanungsobjekt,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { assertUnreachable } from "src/app/utils/function-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { onDemandFeature } from "../on-demand/on-demand.reducer";
import { planungsobjektFeature } from "../planungsobjekt/planungsobjekt.reducer";
import { PlanungsobjektWindowPlanungFormEnablement } from "./planungsobjekt-window.model";
import { planungsobjektWindowFeature } from "./planungsobjekt-window.reducer";
import { extractKanalFromWindowInput } from "./planungsobjekt-window.utils";

/**
 * Wird verwendet um für die EDIT Use Cases das Planungsobjekt zu selektieren und damit die Beziehungen zu .
 * An dieser Stelle wird je nach Use Case das Planungsobjekt aus dem Planungsobjekt- oder OnDemand-Store selektiert.
 * @Bader und @Philip eventuell hier eine bessere Lösung finden, um nicht auf beide Stores zuzugreifen.
 * -> Haben nichts besseres fürs Erste gefunden *shrug*
 */
const selectPlanungsobjektForWindow = createSelector(
  planungsobjektWindowFeature.selectInput,
  planungsobjektFeature.selectEntities,
  onDemandFeature.selectEntities,
  (input, linearEntities, onDemandEntities) => {
    if (!input) return undefined;
    switch (input.usecase) {
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
      case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
        return undefined;
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
      case PlanungsobjektWindowUseCase.READONLY_LINEAR:
        return linearEntities[input.planungsobjektId];
      case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
      case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
        return onDemandEntities[input.planungsobjektId];
      default:
        assertUnreachable(input);
    }
  },
);

const selectInput = createSelector(
  planungsobjektWindowFeature.selectInput,
  selectPlanungsobjektForWindow,
  (input, planungsobjekt): PlanungsobjektWindowInputWithPlanungsobjekt | null => {
    if (input === null) {
      return null;
    }

    if ((<ReadonlyArray<string>>useCasesWithPlanungsobjekt).includes(input.usecase)) {
      if (!planungsobjekt) {
        // @todo logging? should propably not happen...
        return null;
      }

      return <PlanungsobjektWindowInputWithPlanungsobjekt>{
        ...input,
        planungsobjekt,
      };
    }

    return <PlanungsobjektWindowInputWithPlanungsobjekt>{
      ...input,
      planungsobjekt: undefined,
    };
  },
);

const selectPlanungsobjektBeziehungenIds = createSelector(
  selectPlanungsobjektForWindow,
  (planungsobjekt) => planungsobjekt?.beziehungenIds ?? [],
);

const selectHasPlanungsobjektOnDemandId = createSelector(
  selectPlanungsobjektForWindow,
  (planungsobjekt): boolean => !!planungsobjekt?.planungsobjektOnDemandId,
);

const selectKanal = createSelector(selectInput, (input) =>
  input ? extractKanalFromWindowInput(input) : Kanal.UNBEKANNT,
);

const selectPlanungsobjektId = createSelector(
  planungsobjektWindowFeature.selectInput,
  (input) => input?.planungsobjektId,
);

/**
 * Gibt für das aktuelle PlanungsobjektWindow immer den OnDemand-Part einer
 * möglicherweise existierenden LinearOnDemand-Beziehung zurück.
 */
const selectLinearOnDemandBeziehungOnDemandObjekt = createSelector(
  selectPlanungsobjektForWindow,
  onDemandFeature.selectEntities,
  (planungsobjekt, onDemandEntities) => {
    if (!planungsobjekt) return undefined;

    if (PlanungsobjektUtils.isOnDemand(planungsobjekt)) {
      return planungsobjekt;
    }

    const beziehungen = [
      ...planungsobjekt.beziehungenAusgehend,
      ...planungsobjekt.beziehungenEingehend,
    ].filter((beziehung) => beziehung.typ === "LinearOnDemand");

    if (beziehungen.length > 1) {
      throw new Error(
        `Planungsobjekt mit der ID "${planungsobjekt.id}" hat mehr als eine Beziehung vom Typ LinearOnDemand.`,
      );
    }

    const linearOnDemandBeziehung = beziehungen[0];

    if (!linearOnDemandBeziehung) return undefined;

    const otherId =
      linearOnDemandBeziehung.vonId === planungsobjekt.id
        ? linearOnDemandBeziehung.zuId
        : linearOnDemandBeziehung.vonId;

    return onDemandEntities[otherId];
  },
);

/**
 * Selektor, der das Enablement für das Planung Formular zurückgibt.
 */
const selectPlanungFormEnablement = (appAbility: AppAbility) => {
  const permissionTransformer = new CanEditPlanungsobjektWindowPipe(appAbility);

  const canEdit = permissionTransformer.transform.bind(permissionTransformer);

  return createSelector(selectInput, (input): PlanungsobjektWindowPlanungFormEnablement => {
    if (!input) {
      return "no-change";
    }

    const enablement: PlanungsobjektWindowPlanungFormEnablement = {
      onlineAb: "no-change",
      onlineAbZeit: "no-change",
      onlineBis: "no-change",
      sendetag: "no-change",
      beginnzeit: "no-change",
      endzeit: "no-change",
      planlaenge: "no-change",
      variante: "no-change",
      contentCommunities: "no-change",
      farbgebung: "no-change",
      folgennummer: "no-change",
      fsk: "no-change",
      fruehesteVeroeffentlichung: "no-change",
      genre: "no-change",
      gesamtfolgennummer: "no-change",
      highlight: "no-change",
      inhaltsbeschreibung: "no-change",
      mitwirkende: "no-change",
      notiz: "no-change",
      redaktion: "no-change",
      staffelnummer: "no-change",
      stofffuehrendeRedaktion: "no-change",
      titel: "no-change",
    };

    const disablement: PlanungsobjektWindowPlanungFormEnablement = {
      onlineAb: "no-change",
      onlineAbZeit: "no-change",
      onlineBis: "no-change",
      sendetag: "no-change",
      beginnzeit: "no-change",
      endzeit: "no-change",
      planlaenge: "no-change",
      variante: "no-change",
      contentCommunities: "disable",
      farbgebung: "no-change",
      folgennummer: "disable",
      fsk: "disable",
      fruehesteVeroeffentlichung: "no-change",
      genre: "disable",
      gesamtfolgennummer: "disable",
      highlight: "no-change",
      inhaltsbeschreibung: "disable",
      mitwirkende: "disable",
      notiz: "no-change",
      redaktion: "no-change",
      staffelnummer: "disable",
      stofffuehrendeRedaktion: "disable",
      titel: "no-change",
    };

    if (canEdit(input)) {
      return input?.planungsobjekt?.getitId ? disablement : enablement;
    }

    return "no-change";
  });
};

export default {
  selectInput,
  selectPlanungsobjektForEditWindow: selectPlanungsobjektForWindow,
  selectHasPlanungsobjektOnDemandId,
  selectKanal,
  selectPlanungsobjektId,
  selectPlanungsobjektBeziehungenIds,
  selectLinearOnDemandBeziehungOnDemandObjekt,
  selectPlanungFormEnablement,
};
