import { PlanungsobjektWindowOnDemandForm } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.model";
import { DeveloperError } from "src/app/models/errors/technical.error";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { DateFnsService } from "src/app/services/date-fns.service";
import { isDefined } from "src/app/utils/object-utils";
import { PlanungsobjektWindowInput } from "../../planungsobjekt-window.model";
import { onDemandBeziehungFormActions } from "./on-demand-beziehung-form.actions";

export function berechneSynchronisierteOnDemandFormValues(opts: {
  linearSendetag: string | undefined;
  prevFormValue: PlanungsobjektWindowOnDemandForm;
  nextFormValue: PlanungsobjektWindowOnDemandForm;
}) {
  const foundDiff: (keyof PlanungsobjektWindowOnDemandForm)[] = [];
  const throwIfMoreThanOneChange = (property: keyof PlanungsobjektWindowOnDemandForm) => {
    if (foundDiff.length)
      throw new DeveloperError("Mehr als ein Feld wurde geändert", {
        foundDiff,
        property,
        prevFormValue,
        nextFormValue,
      });
    foundDiff.push(property);
  };
  const { linearSendetag, prevFormValue, nextFormValue } = opts;
  const {
    wunschOnlineAb: nextWunschOnlineAb,
    relationZuLinearInTagen: nextRelationZuLinearInTagen,
    onlineAbZeit: nextOnlineAbZeit,
    wunschOnlineBis: nextWunschOnlineBis,
    reihenfolgeHerstellen: nextReihenfolgeHerstellen,
    verweildauerInTagen: nextVerweildauerInTagen,
    minDistanz: nextMinDistanz,
    maxDistanz: nextMaxDistanz,
  } = nextFormValue;
  const { verweildauerInTagen: prevVerweildauerInTagen } = prevFormValue;

  // initialisiere mit vorherigen Werten
  const result: PlanungsobjektWindowOnDemandForm = {
    wunschOnlineAb: nextWunschOnlineAb,
    relationZuLinearInTagen: nextRelationZuLinearInTagen,
    onlineAbZeit: nextOnlineAbZeit,
    wunschOnlineBis: nextWunschOnlineBis,
    reihenfolgeHerstellen: nextReihenfolgeHerstellen,
    verweildauerInTagen: nextVerweildauerInTagen,
    minDistanz: nextMinDistanz,
    maxDistanz: nextMaxDistanz,
  };

  // wunschOnlineAb basiert auf wunschLinear und wird mit angepasst
  if (linearSendetag) {
    if (nextRelationZuLinearInTagen || nextRelationZuLinearInTagen == 0) {
      throwIfMoreThanOneChange("relationZuLinearInTagen");
      const wunschOnlineAb = DateFnsService.addDays(linearSendetag, nextRelationZuLinearInTagen);
      result.wunschOnlineAb = DateFnsService.formatDateAsStringOrNull(wunschOnlineAb);
      // wunschOnlineBis basiert auf wunschOnlineAb und wird mit angepasst sofern eine Verweildauer gesetzt ist
      const verweilDauerInTagen = nextVerweildauerInTagen || prevVerweildauerInTagen;
      if (verweilDauerInTagen) {
        result.wunschOnlineBis = DateFnsService.formatDateAsStringOrNull(
          DateFnsService.addDays(wunschOnlineAb, verweilDauerInTagen),
        );
      }
    }
  }
  return foundDiff ? result : null;
}

export function calculateRelationalZuLinear(
  linear: PlanungsobjektLinearDto,
  ondemand: PlanungsobjektOnDemandDto,
  distanzType: DistanzType,
) {
  // Abhängigkeit von Linear zu OnDemand mit einer Distanz -> Die Distanz entspricht RelationalZuLinear
  const relevanteAbhaengigkeiten = linear.beziehungenAusgehend.filter(
    (beziehung) =>
      beziehung.typ === "Abhaengigkeit" &&
      beziehung.zuId === ondemand.id &&
      beziehung[distanzType] !== null,
  );
  const distanzList = relevanteAbhaengigkeiten
    .map((beziehung) => beziehung[distanzType])
    .filter(isDefined);

  if (distanzList.length < 1) {
    return null;
  } else if (distanzList.length > 1) {
    throw new Error(
      "Es existieren mehrere Abhängigkeiten mit Distanz zwischen einer Linear und OnDemand Beziehung",
    );
  } else {
    // Minuten zu Tage Transformation
    return distanzList[0] / 1440;
  }
}

type DistanzType = "distanz" | "minDistanz" | "maxDistanz";

export function calculateReihenfolgeHerstellen(
  linear: PlanungsobjektLinearDto,
  ondemand: PlanungsobjektOnDemandDto,
) {
  const relevanteReihenfolge = linear.beziehungenAusgehend.filter(
    (beziehung) => beziehung.typ === "Reihenfolge" && beziehung.zuId === ondemand.id,
  );

  return relevanteReihenfolge.length > 0 ? true : false;
}

export function extractLinearOnDemandBeziehungCommandAction(args: {
  windowInput: PlanungsobjektWindowInput;
  onDemandFormValue: PlanungsobjektWindowOnDemandForm;
  shouldCreate: boolean;
}) {
  const { windowInput, onDemandFormValue, shouldCreate } = args;
  const planungsobjektLinearId = windowInput.planungsobjektId;
  if (!planungsobjektLinearId) {
    throw new Error("LinearId ist nicht gesetzt");
  }
  switch (windowInput.planungskontext) {
    case "Vorgeplant":
    case "Vorgeschlagen":
      return shouldCreate
        ? onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellen({
            command: {
              planungsobjektLinearId,
              onlineAb: onDemandFormValue.wunschOnlineAb,
              onlineAbZeit: onDemandFormValue.onlineAbZeit,
              onlineBis: onDemandFormValue.wunschOnlineBis,
              reihenfolgeHerstellen: onDemandFormValue.reihenfolgeHerstellen ?? false,
              relationZuLinearInTagen: onDemandFormValue.relationZuLinearInTagen,
              verweildauerInTagen: onDemandFormValue.verweildauerInTagen,
              minDistanz: onDemandFormValue.minDistanz,
              maxDistanz: onDemandFormValue.maxDistanz,
            },
          })
        : onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisieren({
            enabeledFormGroupValues: onDemandFormValue,
          });
    case "Vorgemerkt":
      return shouldCreate
        ? onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellen({
            command: {
              planungsobjektLinearId,
              onlineAbZeit: onDemandFormValue.onlineAbZeit,
              reihenfolgeHerstellen: onDemandFormValue.reihenfolgeHerstellen ?? false,
              relationZuLinearInTagen: onDemandFormValue.relationZuLinearInTagen!,
              verweildauerInTagen: onDemandFormValue.verweildauerInTagen!,
              minDistanz: onDemandFormValue.minDistanz!,
              maxDistanz: onDemandFormValue.maxDistanz!,
            },
          })
        : onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisieren({
            command: {
              planungsobjektLinearId,
              onlineAbZeit: onDemandFormValue.onlineAbZeit,
              reihenfolgeHerstellen: onDemandFormValue.reihenfolgeHerstellen ?? false,
              relationZuLinearInTagen: onDemandFormValue.relationZuLinearInTagen!,
              verweildauerInTagen: onDemandFormValue.verweildauerInTagen!,
              minDistanz: onDemandFormValue.minDistanz!,
              maxDistanz: onDemandFormValue.maxDistanz!,
            },
          });
    default:
      throw new Error("Unbekannter Planungskontext " + windowInput.planungskontext);
  }
}
