import { createFeature, createReducer, on } from "@ngrx/store";
import { ZOOM_LEVELS, ZOOM_LEVELS_LIST } from "src/app/models/enums/zoom-level";
import { ZoomLevel } from "src/app/models/openapi/model/zoom-level";
import { ManuelleVariantenzeile } from "src/app/pages/ansichten/blockansicht/blockansicht-viewmodel";
import { FeatureKey } from "../feature.keys";
import { blockansichtActions } from "./blockansicht.actions";

/**
 * ZoomLevels sortiert von wenig Blöcken mit jeweils großer Zeitspanne (kompakt)
 * zu vielen Blöcken mit jeweils kleiner Zeitspanne (sehr breit)
 */
const DEFAULT_ZOOM_LEVEL = ZoomLevel.LEVEL4;

export type BlockansichtState = {
  zoomLevel: ZoomLevel;
  varianteZeilen: Record<string, ManuelleVariantenzeile[]>;
};

export const initialBlockansichtState: BlockansichtState = {
  zoomLevel: DEFAULT_ZOOM_LEVEL,
  varianteZeilen: {},
};
export const blockansichtFeature = createFeature({
  name: FeatureKey.Blockansicht,
  reducer: createReducer(
    initialBlockansichtState,
    on(
      blockansichtActions.setCurrentZoomLevel,
      (currentState, { zoomLevel }): BlockansichtState => {
        return { ...currentState, zoomLevel };
      },
    ),
    /**
     * Setzt das nächste Zoomlevel und triggert currentZoomLevel$
     * wenn einmal rein- oder rausgezoomt wird
     * @param zoomIn `false` für ZoomOut
     */
    on(blockansichtActions.setNextZoomLevel, (currentState, { zoomIn }): BlockansichtState => {
      const zoomLevelIndex = zoomIn
        ? Math.min(ZOOM_LEVELS[currentState.zoomLevel].value + 1, 4)
        : Math.max(ZOOM_LEVELS[currentState.zoomLevel].value - 1, 0);
      const zoomLevel = ZOOM_LEVELS_LIST[zoomLevelIndex].key;
      return { ...currentState, zoomLevel };
    }),
    on(blockansichtActions.resetZoomLevel, (currentState): BlockansichtState => {
      return { ...currentState, zoomLevel: DEFAULT_ZOOM_LEVEL };
    }),
    on(
      blockansichtActions.addManuelleVariantenzeile,
      (currentState, { sendetag, maxVariante }): BlockansichtState => {
        const newState = { ...currentState };
        if (sendetag in newState.varianteZeilen) {
          const neueVariantenzeilen = [
            ...newState.varianteZeilen[sendetag],
            { variante: maxVariante },
          ];
          return {
            ...newState,
            varianteZeilen: { ...newState.varianteZeilen, [sendetag]: neueVariantenzeilen },
          };
        } else {
          return {
            ...newState,
            varianteZeilen: {
              ...newState.varianteZeilen,
              [sendetag]: [{ variante: maxVariante }],
            },
          };
        }
      },
    ),
    on(
      blockansichtActions.removeManuelleVariantenzeile,
      (currentState, { sendetag, variante }): BlockansichtState => {
        if (!currentState.varianteZeilen[sendetag]) return currentState;

        let newState: BlockansichtState = { ...currentState };
        const variantenZeilenCopy = { ...newState.varianteZeilen };
        const varianteZeileCopy = [...newState.varianteZeilen[sendetag]];
        const filteredVariante = varianteZeileCopy.filter(
          (variantenzeile) => variantenzeile.variante !== variante,
        );
        if (filteredVariante.length === 0) {
          delete variantenZeilenCopy[sendetag];
          newState = { ...newState, varianteZeilen: variantenZeilenCopy };
        } else {
          newState.varianteZeilen[sendetag] = filteredVariante;
        }
        return newState;
      },
    ),
  ),
});
