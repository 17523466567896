import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap } from "rxjs";
import { ansichtActions } from "../ansicht/ansicht.actions";
import { permissionActions } from "../permissions/permissions.actions";
import { initActions } from "./init.actions";

const actionsAfterUserIsAuthenticated = [
  ansichtActions.loadAnsichten(),
  permissionActions.loadPermissions(),
];

@Injectable()
export class InitEffects {
  private readonly actions$ = inject(Actions);

  loadAnsichtenAfterUserIsAuthenticated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initActions.userIsAuthenticated),
      // Nach der Authentifizierung passieren halt verschiedene Dinge...
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      switchMap(() => actionsAfterUserIsAuthenticated),
    );
  });
}
