<section>
  <ng-container *ngFor="let filter of filterValues$ | async">
    <kendo-chip
      class="filter-chip"
      rounded="full"
      [appTooltipIfEllipsis]="filter.displayValue"
      [attr.data-testid]="'filter-' + filter.value"
      [label]="filter.displayValue"
      [removable]="true"
      (remove)="onRemove(filter)"
    >
    </kendo-chip>
  </ng-container>
</section>
