<form [formGroup]="formGroup">
  <div class="flex-container">Titel: {{ serienWindowInput.planungsobjekt.titel }}</div>

  <div class="flex-container">
    <app-form-field label="Folgennr. ab">
      <kendo-numerictextbox
        formControlName="folgennummerAb"
        format="n0"
        [decimals]="0"
        [min]="1"
        [step]="1"
      >
      </kendo-numerictextbox>
    </app-form-field>
    <app-form-field label="Folgennr. bis">
      <kendo-numerictextbox
        data-testid="folgennummerBis"
        formControlName="folgennummerBis"
        format="n0"
        [decimals]="0"
        [min]="1"
        [step]="1"
      >
      </kendo-numerictextbox>
    </app-form-field>
  </div>

  <div class="flex-container">
    <app-form-field label="Staffelnr.">
      <kendo-numerictextbox
        formControlName="staffelNummer"
        format="n0"
        [decimals]="0"
        [min]="1"
        [step]="1"
      >
      </kendo-numerictextbox>
    </app-form-field>

    <app-form-field label="Folgen in Staffel/Serie">
      <kendo-numerictextbox
        data-testid="anzahlFolgen"
        formControlName="anzahlFolgen"
        format="n0"
        [decimals]="0"
        [min]="1"
        [step]="1"
      >
      </kendo-numerictextbox>
    </app-form-field>
  </div>

  <label class="publit-checkbox flex-start verlinkungen">
    <input formControlName="reihenfolgeVerlinken" kendoCheckBox type="checkbox" />
    <span>Verlinkung des Typs 'Reihenfolge' zwischen den Programmen setzen</span>
  </label>

  <label class="publit-checkbox flex-start blackout-dates">
    <input
      kendoCheckBox
      type="checkbox"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="enableAuszulassendeDaten"
      (ngModelChange)="onEnableAuszulassendeDatenChange($event)"
    />
    <span>Daten ausschließen</span>
  </label>

  <div class="flex-start" style="flex-direction: row; gap: 1rem">
    <kendo-calendar
      *ngIf="enableAuszulassendeDaten"
      formControlName="auszulassendeDaten"
      selection="multiple"
      [focusedDate]="focusedDate"
    >
    </kendo-calendar>

    <div
      *ngIf="formGroup.get('auszulassendeDaten')?.value?.length > 0"
      class="flex-container"
      style="flex-direction: column"
    >
      <h3 style="margin-bottom: 1rem">Ausgeschlossene Daten:</h3>
      <span *ngFor="let date of formGroup.get('auszulassendeDaten')?.value">
        {{ date | date: "dd.MM.yyyy" }}
      </span>
    </div>
  </div>
</form>
<div class="window-action-buttons">
  <button fillMode="outline" kendoButton type="button" (click)="onClose()">Abbrechen</button>
  <button
    class="primary-button"
    kendoButton
    themeColor="primary"
    type="button"
    [disabled]="!formGroup.valid || formGroup.pristine"
    (click)="onSave()"
  >
    Erstellen
  </button>
</div>
