import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { PublitFrontendSettings } from "src/environments/environment";
import { getAccountInfo } from "../services/authorization.service";

@Injectable()
export class AuthorizationMockInterceptor implements HttpInterceptor {
  private frontendSettings = inject(PublitFrontendSettings);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    if (this.frontendSettings.auth.disabled && req.url.includes("/api")) {
      const accountInfo = getAccountInfo();
      request = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${accountInfo.samaccountname}`),
      });
    }

    return next.handle(request);
  }
}
