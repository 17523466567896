import { SenderPlattform } from "../openapi/model/sender-plattform";
import { EnumMetadata, enumRecordToSelectOptions } from "./enum-base";

export const SenderPlattformRecord: Record<SenderPlattform, EnumMetadata> = {
  ARD: { bedeutung: "ARD" },
  Eurosport: { bedeutung: "Eurosport" },
  Kabel1: { bedeutung: "Kabel1" },
  Pro7: { bedeutung: "Pro7" },
  RTL: { bedeutung: "RTL" },
  RTL2: { bedeutung: "RTL2" },
  Sat1: { bedeutung: "Sat1" },
  Vox: { bedeutung: "Vox" },
  AmazonPrime: { bedeutung: "Amazon Prime Video" },
  DAZN: { bedeutung: "DAZN" },
  DisneyPlus: { bedeutung: "Disney+" },
  Joyn: { bedeutung: "Joyn" },
  MagentaTV: { bedeutung: "MagentaTV" },
  Netflix: { bedeutung: "Netflix" },
  RTLPlus: { bedeutung: "RTL+" },
  Sky: { bedeutung: "Sky" },
  Sonstiges: { bedeutung: "Sonstiges" },
};

export const SenderPlattformOptions = enumRecordToSelectOptions(SenderPlattformRecord);
