<kendo-datetimepicker
  format="dd.MM.yyyy HH:mm"
  [disabled]="isDisabled"
  [focusedDate]="focusedDate | toDate"
  [incompleteDateValidation]="true"
  [max]="maxDate | toDate"
  [min]="minDate | toDate"
  [value]="internalDate"
  (valueChange)="onValueChange($event)"
></kendo-datetimepicker>
