import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { take } from "rxjs";
import { LogApiService } from "../api/log/log.api.service";
import { DeveloperError } from "../models/errors/technical.error";
import { ClientLogLevel } from "../models/openapi/model/client-log-level";
import { LogRequest } from "../models/openapi/model/log-request";
import { CustomNotificationService } from "../shared/notifications/custom-notification.service";
import { HttpRequestError } from "./http-request-error";

/**
 * Zone.js wrappt Fehler in Promises in eigenes Objekt, das den
 * eigentlichen Fehler in "rejection" Property beinhaltet
 */
const isZoneWrappedPromiseRejection = (error: unknown): error is { rejection: Error } => {
  return "promise" in (error as any) && "rejection" in (error as any);
};

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(
    private injector: Injector,
    private zone: NgZone,
    private logApiService: LogApiService,
  ) {}

  lastErrorForBackend: LogRequest | undefined = undefined;
  lastErrorForUser: string | undefined = undefined;
  lastError = 0;

  handleError(errorUnknown: unknown) {
    // unwrap zone.js wrapped promise rejections
    const error = isZoneWrappedPromiseRejection(errorUnknown)
      ? errorUnknown.rejection
      : errorUnknown;

    // Fehler in jedem Fall für Entwickler anzeigen
    console.error(error);

    const { errorForUser, errorForBackend } = this.getErrors(error);

    // Fehler werden im UI angezeigt und an das Backend verschickt,
    // wenn sie sie mit einem gewissen Abstand auftreten oder sich es sich um
    // einen anderen Fehler als den vorhergehenden handelt. Dies verhindert, dass die
    // Oberfläche nicht mehr reagiert wenn Fehler z.B. durch Obserables in einer Schleife auftreten.

    if (
      errorForUser &&
      (this.isLongTimeSinceLastError() ||
        !this.isSameErrorForUser(errorForUser, this.lastErrorForUser))
    ) {
      this.showNotification(errorForUser);
    }

    if (
      this.isLongTimeSinceLastError() ||
      !this.isSameErrorForBackend(errorForBackend, this.lastErrorForBackend)
    ) {
      this.writeToBackend(errorForBackend);
    }

    this.lastError = Date.now();
    if (errorForUser) {
      this.lastErrorForUser = errorForUser;
    }
    this.lastErrorForBackend = errorForBackend;
  }

  private getErrors(error: unknown | Error) {
    // Ein Fehler aus dem Global Error Handler, welcher in Zusammenhang mit einem HTTP Request steht
    if (error instanceof HttpRequestError) {
      return this.parseHttpRequestError(error);
    }
    // Ein Fehler aus dem Global Error Handler, welcher in Zusammenhang mit einem HTTP Response steht
    else if (error instanceof HttpErrorResponse) {
      return this.parseHttpErrorResponse(error);
    } else if (error instanceof DeveloperError) {
      return this.parseDeveloperError(error);
    }
    // TODO - Wann kommt es zu diesem Fehlerfall?
    else if (error instanceof Error) {
      return this.parseRegularError(error);
    }

    // TODO - Wann kommt es zu diesem Fehlerfall?
    return this.parseFallback(error);
  }
  /**
   * Sind seit dem letzten Fehler mehr als 1000ms vergangen?
   */
  private isLongTimeSinceLastError(): boolean {
    return Date.now() - this.lastError > 1000;
  }

  /**
   * Verhindert eine Endlosschleife von Fehlermeldungen an die Anwender.
   */
  private isSameErrorForUser(currentError: string, lastError: string | undefined): boolean {
    return currentError === lastError;
  }

  /**
   * Verhindert eine Endlosschleife von Fehlermeldungen an das Backend.
   */
  private isSameErrorForBackend(
    currentError: LogRequest,
    lastError: LogRequest | undefined,
  ): boolean {
    return (
      (currentError === undefined && lastError === undefined) ||
      (currentError !== undefined &&
        lastError !== undefined &&
        currentError.message === lastError.message &&
        currentError.level === lastError.level &&
        // Das könnte man eleganter lösen, sollte für unsere Zwecke aber ausreichen
        // oder einfach eine Deep Equals Library verwenden
        currentError.properties.length === lastError.properties.length)
    );
  }

  /**
   * TODO: Was ist der genaue Zweck dieser Methode?
   * Ursprünglich in ErrorService.
   */
  private getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return "No Internet Connection";
    }
    return error.message ? error.message : error.toString();
  }

  private showNotification(message: string) {
    if (message === undefined) {
      return;
    }

    const notificationService = this.injector.get(CustomNotificationService);
    this.zone.run(() => notificationService.showErrorNotification(message));
  }

  private writeToBackend(message: LogRequest) {
    if (message === undefined) {
      return;
    }

    this.logApiService
      .createLog$(message)
      .pipe(take(1))
      .subscribe({
        next: (response) =>
          console.log(`Persisted log created with correlation id ${response.correlationId}`),
        error: (error: unknown) =>
          console.error("Error creating persisted log", { error, message }),
      });
  }

  private parseHttpRequestError(error: HttpRequestError): {
    errorForUser: string;
    errorForBackend: LogRequest;
  } {
    // Im Global Error Handler wurde hier bereits die Nachricht aus dem Problem Details Objekt extrahiert
    const errorForUser = error.message;
    // In diesem Fall wissen wir in Backend bereits von dem Fehler, ihn daher
    // nochmal an das Backend zu schicken ist nicht wirklich notwendig
    const errorForBackend: LogRequest = {
      level: ClientLogLevel.ERROR,
      message: error.message,
      properties: error.requestData ?? {},
    };

    return { errorForUser, errorForBackend };
  }

  private parseHttpErrorResponse(error: HttpErrorResponse): {
    errorForUser: string;
    errorForBackend: LogRequest;
  } {
    // Im Global Error Handler wurde hier bereits die Nachricht aus dem Problem Details Objekt extrahiert
    const errorForUser = error.message;
    // In diesem Fall wissen wir in Backend bereits von dem Fehler, ihn daher
    // nochmal an das Backend zu schicken ist nicht wirklich notwendig
    const errorForBackend = {
      level: ClientLogLevel.ERROR,
      message: error.message,
      properties: {
        status: error.status,
        statusText: error.statusText,
        url: error.url,
      },
    };

    return { errorForUser, errorForBackend };
  }

  private parseDeveloperError(error: DeveloperError): {
    errorForUser: string;
    errorForBackend: LogRequest;
  } {
    const errorForUser = error.message;
    const errorForBackend: LogRequest = {
      message: errorForUser,
      level: ClientLogLevel.ERROR,
      properties: error.props ?? {},
    };

    return { errorForUser, errorForBackend };
  }

  private parseRegularError(error: Error): {
    errorForUser: string;
    errorForBackend: LogRequest;
  } {
    const errorForUser = error.message ? error.message : this.getClientMessage(error);
    const errorForBackend = {
      message: errorForUser,
      level: ClientLogLevel.ERROR,
      properties: {
        name: error.name,
        cause: error.cause,
        stack: error.stack,
      },
    };

    return { errorForUser, errorForBackend };
  }

  private parseFallback(error: unknown): {
    errorForUser: string;
    errorForBackend: LogRequest;
  } {
    const errorForUser = error as string;
    const errorForBackend = {
      message: errorForUser,
      level: ClientLogLevel.ERROR,
      properties: [],
    };

    return { errorForUser, errorForBackend };
  }
}
