import { createFeature, createReducer, on } from "@ngrx/store";
import { ContentCommunity } from "src/app/models/openapi/model/content-community";
import { Genre } from "src/app/models/openapi/model/genre";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import {
  compareArraysIgnoringOrder,
  shiftElement,
  toggleValuesInArray,
} from "src/app/utils/array-utils";
import { cloneDate } from "src/app/utils/date-utils";
import { FeatureKey } from "../feature.keys";
import { rechercheActions } from "./recherche.actions";
import {
  FilterEnum,
  GridColumnProperties,
  GridResultColumnFields,
  RechercheGridResultColumn,
  defaultGridColumns,
} from "./recherche.model";
import {
  containsLinearAusspielweg,
  containsOnDemandAusspielweg,
  determineActiveAdditionalFiltersFromQuery,
  determineToggledColumnsFromQuery,
  getQueryFromState,
  upsertManyForPlanungsobjekte,
} from "./recherche.utils";

export type RechercheState = {
  results: PlanungsobjekteDto;
  isFirstSearch: boolean;
  /**
   * Enthält die IDs der Ergebnisse, die in der Tabelle expandiert sind.
   * Wird genutzt, um die Tabelle nach dem Neuladen der Daten wieder in den
   * gleichen Zustand zu versetzen in der Zukunft und den Zustand expandierter Einträge insgesamt zu verwalten.
   */
  expandedIds: string[];
  /**
   * Enthält die IDs der Ergebnisse, die mit anderen Planungsobjekten bzgl. Sendeplatz oder Blockansicht-Variante überlappen.
   * Wird visuell für jeden Eintrag am Titel angezeigt
   */
  idsWithVarianten: string[];
  /**
   * Brauchen wir, weil wir alle Planungsobjekte zentral im Store halten (parent und children gemischt). Ohne diese Information
   * ist nicht klar, ob ein bestimmtes linear/ondemand PO als Parent oder Kind (oder BEIDES) angezeigt werden soll und ob es überhaupt Teil der
   * angezeigten Suchergebnisse ist, weil Result und Query zu diesem Zeitpunkt isoliert voneinander sind.
   */
  resultIds: string[];
  /**
   * Wenn die Filter geändert wurden, wird dieser Wert auf true gesetzt.
   * Ermöglicht z.B. beim Triggern der Suche über den Browser mittels
   * {@link rechercheActions.searchByBrowserNavigation} ein einfaches
   * zurücksetzen des Flags.
   */
  isSearchFormDirty: boolean;
  shownColumns: RechercheGridResultColumn[];
  /**
   * Enthält die Eigenschaften der Spalten, die in der Tabelle angezeigt werden.
   * Wird genutzt, um die Breite der Spalten zu speichern.
   * Und wird initialisiert mit den Default-Werten in recherche.model.ts
   */
  columnProperties: GridColumnProperties[];
  activeAdditionalFilters: FilterEnum[];

  von: Date;
  bis: Date;
  kanaeleSelected: Kanal[];
  redaktionenSelected: Redaktion[];
  highlightsOnly: boolean;
  titelFilter: string;
  genreSelected: Genre[];
  planungskontexteSelected: Planungskontext[];
  contentCommunitiesSelected: ContentCommunity[];
};

const von = new Date(Date.now());
const bis = cloneDate(von);
bis.setMonth(von.getMonth() + 6);

export const initialRechercheState: RechercheState = Object.freeze({
  results: { linear: [], onDemand: [] },
  expandedIds: [],
  idsWithVarianten: [],
  resultIds: [],
  isFirstSearch: true,
  isSearchFormDirty: false,
  shownColumns: [],
  columnProperties: defaultGridColumns,
  activeAdditionalFilters: [],

  von,
  bis,
  kanaeleSelected: [Kanal.ZDF],
  redaktionenSelected: [],
  highlightsOnly: false,
  titelFilter: "",
  genreSelected: [],
  planungskontexteSelected: [],
  contentCommunitiesSelected: [],
});

export const rechercheFeature = createFeature({
  name: FeatureKey.Recherche,
  reducer: createReducer(
    initialRechercheState,

    on(
      rechercheActions.search,
      /**
       * Führt mit den aktuellen Werten des States eine Suche aus.
       *
       * Falls die Query nicht valide ist, wird der State nicht verändert.
       * Dies kann (aktuell) nicht passieren, da der Such-Button nur aktiviert ist,
       * wenn das Formular der Suche valide ist.
       *
       * Wenn es sich um die erste Suche handelt, werden abhängig von den eingestellten Filtern zusätzliche Spalten eingeblendet.
       */
      (currentState): RechercheState => {
        const isFirstSearch = currentState.isFirstSearch;
        if (isFirstSearch) {
          const currentQueryVM = getQueryFromState(currentState);
          if (!currentQueryVM) return currentState;
          const newColumnsToHide = determineToggledColumnsFromQuery(isFirstSearch, currentQueryVM);
          return {
            ...currentState,
            isSearchFormDirty: false,
            shownColumns: newColumnsToHide,
            isFirstSearch: false,
          };
        } else {
          return { ...currentState, isSearchFormDirty: false };
        }
      },
    ),
    on(
      rechercheActions.searchByBrowserNavigation,
      (currentState, { query, shownColumns }): RechercheState => {
        const columns = shownColumns;

        const activeAdditionalFilters = determineActiveAdditionalFiltersFromQuery(query);
        const {
          genres,
          highlightsOnly,
          kanaele,
          planungskontext,
          redaktionen,
          sendetagBis,
          sendetagVon,
          titel,
          contentCommunities,
        } = query;
        return {
          ...currentState,
          isSearchFormDirty: false,
          shownColumns: columns,
          activeAdditionalFilters,
          isFirstSearch: false,
          von: sendetagVon,
          bis: sendetagBis,
          genreSelected: genres,
          highlightsOnly,
          kanaeleSelected: kanaele,
          planungskontexteSelected: planungskontext,
          redaktionenSelected: redaktionen,
          titelFilter: titel,
          contentCommunitiesSelected: contentCommunities,
        };
      },
    ),
    on(
      rechercheActions.searchSuccess,
      (
        currentState,
        { results, idsWithVarianten: isOverlapping, isChild, isParent },
      ): RechercheState => {
        return {
          ...currentState,
          results,
          isFirstSearch: false,
          idsWithVarianten: isOverlapping,
          resultIds: isParent,
          expandedIds: [],
        };
      },
    ),
    on(rechercheActions.searchFailure, (currentState): RechercheState => {
      return {
        ...currentState,
        results: { linear: [], onDemand: [] },
        idsWithVarianten: [],
        resultIds: [],
      };
    }),
    on(rechercheActions.searchReset, (): RechercheState => {
      return {
        ...initialRechercheState,
      };
    }),
    on(rechercheActions.searchChildren, (currentState, { parentIds }): RechercheState => {
      return {
        ...currentState,
        expandedIds: [...currentState.expandedIds, ...parentIds],
      };
    }),

    on(rechercheActions.searchChildrenSuccess, (currentState, action): RechercheState => {
      const results = upsertManyForPlanungsobjekte(currentState.results, action.results);

      const idsWithVarianten = Array.from(
        new Set([...currentState.idsWithVarianten, ...action.idsWithVarianten]),
      );

      return {
        ...currentState,
        results,
        idsWithVarianten,
      };
    }),

    on(rechercheActions.collapseParents, (currentState, { parentIds }): RechercheState => {
      return {
        ...currentState,
        expandedIds: currentState.expandedIds.filter((id) => !parentIds.includes(id)),
      };
    }),

    on(rechercheActions.resizeGridColumns, (currentState, action): RechercheState => {
      const newColumnProperties: GridColumnProperties[] = currentState.columnProperties.slice();

      for (const resizedColumn of action.resizedColumns) {
        const columnIndex = currentState.columnProperties.findIndex(
          (prop) => prop.field === resizedColumn.field,
        );
        if (columnIndex !== -1) {
          const modifiedProperty = { ...currentState.columnProperties[columnIndex] };
          modifiedProperty.width = resizedColumn.width;
          newColumnProperties[columnIndex] = { ...modifiedProperty };
        }
      }

      return { ...currentState, columnProperties: newColumnProperties };
    }),

    on(rechercheActions.updateQuery, (currentState, { query }): RechercheState => {
      const newState: RechercheState = { ...currentState, isSearchFormDirty: true };
      if (!compareArraysIgnoringOrder(query.kanaele, currentState.kanaeleSelected)) {
        // shownColumns muss ggf. angepasst werden, wenn sich die Kanäle ändern (außer bei der ersten Suche)
        // gemäß defaultSortierung erst ggf. der Sendetag, falls linear Kanäle hinzu kommen, dann ggf. Online ab

        // Wenn ein linearer Ausspielweg hinzugefügt wird, dann muss die Spalte "Sendetag" eingeblendet werden
        if (
          !containsLinearAusspielweg(currentState.kanaeleSelected) &&
          containsLinearAusspielweg(query.kanaele)
        ) {
          // Wenn die Spalte noch nicht eingeblendet ist, dann füge sie hinzu
          newState.shownColumns = currentState.shownColumns.includes(
            GridResultColumnFields.SENDETAG,
          )
            ? currentState.shownColumns
            : [...currentState.shownColumns, GridResultColumnFields.SENDETAG];
        }

        // Wenn ein OnDemand-Ausspielweg hinzugefügt wird, dann muss die Spalte "Online ab" eingeblendet werden
        if (
          !containsOnDemandAusspielweg(currentState.kanaeleSelected) &&
          containsOnDemandAusspielweg(query.kanaele)
        ) {
          // Wenn die Spalte noch nicht eingeblendet ist, dann füge sie hinzu
          newState.shownColumns = currentState.shownColumns.includes(
            GridResultColumnFields.ONLINEAB,
          )
            ? currentState.shownColumns
            : [...currentState.shownColumns, GridResultColumnFields.ONLINEAB];
        }

        newState.kanaeleSelected = query.kanaele;
      }

      if (!compareArraysIgnoringOrder(query.redaktionen, currentState.redaktionenSelected))
        newState.redaktionenSelected = query.redaktionen;

      if (!compareArraysIgnoringOrder(query.genres, currentState.genreSelected))
        newState.genreSelected = query.genres;

      if (!compareArraysIgnoringOrder(query.planungskontext, currentState.planungskontexteSelected))
        newState.planungskontexteSelected = query.planungskontext;

      if (
        !compareArraysIgnoringOrder(
          query.contentCommunities,
          currentState.contentCommunitiesSelected,
        )
      )
        newState.contentCommunitiesSelected = query.contentCommunities;

      if (query.highlightsOnly !== currentState.highlightsOnly)
        newState.highlightsOnly = query.highlightsOnly;

      if (query.titel !== currentState.titelFilter) newState.titelFilter = query.titel;

      if (query.sendetagVon.getTime() !== currentState.von.getTime())
        newState.von = query.sendetagVon;

      if (query.sendetagBis.getTime() !== currentState.bis.getTime())
        newState.bis = query.sendetagBis;

      return newState;
    }),

    on(rechercheActions.toggleGridColumns, (currentState, action): RechercheState => {
      const shownColumns = toggleValuesInArray(action.columns, currentState.shownColumns);
      return { ...currentState, shownColumns };
    }),

    on(rechercheActions.setAdditionalFilters, (currentState, { filters }): RechercheState => {
      const removedAdditionalFilters = currentState.activeAdditionalFilters.filter(
        (activeFilter) => !filters.includes(activeFilter),
      );

      const newState: RechercheState = { ...currentState, activeAdditionalFilters: filters };

      mutateFilterStateOnRemoveAdditionalFilters(newState, removedAdditionalFilters);

      return newState;
    }),

    on(rechercheActions.removeAdditionalFilter, (currentState, { filter }): RechercheState => {
      const activeAdditionalFilters = currentState.activeAdditionalFilters.filter(
        (activeFilter) => activeFilter !== filter,
      );
      const newState: RechercheState = { ...currentState, activeAdditionalFilters };

      mutateFilterStateOnRemoveAdditionalFilters(newState, [filter]);

      if (filter === FilterEnum.TITEL) newState.titelFilter = "";
      if (filter === FilterEnum.GENRE) newState.genreSelected = [];
      if (filter === FilterEnum.PLANUNGSKONTEXT) newState.planungskontexteSelected = [];
      if (filter === FilterEnum.CONTENT_COMMUNITY) newState.contentCommunitiesSelected = [];

      return newState;
    }),
    on(rechercheActions.reorderRechercheColumns, (currentState, action): RechercheState => {
      const newShownColumns = shiftElement(
        currentState.shownColumns,
        action.oldIndex,
        action.newIndex,
      );

      return { ...currentState, shownColumns: newShownColumns };
    }),
  ),
});

function mutateFilterStateOnRemoveAdditionalFilters(
  currentState: RechercheState,
  removedAdditionalFilters: FilterEnum[],
): void {
  if (removedAdditionalFilters.includes(FilterEnum.TITEL)) currentState.titelFilter = "";
  if (removedAdditionalFilters.includes(FilterEnum.GENRE)) currentState.genreSelected = [];
  if (removedAdditionalFilters.includes(FilterEnum.PLANUNGSKONTEXT))
    currentState.planungskontexteSelected = [];
  if (removedAdditionalFilters.includes(FilterEnum.CONTENT_COMMUNITY))
    currentState.contentCommunitiesSelected = [];
}
