import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import {
  MengengeruestWindowComponentResult,
  MengengeruestWindowInput,
} from "src/app/shared/windows/mengengeruest-window/mengengeruest-window.component";
import {
  MengengeruestEintragWindowCreateResult,
  MengengeruestEintragWindowUpdateResult,
} from "src/app/shared/windows/mengengeruesteintrag-window/mengengeruesteintrag-window.component";

export const mengengeruestWindowActions = createActionGroup({
  source: "Mengengeruest Window",
  events: {
    openDeleteMengengeruesteintragDialog: props<{
      mengengeruesteintrag: MengengeruesteintragDto;
    }>(),
    openMengengeruestWindow: props<{
      ansichtViewModel: AnsichtViewModel;
    }>(),
    openMengengeruestZuweisungWindow: props<{
      input: MengengeruestWindowInput;
    }>(),
    handleMengengeruestWindowResult: props<{
      result: MengengeruestWindowComponentResult | WindowCloseResult;
    }>(),
    openCreateMengengeruestEintragWindow: props<{
      ansichtId: string;
      schemaplatzId: string;
      presetBeginnzeit: string | null;
    }>(),
    handleCreateMengengeruestEintragWindowResult: props<{
      result: MengengeruestEintragWindowCreateResult | WindowCloseResult;
    }>(),
    openEditMengengeruestEintragWindow: props<{
      oldMengengeruesteintrag: MengengeruesteintragDto;
    }>(),
    handleEditMengengeruestEintragWindowResult: props<{
      result: MengengeruestEintragWindowUpdateResult | WindowCloseResult;
    }>(),
    closeWindow: emptyProps(),
  },
});
