import { Component, Input, OnInit, inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { AppAbility } from "src/app/casl/app-ability";
import { aktivitaetenActions } from "src/app/core/stores/aktivitaeten/aktivitaeten.actions";
import aktivitaetenSelectors from "src/app/core/stores/aktivitaeten/aktivitaeten.selectors";
import beziehungSelectors from "src/app/core/stores/beziehung/beziehung.selectors";
import { notificationActions } from "src/app/core/stores/notification/notification.actions";
import planungsobjektWindowWannWoSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window-wann-wo.selectors";
import { planungsobjektWindowActions } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { PlanungsobjektWindowTabEnum } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.model";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import planungsobjektWindowSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.selectors";
import { planungsobjektBeziehungWindowActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.beziehung.window.actions";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { Role } from "src/app/models/openapi/model/role";
import { stringToEnum } from "src/app/utils/enum-utils";
import { PublitFrontendSettings } from "src/environments/environment";
import { PlanungsobjektWindowPlanungFormService } from "./planungsobjekt-window-planung-form.service";
import { PlanungsobjektWindowFacade } from "./planungsobjekt-window.facade";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "./planungsobjekt-window.model";

@Component({
  selector: "app-planungsobjekt-window",
  templateUrl: "./planungsobjekt-window.component.html",
  styleUrls: ["./planungsobjekt-window.component.scss"],
  providers: [PlanungsobjektWindowPlanungFormService, PlanungsobjektWindowFacade],
})
export class PlanungsobjektWindowComponent implements OnInit {
  public readonly facade = inject(PlanungsobjektWindowFacade);

  @Input() input: PlanungsobjektWindowInputWithPlanungsobjekt;

  readonly planungsobjekt$ = this.facade.planungsobjekt$;

  readonly Role = Role;
  readonly PlanungsobjektWindowTabEnum = PlanungsobjektWindowTabEnum;
  readonly PlanungsobjektWindowUseCase = PlanungsobjektWindowUseCase;

  readonly planungForm$ = this.formService.form$;

  readonly planungFormEnablement$ = this.store.select(
    planungsobjektWindowSelectors.selectPlanungFormEnablement(this.appAbility),
  );

  readonly kanal$ = this.store.select(planungsobjektWindowSelectors.selectKanal);

  readonly publikationsart$ = this.store.select(
    planungsobjektWindowWannWoSelectors.selectPublikationsart,
  );

  readonly currentPlanungsobjekt$ = this.store.select(
    planungsobjektWindowSelectors.selectPlanungsobjektForEditWindow,
  );

  private triedToSave = false;

  protected readonly selectedTab$ = this.store.select(
    planungsobjektWindowFeature.selectSelectedTab,
  );
  protected readonly hasPlanungsobjektOnDemandId$ = this.store.select(
    planungsobjektWindowSelectors.selectHasPlanungsobjektOnDemandId,
  );
  protected readonly reihenfolgen$ = this.store.select(
    beziehungSelectors.selectReihenfolgeByPlanungsobjekt,
  );
  protected readonly abhaengigkeiten$ = this.store.select(
    beziehungSelectors.selectAbhaengigkeitenByPlanungsobjekt,
  );
  protected readonly onDemandBeziehungen$ = this.store.select(
    beziehungSelectors.selectLinearOnDemandBeziehungByPlanungsobjekt,
  );
  protected readonly linearOnDemandBeziehungOnDemandObjekt$ = this.store.select(
    planungsobjektWindowSelectors.selectLinearOnDemandBeziehungOnDemandObjekt,
  );
  protected readonly aktivitaeten$ = this.store.select(
    aktivitaetenSelectors.selectAktivitaetenByPlanungsobjektInEditDialog,
  );
  protected readonly isPlanungFormDirty$ = this.store.select(
    planungsobjektWindowFeature.selectIsPlanungFormDirty,
  );

  constructor(
    private store: Store,
    private formService: PlanungsobjektWindowPlanungFormService,
    protected settings: PublitFrontendSettings,
    private appAbility: AppAbility,
  ) {}

  ngOnInit(): void {
    if (!this.input) {
      throw new Error("[PlanungsobjektWindowComponent] Window input wird benötigt");
    } else if (!stringToEnum(this.input.usecase, PlanungsobjektWindowUseCase)) {
      throw new Error(`[PlanungsobjektWindowComponent] Unbekannter Usecase ${this.input.usecase}`);
    }
    this.formService.initialize(this.input);
  }

  onClose() {
    this.store.dispatch(planungsobjektWindowActions.closePlanungsobjektWindow());
  }

  onDelete() {
    this.store.dispatch(planungsobjektWindowActions.deletePlanungsobjekt());
  }

  onSave() {
    const alreadyTriedToSave = this.triedToSave;
    this.triedToSave = true;
    const canSave = this.formService.canSave();
    if (canSave) {
      // Zwischenspeichern oder speichern über Drücken des Speichern-Buttons
      this.store.dispatch(planungsobjektWindowActions.savePlanungsobjektWindow());
    } else {
      if (alreadyTriedToSave) {
        this.store.dispatch(
          notificationActions.showNotification({
            message: "Das Formular enthält noch Fehler",
            notificationType: "Error",
          }),
        );
      }
    }
  }

  onTabSelect(event: SelectEvent, planungForm: FormGroup) {
    const clickedTab = stringToEnum(event.title, PlanungsobjektWindowTabEnum);
    if (!clickedTab) {
      throw new Error(`${event.title} existiert nicht im Enum PlanungsobjektWindowTabEnum`);
    }

    const isFormValid = planungForm.valid;
    if (!planungForm.pristine) {
      planungForm.markAllAsTouched();
    }

    // wechseln des Tabs immer verhindern und stattdessen durch Effect steuern,
    // der als Resultat `selectedTab` im Store aktualisiert oder auch nicht
    event.preventDefault();
    this.store.dispatch(
      planungsobjektWindowActions.tryChangeTab({
        newTab: clickedTab,
        isFormValid,
      }),
    );
  }

  onDeleteBeziehung(beziehung: BeziehungDto) {
    if (
      this.input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
      this.input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
      this.input.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
    ) {
      this.store.dispatch(
        planungsobjektBeziehungWindowActions.openDeletePlanungsobjektBeziehungWindow({
          planungsobjekt: this.input.planungsobjekt,
          beziehung,
        }),
      );
    }
  }

  onLoadAktivitaetenForPlanungsobjekt() {
    if (this.input.planungsobjekt?.id) {
      this.store.dispatch(
        aktivitaetenActions.loadAktivitaetenForPlanungsobjekt({
          planungsobjektId: this.input.planungsobjektId,
        }),
      );
    }
  }
}
