<ng-container
  *ngIf="{
    planenOnSendeplatz: 'planen' | able: (sendeplatz | asSubject: 'Sendeplatz'),
    mengengeruestEditieren:
      'editieren' | able: ({ kanal: ansichtViewModel.kanal } | asSubject: 'Mengengeruest')
  } as can"
>
  <ng-container
    *ngIf="{
      planungsobjekteInMehrfachauswahl: planungsobjekteInMehrfachauswahl$ | async
    } as vm"
  >
    <app-planungsobjekt-vorgeplant-list
      [ansichtViewModel]="ansichtViewModel"
      [isMengengeruestErlaubt]="isMengengeruestErlaubt"
      [planungsobjekteInMehrfachauswahl]="vm.planungsobjekteInMehrfachauswahl"
      [schemaplatz]="schemaplatz"
      [sendeplatz]="sendeplatz"
      [target]="target"
    >
    </app-planungsobjekt-vorgeplant-list>

    <input
      *ngIf="can.planenOnSendeplatz"
      class="sendeplatz-input"
      placeholder="+"
      title="Titel hinzufügen"
      type="text"
      (blur)="addPlanungsobjektInAnsicht($event)"
      (keyup)="onInputEnter($event)"
    />
  </ng-container>
</ng-container>
