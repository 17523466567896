import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe, die es ermöglicht ein Array anhand eines Property-Keys zu mappen.
 *
 * Beispiel: `data: { id: number; name: string }[]`
 * ```html
 * <p *ngFor="let name of data | mapByKey: 'name'">
 *  {{ name }}
 * </p>
 * ```
 */
@Pipe({
  name: "mapByKey",
})
export class MapByKeyPipe implements PipeTransform {
  transform<T, K extends keyof T & string>(arrayValue: T[], key: K): T[K][] {
    return arrayValue.map((value) => value[key]);
  }
}
