import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { EventKategorie } from "src/app/models/openapi/model/event-kategorie";
import { EventViewModel } from "src/app/models/viewmodels/event-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import { compareString } from "src/app/utils/string-utils";
import { ansichtActions } from "../ansicht/ansicht.actions";
import { FeatureKey } from "../feature.keys";
import { konkurrenzprogrammActions } from "../konkurrenzprogramm/konkurrenzprogramm.actions";
import { eventActions } from "./event.actions";

export interface EventState extends EntityState<EventDto> {
  loading: boolean;
}

export const eventKategorieOrder = [
  EventKategorie.FEIERTAG,
  EventKategorie.SPORTVERANSTALTUNG,
  EventKategorie.POLITISCHES_EREIGNIS,
  EventKategorie.GEDENKTAG,
  EventKategorie.GESELLSCHAFTLICHES_EREIGNIS,
  EventKategorie.THEMENTAG_WOCHE,
  EventKategorie.KEINE_KATEGORIE,
];

export const eventViewModelComparer = (a1: EventViewModel, a2: EventViewModel): number => {
  const kategoriePosition1 = eventKategorieOrder.indexOf(a1.kategorie);
  const kategoriePosition2 = eventKategorieOrder.indexOf(a2.kategorie);
  const kategorieCompare = kategoriePosition1 - kategoriePosition2;
  if (kategorieCompare !== 0) return kategorieCompare;

  const secondsA1 = DateFnsService.parseDateAndTimeToDateObject(a1.vonZeit, a1.vonDatum).getTime();
  const secondsA2 = DateFnsService.parseDateAndTimeToDateObject(a2.vonZeit, a2.vonDatum).getTime();

  const timeCompare = secondsA1 - secondsA2;
  if (timeCompare !== 0) return timeCompare;

  return compareString(a1.titel, a2.titel);
};

export const eventAdapter: EntityAdapter<EventDto> = createEntityAdapter<EventDto>();

export const initialState: EventState = eventAdapter.getInitialState({
  loading: false,
});

export const eventFeature = createFeature({
  name: FeatureKey.Event,
  reducer: createReducer(
    initialState,
    on(eventActions.loadEventsByAnsichtenSuccess, (currentState, { events }) =>
      eventAdapter.upsertMany(events, { ...currentState, loading: false }),
    ),
    on(eventActions.loadEventsByYearSuccess, (currentState, { events }) =>
      eventAdapter.upsertMany(events, { ...currentState, loading: false }),
    ),
    on(
      ansichtActions.loadEventsKonkurrenzprogrammeForYear,
      ansichtActions.updateAllEntitiesInAnsicht,
      (currentState): EventState => {
        return { ...currentState, loading: true };
      },
    ),
    // on(eventActions.loadEventsByAnsichtenError, (currentState, { error }): EventState => {
    //   return { ...currentState, loading: false };
    // }),
    on(
      eventActions.createEventSuccess,
      eventActions.copyEventSuccess,
      eventActions.convertKonkurrenzprogrammToEventSuccess,
      (currentState, { event }) => eventAdapter.addOne(event, currentState),
    ),
    on(eventActions.updateEventSuccess, (currentState, { event }) =>
      eventAdapter.updateOne({ id: event.id, changes: event }, currentState),
    ),
    on(eventActions.deleteEventSuccess, (currentState, { eventId }) =>
      eventAdapter.removeOne(eventId, currentState),
    ),
    on(
      konkurrenzprogrammActions.convertEventToKonkurrenzprogrammSuccess,
      (currentState, { convertedGUID }) => eventAdapter.removeOne(convertedGUID, currentState),
    ),
    on(eventActions.setAllEvents, (currentState, { events }) =>
      eventAdapter.setAll(events, { ...currentState, loading: false }),
    ),
  ),
  extraSelectors: ({ selectEventState }) => ({
    ...eventAdapter.getSelectors(selectEventState),
  }),
});
