<span
  *ngFor="let konkurrenzprogramm of konkurrenzprogramme$ | async; let last = last"
  #target="kendoContextMenuTargetContainer"
  kendoContextMenuTargetContainer
  [class]="(hasPrecedingEvents$ | async) ? 'events-konkurrenz-container' : ''"
>
  <app-konkurrenzprogramm
    [kendoContextMenuTarget]="konkurrenzprogramm"
    [konkurrenzprogramm]="konkurrenzprogramm"
    [last]="last"
    (konkurrenzprogrammDblClicked)="openEventKonkurrenzprogrammWindow($event)"
  >
  </app-konkurrenzprogramm>

  <kendo-contextmenu
    showOn="contextmenu"
    [target]="target"
    (select)="onKonkurrenzContextMenuSelect($event, false)"
  >
    <kendo-menu-item
      text="Details"
      [data]="{
        action: AktionEnum.DETAILS
      }"
      [svgIcon]="Icons.information"
    ></kendo-menu-item>
    <kendo-menu-item
      icon="copy"
      text="Kopie anlegen"
      [data]="{
        action: AktionEnum.KOPIE_HIER,
        systemAction: Aktion.KOPIERE_KONKURRENZPROGRAMM
      }"
      [disabled]="('editieren' | able: 'EK') === false"
    >
      <kendo-menu-item
        text="diese Woche"
        [data]="{
          action: AktionEnum.KOPIE_HIER,
          systemAction: Aktion.KOPIERE_KONKURRENZPROGRAMM
        }"
        [svgIcon]="Icons.copyThisWeek"
      ></kendo-menu-item>
      <kendo-menu-item
        text="nächste Woche"
        [data]="{
          action: AktionEnum.KOPIE_NAECHSTE_WOCHE,
          copyPattern: CopyPattern.NEXT_WEEK,
          systemAction: Aktion.KOPIERE_KONKURRENZPROGRAMM
        }"
        [svgIcon]="Icons.copyNextWeek"
      ></kendo-menu-item>
      <kendo-menu-item
        text="benutzerdefiniert"
        [data]="{
          action: AktionEnum.KOPIE_BENUTZERDEFINIERT,
          copyPattern: CopyPattern.BENUTZERDEFINIERT
        }"
        [svgIcon]="Icons.hand"
      ></kendo-menu-item>
    </kendo-menu-item>
  </kendo-contextmenu>
</span>
