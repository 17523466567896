import { Pipe, PipeTransform } from "@angular/core";
import { FarbgebungObject } from "src/app/models/viewmodels";

@Pipe({
  name: "colorMapToNgStyle",
})
export class ColorMapToNgStylePipe implements PipeTransform {
  transform(
    value: string,
    map: Record<string, FarbgebungObject<string>>,
    returnValue: ColorMapToReturnValue.Name,
  ): { name: string };
  transform(
    value: string,
    map: Record<string, FarbgebungObject<string>>,
    returnValue: ColorMapToReturnValue.Style,
  ): { color: string; background: string; borderColor: string };
  transform(
    value: string,
    map: Record<string, FarbgebungObject<string>>,
    returnValue: ColorMapToReturnValue,
  ): { color: string; background: string; borderColor: string | null } | { name: string } {
    const { color, background, name, borderColor: borderColor } = map[value];
    switch (returnValue) {
      case ColorMapToReturnValue.Style:
        return { color, background, borderColor };
      case ColorMapToReturnValue.Name:
        return { name };
    }
  }
}

export enum ColorMapToReturnValue {
  Style = "STYLE",
  Name = "NAME",
}
