import { Component, Input } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { BehaviorSubject, map } from "rxjs";
import { Icons } from "src/app/models/icons";
import { entriesOf } from "src/app/utils/object-utils";
import { getValidationErrorMessage } from "./form-field-error.utils";

/**
 * Komponente, die Validierungsfehler für ein Formularfeld anzeigt.
 */
@Component({
  selector: "app-form-field-error",
  templateUrl: "./form-field-error.component.html",
  styleUrls: ["./form-field-error.component.scss"],
})
export class FormFieldErrorComponent {
  @Input({ required: true }) set validationErrors(value: ValidationErrors | null) {
    this.validationErrorsSubject.next(value);
  }

  private readonly validationErrorsSubject = new BehaviorSubject<ValidationErrors | null>(null);

  readonly validationErrorMessages$ = this.validationErrorsSubject.pipe(
    map((validationErrors) =>
      !validationErrors || Object.keys(validationErrors).length === 0
        ? null
        : entriesOf(validationErrors)
            .map(([key, value]) => getValidationErrorMessage(key, value))
            .join("\n"),
    ),
  );

  readonly Icons = Icons;
}
