<ng-container>
  <div class="u-grid-container">
    <app-produktinformationen-getit-link
      class="u-col-12"
      [planungsobjekt]="planungsobjekt"
      [usecase]="usecase"
    >
    </app-produktinformationen-getit-link>

    <app-select
      class="u-col-4"
      data-testid="stofffuehrendeRedaktion"
      formControlName="stofffuehrendeRedaktion"
      label="Stoffführende Redaktion"
      [options]="StofffuehrendeRedaktionOptionsFiltered"
    >
    </app-select>

    <app-select
      class="u-col-4"
      data-testid="genre"
      formControlName="genre"
      label="PLP Genre"
      [defaultItem]="KeinGenreOption"
      [options]="GenreOptionsFiltered"
    >
    </app-select>

    <app-input-label class="u-col-4" text="AGF v4 Genre">
      <kendo-dropdownlist data-testid="agf-genre" [disabled]="true"></kendo-dropdownlist>
    </app-input-label>

    <app-multiselect
      class="u-col-4"
      data-testid="contentCommunities"
      formControlName="contentCommunities"
      label="Zielgruppe"
      [options]="ContentCommunityOptions"
    >
    </app-multiselect>

    <app-select
      class="u-col-4"
      data-testid="fsk"
      formControlName="fsk"
      label="FSK"
      [options]="FSKEinstufungOptions"
    ></app-select>

    <app-input-label class="u-col-4" text="Barrierefreiheit">
      <kendo-multiselect data-testid="barrierefreiheit" [disabled]="true"></kendo-multiselect>
    </app-input-label>

    <div class="u-col-12 u-grid-container">
      <app-input-label class="u-col-4" text="Staffelnr.">
        <kendo-numerictextbox
          data-testid="staffelnummer"
          formControlName="staffelnummer"
          format="n"
          [min]="1"
          [step]="1"
        ></kendo-numerictextbox>
      </app-input-label>

      <app-input-label class="u-col-4" text="Folgennr.">
        <kendo-numerictextbox
          data-testid="folgennummer"
          formControlName="folgennummer"
          format="n"
          [min]="1"
          [step]="1"
        ></kendo-numerictextbox>
      </app-input-label>

      <app-form-field class="u-col-4" label="Gesamtfolgennr.">
        <kendo-textbox
          data-testid="gesamtfolgennummer"
          formControlName="gesamtfolgennummer"
        ></kendo-textbox>
      </app-form-field>
    </div>

    <app-input-label class="u-col-12" text="Inhaltsbeschreibung">
      <kendo-textarea
        data-testid="inhaltsbeschreibung"
        formControlName="inhaltsbeschreibung"
        resizable="auto"
      ></kendo-textarea>
    </app-input-label>

    <app-input-label class="u-col-12" text="Mitwirkende">
      <kendo-textarea
        data-testid="mitwirkende"
        formControlName="mitwirkende"
        resizable="auto"
      ></kendo-textarea>
    </app-input-label>
  </div>
</ng-container>
