/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type Planungskontext = "Unbekannt" | "Vorgeschlagen" | "Vorgeplant" | "Vorgemerkt";

export const Planungskontext = {
  UNBEKANNT: "Unbekannt" as Planungskontext,
  VORGESCHLAGEN: "Vorgeschlagen" as Planungskontext,
  VORGEPLANT: "Vorgeplant" as Planungskontext,
  VORGEMERKT: "Vorgemerkt" as Planungskontext,
};
