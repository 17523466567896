import { EventKategorie } from "../openapi/model/event-kategorie";
import { EnumMetadata, enumRecordToSelectOptions } from "./enum-base";

export const EventKategorieRecord: Record<EventKategorie, EnumMetadata> = {
  Feiertag: { bedeutung: "Bundesweit gesetzl. Feiertag" },
  Sportveranstaltung: { bedeutung: "Sportereignis" },
  PolitischesEreignis: { bedeutung: "Politisches Ereignis" },
  Gedenktag: { bedeutung: "Gedenktag/Feiertag" },
  GesellschaftlichesEreignis: { bedeutung: "Gesellschaftliches Ereignis" },
  ThementagWoche: { bedeutung: "Thementag/-Woche" },
  KeineKategorie: { bedeutung: "Keine Kategorie" },
};

export const EventKategorieOptions = enumRecordToSelectOptions(EventKategorieRecord);
