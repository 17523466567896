import { Pipe, PipeTransform } from "@angular/core";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { DateFnsService } from "src/app/services/date-fns.service";

@Pipe({
  name: "areSendeplaetzeOverlapping",
})
export class SendeplatzOverlappingPipe implements PipeTransform {
  transform(sendeplatz: SendeplatzDto, sendeplaetze: (SendeplatzDto | null)[]): boolean {
    if (sendeplaetze) {
      const sendeplaetzeToCompare = sendeplaetze.filter((s) => s != sendeplatz);
      const sendeplatzInterval: Interval = {
        start: new Date(sendeplatz.effektivVon),
        end: new Date(sendeplatz.effektivBis),
      };
      for (const sendeplatzToCompare of sendeplaetzeToCompare) {
        const isOverlapping = DateFnsService.areIntervalsOverlapping(sendeplatzInterval, {
          start: new Date(sendeplatzToCompare.effektivVon),
          end: new Date(sendeplatzToCompare.effektivBis),
        });
        if (isOverlapping) return true;
      }
      return false;
    } else {
      return false;
    }
  }
}
