import { KeyValue } from "@angular/common";
import { PublikationsplanungDto } from "src/app/models/openapi/model/publikationsplanung-dto";
import { BlockansichtRowDataVM } from "src/app/pages/ansichten/blockansicht/blockansicht-viewmodel";

export interface TimelineColumnData {
  createTableHeader: boolean;
  widthPx: number;
  title?: string;
  minuteOffset: number;
  colspan?: number;
}

// Zusammengesetzter Schlüssel, welcher es erlaubt eine Planungsobjekt - ähnlich zum SendeplatzKey in
// Listen und Kalenderansicht - in der Blockansicht eindeutig zu identifizieren .
export type TimeCellKey = Required<
  Pick<PublikationsplanungDto, "sendetag" | "beginnzeit" | "variante">
>;

// Anhand des TimeCellKeys kann die korrekte Planungsobjekt für eine bestimmte Zelle
// aus der datumGruppe identifiziert werden.
export type TimeCellGroup = {
  datumGruppe: KeyValue<string, BlockansichtRowDataVM>;
  timeCellKey: TimeCellKey;
  minutesPerColumn: number;
};

// Linke Spalte am Anfang der Blockansicht, damit genügend Platz für den Sendetag.
export const DATE_COLUMN_WIDTH = 50;

// Zusätzliche Spalte am Anfang und Ende der Blockansicht, damit genügend Platz für das
// drag-to-select Rechteck bleibt.
export const GAP_COLUMN_WIDTH = 10;
