import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { Store } from "@ngrx/store";
import { benachrichtigungActions } from "src/app/core/stores/benachrichtigung/benachrichtigung.actions";
import { BenachrichtigungBroadcastDto } from "src/app/models/openapi/model/benachrichtigung-broadcast-dto";
import { LifecycleEventBroadcastDto } from "src/app/models/openapi/model/lifecycle-event-broadcast-dto";
import { v4 as uuidv4 } from "uuid";

@Injectable({
  providedIn: "root",
})
export class BenachrichtigungPushService {
  static readonly reconnectAfterFatalErrorInMs = 5000;

  private readonly clientSessionId = uuidv4();

  private hubConnection: signalR.HubConnection;

  constructor(private store: Store) {
    console.log(`Created Push Service with Client Session Id ${this.clientSessionId}`);
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/benachrichtigungen")
      .withAutomaticReconnect()
      .build();
    this.registerConnectionStatusHandler();
    this.addChangeListener();
  }

  public start() {
    void this.startConnection();
  }

  /**
   * https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio#automatically-reconnect
   */
  private registerConnectionStatusHandler() {
    // Der automatische Reconnect wechselt nicht in den Closed-State, sondern direkt in Reconnecting
    // Zu diesem Zeitpunkt ist die alte ConnectionId nicht mehr gültig und keine neue verfügbar
    this.hubConnection.onreconnecting(() => {
      console.log(`SignalR is reconnecting...`);
    });

    // Nach einem Reconnect wird die Connection Id erneut vergeben, da es sich für den Server um eine neue Verbindung handelt
    this.hubConnection.onreconnected((connectionId) => {
      console.log(`SignalR reconnected with ConnectionId ${connectionId}`);
    });

    // Wenn nach vier Versuchen die Verbindung nicht wiederaufgebaut werden konnte, gilt der Reconnect als fehlgeschlagen
    // und die Verbindung wechselt in den Closed-State
    this.hubConnection.onclose(() => {
      console.log(`SignalR connection closed`);
      setTimeout(() => {
        void this.startConnection();
      }, BenachrichtigungPushService.reconnectAfterFatalErrorInMs);
    });
  }

  // Registriert die Methode aus dem Backend Hub-Interface IBenachrichtigungenHub.cs
  private addChangeListener = () => {
    this.hubConnection.on("NewBenachrichtigung", (benachrichtigung: BenachrichtigungBroadcastDto) =>
      this.onNewBenachrichtigung(benachrichtigung),
    );
    this.hubConnection.on("LifecycleEvent", (lifecycleEvent: LifecycleEventBroadcastDto) =>
      this.onLifecycleEvent(lifecycleEvent),
    );
  };

  private onNewBenachrichtigung(benachrichtigung: BenachrichtigungBroadcastDto): void {
    // Bezieht sich die Benachrichtigung auf eine Aktion, welche von dieser Session ausgelöst wurde,
    // ist keine gesamtheitliche Aktualisierung der Ansicht notwendig
    const requiresReload = benachrichtigung.clientSessionId !== this.clientSessionId;

    this.store.dispatch(
      benachrichtigungActions.benachrichtigungFromSocket({
        benachrichtigung,
        requiresReload,
      }),
    );
  }

  private onLifecycleEvent(lifecycleEvent: LifecycleEventBroadcastDto): void {
    // console.log("onLifecycleEvent", lifecycleEvent);
  }

  private async startConnection() {
    try {
      await this.hubConnection.start();
      console.log(`SignalR connected with ConnectionId ${this.hubConnection.connectionId}`);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
      console.log(`SignalR connection could not be established: ${errorMessage}`);
      // Fehler während dem initialen Verbindungsaufbau werden nicht durch den eingebauten
      // Reconnect Mechanismus abgefangen und müssen manuell behandelt werden
      setTimeout(() => {
        void this.startConnection();
      }, BenachrichtigungPushService.reconnectAfterFatalErrorInMs);
    }
  }

  public getClientSessionId() {
    return this.clientSessionId;
  }
}
