/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ClientLogLevel =
  | "TRACE"
  | "DEBUG"
  | "INFO"
  | "LOG"
  | "WARN"
  | "ERROR"
  | "FATAL"
  | "OFF";

export const ClientLogLevel = {
  TRACE: "TRACE" as ClientLogLevel,
  DEBUG: "DEBUG" as ClientLogLevel,
  INFO: "INFO" as ClientLogLevel,
  LOG: "LOG" as ClientLogLevel,
  WARN: "WARN" as ClientLogLevel,
  ERROR: "ERROR" as ClientLogLevel,
  FATAL: "FATAL" as ClientLogLevel,
  OFF: "OFF" as ClientLogLevel,
};
