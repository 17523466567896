export type AnyObject = {
  [key: string]: any;
};

/**
 * Mapped Type, das alle Werte eines Objekts nullable macht.
 */
export type NullableValues<T> = {
  [K in keyof T]: T[K] | null;
};

/**
 * Methode, die alle undefined Werte eines Objekts auf null setzt.
 * Gerade für Form Tests nützlich, wenn wir Partial-Objekte haben, die wir in ein Formular patchen wollen.
 */
export function nullifyUndefinedValues<T>(obj: Partial<T>): NullableValues<T> {
  return entriesOf(obj).reduce((acc, [key, value]) => {
    acc[key] = value === undefined ? null : value;
    return acc;
  }, {} as NullableValues<T>);
}

/**
 * Methode, die überprüft, ob mindestens ein Wert eines Objektes nicht null/undefined ist.
 */
export function hasDefinedValues<T extends object | null | undefined>(obj: T): boolean {
  if (obj === null || obj === undefined) {
    return false;
  }
  return entriesOf(obj).some(([, value]) => value !== null && value !== undefined);
}

export type ValueOf<T> = T[keyof T];

export type KeySelector<T> = (item: T) => string;

export function keysOf<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function entriesOf<T extends { [idx: string]: any }, K extends keyof T>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}

/**
 * Utility Methode, die z.B. bei einem pipe->filter oder filter auf Arrays
 * Typescript unterstützt null und undefined typsicher aus dem Ergebnis herauszufiltern.
 */
export function isDefined<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export const createPartialForType = <T>(partial: Partial<T>): T => ({ ...partial }) as T;

/**
 * Methode, die für leere Strings null zurückgibt.
 */
export const filledStringOrNullify = (value: string | null): string | null =>
  value !== "" ? value : null;
