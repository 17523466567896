<div
  *ngIf="{
    isFirstSearch: isFirstSearch$ | async,
    isSearchFormDirty: isSearchFormDirty$ | async,
    rechercheResultColumns: rechercheResultColumns$ | async,
    kanaeleSelected: kanaeleSelected$ | async,
    activeAdditionalFilters: activeAdditionalFilters$ | async,
    searchResults: searchResults$ | async,
    expandedIds: expandedIds$ | async,
    query: query$ | async,
    rechercheShownGridColumnsInOrder: rechercheShownGridColumnsInOrder$ | async
  } as vm"
  class="recherche-container"
>
  <app-search-form
    [activeAdditionalFilters]="vm.activeAdditionalFilters"
    [isFirstSearch]="vm.isFirstSearch"
    [isSearchFormDirty]="vm.isSearchFormDirty"
    [query]="vm.query"
    (removeAdditionalFilter)="onRemoveAdditionalFilter($event)"
    (searchRequested)="onSearchRequested()"
    (updateAdditionalFilters)="onUpdateAdditionalFilters($event)"
    (updateQuery)="onUpdateQuery($event)"
  ></app-search-form>
  <div class="result-table">
    <app-search-results-grid
      *ngIf="!vm.isFirstSearch"
      [activeRechercheColumns]="vm.rechercheResultColumns"
      [expandedIds]="vm.expandedIds"
      [kanaeleSelected]="vm.kanaeleSelected"
      [rechercheShownGridColumnsInOrder]="vm.rechercheShownGridColumnsInOrder"
      [searchResults]="vm.searchResults"
      (collapseAllParents)="onCollapseAllParents($event)"
      (collapseParent)="onCollapseParent($event)"
      (columnResize)="onColumnResize($event)"
      (loadAllChildren)="onLoadAllChildren($event)"
      (loadChildren)="onLoadChildren($event)"
      (reorderColumn)="onReorderColumn($event)"
      (resultSelected)="onResultSelected($event)"
      (toggleGridColumn)="onToggleGridColumn($event)"
    ></app-search-results-grid>
  </div>
</div>
