import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { FeatureKey } from "../feature.keys";
import { ansichtActions } from "./ansicht.actions";

export interface AnsichtState extends EntityState<AnsichtViewModel> {
  loading: boolean;
}

const ansichtenTitelTabStripOrder = [
  "Jahresansicht",
  "Wochenende Primetime",
  "Fernsehfilm der Woche",
  "Dienstag 20.15f",
  "Mittwoch 20.15",
  "Donnerstag 20.15",
  "Freitagabend Serie",
  "Sonntag / Montag 22.15",
  "Dienstag 2. Primetime",
  "Mittwoch 2. Primetime",
  "Donnerstag 2. Primetime",
  "Freitag 2. Primetime",
  "Serien 18.00",
  "Serien 19.25",
  "Sa Morgen",
  "So Morgen",
  "Samstag Daytime",
  "Sonntag Daytime",
  "Spielfilmnacht",
  "Kleines Fernsehspiel",
  "Di 20.15ff",
  "Mi 20.15f",
  "So 20.15ff",
  "Do Nacht",
  "Fr Nacht",
];

const compareAnsichtViewModel = (a1: AnsichtViewModel, a2: AnsichtViewModel): number => {
  const yearCompare = a1.year - a2.year;
  if (yearCompare !== 0) return yearCompare;

  const titelPosition1 = ansichtenTitelTabStripOrder.indexOf(a1.titel);
  const titelPosition2 = ansichtenTitelTabStripOrder.indexOf(a2.titel);
  return titelPosition1 - titelPosition2;
};

export const ansichtAdapter: EntityAdapter<AnsichtViewModel> =
  createEntityAdapter<AnsichtViewModel>({
    sortComparer: compareAnsichtViewModel,
  });

export const initialAnsichtState: AnsichtState = ansichtAdapter.getInitialState({
  loading: false,
});

export const ansichtFeature = createFeature({
  name: FeatureKey.Ansicht,
  reducer: createReducer(
    initialAnsichtState,
    on(ansichtActions.loadAnsichten, (currentState): AnsichtState => {
      return { ...currentState, loading: true };
    }),
    on(ansichtActions.loadAnsichtenSuccess, (currentState, { ansichten }) => {
      return ansichtAdapter.setAll(ansichten, { ...currentState, loading: false });
    }),
  ),
  extraSelectors: ({ selectAnsichtState }) => ({
    ...ansichtAdapter.getSelectors(selectAnsichtState),
  }),
});
