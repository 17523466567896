import { Injectable } from "@angular/core";
import { DialogService } from "@progress/kendo-angular-dialog";
import { PlanungsobjektWindowTabEnum } from "../core/stores/planungsobjekt-window/planungsobjekt-window.model";
import { Aktion } from "../models/enums/aktion";
import { CustomDialogRef } from "../shared/dialogs/dialog.model";
import {
  CustomDialogAction,
  StandardActionDialogComponent,
} from "../shared/dialogs/standard-action-dialog/standard-action-dialog.component";

@Injectable({
  providedIn: "root",
})
export class CustomDialogService {
  constructor(private dialogService: DialogService) {}

  openStandardActionDialog<T extends CustomDialogAction>(opts: {
    title: string;
    actions: T[];
    content?: string;
    htmlAttributes?: {
      [key: string]: string;
    };
    dialogTestId?: string;
  }) {
    const { title, content, actions, dialogTestId = "standard-action-dialog" } = opts;
    const htmlAttributes = { "data-testid": dialogTestId, ...(opts.htmlAttributes ?? {}) };
    const standardDialogRef: CustomDialogRef<StandardActionDialogComponent, T> =
      this.dialogService.open({
        content: StandardActionDialogComponent,
        cssClass: "window-in-foreground",
        htmlAttributes,
      });

    const instance = standardDialogRef.content.instance;

    instance.title = title;
    instance.content = content;
    instance.actions = actions;

    return standardDialogRef;
  }

  openDeleteDialog(
    titel: string,
    action: Aktion,
    possibleActions?: CustomDialogAction[],
    customTitel?: string,
    dialogTestId = "delete-dialog",
  ) {
    const title = `${customTitel ?? `Du bist dabei "${titel}" zu entfernen.`} Bist du dir sicher?`;

    const hasAbbrechenButton = possibleActions?.some((action) => action.isCancelAction) ?? false;

    const bestaetigenButton: CustomDialogAction = {
      text: "Bestätigen",
      action,
      themeColor: "primary",
      cssClass: "primary-button",
      fillMode: "solid",
      testId: `${dialogTestId}-bestaetigen`,
    };
    const abbrechenButton: CustomDialogAction = {
      text: "Abbrechen",
      isCancelAction: true,
      testId: `${dialogTestId}-abbrechen`,
    };

    const additionalActions: CustomDialogAction[] = possibleActions?.length
      ? possibleActions
      : [bestaetigenButton];

    const actions = [...additionalActions, ...(hasAbbrechenButton ? [] : [abbrechenButton])];

    return this.openStandardActionDialog({
      title,
      actions,
      dialogTestId,
    });
  }

  openMengeneruestZuweisungValidationDialog() {
    const dialogTestId = "mengengeruest-zuweisung-validation-dialog";
    const standardDialogRef: CustomDialogRef<StandardActionDialogComponent> =
      this.dialogService.open({
        content: StandardActionDialogComponent,
        cssClass: "window-in-foreground",
        htmlAttributes: {
          "data-testid": dialogTestId,
        },
      });

    const instance = standardDialogRef.content.instance;

    instance.title = "Abweichende Mengengerüstposition";

    instance.content =
      "Soll das eingeplante Programm einer vom Schema abweichenden Mengengerüstposition zugeordnet werden?";

    instance.actions = [
      {
        text: "Abbrechen",
        fillMode: "outline",
        isCancelAction: true,
      },
      {
        text: "Ja",
        themeColor: "primary",
        fillMode: "solid",
        testId: `${dialogTestId}-ja`,
      },
    ];
    return standardDialogRef;
  }

  openMehrfachauswahlDeleteDialog() {
    const dialogTestId = "mehrfachauswahl-delete-dialog";
    const standardDialogRef: CustomDialogRef<StandardActionDialogComponent, true> =
      this.dialogService.open({
        content: StandardActionDialogComponent,
        htmlAttributes: {
          "data-testid": dialogTestId,
        },
      });

    const instance = standardDialogRef.content.instance;

    instance.title = "Bist Du sicher, dass Du die ausgewählten Programme ausplanen willst?";

    instance.actions = [
      {
        text: "Ausplanen",
        themeColor: "primary",
        fillMode: "solid",
        testId: `${dialogTestId}-ausplanen`,
      },
      {
        text: "Abbrechen",
        isCancelAction: true,
        testId: `${dialogTestId}-abbrechen`,
      },
    ];
    return standardDialogRef;
  }

  openConfirmPlanungsobjektSaveDialog(newTab: PlanungsobjektWindowTabEnum) {
    const dialogTestId = "planungsobjekt-speichern-dialog";
    return this.openStandardActionDialog({
      title: "Änderungen speichern?",
      content:
        `Ohne das Zwischenspeichern bisher getätigter Änderungen kann nicht zu '${newTab}'` +
        " gewechselt werden. Sollen die Änderungen gespeichert werden?",
      actions: [
        {
          text: "Nein",
          isCancelAction: true,
          testId: `${dialogTestId}-nein`,
        },
        {
          text: "Ja",
          themeColor: "primary",
          fillMode: "solid",
          testId: `${dialogTestId}-ja`,
        },
      ],
      dialogTestId,
    });
  }
}
