<!-- Schaltet zusätzliche Debug Ausgaben in anderen Bereichen der Anwendung frei -->
<button
  class="u-w-full"
  kendoButton
  themeColor="primary"
  (click)="debugService.toggleShowDebugData()"
>
  Show Debug Data ({{ debugService.showDebugData }})
</button>
<hr />
<!-- Für das Testen von Permissions / Pipes -->
<ng-container
  *ngIf="{
    beitragen: Role.PLANUNGSOBJEKT_BEITRAGEN | able: 'Permission',
    lesen: Role.PLANUNGSOBJEKT_ZDF_LESEN | able: 'Permission',
    lesen2021:
      Role.PLANUNGSOBJEKT_ZDF_LESEN | able: ({ geltungsbereich: '2021' } | asSubject: 'Permission'),
    lesen2022:
      Role.PLANUNGSOBJEKT_ZDF_LESEN | able: ({ geltungsbereich: '2022' } | asSubject: 'Permission')
  } as can"
>
  <pre>{{ can | json }}</pre>
</ng-container>
<hr />
<button kendoButton (click)="notExist()">Trigger 404</button>
<button kendoButton (click)="baseException()">Trigger Base Exception</button>
<button kendoButton (click)="validationException()">Trigger Validation Exception</button>
<button kendoButton (click)="frontendError()">Trigger Frontend Error</button>
