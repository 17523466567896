import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { AktionEnum } from "src/app/models/enums/aktion";
import { Icons } from "src/app/models/icons";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import KalenderansichtFacade from "../kalenderansicht.facade";

@Component({
  selector: "app-kalenderansicht-sendeplatz",
  templateUrl: "./kalenderansicht-sendeplatz.component.html",
  styleUrl: "./kalenderansicht-sendeplatz.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KalenderansichtSendeplatzComponent {
  public readonly facade = inject(KalenderansichtFacade);

  @Input({ required: true }) sendeplatz: SendeplatzDto;
  @Input({ required: true }) sendeplatzIndex: number;
  @Input({ required: true }) first: boolean;
  @Input({ required: true }) ansichtViewModel: AnsichtViewModel;

  Icons = Icons;
  AktionEnum = AktionEnum;
}
