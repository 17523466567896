import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PublitFrontendSettings } from "src/environments/environment";
import { CreatePlanungshinweisCommand } from "../../models/openapi/model/create-planungshinweis-command";
import { GetPlanungshinweiseByAnsichtenQuery } from "../../models/openapi/model/get-planungshinweise-by-ansichten-query";
import { PlanungshinweisDto } from "../../models/openapi/model/planungshinweis-dto";
import { PlanungshinweisDtoResultDto } from "../../models/openapi/model/planungshinweis-dto-result-dto";
import { UpdatePlanungshinweisCommand } from "../../models/openapi/model/update-planungshinweis-command";

@Injectable({
  providedIn: "root",
})
export class PlanungshinweisApiService {
  private apiUrl = this.settings.apiUrl;
  private static readonly RESOURCE_PATH = "Planungshinweis";

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getPlanungshinweiseByAnsicht$(ansichtId: string): Observable<PlanungshinweisDto[]> {
    return this.http.get<PlanungshinweisDto[]>(
      `${this.apiUrl}${PlanungshinweisApiService.RESOURCE_PATH}/ByAnsicht/${ansichtId}`,
    );
  }

  getPlanungshinweiseByAnsichten$(ansichtenIds: string[]): Observable<PlanungshinweisDto[]> {
    const command: GetPlanungshinweiseByAnsichtenQuery = { ansichtenIds };
    return this.http.post<PlanungshinweisDto[]>(
      `${this.apiUrl}${PlanungshinweisApiService.RESOURCE_PATH}/ByAnsichten`,
      command,
    );
  }

  createPlanungshinweis$(
    command: CreatePlanungshinweisCommand,
  ): Observable<PlanungshinweisDtoResultDto> {
    return this.http.post<PlanungshinweisDtoResultDto>(
      `${this.apiUrl}${PlanungshinweisApiService.RESOURCE_PATH}`,
      command,
    );
  }

  updatePlanungshinweis$(
    command: UpdatePlanungshinweisCommand,
  ): Observable<PlanungshinweisDtoResultDto> {
    return this.http.put<PlanungshinweisDtoResultDto>(
      `${this.apiUrl}${PlanungshinweisApiService.RESOURCE_PATH}/${command.id}`,
      command,
    );
  }

  deletePlanungshinweis$(id: string): Observable<PlanungshinweisDtoResultDto> {
    return this.http.delete<PlanungshinweisDtoResultDto>(
      `${this.apiUrl}${PlanungshinweisApiService.RESOURCE_PATH}/${id}`,
    );
  }
}
