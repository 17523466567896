import { Pipe, type PipeTransform } from "@angular/core";
import { cloneDate } from "src/app/utils/date-utils";

@Pipe({
  name: "toDate",
})
export class ToDatePipe implements PipeTransform {
  transform(value: Date | number | string | undefined | null): Date | undefined | null;
  transform(
    value: Date | number | string | undefined | null,
    fallbackDate: Date | number | string,
  ): Date;
  transform(
    value: Date | number | string | undefined | null,
    fallbackDate?: Date | string | number,
  ): Date | undefined | null {
    return ToDatePipe.toDate(value, fallbackDate);
  }

  static toDate(value: Date | number | string | null): Date | null;
  static toDate(value: Date | number | string | undefined | null): Date | undefined | null;
  static toDate(
    value: Date | number | string | undefined | null,
    fallbackDate: Date | number | string,
  ): Date;
  static toDate(
    value: Date | number | string | undefined | null,
    fallbackDate?: Date | string | number,
  ): Date | undefined | null;
  static toDate(
    value: Date | number | string | undefined | null,
    fallbackDate?: Date | string | number,
  ): Date | undefined | null {
    if (value == null && !!fallbackDate) return this._toDate(fallbackDate);

    // TODO: vereinfachen zu "return value ? this.toDate(value) : value;", sobald tsconfig.app.json auch auf strict umgestellt wurde
    if (value === null) return null;
    else if (value === undefined) return undefined;
    return this._toDate(value);
  }

  private static _toDate(value: Date | number | string) {
    return value instanceof Date ? cloneDate(value) : new Date(value);
  }
}
