import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BenachrichtigungPushService } from "src/app/api/benachrichtigung/benachrichtigung.push.service";

/**
 * Interceptor zum Hinzufügen von eigenen Header Key-Value Paaren bspw. der connectionId der SignalR Verbindung.
 */
@Injectable()
export class RequestHeaderInterceptor implements HttpInterceptor {
  private ClientSessionIdHeader = "Client-Session-Id";

  constructor(private pushService: BenachrichtigungPushService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clientSessionId = this.pushService.getClientSessionId();
    if (clientSessionId) {
      request = request.clone({
        headers: request.headers.set(this.ClientSessionIdHeader, clientSessionId),
      });
    }
    return next.handle(request);
  }
}
