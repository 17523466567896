import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { DialogCloseResult, WindowCloseResult } from "@progress/kendo-angular-dialog";
import { EMPTY, filter, map, switchMap, tap } from "rxjs";
import { Aktion, AktionEnum } from "src/app/models/enums/aktion";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { CustomDialogService } from "src/app/services/custom-dialog.service";
import { LinearOnDemandService } from "src/app/services/linear-on-demand.service";
import { OnDemandService } from "src/app/services/on-demand.service";
import { PlanungsobjektWindowService } from "src/app/services/planungsobjekt-window.service";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
  ReadonlyUseCase,
  planungsobjektWindowReadUseCases,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { allValuesDefined } from "src/app/utils/array-utils";
import { assertUnreachable } from "src/app/utils/function-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { beziehungActions } from "../beziehung/beziehung.actions";
import { merklisteWindowActions } from "../merkliste/merkliste.window.actions";
import { notificationActions } from "../notification/notification.actions";
import { onDemandActions } from "../on-demand/on-demand.actions";
import { onDemandFeature } from "../on-demand/on-demand.reducer";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { shellActions } from "../shell/shell.actions";
import { planungsobjektWindowActions } from "./planungsobjekt-window.actions";
import { extractPlanungsobjektCommandAction } from "./planungsobjekt-window.effects.utils";
import { PlanungsobjektWindowTabEnum } from "./planungsobjekt-window.model";
import { planungsobjektWindowFeature } from "./planungsobjekt-window.reducer";
import planungsobjektWindowSelectors from "./planungsobjekt-window.selectors";
import {
  extractGetitFieldsFromPlanungsobjektDto,
  isEditWindow,
} from "./planungsobjekt-window.utils";

@Injectable()
export class PlanungsobjektWindowEffects {
  openDeletePlanungsobjektWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openDeletePlanungsobjektWindow),
      switchMap(({ planungsobjekt }) =>
        this.planungsobjektWindowService
          .openDeletePlanungsobjektDialog(planungsobjekt.titel)
          .result.pipe(
            map((result) => {
              if (result instanceof DialogCloseResult)
                return planungsobjektWindowActions.closeWindow();
              switch (result.action) {
                case Aktion.ENTFERNE_PLANUNGSOBJEKT:
                  return planungsobjektActions.deletePlanungsobjekt({
                    planungsobjektId: planungsobjekt.id,
                  });
                case Aktion.PLANUNGSOBJEKT_AUF_MERKLISTE:
                  if (PlanungsobjektUtils.isLinear(planungsobjekt)) {
                    return merklisteWindowActions.openMovePlanungsobjektLinearToMerklisteDialog({
                      planungsobjektLinearId: planungsobjekt.id,
                      planunsobjektLinearTitel: planungsobjekt.titel,
                    });
                  } else if (PlanungsobjektUtils.isOnDemand(planungsobjekt)) {
                    return merklisteWindowActions.openMovePlanungsobjektOnDemandToMerklisteDialog({
                      planungsobjektOnDemandId: planungsobjekt.id,
                      planungsobjektOnDemandTitel: planungsobjekt.titel,
                      quickFilterPreset: undefined,
                    });
                  }
                  throw new Error("Unexpected discriminator");
                default:
                  return planungsobjektWindowActions.closeWindow();
              }
            }),
          ),
      ),
    );
  });

  openDeletePlanungsobjektOnSendeplatzWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openDeletePlanungsobjektOnSendeplatzWindow),
      switchMap(({ planungsobjektId, titel }) =>
        this.planungsobjektWindowService.openDeletePlanungsobjektDialog(titel).result.pipe(
          map((result) => {
            if (result instanceof DialogCloseResult)
              return planungsobjektWindowActions.closeWindow();
            switch (result.action) {
              // VP-Fassung wurde gelöscht
              case Aktion.ENTFERNE_PLANUNGSOBJEKT:
                return planungsobjektActions.deletePlanungsobjekt({
                  planungsobjektId,
                });

              // VP-Fassung wurde auf Merkliste gesetzt
              case Aktion.PLANUNGSOBJEKT_AUF_MERKLISTE:
                return merklisteWindowActions.openMovePlanungsobjektLinearToMerklisteDialog({
                  planungsobjektLinearId: planungsobjektId,
                  planunsobjektLinearTitel: titel,
                });
              default:
                return planungsobjektWindowActions.closeWindow();
            }
          }),
        ),
      ),
    );
  });

  openDeletePlanungsobjektOnBlockansichtWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openDeletePlanungsobjektOnBlockansichtDialog),
      map(({ planungsobjektId, titel }) =>
        planungsobjektWindowActions.openDeletePlanungsobjektOnSendeplatzWindow({
          planungsobjektId,
          titel,
        }),
      ),
    );
  });

  openPlanungsobjektLinearWithPlanungOnSendeplatzWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektLinearWithPlanungOnSendeplatzWindow),
      switchMap((action) =>
        this.planungsobjektService.getLinearById$(action.linearId).pipe(
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
          switchMap((planungsobjekt) => [
            planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess({
              planungsobjekte: {
                linear: [planungsobjekt],
                onDemand: [],
              },
            }),
            planungsobjektWindowActions.openPlanungsobjektWindow({
              input: {
                usecase: PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ,
                planungsobjektId: planungsobjekt.id,
                planungsobjekt: planungsobjekt,
                planungskontext: planungsobjekt.planungskontext,
              },
            }),
          ]),
        ),
      ),
    );
  });

  openUpdateOnDemandOnVODWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openUpdateOnDemandOnVODWindow),
      switchMap(({ onDemandId }) => {
        return this.onDemandService.getOnDemandById$(onDemandId).pipe(
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
          switchMap((planungsobjektOnDemand) => [
            planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess({
              planungsobjekte: {
                linear: [],
                onDemand: [planungsobjektOnDemand],
              },
            }),
            planungsobjektWindowActions.openPlanungsobjektWindow({
              input: {
                usecase: PlanungsobjektWindowUseCase.EDIT_ONDEMAND,
                planungsobjektId: planungsobjektOnDemand.id,
                planungsobjekt: planungsobjektOnDemand,
                planungskontext: planungsobjektOnDemand.planungskontext,
              },
            }),
          ]),
        );
      }),
    );
  });

  openPlanungsobjektLinearWithPlanungOnBlockansichtWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektLinearWithPlanungOnBlockansichtWindow),
      switchMap((action) => {
        return this.planungsobjektService.getLinearById$(action.linearId).pipe(
          switchMap((planungsobjekt) => {
            // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
            return [
              planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess({
                planungsobjekte: {
                  linear: [planungsobjekt],
                  onDemand: [],
                },
              }),
              planungsobjektWindowActions.openPlanungsobjektWindow({
                input: {
                  usecase: PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT,
                  planungsobjektId: planungsobjekt.id,
                  planungsobjekt: planungsobjekt,
                  planungskontext: planungsobjekt.planungskontext,
                  blockansichtDefinition: action.blockansichtDefinition,
                  blockansichtDefinitionen: action.blockansichtDefinitionen,
                },
              }),
            ];
          }),
        );
      }),
    );
  });

  openLinearOnDemandAcceptWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openLinearOnDemandAcceptWindow),
      switchMap(({ windowInput }) => {
        const windowRef = this.linearOnDemandService.openLinearOnDemandAcceptWindow$(windowInput);
        return windowRef.result;
      }),
      map((result) =>
        planungsobjektWindowActions.handleLinearOnDemandAcceptWindowResult({ result }),
      ),
    );
  });

  handleLinearOnDemandAcceptWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.handleLinearOnDemandAcceptWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return planungsobjektWindowActions.closeWindow();
        // LinearOnDemand Annehmen
        if (result.action === AktionEnum.VORSCHLAG_ANNEHMEN_ERGAENZEN) {
          return planungsobjektActions.movePlanungsobjektVorgeschlagenToVorgeplant({
            sendeplatz: result.sendeplatz,
            planungsobjektId: result.linearId,
          });
        } else {
          // Planungsobjekt mit LinearOnDemand Ersetzen
          return planungsobjektActions.replacePlanungsobjektWithVorschlag({
            command: {
              linearId: result.linearId,
              planungsobjektId: result.planungsobjektId,
            },
            sendeplatz: result.sendeplatz,
          });
        }
      }),
    );
  });

  private actionsThatClosePlaunungsobjektWindow = [
    planungsobjektWindowActions.closePlanungsobjektWindow,
    onDemandActions.createOnDemandSuccess,
    onDemandActions.updateOnDemandSuccess,
    onDemandActions.verschiebePlanungsobjektOnDemandZuVorgemerktSuccess,
    planungsobjektActions.createPlanungsobjektOnBlockansichtSuccess,
    planungsobjektActions.updatePlanungsobjektOnBlockansichtSuccess,
    planungsobjektActions.createPlanungsobjektOnSendeplatzSuccess,
    planungsobjektActions.updatePlanungsobjektOnSendeplatzSuccess,
    planungsobjektActions.updatePlanungsobjektOnMerklisteSuccess,
    planungsobjektActions.movePlanungsobjektToMerkliste,
    planungsobjektActions.deletePlanungsobjekt,
    planungsobjektActions.createPlanungsobjektLinearVorgeschlagenSuccess,
    planungsobjektActions.updatePlanungsobjektLinearVorgeschlagenSuccess,
  ];

  openPlanungsobjektWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektWindow),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectInput)),
      switchMap(([_, input]) => {
        if (!input) {
          return EMPTY;
        }

        const windowRef = this.planungsobjektWindowService.openPlanungsobjektWindow(input);

        return this.actions$.pipe(
          // Für das Zwischenspeichern ist take(x) problematisch, weil sich dann das Fenster evtl. nichtmehr schließt danach.
          ofType(...this.actionsThatClosePlaunungsobjektWindow),
          /**
           * Zusätzlicher filter für die beiden onDemand Actions, die beim Wechseln des Tabs ausgelöst werden.
           * Hierbei handelt es sich um ein Zwischenspeichern des Formulars, welches nicht das Fenster schließen soll.
           */
          filter(
            (resultAction) =>
              !("shouldCloseWindow" in resultAction && !resultAction.shouldCloseWindow),
          ),
          tap(() => void windowRef.close()),
          map(() => planungsobjektWindowActions.planungsobjektWindowClosed()),
        );
      }),
    );
  });

  openPlanungsobjektWindowReadonlyForId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektWindowReadonlyForId),
      switchMap(({ planungsobjektId, planungsobjektType }) => {
        switch (planungsobjektType) {
          case "linear":
            return this.planungsobjektService.getLinearById$(planungsobjektId).pipe(
              switchMap((planungsobjekt) => {
                // Wir wollen halt zwei Dinge tun... 🤷🏼‍♀️
                // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
                return [
                  planungsobjektActions.getPlanungsobjektByIdSuccess({ planungsobjekt }),
                  planungsobjektWindowActions.openPlanungsobjektWindowReadonlyForPlanungsobjekt({
                    input: {
                      usecase: PlanungsobjektWindowUseCase.READONLY_LINEAR,
                      planungsobjektId: planungsobjekt.id,
                      planungsobjekt: planungsobjekt,
                      planungskontext: planungsobjekt.planungskontext,
                    },
                  }),
                ];
              }),
            );
          case "ondemand":
            return this.onDemandService.getOnDemandById$(planungsobjektId).pipe(
              switchMap((planungsobjektOnDemand) => {
                // Wir wollen halt zwei Dinge tun... 🤷🏼‍♀️
                // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
                return [
                  onDemandActions.getPlanungsobjektOnDemandByIdSuccess({
                    planungsobjektOnDemand,
                  }),
                  planungsobjektWindowActions.openPlanungsobjektWindowReadonlyForPlanungsobjekt({
                    input: {
                      usecase: PlanungsobjektWindowUseCase.READONLY_ONDEMAND,
                      planungsobjektId: planungsobjektOnDemand.id,
                      planungsobjekt: planungsobjektOnDemand,
                      planungskontext: planungsobjektOnDemand.planungskontext,
                    },
                  }),
                ];
              }),
            );

          default:
            assertUnreachable(planungsobjektType);
        }
      }),
    );
  });

  openPlanungsobjektWindowReadonlyForPlanungsobjekt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openPlanungsobjektWindowReadonlyForPlanungsobjekt),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectInput)),
      map(([_, input]) => input),
      filter(
        (
          input,
        ): input is PlanungsobjektWindowInputWithPlanungsobjekt & { usecase: ReadonlyUseCase } =>
          !!input &&
          (<ReadonlyArray<PlanungsobjektWindowUseCase>>planungsobjektWindowReadUseCases).includes(
            input.usecase,
          ),
      ),
      switchMap((input) => {
        const windowRef = this.planungsobjektWindowService.openPlanungsobjektWindow({
          ...input,
          planungskontext: input.planungsobjekt.planungskontext,
        });

        return this.actions$.pipe(
          ofType(planungsobjektWindowActions.closePlanungsobjektWindow),
          tap(() => void windowRef.close()),
          map(() => planungsobjektWindowActions.planungsobjektWindowClosed()),
        );
      }),
    );
  });

  /**
   * Speichert das Formular und schließt das Fenster, falls es sich nicht um ein Zwischenspeichern handeln
   */
  savePlanungsobjektWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        planungsobjektWindowActions.savePlanungsobjektWindow,
        planungsobjektWindowActions.confirmPlanungsobjektSaveDialog,
      ),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowFeature.selectFormValue),
        this.store.select(planungsobjektWindowSelectors.selectInput),
      ]),
      filter(allValuesDefined),
      map(([action, formValue, windowInput]) => {
        const shouldCloseWindow =
          action.type !== planungsobjektWindowActions.confirmPlanungsobjektSaveDialog.type;
        const nextAction = extractPlanungsobjektCommandAction(
          formValue,
          windowInput,
          shouldCloseWindow,
        );
        return nextAction;
      }),
    );
  });

  openConfirmPlanungsobjektSaveDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openConfirmPlanungsobjektSaveDialog),
      filter(allValuesDefined),
      switchMap(({ newTab }) => {
        return this.customDialogService.openConfirmPlanungsobjektSaveDialog(newTab).result.pipe(
          map((result) => {
            if (result instanceof DialogCloseResult || !result)
              return planungsobjektWindowActions.cancelChangeTab();

            return planungsobjektWindowActions.confirmPlanungsobjektSaveDialog({ newTab });
          }),
        );
      }),
    );
  });

  /**
   * Alle Aktionen, die das Zwischenspeichern erlauben
   */
  confirmPlanungsobjektSaveDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.confirmPlanungsobjektSaveDialog),
      switchMap(({ newTab }) =>
        this.actions$.pipe(
          ofType(
            onDemandActions.createOnDemandSuccess,
            onDemandActions.updateOnDemandSuccess,
            planungsobjektActions.createPlanungsobjektOnBlockansichtSuccess,
            planungsobjektActions.updatePlanungsobjektOnBlockansichtSuccess,
            planungsobjektActions.updatePlanungsobjektOnSendeplatzSuccess,
            planungsobjektActions.updatePlanungsobjektOnMerklisteSuccess,
          ),
          map(() => planungsobjektWindowActions.changePlanungsobjektWindowTab({ newTab })),
        ),
      ),
    );
  });

  tryChangeTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.tryChangeTab),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowFeature.selectSelectedTab),
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(planungsobjektWindowFeature.selectIsPlanungFormDirty),
      ]),
      filter(allValuesDefined),
      map(([{ newTab, isFormValid }, currentTab, windowInput, isPlanungFormDirty]) => {
        if (
          // wenn der Tab bereits geöffnet ist, oder ...
          currentTab === newTab ||
          // wenn der Usecase einer der CREATE Usecases ist ...
          windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ ||
          windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
          windowInput.usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR ||
          windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_ONDEMAND ||
          windowInput.usecase === PlanungsobjektWindowUseCase.READONLY_ONDEMAND ||
          // oder wenn das Formular nicht dirty ist ...
          !isPlanungFormDirty
        ) {
          // ... dann wechsle den Tab ohne weitere Prüfungen
          return planungsobjektWindowActions.changePlanungsobjektWindowTab({ newTab });
        }

        return isFormValid
          ? planungsobjektWindowActions.openConfirmPlanungsobjektSaveDialog({ newTab })
          : notificationActions.showNotification({
              message: "Das Formular enthält Änderungen mit Validierungsfehlern.",
              notificationType: "Error",
            });
      }),
    );
  });

  changePlanungsobjektWindowTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.changePlanungsobjektWindowTab),
      filter(
        ({ newTab }) =>
          newTab === PlanungsobjektWindowTabEnum.VERLINKUNG ||
          newTab === PlanungsobjektWindowTabEnum.PLANUNG,
      ),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(onDemandFeature.selectEntities),
      ]),
      map(([{ newTab }, windowInput, onDemandEntities]) => {
        if (
          newTab === PlanungsobjektWindowTabEnum.VERLINKUNG &&
          (windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
            windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
            windowInput?.usecase === PlanungsobjektWindowUseCase.READONLY_LINEAR ||
            windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND ||
            windowInput?.usecase === PlanungsobjektWindowUseCase.READONLY_ONDEMAND)
        ) {
          {
            return beziehungActions.getBeziehungenForPlanungsobjekt({
              planungsobjektId: windowInput.planungsobjektId,
            });
          }
        }
        // OnlineAb und OnlineBis müssen aktualisiert werden, um synchron mit Änderungen auf dem Verlinkungstab zu bleiben
        if (
          newTab === PlanungsobjektWindowTabEnum.PLANUNG &&
          windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
        ) {
          const currentOnDemandPlanung = onDemandEntities[windowInput.planungsobjektId];
          return currentOnDemandPlanung
            ? planungsobjektWindowActions.patchPlanungsobjektWindowPlanungForm({
                formValue: {
                  onlineAb: currentOnDemandPlanung.onlineAb,
                  onlineAbZeit: currentOnDemandPlanung.onlineAbZeit,
                  onlineBis: currentOnDemandPlanung.onlineBis,
                },
              })
            : planungsobjektWindowActions.changeTabWithoutSideeffects();
        }

        return planungsobjektWindowActions.changeTabWithoutSideeffects();
      }),
    );
  });

  getLinearOnDemandFürVerlinkungTab$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.changePlanungsobjektWindowTab),
      filter(({ newTab }) => newTab === PlanungsobjektWindowTabEnum.VERLINKUNG),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectInput)),
      filter(allValuesDefined),
      map(([action, windowInput]) => {
        switch (windowInput.usecase) {
          case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
          case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
          case PlanungsobjektWindowUseCase.READONLY_LINEAR:
            return planungsobjektActions.getPlanungsobjekteLinearAndOnDemandById({
              planungsobjektId: windowInput.planungsobjektId,
            });
          case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
          case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
          case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
          case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
          case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
            return shellActions.noop({
              sourceAction: action.type,
            });
          default:
            assertUnreachable(windowInput);
        }
      }),
    );
  });

  deletePlanungsobjekt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.deletePlanungsobjekt),
      concatLatestFrom(() => this.store.select(planungsobjektWindowSelectors.selectInput)),
      map(([, windowInput]) => {
        if (!windowInput) {
          throw new Error("WindowInput not set.");
        }
        switch (windowInput.usecase) {
          case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
          case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
          case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
            return planungsobjektWindowActions.openDeletePlanungsobjektWindow({
              planungsobjekt: windowInput.planungsobjekt,
            });
          case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
          case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
          case PlanungsobjektWindowUseCase.READONLY_LINEAR:
          case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
          case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
            throw new Error("Usecase not supported.");
          default:
            assertUnreachable(windowInput);
        }
      }),
    );
  });

  openConfirmGetitVerknuepfungAufhebenDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.openConfirmGetitVerknuepfungAufhebenDialog),
      filter(allValuesDefined),
      switchMap(({ planungsobjekt }) => {
        return this.planungsobjektService
          .openPlanungsobjektGetitVerknuepfungAufhebenWindow$(planungsobjekt.id)
          .result.pipe(
            map((result) => {
              if (result instanceof WindowCloseResult) {
                return planungsobjektWindowActions.rejectGetitVerknuepfungAufhebenDialog();
              }

              return planungsobjektWindowActions.confirmGetitVerknuepfungAufhebenDialog({
                planungsobjektId: result.planungsobjektId,
                produktEingenschaftenBeibehalten: result.produktEingenschaftenBeibehalten,
              });
            }),
          );
      }),
    );
  });

  confirmGetitVerknuepfungAufhebenDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektWindowActions.confirmGetitVerknuepfungAufhebenDialog),
      filter(allValuesDefined),
      map(({ planungsobjektId, produktEingenschaftenBeibehalten }) =>
        planungsobjektActions.entknuepfePlanungsobjektVonGetit({
          planungsobjektId,
          produktEingenschaftenBeibehalten,
        }),
      ),
    );
  });

  /**
   * Das Form aktualisieren, wenn das geöffnete Planungsobjekt aktualisiert wurde
   */
  updateEditFormAfterEntityUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(planungsobjektActions.getPlanungsobjekteLinearAndOnDemandByIdSuccess),
      concatLatestFrom(() => [this.store.select(planungsobjektWindowSelectors.selectInput)]),
      // Wenn das Fenster offen ist, es ein Edit Dialog ist
      filter(([_, window]) => !!window && isEditWindow(window)),
      // Das Objekt herausfiltern, das ggf. gerade geöffnet ist.
      map(([{ planungsobjekte }, window]) =>
        [...planungsobjekte.linear, ...planungsobjekte.onDemand].find(
          (planungsobjekt) => planungsobjekt.id === window?.planungsobjektId,
        ),
      ),
      // Nur, wenn das geöffnete Objekt geändert wurde
      filter(
        (planungsobjekt): planungsobjekt is PlanungsobjektLinearDto | PlanungsobjektOnDemandDto => {
          return !!planungsobjekt;
        },
      ),
      // Das Form aktualisieren
      map((planungsobjekt) =>
        planungsobjektWindowActions.patchPlanungsobjektWindowPlanungForm({
          formValue: extractGetitFieldsFromPlanungsobjektDto(planungsobjekt),
        }),
      ),
    );
  });

  constructor(
    private store: Store,
    private actions$: Actions,
    private planungsobjektService: PlanungsobjektService,
    private planungsobjektWindowService: PlanungsobjektWindowService,
    private linearOnDemandService: LinearOnDemandService,
    private onDemandService: OnDemandService,
    private customDialogService: CustomDialogService,
  ) {}
}
