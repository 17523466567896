import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import mehrfachauswahlSelectors from "src/app/core/stores/mehrfachauswahl/mehrfachauswahl.selectors";

@Pipe({
  name: "cdkDragDisabled",
})
export class CdkDragDisabledPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(planungsobjektId: string): Observable<boolean> {
    return this.store.select(
      mehrfachauswahlSelectors.selectPlanungsobjektDragDisabled(planungsobjektId),
    );
  }
}
