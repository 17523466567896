import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { take } from "rxjs";
import { mehrfachauswahlFeature } from "src/app/core/stores/mehrfachauswahl/mehrfachauswahl.reducer";
import mehrfachauswahlSelectors from "src/app/core/stores/mehrfachauswahl/mehrfachauswahl.selectors";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { Icons } from "src/app/models/icons";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { planungsobjektFarbgebungMap } from "src/app/models/viewmodels/planungsobjekt-viewmodel";
import { PlanungsobjektInteraktionService } from "src/app/services/planungsobjekt-interaktion.service";
import { ColorMapToReturnValue } from "src/app/shared/pipes/color-map-to-ng-style/color-map-to-ng-style.pipe";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { CustomDialogService } from "../../../services/custom-dialog.service";

@Component({
  selector: "app-mehrfachauswahl",
  templateUrl: "./mehrfachauswahl.component.html",
  styleUrls: ["./mehrfachauswahl.component.scss"],
})
export class MehrfachauswahlComponent {
  @Input() planungsobjekteInMehrfachauswahl: PlanungsobjektDto[];

  Redaktion = Redaktion;
  ColorMapToReturnValue = ColorMapToReturnValue;
  planungsobjektFarbgebungMap = planungsobjektFarbgebungMap;

  mehrfachauswahlActive$ = this.store.select(mehrfachauswahlFeature.selectMehrfachauswahlActive);
  hasVpFassungenFromMerkliste$ = this.store.select(
    mehrfachauswahlSelectors.selectHasPlanungsobjektFromMerklisteSelected,
  );
  readonly Icons = Icons;

  constructor(
    private store: Store,
    private customDialogService: CustomDialogService,
    protected planungsobjektInteraktionService: PlanungsobjektInteraktionService,
  ) {}

  clearMarkedPlanungsobjekte(): void {
    this.planungsobjektInteraktionService.clearPlanungsobjekteInMehrfachauswahl();
  }

  cancelMehrfachauswahl(): void {
    this.clearMarkedPlanungsobjekte();
  }

  openMehrfachauswahlDeleteDialog(planungsobjekte: PlanungsobjektDto[]): void {
    const dialogRef = this.customDialogService.openMehrfachauswahlDeleteDialog();

    dialogRef.result.pipe(take(1)).subscribe((result) => {
      if (result instanceof DialogCloseResult) return;

      this.store.dispatch(
        planungsobjektActions.deleteMultiplePlanungsobjekte({
          planungsobjekte,
        }),
      );
    });
  }

  createReihenfolgeForMarkedPlanungsobjekte(markedPlanungsobjekte: PlanungsobjektDto[]) {
    const planungsobjekte = markedPlanungsobjekte.map((mVp) => mVp.id);
    this.store.dispatch(
      planungsobjektActions.createReihenfolgeForSelectedPlanungsobjekte({
        command: { planungsobjekte },
      }),
    );
    this.clearMarkedPlanungsobjekte();
  }

  createAbhaengigkeitForMarkedPlanungsobjekte(markedPlanungsobjekte: PlanungsobjektDto[]) {
    const planungsobjekte = markedPlanungsobjekte.map((mVp) => mVp.id);
    this.store.dispatch(
      planungsobjektActions.createAbhaengigkeitForSelectedPlanungsobjekte({
        command: {
          planungsobjektVon: planungsobjekte[0],
          planungsobjektZu: planungsobjekte[1],
        },
      }),
    );
    this.clearMarkedPlanungsobjekte();
  }

  istMitProduktdatenbankVerknuepft = PlanungsobjektUtils.isConnectedWithProductDb;
}
