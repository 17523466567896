import { inject, Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, EMPTY, filter, map, switchMap, tap } from "rxjs";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import planungsobjektWindowSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.selectors";
import { planungsobjektFeature } from "src/app/core/stores/planungsobjekt/planungsobjekt.reducer";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { LinearOnDemandService } from "src/app/services/linear-on-demand.service";
import { PlanungsobjektBeziehungService } from "src/app/services/planungsobjekt-beziehung.service";
import { PlanungsobjektService } from "src/app/services/planungsobjekt.service";
import { CustomNotificationService } from "src/app/shared/notifications/custom-notification.service";
import { PlanungsobjektWindowUseCase } from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { allValuesDefined } from "src/app/utils/array-utils";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { onDemandBeziehungFormActions } from "./on-demand-beziehung-form.actions";
import onDemandBeziehungFormSelectors from "./on-demand-beziehung-form.selectors";
import {
  berechneSynchronisierteOnDemandFormValues,
  calculateReihenfolgeHerstellen,
  calculateRelationalZuLinear,
  extractLinearOnDemandBeziehungCommandAction,
} from "./on-demand-beziehung-form.utils";

@Injectable()
export class OnDemandBeziehungFormEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly linearOnDemandService = inject(LinearOnDemandService);
  private readonly planungsobjektService = inject(PlanungsobjektService);
  private readonly notificationService = inject(CustomNotificationService);
  private readonly beziehungService = inject(PlanungsobjektBeziehungService);

  onDemandBeziehungFormHelper$ = createEffect(() => {
    const onDemandControlsHelper$ = this.store
      .select(planungsobjektWindowSelectors.selectInput)
      .pipe(
        map(
          (input) =>
            (input?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
              input?.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ ||
              // TODO-1812 TODO-1535 / Im Rahmen von 1812 wird hier ebenfalls der usecase _BLOCKANSICHT
              // mitberücksichtigt, da die LinearOnDemand-Beziehung auch in der Blockansicht erstellt/bearbeitet werden können.
              // Leider war nicht klar was der onDemandBeziehungFormHelper-Effekt genau macht und ob es wirklich notwendig ist.
              // Das kann im Rahmen von 1535 oder dem RxForms Refactoring nochmal überprüft werden.
              input?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT ||
              input?.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT ||
              input?.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND ||
              input?.usecase === PlanungsobjektWindowUseCase.CREATE_ONDEMAND) &&
            // Soll auf Merklisten nicht möglich sein, weil du dort keinen Sendetag hast
            (input?.planungskontext === "Vorgeschlagen" || input?.planungskontext === "Vorgeplant"),
        ),
      );
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.setLinearOnDemandBeziehungForm),
      // dann nur weiter, wenn sich die relevanten Werte geändert haben
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
      // aktuellen Formularwert aus Store holen
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowFeature.selectPreviousOnDemandFormProperties),
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(planungsobjektFeature.selectEntities),
        onDemandControlsHelper$,
      ]),
      filter(allValuesDefined),
      filter(
        ([_nextFormValue, _prevFromState, _input, _planungsobjekte, onDemandControlsHelper]) =>
          onDemandControlsHelper,
      ),
      map(([{ onDemandFormValue }, prevFromState, input, planungsobjekte]) =>
        berechneSynchronisierteOnDemandFormValues({
          // Wenn wir im UseCase EDIT_ONDEMAND sind, dann holen wir uns den Sendetag aus dem linearen Planungsobjekt
          linearSendetag:
            input.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND &&
            input.planungsobjekt?.linearId
              ? planungsobjekte[input.planungsobjekt.linearId]?.publikationsplanung?.sendetag
              : input.planungsobjekt?.publikationsplanung?.sendetag,
          nextFormValue: onDemandFormValue,
          prevFormValue: prevFromState,
        }),
      ),
      map((onDemandFormValue) =>
        onDemandFormValue
          ? onDemandBeziehungFormActions.updateOnDemandBeziehungFormValuesSuccess({
              onDemandFormValue,
            })
          : onDemandBeziehungFormActions.noChangesNecessary(),
      ),
    );
  });

  initializeOnDemandBeziehungFormData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.getInitialOnDemandBeziehungFormData),
      switchMap(({ planungsobjektLinearId }) => {
        if (!planungsobjektLinearId) return EMPTY;
        return this.planungsobjektService.getPlanungsobjekteById$(planungsobjektLinearId);
      }),
      filter(({ onDemand, linear }) =>
        // Wir wissen noch nicht, wie wir mit n:n Beziehungen umgehen sollen
        //new Error("Mehrere OnDemand Planungen sind einer Linearplanung zugeordnet")
        //new Error("Mehrere Linearplanungen sind einer OnDemand-Planung zugeordnet")
        onDemand.length > 1 || linear.length > 1 ? false : true,
      ),
      map(({ onDemand, linear }) => {
        // Wir wissen noch nicht, wie wir mit n:n Beziehungen umgehen sollen
        const singleOnDemandOrEmpty = onDemand.length < 1 ? null : onDemand[0];
        const singleLinearOrEmpty = linear.length < 1 ? null : linear[0];

        return onDemandBeziehungFormActions.getInitialOnDemandBeziehungFormDataSuccess({
          previousOnDemandFormProperties: {
            onlineAbZeit: singleOnDemandOrEmpty?.onlineAbZeit ?? null,
            wunschOnlineAb: singleOnDemandOrEmpty?.onlineAb ?? null,
            wunschOnlineBis: singleOnDemandOrEmpty?.onlineBis ?? null,
            reihenfolgeHerstellen:
              !!singleLinearOrEmpty && !!singleOnDemandOrEmpty
                ? calculateReihenfolgeHerstellen(singleLinearOrEmpty, singleOnDemandOrEmpty)
                : null,
            relationZuLinearInTagen:
              !!singleLinearOrEmpty && !!singleOnDemandOrEmpty
                ? calculateRelationalZuLinear(singleLinearOrEmpty, singleOnDemandOrEmpty, "distanz")
                : null,
            verweildauerInTagen: singleOnDemandOrEmpty?.verweildauerInTagen ?? null,
            minDistanz:
              !!singleLinearOrEmpty && !!singleOnDemandOrEmpty
                ? calculateRelationalZuLinear(
                    singleLinearOrEmpty,
                    singleOnDemandOrEmpty,
                    "minDistanz",
                  )
                : null,
            maxDistanz:
              !!singleLinearOrEmpty && !!singleOnDemandOrEmpty
                ? calculateRelationalZuLinear(
                    singleLinearOrEmpty,
                    singleOnDemandOrEmpty,
                    "maxDistanz",
                  )
                : null,
          },
        });
      }),
    );
  });

  saveLinearOnDemandBeziehung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.saveLinearOnDemandBeziehung),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(
          onDemandBeziehungFormSelectors.selectOnDemandFormMitRelationalOderAbsolutWerten,
        ),
        this.store.select(planungsobjektWindowSelectors.selectHasPlanungsobjektOnDemandId),
      ]),
      filter(allValuesDefined),
      map(([_, windowInput, onDemandFormValue, hasPlanungsobjektOnDemandId]) => {
        const shouldCreate = !hasPlanungsobjektOnDemandId;
        const action = extractLinearOnDemandBeziehungCommandAction({
          windowInput,
          onDemandFormValue,
          shouldCreate,
        });
        return action;
      }),
    );
  });

  linearOnDemandBeziehungVorgemerktErstellen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellen),
      switchMap(({ command }) =>
        this.linearOnDemandService.linearOnDemandBeziehungVorgemerktErstellen$(command),
      ),
      tap(() => {
        this.notificationService.showNotification(
          "OnDemand Beziehung wurde erstellt.",
          NotificationStyle.SUCCESS,
        );
      }),
      concatLatestFrom(() =>
        this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektId),
      ),
      map(([planungsobjekte, currentPlanungsobjektId]) => {
        if (!currentPlanungsobjektId)
          return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenFailure();
        return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktErstellenSuccess({
          planungsobjekte,
          currentPlanungsobjektId,
        });
      }),
    );
  });

  linearOnDemandBeziehungVorgemerktAktualisieren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisieren),
      switchMap(({ command }) =>
        this.linearOnDemandService.linearOnDemandBeziehungVorgemerktAktualisieren$(command),
      ),
      tap(() => {
        this.notificationService.showNotification(
          "OnDemand Beziehung wurde aktualisiert.",
          NotificationStyle.SUCCESS,
        );
      }),
      concatLatestFrom(() =>
        this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektId),
      ),
      map(([planungsobjekte, currentPlanungsobjektId]) => {
        if (!currentPlanungsobjektId)
          return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenFailure();
        return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgemerktAktualisierenSuccess({
          planungsobjekte,
          currentPlanungsobjektId,
        });
      }),
    );
  });

  /**
   * Erstellt ein Planungsobjekt OnDemand über den Verlinkungs-Tab mit entsprechender Beziehung zu Linear und fügt es dem Store hinzu.
   */
  linearOnDemandBeziehungVorgeplantVorgeschlagenErstellen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellen),
      switchMap(({ command }) =>
        this.linearOnDemandService
          .linearOnDemandBeziehungVorgeschlagenVorgeplantErstellen$(command)
          .pipe(
            concatLatestFrom(() =>
              this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektId),
            ),
            map(([planungsobjekte, currentPlanungsobjektId]) => {
              if (!currentPlanungsobjektId)
                return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenFailure();
              return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenErstellenSuccess(
                {
                  planungsobjekte,
                  currentPlanungsobjektId,
                },
              );
            }),
          ),
      ),
      tap(() =>
        this.notificationService.showNotification(
          "OnDemand Beziehung wurde hergestellt.",
          NotificationStyle.SUCCESS,
        ),
      ),
    );
  });

  /**
   * Aktualisiert die Beziehung LinearOnDemand (ggf. mit Update auf der OnDemand-Planung) über den Verlinkungs-Tab.
   */
  linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisieren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisieren,
      ),
      concatLatestFrom(() => [
        this.store.select(planungsobjektWindowSelectors.selectInput),
        this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektForEditWindow),
      ]),
      filter(allValuesDefined),
      switchMap(([{ enabeledFormGroupValues }, input, currentPlanungsobjekt]) => {
        // linear ID bestimmen
        const planungsobjektLinearId =
          input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
          input.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT
            ? currentPlanungsobjekt.id
            : input.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
              ? PlanungsobjektUtils.findLinearIdInOnDemandBeziehungen(
                  currentPlanungsobjekt as PlanungsobjektOnDemandDto,
                )
              : undefined;
        // Soll auch nicht möglich sein bei einem CREATE_LINEAR mit Tab-Switch zwischenspeichern
        if (
          planungsobjektLinearId &&
          (input.planungskontext === "Vorgeplant" || input.planungskontext === "Vorgeschlagen")
        ) {
          {
            return this.linearOnDemandService
              .linearOnDemandBeziehungVorgeschlagenVorgeplantAktualisieren$({
                planungsobjektLinearId,
                // Entweder wunschOnlineAb bzw. wunschOnlineBis oder wir übergeben relationalZuLinear bzw. verweildauer. Nicht beides
                onlineAb: enabeledFormGroupValues.wunschOnlineAb,
                onlineAbZeit: enabeledFormGroupValues.onlineAbZeit,
                onlineBis: enabeledFormGroupValues.wunschOnlineBis,
                reihenfolgeHerstellen: enabeledFormGroupValues.reihenfolgeHerstellen ?? false,
                relationZuLinearInTagen: enabeledFormGroupValues.relationZuLinearInTagen,
                verweildauerInTagen: enabeledFormGroupValues.verweildauerInTagen,
                minDistanz: enabeledFormGroupValues.minDistanz,
                maxDistanz: enabeledFormGroupValues.maxDistanz,
              })
              .pipe(
                tap(() => {
                  this.notificationService.showNotification(
                    "OnDemand Beziehung wurde aktualisiert.",
                    NotificationStyle.SUCCESS,
                  );
                }),
                concatLatestFrom(() =>
                  this.store.select(planungsobjektWindowSelectors.selectPlanungsobjektId),
                ),
                map(([planungsobjekte, currentPlanungsobjektId]) => {
                  if (!currentPlanungsobjektId)
                    return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenFailure();
                  return onDemandBeziehungFormActions.linearOnDemandBeziehungVorgeplantVorgeschlagenAktualisierenSuccess(
                    {
                      planungsobjekte,
                      currentPlanungsobjektId,
                    },
                  );
                }),
              );
          }
        } else {
          return EMPTY;
        }
      }),
    );
  });
}
