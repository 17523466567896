import { AnsichtsdefinitionDto } from "../models/openapi/model/ansichtsdefinition-dto";
import { Kanal } from "../models/openapi/model/kanal";
import { Planungskontext } from "../models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "../models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektOnDemandDto } from "../models/openapi/model/planungsobjekt-on-demand-dto";
import { PublitPermissionDto } from "../models/openapi/model/publit-permission-dto";
import { Role } from "../models/openapi/model/role";
import { SchemaplatzDto } from "../models/openapi/model/schemaplatz-dto";
import { SendeplatzDto } from "../models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "../models/viewmodels/ansicht-viewmodel";
import { toWochentag } from "../utils/date-utils";
import { assertUnreachable } from "../utils/function-utils";
import { PlanungsobjektUtils } from "../utils/planungsobjekt.utils";

function canPlanenOnSchemaplatz(
  ansichtsdefinitionen: AnsichtsdefinitionDto[],
  permissionPlanenGeltungsbereiche: string[],
  schemaplatz: SchemaplatzDto,
): boolean {
  // console.log("canPlanenOnSchemaplatz?", schemaplatz);
  const allowedAnsichtsdefinitionen = ansichtsdefinitionen.filter((ansichtsdefinition) => {
    return permissionPlanenGeltungsbereiche.includes(ansichtsdefinition.id);
  });

  for (const ansichtsdefinition of allowedAnsichtsdefinitionen) {
    for (const schemaplatzInAsichtsdefinition of ansichtsdefinition.schemaplaetze) {
      if (schemaplatzInAsichtsdefinition.id === schemaplatz.id) {
        return true;
      }
    }
  }

  return false;
}

/**
 * Mit dem Recht Beitragen können wir auf allen Merklisten arbeiten.
 * Können wir wir nur planen, müssen wir uns auch auf einer Merkliste befinden, für dessen
 * Ansichtsdefinition (bzw. Ansichtsdefinition der Ansicht) wir planen dürfen.
 * Wir machen uns an dieser Stelle die Tatsache zu Nutze, dass die Merkliste immer nur auf der
 * aktuell besuchten Ansicht angezeigt wird. Daher können wir prüfen ob wir auf der aktuellen
 * Ansicht planen dürfen.
 */
function canBeitragenMerkliste(
  permissionBeitragen: PublitPermissionDto | undefined,
  permissionPlanen: PublitPermissionDto | undefined,
  ansichten: AnsichtViewModel[],
) {
  // console.log("canBeitragenMerkliste?");
  if (permissionBeitragen) {
    return true;
  }

  if (permissionPlanen) {
    return canPlanenOnCurrentAnsicht(ansichten, permissionPlanen.geltungsbereiche);
  }

  return false;
}

function canBeitragenMerklisteOnDemand(
  permissionBeitragen: PublitPermissionDto | undefined,
  permissionPlanenZdfMediathek: PublitPermissionDto | undefined,
) {
  // console.log("canBeitragenMerklisteOnDemand?");
  if (permissionBeitragen) {
    return true;
  }

  if (permissionPlanenZdfMediathek) {
    return true;
  }

  return false;
}

function canBearbeitenPlanungsobjekt(
  planungsobjekt: PlanungsobjektDto,
  permissionBeitragen: PublitPermissionDto | undefined,
  permissionPlanen: PublitPermissionDto | undefined,
  ansichten: AnsichtViewModel[],
): boolean {
  if (planungsobjekt === null || planungsobjekt === undefined) {
    throw new Error("Planungsobjekt may not be null or undefined for permission check.");
  }

  if (
    planungsobjekt.planungskontext === Planungskontext.VORGEMERKT ||
    planungsobjekt.planungskontext === Planungskontext.VORGESCHLAGEN
  ) {
    // Beitragen ermöglicht das Bearbeiten von Planungsobjekte ohne weitere Einschränkungen
    if (permissionBeitragen) {
      return true;
    }

    // Wenn wir "nur" planen können ist die Frage, wird die Prüfung etwas komplexer, da wir nun
    // ebenfalls die Geltungsbereiche der Berechtigung berücksichten müssen
    if (permissionPlanen) {
      if (planungsobjekt.planungskontext === Planungskontext.VORGESCHLAGEN) {
        // Vorschläge liegen immer auf einem Sendeplatz
        const ansichtsdefinitionen = filterAnsichtsdefinitionen(ansichten);
        return canPlanenOnSendeplatzKey(ansichtsdefinitionen, permissionPlanen.geltungsbereiche, {
          kalendertag: planungsobjekt.publikationsplanung.kalendertag,
          beginnzeit: planungsobjekt.publikationsplanung.beginnzeit,
          laenge: planungsobjekt.publikationsplanung.laenge,
        });
      }

      if (planungsobjekt.planungskontext === Planungskontext.VORGEMERKT) {
        // An dieser Stelle fehlt der Zusammenhang zwischen Merkliste und Ansicht (über die wir dann wieder auf die Ansichtsdefinition kommen würden)
        // const ansicht = planungsobjekt.merkliste.ansichtId;
        // Da die Merkliste aber immer nur auf der aktuell besuchten Ansicht angezeigt wird,
        // können wir prüfen ob wir auf der aktuellen Ansicht planen dürfen
        return canPlanenOnCurrentAnsicht(ansichten, permissionPlanen.geltungsbereiche);
      }
    }
  }

  // Wenn die Planungsobjekte vorgeplant sind, dürfen wir sie nur mit den Rechten Planen bearbeiten
  if (planungsobjekt.planungskontext === Planungskontext.VORGEPLANT) {
    if (permissionPlanen) {
      if (PlanungsobjektUtils.isOnBlockansicht(planungsobjekt)) {
        return canPlanenOnCurrentAnsicht(ansichten, permissionPlanen.geltungsbereiche);
      }

      if (PlanungsobjektUtils.isOnListeOrKalenderansicht(planungsobjekt)) {
        const ansichtsdefinitionen = filterAnsichtsdefinitionen(ansichten);
        return canPlanenOnSendeplatzKey(ansichtsdefinitionen, permissionPlanen.geltungsbereiche, {
          kalendertag: planungsobjekt.publikationsplanung.kalendertag,
          beginnzeit: planungsobjekt.publikationsplanung.sendeplatzBeginnzeit,
          laenge: planungsobjekt.publikationsplanung.sendeplatzLaenge,
        });
      }
    }
  }

  return false;
}

export function canBeitragenPlanungsobjektOnDemand(
  planungsobjekt: PlanungsobjektOnDemandDto | null | undefined,
  permissionBeitragen: PublitPermissionDto | undefined,
  permissionPlanen: PublitPermissionDto | undefined,
): boolean {
  if (planungsobjekt === null || planungsobjekt === undefined) {
    throw new Error("Planungsobjekt may not be null or undefined for permission check.");
  }

  if (
    planungsobjekt.planungskontext === "Vorgemerkt" ||
    planungsobjekt.planungskontext === "Vorgeschlagen"
  ) {
    // Beitragen ermöglicht das Bearbeiten von Planungsobjekte ohne weitere Einschränkungen
    if (permissionBeitragen) {
      return true;
    }

    // Wenn wir "nur" planen können ist die Frage, wird die Prüfung etwas komplexer, da wir nun
    // ebenfalls die Geltungsbereiche der Berechtigung berücksichten müssen
    if (permissionPlanen) {
      return canPlanenPlanungsobjektOnDemand(planungsobjekt, permissionPlanen.geltungsbereiche);
    }
    // Wenn die Planungsobjekte vorgeplant sind, dürfen wir sie nur mit den Rechten Planen bearbeiten
  } else if (planungsobjekt.planungskontext === "Vorgeplant") {
    if (permissionPlanen) {
      return canPlanenPlanungsobjektOnDemand(planungsobjekt, permissionPlanen.geltungsbereiche);
    }
  } else if (planungsobjekt.planungskontext === "Unbekannt") {
    throw new Error("Planungsobjekt with unknown Planungskontext.");
  } else {
    assertUnreachable(planungsobjekt.planungskontext);
  }

  return false;
}

function getAnsichtIdFromCurrentAnsicht(): string | undefined {
  const href = window.location.href;
  const url = new URL(href);
  const params = new URLSearchParams(url.search);
  const ansichtId = params.get("ansichtId");

  if (!ansichtId) {
    return undefined;
  }

  return ansichtId;
}

function canPlanenOnCurrentAnsicht(
  ansichten: AnsichtViewModel[],
  permissionPlanenGeltungsbereiche: string[] | undefined,
): boolean {
  // console.log("canPlanenOnCurrentAnsicht?");
  if (!permissionPlanenGeltungsbereiche) {
    return false;
  }

  const currentAnsichtId = getAnsichtIdFromCurrentAnsicht();
  const currentAnsicht = ansichten.find((ansicht) => ansicht.id === currentAnsichtId);
  if (!currentAnsicht) {
    return false;
  }

  if (!currentAnsicht.ansichtsdefinition) {
    throw new Error(
      "Ansichtsdefinition of Ansicht may not be null or undefined for permission check.",
    );
  }
  return permissionPlanenGeltungsbereiche.includes(currentAnsicht.ansichtsdefinition.id);
}

function canPlanenOnSendeplatzKey(
  ansichtsdefinitionen: AnsichtsdefinitionDto[],
  permissionPlanenGeltungsbereiche: string[],
  { kalendertag, beginnzeit, laenge }: { kalendertag: string; beginnzeit: string; laenge: number },
): boolean {
  // console.log("canPlanenOnSendeplatzKey?", kalendertag, beginnzeit, laenge);
  const allowedAnsichtsdefinitionen = ansichtsdefinitionen.filter((ansichtsdefinition) => {
    return permissionPlanenGeltungsbereiche.includes(ansichtsdefinition.id);
  });

  for (const ansichtsdefinition of allowedAnsichtsdefinitionen) {
    for (const schemaplatz of ansichtsdefinition.schemaplaetze) {
      const sendeplatzWochentag = toWochentag(kalendertag);
      if (
        schemaplatz.wochentag === sendeplatzWochentag &&
        schemaplatz.beginnzeit === beginnzeit &&
        schemaplatz.laenge === laenge
      ) {
        return true;
      }
    }
  }

  return false;
}

function canPlanenOnSendeplatz(
  ansichtsdefinitionen: AnsichtsdefinitionDto[],
  permissionPlanenGeltungsbereiche: string[],
  sendeplatz: SendeplatzDto,
): boolean {
  // console.log("canPlanenOnSendeplatz?", sendeplatz);
  return canPlanenOnSendeplatzKey(ansichtsdefinitionen, permissionPlanenGeltungsbereiche, {
    kalendertag: sendeplatz.kalendertag,
    beginnzeit: sendeplatz.beginnzeit,
    laenge: sendeplatz.laenge,
  });
}

function canPlanenPlanungsobjektOnDemand(
  planungsobjekt: PlanungsobjektOnDemandDto,
  planenZdfMediathekGeltungsbereiche: string[] | null,
): boolean {
  if (planungsobjekt.kanal !== "ZDFMediathek") return false;
  // TODO PUBLIT-1547 Klären, ob es für PlanungsobjektZDFMediathekPlanen auch eine Geltungsbereichs-Prüfung geben soll.
  // "GeltungsbereicheService.Alle" wird hier aktuell laut Backend nicht unterstützt
  // if (planenZdfMediathekGeltungsbereiche) {
  //   const years = planenZdfMediathekGeltungsbereiche.map(Number).filter((year) => !isNaN(year));
  //   return years.includes(new Date(planungsobjekt.onlineAb!).getFullYear());
  // }
  return true;
}

/**
 * Filtert aus der Liste der Ansichten alle einzigartigen Ansichtsdefinitionen heraus.
 */
function filterAnsichtsdefinitionen(ansichten: AnsichtViewModel[]): AnsichtsdefinitionDto[] {
  const uniqueAnsichtsdefinitionIds = new Set<string>();
  return ansichten
    .map((ansicht) => ansicht.ansichtsdefinition)
    .filter((ansichtsdefinition) => {
      if (uniqueAnsichtsdefinitionIds.has(ansichtsdefinition.id)) {
        return false;
      } else {
        uniqueAnsichtsdefinitionIds.add(ansichtsdefinition.id);
        return true;
      }
    });
}

export function roleHasPlanenPermissionForKanal(kanal: Kanal, role: Role) {
  switch (kanal) {
    case Kanal.ZDF:
      return role === Role.PLANUNGSOBJEKT_ZDF_PLANEN;
    case Kanal.ZDF_NEO:
      return role === Role.PLANUNGSOBJEKT_ZDF_NEO_PLANEN;
    case Kanal.ZDF_MEDIATHEK:
      return role === Role.PLANUNGSOBJEKT_ZDF_MEDIATHEK_PLANEN;
    default:
      return false;
  }
}

export {
  canBearbeitenPlanungsobjekt,
  canBeitragenMerkliste,
  canBeitragenMerklisteOnDemand,
  canPlanenOnCurrentAnsicht,
  canPlanenOnSchemaplatz,
  canPlanenOnSendeplatz,
  filterAnsichtsdefinitionen,
};
