import { Injectable } from "@angular/core";
import { GetitWindowInput } from "../core/stores/getit-window/getit-model";
import {
  GetItImportWindowComponent,
  GetItImportWindowResult,
} from "../shared/windows/get-it-import-window/get-it-import-window.component";
import { SmallWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";

@Injectable({
  providedIn: "root",
})
/**
 * Bereitstellung von Funktionalitäten rund um die Get.it-Integration in Publish.it
 */
export class GetitService {
  constructor(private customWindowService: CustomWindowService) {}

  openGetitWindow(event: GetitWindowInput) {
    const windowRef = this.customWindowService.open<
      GetItImportWindowComponent,
      GetItImportWindowResult
    >({
      content: GetItImportWindowComponent,
      title: "Produktsuche",
      ...SmallWindow(),
    });

    windowRef.content.instance.input = event;

    return windowRef;
  }
}
