<span
  class="konkurrenz-chip"
  [attr.data-testid]="'kp-' + konkurrenzprogramm.titel"
  [kendoContextMenuTarget]="konkurrenzprogramm"
  [ngClass]="
    'konkurrenz-chip-' +
    (konkurrenzprogramm.kategorie | ekCssClass) +
    ' ' +
    (konkurrenzprogramm.isAbgesetzt ? 'u-text-decoration-line-through' : '')
  "
  [title]="konkurrenzprogramm | ekTooltip"
  (dblclick)="dblClick($event, false)"
>
  <ng-container
    *ngIf="konkurrenzprogramm.senderPlattform !== SenderPlattform.SONSTIGES; else sonstiges"
  >
    <app-konkurrenzprogramm-icons
      class="konkurrenz-sender-icon"
      [sender]="konkurrenzprogramm.senderPlattform"
    ></app-konkurrenzprogramm-icons>
  </ng-container>
  <ng-template #sonstiges>
    <span *ngIf="konkurrenzprogramm.senderPlattformSonstiges" class="konkurrenz-sonstiges-test">
      {{ konkurrenzprogramm.senderPlattformSonstiges }}
    </span>
  </ng-template>

  <span [ngClass]="konkurrenzprogramm.kategorie | ekCssClass">
    {{
      !last && (konkurrenzprogramm.kategorie | ekCssClass) !== "sport"
        ? konkurrenzprogramm.titel + ","
        : konkurrenzprogramm.titel
    }}
  </span>
</span>
