import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SidebarTabEnum } from "src/app/models/enums/ansichten-sidebar";

export const sidebarActions = createActionGroup({
  source: "Sidebar",
  events: {
    expandSidebar: props<{
      expanded?: boolean;
    }>(),
    setActiveSidebarTab: props<{
      newTab: SidebarTabEnum;
    }>(),
    toggleKonkurrenzEvents: emptyProps(),
    toggleNotizen: emptyProps(),
    resetSidebarToDefaultTab: emptyProps(),
    openMehrfachauswahlTab: emptyProps(),
    closeMehrfachauswahlTab: emptyProps(),
  },
});
