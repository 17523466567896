import { Pipe, PipeTransform } from "@angular/core";
import {
  BlockansichtDataVM,
  BlockansichtDefinition,
} from "src/app/pages/ansichten/blockansicht/blockansicht-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";

@Pipe({
  name: "rowData",
})
export class RowDataPipe implements PipeTransform {
  transform(data: BlockansichtDataVM, definition: BlockansichtDefinition): BlockansichtDataVM {
    return Object.keys(data)
      .filter(
        (rowData) =>
          DateFnsService.parseStringToDate(rowData).getFullYear() ===
          definition.ansichtViewModel.zeitraum.start.getFullYear(),
      )
      .reduce((filteredRows, rowData) => {
        filteredRows[rowData] = data[rowData];
        return filteredRows;
      }, {} as BlockansichtDataVM);
  }
}
