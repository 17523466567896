import { createActionGroup, props } from "@ngrx/store";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";

export const beziehungActions = createActionGroup({
  source: "Beziehung API",
  events: {
    getBeziehungenForPlanungsobjekt: props<{
      planungsobjektId: string;
    }>(),
    getBeziehungenForPlanungsobjektSuccess: props<{ beziehungen: BeziehungDto[] }>(),
    removeBeziehung: props<{
      planungsobjekt: PlanungsobjektDto;
      beziehung: BeziehungDto;
    }>(),
    removeBeziehungSuccess: props<{
      /**
       * Planungsobjekt, von der gelöscht wurde - NOCH NICHT AKTUALISIERT
       */
      planungsobjekt: PlanungsobjektDto;
      /**
       * die erfolgreich gelöschte Beziehung
       */
      beziehung: BeziehungDto;
    }>(),
  },
});
