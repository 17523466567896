import { Directive, inject, input } from "@angular/core";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { FormGroupDirective } from "@angular/forms";
import { tap } from "rxjs";
import { ControlEnablement, EnablementForKeysOf } from "src/app/utils/form-utils";
import { entriesOf } from "src/app/utils/object-utils";

/**
 * Direktive, die das Enablement von FormControls steuert.
 *
 * @param appFormEnablement - Objekt, das für FormControls im Formular "enable", "disable" oder "no-change" angibt.
 *
 * **Achtung: Unbekannte Controls werden still ignoriert**.
 *
 * @example
 * Im Template:
 * ```html
 * <form [appFormEnablement]="formEnablement" [formGroup]="form">
 *  <input formControlName="control1" />
 *  <input formControlName="control2" />
 *  <input formControlName="control3" />
 * </form>
 * ```
 *
 * Im Komponenten-Code:
 * ```typescript
 * const form = formBuilder.group({
 *   control1: [""],
 *   control2: [""],
 *   control3: [{ value: "", disabled: true }],
 * })
 * const formEnablement: EnablementForFormControls<{ control1: string; control2: string }> = {
 *  control1: "disable",
 *  control2: "enable",
 *  control3: "no-change",
 * };
 * ```
 */
@Directive({
  selector: "[appFormEnablement]",
})
export class FormEnablementDirective {
  /**
   * Input für das Enablement von FormControls.
   *
   * @see {@link FormEnablementDirective}
   * @example
   * ```typescript
   * type MyFormControls = {
   *   control1: string;
   *   control2: string;
   * }
   * const formEnablement: EnablementForFormControls<MyFormControls> = {
   *   control1: "disable",
   *   control2: "enable",
   * };
   * ```
   */
  appFormEnablement = input.required<ControlEnablement | EnablementForKeysOf<any>>();

  private readonly formGroup = inject(FormGroupDirective, { self: true });

  private readonly formEnablementUpdateEffect = toObservable(this.appFormEnablement)
    .pipe(
      takeUntilDestroyed(),
      tap((enablement) => {
        if (!this.formGroup) {
          console.warn("Form wurde nicht gefunden. Enablement kann nicht gesetzt werden.");
          return;
        }

        if (typeof enablement === "string") {
          this.handleGroupEnablement(enablement);
        } else {
          this.handleChildEnablement(enablement);
        }
      }),
    )
    .subscribe();

  private handleGroupEnablement(enablement: ControlEnablement) {
    if (enablement === "enable" && this.formGroup.form.disabled) {
      this.formGroup.form.enable();
    } else if (enablement === "disable" && this.formGroup.form.enabled) {
      this.formGroup.form.disable();
    }
  }

  private handleChildEnablement(enablement: EnablementForKeysOf<any>) {
    for (const [controlName, change] of entriesOf(enablement)) {
      if (change === "no-change" || change === undefined) continue;
      const control = this.formGroup.form.get(controlName);
      if (!control) continue;
      if (change === "enable" && control.disabled) {
        control.enable();
      } else if (change === "disable" && control.enabled) {
        control.disable();
      }
    }
  }
}
