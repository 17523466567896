import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, combineLatest, filter, map, switchMap } from "rxjs";
import { AppAbility } from "src/app/casl/app-ability";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";
import planungsobjektWindowSelectors from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.selectors";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { Role } from "src/app/models/openapi/model/role";
import { CanEditPlanungsobjektWindowPipe } from "./can-edit-planungsobjekt-window.pipe";
import { PlanungsobjektWindowPlanungFormService } from "./planungsobjekt-window-planung-form.service";
import { PlanungsobjektWindowInputWithPlanungsobjekt } from "./planungsobjekt-window.model";

@Injectable()
export class PlanungsobjektWindowFacade {
  private readonly store = inject(Store);
  private readonly formService = inject(PlanungsobjektWindowPlanungFormService);
  private readonly canEdit = inject(CanEditPlanungsobjektWindowPipe);
  private readonly ability = inject(AppAbility);

  public readonly input$ = this.store.select(planungsobjektWindowSelectors.selectInput);

  public readonly planungsobjekt$: Observable<PlanungsobjektOnDemandDto | PlanungsobjektLinearDto> =
    this.input$.pipe(
      map((input) => input?.planungsobjekt),
      filter(
        (planungsobjekt): planungsobjekt is PlanungsobjektOnDemandDto | PlanungsobjektLinearDto =>
          !!planungsobjekt,
      ),
    );

  public readonly isFormDirty$: Observable<boolean> = this.store.select(
    planungsobjektWindowFeature.selectIsPlanungFormDirty,
  );

  public readonly isGetItSyncDisabled$ = combineLatest([this.isFormDirty$, this.input$]).pipe(
    map(([isFormDirty, input]) => isFormDirty || !input || !this.canEdit.transform(input)),
  );

  public readonly isGetItDisabled$ = combineLatest([this.isFormDirty$, this.input$]).pipe(
    map(
      ([isFormDirty, input]) =>
        isFormDirty || !input || !(this.canEdit.transform(input) || this.canBeitragenGetit(input)),
    ),
  );

  /**
   * Aus canEdit() abweichende Berechtigung aus PUBLIT-1766.
   * get.it Ver/Entknüpfen und Synchronisation der get.it Daten von vorgeplanten Planungsobjekten
   * ist auch nur mit Beitragen Berechtigungen möglich.
   */
  private canBeitragenGetit(input: PlanungsobjektWindowInputWithPlanungsobjekt) {
    return (
      input.planungskontext === Planungskontext.VORGEPLANT &&
      this.ability.can(Role.PLANUNGSOBJEKT_BEITRAGEN, "Permission")
    );
  }

  public readonly validationErrors$ = this.formService.form$.pipe(
    switchMap((form) => form.valueChanges.pipe(map(() => form.errors))),
  );

  public updateProduktInformationenFromGetit(planungsobjekt: PlanungsobjektDto) {
    this.store.dispatch(
      planungsobjektActions.synchronisierePlanungsobjektMitGetit({
        command: { planungsobjektId: planungsobjekt.id },
      }),
    );
  }
}
