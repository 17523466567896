import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  Observable,
  race,
  share,
  shareReplay,
  switchMap,
  take,
  tap,
  timer,
  withLatestFrom,
} from "rxjs";
import { mehrfachauswahlActions } from "../core/stores/mehrfachauswahl/mehrfachauswahl.actions";
import { mehrfachauswahlFeature } from "../core/stores/mehrfachauswahl/mehrfachauswahl.reducer";
import mehrfachauswahlSelectors from "../core/stores/mehrfachauswahl/mehrfachauswahl.selectors";
import { planungsobjektActions } from "../core/stores/planungsobjekt/planungsobjekt.actions";
import { sidebarActions } from "../core/stores/sidebar/sidebar.actions";
import { PlanungsobjektDto } from "../models/openapi/model/planungsobjekt-dto";
import { sameMembers } from "../utils/array-utils";

/**
 * Verwaltet Aktionen die beim Arbeiten mit Planungsobjekte entstehen.
 * Bsp. Selektieren (Klick-Aktion) einzelner oder das markieren (Drag & Select) von mehreren Planungsobjekte.
 */
@Injectable({
  providedIn: "root",
})
export class PlanungsobjektInteraktionService {
  private readonly store = inject(Store);

  readonly isMultipleDragging$ = this.store.select(
    mehrfachauswahlSelectors.selectIsDraggingMultiple,
  );

  readonly mehrfachauswahlEnabled$ = this.mehrfachauswahlToggleSetup$();
  /**
   * Whether or not the drag and drop feature for Planungsobjekt is disabled. Currently this should be disabled
   * whenever Mehrfachauswahl is enabled.
   */
  readonly dragDropDisabled$ = this.mehrfachauswahlEnabled$;

  clearSelectedPlanungsobjekt() {
    this.store.dispatch(planungsobjektActions.deselectPlanungsobjektInAnsicht({}));
  }

  clearPlanungsobjekteInMehrfachauswahl() {
    this.store.dispatch(mehrfachauswahlActions.removeAllPlanungsobjekteFromMehrfachauswahl());
    this.clearSelectedPlanungsobjekt();
  }

  removePlanungsobjektInMehrfachauswahl(planungsobjektId: string): void {
    this.store.dispatch(
      mehrfachauswahlActions.removePlanungsobjektFromMehrfachauswahl({
        planungsobjektId,
      }),
    );
  }

  onPlanungsobjektClick(newPlanungsobjekt: PlanungsobjektDto, mehrfachauswahlEnabled: boolean) {
    if (!mehrfachauswahlEnabled) {
      // Einzelner Maus Klick im Drag&Drop Modus
      this.store.dispatch(
        planungsobjektActions.selectPlanungsobjektInAnsicht({
          planungsobjektId: newPlanungsobjekt.id,
        }),
      );
      this.addEventListener();
    }
  }

  /**
   * Diese Methode wird sowohl über `(click)` als auch die Events des `dtsSelectContainers` aufgerufen.
   * Entscheidend ist hierbei das `externalEvent` Flag, welches auf `true` gesetzt werden muss, wenn die Methode bspw.
   * in der Merkliste oder Mehrfachauswahl Komponente verwendet wird. Diese Komponenten sind nicht Teil der
   * `ansichten.component.ts` und lösen somit keine Events des `dtsSelectContainers` aus. Wenn externalEvent = true
   * muss daher zusätzlich die `endSelection` Methode aufgerufen werden, um die Mehrfachauswahl "manuell" zu beenden.
   * @param planungsobjektId
   * @param planungsobjekteIdsInMehrfachauswahl
   * @param externalEvent
   */
  selectItem(
    planungsobjektId: string,
    planungsobjekteIdsInMehrfachauswahl: PlanungsobjektDto[],
    externalEvent = false,
  ) {
    if (
      planungsobjekteIdsInMehrfachauswahl
        .map((planungsobjektf) => planungsobjektf.id)
        .includes(planungsobjektId)
    ) {
      this.store.dispatch(
        mehrfachauswahlActions.removePlanungsobjektFromMehrfachauswahl({
          planungsobjektId,
        }),
      );
    } else {
      this.store.dispatch(
        mehrfachauswahlActions.selectPlanungsobjektToMehrfachauswahl({
          planungsobjektId,
        }),
      );
    }

    if (externalEvent)
      this.endSelection([planungsobjektId], planungsobjekteIdsInMehrfachauswahl, externalEvent);
  }

  deselectItem(planungsobjektId: string) {
    this.store.dispatch(
      mehrfachauswahlActions.deselectPlanungsobjektFromMehrfachauswahl({
        planungsobjektId,
      }),
    );
  }

  startSelection() {
    this.store.dispatch(mehrfachauswahlActions.startMehrfachauswahlSelection());
  }

  endSelection(
    selectedPlanungsobjekte: string[],
    planungsobjekteInMehrfachauswahl: PlanungsobjektDto[],
    externalEvent = false,
  ) {
    if (selectedPlanungsobjekte.length) this.openMehrfachauswahlSidebar();
    if (
      planungsobjekteInMehrfachauswahl.length === 0 &&
      selectedPlanungsobjekte.length > 0 &&
      !externalEvent
    ) {
      this.closeMehrfachauswahlSidebar();
    }

    if (
      externalEvent &&
      selectedPlanungsobjekte.length === 1 &&
      planungsobjekteInMehrfachauswahl.length === 1 &&
      sameMembers(
        selectedPlanungsobjekte,
        planungsobjekteInMehrfachauswahl.map((planungsobjektf) => planungsobjektf.id),
      )
    ) {
      this.closeMehrfachauswahlSidebar();
    }

    this.store.dispatch(
      mehrfachauswahlActions.stopMehrfachauswahlSelection({
        planungsobjekte: selectedPlanungsobjekte,
      }),
    );
  }

  private openMehrfachauswahlSidebar() {
    window.addEventListener("keyup", this.escapeHandler);
    this.store.dispatch(sidebarActions.openMehrfachauswahlTab());
  }

  private closeMehrfachauswahlSidebar() {
    window.removeEventListener("keyup", this.escapeHandler);
    this.clearSelectedPlanungsobjekt();
    this.store.dispatch(sidebarActions.closeMehrfachauswahlTab());
  }

  updateMultiauswahl(
    mehrfachauswahlEnabled: boolean,
    oldPlanungsobjekte: PlanungsobjektDto[],
    ...newPlanungsobjekte: PlanungsobjektDto[]
  ) {
    if (mehrfachauswahlEnabled) {
      if (!newPlanungsobjekte.length) return;
      // Wenn noch keine VP-Fassung ausgewählt wurde
      if (oldPlanungsobjekte.length === 0) {
        this.startMehrfachauswahl(newPlanungsobjekte);
      } else {
        // Wenn bereits Planungsobjekte ausgewählt wurden und die Mehrfachauswahl fortgesetzt wird
        this.updateMehrfachauswahl(newPlanungsobjekte, oldPlanungsobjekte);

        // Wenn alle markierten Planungsobjekte wieder deselektiert werden
        this.stopMehrfachauswahl(oldPlanungsobjekte, newPlanungsobjekte);
      }
    }
  }

  private startMehrfachauswahl(newPlanungsobjekte: PlanungsobjektDto[]) {
    this.store.dispatch(
      mehrfachauswahlActions.setPlanungsobjekteInMehrfachauswahl({
        planungsobjektIds: newPlanungsobjekte.map((planungsobjektf) => planungsobjektf.id),
      }),
    );
  }

  private updateMehrfachauswahl(
    newPlanungsobjekte: PlanungsobjektDto[],
    oldPlanungsobjekte: PlanungsobjektDto[],
  ) {
    const planungsobjekteToAdd: string[] = [];
    const planungsobjekteToRemove: string[] = [];
    for (const planungsobjekt of newPlanungsobjekte) {
      if (oldPlanungsobjekte.includes(planungsobjekt)) {
        planungsobjekteToRemove.push(planungsobjekt.id);
      } else {
        planungsobjekteToAdd.push(planungsobjekt.id);
      }
    }

    this.store.dispatch(
      mehrfachauswahlActions.updatePlanungsobjekteInMehrfachauswahl({
        planungsobjekteToAdd,
        planungsobjekteToRemove,
      }),
    );
    // Muss ggf. nochmal aufgerufen werden falls bspw. Planungsobjekte aus der Merkliste zur Mehrfachauswahl
    // hinzugefügt werden
    this.openMehrfachauswahlSidebar();
  }

  private stopMehrfachauswahl(
    oldPlanungsobjekte: PlanungsobjektDto[],
    newPlanungsobjekte: PlanungsobjektDto[],
  ) {
    const isMehrfachauswahlEmpty = sameMembers(
      oldPlanungsobjekte.map((s) => s.id),
      newPlanungsobjekte.map((s) => s.id),
    );

    if (isMehrfachauswahlEmpty) {
      this.closeMehrfachauswahlSidebar();
    }
  }

  /**
   * muss eine Arrow Function sein wegen des `this` Kontextes innerhalb des Escape Handlers.
   * Wenn es eine `function` ist, wäre `this` das `window` Objekt
   */
  escapeHandler = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      this.clearPlanungsobjekteInMehrfachauswahl();
      this.clearSelectedPlanungsobjekt();
      this.store.dispatch(mehrfachauswahlActions.deactivateMehrfachauswahl());
      this.removeEventListener();
    }
  };

  mouseDownHandler = (event: MouseEvent) => {
    // Wenn ein linker Mouse Klick stattfindet und eine VP-Fassung ausgewählt wurde, wird die Selektion
    // aufgehoben. Das gilt nicht für den Mehrfachauswahl Modus oder wenn sich Planungsobjekte in der Mehrfachaus-
    // auswahl befinden.
    this.store
      .select(mehrfachauswahlFeature.selectMehrfachauswahlActive)
      .pipe(
        take(1),
        withLatestFrom(
          this.store.select(mehrfachauswahlSelectors.selectPlanungsobjekteIdsInMehrfachauswahl),
        ),
        filter(
          ([mehrfachauswahlActive, planungsobjekteIdsInMehrfachauswahl]) =>
            event.button === 0 &&
            !mehrfachauswahlActive &&
            planungsobjekteIdsInMehrfachauswahl.size === 0,
        ),
      )
      .subscribe(() => {
        this.clearSelectedPlanungsobjekt();
        this.removeEventListener();
      });
  };

  private addEventListener() {
    window.addEventListener("keyup", this.escapeHandler);
    window.addEventListener("mousedown", this.mouseDownHandler);
  }

  private removeEventListener() {
    window.removeEventListener("keyup", this.escapeHandler);
    window.removeEventListener("mousedown", this.mouseDownHandler);
  }

  private mehrfachauswahlToggleSetup$(): Observable<boolean> {
    const windowBlurred$ = fromEvent(window, "blur").pipe(
      map(() => false),
      tap((event) =>
        this.store.dispatch(mehrfachauswahlActions.toggleMehrfachauswahl({ active: event })),
      ),
    );

    const metaDown$ = fromEvent<KeyboardEvent>(window, "keydown").pipe(
      filter((event) => !event.repeat),
      filter((event) => event.key === "Meta" || event.key === "Control"),
      share(),
    );

    const metaUp$ = fromEvent<KeyboardEvent>(window, "keyup").pipe(
      filter((event) => event.key === "Meta" || event.key === "Control"),
      share(),
    );

    const shortPress$ = metaDown$.pipe(
      switchMap((keydown) => {
        return race(
          metaUp$.pipe(map(() => ({ type: "short" as const, keydown }))),
          timer(200).pipe(map(() => ({ type: "long" as const }))),
        );
      }),
    );

    const metaPressed$ = shortPress$.pipe(
      filter((event) => event.type === "short"),
      withLatestFrom(this.store.select(mehrfachauswahlFeature.selectMehrfachauswahlActive)),
      map(([_, activeState]) => !activeState),
      tap((event) =>
        this.store.dispatch(mehrfachauswahlActions.toggleMehrfachauswahl({ active: event })),
      ),
    );

    const metaState$ = merge(
      windowBlurred$,
      metaPressed$,
      this.store.select(mehrfachauswahlFeature.selectMehrfachauswahlActive),
    );
    return metaState$.pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));
  }
}
