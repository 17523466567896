import { createSelector } from "@ngrx/store";
import { SelectOption } from "src/app/models/enums/enum-base";
import {
  PlanungsobjektWindowUseCase,
  Publikationsart,
} from "src/app/shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { assertUnreachable } from "src/app/utils/function-utils";
import { wochentagToDayOfWeek } from "src/app/utils/kanal-offset-utils";
import { calculateEndzeitValue } from "./planungsobjekt-window-wann-wo.utils";
import { planungsobjektWindowFeature } from "./planungsobjekt-window.reducer";
import planungsobjektWindowSelectors from "./planungsobjekt-window.selectors";

const selectPublikationsart = createSelector(
  planungsobjektWindowSelectors.selectInput,
  (input): Publikationsart | undefined => {
    if (!input) return undefined;
    switch (input.usecase) {
      case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
      case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
      case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
        return "ondemand";
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
      case PlanungsobjektWindowUseCase.READONLY_LINEAR:
        return "linear";
      default:
        assertUnreachable(input);
    }
  },
);

const selectOnlineAb = createSelector(
  planungsobjektWindowFeature.selectFormValue,
  planungsobjektWindowSelectors.selectInput,
  (formValue, windowInput) => {
    if (!formValue) {
      return windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
        ? windowInput.planungsobjekt.onlineAb
        : undefined;
    }

    return formValue.onlineAb;
  },
);

const selectOnlineBis = createSelector(
  planungsobjektWindowFeature.selectFormValue,
  planungsobjektWindowSelectors.selectInput,
  (formValue, windowInput) => {
    if (!formValue) {
      return windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_ONDEMAND
        ? windowInput.planungsobjekt.onlineBis
        : undefined;
    }

    return formValue.onlineBis;
  },
);

const selectSendetag = createSelector(
  planungsobjektWindowFeature.selectFormValue,
  planungsobjektWindowSelectors.selectInput,
  (formValue, windowInput) => {
    if (!formValue) {
      return windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
        windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT
        ? windowInput.planungsobjekt.sendetag
        : undefined;
    }

    return formValue.sendetag;
  },
);

const selectBeginnzeit = createSelector(
  planungsobjektWindowFeature.selectFormValue,
  planungsobjektWindowSelectors.selectInput,
  (formValue, windowInput) => {
    if (!formValue) {
      return windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
        windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT
        ? windowInput.planungsobjekt.beginnzeit
        : undefined;
    }

    return formValue.beginnzeit;
  },
);

const selectPlanlaenge = createSelector(
  planungsobjektWindowFeature.selectFormValue,
  planungsobjektWindowSelectors.selectInput,
  (formValue, windowInput) => {
    if (!formValue) {
      return windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
        windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT
        ? windowInput.planungsobjekt.planlaenge
        : undefined;
    }

    return formValue.planlaenge;
  },
);

const selectEndzeit = createSelector(
  planungsobjektWindowFeature.selectFormValue,
  planungsobjektWindowSelectors.selectInput,
  (formValue, windowInput) => {
    if (!formValue) {
      const planlaenge = windowInput?.planungsobjekt?.planlaenge ?? 0; //TODO: check if this is correct
      return windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ ||
        windowInput?.usecase === PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT
        ? calculateEndzeitValue(planlaenge, windowInput.planungsobjekt.beginnzeit)
        : undefined;
    }

    return formValue.endzeit;
  },
);

/**
 * Selektor, der die Optionen für die Varianten-Selectbox selektiert.
 * Die Optionen sind abhängig vom UseCase des Planungsobjekt-Fensters.
 * Wenn die Varianten bereits geladen wurden, werden diese direkt verwendet.
 * Ansonsten wird die Variante aus dem WannPreset bzw. dem Planungsobjekt verwendet,
 * damit die Selectbox nicht leer ist und der initiale Wert angezeigt werden kann.
 *
 * @returns SelectOption<number>[] | undefined
 * @see PlanungsobjektWindowUseCase
 */
const selectVariants = createSelector(
  planungsobjektWindowFeature.selectVarianten,
  planungsobjektWindowSelectors.selectInput,
  (varianten, windowInput): SelectOption<number>[] | undefined => {
    if (
      windowInput?.usecase !== PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT &&
      windowInput?.usecase !== PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT
    ) {
      return undefined;
    }

    if (varianten.length) {
      return varianten.map(
        ({ variante, frei }): SelectOption<number> => ({
          text: variante.toString(),
          value: variante,
          disabled: !frei,
        }),
      );
    }

    switch (windowInput.usecase) {
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
        return [
          {
            text: windowInput.wannPreset.variante.toString(),
            value: windowInput.wannPreset.variante,
          },
        ];
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
        return windowInput.planungsobjekt.variante
          ? [
              {
                text: windowInput.planungsobjekt.variante.toString(),
                value: windowInput.planungsobjekt.variante,
              },
            ]
          : [];
      default:
        assertUnreachable(windowInput);
    }
  },
);

/**
 * Selektor, der die verfügbare Variante für die Planungsobjekt-Blockansicht selektiert.
 * * Wenn die aktuelle Variante frei ist, wird diese zurückgegeben.
 * * Ansonsten wird die Variante des vorherigen Planungsobjekts verwendet, wenn dieses
 * frei ist oder sich die relevanten Werte nicht geändert haben oder ein neues Planungsobjekt
 * erstellt wird.
 * * Andernfalls wird `null` zurückgegeben.
 */
const selectAvailableVariant = createSelector(
  planungsobjektWindowFeature.selectFormValue,
  planungsobjektWindowSelectors.selectInput,
  planungsobjektWindowFeature.selectVarianten,
  (formValue, windowInput, varianten) => {
    if (!formValue || !windowInput) return undefined;

    if (
      windowInput.usecase !== PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT &&
      windowInput.usecase !== PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT
    ) {
      return undefined;
    }

    const currentVariante = formValue.variante;
    const currentVariantenZeile = varianten.find((v) => v.variante === currentVariante);
    if (currentVariantenZeile?.frei) return currentVariante;

    const previousPlanungsobjekt = windowInput.planungsobjekt;
    const previousVariante =
      previousPlanungsobjekt?.variante ??
      (windowInput.usecase === PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT
        ? windowInput.wannPreset.variante
        : undefined);
    const previousVariantenZeile = varianten.find((v) => v.variante === previousVariante);

    const isChanged =
      !previousPlanungsobjekt ||
      previousPlanungsobjekt.sendetag !== formValue.sendetag ||
      previousPlanungsobjekt.beginnzeit !== formValue.beginnzeit ||
      previousPlanungsobjekt.planlaenge !== formValue.planlaenge;

    if (previousVariantenZeile?.frei || !isChanged) {
      return previousVariante;
    } else {
      return null;
    }
  },
);

/**
 * Selector, der die für die Planlängenhelper Logik relevanten Properties aus dem FormValue selektiert.
 */
const selectPlanlaengenSyncProperties = createSelector(
  planungsobjektWindowFeature.selectFormValue,
  (formValue) => {
    if (!formValue) return formValue;

    return {
      sendetag: formValue.sendetag,
      beginnzeit: formValue.beginnzeit,
      endzeit: formValue.endzeit,
      planlaenge: formValue.planlaenge,
    };
  },
);

/**
 * Selector, der eine Methode zurückgibt, die prüft, ob ein Datum im Datepicker für den Sendetag
 * des Planungsobjekts deaktiviert werden soll.
 */
const selectSendetagIsDateDisabled = createSelector(
  planungsobjektWindowSelectors.selectInput,
  (input) => {
    if (!input) return () => false;
    switch (input.usecase) {
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT: {
        const wochentag = input.blockansichtDefinition.wochentagSendetag;
        return (date: Date) => date.getDay() !== wochentagToDayOfWeek[wochentag];
      }
      default:
        return () => false;
    }
  },
);

export default {
  selectPublikationsart,
  selectOnlineAb,
  selectOnlineBis,
  selectSendetag,
  selectBeginnzeit,
  selectPlanlaenge,
  selectEndzeit,
  selectVariants,
  selectAvailableVariant,
  selectPlanlaengenSyncProperties,
  selectSendetagIsDateDisabled,
};
