import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LinearOnDemandApiService } from "../api/linear-on-demand/linear-on-demand.api.service";
import { PlanungsobjektLinearApiService } from "../api/planungsobjekt/planungsobjekt-linear.api.service";
import { LinearOnDemandBeziehungVorgemerktAktualisierenCommand } from "../models/openapi/model/linear-on-demand-beziehung-vorgemerkt-aktualisieren-command";
import { LinearOnDemandBeziehungVorgemerktErstellenCommand } from "../models/openapi/model/linear-on-demand-beziehung-vorgemerkt-erstellen-command";
import { LinearOnDemandBeziehungVorgeschlagenAktualisierenCommand } from "../models/openapi/model/linear-on-demand-beziehung-vorgeschlagen-aktualisieren-command";
import { LinearOnDemandBeziehungVorgeschlagenVorgeplantErstellenCommand } from "../models/openapi/model/linear-on-demand-beziehung-vorgeschlagen-vorgeplant-erstellen-command";
import { LinearOnDemandDto } from "../models/openapi/model/linear-on-demand-dto";
import { CustomWindowRef } from "../shared/dialogs/dialog.model";
import {
  PlanungsobjektAcceptWindowComponent,
  PlanungsobjektAcceptWindowInput,
  PlanungsobjektAcceptWindowResult,
} from "../shared/windows/planungsobjekt-accept-window/planungsobjekt-accept-window.component";
import { MediumWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";

/**
 * Service der derzeit noch LinearOnDemandDto verwaltet.
 */
@Injectable({
  providedIn: "root",
})
export class LinearOnDemandService {
  constructor(
    private linearOnDemandApi: LinearOnDemandApiService,
    private planungsobjektLinearApiService: PlanungsobjektLinearApiService,
    private customWindowService: CustomWindowService,
  ) {}

  // CRUD
  getLinearOnDemandByLinearId$(linearId: string): Observable<LinearOnDemandDto> {
    return this.linearOnDemandApi.getLinearOnDemandByLinearId$(linearId);
  }

  getLinearOnDemandByOnDemandId$(onDemandId: string): Observable<LinearOnDemandDto> {
    return this.linearOnDemandApi.getLinearOnDemandByOnDemandId$(onDemandId);
  }

  linearOnDemandBeziehungVorgeschlagenVorgeplantErstellen$(
    command: LinearOnDemandBeziehungVorgeschlagenVorgeplantErstellenCommand,
  ) {
    return this.linearOnDemandApi.linearOnDemandBeziehungVorgeschlagenVorgeplantErstellen$(command);
  }

  linearOnDemandBeziehungVorgeschlagenVorgeplantAktualisieren$(
    command: LinearOnDemandBeziehungVorgeschlagenAktualisierenCommand,
  ) {
    return this.linearOnDemandApi.linearOnDemandBeziehungVorgeschlagenVorgeplantAktualisieren$(
      command,
    );
  }

  linearOnDemandBeziehungVorgemerktErstellen$(
    command: LinearOnDemandBeziehungVorgemerktErstellenCommand,
  ) {
    return this.linearOnDemandApi.linearOnDemandBeziehungVorgemerktErstellen$(command);
  }

  linearOnDemandBeziehungVorgemerktAktualisieren$(
    command: LinearOnDemandBeziehungVorgemerktAktualisierenCommand,
  ) {
    return this.linearOnDemandApi.linearOnDemandBeziehungVorgemerktAktualisieren$(command);
  }

  openLinearOnDemandAcceptWindow$(windowInput: PlanungsobjektAcceptWindowInput) {
    const linearTitel = windowInput.linearTitel;

    const dialogRef: CustomWindowRef<
      PlanungsobjektAcceptWindowComponent,
      PlanungsobjektAcceptWindowResult
    > = this.customWindowService.open({
      content: PlanungsobjektAcceptWindowComponent,
      ...MediumWindow(),
      title: `Vorschlag "${
        linearTitel.length > 25 ? linearTitel.substring(0, 23) + "..." : linearTitel
      }" akzeptieren`,
    });

    dialogRef.content.instance.data = windowInput;
    return dialogRef;
  }
}
