import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DropDownFillMode, MultiSelectComponent } from "@progress/kendo-angular-dropdowns";
import { tap } from "rxjs";
import { SelectOption } from "src/app/models/enums/enum-base";
import { OnChangeFn, OnTouchedFn, setEnablementForControl } from "src/app/utils/form-utils";
import { noop } from "src/app/utils/function-utils";

@Component({
  selector: "app-multiselect",
  templateUrl: "./multiselect.component.html",
  styleUrls: ["./multiselect.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiselectComponent,
      multi: true,
    },
  ],
})
export class MultiselectComponent<T extends string>
  implements ControlValueAccessor, OnInit, OnChanges
{
  @Input() options: SelectOption<T>[];
  @Input() fillMode: DropDownFillMode = "solid";
  @Input() label: string;
  @Input() autoClose = false;
  @Input() clearButton = false;
  @Input() openDropdownInitial = false;
  /**
   * Gibt an, ab wie vielen Elementen die Summary Tags angezeigt werden sollen.
   * Wird initial auf Number.MAX_SAFE_INTEGER gesetzt, da das Kendo Dropdown
   * keine Alternative anbietet, um Summary Tags zu deaktivieren.
   */
  @Input() summaryTagMaxItems = Number.MAX_SAFE_INTEGER;

  @ViewChild("multiselect", { static: true }) private multiselect: MultiSelectComponent;

  isCheckedSelectAll: boolean;
  dropdownListDefaultElementHeight = 36;
  dropdownListDefaultMaxHeight = 432; // Entspricht 12 Elementen
  dropdownListHeight = 200; // 200px ist der Kendo Default

  formControl = new FormControl<T[]>([]);
  /**
   * FormControl ist die einzige Stelle, an der die Werte des Multiselects
   * gespeichert werden.
   */
  get _value(): T[] {
    return this.formControl.value ?? [];
  }
  changed: OnChangeFn<T[] | null> = noop;
  touched: OnTouchedFn = noop;

  /**
   * Gibt an, ob die Summary Tags angezeigt werden sollen. Dies ist der Fall,
   * wenn die Anzahl der ausgewählten Elemente größer oder gleich dem Wert
   * von `summaryTagMaxItems` ist.
   */
  isShowingSummaryTags = false;

  constructor() {
    this.formControl.valueChanges
      .pipe(
        tap(() => this.touched()),
        tap(() => this.updateShowingSummaryTags()),
        takeUntilDestroyed(),
      )
      .subscribe((newValue) => {
        this.changed(newValue);
      });
  }

  ngOnInit(): void {
    if (this._value.length < this.summaryTagMaxItems)
      this.multiselect.toggle(this.openDropdownInitial);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options?.currentValue) {
      const calculatedMaxHeight = this.dropdownListDefaultElementHeight * this.options.length;
      this.dropdownListHeight =
        calculatedMaxHeight > this.dropdownListDefaultMaxHeight
          ? this.dropdownListDefaultMaxHeight
          : calculatedMaxHeight;
    }
  }

  writeValue(value: T[]): void {
    this.formControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.touched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    setEnablementForControl(this.formControl, !isDisabled);
  }

  get toggleAllText() {
    return this.isCheckedSelectAll ? "Auswahl aufheben" : "Alle auswählen";
  }

  get isPartiallyChecked() {
    return this._value.length !== 0 && this._value.length !== this.options.length;
  }

  isItemSelected(item: T): boolean {
    // Workaround: Sorgt dafür, dass das Dropdown automatisch
    // geschlossen wird, nach dem es initial geöffnet wurde
    if (this.openDropdownInitial) this.multiselect.focus();
    return this._value.includes(item);
  }

  onClickSelectAll() {
    this.isCheckedSelectAll = !this.isCheckedSelectAll;
    const allOptions = this.options.map((options) => options.value);
    const selectedValues = this.isCheckedSelectAll ? allOptions : [];
    this.formControl.setValue(selectedValues);
  }

  private updateShowingSummaryTags() {
    this.isShowingSummaryTags = this._value.length >= this.summaryTagMaxItems;
  }
}
