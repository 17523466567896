import { Component, Input } from "@angular/core";
import { DialogAction, DialogContentBase, DialogRef } from "@progress/kendo-angular-dialog";
import { Aktion } from "src/app/models/enums/aktion";

export interface CustomDialogAction<T = any> extends DialogAction {
  tooltip?: string;
  isCancelAction?: boolean;
  testId?: string;
  /**
   * TODO entfernen, wenn möglich
   */
  action?: Aktion;
  data?: T;
}

@Component({
  selector: "app-standard-action-dialog",
  templateUrl: "./standard-action-dialog.component.html",
  styleUrls: ["./standard-action-dialog.component.scss"],
})
export class StandardActionDialogComponent extends DialogContentBase {
  @Input() actions: CustomDialogAction[];
  @Input() content: string | undefined;
  @Input() title: string;

  constructor(public dialogRef: DialogRef) {
    super(dialogRef);
  }
}
