import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { ContextMenuTargetContainerDirective } from "@progress/kendo-angular-menu";
import mehrfachauswahlSelectors from "src/app/core/stores/mehrfachauswahl/mehrfachauswahl.selectors";
import { planungsobjektActions } from "src/app/core/stores/planungsobjekt/planungsobjekt.actions";
import { SchemaplatzDto } from "src/app/models/openapi/model/schemaplatz-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzKeyDto } from "src/app/models/openapi/model/sendeplatz-key-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

@Component({
  selector: "app-sendeplatz",
  templateUrl: "./sendeplatz.component.html",
  styleUrls: ["./sendeplatz.component.scss"],
})
export class SendeplatzComponent {
  @Input() sendeplatz: SendeplatzDto;
  @Input() schemaplatz: SchemaplatzDto;
  @Input() ansichtViewModel: AnsichtViewModel;
  @Input() target: ContextMenuTargetContainerDirective;
  @Input() isMengengeruestErlaubt: boolean;

  readonly planungsobjekteInMehrfachauswahl$ = this.store.select(
    mehrfachauswahlSelectors.selectPlanungsobjekteFromIdsInMehrfachauswahl,
  );
  constructor(private store: Store) {}

  addPlanungsobjektInAnsicht(event: Event): void {
    const titel = (event.target as HTMLInputElement).value.trim();

    if (titel.length > 1) {
      const sendeplatzKey: SendeplatzKeyDto = {
        kanal: this.sendeplatz.kanal,
        kalendertag: this.sendeplatz.kalendertag,
        beginnzeit: this.sendeplatz.beginnzeit,
        laenge: this.sendeplatz.laenge,
        sendetag: this.sendeplatz.sendetag,
      };

      this.store.dispatch(
        planungsobjektActions.createPlanungsobjektOnSendeplatz({
          shouldCloseWindow: true, // Das fühlt sich falsch an.
          titel,
          sendeplatzKey,
        }),
      );
    }
    // Setze das Input Feld nach einer Eingabe oder beim Blur zurück
    (event.target as HTMLInputElement).value = "";
  }

  onInputEnter(event: KeyboardEvent) {
    if (event.key === "Enter" && event.type === "keyup" && event.target) {
      const inputField = event.target as HTMLInputElement;
      inputField.blur();
    }
  }
}
