/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type KonkurrenzprogrammKategorie =
  | "KeineKategorie"
  | "Sport"
  | "Show"
  | "Fiction"
  | "Dokumentation";

export const KonkurrenzprogrammKategorie = {
  KEINE_KATEGORIE: "KeineKategorie" as KonkurrenzprogrammKategorie,
  SPORT: "Sport" as KonkurrenzprogrammKategorie,
  SHOW: "Show" as KonkurrenzprogrammKategorie,
  FICTION: "Fiction" as KonkurrenzprogrammKategorie,
  DOKUMENTATION: "Dokumentation" as KonkurrenzprogrammKategorie,
};
