<table class="listenansicht-table">
  <app-listenansicht-header
    class="column-header"
    [ansichtInitHeaders]="facade.ansichtHeaders$ | async"
    (toggleColumnByIdEvent)="facade.toggleColumnHeaderForId($event)"
  ></app-listenansicht-header>
  <ng-container
    *ngFor="
      let multiAnsichtsendeplatzGroup of facade.multiansichtSendeplatzGruppen$ | async | keyvalue
    "
  >
    <tr
      *ngIf="facade.displaysMultipleYears$ | async"
      app-ansicht-divider
      [ansichtHeaderLength]="(facade.ansichtHeaders$ | async)?.length"
      [topOffsetForSticky]="19"
      [year]="+multiAnsichtsendeplatzGroup.key"
    ></tr>
    <ng-container *ngFor="let sendeplatzGroupZeilen of multiAnsichtsendeplatzGroup.value">
      <tr
        app-listenansicht-row
        [ansicht]="ansichtViewModel"
        [ansichtHeaders]="facade.ansichtHeaders$ | async"
        [sendeplatzGroupZeilen]="sendeplatzGroupZeilen"
        [showKonkurrenzEvents]="showKonkurrenzEvents"
        [showNotizen]="showNotizen"
      ></tr>
    </ng-container>
  </ng-container>
  <button
    class="next-year-button dts-select-exclude"
    data-testid="next-year-arrow-button"
    fillMode="flat"
    kendoButton
    size="none"
    title="Lade Ansicht für nächstes Jahr"
    [disabled]="(facade.hasNextAnsicht$ | async) === false"
    [svgIcon]="Icons.arrowDownIcon"
    (click)="facade.loadNextAnsicht()"
  ></button>
</table>
