import { DatePipe, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UrlSerializer } from "@angular/router";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { de } from "date-fns/locale";
import { DateFnsConfigurationService, DateFnsModule } from "ngx-date-fns";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthorizationMockInterceptor } from "./authorization/authorization-mock.interceptor";
import { CoreModule } from "./core/core.module";
import { GlobalErrorHandler } from "./errors/global-error-handler";
import { AnsichtDividerComponent } from "./pages/ansichten/ansicht-divider/ansicht-divider.component";
import { AnsichtenComponent } from "./pages/ansichten/ansichten.component";
import BlockansichtBlockComponent from "./pages/ansichten/blockansicht/blockansicht-block/blockansicht-block.component";
import { BlockansichtComponent } from "./pages/ansichten/blockansicht/blockansicht.component";
import { PlanungsobjektOnBlockansichtChipComponent } from "./pages/ansichten/blockansicht/planungsobjekt-on-blockansicht-chip/planungsobjekt-on-blockansicht-chip.component";
import { ZoomSliderComponent } from "./pages/ansichten/blockansicht/zoom-slider/zoom-slider.component";
import { KalenderansichtSendeplatzJahrComponent } from "./pages/ansichten/kalenderansicht/kalenderansicht-sendeplatz-jahr/kalenderansicht-sendeplatz-jahr.component";
import { KalenderansichtSendeplatzMonatComponent } from "./pages/ansichten/kalenderansicht/kalenderansicht-sendeplatz-monat/kalenderansicht-sendeplatz-monat.component";
import { KalenderansichtSendeplatzTagComponent } from "./pages/ansichten/kalenderansicht/kalenderansicht-sendeplatz-tag/kalenderansicht-sendeplatz-tag.component";
import { KalenderansichtSendeplatzWocheComponent } from "./pages/ansichten/kalenderansicht/kalenderansicht-sendeplatz-woche/kalenderansicht-sendeplatz-woche.component";
import { KalenderansichtSendeplatzComponent } from "./pages/ansichten/kalenderansicht/kalenderansicht-sendeplatz/kalenderansicht-sendeplatz.component";
import { KalenderansichtComponent } from "./pages/ansichten/kalenderansicht/kalenderansicht.component";
import { ListenansichtHeaderComponent } from "./pages/ansichten/listenansicht/listenansicht-header/listenansicht-header.component";
import { ListenansichtRowComponent } from "./pages/ansichten/listenansicht/listenansicht-row/listenansicht-row.component";
import { ListenansichtSendeplatzNotizenComponent } from "./pages/ansichten/listenansicht/listenansicht-row/listenansicht-sendeplatz-notizen/listenansicht-sendeplatz-notizen.component";
import { ListenansichtSendeplatzVorgeplantComponent } from "./pages/ansichten/listenansicht/listenansicht-row/listenansicht-sendeplatz-vorgeplant/listenansicht-sendeplatz-vorgeplant.component";
import { PlanungsobjektVorgeschlagenBadgeComponent } from "./pages/ansichten/listenansicht/listenansicht-row/planungsobjekt-vorgeschlagen-badge/planungsobjekt-vorgeschlagen-badge.component";
import { ListenansichtComponent } from "./pages/ansichten/listenansicht/listenansicht.component";
import { YearDropdownComponent } from "./pages/ansichten/year-dropdown/year-dropdown.component";
import { DashboardAnsichtComponent } from "./pages/dashboard-tab/dashboard-ansicht/dashboard-ansicht.component";
import { DashboardTabComponent } from "./pages/dashboard-tab/dashboard-tab.component";
import { DashboardZDFComponent } from "./pages/dashboard-zdf/dashboard-zdf.component";
import { DashboardZDFneoComponent } from "./pages/dashboard-zdfneo/dashboard-zdfneo.component";
import { EventListComponent } from "./pages/event/event-list/event-list.component";
import { EventComponent } from "./pages/event/event.component";
import { EkAnsichtComponent } from "./pages/events-konkurenz-ansicht/ek-ansicht.component";
import { AuthorizationFailedComponent } from "./pages/fehler/authorization-failed.component";
import { KonkurrenzprogrammListComponent } from "./pages/konkurrenz/konkurrenz-list/konkurrenzprogramm-list.component";
import { KonkurrenzprogrammComponent } from "./pages/konkurrenz/konkurrenzprogramm.component";
import { MerklisteComponent } from "./pages/merkliste/merkliste.component";
import { AbweichendeLaengeBadgeComponent } from "./pages/planungsobjekt/abweichende-laenge-badge/abweichende-laenge-badge.component";
import { PlanungsobjektVorgeplantListComponent } from "./pages/planungsobjekt/planungsobjekt-vorgeplant-list/planungsobjekt-vorgeplant-list.component";
import { PlanungsobjektVorgeplantComponent } from "./pages/planungsobjekt/planungsobjekt-vorgeplant.component";
import { PlanungsobjektVorgeschlagenListComponent } from "./pages/planungsobjekt/planungsobjekt-vorgeschlagen-list/planungsobjekt-vorgeschlagen-list.component";
import { PlanungsobjektVorgeschlagenComponent } from "./pages/planungsobjekt/planungsobjekt-vorgeschlagen.component";
import { AdditionalFilterWrapperComponent } from "./pages/recherche/additional-filter-wrapper/additional-filter-wrapper.component";
import { AdditionalFiltersComponent } from "./pages/recherche/additional-filters/additional-filters.component";
import { FilterRowComponent } from "./pages/recherche/filter-chip-list/filter-chip-list.component";
import { RechercheComponent } from "./pages/recherche/recherche.component";
import { SearchFormComponent } from "./pages/recherche/search-form/search-form.component";
import { SearchResultsDetailsGridComponent } from "./pages/recherche/search-results-grid/search-results-details-grid/search-results-details-grid.component";
import { SearchResultsGridComponent } from "./pages/recherche/search-results-grid/search-results-grid.component";
import { SendeplatzComponent } from "./pages/sendeplatz/sendeplatz.component";
import { FilterComponent } from "./pages/sidebar/filter/filter.component";
import { MehrfachauswahlComponent } from "./pages/sidebar/mehrfachauswahl/mehrfachauswahl.component";
import { SidebarComponent } from "./pages/sidebar/sidebar.component";
import { WeiteresComponent } from "./pages/sidebar/weiteres/weiteres.component";
import { ShortenedUrlSerializer } from "./shared/angular/angular/ShortendedUrlSerializer";
import { PharosExportWindowComponent } from "./shared/dialogs/pharos-export-window/pharos-export-window.component";
import { SharedModule } from "./shared/shared.module";
import { DebugWindowComponent } from "./shared/windows/debug-window/debug-window.component";
import { EKWindowComponent } from "./shared/windows/ek-window/ek-window.component";
import { GetItImportWindowComponent } from "./shared/windows/get-it-import-window/get-it-import-window.component";
import { InformationWindowComponent } from "./shared/windows/information-window/information-window.component";
import { MengengeruestWindowComponent } from "./shared/windows/mengengeruest-window/mengengeruest-window.component";
import { MengengeruestEintragWindowComponent } from "./shared/windows/mengengeruesteintrag-window/mengengeruesteintrag-window.component";
import { PlanungsobjektAcceptWindowComponent } from "./shared/windows/planungsobjekt-accept-window/planungsobjekt-accept-window.component";
import { PlanungsobjektGetitVerknuepfungAufhebenWindowComponent } from "./shared/windows/planungsobjekt-getit-verknuepfung-aufheben/planungsobjekt-getit-verknuepfung-aufheben-window.component";
import { PlanungsobjektOnDemandZuMerklisteWindowComponent } from "./shared/windows/planungsobjekt-ondemand-zu-merkliste-window/planungsobjekt-ondemand-zu-merkliste-window.component";
import { PlanungsobjektWindowModule } from "./shared/windows/planungsobjekt-window/planungsobjekt-window.module";
import { PlanungshinweiseWindowComponent } from "./shared/windows/planunshinweise-window/planungshinweise-window.component";
import { SendeplatzWindowComponent } from "./shared/windows/sendeplatz-window/sendeplatz-window.component";

// Lokalisierung Angular
registerLocaleData(localeDe, "de");

// Lokalisierung Date FNS Pipes
const dateFnsPipeConfig = new DateFnsConfigurationService();
dateFnsPipeConfig.setLocale(de);

@NgModule({
  declarations: [
    AnsichtenComponent,
    AppComponent,
    DashboardTabComponent,
    DashboardZDFComponent,
    DashboardZDFneoComponent,
    DashboardAnsichtComponent,
    KalenderansichtComponent,
    KalenderansichtSendeplatzJahrComponent,
    KalenderansichtSendeplatzMonatComponent,
    KalenderansichtSendeplatzWocheComponent,
    KalenderansichtSendeplatzTagComponent,
    KalenderansichtSendeplatzComponent,
    ListenansichtComponent,
    EkAnsichtComponent,
    MerklisteComponent,
    MengengeruestWindowComponent,
    PharosExportWindowComponent,
    GetItImportWindowComponent,
    MengengeruestEintragWindowComponent,
    SendeplatzComponent,
    SendeplatzWindowComponent,
    InformationWindowComponent,
    DebugWindowComponent,
    EKWindowComponent,
    KonkurrenzprogrammComponent,
    KonkurrenzprogrammListComponent,
    EventListComponent,
    EventComponent,
    PlanungsobjektVorgeplantComponent,
    AbweichendeLaengeBadgeComponent,
    PlanungsobjektVorgeplantListComponent,
    BlockansichtComponent,
    BlockansichtBlockComponent,
    PlanungsobjektOnBlockansichtChipComponent,
    MehrfachauswahlComponent,
    AnsichtDividerComponent,
    ListenansichtRowComponent,
    PlanungsobjektVorgeschlagenBadgeComponent,
    ZoomSliderComponent,
    RechercheComponent,
    SearchFormComponent,
    FilterRowComponent,
    SearchResultsGridComponent,
    SearchResultsDetailsGridComponent,
    AdditionalFiltersComponent,
    AdditionalFilterWrapperComponent,
    ListenansichtHeaderComponent,
    PlanungsobjektVorgeschlagenComponent,
    PlanungsobjektVorgeschlagenListComponent,
    PlanungsobjektAcceptWindowComponent,
    PlanungsobjektOnDemandZuMerklisteWindowComponent,
    PlanungsobjektGetitVerknuepfungAufhebenWindowComponent,
    YearDropdownComponent,
    ListenansichtSendeplatzVorgeplantComponent,
    ListenansichtSendeplatzNotizenComponent,
    PlanungshinweiseWindowComponent,
    SidebarComponent,
    FilterComponent,
    WeiteresComponent,
    AuthorizationFailedComponent,
  ],
  providers: [
    {
      provide: OAuthStorage,
      useFactory: () => localStorage,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    DatePipe,
    { provide: DateFnsConfigurationService, useValue: dateFnsPipeConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationMockInterceptor,
      multi: true,
    },
    {
      provide: UrlSerializer,
      useClass: ShortenedUrlSerializer,
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    DateFnsModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
    PlanungsobjektWindowModule,
  ],
})
export class AppModule {}
