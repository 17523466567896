import { createActionGroup, props } from "@ngrx/store";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzKeyDto } from "src/app/models/openapi/model/sendeplatz-key-dto";

export const sendeplatzActions = createActionGroup({
  source: "Sendeplatz API",
  events: {
    // TODO: Sollte vielleicht nochmal in mehrere Aktionen aufgeteilt werden
    updateSendeplaetzeByAnsichtenSuccess: props<{
      sendeplaetze: SendeplatzDto[];
    }>(),
    updateSendeplatz: props<{
      sendeplatz: SendeplatzDto;
    }>(),
    updateSendeplatzSuccess: props<{
      sendeplatz: SendeplatzDto;
    }>(),
    updateSendeplatzWithSendeplatzKey: props<{
      sendeplatzKey: SendeplatzKeyDto;
    }>(),
    updateSendeplatzWithSendeplatzKeySuccess: props<{
      sendeplatz: SendeplatzDto;
    }>(),
    loadSendeplatzWithSendeplatzKey: props<{
      planungsobjekt: PlanungsobjektDto;
    }>(),
  },
});
