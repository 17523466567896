/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type PlanungshinweisStatus = "KeinStatus" | "Problematisch";

export const PlanungshinweisStatus = {
  KEIN_STATUS: "KeinStatus" as PlanungshinweisStatus,
  PROBLEMATISCH: "Problematisch" as PlanungshinweisStatus,
};
