import { ContentCommunity } from "src/app/models/openapi/model/content-community";
import { FSKEinstufung } from "src/app/models/openapi/model/fsk-einstufung";
import { Genre } from "src/app/models/openapi/model/genre";
import { GetitProduktStatus } from "src/app/models/openapi/model/getit-produkt-status";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektFarbgebung } from "src/app/models/openapi/model/planungsobjekt-farbgebung";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { StofffuehrendeRedaktion } from "src/app/models/openapi/model/stofffuehrende-redaktion";

// Virtual Scroll Property. Fixer Wert wurde aus dem DOM entnommen.
export const GRID_ROW_HEIGHT = 30;
export const MIN_RESIZABLE_COLUMN_WIDTH = 80;
export const DEFAULT_COLUMN_WIDTH = 100;

export enum FilterEnum {
  GENRE = "GENRE",
  PLANUNGSKONTEXT = "PLANUNGSKONTEXT",
  TITEL = "TITEL",
  CONTENT_COMMUNITY = "CONTENT_COMMUNITY",
}

export type AdditionalFilter = {
  text: string;
  columnKey: RechercheGridResultColumn;
  type: FilterEnum;
};

export interface ResizedColumn {
  field: RechercheGridResultColumn;
  width: number;
}

export interface RechercheSearchQueryVM {
  sendetagVon: Date;
  sendetagBis: Date;
  kanaele: Array<Kanal>;
  redaktionen: Array<Redaktion>;
  highlightsOnly: boolean;
  genres: Array<Genre>;
  planungskontext: Array<Planungskontext>;
  titel: string;
  contentCommunities: Array<ContentCommunity>;
}

export interface RechercheSearchResultVM {
  id: string;
  /**
   * Nur Parents können expandiert sein.
   */
  isExpanded: boolean;
  /**
   * Parent-Child-Relation, basierend auf vorhandenen LinearOnDemand Beziehungen
   */
  childrenIds: string[];
  /**
   * Die wirklichen Kinder, die nachgeladen wurden und in das Objekt reingemappt wurden
   */
  children: RechercheSearchResultVM[];
  sendetag: Date | null;
  zeit: { von: Date; bis: Date } | null;
  onlineAb: Date | null;
  onlineAbZeit: string | null;
  onlineBis: Date | null;
  titel: string;
  redaktion: Redaktion;
  mengengeruest: boolean;
  highlight: boolean;
  genre: Genre;
  hasVariante: boolean;
  planlaenge: number | null;
  notiz: string;
  farbgebung: PlanungsobjektFarbgebung;
  contentCommunities: Array<ContentCommunity>;
  planungskontext: Planungskontext;
  variante: number | null;
  kanal: Kanal;
  fruehesteVeroeffentlichung: string | null;
  stofffuehrendeRedaktion: StofffuehrendeRedaktion | null;
  fsk: FSKEinstufung | null;
  staffelnummer: number | null;
  folgennummer: number | null;
  gesamtfolgennummer: string | null;
  inhaltsbeschreibung: string | null;
  mitwirkende: string | null;
  getitId: string | null;
  produkttitel: string | null;
  produkttitelMultipart: string | null;
  produktstatus: GetitProduktStatus | null;
  produktlaenge: number | null;
}

/**
 * Die ExportableRechercheSearchResultVM ist eine abgeleitete Version der RechercheSearchResultVM,
 * die für den Export in Excel-Dateien verwendet wird. Insbesondere die Felder "zeit", "planlaenge" und "contentCommunities"
 * werden in ein für den Export geeignetes Format umgewandelt.
 */
export type ExportableRechercheSearchResultVM = Omit<
  RechercheSearchResultVM,
  | "zeit"
  | "planlaenge"
  | "contentCommunities"
  | "kanal"
  | "isExpanded"
  | "childrenIds"
  | "isChild"
  | "children"
> & {
  zeit: string | null;
  planlaenge: string | null;
  contentCommunities: string;
  kanal: string;
};

/**
 * Feldnamen der Recherche Ergebnis Tabelle.
 * !! Namen müssen 1:1 mit den Feldern des {@link SearchResultDto} übereinstimmen, damit die Sortierung der Kendo Grids funktioniert. !!
 */
export const GridResultColumnFields = {
  KANAL: "kanal",
  SENDETAG: "sendetag",
  ZEIT: "zeit",
  ONLINEAB: "onlineAb",
  ONLINEABZEIT: "onlineAbZeit",
  ONLINEBIS: "onlineBis",
  TITEL: "titel",
  REDAKTION: "redaktion",
  PLANLAENGE: "planlaenge",
  GENRE: "genre",
  CONTENT_COMMUNITIES: "contentCommunities",
  FARBGEBUNG: "farbgebung",
  NOTIZ: "notiz",
  VARIANTE: "variante",
  PLANUNGSKONTEXT: "planungskontext",
  // produktfelder
  FRUEHESTEVEROEFFENTLICHUNG: "fruehesteVeroeffentlichung",
  GETITID: "getitId",
  PRODUKTSTATUS: "produktstatus",
  PRODUKTTITEL: "produkttitel",
  PRODUKTLAENGE: "produktlaenge",
  STOFFFUEHRENDEREDAKTION: "stofffuehrendeRedaktion",
  FSK: "fsk",
  STAFFELNUMMER: "staffelnummer",
  FOLGENNUMMER: "folgennummer",
  GESAMTFOLGENNUMMER: "gesamtfolgennummer",
  INHALTSBESCHREIBUNG: "inhaltsbeschreibung",
  MITWIRKENDE: "mitwirkende",
} satisfies Record<string, keyof RechercheSearchResultVM>;

export type RechercheGridResultColumn =
  (typeof GridResultColumnFields)[keyof typeof GridResultColumnFields];

export const isRechercheGridResultColumn = (value: string): value is RechercheGridResultColumn =>
  Object.values(GridResultColumnFields).includes(value as RechercheGridResultColumn);

/**
 * Die Standard-Sortierung der Spalten in der Recherche-Ergebnis-Tabelle.
 */
export const defaultRechercheGridColumnOrder: RechercheGridResultColumn[] = [
  "kanal",
  "sendetag",
  "zeit",
  "onlineAb",
  "onlineAbZeit",
  "onlineBis",
  "titel",
  "redaktion",
  "planlaenge",
  "genre",
  "contentCommunities",
  "farbgebung",
  "notiz",
  "variante",
  "planungskontext",
  "getitId",
  "produktstatus",
  "produkttitel",
  "produktlaenge",
  "stofffuehrendeRedaktion",
  "fsk",
  "staffelnummer",
  "folgennummer",
  "gesamtfolgennummer",
  "inhaltsbeschreibung",
  "mitwirkende",
];

export interface RechercheGridFormattingOptionsVM {
  shownColumns: RechercheGridResultColumn[];
}

/**
 * Entspricht den Eigenschaften einer kendo-grid-column in der Recherche-Ergebnis-Tabelle.
 */
export type GridColumnProperties = {
  title: string;
  field: RechercheGridResultColumn;
  /**
   * Die Breite der Spalte in Pixeln. `null` bedeutet, dass die Spalte nicht festgelegt ist und die Breite automatisch berechnet wird.
   * Wird initial standardmäßig auf `null` gesetzt, über resizing dynamisch angepasst und in den Store geschrieben
   */
  width: number | null;
  sortable: boolean;
  style: { [key: string]: string | undefined };
};

/**
 * Erlaubt es den GridColumnProperties die Eigenschaft "hidden" dynamisch nachträglich hinzuzufügen.
 */
export type ActiveGridColumnProperties = GridColumnProperties & {
  hidden: boolean;
  /**
   * Wird auf `true` gesetzt, wenn die Checkbox in {@link AdditionalColumnsPopupComponent} deaktiviert werden soll.
   * Bspw. wenn die Spalte aufgrund eines Kanals (ZDF: Sendetag, Titel & Zeit) immer angezeigt werden soll.
   */
  disabled: boolean;
};

/**
 * Die Default Eigenschaften der Grid Columns.
 */
export const defaultGridColumns: GridColumnProperties[] = [
  {
    field: GridResultColumnFields.KANAL,
    sortable: true,
    style: { "line-height": undefined },
    title: " ",
    width: null,
  },
  {
    field: GridResultColumnFields.SENDETAG,
    sortable: true,
    style: { "line-height": undefined },
    title: "Sendetag",
    width: null,
  },
  {
    field: GridResultColumnFields.ZEIT,
    sortable: false,
    style: { "line-height": undefined },
    title: "Zeit",
    width: null,
  },
  {
    field: GridResultColumnFields.ONLINEAB,
    sortable: true,
    style: { "line-height": undefined },
    title: "Online ab",
    width: null,
  },
  {
    field: GridResultColumnFields.ONLINEABZEIT,
    sortable: true,
    style: { "line-height": undefined },
    title: "Online ab Zeit",
    width: null,
  },
  {
    field: GridResultColumnFields.ONLINEBIS,
    sortable: true,
    style: { "line-height": undefined },
    title: "Online bis",
    width: null,
  },
  {
    field: GridResultColumnFields.TITEL,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Planungstitel",
    width: null,
  },
  {
    field: GridResultColumnFields.REDAKTION,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "pl. Red.",
    width: null,
  },
  {
    field: GridResultColumnFields.PLANLAENGE,
    sortable: true,
    style: { "line-height": undefined },
    title: "Länge (min:sek)",
    width: null,
  },
  {
    field: GridResultColumnFields.GENRE,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "PLP Genre",
    width: null,
  },
  {
    field: GridResultColumnFields.CONTENT_COMMUNITIES,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Zielgruppe",
    width: null,
  },
  {
    field: GridResultColumnFields.FARBGEBUNG,
    sortable: true,
    style: { "line-height": undefined },
    title: "Farbe",
    width: null,
  },
  {
    field: GridResultColumnFields.NOTIZ,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Notiz",
    width: null,
  },
  {
    field: GridResultColumnFields.VARIANTE,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Variante",
    width: null,
  },
  {
    field: GridResultColumnFields.PLANUNGSKONTEXT,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Planungskontext",
    width: null,
  },
  {
    field: GridResultColumnFields.FRUEHESTEVEROEFFENTLICHUNG,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "früheste VÖ",
    width: null,
  },
  {
    field: GridResultColumnFields.GETITID,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "get.it ID",
    width: null,
  },
  {
    field: GridResultColumnFields.PRODUKTSTATUS,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "get.it Status",
    width: null,
  },
  {
    field: GridResultColumnFields.PRODUKTTITEL,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "get.it Produkttitel",
    width: null,
  },
  {
    field: GridResultColumnFields.PRODUKTLAENGE,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "get.it Länge",
    width: null,
  },
  {
    field: GridResultColumnFields.STOFFFUEHRENDEREDAKTION,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Stofff. Red.",
    width: null,
  },
  {
    field: GridResultColumnFields.FSK,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "FSK",
    width: null,
  },
  {
    field: GridResultColumnFields.STAFFELNUMMER,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Staffelnr.",
    width: null,
  },
  {
    field: GridResultColumnFields.FOLGENNUMMER,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Folgennr.",
    width: null,
  },
  {
    field: GridResultColumnFields.GESAMTFOLGENNUMMER,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Gesamtfolgennr.",
    width: null,
  },
  {
    field: GridResultColumnFields.INHALTSBESCHREIBUNG,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Inhalt",
    width: null,
  },
  {
    field: GridResultColumnFields.MITWIRKENDE,
    sortable: true,
    style: { "line-height": "1.5" },
    title: "Mitwirkende",
    width: null,
  },
];
