import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { GetVariantenzeilenByPublikationKeyQuery } from "src/app/models/openapi/model/get-variantenzeilen-by-publikation-key-query";
import { VariantenzeileDto } from "src/app/models/openapi/model/variantenzeile-dto";
import { PlanlaengenProperties } from "./planungsobjekt-window-wann-wo.utils";

export const planungsobjektWindowWannWoActions = createActionGroup({
  source: "Planungsobjekt Wann Wo Window",
  events: {
    fetchAvailableVariantenzeilenDebounced: props<{
      query: GetVariantenzeilenByPublikationKeyQuery;
    }>(),
    fetchAvailableVariantenzeilen: props<{ query: GetVariantenzeilenByPublikationKeyQuery }>(),
    fetchAvailableVariantenzeilenNotPossible: emptyProps(),

    fetchAvailableVariantenzeilenSuccess: props<{
      variantenzeilen: VariantenzeileDto[];
    }>(),
    fetchAvailableVariantenzeilenFailure: props<{
      error: unknown;
    }>(),

    updatedBeginnzeit: props<{
      beginnzeit: string | null;
    }>(),

    updatedEndzeit: props<{
      endzeit: string | null;
    }>(),

    updatedSendetag: props<{
      sendetag: string | null;
    }>(),

    updatedPlanlaenge: props<{
      planlaenge: number | null;
    }>(),

    /**
     * Irgendein Feld wurde erfolgreich aktualisiert und kann vom Form Service übernommen werden für das Patchen
     */
    updateWannBezugValueSuccess: props<{ wannBezugForm: PlanlaengenProperties }>(),

    /**
     * Der Update-Prozess war nicht notwendig, da keine Änderungen vorgenommen wurden. Sollte in der Regel nicht vorkommen,
     * weil wir über distinctUntilChanged und jsonify schon vorher filtern.
     *
     */
    noWannBezugChangesNecessary: emptyProps(),

    resetEndzeit: emptyProps(),
  },
});
