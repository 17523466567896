/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Alle Entitäten, für die Benachrichtigungen erstellt werden können.
 */
export type EntityType =
  | "Sendeplatz"
  | "PlanungsobjektLinear"
  | "PlanungsobjektOnDemand"
  | "Event"
  | "Konkurrenzprogrogramm"
  | "Planungshinweis";

export const EntityType = {
  SENDEPLATZ: "Sendeplatz" as EntityType,
  PLANUNGSOBJEKT_LINEAR: "PlanungsobjektLinear" as EntityType,
  PLANUNGSOBJEKT_ON_DEMAND: "PlanungsobjektOnDemand" as EntityType,
  EVENT: "Event" as EntityType,
  KONKURRENZPROGROGRAMM: "Konkurrenzprogrogramm" as EntityType,
  PLANUNGSHINWEIS: "Planungshinweis" as EntityType,
};
