import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateMengengeruesteintragCommand } from "src/app/models/openapi/model/create-mengengeruesteintrag-command";
import { MengengeruestGroupDto } from "src/app/models/openapi/model/mengengeruest-group-dto";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { UpdateMengengeruesteintragCommand } from "src/app/models/openapi/model/update-mengengeruesteintrag-command";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MengengeruestApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  createMengengeruesteintrag$(
    body: CreateMengengeruesteintragCommand,
  ): Observable<MengengeruesteintragDto> {
    return this.http.post<MengengeruesteintragDto>(this.apiUrl + "Mengengeruesteintrag/", body);
  }

  updateMengengeruesteintrag$(
    body: UpdateMengengeruesteintragCommand,
  ): Observable<MengengeruesteintragDto> {
    return this.http.put<MengengeruesteintragDto>(
      this.apiUrl + "Mengengeruesteintrag/" + `${body.id}`,
      body,
    );
  }

  deleteMengengeruestEintrag$(id: string): Observable<void> {
    return this.http.delete<void>(this.apiUrl + "Mengengeruesteintrag/" + `${id}`);
  }

  getMengengeruesteintraegeBySendeplatzId$(
    ansichtId: string,
    sendeplatzId: string,
  ): Observable<MengengeruestGroupDto> {
    return this.http.get<MengengeruestGroupDto>(
      this.apiUrl +
        "Mengengeruesteintrag/ByAnsicht/" +
        `${ansichtId}` +
        "/BySendeplatz/" +
        `${sendeplatzId}`,
    );
  }

  getMengengeruesteintraegeByAnsichtId$(ansichtId: string): Observable<MengengeruestGroupDto[]> {
    return this.http.get<MengengeruestGroupDto[]>(
      this.apiUrl + "Mengengeruesteintrag/ByAnsicht/" + `${ansichtId}`,
    );
  }
}
