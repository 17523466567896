import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { rechercheActions } from "src/app/core/stores/recherche/recherche.actions";
import {
  FilterEnum,
  RechercheGridResultColumn,
  RechercheSearchQueryVM,
  RechercheSearchResultVM,
  ResizedColumn,
} from "src/app/core/stores/recherche/recherche.model";
import { rechercheFeature } from "src/app/core/stores/recherche/recherche.reducer";
import rechercheSelectors from "src/app/core/stores/recherche/recherche.selectors";

@Component({
  selector: "app-recherche",
  templateUrl: "./recherche.component.html",
  styleUrls: ["./recherche.component.scss"],
})
export class RechercheComponent {
  readonly isFirstSearch$ = this.store.select(rechercheFeature.selectIsFirstSearch);
  readonly isSearchFormDirty$ = this.store.select(rechercheFeature.selectIsSearchFormDirty);
  readonly searchResults$ = this.store.select(rechercheSelectors.selectSearchResults);
  readonly expandedIds$ = this.store.select(rechercheFeature.selectExpandedIds);
  readonly kanaeleSelected$ = this.store.select(rechercheFeature.selectKanaeleSelected);
  readonly query$ = this.store.select(rechercheSelectors.selectSearchQueryVM);
  readonly activeAdditionalFilters$ = this.store.select(
    rechercheFeature.selectActiveAdditionalFilters,
  );
  readonly rechercheResultColumns$ = this.store.select(
    rechercheSelectors.selectRechercheResultColumns,
  );

  readonly rechercheShownGridColumnsInOrder$ = this.store.select(
    rechercheSelectors.selectShownGridColumnsSorted,
  );

  constructor(private store: Store) {}

  onUpdateQuery(query: RechercheSearchQueryVM) {
    this.store.dispatch(rechercheActions.updateQuery({ query }));
  }

  onUpdateAdditionalFilters(filters: FilterEnum[]) {
    this.store.dispatch(rechercheActions.setAdditionalFilters({ filters }));
  }

  onRemoveAdditionalFilter(filter: FilterEnum) {
    this.store.dispatch(rechercheActions.removeAdditionalFilter({ filter }));
  }

  protected onSearchRequested() {
    this.store.dispatch(rechercheActions.search());
  }

  protected onResultSelected(event: RechercheSearchResultVM) {
    this.store.dispatch(
      rechercheActions.openRechercheResultDetails({
        result: event,
      }),
    );
  }

  protected onToggleGridColumn(columns: RechercheGridResultColumn[]) {
    this.store.dispatch(
      rechercheActions.toggleGridColumns({
        columns,
      }),
    );
  }

  protected onLoadChildren(event: { childrenIds: string[]; parentId: string }) {
    const { childrenIds, parentId } = event;
    this.store.dispatch(rechercheActions.searchChildren({ childrenIds, parentIds: [parentId] }));
  }

  protected onLoadAllChildren(event: { childrenIds: string[]; parentIds: string[] }) {
    const { childrenIds, parentIds } = event;
    this.store.dispatch(rechercheActions.searchChildren({ childrenIds, parentIds }));
  }

  protected onCollapseParent(parentId: string) {
    this.store.dispatch(rechercheActions.collapseParents({ parentIds: [parentId] }));
  }

  protected onCollapseAllParents(parentIds: string[]) {
    this.store.dispatch(rechercheActions.collapseParents({ parentIds }));
  }

  protected onReorderColumn(event: { oldIndex: number; newIndex: number }) {
    this.store.dispatch(
      rechercheActions.reorderRechercheColumns({
        oldIndex: event.oldIndex,
        newIndex: event.newIndex,
      }),
    );
  }

  protected onColumnResize($event: ResizedColumn[]) {
    this.store.dispatch(
      rechercheActions.resizeGridColumns({
        resizedColumns: $event,
      }),
    );
  }
}
