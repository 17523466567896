export enum FeatureKey {
  Ansicht = "Ansicht",
  MultiAnsicht = "MultiAnsicht",
  Listenansicht = "Listenansicht",
  Kalenderansicht = "Kalenderansicht",
  Blockansicht = "Blockansicht",
  Sendeplatz = "Sendeplatz",
  SendeplatzGroup = "SendeplatzGroup",
  Sidebar = "Sidebar",
  Event = "Event",
  Konkurrenzprogramm = "Konkurrenzprogramm",
  Planungsobjekt = "Planungsobjekt",
  Beziehung = "Beziehung",
  Merkliste = "Merkliste",
  Dialogs = "Dialogs",
  Metadaten = "Metadaten",
  Mengengeruest = "Mengengeruest",
  Recherche = "Recherche",
  Permission = "Permission",
  Mehrfachauswahl = "Mehrfachauswahl",
  DragDrop = "DragDrop",
  Benachrichtigung = "Benachrichtigung",
  Planungshinweis = "Planungshinweis",
  Aktivitaeten = "Aktivitaeten",
  Shell = "Shell",
}
