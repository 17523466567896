import { Injectable } from "@angular/core";
import { minutesToSeconds } from "date-fns";
import {
  DATE_COLUMN_WIDTH,
  GAP_COLUMN_WIDTH,
} from "src/app/core/stores/blockansicht/blockansicht.model";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { DateFnsService } from "src/app/services/date-fns.service";
import { roundSecondsToNextMinute } from "src/app/utils/time-utils";
import { BlockansichtPillVM, BlockansichtRowDataVM } from "./blockansicht-viewmodel";

@Injectable({
  providedIn: "root",
})
export class BlockansichtPillPositioningService {
  /**
   * Erstelle die Blockansicht Pillen ViewModels
   * (aka die visuelle Repräsentation der Planungsobjekt auf einer Blockansicht).
   * Damit es im Bereich von Zeitumstellungen zu keinen Darstellungsfehlern kommt,
   * wird die Positionierung der Pillen ohne Zeitzoneninformationen berechnet.
   * Dies kann in Javascript erreicht werden, wenn wir die Zeit in
   */
  static calculatePillDataViewModel(
    planungsobjekt: PlanungsobjektLinearDto,
    rowData: BlockansichtRowDataVM,
    isFirstRow: boolean,
    blockansichtBeginnzeit: string,
    blockansichtLaengeInMinuten: number,
    blockWidth: number,
    minutesPerColumn: number,
  ): BlockansichtPillVM {
    // Falls die Blockansicht nach 00:00 startet, liegt
    // der Kalendertag der Zeile einen Tag hinter dem Sendetag.
    const zeileRange = BlockansichtPillPositioningService.toZuluDateInterval(
      rowData.kalendertag,
      blockansichtBeginnzeit,
      minutesToSeconds(blockansichtLaengeInMinuten),
    );

    const planungsobjektRange = BlockansichtPillPositioningService.toZuluDateInterval(
      planungsobjekt.kalendertag,
      planungsobjekt.beginnzeit,
      planungsobjekt.planlaenge!,
    );

    const overflowLeft = BlockansichtPillPositioningService.isVpFassungOverflownLeft(
      planungsobjektRange.start,
      zeileRange.start,
    );

    const overflowRight = BlockansichtPillPositioningService.isVpFassungOverflownRight(
      planungsobjektRange.end,
      zeileRange.end,
    );

    const pillWrapper = BlockansichtPillPositioningService.computePositionForPillWrapper(
      planungsobjektRange,
      zeileRange,
      blockWidth,
      isFirstRow,
      minutesPerColumn,
    );

    // Berechnete Width gemäß der Länge ohne Overflow
    const widthWithLaenge = BlockansichtPillPositioningService.computeWidthWithLaenge(
      blockWidth,
      minutesPerColumn,
      planungsobjekt,
    );

    const originalWidth = `${widthWithLaenge}px`;

    // Berechnete Width gemäß der Länge mit optionalem Overflow
    const dtoWidth = BlockansichtPillPositioningService.computeDtoWidth(
      zeileRange,
      planungsobjektRange,
      blockWidth,
      minutesPerColumn,
    );
    const computedWidth = `${dtoWidth}px`;

    return {
      planungsobjekt,
      overflowLeft,
      overflowRight,
      originalWidth,
      computedWidth,
      pillWrapper,
      tooltip: planungsobjekt.titel,
    };
  }

  static toZuluDateInterval(
    dateAsString: string,
    timeAsString: string,
    laengeInSekunden: number,
  ): Interval {
    const start = DateFnsService.createZuluDate(dateAsString, timeAsString);
    const end = DateFnsService.addSeconds(start, laengeInSekunden);
    return { start, end };
  }

  static isVpFassungOverflownRight(planungsobjektEnde: Date | number, zeileEnde: Date | number) {
    if (planungsobjektEnde > zeileEnde) {
      return {
        "border-top-right-radius": "0",
        "border-bottom-right-radius": "0",
      };
    }
    return null;
  }

  static isVpFassungOverflownLeft(planungsobjektBeginn: Date | number, zeileBeginn: Date | number) {
    if (planungsobjektBeginn < zeileBeginn) {
      return {
        "border-top-left-radius": "0",
        "border-bottom-left-radius": "0",
      };
    }
    return null;
  }

  static computePositionForPillWrapper(
    planungsobjektRange: Interval,
    zeileRange: Interval,
    blockWidth: number,
    isFirst: boolean,
    minutesPerColumn: number,
  ) {
    const leftPx = this.computeDtoOffset(
      zeileRange.start,
      planungsobjektRange.start,
      blockWidth,
      minutesPerColumn,
    );
    const left = `${leftPx}px`;

    return {
      position: "absolute",
      // "top" anstatt "bottom" verhindert bei mehreren Zeilen E&K, dass Chip unten "andockt"
      // Der Wert "0.3rem" kommt von der scss-Variable "$planungsobjektf-first-space"
      top: isFirst ? "0.3rem" : "0",
      // width und height müssen auf 0 gesetzt werden, damit ein horizontales Verschieben der Pillen möglich ist.
      // Gleichzeitig verhindert diese Anpassung, dass sich die Pillen gemäß https://jira.zdf.de/jira/browse/PUBLIT-1028
      // überlagern.
      width: "0",
      height: "0",
      left,
    };
  }

  static computeDtoOffset(
    zeileBeginn: Date | number,
    planungsobjektBeginn: Date | number,
    blockWidth: number,
    minutesPerColumn: number,
  ) {
    const leftOffset = DATE_COLUMN_WIDTH + GAP_COLUMN_WIDTH;

    if (this.isVpFassungOverflownLeft(planungsobjektBeginn, zeileBeginn))
      planungsobjektBeginn = zeileBeginn;

    const minutesSinceOffset = DateFnsService.getAbsoluteMinuteDifference(
      planungsobjektBeginn,
      zeileBeginn,
    );
    const beginnColumnCount = minutesSinceOffset / minutesPerColumn;
    return leftOffset + beginnColumnCount * blockWidth;
  }

  static computeWidthWithLaenge(
    blockWidth: number,
    minutesPerColumn: number,
    dto: PlanungsobjektDto,
  ): number {
    const laengeInMinutes = DateFnsService.secondsToMinutes(
      roundSecondsToNextMinute(dto.planlaenge),
    );
    return (laengeInMinutes / minutesPerColumn) * blockWidth;
  }

  static computeDtoWidth(
    zeileRange: Interval,
    planungsobjektRange: Interval,
    blockWidth: number,
    minutesPerColumn: number,
  ) {
    // Berechne die Länge der VP-Fassung in Sekunden und führe eine Rundung erst am Ende durch
    let laenge = DateFnsService.getAbsoluteSecondsDifference(
      planungsobjektRange.start,
      planungsobjektRange.end,
    );
    // Falls die Länge der VP-Fassung über den Zeitstrahl rechts hinausragen sollte
    // verwende die maximal möglich Länge im Rahmen des Zeistrahls
    // (Vermeidet einen visuellen Overflow der Pille über die E&K Spalte hinaus)
    if (planungsobjektRange.end > zeileRange.end) {
      const maxPossibleLaenge = DateFnsService.getAbsoluteSecondsDifference(
        planungsobjektRange.start,
        zeileRange.end,
      );
      // Bei der maxPossibleLaenge wird zusätzlich die endzeitAnpassung hinzu addiert.
      // Somit ragt die VP-Fassung ein kleines bisschen in die E&K Spalte und signalisiert
      // dem Nutzer, dass die Länge über die Ansicht hinausgeht
      laenge = maxPossibleLaenge;
    }

    // Falls die Länge der VP-Fassung über den Zeitstrahl links hinausragen sollte
    // verwende die maximal möglich Länge im Rahmen des Zeistrahls
    if (zeileRange.start > planungsobjektRange.start) {
      const differenceVpBeginnZeileBeginn = DateFnsService.getAbsoluteSecondsDifference(
        planungsobjektRange.start,
        zeileRange.start,
      );

      laenge = laenge - differenceVpBeginnZeileBeginn;
    }

    const laengeInMinutes = DateFnsService.secondsToMinutes(roundSecondsToNextMinute(laenge));
    return (laengeInMinutes / minutesPerColumn) * blockWidth;
  }
}
