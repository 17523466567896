import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";

export const notificationActions = createActionGroup({
  source: "Notification",
  events: {
    showNotification: props<{ message: string; notificationType: NotificationStyle }>(),
    noNotificationNecessary: emptyProps(),
  },
});
