import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { BenachrichtigungDto } from "src/app/models/openapi/model/benachrichtigung-dto";
import { BenachrichtigungVm } from "src/app/models/viewmodels/benachrichtigung-viewmodel";
import { isDefined } from "src/app/utils/object-utils";
import { FeatureKey } from "../feature.keys";
import { benachrichtigungActions } from "./benachrichtigung.actions";
import { isBenachrichtigungInIntervals } from "./benachrichtigung.filters";

function mapToViewModel(
  benachrichtigung: BenachrichtigungDto,
  confirmed: boolean,
): BenachrichtigungVm {
  return {
    ...benachrichtigung,
    confirmed,
    timestamp: new Date(benachrichtigung.createdAt),
  };
}

export type BenachrichtigungState = EntityState<BenachrichtigungVm>;

export const benachrichtigungAdapter: EntityAdapter<BenachrichtigungVm> =
  createEntityAdapter<BenachrichtigungVm>();

export const initialState: BenachrichtigungState = benachrichtigungAdapter.getInitialState({});

export const benachrichtigungFeature = createFeature({
  name: FeatureKey.Benachrichtigung,
  reducer: createReducer(
    initialState,
    on(
      benachrichtigungActions.loadBenachrichtigungenSuccess,
      (currentState, { benachrichtigungen }) => {
        const benachrichtigungenViewModels = benachrichtigungen.map((benachrichtigung) =>
          mapToViewModel(benachrichtigung, true),
        );
        return benachrichtigungAdapter.setAll(benachrichtigungenViewModels, currentState);
      },
    ),
    on(
      benachrichtigungActions.benachrichtigungFromSocket,
      (currentState, { benachrichtigung, requiresReload }) => {
        const benachrichtigungViewModel = mapToViewModel(benachrichtigung, !requiresReload);
        return benachrichtigungAdapter.addOne(benachrichtigungViewModel, currentState);
      },
    ),
    on(
      benachrichtigungActions.verwerfenMultipleSuccess,
      (currentState, { benachrichtigungIds }) => {
        return benachrichtigungAdapter.removeMany(benachrichtigungIds, currentState);
      },
    ),
    on(benachrichtigungActions.markAsConfirmedForIntervals, (currentState, { intervals }) => {
      const updates = Object.values(currentState.entities)
        .filter(isDefined)
        .filter(
          (benachrichtigung) =>
            !benachrichtigung.confirmed &&
            isBenachrichtigungInIntervals(benachrichtigung, intervals),
        )
        .map((benachrichtigung) => {
          return {
            id: benachrichtigung.id,
            changes: { confirmed: true },
          };
        });
      return benachrichtigungAdapter.updateMany(updates, currentState);
    }),
    on(benachrichtigungActions.markAsConfirmed, (currentState, { benachrichtigungIds }) => {
      const updates = Object.values(currentState.entities)
        .filter(isDefined)
        .filter(
          (benachrichtigung) =>
            !benachrichtigung.confirmed && benachrichtigungIds.includes(benachrichtigung.id),
        )
        .map((benachrichtigung) => {
          return {
            id: benachrichtigung.id,
            changes: { confirmed: true },
          };
        });
      return benachrichtigungAdapter.updateMany(updates, currentState);
    }),
  ),
  extraSelectors: ({ selectBenachrichtigungState }) => ({
    ...benachrichtigungAdapter.getSelectors(selectBenachrichtigungState),
  }),
});
