import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetVariantenzeilenByPublikationKeyQuery } from "src/app/models/openapi/model/get-variantenzeilen-by-publikation-key-query";
import { VariantenzeileDto } from "src/app/models/openapi/model/variantenzeile-dto";
import { SKIP_LOADING_INTERCEPTOR } from "src/app/shared/interceptors/http-loading.interceptor";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BlockansichtApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getVariantenzeilenByPublikationKey$(query: GetVariantenzeilenByPublikationKeyQuery) {
    return this.http.post<VariantenzeileDto[]>(
      `${this.apiUrl}Planungsobjekt/Variantenzeilen`,
      query,
      {
        context: new HttpContext().set(SKIP_LOADING_INTERCEPTOR, true),
      },
    );
  }
}
