export function secondsToMinutesOrNull(seconds: number | null | undefined): number | null {
  if (seconds === null || seconds === undefined) {
    return null;
  }
  return Math.floor(seconds / 60);
}

export function minutesToSecondsOrNull(minutes: number | null | undefined): number | null {
  if (minutes === null || minutes === undefined) {
    return null;
  }
  return minutes * 60;
}

export function roundSecondsInDateToNextMinute(date: Date): Date;
export function roundSecondsInDateToNextMinute(date: Date | null | undefined): Date | null;
export function roundSecondsInDateToNextMinute(date: Date | null | undefined): Date | null {
  if (!date) return null;
  const roundedDate = new Date(date);

  // Get the current seconds
  const seconds = roundedDate.getSeconds();

  // If seconds are greater than 0, round to the next minute
  if (seconds > 0) {
    roundedDate.setSeconds(0);
    roundedDate.setMinutes(roundedDate.getMinutes() + 1);
  }

  return roundedDate;
}

export function roundSecondsToNextMinute(seconds: number): number {
  return Math.ceil(seconds / 60) * 60;
}
