import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GUID } from "src/app/models";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektEntknuepfenVonGetitCommand } from "src/app/models/openapi/model/planungsobjekt-entknuepfen-von-getit-command";
import { PlanungsobjektMitGetitSynchronisierenCommand } from "src/app/models/openapi/model/planungsobjekt-mit-getit-synchronisieren-command";
import { PlanungsobjektVerknuepfenMitGetitCommand } from "src/app/models/openapi/model/planungsobjekt-verknuepfen-mit-getit-command";
import { PlanungsobjekteDto } from "src/app/models/openapi/model/planungsobjekte-dto";
import { PlanungsobjekteDtoResultDto } from "src/app/models/openapi/model/planungsobjekte-dto-result-dto";
import { WithNotificationsDto } from "src/app/models/openapi/model/with-notifications-dto";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PlanungsobjektApiService {
  private apiUrl = this.settings.apiUrl;
  private readonly basePath = "Planungsobjekt";

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getPlanungsobjektById$(planungsobjektId: string): Observable<PlanungsobjektDto> {
    return this.http.get<PlanungsobjektDto>(`${this.apiUrl}${this.basePath}/${planungsobjektId}`);
  }

  getPlanungsobjekteById$(planungsobjektId: string): Observable<PlanungsobjekteDto> {
    return this.http.get<PlanungsobjekteDto>(
      `${this.apiUrl}${this.basePath}/Planungsobjekte/${planungsobjektId}`,
    );
  }

  deletePlanungsobjekt$(planungsobjektId: GUID) {
    return this.http.delete<WithNotificationsDto>(
      `${this.apiUrl}${this.basePath}/${planungsobjektId}`,
    );
  }

  verknuepfePlanungsobjektMitGetit$(command: PlanungsobjektVerknuepfenMitGetitCommand) {
    return this.http.put<PlanungsobjekteDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.planungsobjektId}/Getit/Verknuepfen`,
      command,
    );
  }

  synchronisierePlanungsobjektMitGetit$(command: PlanungsobjektMitGetitSynchronisierenCommand) {
    return this.http.put<PlanungsobjekteDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.planungsobjektId}/Getit/Sync`,
      command,
    );
  }

  planungsobjektEntknuepfenVonGetit$(command: PlanungsobjektEntknuepfenVonGetitCommand) {
    return this.http.put<PlanungsobjekteDtoResultDto>(
      `${this.apiUrl}${this.basePath}/${command.planungsobjektId}/Getit/Entknuepfen`,
      command,
    );
  }
}
