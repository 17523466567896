import { entriesOf } from "src/app/utils/object-utils";
import { StofffuehrendeRedaktion } from "../openapi/model/stofffuehrende-redaktion";
import {
  EnumMetadata,
  enumRecordToSelectOptions,
  enumRecordToSelectOptionsPrefereShort,
  SelectOption,
} from "./enum-base";

export const StofffuehrendeRedaktionRecord = Object.freeze<
  Record<StofffuehrendeRedaktion, EnumMetadata>
>({
  C: { bedeutung: "Chefredaktion", short: "C" },
  CI: { bedeutung: "HR Politik & Zeitgeschehen", short: "CI" },
  CPM: { bedeutung: "Frontal 21", short: "CPM" },
  CS: { bedeutung: "HR Sport", short: "CS" },
  CT: { bedeutung: "HR Aktuelles", short: "CT" },
  CW: { bedeutung: "HR WIRSSUM", short: "CW" },
  CZE: { bedeutung: "PB Info, Gesellschaft & Leben", short: "CZE" },
  PK: { bedeutung: "HR Geschichte und Wissenschaft", short: "PK" },
  PKJ: { bedeutung: "HR Kinder und Jugend", short: "PKJ" },
  PKM: { bedeutung: "HR Kultur", short: "PKM" },
  PS: { bedeutung: "HR Fernsehfilm/Serie I", short: "PS" },
  PSF: { bedeutung: "HR International Fiktion", short: "PSF" },
  PUS: { bedeutung: "HR Show", short: "PUS" },
  PUW: { bedeutung: "HR Fernsehfilm/Serie II", short: "PUW" },
  NN: { bedeutung: "", short: "NN" },
});

export const StofffuehrendeRedaktionOptions = enumRecordToSelectOptions(
  StofffuehrendeRedaktionRecord,
);

export const StofffuehrendeRedaktionOptionsFiltered = enumRecordToSelectOptions(
  StofffuehrendeRedaktionRecord,
).filter((redaktion) => redaktion.value !== StofffuehrendeRedaktion.NN);

export const RedaktionShortOptions = enumRecordToSelectOptionsPrefereShort(
  StofffuehrendeRedaktionRecord,
);

export const redaktionListToOptions = (
  redaktionList: StofffuehrendeRedaktion[],
): SelectOption<StofffuehrendeRedaktion>[] => {
  const filteredEntries = entriesOf(StofffuehrendeRedaktionRecord).filter(([key]) =>
    redaktionList.includes(key),
  );

  const filteredRecord = Object.fromEntries(filteredEntries) as Record<
    StofffuehrendeRedaktion,
    EnumMetadata
  >;
  return enumRecordToSelectOptions(filteredRecord);
};
