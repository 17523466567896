import { createSelector } from "@ngrx/store";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { SendeplatzKeyDto } from "src/app/models/openapi/model/sendeplatz-key-dto";
import { KonkurrenzprogrammViewModel } from "src/app/models/viewmodels/konkurrenzprogramm-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import routerSelectors from "../router/router.selectors";
import {
  konkurrenzprogrammFeature,
  konkurrenzprogrammViewModelComparer,
} from "./konkurrenzprogramm.reducer";

const selectKonkurrenzprogrammViewModels = createSelector(
  konkurrenzprogrammFeature.selectAll,
  (konkurrenzprogramme) => {
    return konkurrenzprogramme.map((konkurrenzprogramm: KonkurrenzprogrammDto) => {
      const konkurrenzprogrammViewModel: KonkurrenzprogrammViewModel = {
        ...konkurrenzprogramm,
        zeitraum: {
          start: DateFnsService.parseDateAndTimeToDateObject(
            konkurrenzprogramm.vonZeit,
            new Date(konkurrenzprogramm.vonDatum),
          ),
          end: DateFnsService.parseDateAndTimeToDateObject(
            konkurrenzprogramm.bisZeit,
            new Date(konkurrenzprogramm.bisDatum),
          ),
        },
      };
      return konkurrenzprogrammViewModel;
    });
  },
);

const selectKonkurrenzprogrammForSendeplatz = (sendeplaetzeKeys: SendeplatzKeyDto[]) => {
  return createSelector(selectKonkurrenzprogrammViewModels, (konkurrenzprogammViewModels) => {
    const groupedKonkurrenzprogrammViewModels: KonkurrenzprogrammViewModel[] = [];
    // Wenn kein Sendeplaetze übergeben wurde oder dieser undefined ist wird ein leeres Array zurückgegeben
    if (!Array.isArray(sendeplaetzeKeys)) return groupedKonkurrenzprogrammViewModels;

    for (const sendeplatz of sendeplaetzeKeys) {
      if (!sendeplatz) continue;
      const von = DateFnsService.parseDateAndTimeToDateObject(
        sendeplatz.beginnzeit,
        new Date(sendeplatz.kalendertag),
      );
      const bis = DateFnsService.addSeconds(von, sendeplatz.laenge);
      const interval = { start: von, end: bis };
      for (const konkurrenzprogrammViewModel of konkurrenzprogammViewModels) {
        if (DateFnsService.areIntervalsOverlapping(interval, konkurrenzprogrammViewModel.zeitraum))
          groupedKonkurrenzprogrammViewModels.push(konkurrenzprogrammViewModel);
      }
    }
    // Sortiere und entferne Duplikate
    return [
      ...new Set(groupedKonkurrenzprogrammViewModels.sort(konkurrenzprogrammViewModelComparer)),
    ];
  });
};

const selectKonkurrenzprogrammeForInterval = (interval: { start: Date; end: Date }) => {
  return createSelector(selectKonkurrenzprogrammViewModels, (konkurrenzprogrammViewModels) => {
    let filteredKonkurrenzprogramme: KonkurrenzprogrammViewModel[] = [];
    if (!interval) return filteredKonkurrenzprogramme;
    filteredKonkurrenzprogramme = konkurrenzprogrammViewModels.filter((konkurrenzprogramm) => {
      return DateFnsService.areIntervalsOverlapping(interval, konkurrenzprogramm.zeitraum);
    });

    return [...new Set(filteredKonkurrenzprogramme.sort(konkurrenzprogrammViewModelComparer))];
  });
};

const selectKonkurrenzprogrammeForYear = createSelector(
  routerSelectors.selectYearFromQueryParamOrDefault,
  konkurrenzprogrammFeature.selectAll,
  (year, konkurrenzprogramme) => {
    return konkurrenzprogramme.filter(
      (konkurrenzprogramm) => new Date(konkurrenzprogramm.vonDatum).getFullYear() === year,
    );
  },
);

export default {
  selectKonkurrenzprogrammForSendeplatz,
  selectKonkurrenzprogrammeForYear,
  selectKonkurrenzprogrammeForInterval,
};
