import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { OAuthService } from "angular-oauth2-oidc";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  shareReplay,
  startWith,
  take,
  tap,
} from "rxjs";
import { PublitFrontendSettings } from "src/environments/environment";
import { getAuthCodeFlowConfig } from "../auth-config";
import { MOCK_USERNAME_KEY, MockUser } from "../authorization/authorization-mock.model";
import { AppAbilityService } from "../casl/app-ability.service";
import ansichtSelectors from "../core/stores/ansicht/ansicht.selectors";
import { initActions } from "../core/stores/init/init.actions";
import permissionsSelectors from "../core/stores/permissions/permissions.selectors";
import { CustomNotificationService } from "../shared/notifications/custom-notification.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  private readonly accountInfo$ = new BehaviorSubject<Record<string, unknown> | null>(null);

  readonly isLoggedIn$ = this.accountInfo$.pipe(
    map((account) => !!account?.samaccountname),
    startWith(false),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly notifyIsLoggedIn$ = this.isLoggedIn$
    .pipe(
      filter((isLoggedIn) => !!isLoggedIn),
      take(1),
    )
    .subscribe(() => {
      this.store.dispatch(initActions.userIsAuthenticated());
    });

  readonly permissions$ = this.store
    .select(permissionsSelectors.selectPermissionsAll)
    .pipe(shareReplay({ bufferSize: 1, refCount: false }));

  // Für die Verwendung von CASL müssen zum Zeitpunkt der Berechnung / Aktualisierung der Berechtigungen
  // alle nötigen Parameter (wie z.B. die Ansichten) bekannt sein. Wenn die Berechtigungen abgefragt werden,
  // werden keine asynchronen Aufrufe oder obserables unterstützt.
  readonly caslPermissions$ = combineLatest([
    this.store.select(permissionsSelectors.selectPermissionsAll),
    this.store.select(ansichtSelectors.selectAnsichten),
    // TOOD RUR/RR2: Wir könnten hier auch die jeweils aktuelle Ansicht über den Router auslesen
  ]).pipe(
    tap(([permissions, ansichten]) => {
      if (permissions) {
        this.caslAbilityService.update(permissions, ansichten);
      }
    }),
  );

  constructor(
    private store: Store,
    private caslAbilityService: AppAbilityService,
    private oauthService: OAuthService,
    private settings: PublitFrontendSettings,
    private notificationService: CustomNotificationService,
    private router: Router,
  ) {}

  login() {
    if (this.settings.auth.disabled) {
      this.accountInfo$.next(getAccountInfo());
    } else {
      // Wir nutzen hier das Window, da der Angular Router zu diesem Zeitpunkt noch keine gültige URL liefert.
      const urlToRedirectTo = window.location.pathname + window.location.search;

      const authCodeFlowConfig = getAuthCodeFlowConfig(this.settings.auth);
      this.oauthService.configure(authCodeFlowConfig);
      this.oauthService
        .loadDiscoveryDocumentAndLogin({
          state: urlToRedirectTo,
        })
        .then((_) => {
          if (!this.oauthService.hasValidAccessToken()) {
            this.oauthService.initCodeFlow(urlToRedirectTo);
          }
          this.accountInfo$.next(this.oauthService.getIdentityClaims());
          this.oauthService.setupAutomaticSilentRefresh();

          // Nachdem wir eingeloggt sind, leiten wir auf die ursprüngliche Seite zurück
          if (
            this.oauthService.state &&
            this.oauthService.state !== "undefined" &&
            this.oauthService.state !== "null"
          ) {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith("/") === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            void this.router.navigateByUrl(stateUrl);
          }
        })
        .catch((error) => {
          this.notificationService.showErrorNotification(
            "Fehler beim Login, bitte via F5 die Seite neu laden.",
          );
        });
    }
  }

  logout() {
    this.oauthService.logOut();
  }
}

const getUserName = () => {
  const defaultUsername = MockUser.Bob;
  let username = localStorage.getItem(MOCK_USERNAME_KEY) as MockUser;
  if (!username) {
    console.warn(
      `${MOCK_USERNAME_KEY} in local Storage not set. Setting default user ${defaultUsername}.`,
    );
    username = "Bob";
    localStorage.setItem(MOCK_USERNAME_KEY, username);
  } else if (!Object.values(MockUser).includes(username)) {
    console.warn(`Mock user ${username} is not valid. Using default user ${defaultUsername}.`);
    username = "Bob";
  }
  return username;
};

export const getAccountInfo = (): AccountInfo => {
  return {
    environment: "MOCK",
    homeAccountId: "MOCK_HOME_ACCOUNT_ID",
    localAccountId: "MOCK_LOCAL_ACCOUNT_ID",
    tenantId: "MOCK_TENANT_ID",
    samaccountname: getUserName(),
    idTokenClaims: {},
  };
};

type AccountInfo = {
  environment: string;
  homeAccountId: string;
  localAccountId: string;
  tenantId: string;
  samaccountname: MockUser;
  idTokenClaims: Record<string, unknown>;
};
