import { CdkDrag, CdkDropList } from "@angular/cdk/drag-drop";
import { createSelector } from "@ngrx/store";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import mehrfachauswahlSelectors from "../mehrfachauswahl/mehrfachauswahl.selectors";
import routerSelectors from "../router/router.selectors";
import { sendeplatzFeature } from "./sendeplatz.reducer";

const selectAllSendeplaetzeGroupedByYear = createSelector(
  routerSelectors.selectKanalQueryParam,
  sendeplatzFeature.selectAll,
  (kanal, sendeplaetze): Map<number, SendeplatzDto[]> => {
    sendeplaetze = sendeplaetze.filter((sendeplatz) => sendeplatz.kanal === kanal);
    return groupByToMap(sendeplaetze, (sendeplatz: SendeplatzDto) =>
      new Date(sendeplatz.sendetag).getFullYear(),
    );
  },
);

const selectSendeplatzBySendeplatzKey = (entitySendeplatzKey: string) => {
  return createSelector(
    sendeplatzFeature.selectEntities,
    (sendeplaetze): SendeplatzDto => sendeplaetze[entitySendeplatzKey],
  );
};

const selectCanDropPlanungsobjektInVorgeplantSpaltePredicate = (
  canDropPlanungsobjektOnSendeplatz: boolean,
) => {
  return createSelector(
    mehrfachauswahlSelectors.selectIsDraggingMultiple,
    (
      isDraggingMultiple,
    ): ((drag: CdkDrag<PlanungsobjektDto>, drop: CdkDropList<SendeplatzDto>) => boolean) => {
      return (drag, drop) => {
        // Mehrfachauswahl beim Verschieben ist nur für vorgeplante Planungsobjekte erlaubt,
        // die innerhalb des gleichen Sendeplatzes verschoben werden
        if (drag.data.planungskontext !== Planungskontext.VORGEMERKT) {
          const fromSendeplatzKey = SendeplatzUtils.getSendeplatzKeyFromPlanungsobjekt(drag.data);
          const toSendeplatzKey = SendeplatzUtils.getSendeplatzKey(drop.data);
          const isSameSendeplatz = SendeplatzUtils.isSameSendeplatzByKeys(
            fromSendeplatzKey,
            toSendeplatzKey,
          );
          return !isDraggingMultiple || (isSameSendeplatz && canDropPlanungsobjektOnSendeplatz);
        }

        // TODO RUR: Wenn ich nur ein Item verschiebe, darf ich das also immer?
        return !isDraggingMultiple || canDropPlanungsobjektOnSendeplatz;
      };
    },
  );
};

const selectCanDropPlanungsobjektInVorschlagspaltePredicate = createSelector(
  mehrfachauswahlSelectors.selectIsDraggingMultiple,
  sendeplatzFeature.selectEntities,
  (
    isDraggingMultiple,
    sendeplaetze,
  ): ((drag: CdkDrag<PlanungsobjektDto>, drop: CdkDropList<SendeplatzDto>) => boolean) => {
    return (drag, drop) => {
      if (drag.data.planungskontext !== Planungskontext.VORGEMERKT) {
        const oldSendeplatzKey = SendeplatzUtils.createSendeplatzKey(
          SendeplatzUtils.getSendeplatzKeyFromPlanungsobjekt(drag.data),
        );
        const oldSendeplatz = sendeplaetze[oldSendeplatzKey];
        return !isDraggingMultiple || SendeplatzUtils.isSameSendeplatz(oldSendeplatz, drop.data);
      }
      return !isDraggingMultiple;
    };
  },
);

const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
  array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());

export default {
  selectAllSendeplaetzeGroupedByYear,
  selectSendeplatzBySendeplatzKey,
  selectCanDropPlanungsobjektInVorschlagspaltePredicate,
  selectCanDropPlanungsobjektInVorgeplantSpaltePredicate,
};
