import { Injectable } from "@angular/core";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { PlanungsobjektDto } from "../models/openapi/model/planungsobjekt-dto";
import { SendeplatzKeyDto } from "../models/openapi/model/sendeplatz-key-dto";
import { SendeplatzDroplistData } from "../models/viewmodels/sendeplatz-viewmodel";
import { DateFnsService } from "../services/date-fns.service";
import { expectToBeDefined } from "./assert-utils";
import { isDefined } from "./object-utils";
import { PlanungsobjektUtils } from "./planungsobjekt.utils";

@Injectable({
  providedIn: "root",
})
export class SendeplatzUtils {
  static createSendeplatzKey = (
    s: Pick<SendeplatzDto, "kanal" | "kalendertag" | "beginnzeit" | "laenge"> | SendeplatzKeyDto,
  ): string => [s.kanal, s.kalendertag, s.beginnzeit, s.laenge].join("-");

  static getSendeplatzKey(
    sendeplatz: SendeplatzDto | undefined | null,
  ): SendeplatzKeyDto | undefined {
    if (!isDefined(sendeplatz)) {
      return undefined;
    }

    return {
      kanal: sendeplatz.kanal,
      sendetag: sendeplatz.sendetag,
      kalendertag: sendeplatz.kalendertag,
      beginnzeit: sendeplatz.beginnzeit,
      laenge: sendeplatz.laenge,
    };
  }

  static getSendeplatzKeys(von: SendeplatzDto[]): SendeplatzKeyDto[] {
    if (!Array.isArray(von)) {
      return [];
    }

    return von
      .map(SendeplatzUtils.getSendeplatzKey)
      .filter((sendeplatzKey): sendeplatzKey is SendeplatzKeyDto => !!sendeplatzKey);
  }

  static getSendeplatzKeyFromPlanungsobjekt(
    planungsobjekt: PlanungsobjektDto,
  ): SendeplatzKeyDto | undefined {
    if (!planungsobjekt) {
      return undefined;
    }

    if (PlanungsobjektUtils.isOnMerkliste(planungsobjekt)) {
      return undefined;
    }

    expectToBeDefined(planungsobjekt.publikationsplanung);
    expectToBeDefined(planungsobjekt.publikationsplanung.sendeplatzBeginnzeit);
    expectToBeDefined(planungsobjekt.publikationsplanung.sendeplatzLaenge);

    return {
      kanal: planungsobjekt.publikationsplanung.kanal,
      sendetag: planungsobjekt.publikationsplanung.sendetag,
      kalendertag: planungsobjekt.publikationsplanung.kalendertag,
      beginnzeit: planungsobjekt.publikationsplanung.sendeplatzBeginnzeit,
      laenge: planungsobjekt.publikationsplanung.sendeplatzLaenge,
    };
  }

  static isSameSendeplatz(von: SendeplatzDto, zu: SendeplatzDto): boolean {
    return (
      (von &&
        zu &&
        von.kanal === zu.kanal &&
        DateFnsService.parseStringToDate(von.kalendertag).getDay() ===
          DateFnsService.parseStringToDate(zu.kalendertag).getDay() &&
        von.beginnzeit === zu.beginnzeit &&
        von.laenge === zu.laenge) ||
      !von ||
      !zu
    );
  }

  /**
   * Achtung, hier wird nur der Wochentag verglichen, nicht das Datum.
   * Es wird verglichen, ob die beiden Sendeplätze dem selben Schemaplatz entsprechen.
   */
  static isSameSendeplatzByKeys(von: SendeplatzKeyDto, zu: SendeplatzKeyDto): boolean {
    return (
      (von &&
        zu &&
        von.kanal === zu.kanal &&
        DateFnsService.parseStringToDate(von.kalendertag).getDay() ===
          DateFnsService.parseStringToDate(zu.kalendertag).getDay() &&
        von.beginnzeit === zu.beginnzeit &&
        von.laenge === zu.laenge) ||
      !von ||
      !zu
    );
  }

  static isSameSendeplatzKey(von: SendeplatzKeyDto, zu: SendeplatzKeyDto): boolean {
    return (
      (!von && !zu) ||
      (von &&
        zu &&
        von.kanal === zu.kanal &&
        von.kalendertag === zu.kalendertag &&
        von.beginnzeit === zu.beginnzeit &&
        von.laenge === zu.laenge)
    );
  }

  static isDraggedFromSendeplatzNotMerkliste(
    previousContainer: SendeplatzDroplistData,
  ): SendeplatzDto | undefined {
    const isSendeplatzAndNotMerkliste = (
      planungsobjekt: SendeplatzDroplistData,
    ): planungsobjekt is SendeplatzDto => "sendetag" in planungsobjekt;
    return isSendeplatzAndNotMerkliste(previousContainer) ? previousContainer : undefined;
  }

  static getLatestPlanungsobjekt(sendeplatz: SendeplatzDto) {
    if (sendeplatz.planungsobjekte.length == 0 && sendeplatz.vorschlaege.length > 0) {
      return sendeplatz.vorschlaege.sort(PlanungsobjektUtils.sortPlanungsobjektAscByCreatedAt)[0];
    }

    if (sendeplatz.planungsobjekte.length > 0 && sendeplatz.vorschlaege.length == 0) {
      return sendeplatz.planungsobjekte.sort(
        PlanungsobjektUtils.sortPlanungsobjektAscByCreatedAt,
      )[0];
    }

    const latestPlanungsobjekt = sendeplatz.planungsobjekte.sort(
      PlanungsobjektUtils.sortPlanungsobjektAscByCreatedAt,
    )[0];
    const latestVorschlag = sendeplatz.vorschlaege.sort(
      PlanungsobjektUtils.sortPlanungsobjektAscByCreatedAt,
    )[0];

    return latestPlanungsobjekt.createdAt > latestVorschlag.createdAt
      ? latestPlanungsobjekt
      : latestVorschlag;
  }
}
