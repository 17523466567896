import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { map } from "rxjs";
import { Icons } from "src/app/models/icons";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { SendeplatzViewModel } from "src/app/models/viewmodels/sendeplatz-viewmodel";
import KalenderansichtFacade from "../kalenderansicht.facade";

@Component({
  selector: "app-kalenderansicht-sendeplatz-monat",
  templateUrl: "./kalenderansicht-sendeplatz-monat.component.html",
  styleUrl: "./kalenderansicht-sendeplatz-monat.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KalenderansichtSendeplatzMonatComponent {
  public readonly facade = inject(KalenderansichtFacade);

  public headerLength$ = this.facade.kalenderAnsichtHeader$.pipe(map((header) => header.length));

  @Input({ required: true }) sendeplaetzeMonth: SendeplatzViewModel[][];
  @Input({ required: true }) sendeplaetzeYearKey: string;
  @Input({ required: true }) monthIndex: number;
  @Input({ required: true }) firstMonth: boolean;
  @Input({ required: true }) firstYear: boolean;

  @Input({ required: true }) ansichtViewModel: AnsichtViewModel;

  Icons = Icons;
}
