import { createFeature, createReducer, on } from "@ngrx/store";
import { VersionInformation } from "src/app/models/openapi/model/version-information";
import { FeatureKey } from "../feature.keys";
import metadatenActions from "./metadaten.actions";

export type VersionInformationState = VersionInformation;

const initialState: VersionInformationState = {
  version: "wird geladen...",
  commitsAhead: 0,
  latestSHA: "",
};

export const metadatenFeature = createFeature({
  name: FeatureKey.Metadaten,
  reducer: createReducer(
    initialState,
    on(
      metadatenActions.loadVersionInformationSuccess,
      (state, payload): VersionInformationState => payload.versionInformation,
    ),
    on(metadatenActions.loadVersionInformationFailure, (): VersionInformationState => initialState),
  ),
});
