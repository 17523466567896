import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { routerNavigationAction } from "@ngrx/router-store";
import { map } from "rxjs";
import { listenansichtActions } from "./listenansicht.actions";

@Injectable()
export class ListenansichtEffects {
  private readonly actions$ = inject(Actions);

  /**
   * setze zurück welche Spalten-Header aktiv sind
   */
  resetActiveHeaderState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      map(() => listenansichtActions.resetActiveHeaders()),
    );
  });
}
