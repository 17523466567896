import { createSelector } from "@ngrx/store";
import { PlanungsobjektWindowOnDemandForm } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.model";
import { planungsobjektWindowFeature } from "src/app/core/stores/planungsobjekt-window/planungsobjekt-window.reducer";

/**
 * Selektor, der abhängig von den Toggles im OnDemand Beziehung Formular die relationalen
 * oder die absoluten Werte zurückgibt und die anderen Werte auf `null` setzt.
 */
const selectOnDemandFormMitRelationalOderAbsolutWerten = createSelector(
  planungsobjektWindowFeature.selectOnDemandFormValue,
  planungsobjektWindowFeature.selectOnDemandToggleFormValue,
  (onDemandFormValue, onDemandToggleFormValue): PlanungsobjektWindowOnDemandForm | undefined => {
    if (!onDemandFormValue || !onDemandToggleFormValue) {
      return undefined;
    }
    const { wunschOnlineAbVsRelationalZuLinearToggle, wunschOnlineBisVsVerweildauerToggle } =
      onDemandToggleFormValue;
    const {
      onlineAbZeit,
      wunschOnlineAb,
      wunschOnlineBis,
      reihenfolgeHerstellen,
      relationZuLinearInTagen,
      verweildauerInTagen,
      minDistanz,
      maxDistanz,
    } = onDemandFormValue;

    return {
      onlineAbZeit,
      wunschOnlineAb: wunschOnlineAbVsRelationalZuLinearToggle ? null : wunschOnlineAb,
      relationZuLinearInTagen: wunschOnlineAbVsRelationalZuLinearToggle
        ? relationZuLinearInTagen
        : null,
      wunschOnlineBis: wunschOnlineBisVsVerweildauerToggle ? null : wunschOnlineBis,
      verweildauerInTagen: wunschOnlineBisVsVerweildauerToggle ? verweildauerInTagen : null,
      reihenfolgeHerstellen,
      minDistanz: minDistanz === null ? relationZuLinearInTagen : minDistanz,
      maxDistanz: maxDistanz === null ? relationZuLinearInTagen : maxDistanz,
    };
  },
);

export default {
  selectOnDemandFormMitRelationalOderAbsolutWerten,
};
