import { AfterContentInit, Component, ContentChild, inject, Input } from "@angular/core";
import { AbstractControl, FormControlDirective, FormControlName, Validators } from "@angular/forms";
import { LabelDirective } from "@progress/kendo-angular-label";
import { BehaviorSubject, filter, map, merge, startWith, switchMap } from "rxjs";
import { isDefined } from "src/app/utils/object-utils";

/**
 * Komponente, die ein Label für ein Formularfeld rendert und zusätzlich
 * einen "*" anzeigt, wenn das Feld ein Pflichtfeld ist.
 */
@Component({
  selector: "app-input-label",
  templateUrl: "./input-label.component.html",
  styleUrls: ["./input-label.component.scss"],
})
export class InputLabelComponent implements AfterContentInit {
  /**
   * Der Labeltext.
   */
  @Input() text = "";

  /**
   * Wenn true, wird ein Ladeindikator angezeigt.
   * Kann z.B. verwendet werden, wenn das Label bei einem Dropdown verwendet wird,
   * dessen Optionen asynchron geladen werden.
   */
  @Input() loading = false;

  /**
   * Workaround, weil verschachtelte ng-content nicht gefunden werden.
   * Wird benötigt, um in {@link }
   * @see https://github.com/angular/angular/issues/16299
   */
  @Input() set control(value: AbstractControl | null) {
    if (value) {
      this.controlSubject.next(value);
    }
  }

  /**
   * Das Feld, für das das Label ist. Sollte im Idealfall eine Referenz auf ein Element
   * sein, das im Template via #variable definiert wurde.
   */
  @Input() for?: LabelDirective["for"];

  /**
   * Falls im ng-content ein Element die FormControl Direktive hat, wird diese hier gespeichert.
   */
  @ContentChild(FormControlDirective) set formControl(value: FormControlDirective) {
    if (value) {
      this.controlSubject.next(value.control);
    }
  }

  /**
   * Falls im ng-content ein Element die FormControlName Direktive hat, wird diese hier gespeichert.
   */
  @ContentChild(FormControlName) set formControlName(value: FormControlName) {
    if (value) {
      this.controlSubject.next(value.control);
    }
  }

  /**
   * Falls die InputLabelComponent z.B. in einem ControlValueAccessor verwendet wird, kann
   * über @ContentChild kein Zugriff auf die FormControl möglich sein. In diesem Fall kann
   * die FormControl über die inject Funktion - also von außen - injected werden.
   *
   * @example
   * ```html
   *
   * <app-select formControlName="redaktion" label="Redaktion"></app-select> <!-- FormControlName wird hier verwendet -->
   *
   * <!-- in AppSelectComponent: -->
   * <app-input-label class="u-w-full" [for]="dropdownList" [text]="label"> <!-- hier wird Label verwendet, was sich dann FormControlName von außen holt -->
   *   <kendo-dropdownlist #dropdownList [...]></kendo-dropdownlist>
   * </app-input-label>
   *
   * ```
   */
  readonly injectedFormControlName = inject(FormControlName, { optional: true });
  /** siehe {@link injectedFormControlName} */
  readonly injectedFormControlDirective = inject(FormControlDirective, { optional: true });

  private readonly controlSubject = new BehaviorSubject<AbstractControl | null>(null);

  /**
   * Observable, das das Control enthält, sobald es gesetzt wurde.
   */
  protected readonly control$ = this.controlSubject.asObservable().pipe(filter(isDefined));

  /**
   * Observable, das bei jeder Änderung des Controls ein neues Event mit dem Control ausgibt.
   */
  private readonly controlChanged$ = this.control$.pipe(
    switchMap((control) =>
      merge(control.statusChanges, control.valueChanges).pipe(
        map(() => control),
        startWith(control),
      ),
    ),
  );

  /**
   * Ob das Feld ein Pflichtfeld ist.
   */
  readonly isRequired$ = this.controlChanged$.pipe(
    map((control) => control.hasValidator(Validators.required)),
  );

  /**
   * Ob das Feld invalide ist.
   */
  readonly isInvalid$ = this.control$.pipe(
    switchMap((control) => control.valueChanges.pipe(map(() => control.status))),
    map((status) => status === "INVALID"),
  );

  ngAfterContentInit(): void {
    if (this.injectedFormControlName) {
      const control = this.injectedFormControlName.control;
      this.controlSubject.next(control);
    }
    if (this.injectedFormControlDirective) {
      const control = this.injectedFormControlDirective.control;
      this.controlSubject.next(control);
    }
  }
}
