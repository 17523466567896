import { Component, Input } from "@angular/core";

@Component({
  selector: "app-ansicht-divider, [app-ansicht-divider]",
  templateUrl: "./ansicht-divider.component.html",
  styleUrls: ["./ansicht-divider.component.scss"],
})
export class AnsichtDividerComponent {
  @Input() year: number;
  @Input() ansichtHeaderLength: number;
  @Input() showNotizen: boolean;
  @Input() topOffsetForSticky = 20;
}
