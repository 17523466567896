import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { SendeplatzViewModel } from "src/app/models/viewmodels/sendeplatz-viewmodel";

@Component({
  selector: "app-kalenderansicht-sendeplatz-woche",
  templateUrl: "./kalenderansicht-sendeplatz-woche.component.html",
  styleUrl: "./kalenderansicht-sendeplatz-woche.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KalenderansichtSendeplatzWocheComponent {
  @Input({ required: true }) sendeplatzWeek: SendeplatzViewModel[];
  @Input({ required: true }) ansichtViewModel: AnsichtViewModel;
}
