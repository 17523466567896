import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { EditService } from "@progress/kendo-angular-grid/editing-directives/edit-service.interface";
import { planungshinweisActions } from "src/app/core/stores/planunshinweis/planungshinweis.actions";
import { CreatePlanungshinweisCommand } from "src/app/models/openapi/model/create-planungshinweis-command";
import { PlanungshinweisDto } from "src/app/models/openapi/model/planungshinweis-dto";
import { UpdatePlanungshinweisCommand } from "src/app/models/openapi/model/update-planungshinweis-command";

@Injectable({
  providedIn: "root",
})
/**
 * Wird von Kendo Grid für inline Bearbeitung verwendetet.
 */
export class PlanungshinweisEditService implements EditService {
  constructor(private store: Store) {}

  create(item: PlanungshinweisDto): void {
    // console.log("create", item);

    const command: CreatePlanungshinweisCommand = {
      von: item.von,
      bis: item.bis,
      status: item.status,
      kanal: item.kanal,
      notiz: item.notiz,
    };

    this.store.dispatch(planungshinweisActions.createPlanungshinweis({ command }));
  }

  /**
   * Geht davon aus, dass in assignValues die Werte bereits aktualisiert wurden.
   * Falls nicht, bekommen wir in item die alten Werte (vor dem Editieren).
   * Da das nicht ganz mit unserem NgRx Store zusammenpasst, verwenden wir
   * assignValues() für Updates von vorhanden Einträgen.
   */
  update(_: PlanungshinweisDto): void {
    // console.log("update", _);
  }

  remove(item: PlanungshinweisDto): void {
    // console.log("remove", item);

    this.store.dispatch(planungshinweisActions.deletePlanungshinweis({ id: item.id }));
  }

  /**
   * @param target - Enthält die alten Werte, vor dem Bearbeiten.
   * @param source - Enthält die neuen Werte, nach dem Bearbeiten.
   * In source werden jedoch nur die Werte übernommen die auch in der Form Group enthalten sind (siehe unten).
   */
  assignValues(target: PlanungshinweisDto, source: PlanungshinweisDto): void {
    // console.log("assignValues", target, source);

    // Diese Methode wird auch für neuen Zeilen aufgerufen, welche neu hinzugefügt werden,
    // neue Planungshinweise haben werden aber in create() verarbeitet.
    if (target.id === undefined) {
      return;
    }

    const command: UpdatePlanungshinweisCommand = {
      // ID und Kanal sind nicht in der Form Group enthalten,
      // daher müssen wir diese Werte aus dem ursprünglichen Objekt übernehmen.
      id: target.id,
      kanal: target.kanal,
      // ----------------
      von: source.von,
      bis: source.bis,
      status: source.status,
      notiz: source.notiz,
    };

    this.store.dispatch(planungshinweisActions.updatePlanungshinweis({ command }));
  }
}
