import { Injectable } from "@angular/core";
import { DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { LoadingDialogComponent } from "../shared/dialogs/loading-dialog/loading-dialog.component";

@Injectable({
  providedIn: "root",
})
export class LoadingDialogService {
  private _pendingRequestCount = 0;
  private manualTrackedRequests: symbol[] = [];
  private dialogRef: DialogRef;

  constructor(private dialogService: DialogService) {}

  addPendingRequest() {
    if (this.pendingRequestCount === 0) this.dialogRef = this.openLoadingDialog();
    this._pendingRequestCount++;
  }

  removePendingRequest() {
    this._pendingRequestCount--;
    if (this.pendingRequestCount === 0) this.dialogRef.close();
  }

  /**
   * Wird beim Start von Requests aufgerufen
   * @param requestSymbol Id der Requests
   */
  startTrackManualRequest(requestSymbol: symbol) {
    if (this.pendingRequestCount === 0) this.dialogRef = this.openLoadingDialog();
    this.manualTrackedRequests.push(requestSymbol);
  }

  /**
   * Wird aufgerufen, wenn die Requests durchgelaufen sind. Beispielsweise im `finalize()`
   * @param requestSymbol Id der Requests
   */
  stopTrackManualRequest(requestSymbol: symbol) {
    this.manualTrackedRequests = this.manualTrackedRequests.filter(
      (symbol) => symbol !== requestSymbol,
    );
    if (this.pendingRequestCount === 0) this.dialogRef.close();
  }

  private get pendingRequestCount() {
    return this.manualTrackedRequests.length + this._pendingRequestCount;
  }

  private openLoadingDialog(): DialogRef {
    return this.dialogService.open({
      content: LoadingDialogComponent,
    });
  }
}
