import { createSelector } from "@ngrx/store";
import { dragDropFeature } from "./dragdrop.reducer";

const selectHighlightSendeplatz = (sendetag: string) =>
  createSelector(
    dragDropFeature.selectHoveredSendeplatz,
    (hoveredSendeplatz) => hoveredSendeplatz === sendetag,
  );

export default {
  selectHighlightSendeplatz,
};
