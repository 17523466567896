import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction,
  ViewChild,
  computed,
  input,
  signal,
} from "@angular/core";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import {
  CellClickEvent,
  ColumnComponent,
  ColumnReorderEvent,
  ColumnResizeArgs,
  ColumnVisibilityChangeEvent,
  DetailCollapseEvent,
  DetailExpandEvent,
  GridComponent,
  GridDataResult,
  PageChangeEvent,
  RowArgs,
} from "@progress/kendo-angular-grid";
import { ContextMenuComponent, ContextMenuSelectEvent } from "@progress/kendo-angular-menu";
import { SortDescriptor, State } from "@progress/kendo-data-query";
import {
  ActiveGridColumnProperties,
  DEFAULT_COLUMN_WIDTH,
  GRID_ROW_HEIGHT,
  GridResultColumnFields,
  MIN_RESIZABLE_COLUMN_WIDTH,
  RechercheGridResultColumn,
  RechercheSearchResultVM,
  ResizedColumn,
} from "src/app/core/stores/recherche/recherche.model";
import { FSKEinstufungRecord } from "src/app/models/enums/fsk-einstufung";
import { GenreRecord } from "src/app/models/enums/genre";
import { PlanungskontextRecord } from "src/app/models/enums/planungskontext";
import { ProduktStatusRecord } from "src/app/models/enums/produktstatus";
import { RedaktionRecord } from "src/app/models/enums/redaktion";
import { StofffuehrendeRedaktionRecord } from "src/app/models/enums/stofffuehrende-redaktion";
import { Icons } from "src/app/models/icons";
import { RechercheMapper } from "src/app/models/mapper/recherche.mapper";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { planungsobjektFarbgebungMap } from "src/app/models/viewmodels";
import { ColorMapToReturnValue } from "src/app/shared/pipes/color-map-to-ng-style/color-map-to-ng-style.pipe";
import { applySortingForKendoGrid } from "src/app/utils/sort-utils";

@Component({
  selector: "app-search-results-grid",
  templateUrl: "./search-results-grid.component.html",
  styleUrls: ["./search-results-grid.component.scss"],
})
export class SearchResultsGridComponent implements AfterViewInit {
  // Wandelt das Input in ein WritableSignal
  searchResults = input.required<RechercheSearchResultVM[]>();
  @Input() activeRechercheColumns: Partial<
    Record<RechercheGridResultColumn, ActiveGridColumnProperties>
  >;
  @Input({ required: true }) expandedIds: string[];
  @Input({ required: true }) rechercheShownGridColumnsInOrder: ActiveGridColumnProperties[];
  @Input({ required: true }) kanaeleSelected: Kanal[];
  @Output() resultSelected = new EventEmitter<RechercheSearchResultVM>();
  @Output() loadChildren = new EventEmitter<{ childrenIds: string[]; parentId: string }>();
  @Output() loadAllChildren = new EventEmitter<{ childrenIds: string[]; parentIds: string[] }>();
  @Output() collapseParent = new EventEmitter<string>();
  @Output() collapseAllParents = new EventEmitter<string[]>();
  @Output() columnResize = new EventEmitter<ResizedColumn[]>();
  @Output() toggleGridColumn = new EventEmitter<RechercheGridResultColumn[]>();
  @Output() reorderColumn = new EventEmitter<{ oldIndex: number; newIndex: number }>();

  gridStateSignal = signal<State>({
    skip: 0,
    take: 60,
    sort: [],
  });

  minColumnWidth = MIN_RESIZABLE_COLUMN_WIDTH;
  defaultColumnWidth = DEFAULT_COLUMN_WIDTH;

  gridDataSignal = computed<GridDataResult>(() => {
    const searchResults = this.searchResults();
    const gridState = this.gridStateSignal();

    if (!searchResults || !gridState) {
      return { data: [], total: 0 };
    }

    // Sortierlogik anwenden
    const sortedData = applySortingForKendoGrid(searchResults, gridState);
    return {
      data: sortedData.slice(gridState.skip ?? 0, (gridState.skip ?? 0) + (gridState.take ?? 0)),
      total: searchResults.length,
    };
  });

  ngAfterViewInit() {
    const columns = this.grid.columns.filter(
      (column): column is ColumnComponent => column instanceof ColumnComponent && column.isVisible,
    );

    // Setze die Breite der Spalten gemäßg implzit durch das Grid festgelegter Breite
    this.columnResize.emit(
      columns.map((column) => ({
        field: column.field as RechercheGridResultColumn,
        width: column.field === GridResultColumnFields.KANAL ? 40 : column.implicitWidth,
      })),
    );
  }

  readonly RedaktionRecord = RedaktionRecord;
  readonly PlanungskontextRecord = PlanungskontextRecord;
  readonly GenreRecord = GenreRecord;
  readonly Icons = Icons;
  readonly ColorMapToReturnValue = ColorMapToReturnValue;
  readonly Planungskontext = Planungskontext;
  readonly planungsobjektFarbgebungMap = planungsobjektFarbgebungMap;
  readonly GridResultColumnFields = GridResultColumnFields;
  readonly ProduktStatusRecord = ProduktStatusRecord;
  readonly StofffuehrendeRedaktionRecord = StofffuehrendeRedaktionRecord;
  readonly FSKEinstufungRecord = FSKEinstufungRecord;

  selectedRow: RechercheSearchResultVM | null;
  gridRowHeight = GRID_ROW_HEIGHT;

  @ViewChild("grid") grid: GridComponent;

  @ViewChild("gridContextMenu")
  public gridContextMenu: ContextMenuComponent;

  trackByField: TrackByFunction<ActiveGridColumnProperties> = (_, item) => item.field;

  isExpanded({ dataItem }: RowArgs): boolean {
    const searchResult = dataItem as RechercheSearchResultVM;
    return searchResult.isExpanded;
  }
  /**
   * Kendo bietet nur ein Click Event an, in welchem wir Informationen über die aktuell ausgewählte Zeile erhalten.
   * Diese muss kurz zwischengespeichert und im eigentlich DoubleClick/ContextMenuSelect-Event ausgewertet werden.
   * https://www.telerik.com/forums/kendo-grid-row-double-single-click-event
   */
  onRowClick(event: CellClickEvent) {
    if (event.type === "click" || event.type == "contextmenu") {
      this.selectedRow = event.dataItem as RechercheSearchResultVM;
    }

    // Basierend auf:
    // https://www.telerik.com/kendo-angular-ui/components/menus/contextmenu/data-bound-components/#toc-grid
    if (event.type === "contextmenu") {
      const originalEvent = event.originalEvent as PointerEvent;
      originalEvent.preventDefault();
      this.gridContextMenu.show({
        left: originalEvent.pageX,
        top: originalEvent.pageY,
      });
    }
  }

  onRowDoubleClick() {
    this.showDetails();
  }

  onContexMenuSelect(_: ContextMenuSelectEvent) {
    // Da wir aktuell nur eine Aktion im Kontext Menü anbieten ist eine Unterscheidung aktuell nicht nötig
    this.showDetails();
  }

  onPageChange(event: PageChangeEvent): void {
    this.gridStateSignal.set(event);
  }

  onColumnReorder($event: ColumnReorderEvent) {
    this.reorderColumn.emit({ oldIndex: $event.oldIndex, newIndex: $event.newIndex });
  }

  onColumnResize(event: ColumnResizeArgs[]) {
    const columns: ResizedColumn[] = event
      .map((resizeArgs) => resizeArgs.column)
      .filter((column): column is ColumnComponent => column instanceof ColumnComponent)
      .map((column) => ({
        field: column.field as RechercheGridResultColumn,
        width: column.width,
      }));

    this.columnResize.emit(columns);
  }

  // Event-Handler für Sortierereignisse
  onSortChange(sort: SortDescriptor[]): void {
    if (sort && sort.length > 0) {
      // Die Sortierrichtung ist wie ein Toggle implementiert: Klick auf einen Spaltenkopf wechselt zwischen "asc" und "desc"
      const sortField = sort[0].field;
      const currentSort = this.gridStateSignal().sort?.find((sort) => sort.field === sortField);
      if (currentSort) {
        // Wechsel der Sortierrichtung
        sort[0].dir = currentSort.dir === "asc" ? "desc" : "asc";
      }
      this.gridStateSignal.update((state) => ({ ...state, sort }));
    }
  }

  onToggleAllDetails() {
    const parents = this.searchResults().filter((result) => this.hasChildren(result));
    const parentIds = parents.map((result) => result.id);

    const allExpanded = parents.every((result) => result.isExpanded);
    if (allExpanded) {
      this.collapseAllParents.emit(parentIds);
    } else {
      const childrenIds = parents.flatMap((result) => result.childrenIds);
      this.loadAllChildren.emit({ childrenIds, parentIds });
    }
  }

  onDetailExpand(event: DetailExpandEvent) {
    if (event.dataItem) {
      const columns = this.rechercheShownGridColumnsInOrder.map((column) => column.field);
      const searchResult = event.dataItem as RechercheSearchResultVM;
      // if (!searchResult.onlineAb) { -> Warum wurde hier geprüft, ob onlineAb gesetzt ist, onDemand/linear können beide Parent sein
      this.loadChildren.emit({
        childrenIds: searchResult.childrenIds,
        parentId: searchResult.id,
      });
      // }
    }
  }

  onDetailCollapse(event: DetailCollapseEvent) {
    if (event.dataItem) {
      const searchResult = event.dataItem as RechercheSearchResultVM;
      // if (!searchResult.onlineAb) { -> Warum wurde hier geprüft, ob onlineAb gesetzt ist, onDemand/linear können beide Parent sein
      this.collapseParent.emit(searchResult.id);
      // }
    }
  }

  private showDetails() {
    if (this.selectedRow) {
      this.resultSelected.emit(this.selectedRow);
      this.selectedRow = null;
    }
  }

  protected hasChildren(event: RechercheSearchResultVM) {
    return event.childrenIds.length > 0;
  }

  protected onChildDoubleClicked(event: RechercheSearchResultVM) {
    this.resultSelected.emit(event);
  }

  onToggleGridColumn(event: ColumnVisibilityChangeEvent) {
    const columns = event.columns.filter(
      (column): column is ColumnComponent => column instanceof ColumnComponent,
    );
    this.toggleGridColumn.emit(columns.map((column) => column.field as RechercheGridResultColumn));
    // Eventuell für ein Austogglen nicht notwendig, können wir aber hier nicht unterscheiden
    this.columnResize.emit(
      columns.map((column) => ({
        field: column.field as RechercheGridResultColumn,
        width: this.defaultColumnWidth,
      })),
    );
  }

  /// Hier handelt es sich explizit um eine Arrow-Function, da sonst der Kontext von "this" verloren geht
  filteredData = (): ExcelExportData => {
    const mappedSearchResults = RechercheMapper.mapRechercheSearchResultVM(
      RechercheMapper.flattenSearchResults(this.searchResults()),
    );
    const result: ExcelExportData = {
      data: mappedSearchResults,
    };
    return result;
  };
}
