import { Pipe, PipeTransform } from "@angular/core";
import {
  ListenansichtColumnHeader,
  ListenansichtHeaderEnum,
  createColumnId,
} from "src/app/core/stores/listenansicht/listenansicht.reducer";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzDummy } from "src/app/models/viewmodels/sendeplatz-viewmodel";
import { toWochentag } from "src/app/utils/date-utils";

/**
 * Evaluiert, ob eine Vorschlagspalte angezeigt werden soll. Hierzu wird entweder anhand des sendetagDatums oder dem
 * connectedSchemaplatz Attribut der vorangegangene Schemaplatz Spaltenindex identifziert.
 */
@Pipe({
  name: "showVorschlaegeOrVorschlaegeNotizen",
})
export class ShowVorschlaegePipe implements PipeTransform {
  transform(
    ansichtHeaders: ListenansichtColumnHeader[],
    sendeplatzOrDummy: SendeplatzDto | SendeplatzDummy | null | undefined,
    headerEnum: ListenansichtHeaderEnum.VORSCHLAEGE | ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN,
  ): boolean {
    if (!sendeplatzOrDummy) return false;
    const wochentag =
      "wochentag" in sendeplatzOrDummy
        ? sendeplatzOrDummy.wochentag
        : toWochentag(sendeplatzOrDummy.sendetag);
    const columnId = createColumnId(headerEnum, wochentag, sendeplatzOrDummy.beginnzeit);
    const isActive = ansichtHeaders.some((header) => header.columnId === columnId);
    return isActive;
  }
}
