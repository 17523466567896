import { createSelector } from "@ngrx/store";
import { BlockansichtMapper } from "src/app/models/mapper/blockansicht.mapper";
import { Layout } from "src/app/models/openapi/model/layout";
import { BlockansichtPillPositioningService } from "src/app/pages/ansichten/blockansicht/blockansicht-chip-positioning.service";
import { BlockansichtDefinition } from "src/app/pages/ansichten/blockansicht/blockansicht-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import { multiAnsichtFeature } from "../multiansicht/multiansicht.reducer";
import { planungsobjektFeature } from "../planungsobjekt/planungsobjekt.reducer";
import planungsobjektSelectors from "../planungsobjekt/planungsobjekt.selectors";
import { TimelineColumnData } from "./blockansicht.model";
import { blockansichtFeature } from "./blockansicht.reducer";

/**
 * Mappt den aktuellen Zoomlevel und das ViewModel der Multiansicht auf die BlockansichtDefinitionen.
 */
const selectBlockansichtDefinitionen = createSelector(
  blockansichtFeature.selectZoomLevel,
  multiAnsichtFeature.selectMultiAnsichtViewModel,
  (zoomLevel, multiansichtViewModel): BlockansichtDefinition[] => {
    return multiansichtViewModel?.layout === Layout.BLOCK
      ? BlockansichtMapper.mapMultiAnsichtViewModelToBlockansichtDefinition(
          zoomLevel,
          multiansichtViewModel,
        )
      : [];
  },
);

/**
 * Berechnet die Spalten der Blockansicht anhand der BlockansichtDefinitionen.
 */
const selectBlockansichtColumns = createSelector(
  selectBlockansichtDefinitionen,
  (blockansichtDefinitionen): TimelineColumnData[] => {
    // Hier kann auf die erste Definition zugegriffen werden, da alle Definitionen die gleichen Werte für
    // beginnzeit, minutesPerColumn, laengeInMinuten und blockWidth haben. Abgeleitet aus dem AnsichtViewModel.
    if (blockansichtDefinitionen.length === 0) return [];
    const neededColumnCount = Math.ceil(
      blockansichtDefinitionen[0].laengeInMinuten / blockansichtDefinitionen[0].minutesPerColumn,
    );
    return Array.from(Array(neededColumnCount), (_, index): TimelineColumnData => {
      const createTableHeader =
        isHeaderColumnEnd(index) ||
        isHeaderColumnCenter(index) ||
        fitsNeededColumnCount(index, neededColumnCount);
      const colspan = isHeaderColumnEnd(index) ? 2 : undefined;
      const minuteOffset = blockansichtDefinitionen[0].minutesPerColumn * index;
      const time = DateFnsService.addMinutes(
        DateFnsService.parseDateAndTimeToDateObject(blockansichtDefinitionen[0].beginnzeit),
        minuteOffset,
      );
      const title = DateFnsService.formatDateAsTimeString(time);
      return {
        createTableHeader,
        widthPx: blockansichtDefinitionen[0].blockWidth,
        minuteOffset,
        title,
        colspan,
      };
    });
  },
);

/**
 * Ermittelt das BlockansichtDataVM anhand der BlockansichtDefinitionen und der Planungsobjekte die angezeigt
 * werden sollen
 */
const selectBlockansichtDataVM = createSelector(
  selectBlockansichtDefinitionen,
  blockansichtFeature.selectVarianteZeilen,
  planungsobjektSelectors.selectPlanungsobjekteOnBlockansicht,
  planungsobjektFeature.selectLoading,
  (
    blockansichtDefinitionen,
    manuelleVariantenzeilen,
    planungsobjekte,
    isLoadingPlanungsobjekte,
  ) => {
    if (blockansichtDefinitionen.length === 0 || isLoadingPlanungsobjekte) return {};
    return BlockansichtMapper.mapBlockansichtDefinitionToBlockansichtViewmodel(
      blockansichtDefinitionen,
      BlockansichtMapper.filterPlanungsobjekteForBlockansichtdefinitionen(
        blockansichtDefinitionen,
        planungsobjekte,
      ),
      manuelleVariantenzeilen,
    );
  },
);

/**
 * Berechnet die originale Breite der Chips auf der Merkliste in der Blockansicht anhand der Laenge der
 * Planungsobjekt. Hierzu wird mit der planungsobjektId die korrekte Planungsobjekt aus dem State geladen.
 * @param planungsobjektId
 * @returns die Breite der Chips in Pixeln oder null (sollte aber eigentlich nie auftreten)
 */
const selectBlockansichtChipWidth = (planungsobjektId: string) => {
  return createSelector(
    selectBlockansichtDefinitionen,
    planungsobjektFeature.selectEntities,
    (blockansichtDefinitionen, planungsobjekte) => {
      if (
        (blockansichtDefinitionen && blockansichtDefinitionen.length === 0) ||
        !planungsobjekte ||
        !planungsobjektId
      )
        return null;
      const planungsobjekt = planungsobjekte[planungsobjektId];
      if (!planungsobjekt) return null;

      return BlockansichtPillPositioningService.computeWidthWithLaenge(
        blockansichtDefinitionen[0].blockWidth,
        blockansichtDefinitionen[0].minutesPerColumn,
        planungsobjekt,
      );
    },
  );
};

const isHeaderColumnEnd = (index: number) => {
  return index % 3 === 0 && index > 0;
};

const isHeaderColumnCenter = (index: number) => {
  return index % 3 === 1;
};

const fitsNeededColumnCount = (index: number, neededColumnCount: number) => {
  return index + 1 >= neededColumnCount;
};

export default {
  selectBlockansichtDefinitionen,
  selectBlockansichtDataVM,
  selectBlockansichtColumns,
  selectBlockansichtChipWidth,
};
