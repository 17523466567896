import { Pipe, PipeTransform } from "@angular/core";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { DurationPipe } from "./duration.pipe";

@Pipe({
  name: "planungsobjektTooltip",
})
export class PlanungsobjektTooltipPipe implements PipeTransform {
  transform(vm: PlanungsobjektDto): string {
    const laengeInMinuten = DurationPipe.transform(vm.publikationsplanung.laenge, 3);
    return `${vm.titel} \nBeginnzeit: ${vm.publikationsplanung.beginnzeit} Uhr | Länge: ${laengeInMinuten} Minuten`;
  }
}
