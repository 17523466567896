import { createSelector } from "@ngrx/store";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { PlanungsobjektBeziehungTyp } from "src/app/models/openapi/model/planungsobjekt-beziehung-typ";
import planungsobjektWindowSelectors from "../planungsobjekt-window/planungsobjekt-window.selectors";
import { beziehungFeature } from "./beziehung.reducer";

const reduceByBeziehungTyp = (
  acc: BeziehungDto[],
  beziehung: BeziehungDto | null,
  beziehungTyp: PlanungsobjektBeziehungTyp,
) => {
  if (beziehung !== null && beziehung.beziehungTyp === beziehungTyp) {
    acc.push(beziehung);
  }
  return acc;
};

const selectReihenfolgeByPlanungsobjekt = createSelector(
  beziehungFeature.selectEntities,
  planungsobjektWindowSelectors.selectPlanungsobjektBeziehungenIds,
  (beziehungen, beziehungenIds) => {
    return beziehungenIds
      .map((id) => beziehungen[id] ?? null)
      .reduce(
        (acc: BeziehungDto[], beziehung) => reduceByBeziehungTyp(acc, beziehung, "Reihenfolge"),
        [],
      );
  },
);

const selectAbhaengigkeitenByPlanungsobjekt = createSelector(
  beziehungFeature.selectEntities,
  planungsobjektWindowSelectors.selectPlanungsobjektBeziehungenIds,
  (beziehungen, beziehungenIds) => {
    return beziehungenIds
      .map((id) => beziehungen[id] ?? null)
      .reduce(
        (acc: BeziehungDto[], beziehung) => reduceByBeziehungTyp(acc, beziehung, "Abhaengigkeit"),
        [],
      );
  },
);

const selectLinearOnDemandBeziehungByPlanungsobjekt = createSelector(
  beziehungFeature.selectEntities,
  planungsobjektWindowSelectors.selectPlanungsobjektBeziehungenIds,
  (beziehungen, beziehungenIds) => {
    return beziehungenIds
      .map((id) => beziehungen[id] ?? null)
      .reduce(
        (acc: BeziehungDto[], beziehung) => reduceByBeziehungTyp(acc, beziehung, "LinearOnDemand"),
        [],
      );
  },
);

export default {
  selectAbhaengigkeitenByPlanungsobjekt,
  selectReihenfolgeByPlanungsobjekt,
  selectLinearOnDemandBeziehungByPlanungsobjekt,
};
