import { createFeature, createReducer, on } from "@ngrx/store";
import {
  AnsichtPublikationViewModel,
  MultiAnsichtViewModel,
} from "src/app/models/viewmodels/ansicht-viewmodel";
import { FeatureKey } from "../feature.keys";
import { multiansichtActions } from "./multiansicht.actions";

// MultiAnsicht
export type MultiAnsichtState = {
  multiAnsichtViewModel: MultiAnsichtViewModel | null;
  loading: boolean;
};

const initialMultiAnsichtState: MultiAnsichtState = {
  multiAnsichtViewModel: null,
  loading: false,
};

export const multiAnsichtFeature = createFeature({
  name: FeatureKey.MultiAnsicht,
  reducer: createReducer(
    initialMultiAnsichtState,
    on(multiansichtActions.setMultiansicht, (currentState): MultiAnsichtState => {
      return { ...currentState, loading: true };
    }),
    on(
      multiansichtActions.setMultiansichtSuccess,
      (currentState, { multiAnsichtViewModel }): MultiAnsichtState => {
        return {
          ...currentState,
          multiAnsichtViewModel,
          loading: false,
        };
      },
    ),
    on(
      multiansichtActions.updateAnsichtInMultiansichtSuccess,
      (currentState, { additionalAnsichtViewModelId }): MultiAnsichtState => {
        if (additionalAnsichtViewModelId) {
          const currentMultiAnsichtViewModel = currentState.multiAnsichtViewModel;
          if (!currentMultiAnsichtViewModel) return currentState;

          const toReplaceIndex = currentMultiAnsichtViewModel.ansichtViewModels.findIndex(
            (amv) => additionalAnsichtViewModelId === amv.ansichtViewModel.id,
          );
          const updatedAnsichtViewModels = [...currentMultiAnsichtViewModel.ansichtViewModels];
          const newAnsicht: AnsichtPublikationViewModel = {
            ansichtViewModel: { ...updatedAnsichtViewModels[toReplaceIndex].ansichtViewModel },
            visible: true,
            primary: false,
          };
          updatedAnsichtViewModels[toReplaceIndex] = newAnsicht;
          return {
            ...currentState,
            multiAnsichtViewModel: {
              ...currentMultiAnsichtViewModel,
              ansichtViewModels: updatedAnsichtViewModels,
            },
            loading: false,
          };
        } else {
          return currentState;
        }
      },
    ),
  ),
});
