import { createActionGroup, props } from "@ngrx/store";
import { CreatePlanungshinweisCommand } from "src/app/models/openapi/model/create-planungshinweis-command";
import { PlanungshinweisDto } from "src/app/models/openapi/model/planungshinweis-dto";
import { SideEffectNotificationDto } from "src/app/models/openapi/model/side-effect-notification-dto";
import { UpdatePlanungshinweisCommand } from "src/app/models/openapi/model/update-planungshinweis-command";

export const planungshinweisActions = createActionGroup({
  source: "Planungshinweise API",
  events: {
    loadPlanungshinweiseByAnsicht: props<{
      ansichtId: string;
    }>(),
    loadPlanungshinweiseByAnsichtSuccess: props<{
      planungshinweise: PlanungshinweisDto[];
    }>(),
    loadPlanungshinweiseByAnsichtError: props<{
      error: unknown;
    }>(),

    loadPlanungshinweiseByAnsichten: props<{
      ansichtenIds: string[];
    }>(),
    loadPlanungshinweiseByAnsichtenSuccess: props<{
      planungshinweise: PlanungshinweisDto[];
    }>(),
    loadPlanungshinweiseByAnsichtenError: props<{
      error: unknown;
    }>(),

    createPlanungshinweis: props<{
      command: CreatePlanungshinweisCommand;
    }>(),
    createPlanungshinweisSuccess: props<{
      planungshinweis: PlanungshinweisDto;
      notifications: SideEffectNotificationDto[];
    }>(),
    createPlanungshinweisError: props<{
      error: unknown;
    }>(),

    updatePlanungshinweis: props<{
      command: UpdatePlanungshinweisCommand;
    }>(),
    updatePlanungshinweisSuccess: props<{
      planungshinweis: PlanungshinweisDto;
      notifications: SideEffectNotificationDto[];
    }>(),
    updatePlanungshinweisError: props<{
      error: unknown;
    }>(),

    deletePlanungshinweis: props<{
      id: string;
    }>(),
    deletePlanungshinweisSuccess: props<{
      planungshinweis: PlanungshinweisDto;
      notifications: SideEffectNotificationDto[];
    }>(),
    deletePlanungshinweisError: props<{
      error: unknown;
    }>(),
  },
});
