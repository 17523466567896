<!-- Das [].constructor(ansichtHeaderLength.length - 1) wird an dieser Stelle verwendet, um eine Zahl (ansichtHeaderLength.length) für das ngFor iterierbar zu machen  -->

<ng-container *ngFor="let _ of [].constructor(ansichtHeaderLength - 1); first as first">
  <th
    *ngIf="first"
    class="year-divider year"
    [attr.data-testid]="'year-divider-' + year"
    [style.top.px]="topOffsetForSticky"
  >
    {{ year }}
  </th>
  <th class="year-divider" [style.top.px]="topOffsetForSticky"></th>
</ng-container>
