import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { map, switchMap } from "rxjs";
import { GetitService } from "src/app/services/getit.service";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { getitWindowActions } from "./getit-window.actions";

@Injectable()
export class GetitWindowEffects {
  openGetitWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getitWindowActions.openGetitWindow),
      switchMap((action) => this.getitService.openGetitWindow(action.windowInputs).result),
      map((result) =>
        getitWindowActions.handleGetitWindowResult({
          result,
        }),
      ),
    );
  });

  handleGetitWindowResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getitWindowActions.handleGetitWindowResult),
      map(({ result }) => {
        if (result instanceof WindowCloseResult) return getitWindowActions.closeWindow();
        if (result.verknuepfenCommand) {
          return planungsobjektActions.verknuepfePlanungsobjektMitGetit({
            command: result.verknuepfenCommand,
          });
        } else {
          return planungsobjektActions.createPlanungsobjektWithGetitId({
            command: result.erstellenCommand!,
          });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private getitService: GetitService,
  ) {}
}
