import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { AktionEnum } from "src/app/models/enums/aktion";
import { AusstrahlungOptions } from "src/app/models/enums/ausstrahlung";
import { GenreOptionsFiltered } from "src/app/models/enums/genre";
import { RedaktionOptions } from "src/app/models/enums/redaktion";
import { Ausstrahlung } from "src/app/models/openapi/model/ausstrahlung";
import { CreateMengengeruesteintragCommand } from "src/app/models/openapi/model/create-mengengeruesteintrag-command";
import { Genre } from "src/app/models/openapi/model/genre";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { UpdateMengengeruesteintragCommand } from "src/app/models/openapi/model/update-mengengeruesteintrag-command";
import { filledStringOrNullify } from "src/app/utils/object-utils";

export type MengengeruestEintragWindowInput =
  | {
      action: AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG;
      ansichtId: string;
      schemaplatzId: string;
      presetBeginnzeit: string | null;
    }
  | {
      action: AktionEnum.BEARBEITE_MENGENGERUESTEINTRAG;
      mengengeruesteintrag: MengengeruesteintragDto;
    };

export type MengengeruestEintragWindowCreateResult = {
  mengengeruesteintrag: CreateMengengeruesteintragCommand;
};

export type MengengeruestEintragWindowUpdateResult = {
  mengengeruesteintrag: UpdateMengengeruesteintragCommand;
};

@Component({
  selector: "app-mengengeruesteintrag-window",
  templateUrl: "./mengengeruesteintrag-window.component.html",
  styleUrls: ["./mengengeruesteintrag-window.component.scss"],
})
export class MengengeruestEintragWindowComponent implements OnInit {
  @Input() public data: MengengeruestEintragWindowInput;

  formGroup: ReturnType<typeof this.initializeForm>;

  Genre = Genre;
  RedaktionOptions = RedaktionOptions.filter(
    (redaktion) => redaktion.value !== Redaktion.KEINE_REDAKTION,
  );
  GenreOptions = GenreOptionsFiltered;
  AusstrahlungOptions = AusstrahlungOptions;

  constructor(
    private formBuilder: FormBuilder,
    private windowRef: WindowRef,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.initializeForm();

    if (
      this.data.action === AktionEnum.BEARBEITE_MENGENGERUESTEINTRAG &&
      this.data.mengengeruesteintrag
    ) {
      this.initializeData(this.formGroup, this.data.mengengeruesteintrag);
    }
  }

  private initializeForm() {
    return this.formBuilder.group({
      titel: ["", [Validators.minLength(2), Validators.required]],
      redaktion: [null as Redaktion | null, Validators.required],
      genre: [null as Genre | null],
      beginnzeit: [this.getBeginnzeitPresetAsDate()],
      ausstrahlung: [null as Ausstrahlung | null, Validators.required],
      sendesoll: [null as number | null, [Validators.min(1), Validators.required]],
      laenge: [null as number | null],
      kommentar: [""],
    });
  }

  private initializeData(
    formGroup: ReturnType<typeof this.initializeForm>,
    mengengeruesteintrag: MengengeruesteintragDto,
  ) {
    formGroup.controls.titel.setValue(mengengeruesteintrag.titel);
    formGroup.controls.redaktion.setValue(mengengeruesteintrag.redaktion);
    formGroup.controls.genre.setValue(
      mengengeruesteintrag.genre !== Genre.KEIN_GENRE ? mengengeruesteintrag.genre : null,
    );
    formGroup.controls.ausstrahlung.setValue(mengengeruesteintrag.ausstrahlung);
    formGroup.controls.sendesoll.setValue(mengengeruesteintrag.sendesoll);
    formGroup.controls.kommentar.setValue(mengengeruesteintrag.kommentar);
    formGroup.controls.laenge.setValue(mengengeruesteintrag.laenge);
    formGroup.controls.beginnzeit.setValue(mengengeruesteintrag.beginnzeit);
  }

  onSave(): void {
    const result =
      this.data.action === AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG
        ? this.getCreateResult()
        : this.getUpdateMengengeruesteintragCommand();
    this.windowRef.close(result);
  }

  onClose(): void {
    this.windowRef.close();
  }

  private getCreateResult(): MengengeruestEintragWindowCreateResult {
    if (this.data.action !== AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG) {
      throw new Error("Falsche Aktion");
    }
    const { titel, redaktion, genre, ausstrahlung, sendesoll, kommentar, beginnzeit, laenge } =
      this.formGroup.getRawValue();

    if (!titel || !redaktion || !ausstrahlung || !sendesoll) {
      throw new Error("Formular nicht korrekt ausgefüllt");
    }

    return {
      mengengeruesteintrag: {
        ansichtId: this.data.ansichtId,
        schemaplatzId: this.data.schemaplatzId,
        titel,
        redaktion,
        genre: genre ?? Genre.KEIN_GENRE,
        ausstrahlung,
        sendesoll,
        kommentar: kommentar ?? null,
        beginnzeit: filledStringOrNullify(beginnzeit),
        laenge,
      } satisfies CreateMengengeruesteintragCommand,
    };
  }

  private getUpdateMengengeruesteintragCommand(): MengengeruestEintragWindowUpdateResult {
    if (this.data.action !== AktionEnum.BEARBEITE_MENGENGERUESTEINTRAG) {
      throw new Error("Falsche Aktion");
    }
    const { titel, redaktion, genre, ausstrahlung, sendesoll, kommentar, beginnzeit, laenge } =
      this.formGroup.getRawValue();

    if (!titel || !redaktion || !ausstrahlung || !sendesoll) {
      throw new Error("Formular nicht korrekt ausgefüllt");
    }

    return {
      mengengeruesteintrag: {
        id: this.data.mengengeruesteintrag.id,
        titel,
        redaktion,
        genre: genre ?? Genre.KEIN_GENRE,
        ausstrahlung,
        sendesoll,
        kommentar,
        beginnzeit: filledStringOrNullify(beginnzeit),
        laenge,
      } satisfies UpdateMengengeruesteintragCommand,
    };
  }

  getBeginnzeitPresetAsDate = () => {
    return this.data.action === AktionEnum.ERSTELLE_MENGENGERUESTEINTRAG
      ? this.data.presetBeginnzeit
      : null;
  };
}
