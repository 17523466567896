<ng-container
  *ngIf="{
    selectedAnsichtViewModel: facade.selectedAnsichtViewModel$ | async,
    planungsobjekteInMehrfachauswahl: facade.planungsobjekteInMehrfachauswahl$ | async,
    isMultipleDragging: planungsobjektInteraktionService.isMultipleDragging$ | async,
    loading: loading$ | async,
    mehrfachauswahlEnabled: (planungsobjektInteraktionService.mehrfachauswahlEnabled$ | async),
    benachrichtigungenComponentData: benachrichtigungenComponentData$ | async
  } as vm"
  cdkDropListGroup
>
  <ng-container *ngIf="vm.selectedAnsichtViewModel; else noData">
    <kendo-drawer-container [ngClass]="{ 'mehrfachauswahl-cursor': vm.mehrfachauswahlEnabled }">
      <kendo-drawer
        #sidebarDrawer
        class="sidebar-drawer"
        data-testid="sidebar-drawer"
        mode="push"
        position="end"
        [expanded]="showSidebar$ | async"
        [width]="330"
      >
        <ng-template kendoDrawerTemplate>
          <app-sidebar
            #sidebarDrawer
            [activeSidebarTab]="activeSidebarTab$ | async"
            [benachrichtigungenComponentData]="vm.benachrichtigungenComponentData"
            [merklisten]="merklisten$ | async"
            [planungsobjekteInMehrfachauswahl]="vm.planungsobjekteInMehrfachauswahl"
            [selectedAnsichtViewModel]="vm.selectedAnsichtViewModel"
            [showKonkurrenzEvents]="facade.showKonkurrenzEvents$ | async"
            [showNotizen]="showNotizen$ | async"
            [sidebarExpanded]="showSidebar$ | async"
            [unreadNotificationForCurrentAnsicht]="unreadNotificationForCurrentAnsicht$ | async"
            [zoomLevel]="zoomLevel$ | async"
            (openCreateEkWindow)="facade.openCreateEkWindow()"
            (openMengengeruesteWindow)="
              facade.openMengengeruesteWindow(vm.selectedAnsichtViewModel)
            "
            (selectedZoomLevelChanged)="setCurrentZoomLevel($event)"
            (setActiveSidebarTab)="setActiveSidebarTab($event)"
            (toggleShowKonkurrenzEvents)="toggleShowKonkurrenzEvents()"
            (toggleShowNotizen)="toggleShowNotizen()"
            (triggerReportDownload)="triggerReportDownload($event)"
          ></app-sidebar>
        </ng-template>
      </kendo-drawer>
      <kendo-drawer-content>
        <div class="splitter-container">
          <div class="ansichten-pane">
            <app-year-dropdown></app-year-dropdown>
            <kendo-tabstrip
              *ngIf="ansichtViewModelsForYear$ | async as ansichtenViewModelsForYear"
              class="ansichten-tab-group"
              [scrollable]="tabScrollSettings"
            >
              <kendo-tabstrip-tab
                *ngFor="
                  let ansichtViewModel of ansichtenViewModelsForYear;
                  trackBy: trackByAnsichtViewModel;
                  index as i
                "
                [selected]="ansichtViewModel.id === vm.selectedAnsichtViewModel?.id"
              >
                <ng-template kendoTabTitle>
                  <a
                    class="overshadow-kendo-tab-title u-text-primary-contrast"
                    [attr.data-testid]="'tab-ansicht-' + ansichtViewModel.id"
                    [queryParams]="{
                      ansichtId: ansichtViewModel.id,
                      year: ansichtViewModel.year,
                      kanal: ansichtViewModel.kanal
                    }"
                    [routerLink]="[]"
                    >{{ ansichtViewModel.titel | shortTitle }}</a
                  >
                </ng-template>
                <ng-template kendoTabContent>
                  <dts-select-container
                    #selectContainer="dts-select-container"
                    class="scrollable-container"
                    intersectionMode="included"
                    [custom]="true"
                    [disableRangeSelection]="true"
                    [disabled]="!vm.mehrfachauswahlEnabled"
                    [selectNotExcludedOnly]="true"
                    [selectOnClick]="vm.mehrfachauswahlEnabled"
                    (itemDeselected)="onItemDeselected($event)"
                    (itemSelected)="onItemSelected($event, vm.planungsobjekteInMehrfachauswahl)"
                    (selectionEnded)="onSelectionEnded($event, vm.planungsobjekteInMehrfachauswahl)"
                    (selectionStarted)="onSelectionStarted()"
                  >
                    <ng-container
                      *ngIf="
                        ansichtViewModel.ansichtsdefinition.layout === Layout.LISTE && !vm.loading
                      "
                    >
                      <app-listenansicht
                        [ansichtViewModel]="ansichtViewModel"
                        [showKonkurrenzEvents]="facade.showKonkurrenzEvents$ | async"
                        [showNotizen]="showNotizen$ | async"
                      ></app-listenansicht>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        ansichtViewModel.ansichtsdefinition.layout === Layout.KALENDER &&
                        !vm.loading
                      "
                    >
                      <app-kalenderansicht
                        [ansichtViewModel]="ansichtViewModel"
                      ></app-kalenderansicht>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        ansichtViewModel.ansichtsdefinition.layout === Layout.BLOCK && !vm.loading
                      "
                    >
                      <app-blockansicht
                        [ansichtViewModel]="ansichtViewModel"
                        [showKonkurrenzEvents]="facade.showKonkurrenzEvents$ | async"
                        [showNotizen]="showNotizen$ | async"
                      ></app-blockansicht>
                    </ng-container>
                  </dts-select-container>
                </ng-template>
              </kendo-tabstrip-tab>
            </kendo-tabstrip>
            <!-- TODO: Das in ein eigenes Template packen, falls es dieses Feature in einen Kendo release schafft:
          https://feedback.telerik.com/kendo-angular-ui/1627028-add-templates-for-custom-content-before-and-after-kendo-tabstrips-f-e-kendopretabcontent-and-kendoposttabcontent -->
            <div class="quick-access-container">
              <div data-testid="quick-access-ek" (click)="toggleShowKonkurrenzEvents()">
                <kendo-svg-icon
                  class="quick-access-selection-icon"
                  title="E/K Spalten ein-/ausblenden"
                  [icon]="Icons.ekIcon"
                  [ngClass]="
                    (facade.showKonkurrenzEvents$ | async) ? 'icon-active' : 'icon-inactive'
                  "
                ></kendo-svg-icon>
              </div>
              <div
                *ngIf="vm.selectedAnsichtViewModel"
                data-testid="quick-access-notizen"
                [ngClass]="{
                  'selection-disabled':
                    vm.selectedAnsichtViewModel.ansichtsdefinition.layout === Layout.KALENDER
                }"
                (click)="toggleShowNotizen()"
              >
                <kendo-svg-icon
                  class="quick-access-selection-icon"
                  title="Notizen ein-/ausblenden"
                  [icon]="Icons.notizenIcon"
                  [ngClass]="
                    vm.selectedAnsichtViewModel.ansichtsdefinition.layout === Layout.KALENDER
                      ? 'selection-disabled'
                      : (showNotizen$ | async)
                        ? 'icon-active'
                        : 'icon-inactive'
                  "
                ></kendo-svg-icon>
              </div>
              <div
                data-testid="quick-access-ansicht-herunterladen"
                (click)="triggerReportDownload(vm.selectedAnsichtViewModel.id)"
              >
                <kendo-svg-icon
                  class="quick-access-selection-icon"
                  title="Ansicht herunterladen"
                  [icon]="Icons.download"
                ></kendo-svg-icon>
              </div>
            </div>
            <div class="sidebar-toggle-container">
              <kendo-badge-container>
                <span
                  class="sidebar-toggle"
                  data-testid="sidebar-toggle"
                  fillMode="none"
                  kendoButton
                  size="none"
                  title="weitere Funktionen ein/-ausblenden"
                  [disabled]="!!vm.planungsobjekteInMehrfachauswahl.length"
                  [ngClass]="(showSidebar$ | async) === false ? 'icon-inactive' : 'icon-active'"
                  [svgIcon]="Icons.indentIcon"
                  (click)="expandSidebar()"
                ></span>
                <kendo-badge
                  *ngIf="unreadNotificationForCurrentAnsicht$ | async"
                  shape="dot"
                ></kendo-badge>
              </kendo-badge-container>
            </div>
          </div>
        </div>
      </kendo-drawer-content>
    </kendo-drawer-container>
  </ng-container>
</ng-container>

<ng-template #noData>
  <app-authorization-failed></app-authorization-failed>
</ng-template>
