import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  DrawerItem,
  DrawerItemExpandedFn,
  DrawerSelectEvent,
} from "@progress/kendo-angular-layout";
import { Observable, Subject, combineLatest, delay, filter, map, scan, startWith, switchMap } from "rxjs";
import { PublitFrontendSettings } from "../environments/environment";
import ansichtSelectors from "./core/stores/ansicht/ansicht.selectors";
import routerSelectors from "./core/stores/router/router.selectors";
import { Icons } from "./models/icons";
import { Kanal } from "./models/openapi/model/kanal";
import { AnsichtViewModel } from "./models/viewmodels/ansicht-viewmodel";
import { AuthorizationService } from "./services/authorization.service";
import { CustomWindowService } from "./services/custom-window.service";
import { DebugService } from "./services/debug.service";

export interface PublitDrawerItem extends DrawerItem {
  handler?: (event: DrawerSelectEvent) => void;
  testId: string;
  hasChildren: boolean;
  path?: string;
  queryParams?: Record<string, unknown>;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  isExpanded = false;
  Icons = Icons;
  public logoUrl = this.settings.logoUrl;
  navigationItems$: Observable<PublitDrawerItem[]>;

  /**
   * Action/Subject, um das Expandieren von hierarchischen DrawerItems zu steuern.
   */
  private toggleExpandSubject = new Subject<string>();

  /**
   * Observable, das die IDs der aktuell expandierten DrawerItems hält.
   */
  private expandedDrawerIndices$ = this.toggleExpandSubject.pipe(
    scan(
      (acc, id) => (acc.includes(id) ? acc.filter((i) => i !== id) : [...acc, id]),
      [] as string[],
    ),
    startWith([] as string[]),
  );

  /**
   * Observable, das die aktuelle Methode zur Expansion von DrawerItems hält.
   */
  protected isDrawerItemExpanded$: Observable<DrawerItemExpandedFn> =
    this.expandedDrawerIndices$.pipe(
      switchMap((expandedIndices) =>
        this.store.select(ansichtSelectors.selectMenuBarHierachicalDrawerExpanded(expandedIndices)),
      ),
    );

  initComplete$: Observable<boolean> = combineLatest([
    this.authorizationService.isLoggedIn$,
    this.authorizationService.permissions$,
    this.authorizationService.caslPermissions$,
  ]).pipe(
    map(([isLoggedIn, permissions]) => {
      return isLoggedIn && permissions !== undefined;
    }),
  );

  constructor(
    public customWindowService: CustomWindowService,
    private settings: PublitFrontendSettings,
    private debug: DebugService,
    private authorizationService: AuthorizationService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.authorizationService.login();

    // Alle Umgebungen außer prod, verwenden das altfavicon
    if (!this.settings.production) {
      document.getElementById("favIcon").setAttribute("href", "assets/favicon/altfavicon.svg");
    }
    this.setupReactiveNavigationItems();
  }

  protected onSelect(event: DrawerSelectEvent): void {
    const item = event.item as PublitDrawerItem;
    if (item.handler) item.handler(event);
  }

  private setupReactiveNavigationItems() {
    const path$ = this.store.select(routerSelectors.selectUrl);
    const yearFromParamsOrDefault$ = this.store.select(routerSelectors.selectYearFromAnyQueryParam);
    const ansichtIdsForYearAndKanal$ = this.store.select(
      ansichtSelectors.selectAnsichtViewModelForYearByKanal,
    );

    /**
     * Wann immer die aktuelle Methode aus `isDrawerItemExpanded$` sich ändert, müssen
     * die DrawerItems neu berechnet werden, da nur dadurch im Kendo Drawer erneut
     * geprüft wird, ob ein DrawerItem expandiert ist oder nicht.
     */
    const isDrawerItemExpandedAusloesen$ = this.isDrawerItemExpanded$.pipe(map(() => void 0));

    this.navigationItems$ = combineLatest([
      path$,
      ansichtIdsForYearAndKanal$,
      yearFromParamsOrDefault$,
      this.authorizationService.isLoggedIn$,
      isDrawerItemExpandedAusloesen$,
    ]).pipe(
      // Delay(0) damit wir nicht schon neue Elemente Rendern, bevor Kendo das Expandieren der DrawerItems durchgeführt hat.
      // Ansonsten liefert Kendo das falsche Menu-Item zurück, dann öffnet sich z.B. das Info Fenster, wenn man auf die Recherche klickt.
      delay(0),
      map(([path, ansichtIdsForYearByKanal, yearParam, isLoggedIn, _]) => {
        return this.buildNavigationItems(
          path,
          ansichtIdsForYearByKanal,
          yearParam.toString(),
          isLoggedIn,
        );
      }),
    );
  }

  private logout(): void {
    this.authorizationService.logout();
  }

  private buildNavigationItems(
    path: string,
    ansichtIdsForYearByKanal: Record<string, AnsichtViewModel[]>,
    yearParam: string,
    isLoggedIn: boolean,
  ): PublitDrawerItem[] {
    return [
      { separator: true, hasChildren: false, testId: "drawer-separator-top" },
      {
        text: "Login",
        hasChildren: false,
        svgIcon: Icons.loginIcon,
        cssClass: { "k-hidden": isLoggedIn },
        testId: "drawer-login",
      },
      {
        id: "linear",
        hasChildren: true,
        text: "Lineare Planung",
        svgIcon: Icons.linear,
        cssClass: { "k-hidden": !isLoggedIn },
        testId: "drawer-linear",
        path: "/dashboard",
        queryParams: {
          year: yearParam,
        },
        selected: path.startsWith("/dashboard"),
        handler: (event: DrawerSelectEvent) => {
          // Handelt das Expandieren von hierarchischen Items
          const drawerItem = event.item as DrawerItem;
          const currentId = drawerItem.id?.toString();
          if (!currentId) return;

          this.toggleExpandSubject.next(currentId);
        },
      },
      // Perspektivisch könnte man das Icon anzeigen, je nachdem ob ansichtIdsForYearByKanal[Kanal] existiert oder nicht
      {
        id: "zdf",
        hasChildren: false,
        parentId: "linear",
        text: "ZDF",
        svgIcon: Icons.kanalIcons.zdfBlack,
        cssClass: { "k-hidden": !isLoggedIn },
        testId: "drawer-linear-ZDF",
        path: "/ansichten",

        queryParams: {
          ansichtId: ansichtIdsForYearByKanal[Kanal.ZDF]
            ? // Soll sich an den Tabs für die Ansichten orientieren. Wichtig ist nur, dass die erste Ansicht hier = erste Ansicht als Tab.
              // Beides orientiert sich am Selektor selectAnsichtViewModelFilteredByYear, der die Reihenfolge "as is" aus dem Store zurückgibt.
              // Es ist also potentiell entweder gemeinsam erwartet oder gemeinsam unerwartet was zurückkommt, aber die erste Ansicht ist die selbe.
              ansichtIdsForYearByKanal[Kanal.ZDF][0].id
            : undefined,
          year: yearParam,
          kanal: Kanal.ZDF,
        },
        // Ansichten mit jeweiligem Ausspielweg
        selected:
          path.startsWith("/ansichten") && ansichtIdsForYearByKanal[Kanal.ZDF]
            ? ansichtIdsForYearByKanal[Kanal.ZDF].some((viewmodel) => path.includes(viewmodel.id))
            : false,
      },
      {
        id: "zdfNeo",
        hasChildren: false,
        parentId: "linear",
        text: "ZDFneo",
        svgIcon: Icons.kanalIcons.zdfNeoBlack,
        cssClass: { "k-hidden": !isLoggedIn },
        testId: "drawer-linear-ZDFneo",
        path: "/ansichten",

        queryParams: {
          ansichtId: ansichtIdsForYearByKanal[Kanal.ZDF_NEO]
            ? // Soll sich an den Tabs für die Ansichten orientieren. Wichtig ist nur, dass die erste Ansicht hier = erste Ansicht als Tab.
              // Beides orientiert sich am Selektor selectAnsichtViewModelFilteredByYear, der die Reihenfolge "as is" aus dem Store zurückgibt.
              // Es ist also potentiell entweder gemeinsam erwartet oder gemeinsam unerwartet was zurückkommt, aber die erste Ansicht ist die selbe.
              ansichtIdsForYearByKanal[Kanal.ZDF_NEO][0].id
            : undefined,
          year: yearParam,
          kanal: Kanal.ZDF_NEO,
        },
        // Ansichten mit jeweiligem Ausspielweg
        selected:
          path.startsWith("/ansichten") && ansichtIdsForYearByKanal[Kanal.ZDF_NEO]
            ? ansichtIdsForYearByKanal[Kanal.ZDF_NEO].some((viewmodel) =>
                path.includes(viewmodel.id),
              )
            : false,
      },
      {
        text: "On Demand",
        hasChildren: false,
        svgIcon: Icons.onDemand,
        cssClass: { "k-hidden": !isLoggedIn },
        testId: "drawer-vod-ansichten",
        path: "/vod-ansichten",
        selected: path.startsWith("/vod-ansichten"),
      },
      {
        text: "Recherche",
        hasChildren: false,
        svgIcon: Icons.recherche,
        cssClass: { "k-hidden": !isLoggedIn },
        testId: "drawer-recherche",
        path: "/recherche",
        selected: path.startsWith("/recherche"),
      },
      {
        text: "Events & Konkurrenz",
        hasChildren: false,
        svgIcon: Icons.ekIcon,
        cssClass: { "k-hidden": !isLoggedIn },
        testId: "drawer-ek",
        path: "/ek-ansicht",
        queryParams: { year: yearParam },
        selected: path.startsWith("/ek-ansicht"),
      },
      {
        text: "Informationen",
        hasChildren: false,
        svgIcon: Icons.information,
        cssClass: { "k-hidden": !isLoggedIn },
        testId: "drawer-info",
        handler: (event: DrawerSelectEvent) => {
          event.preventDefault();
          this.customWindowService.openVersionWindow();
        },
      },
      {
        text: "Logout",
        hasChildren: false,
        svgIcon: Icons.logoutIcon,
        // cssClass: { "k-hidden": !isLoggedIn },
        testId: "drawer-logout",
        handler: (event: DrawerSelectEvent) => {
          event.preventDefault();
          this.logout();
        },
      },
      {
        text: "Debug",
        hasChildren: false,
        svgIcon: Icons.wrenchIcon,
        testId: "drawer-debug",
        cssClass: { "k-hidden": !this.debug.showDebugMenu },
        handler: (event: DrawerSelectEvent) => {
          event.preventDefault();
          this.customWindowService.openDebugWindow();
        },
      },
      { separator: true, hasChildren: false, testId: "drawer-separator-bottom" },
    ];
  }
}
