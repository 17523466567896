/* tslint:disable */
/* eslint-disable */
/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Planungskontext = {
    UNBEKANNT: 'Unbekannt',
    VORGESCHLAGEN: 'Vorgeschlagen',
    VORGEPLANT: 'Vorgeplant',
    VORGEMERKT: 'Vorgemerkt'
} as const;
export type Planungskontext = typeof Planungskontext[keyof typeof Planungskontext];


export function PlanungskontextFromJSON(json: any): Planungskontext {
    return PlanungskontextFromJSONTyped(json, false);
}

export function PlanungskontextFromJSONTyped(json: any, ignoreDiscriminator: boolean): Planungskontext {
    return json as Planungskontext;
}

export function PlanungskontextToJSON(value?: Planungskontext | null): any {
    return value as any;
}

