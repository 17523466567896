import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";

export const sendeplatzWindowActions = createActionGroup({
  source: "Sendeplatz Window",
  events: {
    openSendeplatzWindow: props<{
      sendeplatz: SendeplatzDto;
    }>(),
    windowClosed: emptyProps(),
  },
});
