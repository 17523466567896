import { Injectable } from "@angular/core";
import { DateFnsService } from "src/app/services/date-fns.service";
import { groupByPredicateToMap } from "src/app/utils/array-utils";
import { wochentagIsoOrder } from "src/app/utils/kanal-offset-utils";
import { isDefined } from "src/app/utils/object-utils";
import { Layout } from "../openapi/model/layout";
import { SendeplatzDto } from "../openapi/model/sendeplatz-dto";
import { Wochentag } from "../openapi/model/wochentag";
import { MultiAnsichtViewModel } from "../viewmodels/ansicht-viewmodel";
import { SchemaplatzHeaderViewModel } from "../viewmodels/schemaplatz-header-viewmodel";
import { SendeplatzViewModel } from "../viewmodels/sendeplatz-viewmodel";

@Injectable({
  providedIn: "root",
})
export class KalenderansichtMapper {
  /**
   * Transformiert die Sendeplätze in ein mehrdimensionales Array, dass geschachtelt ist in,
   * Monate, Wochen und Anzahl der Sendeplätze pro Tag. Fügt Leereinträge am Anfang und Ende des Monats ein,
   * um die Tabellenstruktur der Kalenderansicht einzuhalten
   * @param allSendeplaetze
   * @param multiAnsichtViewModel
   * @param sortedWochentage
   */
  static mapSendeplaetzeToVmForKalenderansicht(
    allSendeplaetze: Map<number, SendeplatzDto[]>,
    multiAnsichtViewModel: MultiAnsichtViewModel,
    sortedWochentage: Wochentag[],
  ): Record<string, SendeplatzViewModel[][][]> {
    const kalenderansichtSendeplaetzePerYear: Record<number, SendeplatzViewModel[][][]> = {};
    const primaryAnsichtViewModel = multiAnsichtViewModel.ansichtViewModels.find(
      (ansichtViewModel) => ansichtViewModel.primary,
    )?.ansichtViewModel;
    if (primaryAnsichtViewModel?.ansichtsdefinition.layout !== Layout.KALENDER)
      return kalenderansichtSendeplaetzePerYear;

    const schemaplaetze = primaryAnsichtViewModel.ansichtsdefinition?.schemaplaetze;
    const visibleAnsichten = multiAnsichtViewModel.ansichtViewModels.filter(
      (ansicht) => ansicht.visible,
    );

    for (const visibleAnsicht of visibleAnsichten) {
      const kalenderansichtSendeplaetzePerMonth: SendeplatzViewModel[][][] = [];

      const year = visibleAnsicht.ansichtViewModel.year;
      const sendeplaetzeByYear = allSendeplaetze.get(year);

      // Wenn es für das Jahr keine Sendeplätze gibt, überspringe die Iteration
      if (!sendeplaetzeByYear) continue;

      // Filter die für die Ansicht relevanten Sendepätze anhand der definierten Schemaplätze heraus.
      // Hierzu wird der Wochentag und die Beginnzeit berücksichtigt
      const filteredSendeplaetzeBySchemaplaetze = sendeplaetzeByYear
        .map((sendeplatz) => {
          // Der Kalendertag des Sendeplatzes entspricht dem Wochentag des Schemaplatzes
          const sendeplatzBeginnzeit = DateFnsService.parseDateAndTimeToDateObject(
            sendeplatz.beginnzeit,
            sendeplatz.kalendertag,
          );
          const foundSchemaplatz = schemaplaetze.find(
            (schemaplatz) =>
              DateFnsService.getWeekday(sendeplatzBeginnzeit) === schemaplatz.wochentag &&
              sendeplatz.beginnzeit === schemaplatz.beginnzeit,
          );
          if (!foundSchemaplatz) return null;
          return sendeplatz;
        })
        .filter(isDefined)
        .sort((a, b) => a.effektivVon.localeCompare(b.effektivVon));

      // Gruppiere die Sendeplätze nach Monat
      const groupedSendeplaetzeByMonth = groupByPredicateToMap(
        filteredSendeplaetzeBySchemaplaetze,
        (sendeplatz) => DateFnsService.getMonth(sendeplatz.sendetag),
      );

      // Iteriere über die Monate und ihre Sendetage. Für jede Woche wird ein Array mit der Schemaplatzlänge erstellt.
      // Ggf. müssen Leereinträge am Anfang und Ende des Monats eingefügt werden, um die Tabellenstruktur einzuhalten.
      for (const sendeplaetze of groupedSendeplaetzeByMonth.values()) {
        let dayIndex = 0;
        const sendeplatzTupelMonth: SendeplatzViewModel[][] = [];
        let sendeplatzTupelWeek: SendeplatzViewModel[] = [];

        const groupedSendeplaetzeBySendetag = groupByPredicateToMap(
          sendeplaetze,
          (sendeplatz) => sendeplatz.sendetag,
        );

        const sendeplaetzeValues = [...groupedSendeplaetzeBySendetag.values()];

        // Der Index wird benötigt um den Offset zu ermitteln welcher bestimmt mit wie vielen
        // Leereinträgen die Woche aufgefüllt werden muss, damit der Monat am richtigen Wochentag beginnt.
        const wochentagIndex = sortedWochentage.findIndex(
          (s) => s === DateFnsService.getWeekday(sendeplaetzeValues[0][0].sendetag),
        );

        // Berechne die Anzahl der Leereinträge die benötigt werden
        const weekdayOffset = wochentagIndex % sortedWochentage.length;

        // Fügt Leereinträge an den Anfang der 1.Woche des Monats
        sendeplatzTupelWeek = [
          ...new Array<SendeplatzViewModel>(weekdayOffset).fill({} as SendeplatzViewModel),
        ];

        // Anzahl der Wochentage, die in der 1. Woche nach dem 1. Tag noch hinzugefügt werden müssen.
        let weekdayLimit = sortedWochentage.length - weekdayOffset;

        // Berechne die Anzahl der Kalenderwochen im Monat abhängig von der Anzahl der Tage und der Schemaplatzlänge
        const calendarWeeks = Math.ceil(
          (groupedSendeplaetzeBySendetag.size + weekdayOffset) / sortedWochentage.length,
        );

        for (let weekIndex = 0; weekIndex < calendarWeeks; weekIndex++) {
          // Iterieren über die Wochentage minus Leereinträge für die erste Woche
          for (let weekdayIndex = 0; weekdayIndex < weekdayLimit; weekdayIndex++) {
            dayIndex++;
            // Fügt die Sendeplätze oder die Leereinträge in die Wochen-Arrays ein
            // unter Berücksichtigung der Leereinträge in der 1. Monatswoche
            if (sendeplaetzeValues[dayIndex - 1] === undefined) {
              sendeplatzTupelWeek.push({} as SendeplatzViewModel);
            } else {
              sendeplatzTupelWeek.push({
                sendeplaetze: sendeplaetzeValues[dayIndex - 1],
                sendetagDatum: new Date(sendeplaetzeValues[dayIndex - 1][0].effektivVon),
              });
            }
          }
          sendeplatzTupelMonth.push(sendeplatzTupelWeek);
          sendeplatzTupelWeek = [];
          // Nur für die erste Woche eines Monats muss die Menge der Tage mit dem Offset angepasst werden.
          // Bei den anderen Wochenen im Monat kann die komplette Länge der Schemplätze genutzt werden.
          weekdayLimit = sortedWochentage.length;
        }
        kalenderansichtSendeplaetzePerMonth.push(sendeplatzTupelMonth);
      }
      kalenderansichtSendeplaetzePerYear[year] = kalenderansichtSendeplaetzePerMonth;
    }
    return kalenderansichtSendeplaetzePerYear;
  }

  /**
   * Transformiert die Schemaplaetze in ein Array, dass die Kalenderansicht Header in Form von Wochentage
   * und Beginnzeiten abbildet.
   * @param multiAnsichtViewModel
   * @returns Liste an SchemaplatzHeadern
   */
  static mapMultiAnsichtViewModelToKalenderansichtHeader(
    multiAnsichtViewModel: MultiAnsichtViewModel,
  ): SchemaplatzHeaderViewModel[] {
    // Hole die Schemaplaetze der primären Ansicht
    const schemaplaetze = multiAnsichtViewModel?.ansichtViewModels.find((avm) => avm.primary)
      ?.ansichtViewModel.ansichtsdefinition.schemaplaetze;
    if (!schemaplaetze) return [];

    const kalenderansichtHeader: SchemaplatzHeaderViewModel[] = [];
    for (const schemaplatz of schemaplaetze) {
      const index = kalenderansichtHeader.findIndex(
        (spHeader) => schemaplatz.wochentag === spHeader.wochentag,
      );
      // Wenn der Schemaplatz bereits in den Headern vorhanden ist, füge die Beginnzeit hinzu,
      // ansonsten erstelle einen neuen Header Eintrag, bestehend aus Wochentag und einer Liste von Beginnzeiten
      if (index !== -1) {
        kalenderansichtHeader[index].beginnzeiten.push(schemaplatz.beginnzeit);
      } else {
        kalenderansichtHeader.push({
          wochentag: schemaplatz.wochentag,
          beginnzeiten: [schemaplatz.beginnzeit],
        });
      }
    }

    // Sortiere die Header nach der vom ZDF definierten Kalenderwochentag Reihenfolge Montag - Sonntag
    const sortedKalenderansichtHeader = [...kalenderansichtHeader].sort(
      (a, b) => wochentagIsoOrder[a.wochentag] - wochentagIsoOrder[b.wochentag],
    );
    return sortedKalenderansichtHeader;
  }
}
