import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from "@angular/core";
import {
  ListenansichtColumnHeader,
  ListenansichtHeaderEnum,
} from "src/app/core/stores/listenansicht/listenansicht.reducer";
import { Icons } from "src/app/models/icons";
import ListenansichtFacade from "../listenansicht.facade";

type ColumnId = string;

type VisibleHeadersByColumnId = Record<
  ColumnId,
  Record<ListenansichtHeaderEnum.VORSCHLAEGE | ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN, boolean>
>;

@Component({
  selector: "app-listenansicht-header",
  templateUrl: "./listenansicht-header.component.html",
  styleUrls: ["./listenansicht-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListenansichtHeaderComponent implements OnChanges {
  public readonly facade = inject(ListenansichtFacade);

  @Input() ansichtInitHeaders: ListenansichtColumnHeader[];
  @Output() toggleColumnByIdEvent = new EventEmitter<string>();

  PREVIOUS_YEAR_OFFSET = -1;

  // Die Vorschlagspalte folgt immer auf einen Schemaplatz Header
  ListenansichtHeader = ListenansichtHeaderEnum;
  Icons = Icons;
  trackByColumnId = (_: number, item: ListenansichtColumnHeader) => item.columnId;

  /**
   * Beinhaltet ein Objekt, das für jede Spalte die Sichtbarkeit der Vorschläge und Vorschläge Notizen Spalte
   * angibt. Die Spalten werden anhand ihrer ID identifiziert.
   */
  visibleHeadersByColumnId: VisibleHeadersByColumnId = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ansichtInitHeaders) {
      this.updateVisibleHeadersByColumnId();
    }
  }
  /**
   * Toggelt für eine gegebene Spalte eine andere Spalte entsprechend dem Wert in {@link listenansichtHeader}.
   *
   * @param columnId die Spalten-ID, für die die {@link listenansichtHeader}-Spalte getoggelt werden soll.
   * @param listenansichtHeader der Spaltentyp, der getoggelt werden soll.
   */
  toggleColumnById(columnId: string, listenansichtHeader: ListenansichtHeaderEnum) {
    const [, wochentag, beginnzeit] = columnId.split("-");
    this.toggleColumnByIdEvent.emit([listenansichtHeader, wochentag, beginnzeit].join("-"));
  }

  /**
   * Aktualisiert {@link visibleHeadersByColumnId} anhand der {@link ansichtInitHeaders}.
   *
   * Es wird für jede Spalte geprüft, ob die Vorschläge und Vorschläge Notizen Spalte sichtbar ist.
   * Die Information wird redundant gespeichert, um den Zugriff im Template zu vereinfachen.
   */
  private updateVisibleHeadersByColumnId() {
    const isColumnVisible = (columnId: string, columnType: ListenansichtHeaderEnum) => {
      const [, wochentag, beginnzeit] = columnId.split("-");
      const otherColumnId = [columnType, wochentag, beginnzeit].join("-");
      return this.ansichtInitHeaders.some((header) => header.columnId === otherColumnId);
    };

    this.visibleHeadersByColumnId = this.ansichtInitHeaders.reduce((acc, header) => {
      const isVorschlaegeVisible = isColumnVisible(
        header.columnId,
        ListenansichtHeaderEnum.VORSCHLAEGE,
      );
      const isVorschlaegeNotizenVisible = isColumnVisible(
        header.columnId,
        ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN,
      );
      return {
        ...acc,
        [header.columnId]: {
          [ListenansichtHeaderEnum.VORSCHLAEGE]: isVorschlaegeVisible,
          [ListenansichtHeaderEnum.VORSCHLAEGE_NOTIZEN]: isVorschlaegeNotizenVisible,
        },
      };
    }, {});
  }
}
