import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { OnDemandFilter } from "src/app/models/openapi/model/on-demand-filter";
import { RenameMerklisteForAnsichtCommand } from "src/app/models/openapi/model/rename-merkliste-for-ansicht-command";
import { RenameMerklisteForOnDemandCommand } from "src/app/models/openapi/model/rename-merkliste-for-on-demand-command";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MerklisteApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getMerklistenByAnsichtId$(ansichtId: string) {
    return this.http.get<MerklisteDto[]>(`${this.apiUrl}Merkliste/ByAnsicht/${ansichtId}`);
  }

  getMerklistenByOnDemand$(filter: OnDemandFilter) {
    return this.http.get<MerklisteDto[]>(`${this.apiUrl}Merkliste/ByOnDemand/${filter}`);
  }

  renameMerklisteAnsicht$(command: RenameMerklisteForAnsichtCommand) {
    return this.http.put<MerklisteDto>(`${this.apiUrl}Merkliste/Ansicht/Umbenennen`, command);
  }

  renameMerklisteOnDemand$(command: RenameMerklisteForOnDemandCommand): Observable<MerklisteDto> {
    return this.http.put<MerklisteDto>(`${this.apiUrl}Merkliste/OnDemand/Umbenennen`, command);
  }
}
