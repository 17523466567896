import { CdkDrag, CdkDropList } from "@angular/cdk/drag-drop";
import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import sendeplatzSelectors from "src/app/core/stores/sendeplatz/sendeplatz.selectors";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";

@Pipe({
  name: "canDropInVorgeplantSpalte",
})
export class CanDropInVorgeplantSpaltePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(
    canDropPlanungsobjektOnSendeplatz: boolean,
  ): Observable<(drag: CdkDrag<PlanungsobjektDto>, drop: CdkDropList<SendeplatzDto>) => boolean> {
    return this.store.select(
      sendeplatzSelectors.selectCanDropPlanungsobjektInVorgeplantSpaltePredicate(
        canDropPlanungsobjektOnSendeplatz,
      ),
    );
  }
}
