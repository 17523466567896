import { State } from "@progress/kendo-data-query";
import { RechercheSearchResultVM } from "../core/stores/recherche/recherche.model";
import { AktivitaetDto } from "../models/openapi/model/aktivitaet-dto";
import { AktivitaetEintragDto } from "../models/openapi/model/aktivitaet-eintrag-dto";
import { Kanal } from "../models/openapi/model/kanal";
import { Layout } from "../models/openapi/model/layout";
import { MengengeruesteintragDto } from "../models/openapi/model/mengengeruesteintrag-dto";
import { MerklisteDto } from "../models/openapi/model/merkliste-dto";
import { MerklisteKategorie } from "../models/openapi/model/merkliste-kategorie";
import { PlanungsobjektDto } from "../models/openapi/model/planungsobjekt-dto";
import { SchemaplatzDto } from "../models/openapi/model/schemaplatz-dto";
import { BenachrichtigungVm } from "../models/viewmodels/benachrichtigung-viewmodel";
import { DateFnsService } from "../services/date-fns.service";
import {
  KanalOffsetUtils,
  wochentagIsoOrder,
  wochentagProgrammwocheOrder,
} from "./kanal-offset-utils";
import { compareString } from "./string-utils";

/**
 * Vergleicht zwei SchemaplatzDto und sortiert diese nach dem Sendewochentag
 * @param kanal Der Kanal, für den die Sortierung durchgeführt werden soll
 * @returns Eine Funktion, die zwei SchemaplatzDto vergleicht. Für die Sortierung
 * wird zuerst der Sendewochentag des SchemaplatzDto bestimmt und dann nach der
 * fest definierten Sortierungsreihenfolge der Wochentage sortiert.
 */
export const compareBySendewochentag =
  (kanal: Kanal, layout: Layout) => (a: SchemaplatzDto, b: SchemaplatzDto) => {
    const wochentagOrder =
      layout === Layout.KALENDER ? wochentagIsoOrder : wochentagProgrammwocheOrder;
    const oneSendewochentag = KanalOffsetUtils.getSendewochentag(a.wochentag, a.beginnzeit, kanal);
    const twoSendewochentag = KanalOffsetUtils.getSendewochentag(b.wochentag, b.beginnzeit, kanal);
    return wochentagOrder[oneSendewochentag] - wochentagOrder[twoSendewochentag];
  };

export const compareByIsBetweenMidnightToTagesgrenze =
  (kanal: Kanal) => (a: SchemaplatzDto, b: SchemaplatzDto) =>
    compareBetweenMidnightToTagesgrenze(kanal)(a.beginnzeit, b.beginnzeit);

/**
 * Vergleicht zwei Strings, die eine Uhrzeit im Format "HH:mm" darstellen
 * und sortiert diese nach der Tagesgrenze des gegebenen Kanals.
 * @param kanal Der Kanal, für den die Sortierung durchgeführt werden soll
 * @returns Eine Funktion, die zwei Strings vergleicht und die Sortierung
 * nach der Tagesgrenze des gegebenen Kanals durchführt.
 */
export const compareBetweenMidnightToTagesgrenze = (kanal: Kanal) => (a: string, b: string) => {
  const oneBetweenMidnightToTagesgrenze = KanalOffsetUtils.isBetweenMidnightToTagesgrenze(a, kanal);
  const twoBetweenMidnightToTagesgrenze = KanalOffsetUtils.isBetweenMidnightToTagesgrenze(b, kanal);

  return Number(oneBetweenMidnightToTagesgrenze) - Number(twoBetweenMidnightToTagesgrenze);
};

export function compareSchemaplaetzeByBeginnzeit(a: SchemaplatzDto, b: SchemaplatzDto) {
  return compareBeginnzeit(a.beginnzeit, b.beginnzeit);
}

/**
 * Vergleicht zwei Uhrzeiten im Format "HH:mm"
 */
export function compareBeginnzeit(a: string, b: string) {
  // Beginnzeit kann lexicographisch sortiert werden, da sie im Format "HH:mm" vorliegt
  return a.localeCompare(b);
}

export function sortBenachrichtigungenByTimestamp(a: BenachrichtigungVm, b: BenachrichtigungVm) {
  return b.timestamp.getTime() - a.timestamp.getTime();
}

/**
 * Vergleicht zwei MengengeruesteintragDto wie folgt:
 * 1. Einträge ohne gesetzte Beginnzeit sollen immer am Ende angezeigt werden
 * 2. Einträge, die zwischen 0:00 und Tagesgrenze des gegebenen Kanals liegen sollen immer zuletzt angezeigt werden
 * 3. Einträge werden nach Beginnzeit sortiert
 * @param kanal Der Kanal, für den die Sortierung durchgeführt werden soll
 * @returns Eine Funktion, die zwei MengengeruesteintragDto vergleicht.
 */
export const compareMengengeruestEintraege =
  (kanal: Kanal) => (a: MengengeruesteintragDto, b: MengengeruesteintragDto) => {
    const beginnzeitA = a.beginnzeit;
    const beginnzeitB = b.beginnzeit;
    // Einträge ohne gesetzte Beginnzeit sollen immer am Ende angezeigt werden
    if (!beginnzeitA) {
      return 1;
    } else if (!beginnzeitB) {
      return -1;
    }
    const isBetweenMidnightToTagesgrenzeSortOrder = compareBetweenMidnightToTagesgrenze(kanal)(
      beginnzeitA,
      beginnzeitB,
    );
    if (isBetweenMidnightToTagesgrenzeSortOrder !== 0) {
      // Schemaplätze die zwischen 0:00 und Tagesgrenze liegen sollen immer zuletzt angezeigt werden
      // Bsp: 22:30, 23:00, 23:30, 0:30
      return isBetweenMidnightToTagesgrenzeSortOrder;
    }

    return compareBeginnzeit(beginnzeitA, beginnzeitB);
  };

/**
 * VPF mit Publikationsplanung werden nach Zeit sortiert
 */
export function comparePlanungsobjekteByPublikationsplanung(
  left: PlanungsobjektDto,
  right: PlanungsobjektDto,
) {
  const beginnzeitLeft = left.publikationsplanung.beginnzeit
    ? // VPF mit Publikationsplanung
      KanalOffsetUtils.getDateWithTagesgrenze(
        DateFnsService.parseDateAndTimeToDateObject(
          left.publikationsplanung.beginnzeit,
          left.publikationsplanung.sendetag,
        ),
        left.publikationsplanung.kanal,
      )
    : // VPF ohne Publikationsplanung
      new Date(0);

  const beginnzeitRight = right.publikationsplanung.beginnzeit
    ? // VPF mit Publikationsplanung
      KanalOffsetUtils.getDateWithTagesgrenze(
        DateFnsService.parseDateAndTimeToDateObject(
          right.publikationsplanung.beginnzeit,
          right.publikationsplanung.sendetag,
        ),
        right.publikationsplanung.kanal,
      )
    : // VPF ohne Publikationsplanung
      new Date(0);

  return beginnzeitLeft.getTime() - beginnzeitRight.getTime();
}

/**
 * Merklisten sollen zunächst nach ihrer Nummer aufsteigend sortiert werden (1, 2, 3),
 * dies sowohl für Ansichts-, als auch OnDemand-Merklisten.
 * Danach soll die Ausspielweg spezifische Merkliste angezeigt werden.
 */
export function sortMerklisten(left: MerklisteDto, right: MerklisteDto) {
  if (left.kategorie === right.kategorie) {
    return left.nummer - right.nummer;
  }
  return left.kategorie === MerklisteKategorie.AUSSPIELWEG ? 1 : -1;
}

/**
 * Einträge auf der Merkliste werden ihrem Titel nach alphabetisch sortiert.
 */
export function sortPlanungsobjektOnMerkliste(left: PlanungsobjektDto, right: PlanungsobjektDto) {
  return compareString(left.titel, right.titel);
}

/**
 * Aktivitäten werden nach ihrem Zeitstempel absteigend sortiert.
 */
export function sortAktivitaeten(left: AktivitaetDto, right: AktivitaetDto) {
  return new Date(right.createdAt).getTime() - new Date(left.createdAt).getTime();
}

const aktivitaetEintagOrder = [
  "Planungskontext",
  "Merkliste",
  "Sendetag",
  "Kalendertag",
  "Beginnzeit",
  "Endzeit",
  "Online ab",
  "Online ab Zeit",
  "Online bis",
  "Verweildauer",
  "Planlänge",
  "Länge",
  "Variante",
  "Titel",
  "Highlight",
  "Notiz",
  "Redaktion",
  "Stoffführende Redaktion",
  "Farbgebung",
  "Früheste Veröffentlichung",
  "Genre",
  "Content Communities",
  "FSK",
  "Staffelnummer",
  "Folgennummer",
  "Gesamtfolgennummer",
  "Inhaltsbeschreibung",
  "Mitwirkende",
  "Mengengerüsteintrag",
];

/**
 * Einträge werden nach einer festgelegten Reihenfolge sortiert,
 * die sich an der Reihenfolge der Werte im Planungsobjekt Fenster orientiert.
 */
export function sortAktivitaetEintraege(left: AktivitaetEintragDto, right: AktivitaetEintragDto) {
  const indexLeft = aktivitaetEintagOrder.indexOf(left.eigenschaft);
  const indexRight = aktivitaetEintagOrder.indexOf(right.eigenschaft);
  return indexLeft - indexRight;
}
/**
 * Methode zur Anwendung der Sortierlogik für das Recherche-Grid.
 * Sollte wieder entfernt werden, sobald die Daten wieder automatisch über das Kendo-Grid sortiert werden können.
 */
export function applySortingForKendoGrid(
  data: RechercheSearchResultVM[],
  state: State,
): RechercheSearchResultVM[] {
  const stateSort = state.sort;

  if (!stateSort || stateSort.length === 0) {
    return data;
  }

  // Erstellen einer Kopie des Arrays vor der Sortierung, damit wir nicht in-place sortieren
  const dataCopy = [...data];

  const sortedData = dataCopy.sort((a, b) => {
    for (const sort of stateSort) {
      const dir = sort.dir === "asc" ? 1 : -1;
      const field = sort.field as keyof RechercheSearchResultVM;
      if (a[field] === null || a[field] === undefined) {
        return dir === 1 ? 1 : -1; // Null-Werte nach oben bei aufsteigender Sortierung
      }
      if (b[field] === null || b[field] === undefined) {
        return dir === 1 ? -1 : 1; // Null-Werte nach unten bei absteigender Sortierung
      }
      if (a[field] < b[field]) {
        return -1 * dir;
      }
      if (a[field] > b[field]) {
        return 1 * dir;
      }
    }
    return 0;
  });

  return sortedData;
}
