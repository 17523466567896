import { Pipe, PipeTransform } from "@angular/core";
import { ForcedSubject } from "@casl/ability";
import { CaslSubjects } from "src/app/casl/casl-subjects";
import { asSubject } from "src/app/casl/casl-utils";

@Pipe({
  name: "asSubject",
})
export class AsSubjectPipe implements PipeTransform {
  transform<T extends CaslSubjects>(
    object: object | undefined | null,
    subjectType: T,
  ): ForcedSubject<T> {
    return asSubject(subjectType, object);
  }
}
