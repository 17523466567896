<ng-container *ngIf="{ planen: 'planen' | able: ({} | asSubject: 'Ansicht') } as can">
  <div
    *ngIf="{
      mehrfachauswahlActive: mehrfachauswahlActive$ | async
    } as vm"
    class="mehrfachauswahl-container"
  >
    <div class="mehrfachauswahl-chip-list-container">
      <kendo-chip-list
        aria-label="Titel"
        class="mehrfachauswahl-chip-list"
        kendoContextMenuTargetContainer
      >
        <kendo-chip
          *ngFor="let planungsobjekt of planungsobjekteInMehrfachauswahl"
          class="mehrfachauswahl-chip"
          rounded="full"
          [ngClass]="{
            'u-font-style-italic': !istMitProduktdatenbankVerknuepft(planungsobjekt)
          }"
          [ngStyle]="
            planungsobjekt.farbgebung
              | colorMapToNgStyle: planungsobjektFarbgebungMap : ColorMapToReturnValue.Style
          "
          [title]="planungsobjekt | mehrfachAuswahlTooltip | async"
          (click)="
            vm.mehrfachauswahlActive &&
              planungsobjektInteraktionService.selectItem(
                planungsobjekt.id,
                planungsobjekteInMehrfachauswahl,
                true
              )
          "
        >
          {{ planungsobjekt.titel }}
          {{
            planungsobjekt.redaktion && planungsobjekt.redaktion !== Redaktion.KEINE_REDAKTION
              ? " (" + planungsobjekt.redaktion + ")"
              : ""
          }}
          <kendo-svg-icon
            *ngIf="planungsobjekt.highlight"
            class="icon-xxs"
            [icon]="Icons.star"
          ></kendo-svg-icon>
        </kendo-chip>
      </kendo-chip-list>
    </div>
    <div>
      <div
        *ngIf="
          planungsobjekteInMehrfachauswahl.length === 2 &&
          (hasVpFassungenFromMerkliste$ | async) === false
        "
        class="sidebar-selection-row"
        [ngClass]="{
          'selection-disabled': !can.planen
        }"
        (click)="createAbhaengigkeitForMarkedPlanungsobjekte(planungsobjekteInMehrfachauswahl)"
      >
        <kendo-svg-icon [icon]="Icons.abhaengigkeitFestlegen"></kendo-svg-icon>
        Abhängigkeit festlegen
      </div>
      <div
        *ngIf="
          planungsobjekteInMehrfachauswahl.length > 1 &&
          (hasVpFassungenFromMerkliste$ | async) === false
        "
        class="sidebar-selection-row"
        [ngClass]="{
          'selection-disabled': !can.planen
        }"
        (click)="createReihenfolgeForMarkedPlanungsobjekte(planungsobjekteInMehrfachauswahl)"
      >
        <kendo-svg-icon [icon]="Icons.reihenfolgeFestlegen"></kendo-svg-icon>
        Reihenfolge festlegen
      </div>
      <div
        class="sidebar-selection-row"
        [ngClass]="{
          'selection-disabled': !can.planen || planungsobjekteInMehrfachauswahl.length === 0
        }"
        (click)="openMehrfachauswahlDeleteDialog(planungsobjekteInMehrfachauswahl)"
      >
        <kendo-svg-icon [icon]="Icons.trash"></kendo-svg-icon>
        Auswahl ausplanen
      </div>
      <div
        class="sidebar-selection-row"
        [ngClass]="{
          'selection-disabled': planungsobjekteInMehrfachauswahl.length === 0
        }"
        (click)="cancelMehrfachauswahl()"
      >
        <kendo-svg-icon [icon]="Icons.closeOutline"></kendo-svg-icon>
        Auswahl aufheben
      </div>
    </div>
  </div>
</ng-container>
