<app-kanal-icons
  *ngIf="kanal"
  data-testid="planungsbezug-ausspielweg"
  [kanal]="kanal"
  [tooltip]="'Ausspielweg: ' + kanal"
></app-kanal-icons>
<kendo-svg-icon *ngIf="!kanal" size="large" [icon]="UnbekanntIcon"></kendo-svg-icon>

<kendo-svg-icon
  *ngIf="publikationsartIcons[publikationsart] as publikationsartIcon"
  data-testid="planungsbezug-publikationsart"
  size="large"
  [icon]="publikationsartIcon"
  [title]="'Publikationsart: ' + publikationsart"
></kendo-svg-icon>

<kendo-svg-icon
  *ngIf="planungskontextIcons[planungskontext] as planungskontextIcon"
  data-testid="planungsbezug-planungskontext"
  size="large"
  [icon]="planungskontextIcon"
  [title]="'Planungskontext: ' + planungskontext"
></kendo-svg-icon>
