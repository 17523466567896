import { Injectable } from "@angular/core";
import { NotificationService } from "@progress/kendo-angular-notification";
import { NotificationStyle } from "src/app/models/openapi/model/notification-style";
import { SideEffectNotificationDto } from "src/app/models/openapi/model/side-effect-notification-dto";
import { formatString } from "src/app/utils/string-utils";
import { Aktion, AktionEnum } from "../../models/enums/aktion";
import { TemplateType, defaultNotificationSettings, templates } from "./notification.templates";
import { getKendoStyleType } from "./notification.utils";

@Injectable({
  providedIn: "root",
})
export class CustomNotificationService {
  constructor(private notificationService: NotificationService) {}

  showErrorNotification(error: string | Error | Aktion, titel?: string): void {
    let message: string;
    if (error instanceof Error) {
      message = error.message;
    } else if (error instanceof Aktion) {
      const errorMessage = error.value.notifications.Error ?? "";
      const entityTitle = titel ?? "";
      message = `${error.value.entityType} "${entityTitle}" ${errorMessage}`;
    } else {
      message = `${error}`;
    }

    this.notificationService.show({
      content: message,
      animation: { type: "fade", duration: 100 },
      type: { style: getKendoStyleType(NotificationStyle.ERROR), icon: false },
      closable: true,
    });
  }

  /**
   * Zeigt eine Benachrichtigung an.
   * @param message Nachricht, die angezeigt wird
   * @param type Style der Benachrichtigung und entsprechende Standard-Benachrichtigung, die erweitert wird
   */
  showNotification(message: string, type: NotificationStyle, closable = false) {
    this.notificationService.show({
      ...defaultNotificationSettings[type ?? NotificationStyle.NONE],
      type: {
        icon: false,
        style: getKendoStyleType(type) ?? getKendoStyleType(NotificationStyle.NONE),
      },
      content: message ?? "Unbekannter Fehler!",
      closable: closable,
    });
  }

  /**
   * Zeigt mehrere Benachrichtigungen an.
   */
  showNotifications(notifications: SideEffectNotificationDto[]) {
    for (const notification of notifications) {
      this.showNotification(notification.nachricht, notification.type);
    }
  }

  /**
   * Zeigt eine vorgefertigte Benachrichtigung die sehr spezifische Aktionen verwendet wird
   * @param template Auswahl eines Templates aus der `templates` Liste
   * @param args Liste von string Argumenten, die im Template eingesetzt werden
   */
  showTemplateNotification(template: TemplateType, ...args: string[]) {
    const { type, nachricht } = templates[template];
    const defaultSettings = defaultNotificationSettings[type];

    this.notificationService.show({
      ...defaultSettings,
      type: { icon: false, style: getKendoStyleType(type) },
      content: formatString(nachricht, args),
    });
  }

  /**
   * Zeigt eine vorgefertigte Benachrichtigung an die an mehreren Stellen für entitätsbezogene Aktionen verwendet wird
   * @param template Auswahl eines Templates aus der `templates` Liste
   * @param args Liste von string Argumenten, die im Template eingesetzt werden
   */
  showActionNotification(
    actionKey: Aktion | AktionEnum,
    type?: NotificationStyle,
    ...args: string[]
  ) {
    const action = actionKey instanceof Aktion ? actionKey : (Aktion[actionKey] as Aktion);
    const { entityType, notifications, duration, icon } = action.value;
    const notificationStyle: NotificationStyle = type ?? action.value.style;
    const defaultSettings = defaultNotificationSettings[notificationStyle];
    const message = `${entityType} "%1" ${notifications[notificationStyle]}`;

    this.notificationService.show({
      ...defaultSettings,
      type: {
        style: getKendoStyleType(notificationStyle),
        icon: icon ?? defaultSettings.type.icon,
      },
      closable: !(duration ?? !defaultSettings.closable),
      hideAfter: duration ?? defaultSettings.hideAfter,
      content: formatString(message, args),
    });
  }
}
