<kendo-grid
  *ngIf="activeRechercheColumns"
  class="u-h-full sub-grid"
  data-testid="recherche-ergebnisse"
  scrollable="none"
  [columnMenu]="false"
  [data]="data"
  [hideHeader]="true"
  [pageSize]="pageSize"
  [reorderable]="true"
  [resizable]="true"
  [rowHeight]="gridRowHeight"
  [skip]="skip"
  [sortable]="true"
  (cellClick)="onRowClick($event)"
  (columnVisibilityChange)="onToggleGridColumn($event)"
  (dblclick)="onRowDoubleClick()"
>
  <kendo-grid-messages
    [noRecords]="({} | isLoading | async) ? 'Wird geladen...' : 'Kann nicht angezeigt werden'"
  >
  </kendo-grid-messages>
  <kendo-grid-column
    *ngFor="let column of rechercheShownGridColumnsInOrder; trackBy: trackByField"
    [columnMenu]="false"
    [field]="column.field"
    [hidden]="column.hidden"
    [includeInChooser]="!activeRechercheColumns[column.field]?.disabled"
    [width]="column.width"
  >
    <!-- Ausspielweg -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.KANAL"
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        [attr.data-testid]="'recherche-spalte-kanal-' + dataItem.titel"
        [ngClass]="{
          'planungsobjektVorgeschlagen-cell':
            dataItem.planungskontext === Planungskontext.VORGESCHLAGEN
        }"
        [title]="dataItem.kanal"
      >
        <app-kanal-icons [kanal]="dataItem.kanal"></app-kanal-icons>
      </span>
    </ng-template>

    <!-- Zeit -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.ZEIT"
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        [attr.data-testid]="'recherche-spalte-zeit-' + dataItem.titel"
        [ngClass]="{
          'planungsobjektVorgeschlagen-cell':
            dataItem.planungskontext === Planungskontext.VORGESCHLAGEN
        }"
        [title]="
          (dataItem.zeit?.von | dfnsFormat: 'HH:mm') +
          ' - ' +
          (dataItem.zeit?.bis | dfnsFormat: 'HH:mm')
        "
      >
        <span>{{ dataItem.zeit?.von | dfnsFormat: "HH:mm" }}</span>
        <span *ngIf="dataItem.zeit">-</span>
        <span>{{ dataItem.zeit?.bis | dfnsFormat: "HH:mm" }}</span></span
      >
    </ng-template>

    <!-- Sendetag -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.SENDETAG"
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        [attr.data-testid]="'recherche-spalte-sendetag-' + dataItem.titel"
        [ngClass]="{
          'planungsobjektVorgeschlagen-cell':
            dataItem.planungskontext === Planungskontext.VORGESCHLAGEN
        }"
        [title]="dataItem.sendetag | dfnsFormat: 'EEEEEE dd.MM.yyyy'"
        >{{ dataItem.sendetag | dfnsFormat: "EEEEEE dd.MM.yyyy" }}</span
      >
    </ng-template>

    <!-- Online ab -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.ONLINEAB"
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        [attr.data-testid]="'recherche-spalte-onlineab-' + dataItem.titel"
        [ngClass]="{
          'planungsobjektVorgeschlagen-cell':
            dataItem.planungskontext === Planungskontext.VORGESCHLAGEN
        }"
        [title]="dataItem.onlineAb | dfnsFormat: 'EEEEEE dd.MM.yyyy'"
        >{{ dataItem.onlineAb | dfnsFormat: "EEEEEE dd.MM.yyyy" }}</span
      >
    </ng-template>

    <!-- Online ab Zeit -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.ONLINEABZEIT"
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        [attr.data-testid]="'recherche-spalte-onlineabzeit-' + dataItem.titel"
        [ngClass]="{
          'planungsobjektVorgeschlagen-cell':
            dataItem.planungskontext === Planungskontext.VORGESCHLAGEN
        }"
        [title]="dataItem.onlineAbZeit"
        >{{ dataItem.onlineAbZeit }}</span
      >
    </ng-template>

    <!-- Online bis -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.ONLINEBIS"
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        [attr.data-testid]="'recherche-spalte-onlinebis-' + dataItem.titel"
        [ngClass]="{
          'planungsobjektVorgeschlagen-cell':
            dataItem.planungskontext === Planungskontext.VORGESCHLAGEN
        }"
        [title]="dataItem.onlineBis | dfnsFormat: 'EEEEEE dd.MM.yyyy'"
        >{{ dataItem.onlineBis | dfnsFormat: "EEEEEE dd.MM.yyyy" }}</span
      >
    </ng-template>

    <!-- Titel -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.TITEL"
      kendoGridCellTemplate
      let-dataItem
    >
      <span *ngIf="!dataItem.mengengeruest">
        <kendo-svg-icon
          class="icon-xxs"
          title="Mengengerüst-Zuordnung fehlt"
          [icon]="Icons.balance"
        ></kendo-svg-icon>
        &nbsp;
      </span>
      <span [title]="dataItem.titel">{{ dataItem.titel }}</span>
      <span *ngIf="dataItem.highlight" title="Highlight">
        &nbsp;
        <kendo-svg-icon size="xsmall" title="Highlight" [icon]="Icons.star"></kendo-svg-icon>
      </span>
      <span *ngIf="dataItem.hasVariante" data-testid="recherche-result-has-variante">
        &nbsp;
        <kendo-svg-icon
          size="xsmall"
          title="weitere Varianten sind vorhanden, aber eventuell nicht Teil der Filtermenge"
          [icon]="Icons.warning"
        ></kendo-svg-icon>
      </span>
    </ng-template>

    <!-- Redaktion -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.REDAKTION"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.redaktion | enumBeschreibung: RedaktionRecord : true">{{
        dataItem.redaktion | enumBeschreibung: RedaktionRecord : true
      }}</span>
    </ng-template>

    <!-- Planlaenge -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.PLANLAENGE"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.planlaenge | duration: 3">{{
        dataItem.planlaenge | duration: 3
      }}</span>
    </ng-template>

    <!-- Genre -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.GENRE"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.genre | enumBeschreibung: GenreRecord : false">{{
        dataItem.genre | enumBeschreibung: GenreRecord : false
      }}</span>
    </ng-template>

    <!-- Zielgruppe/Content Communities -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.CONTENT_COMMUNITIES"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.contentCommunities | orderBy">{{
        dataItem.contentCommunities | orderBy
      }}</span>
    </ng-template>

    <!-- Farbe -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.FARBGEBUNG"
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        class="color-indicator"
        [ngStyle]="
          dataItem.farbgebung
            | colorMapToNgStyle: planungsobjektFarbgebungMap : ColorMapToReturnValue.Style
        "
        [title]="
          (
            dataItem.farbgebung
            | colorMapToNgStyle: planungsobjektFarbgebungMap : ColorMapToReturnValue.Name
          )?.name
        "
      ></span>
      <span
        [title]="
          (
            dataItem.farbgebung
            | colorMapToNgStyle: planungsobjektFarbgebungMap : ColorMapToReturnValue.Name
          )?.name
        "
        >{{
          (
            dataItem.farbgebung
            | colorMapToNgStyle: planungsobjektFarbgebungMap : ColorMapToReturnValue.Name
          )?.name
        }}
      </span>
    </ng-template>

    <!-- Notiz -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.NOTIZ"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.notiz">{{ dataItem.notiz }}</span>
    </ng-template>

    <!-- Variante -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.VARIANTE"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.variante">{{ dataItem.variante }}</span>
    </ng-template>

    <!-- Planungskontext -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.PLANUNGSKONTEXT"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.planungskontext">{{
        dataItem.planungskontext | enumBeschreibung: PlanungskontextRecord : false
      }}</span>
    </ng-template>

    <!-- Getid -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.GETITID"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.getitId">{{ dataItem.getitId }}</span>
    </ng-template>

    <!-- Produktstatus -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.PRODUKTSTATUS"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.produktstatus | enumBeschreibung: ProduktStatusRecord : false">{{
        dataItem.produktstatus | enumBeschreibung: ProduktStatusRecord : false
      }}</span>
    </ng-template>

    <!-- Frueheste Veroeffentlichung -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.FRUEHESTEVEROEFFENTLICHUNG"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.fruehesteVeroeffentlichung | dfnsFormat: 'dd.MM.yyyy'">{{
        dataItem.fruehesteVeroeffentlichung | dfnsFormat: "dd.MM.yyyy"
      }}</span>
    </ng-template>

    <!-- Produkttitel -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.PRODUKTTITEL"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.produkttitelMultipart + ' ' + dataItem.produkttitel"
        ><em>{{ dataItem.produkttitelMultipart }}</em></span
      >
      <span>&nbsp;</span>
      <span [title]="dataItem.produkttitelMultipart + ' ' + dataItem.produkttitel">{{
        dataItem.produkttitel
      }}</span>
    </ng-template>

    <!-- Produktlaenge -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.PRODUKTLAENGE"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.produktlaenge | duration: 3">{{
        dataItem.produktlaenge | duration: 3
      }}</span>
    </ng-template>

    <!-- Stofffuehrende Redaktion -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.STOFFFUEHRENDEREDAKTION"
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        [title]="
          dataItem.stofffuehrendeRedaktion | enumBeschreibung: StofffuehrendeRedaktionRecord : true
        "
        >{{
          dataItem.stofffuehrendeRedaktion | enumBeschreibung: StofffuehrendeRedaktionRecord : true
        }}</span
      >
    </ng-template>

    <!-- FSK -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.FSK"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.fsk | enumBeschreibung: FSKEinstufungRecord : false">{{
        dataItem.fsk | enumBeschreibung: FSKEinstufungRecord : false
      }}</span>
    </ng-template>

    <!-- Staffelnummer -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.STAFFELNUMMER"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.staffelnummer">{{ dataItem.staffelnummer }}</span>
    </ng-template>

    <!-- Folgennummer -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.FOLGENNUMMER"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.folgennummer">{{ dataItem.folgennummer }}</span>
    </ng-template>

    <!-- Gesamtfolgennummer -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.GESAMTFOLGENNUMMER"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.gesamtfolgennummer">{{ dataItem.gesamtfolgennummer }}</span>
    </ng-template>

    <!-- Inhaltsbeschreibung -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.INHALTSBESCHREIBUNG"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.inhaltsbeschreibung">{{ dataItem.inhaltsbeschreibung }}</span>
    </ng-template>

    <!-- Mitwirkende -->
    <ng-template
      *ngIf="column.field === GridResultColumnFields.MITWIRKENDE"
      kendoGridCellTemplate
      let-dataItem
    >
      <span [title]="dataItem.mitwirkende">{{ dataItem.mitwirkende }}</span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<kendo-contextmenu #gridContextMenu (select)="onContexMenuSelect($event)">
  <kendo-menu-item text="Details anzeigen" [svgIcon]="Icons.information"></kendo-menu-item>
</kendo-contextmenu>
