import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "monthByIndex",
})
export class MonthByIndexPipe implements PipeTransform {
  transform(monthIndex: number): string {
    return new Date(0, monthIndex).toLocaleString("de", { month: "long" });
  }
}
