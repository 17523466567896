/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type StofffuehrendeRedaktion =
  | "C"
  | "CI"
  | "CPM"
  | "CS"
  | "CT"
  | "CW"
  | "CZE"
  | "PK"
  | "PKJ"
  | "PKM"
  | "PS"
  | "PSF"
  | "PUS"
  | "PUW"
  | "NN";

export const StofffuehrendeRedaktion = {
  C: "C" as StofffuehrendeRedaktion,
  CI: "CI" as StofffuehrendeRedaktion,
  CPM: "CPM" as StofffuehrendeRedaktion,
  CS: "CS" as StofffuehrendeRedaktion,
  CT: "CT" as StofffuehrendeRedaktion,
  CW: "CW" as StofffuehrendeRedaktion,
  CZE: "CZE" as StofffuehrendeRedaktion,
  PK: "PK" as StofffuehrendeRedaktion,
  PKJ: "PKJ" as StofffuehrendeRedaktion,
  PKM: "PKM" as StofffuehrendeRedaktion,
  PS: "PS" as StofffuehrendeRedaktion,
  PSF: "PSF" as StofffuehrendeRedaktion,
  PUS: "PUS" as StofffuehrendeRedaktion,
  PUW: "PUW" as StofffuehrendeRedaktion,
  NN: "NN" as StofffuehrendeRedaktion,
};
