import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AktivitaetDto } from "src/app/models/openapi/model/aktivitaet-dto";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AktivitaetenApiService {
  private apiUrl = this.settings.apiUrl;
  private readonly basePath = "Aktivitaeten";

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getAktivitaeten$(planungsobjektId: string) {
    return this.http.get<AktivitaetDto[]>(
      `${this.apiUrl}${this.basePath}/ByPlanungsobjekt/${planungsobjektId}`,
    );
  }
}
