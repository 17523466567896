/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Anforderung aus:  https://jira.zdf.de/jira/browse/PUBLIT-308
 */
export type ContentCommunity =
  | "CC1"
  | "CC2"
  | "CC3"
  | "CC4"
  | "CC5"
  | "CC6"
  | "CCKids1"
  | "CCKids2"
  | "CCKids3";

export const ContentCommunity = {
  CC1: "CC1" as ContentCommunity,
  CC2: "CC2" as ContentCommunity,
  CC3: "CC3" as ContentCommunity,
  CC4: "CC4" as ContentCommunity,
  CC5: "CC5" as ContentCommunity,
  CC6: "CC6" as ContentCommunity,
  CC_KIDS1: "CCKids1" as ContentCommunity,
  CC_KIDS2: "CCKids2" as ContentCommunity,
  CC_KIDS3: "CCKids3" as ContentCommunity,
};
