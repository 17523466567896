import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { take } from "rxjs";
import { GetitWindowInput } from "src/app/core/stores/getit-window/getit-model";
import { PlanungsobjektLinearVorgeplantErstellenMitGetitCommand } from "src/app/models/openapi/model/planungsobjekt-linear-vorgeplant-erstellen-mit-getit-command";
import { PlanungsobjektVerknuepfenMitGetitCommand } from "src/app/models/openapi/model/planungsobjekt-verknuepfen-mit-getit-command";
import { PublitFrontendSettings } from "src/environments/environment";

export interface GetItImportWindowResult {
  erstellenCommand: PlanungsobjektLinearVorgeplantErstellenMitGetitCommand | null;
  verknuepfenCommand: PlanungsobjektVerknuepfenMitGetitCommand | null;
}

const openPopupCentered = (url: string, w: number, h: number): Window | null => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const openParams = [
    "directories=no",
    "titlebar=no",
    "toolbar=no",
    "location=no",
    "status=no",
    "menubar=no",
    "scrollbars=no",
    "popup",
    `width=${w / systemZoom}`,
    `height=${h / systemZoom}`,
    `top=${top}`,
    `left=${left}`,
  ].join(",");

  return window.open(url, "_blank", openParams);
};

@Component({
  selector: "app-get-it-import-window",
  templateUrl: "./get-it-import-window.component.html",
  styleUrls: ["./get-it-import-window.component.scss"],
})
export class GetItImportWindowComponent implements OnInit {
  @Input() input: GetitWindowInput;

  public form = this.formBuilder.group({
    id: ["", [Validators.required, Validators.minLength(2)]],
  });

  private getitRechercheWindow: Window | null = null;
  private getitBaseUrl: string;
  private getitRechercheEventListener: (event: MessageEvent) => void | undefined;

  constructor(
    private windowRef: WindowRef,
    private formBuilder: FormBuilder,
    private settings: PublitFrontendSettings,
  ) {}

  ngOnInit(): void {
    this.getitBaseUrl = this.settings.produktDbBaseUrl;
    this.getitRechercheEventListener = this.getitRechercheCallback.bind(this);

    this.windowRef.result.pipe(take(1)).subscribe(() => {
      if (this.getitRechercheWindow) {
        this.closeRecherche();
      }
    });
  }

  private closeRecherche() {
    this.getitRechercheWindow?.close();
    window.removeEventListener("message", this.getitRechercheEventListener);
  }

  public close(result: GetItImportWindowResult | undefined = undefined): void {
    this.windowRef.close(result);
  }

  public onSave(): void {
    if (this.input.type === "GetitVerknuepfung") {
      if (this.input.planungsobjektId) {
        const result: GetItImportWindowResult = {
          verknuepfenCommand: {
            getitId: this.form.controls.id.value!,
            planungsobjektId: this.input.planungsobjektId,
          },
          erstellenCommand: null,
        };
        this.close(result);
        return;
      } else {
        throw new Error("PlanungsobjektId ist nicht korrekt gesetzt");
      }
    } else {
      const result: GetItImportWindowResult = {
        erstellenCommand: {
          getitId: this.form.controls.id.value,
          kanal: this.input.kanal,
          kalendertag: this.input.type === "Sendeplatz" ? this.input.kalendertag : null,
          beginnzeit: this.input.beginnzeit,
          laenge: this.input.laenge,
          variante: this.input.type === "Blockansicht" ? this.input.variante : null,
          sendetag: this.input.type === "Blockansicht" ? this.input.sendetag : null,
          isVorschlag: this.input.isVorschlag,
        },
        verknuepfenCommand: null,
      };

      this.close(result);
    }
  }

  recherche() {
    // Ist bereits offen, also wieder in den Fokus holen.
    if (this.getitRechercheWindow && this.getitRechercheWindow.closed === false) {
      this.getitRechercheWindow.focus();
      return;
    }

    const columnsInResultTable = [
      "id",
      "productTitle",
      "partNumber",
      "totalPartNumber",
      "editorialDepartmentInCharge",
      "productStatus",
    ]
      .map((c) => "&filter=" + c)
      .join("");

    const researchParams = "/research?productType=Produkt&external=true";

    const url = `${this.getitBaseUrl}${researchParams}${columnsInResultTable}`;

    const w = 1200;
    const h = 1000;

    this.getitRechercheWindow = openPopupCentered(url, w, h);
    window.addEventListener("message", this.getitRechercheEventListener);
  }

  private getitRechercheCallback(event: MessageEvent) {
    if (!event.origin.startsWith(this.getitBaseUrl)) {
      return;
    }

    // get.it sendet die ID als string, getrennt durch ein Komma
    // ggf. in Zukunft als String Array...
    const data = typeof event.data === "string" ? event.data.split(",") : (event.data as string[]);
    const id = data[0];
    this.form.patchValue({ id });
    this.onSave();
  }
}
