/**
 * Abhängig vom aktuelle angezeigten Bereich der Anwendung, müssen
 * Reload Benachrichtigungen unterschiedlich angezeigt werden und unterschiedliche Aktionen auslösen.
 */
export enum AppPage {
  Ansicht,
  OnDemand,
  // EK,
  // Recherche,
}
