import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import planungsobjektSelectors from "src/app/core/stores/planungsobjekt/planungsobjekt.selectors";

@Pipe({
  name: "planungsobjektStyle",
})
export class PlanungsobjektStylePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(planungsobjektId: string) {
    return this.store.select(planungsobjektSelectors.selectPlanungsobjektStyles(planungsobjektId));
  }
}
