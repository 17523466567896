import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { DateFnsService } from "src/app/services/date-fns.service";
import { ToDatePipe } from "../../pipes/to-date.pipe";
import { AbstractControlValueAccessor } from "../abstract-control-value-accessor";

@Component({
  selector: "app-iso-dateonly",
  templateUrl: "./iso-dateonly.component.html",
  styleUrls: ["./iso-dateonly.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IsoDateonlyComponent),
      multi: true,
    },
  ],
})
export class IsoDateonlyComponent extends AbstractControlValueAccessor<string> {
  /**
   * Das Datum, das fokussiert werden soll, wenn der Kalender geöffnet wird.
   */
  @Input() focusedDate: Date | number | string | null | undefined;
  @Input() maxDate: Date | number | string | null | undefined;
  @Input() minDate: Date | number | string | null | undefined;
  /**
   * Funktion, die ein Datum im Kalender deaktiviert.
   *
   * @returns true, wenn das Datum deaktiviert ist, sonst false.
   */
  @Input() isDateDisabled: (date: Date) => boolean = () => false;

  internalValue: Date | null = null;

  onChange(value: Date | null) {
    const _value = DateFnsService.formatDateAsStringOrNull(value);
    this._onChange(_value);
  }

  writeValue(value: string | null): void {
    this.internalValue = value ? ToDatePipe.toDate(value) : null;
  }
}
