<app-input-label class="u-w-full" [text]="label">
  <kendo-maskedtextbox
    #maskedTextBox
    [attr.data-testid]="dataTestId"
    [formControl]="form"
    [includeLiterals]="true"
    [mask]="mask"
    [maskOnFocus]="false"
    [required]="isRequired"
    [rules]="rules"
    (blur)="onBlur()"
  >
  </kendo-maskedtextbox>
  <kendo-formerror *ngIf="form.errors?.maxExceeded">
    {{ form.errors?.maxExceeded }}
  </kendo-formerror>
</app-input-label>
