import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { DEFAULT_COLOR_SCHEME, LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { OnDemandBeziehungFormEffects } from "../shared/windows/planungsobjekt-window/verlinkung-tab/on-demand-beziehung-form/on-demand-beziehung-form.effects";
import { metaReducers, reducers } from "./stores";
import { AktivitaetenEffects } from "./stores/aktivitaeten/aktivitaeten.effects";
import { AnsichtEffects } from "./stores/ansicht/ansicht.effects";
import { BenachrichtigungEffects } from "./stores/benachrichtigung/benachrichtigung.effects";
import { BeziehungEffects } from "./stores/beziehung/beziehung.effects";
import { BlockansichtEffects } from "./stores/blockansicht/blockansicht.effect";
import { DragDropEffects } from "./stores/dragdrop/dragdrop.effects";
import { DurationEffects } from "./stores/duration/duration.effects";
import { DurationWindowEffects } from "./stores/duration/duration.window.effects";
import { EkWindowEffects } from "./stores/ek-window/ek-window.effects";
import { EventEffects } from "./stores/event/event.effects";
import { GetitWindowEffects } from "./stores/getit-window/getit-window.effects";
import { InitEffects } from "./stores/init/init.effects";
import { KonkurrenzprogrammEffects } from "./stores/konkurrenzprogramm/konkurrenzprogramm.effects";
import { ListenansichtEffects } from "./stores/listenansicht/listenansicht.effects";
import { MehrfachauswahlEffects } from "./stores/mehrfachauswahl/mehrfachauswahl.effects";
import { MengengeruestEffects } from "./stores/mengengeruest/mengengeruest.effects";
import { MengengeruestWindowEffects } from "./stores/mengengeruest/mengengeruest.window.effects";
import { MerklisteEffects } from "./stores/merkliste/merkliste.effects";
import { MerklisteWindowEffects } from "./stores/merkliste/merkliste.window.effects";
import { MetadatenEffects } from "./stores/metadaten/metadaten.effects";
import { MultiansichtEffects } from "./stores/multiansicht/multiansicht.effects";
import { NotificationEffects } from "./stores/notification/notification.effects";
import { OnDemandEffects } from "./stores/on-demand/on-demand.effects";
import { PermissionEffects } from "./stores/permissions/permissions.effects";
import { PlanungsobjektWindowWannWoEffects } from "./stores/planungsobjekt-window/planungsobjekt-window-wann-wo.effects";
import { PlanungsobjektWindowEffects } from "./stores/planungsobjekt-window/planungsobjekt-window.effects";
import { PlanungsobjektVorgeschlagenEffects } from "./stores/planungsobjekt/planungsobjekt-vorgeschlagen.effects";
import { PlanungsobjektBeziehungWindowEffects } from "./stores/planungsobjekt/planungsobjekt.beziehung.window.effects";
import { PlanungsobjektEffects } from "./stores/planungsobjekt/planungsobjekt.effects";
import { PlanungsobjektMerklisteEffects } from "./stores/planungsobjekt/planungsobjekt.merkliste.effects";
import { PlanungshinweisEffects } from "./stores/planunshinweis/planungshinweis.effects";
import { RechercheEffects } from "./stores/recherche/recherche.effects";
import { SendeplatzEffects } from "./stores/sendeplatz/sendeplatz.effects";
import { SendeplatzWindowEffects } from "./stores/sendeplatz/sendeplatz.window.effects";
import { SerienWindowEffects } from "./stores/serien-window/serien.window.effects";
import { ShellEffects } from "./stores/shell/shell.effects";

@NgModule({
  imports: [
    // CommonModule,
    // DragToSelectModule.forRoot(),
    // DateFnsModule.forRoot(),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.INFO,
      // Wird benötigt um Dateinamen/Zeilen im Logger anzuzeigen
      enableSourceMaps: true,
      serverLogLevel: NgxLoggerLevel.INFO,
      colorScheme: DEFAULT_COLOR_SCHEME,
      timestampFormat: "yyyy-MM-dd HH:mm:ss",
      // serverLoggingUrl: `${environment.apiUrl}Log/Frontend`,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateImmutability: true,
        strictStateSerializability: false,
      },
    }),
    // TODO: Nur in Non-Prod Umgebungen verwenden
    // isDebug ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      MetadatenEffects,
      RechercheEffects,
      AnsichtEffects,
      MultiansichtEffects,
      MengengeruestEffects,
      MengengeruestWindowEffects,
      SendeplatzEffects,
      SendeplatzWindowEffects,
      PlanungsobjektEffects,
      PlanungsobjektMerklisteEffects,
      PlanungsobjektVorgeschlagenEffects,
      PlanungsobjektBeziehungWindowEffects,
      BeziehungEffects,
      PlanungsobjektWindowEffects,
      PlanungsobjektWindowWannWoEffects,
      EkWindowEffects,
      GetitWindowEffects,
      SerienWindowEffects,
      SendeplatzWindowEffects,
      EventEffects,
      KonkurrenzprogrammEffects,
      MerklisteEffects,
      MerklisteWindowEffects,
      DurationEffects,
      DurationWindowEffects,
      PlanungsobjektVorgeschlagenEffects,
      BlockansichtEffects,
      PermissionEffects,
      OnDemandEffects,
      DragDropEffects,
      BenachrichtigungEffects,
      MehrfachauswahlEffects,
      PlanungshinweisEffects,
      ShellEffects,
      NotificationEffects,
      ListenansichtEffects,
      AktivitaetenEffects,
      OnDemandBeziehungFormEffects,
      InitEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
  ],
})
export class CoreModule {}
