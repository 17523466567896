import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { PharosTransferTableDto } from "src/app/models/openapi/model/pharos-transfer-table-dto";
import { PrepareTransferToPharosQuery } from "src/app/models/openapi/model/prepare-transfer-to-pharos-query";
import {
  PharosTransferTableViewModel,
  TransferToPharosViewModel,
} from "src/app/models/viewmodels/pharos-viewmodel";
import { DateFnsService } from "src/app/services/date-fns.service";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PharosService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getPharosTransferTable$(
    sendeplatz: PrepareTransferToPharosQuery,
  ): Observable<PharosTransferTableViewModel> {
    return this.http
      .post<PharosTransferTableDto>(this.apiUrl + "Pharos/PrepareTransfer", sendeplatz)
      .pipe(
        map((content) => ({
          fromPharos: {
            ...content.fromPharos,
            laenge: DateFnsService.parseSecondsToTimeString(content.fromPharos.laenge),
          },
          fromPublit: {
            ...content.fromPublit,
            laenge: DateFnsService.parseSecondsToTimeString(content.fromPublit.laenge),
          },
        })),
      );
  }

  updatePharosTransferTable$(pharosTransferTable: TransferToPharosViewModel): Observable<void> {
    return this.http.post<void>(this.apiUrl + "Pharos/Transfer", {
      ...pharosTransferTable,
      laenge: DateFnsService.parseDurationStringToSeconds(pharosTransferTable.laenge),
    });
  }
}
