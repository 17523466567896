import { Pipe, PipeTransform } from "@angular/core";
import { DateFnsService } from "../../services/date-fns.service";

@Pipe({
  name: "parseDate",
})
export class ParseDatePipe implements PipeTransform {
  transform(date: string): string {
    return DateFnsService.parseStringToGermanDateString(date);
  }
}
