import { Pipe, PipeTransform } from "@angular/core";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";

@Pipe({
  name: "testId",
})
export class TestIdPipe implements PipeTransform {
  transform(dto: SendeplatzDto, prefix: string, postfix?: string): string {
    const postfixStr = postfix ? `-${postfix}` : "";
    if (!dto) {
      return `${prefix}-undefined${postfixStr}`;
    }

    return `${prefix}-${dto.kalendertag}-${dto.beginnzeit}${postfixStr}`;
  }
}
