import { Pipe, PipeTransform } from "@angular/core";
import { AppAbility } from "src/app/casl/app-ability";
import { asSubject } from "src/app/casl/casl-utils";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { Role } from "src/app/models/openapi/model/role";
import { assertUnreachable } from "src/app/utils/function-utils";
import {
  PlanungsobjektWindowInputWithPlanungsobjekt,
  PlanungsobjektWindowUseCase,
} from "./planungsobjekt-window.model";

/**
 * @philip - Hier wäre ein Kommentar gut, warum an dieser Stelle die Berechtigungen mit einer
 * eigenen Pipe geprüft werden, und nicht wie an allen anderen Stellen direkt im Template.
 * -> Um die Komplexität des Templates zu reduzieren und die Lesbarkeit zu erhöhen.
 * -> Außerdem können die Berechtigungen an einer zentralen Stelle definiert, gepflegt und getestet werden.
 */
@Pipe({
  name: "canEditPlanungsobjektWindow",
})
export class CanEditPlanungsobjektWindowPipe implements PipeTransform {
  constructor(private ability: AppAbility) {}

  // TODO CurrentPlanungsobjekt als Parameter hinzufügen zum triggern der pipe nach verknüpfung
  transform(windowInput: PlanungsobjektWindowInputWithPlanungsobjekt) {
    switch (windowInput.usecase) {
      case PlanungsobjektWindowUseCase.CREATE_ONDEMAND:
      case PlanungsobjektWindowUseCase.EDIT_ONDEMAND:
        return this.canEditOnDemand(windowInput.planungskontext);
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_SENDEPLATZ:
      case PlanungsobjektWindowUseCase.CREATE_LINEAR_BLOCKANSICHT:
        return (
          this.ability.can("planen", asSubject("Ansicht", {})) ||
          (this.ability.can(Role.PLANUNGSOBJEKT_BEITRAGEN, "Permission") &&
            windowInput.planungskontext === Planungskontext.VORGESCHLAGEN)
        );
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_SENDEPLATZ:
      case PlanungsobjektWindowUseCase.EDIT_LINEAR_BLOCKANSICHT:
        return this.ability.can(
          "bearbeiten",
          asSubject("Planungsobjekt", windowInput.planungsobjekt),
        );
      case PlanungsobjektWindowUseCase.READONLY_ONDEMAND:
      case PlanungsobjektWindowUseCase.READONLY_LINEAR:
        return false;
      default:
        assertUnreachable(windowInput, "Unbekanntes WindowInput: %1");
    }
  }

  private canEditOnDemand(planungskontext: Planungskontext) {
    switch (planungskontext) {
      case Planungskontext.VORGEPLANT:
        return this.ability.can("planen", "OnDemand");
      case Planungskontext.VORGESCHLAGEN:
        return this.ability.can(Role.PLANUNGSOBJEKT_BEITRAGEN, "Permission");
      case Planungskontext.VORGEMERKT:
        return (
          this.ability.can(Role.PLANUNGSOBJEKT_BEITRAGEN, "Permission") ||
          // auf der Merkliste können wir mit MEDIATHEK_PLANEN immer planen,
          // unabhängig davon wie die Geltungsbereiche für diese Rolle konfiguriert sind
          this.ability.can(Role.PLANUNGSOBJEKT_ZDF_MEDIATHEK_PLANEN, "Permission")
        );
      default:
        throw new Error(`Unbekannter Planungskontext: "${planungskontext}" für ondemand`);
    }
  }
}
