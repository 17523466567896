import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs";
import { AktivitaetenApiService } from "src/app/api/aktivitaeten/aktivitaeten.api.service";
import { aktivitaetenActions } from "./aktivitaeten.actions";

@Injectable()
export class AktivitaetenEffects {
  loadAktivitaetenForPlanungsobjekt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(aktivitaetenActions.loadAktivitaetenForPlanungsobjekt),
      switchMap(({ planungsobjektId }) => this.api.getAktivitaeten$(planungsobjektId)),
      map((aktivitaeten) =>
        aktivitaetenActions.loadAktivitaetenForPlanungsobjektSuccess({ aktivitaeten }),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private api: AktivitaetenApiService,
  ) {}
}
