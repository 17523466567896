import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SearchChildrenByIdsQuery } from "src/app/models/openapi/model/search-children-by-ids-query";
import { SearchPlanungsobjekteQuery } from "src/app/models/openapi/model/search-planungsobjekte-query";
import { SearchResultsDto } from "src/app/models/openapi/model/search-results-dto";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RechercheApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  search$(body: SearchPlanungsobjekteQuery): Observable<SearchResultsDto> {
    return this.http.post<SearchResultsDto>(`${this.apiUrl}Planungsobjekt/Search`, body);
  }

  searchChildren$(body: SearchChildrenByIdsQuery): Observable<SearchResultsDto> {
    return this.http.post<SearchResultsDto>(`${this.apiUrl}Planungsobjekt/Search/ByIds`, body);
  }
}
