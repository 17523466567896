import { createSelector } from "@ngrx/store";
import { DrawerItemExpandedFn } from "@progress/kendo-angular-layout";
import { AnsichtenMapper } from "src/app/models/mapper/ansichten.mapper";
import { KalenderansichtMapper } from "src/app/models/mapper/kalenderansicht.mapper";
import { ListenansichtMapper } from "src/app/models/mapper/listenansicht.mapper";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Layout } from "src/app/models/openapi/model/layout";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { groupBy } from "src/app/utils/array-utils";
import { isDefined } from "src/app/utils/object-utils";
import { getQueryParamFromUrl } from "src/app/utils/url-utils";
import { getSendeplatzIntervals } from "../benachrichtigung/benachrichtigung.filters";
import { eventFeature } from "../event/event.reducer";
import eventSelectors from "../event/event.selectors";
import { konkurrenzprogrammFeature } from "../konkurrenzprogramm/konkurrenzprogramm.reducer";
import konkurrenzprogrammSelectors from "../konkurrenzprogramm/konkurrenzprogramm.selectors";
import { multiAnsichtFeature } from "../multiansicht/multiansicht.reducer";
import { planungsobjektFeature } from "../planungsobjekt/planungsobjekt.reducer";
import routerSelectors from "../router/router.selectors";
import { sendeplatzFeature } from "../sendeplatz/sendeplatz.reducer";
import sendeplatzSelectors from "../sendeplatz/sendeplatz.selectors";
import { ansichtFeature } from "./ansicht.reducer";

const selectAnsichtViewModelYearsZDF = createSelector(ansichtFeature.selectAll, (ansichten) => {
  const years = ansichten
    .filter((ansicht) => ansicht.kanal === Kanal.ZDF)
    .map((ansichtViewModel) => ansichtViewModel.year);
  return [...new Set(years)];
});

const selectAnsichtViewModelYearsZDFNeo = createSelector(ansichtFeature.selectAll, (ansichten) => {
  const years = ansichten
    .filter((ansicht) => ansicht.kanal === Kanal.ZDF_NEO)
    .map((ansichtViewModel) => ansichtViewModel.year);
  return [...new Set(years)];
});

const selectAnsichtPossibleYears = createSelector(
  ansichtFeature.selectEntities,
  routerSelectors.selectAnsichtIdQueryParam,
  routerSelectors.selectKanalQueryParam,
  (ansichtenDictionary, ansichtId, kanal) => {
    if (!ansichtenDictionary || !ansichtId) return undefined;

    const currentAnsicht = ansichtenDictionary[ansichtId];
    if (!currentAnsicht) return [];

    const availableYearsForCurrentAnsicht = Object.values(ansichtenDictionary)
      .filter(isDefined)
      .filter((ansicht) => ansicht.titel === currentAnsicht.titel)
      .filter((ansicht) => ansicht.kanal === kanal)
      .map((ansicht) => ansicht.year);
    return availableYearsForCurrentAnsicht;
  },
);

const selectAnsichtViewModelFilteredByYearAndKanal = createSelector(
  ansichtFeature.selectAll,
  routerSelectors.selectYearFromAnyQueryParam,
  routerSelectors.selectKanalQueryParam,
  (ansichtenViewModel, year, kanal) =>
    ansichtenViewModel
      .filter((ansichtViewModel) => ansichtViewModel.year === year)
      .filter((ansichtViewModel) => ansichtViewModel.kanal === kanal),
);

const selectAnsichtViewModelFilteredByYear = createSelector(
  ansichtFeature.selectAll,
  routerSelectors.selectYearFromAnyQueryParam,
  (ansichtenViewModel, year) =>
    ansichtenViewModel.filter((ansichtViewModel) => ansichtViewModel.year === year),
);

/**
 * Wird für die dynamische Erstellung von Ansichten-Links in der Drawer-Komponente verwendet.
 */
const selectAnsichtViewModelForYearByKanal = createSelector(
  selectAnsichtViewModelFilteredByYear,
  (ansichtenViewModel) =>
    groupBy(ansichtenViewModel, (viewmodel: AnsichtViewModel) => viewmodel.kanal),
);

const selectAnsichtViewModelForAnsichtId = (ansichtId: string) => {
  return createSelector(
    ansichtFeature.selectEntities,
    (ansichtenEntities) => ansichtenEntities[ansichtId],
  );
};

const selectAnsichtViewModelForTitelAndYearAndKanal = (
  titel: string,
  year: number,
  kanal: Kanal,
) => {
  return createSelector(ansichtFeature.selectAll, (ansichten) =>
    ansichten.find(
      (ansicht) => ansicht.titel === titel && ansicht.year === year && ansicht.kanal === kanal,
    ),
  );
};

const selectAnsichtViewModelForAnsichtIdFromQueryParam = createSelector(
  ansichtFeature.selectEntities,
  routerSelectors.selectAnsichtIdQueryParam,
  (ansichtenEntities, ansichtId) => (ansichtId ? ansichtenEntities[ansichtId] : undefined),
);

const selectIsBlockansicht = createSelector(
  selectAnsichtViewModelForAnsichtIdFromQueryParam,
  (ansicht) => (ansicht ? ansicht.ansichtsdefinition.layout === Layout.BLOCK : undefined),
);

const selectIsListenansicht = createSelector(
  selectAnsichtViewModelForAnsichtIdFromQueryParam,
  (ansicht) => ansicht?.ansichtsdefinition.layout === Layout.LISTE,
);

const selectAnsichtLayout = createSelector(
  selectAnsichtViewModelForAnsichtIdFromQueryParam,
  (ansicht) => ansicht?.ansichtsdefinition.layout,
);

const selectAnsichtYearDefinition = createSelector(
  multiAnsichtFeature.selectMultiAnsichtViewModel,
  routerSelectors.selectYearQueryParam,
  (multiansichtviewModel, currentYear) => {
    if (!multiansichtviewModel)
      return { visibleYears: [currentYear], possibleYears: [currentYear] };

    const visibleYears = multiansichtviewModel.ansichtViewModels
      .filter((apvm) => apvm.primary === true || apvm.visible === true)
      .map((apvm) => apvm.ansichtViewModel.year)
      .sort();
    const possibleYears = multiansichtviewModel.ansichtViewModels.map(
      (apvm) => apvm.ansichtViewModel.year,
    );
    return { visibleYears, possibleYears };
  },
);

const selectListenansicht = createSelector(
  selectAnsichtYearDefinition,
  sendeplatzSelectors.selectAllSendeplaetzeGroupedByYear,
  multiAnsichtFeature.selectMultiAnsichtViewModel,
  (ansichtYearDefinition, sendeplaetze, multiAnsichtViewModel) =>
    sendeplaetze?.size && multiAnsichtViewModel?.layout === Layout.LISTE
      ? {
          ansichtYearDefinition,
          multiAnsichtViewModel,
          sendeplaetze: ListenansichtMapper.mapSendeplaetzeToVmForListenansicht(
            sendeplaetze,
            multiAnsichtViewModel,
          ),
        }
      : undefined,
);

const selectMultiKalenderansichtHeader = createSelector(
  multiAnsichtFeature.selectMultiAnsichtViewModel,
  (multiAnsichtViewModel) =>
    multiAnsichtViewModel
      ? KalenderansichtMapper.mapMultiAnsichtViewModelToKalenderansichtHeader(multiAnsichtViewModel)
      : undefined,
);

/**
 * Gibt einen Record zurück, der die Sendeplätze zunächst nach Jahren gruppiert.
 * Darin enthalten sind verschachtelte Arrays, die die Sendeplätze nach Monaten, Wochen und Tagen gruppieren.
 * TODO: Refactorn sodass die Arrays zumindest in einem Objekt mit geeigneten Keys (Wie Monat/Woche/Wochentag) gruppiert sind.
 */
const selectKalenderansichtSendeplaetze = createSelector(
  sendeplatzSelectors.selectAllSendeplaetzeGroupedByYear,
  multiAnsichtFeature.selectMultiAnsichtViewModel,
  selectMultiKalenderansichtHeader,
  (sendeplaetzeGroups, multiAnsichtViewModel, multiKalenderansichtHeader) => {
    if (!sendeplaetzeGroups?.size || !multiAnsichtViewModel || !multiKalenderansichtHeader)
      return undefined;

    const sortedWochentage = [...multiKalenderansichtHeader].map((s) => s.wochentag);

    const sendeplaetzeGroupedByYearThenMonthThenWeekThenDay =
      KalenderansichtMapper.mapSendeplaetzeToVmForKalenderansicht(
        sendeplaetzeGroups,
        multiAnsichtViewModel,
        sortedWochentage,
      );

    return sendeplaetzeGroupedByYearThenMonthThenWeekThenDay;
  },
);

/**
 * Aggregiert alle notwendigen Daten für die Kalenderansicht.
 */
const selectKalenderansicht = createSelector(
  selectKalenderansichtSendeplaetze,
  selectAnsichtYearDefinition,
  multiAnsichtFeature.selectMultiAnsichtViewModel,
  selectMultiKalenderansichtHeader,
  (sendeplaetze, yearDefinition, multiAnsichtViewModel, multiKalenderansichtHeader) => {
    if (
      multiAnsichtViewModel?.layout !== Layout.KALENDER ||
      !multiKalenderansichtHeader ||
      !sendeplaetze
    )
      return undefined;

    return {
      sendeplaetze,
      header: multiKalenderansichtHeader,
      yearDefinition,
      multiAnsichtViewModel,
    };
  },
);

const selectEksForEkAnsicht = createSelector(
  routerSelectors.selectYearFromQueryParamOrDefault,
  eventSelectors.selectEventsForYear,
  konkurrenzprogrammSelectors.selectKonkurrenzprogrammeForYear,
  (year, events, konkurrenzprogramme) => {
    return AnsichtenMapper.mapEksToEkGroupMonthViewModel(year, {
      events,
      konkurrenzprogramme,
    });
  },
);

const selectIsLoadingEks = createSelector(
  eventFeature.selectLoading,
  konkurrenzprogrammFeature.selectLoading,
  (loadingEvents, loadingKonkurrenzprogramme) => loadingEvents || loadingKonkurrenzprogramme,
);

const selectAnsichtsdefinition = (ansichtId: string) => {
  return createSelector(
    selectAnsichtViewModelForAnsichtId(ansichtId),
    (ansicht) => ansicht?.ansichtsdefinition.id,
  );
};

const selectAnsichtsdefinitionen = createSelector(ansichtFeature.selectAll, (ansichten) => {
  const uniqueAnsichtsdefinitionIds = new Set<string>();
  return ansichten
    .map((ansicht) => ansicht.ansichtsdefinition)
    .filter((ansichtsdefinition) => {
      if (uniqueAnsichtsdefinitionIds.has(ansichtsdefinition.id)) {
        return false;
      } else {
        uniqueAnsichtsdefinitionIds.add(ansichtsdefinition.id);
        return true;
      }
    });
});

const selectAnsichtLoadingStates = createSelector(
  planungsobjektFeature.selectLoading,
  sendeplatzFeature.selectLoading,
  selectIsLoadingEks,
  (loadingPlanungsobjekt, loadingSendeplaetze, loadingEks) =>
    loadingPlanungsobjekt || loadingSendeplaetze || loadingEks,
);

const selectIsBlockansichtOrEkAnsicht = createSelector(
  routerSelectors.selectEkAnsichtFromUrlPath,
  selectIsBlockansicht,
  (isEkAnsicht, isBlockansicht) => isBlockansicht || isEkAnsicht,
);

const selectMenuBarHierachicalDrawerExpanded = (expandedDrawerIndices: string[]) => {
  return createSelector(
    routerSelectors.selectUrl,
    selectAnsichtViewModelForYearByKanal,
    (url, ansichtViewModel): DrawerItemExpandedFn => {
      return (item: { id: string }) =>
        expandedDrawerIndices.includes(item.id) ||
        (!!url &&
          url.startsWith("/ansichten") &&
          ansichtViewModel[getQueryParamFromUrl(url, "kanal")]?.some((ansicht) =>
            url.includes(ansicht.id),
          ));
    },
  );
};

const selectSendeplatzIntervalsForCurrentAnsichten = createSelector(
  multiAnsichtFeature.selectMultiAnsichtViewModel,
  (multiAnsichtVm) => {
    if (!multiAnsichtVm) {
      return [];
    }

    return multiAnsichtVm.ansichtViewModels
      .filter((publikationVm) => publikationVm.visible)
      .flatMap((publikationVm) => getSendeplatzIntervals(publikationVm.ansichtViewModel));
  },
);

export default {
  selectAnsichten: ansichtFeature.selectAll,
  selectAnsichtLayout,
  selectAnsichtYearDefinition,
  selectAnsichtLoadingStates,
  selectAnsichtPossibleYears,
  selectAnsichtViewModelYearsZDF,
  selectAnsichtViewModelFilteredByYearAndKanal,
  selectAnsichtViewModelForYearByKanal,
  selectAnsichtViewModelForAnsichtId,
  selectAnsichtViewModelForAnsichtIdFromQueryParam,
  selectIsBlockansicht,
  selectIsBlockansichtOrEkAnsicht,
  selectIsListenansicht,
  selectIsLoadingEks,
  selectEksForEkAnsicht,
  selectListenansicht,
  selectMultiKalenderansichtHeader,
  selectKalenderansichtSendeplaetze,
  selectKalenderansicht,
  selectAnsichtsdefinition,
  selectAnsichtsdefinitionen,
  selectMenuBarHierachicalDrawerExpanded,
  selectAnsichtViewModelYearsZDFNeo,
  selectAnsichtViewModelForTitelAndYearAndKanal,
  selectAnsichtViewModelFilteredByYear,
  selectSendeplatzIntervalsForCurrentAnsichten,
};
