<section class="information-content">
  <ng-container *ngIf="{ tech: Role.TECHNISCHER_ADMINISTRATOR | able: 'Permission' } as can">
    <ng-container
      *ngIf="{
        permissions: permissions$ | async,
        ansichtsdefinitionen: ansichtsdefinitionen$ | async
      } as data"
    >
      <p *ngIf="!data.permissions?.length">Eigene Nutzerrollen: Keine</p>
      <p *ngIf="data.permissions?.length">
        Eigene Nutzerrollen:
        <span *ngFor="let permission of data.permissions; let lastPermission = last">
          {{ permission.role }}{{ getGeltungsbereiche(permission, data.ansichtsdefinitionen)
          }}<span *ngIf="!lastPermission">, </span>
        </span>
      </p>
    </ng-container>
  </ng-container>

  <p class="k-mt-4">
    aktuelle Version: {{ buildVersion$ | async }}
    <a
      class="k-text-primary k-text-nowrap"
      href="https://jira.zdf.de/confluence/display/PUBLIT/Release+Notes"
      rel="noopener noreferrer"
      target="_blank"
    >
      <kendo-svg-icon [icon]="Icons.hyperlinkOpenIcon"></kendo-svg-icon>
      Release Notes</a
    >
  </p>

  <p class="k-mt-4">
    Ausführliche Beschreibungen zu Funktionalitäten von publish.it befinden sich in der
    <a
      class="k-text-primary k-text-nowrap"
      href="https://jira.zdf.de/confluence/display/PUBLIT/Anwenderdokumentation"
      rel="noopener noreferrer"
      target="_blank"
      ><kendo-svg-icon [icon]="Icons.hyperlinkOpenIcon"></kendo-svg-icon> Anwenderdokumentation</a
    >
  </p>

  <p class="k-mt-4">
    Sollten Fragen, Wünsche, Anregungen zu publish.it bestehen, kann
    <a class="k-text-primary k-text-nowrap" href="mailto:wilk.d@zdf.de"
      ><kendo-svg-icon [icon]="Icons.email"></kendo-svg-icon> Wilk, Dana</a
    >
    kontaktiert werden. Gleiches gilt für Fehlerfälle. Alternativ können auch die entsprechenden
    Kanäle im publish.it MS Teams genutzt werden.
  </p>

  <button
    *ngIf="Role.TECHNISCHER_ADMINISTRATOR | able: 'Permission'"
    class="k-mt-4 u-w-full"
    kendoButton
    themeColor="warning"
    type="button"
    [svgIcon]="Icons.trash"
    (click)="clearCache()"
  >
    Cache leeren
  </button>

  <p class="copyright k-mb-4 k-mt-6 k-px-4 k-py-3 k-rounded k-text-center">
    © Copyright 2022 - Alle Inhalte dieser Software sind geschützt. Das ZDF ist ausschließlicher
    Rechteinhaber, soweit nicht ausdrücklich anders gekennzeichnet. Alle Rechte bleiben vorbehalten.
  </p>
</section>
