import { Pipe, PipeTransform } from "@angular/core";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { DateFnsService } from "src/app/services/date-fns.service";

@Pipe({
  name: "ekTooltip",
})
export class EKTooltipPipe implements PipeTransform {
  transform(ek: KonkurrenzprogrammDto | EventDto): string {
    if (ek.vonDatum === ek.bisDatum) {
      if (ek.vonZeit && ek.bisZeit) return ek.vonZeit + "-" + ek.bisZeit;
    } else {
      if (ek.vonZeit && ek.bisZeit && ek.bisDatum) {
        return (
          DateFnsService.parseStringToGermanDateString(ek.vonDatum) +
          " " +
          ek.vonZeit +
          " -\n" +
          DateFnsService.parseStringToGermanDateString(ek.bisDatum) +
          " " +
          ek.bisZeit
        );
      }
    }
    return "";
  }
}
