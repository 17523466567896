import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Observable, map, startWith } from "rxjs";
import { ContentCommunityOptions } from "src/app/models/enums/content-community";
import { SelectOption, toSelectOptionRecord } from "src/app/models/enums/enum-base";
import { GenreOptions } from "src/app/models/enums/genre";
import { KanalOptions } from "src/app/models/enums/kanal";
import { PlanungskontextOptions } from "src/app/models/enums/planungskontext";
import { RedaktionOptions } from "src/app/models/enums/redaktion";
import { ArrayProperties } from "src/app/utils/array-utils";
import { SearchForm } from "../search-form/search-form.component";

type SearchFormValue = ReturnType<SearchForm["getRawValue"]>;

type FilterObject<T extends object> = {
  key: ArrayProperties<T>;
  value: string;
  displayValue: string;
};

export type SearchFormFilterObject = FilterObject<SearchFormValue>;

@Component({
  selector: "app-filter-chip-list",
  templateUrl: "./filter-chip-list.component.html",
  styleUrls: ["./filter-chip-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterRowComponent implements OnInit {
  @Input({ required: true }) searchForm: SearchForm;
  @Output() filterRemoved = new EventEmitter<SearchFormFilterObject>();

  filterValues$: Observable<SearchFormFilterObject[]>;

  // Übersetzen zwischen den technischen Enum-Werten und den für den Anwender angezeigten SelectOptions
  translations = {
    genres: toSelectOptionRecord(GenreOptions),
    redaktionen: toSelectOptionRecord(RedaktionOptions),
    planungskontext: toSelectOptionRecord(PlanungskontextOptions),
    contentCommunities: toSelectOptionRecord(ContentCommunityOptions),
    kanaele: toSelectOptionRecord(KanalOptions),
  } satisfies Record<ArrayProperties<SearchFormValue>, null | Record<string, SelectOption<any>>>;

  ngOnInit(): void {
    this.filterValues$ = this.searchForm.valueChanges.pipe(
      startWith(this.searchForm.value),
      map((searchValue) => this.getFiltersFromSearchForm(searchValue)),
    );
  }

  onRemove(filter: SearchFormFilterObject) {
    this.filterRemoved.emit(filter);
  }

  private getFiltersFromSearchForm(
    searchValue: Partial<SearchFormValue>,
  ): SearchFormFilterObject[] {
    const filters: SearchFormFilterObject[] = [];

    Object.entries(searchValue).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        const _key = key as ArrayProperties<SearchFormValue>;
        const _values: string[] = values;
        const _translations = this.translations[_key];
        _values.forEach((value) => {
          filters.push({
            key: _key,
            value,
            displayValue: _translations ? _translations[value].text : value,
          });
        });
      }
    });
    return filters;
  }
}
