<ng-container
  *ngIf="{
    selectedTab: Kanal.ZDF
  } as vm"
>
  <kendo-tabstrip class="dashboard-tab-header" [keepTabContent]="false">
    <kendo-tabstrip-tab [selected]="vm.selectedTab === Kanal.ZDF" [title]="Kanal.ZDF">
      <ng-template kendoTabTitle>
        <div class="kendo-tab-title" data-testid="zdf-tab" title="ZDF">ZDF</div>
      </ng-template>
      <ng-template kendoTabContent>
        <app-dashboard-zdf></app-dashboard-zdf>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [selected]="vm.selectedTab === Kanal.ZDF_NEO" [title]="Kanal.ZDF_NEO">
      <ng-template kendoTabTitle>
        <div class="kendo-tab-title" data-testid="zdf-tab" title="ZDF">ZDFneo</div>
      </ng-template>
      <ng-template kendoTabContent>
        <app-dashboard-zdfneo></app-dashboard-zdfneo>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</ng-container>
