import { Component, Input } from "@angular/core";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektWindowUseCase, Publikationsart } from "../../planungsobjekt-window.model";

@Component({
  selector: "app-wann-bezug",
  templateUrl: "./wann-bezug.component.html",
  styleUrls: ["./wann-bezug.component.scss"],
})
export class WannBezugComponent {
  @Input({ required: true }) publikationsart: Publikationsart;
  @Input({ required: true }) usecase: PlanungsobjektWindowUseCase;
  @Input({ required: true }) planungskontext: Planungskontext;
}
