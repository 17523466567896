import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { AktivitaetDto } from "src/app/models/openapi/model/aktivitaet-dto";

export const aktivitaetenActions = createActionGroup({
  source: "Aktivitaeten API",
  events: {
    loadAktivitaetenForPlanungsobjekt: props<{ planungsobjektId: string }>(),
    loadAktivitaetenForPlanungsobjektSuccess: props<{
      aktivitaeten: AktivitaetDto[];
    }>(),
    loadAktivitaetenForPlanungsobjektFailure: emptyProps(),
  },
});
