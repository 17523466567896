<kendo-tilelayout-item
  #target="kendoContextMenuTargetContainer"
  kendoContextMenuTargetContainer
  [col]="position.col"
  [colSpan]="position.colSpan"
  [ngClass]="ansichtViewModel ? 'planungsansicht-tile' : 'planungsansicht-tile-disabled'"
  [row]="position.row"
  [rowSpan]="position.rowSpan"
  (click)="gotoAnsicht()"
>
  <kendo-tilelayout-item-header class="k-card-title" kendoContextMenuTarget>{{
    title
  }}</kendo-tilelayout-item-header>
  <kendo-tilelayout-item-body kendoContextMenuTarget>
    <ng-container *ngFor="let schemaplatz of schemaplaetze">
      <p>{{ schemaplatz }}</p>
    </ng-container>
  </kendo-tilelayout-item-body>
</kendo-tilelayout-item>

<kendo-contextmenu
  showOn="contextmenu"
  style="display: none"
  [target]="target"
  (select)="onContextMenuSelect($event)"
>
  <kendo-menu-item
    text="Mengengerüst"
    [disabled]="
      !this.ansichtViewModel || !this.ansichtViewModel.ansichtsdefinition.isMengengeruestErlaubt
    "
    [svgIcon]="Icons.balance"
  ></kendo-menu-item
></kendo-contextmenu>
