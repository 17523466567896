import { Component, EventEmitter, Input, Output } from "@angular/core";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import { SenderPlattform } from "src/app/models/openapi/model/sender-plattform";

@Component({
  selector: "app-konkurrenzprogramm",
  templateUrl: "./konkurrenzprogramm.component.html",
  styleUrls: ["./konkurrenzprogramm.component.scss"],
})
export class KonkurrenzprogrammComponent {
  @Input() konkurrenzprogramm: KonkurrenzprogrammDto;
  @Input() last: boolean;
  @Output() konkurrenzprogrammDblClicked = new EventEmitter<{
    event: MouseEvent;
    isEvent: boolean;
    konkurrenzEvent: KonkurrenzprogrammDto;
  }>();

  SenderPlattform = SenderPlattform;

  protected dblClick(event: MouseEvent, isEvent: boolean) {
    this.konkurrenzprogrammDblClicked.emit({
      event,
      isEvent,
      konkurrenzEvent: this.konkurrenzprogramm,
    });
  }
}
