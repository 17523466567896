import { createSelector } from "@ngrx/store";
import { isDefined } from "src/app/utils/object-utils";
import {
  planungsobjektComparer,
  planungsobjektFeature,
} from "../planungsobjekt/planungsobjekt.reducer";
import { mehrfachauswahlFeature } from "./mehrfachauswahl.reducer";

const selectIsDraggingMultiple = createSelector(
  mehrfachauswahlFeature.selectPlanungsobjekte,
  (planungsobjekte) => Object.keys(planungsobjekte).length > 1,
);

const selectPlanungsobjekteIdsInMehrfachauswahl = createSelector(
  mehrfachauswahlFeature.selectPlanungsobjekte,
  (planungsobjekte) => new Set(Object.keys(planungsobjekte)),
);

const selectPlanungsobjekteFromIdsInMehrfachauswahl = createSelector(
  selectPlanungsobjekteIdsInMehrfachauswahl,
  planungsobjektFeature.selectEntities,
  (planungsobjekteIdsInMehrfachauswahl, planungsobjekte) => {
    const filteredPlanungsobjekte = [...planungsobjekteIdsInMehrfachauswahl]
      .map((id) => planungsobjekte[id])
      .filter(isDefined);

    return filteredPlanungsobjekte.sort(planungsobjektComparer);
  },
);

const selectHasPlanungsobjektFromMerklisteSelected = createSelector(
  selectPlanungsobjekteFromIdsInMehrfachauswahl,
  (planungsobjekte) =>
    planungsobjekte.some((planungsobjektf) => planungsobjektf?.merklisteId !== null),
);

const selectPlanungsobjektDragDisabled = (planungsobjektId: string) => {
  return createSelector(
    mehrfachauswahlFeature.selectMehrfachauswahlActive,
    selectPlanungsobjekteIdsInMehrfachauswahl,
    (mehrfachauswahlActive, planungsobjekteIdsInMehrfachauswahl) => {
      const planungsobjektNotFromMehrfachauswahl =
        planungsobjekteIdsInMehrfachauswahl.size > 0 &&
        !planungsobjekteIdsInMehrfachauswahl.has(planungsobjektId);
      return mehrfachauswahlActive || planungsobjektNotFromMehrfachauswahl;
    },
  );
};

export default {
  selectIsDraggingMultiple,
  selectPlanungsobjekteIdsInMehrfachauswahl,
  selectPlanungsobjekteFromIdsInMehrfachauswahl,
  selectHasPlanungsobjektFromMerklisteSelected,
  selectPlanungsobjektDragDisabled,
};
