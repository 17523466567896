import { createFeature, createReducer, on } from "@ngrx/store";
import { FeatureKey } from "../feature.keys";
import { mehrfachauswahlActions } from "./mehrfachauswahl.actions";

export type MehrfachauswahlState = {
  mehrfachauswahlActive: boolean;
  selectionOnGoing: boolean;
  planungsobjekte: Record<string, string>;
};

const initialMehrfachauswahlState: MehrfachauswahlState = {
  mehrfachauswahlActive: false,
  selectionOnGoing: false,
  planungsobjekte: {},
};

export const mehrfachauswahlFeature = createFeature({
  name: FeatureKey.Mehrfachauswahl,
  reducer: createReducer(
    initialMehrfachauswahlState,
    on(
      mehrfachauswahlActions.setPlanungsobjekteInMehrfachauswahl,
      (currentState, { planungsobjektIds }): MehrfachauswahlState => {
        const newPlanungsobjekte: { [key: string]: string } = {};
        for (const id of planungsobjektIds) {
          newPlanungsobjekte[id] = id;
        }
        return {
          ...currentState,
          planungsobjekte: newPlanungsobjekte,
        };
      },
    ),
    on(
      mehrfachauswahlActions.selectPlanungsobjektToMehrfachauswahl,
      (currentState, { planungsobjektId }): MehrfachauswahlState => {
        return {
          ...currentState,
          planungsobjekte: {
            ...currentState.planungsobjekte,
            [planungsobjektId]: planungsobjektId,
          },
        };
      },
    ),
    on(
      mehrfachauswahlActions.toggleMehrfachauswahl,
      (state, { active }): MehrfachauswahlState => ({
        ...state,
        mehrfachauswahlActive: active,
      }),
    ),
    on(
      mehrfachauswahlActions.deactivateMehrfachauswahl,
      (state): MehrfachauswahlState => ({
        ...state,
        mehrfachauswahlActive: false,
      }),
    ),
    on(
      mehrfachauswahlActions.removePlanungsobjektFromMehrfachauswahl,
      (state, { planungsobjektId }): MehrfachauswahlState => {
        const planungsobjekteCopy = { ...state.planungsobjekte };
        delete planungsobjekteCopy[planungsobjektId];
        return {
          ...state,
          planungsobjekte: planungsobjekteCopy,
        };
      },
    ),
    on(
      mehrfachauswahlActions.updatePlanungsobjekteInMehrfachauswahl,
      (state, { planungsobjekteToAdd, planungsobjekteToRemove }): MehrfachauswahlState => {
        const planungsobjekteCopy = { ...state.planungsobjekte };
        for (const id of planungsobjekteToAdd) {
          planungsobjekteCopy[id] = id;
        }

        for (const id of planungsobjekteToRemove) {
          delete planungsobjekteCopy[id];
        }
        return {
          ...state,
          planungsobjekte: planungsobjekteCopy,
        };
      },
    ),
    on(
      mehrfachauswahlActions.removeAllPlanungsobjekteFromMehrfachauswahl,
      (state): MehrfachauswahlState => ({
        ...state,
        planungsobjekte: {},
      }),
    ),
    on(
      mehrfachauswahlActions.startMehrfachauswahlSelection,
      (state): MehrfachauswahlState => ({
        ...state,
        selectionOnGoing: true,
      }),
    ),
    on(
      mehrfachauswahlActions.stopMehrfachauswahlSelection,
      (state): MehrfachauswahlState => ({
        ...state,
        selectionOnGoing: false,
      }),
    ),
  ),
});
