import { OnDemandFilter } from "../openapi/model/on-demand-filter";
import { EnumMetadata, enumRecordToSelectOptions } from "./enum-base";

export const OnDemandFilterRecord: Record<OnDemandFilter, EnumMetadata> = {
  Unbekannt: { short: "Unbekannt", bedeutung: "Unbekannt" },
  Fiction: { bedeutung: "Fiction" },
  Doku: { bedeutung: "Doku" },
  Show: { bedeutung: "Show" },
};

export const OnDemandFilterOptions = enumRecordToSelectOptions(OnDemandFilterRecord).filter(
  (option) => option.value !== OnDemandFilter.UNBEKANNT,
);
