export function isEnumValue<T extends string>(value: string, enumValues: T[]): value is T {
  return enumValues.includes(value as T);
}

export function toEnumArray<T extends string>(
  value: string[] | string | null | undefined,
  enumAsStringArray: T[],
): T[] {
  if (value === null || value === undefined) {
    return [];
  }
  if (Array.isArray(value)) {
    return value.filter((enumValue): enumValue is T => enumAsStringArray.includes(enumValue as T));
  }

  return isEnumValue(value, enumAsStringArray) ? [value] : [];
}

export function stringToEnum<T extends Record<string, string>>(
  str: string | undefined | null,
  enumObj: T,
): T[keyof T] | undefined {
  if (!str || typeof str !== "string") {
    return undefined;
  }

  if (!enumObj || typeof enumObj !== "object") {
    return undefined;
  }

  const enumValues = Object.values(enumObj);
  const enumKey = enumValues.find((key) => key === str);
  return enumKey as T[keyof T] | undefined;
}
