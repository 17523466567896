import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { DateFnsService } from "../../../services/date-fns.service";
import { maxSecondsForMaskedInput } from "../../form-inputs/masked-input/masked-input.component";

export interface SendeplatzWindowResult {
  sendeplatz: SendeplatzDto;
}

@Component({
  selector: "app-sendeplatz-window",
  templateUrl: "./sendeplatz-window.component.html",
  styleUrls: ["./sendeplatz-window.component.scss"],
})
export class SendeplatzWindowComponent implements OnInit {
  @Input() sendeplatz: SendeplatzDto;

  formGroup: ReturnType<typeof this.initializeForm>;

  constructor(
    private windowRef: WindowRef,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.formGroup = this.initializeForm();
  }

  private initializeForm() {
    const formGroup = this.formBuilder.group({
      abweichendeBeginnzeit: [
        this.sendeplatz.abweichendeBeginnzeit
          ? DateFnsService.parseDateAndTimeToDateObject(
              this.sendeplatz.abweichendeBeginnzeit,
              this.sendeplatz.kalendertag,
            )
          : null,
      ],
      abweichendeLaenge: [
        this.sendeplatz.abweichendeLaenge,
        Validators.max(maxSecondsForMaskedInput),
      ],
      notiz: [this.sendeplatz.notiz],
    });

    return formGroup;
  }

  public onCancelAction(): void {
    this.windowRef.close();
  }

  public onConfirmAction(): void {
    if (this.formGroup.pristine) {
      this.windowRef.close();
      return;
    }

    const abweichendeBeginnzeit = this.formGroup.value.abweichendeBeginnzeit
      ? DateFnsService.formatDateAsTimeString(this.formGroup.value.abweichendeBeginnzeit)
      : null;

    const abweichendeLaenge = this.formGroup.value.abweichendeLaenge || null;

    const sendeplatzWindowResult: SendeplatzWindowResult = {
      sendeplatz: {
        ...this.sendeplatz,
        abweichendeBeginnzeit,
        abweichendeLaenge,
        notiz: this.formGroup.value.notiz ?? "",
      },
    };
    this.windowRef.close(sendeplatzWindowResult);
  }
}
