import { createFeature, createReducer, on } from "@ngrx/store";
import { SidebarTabEnum } from "src/app/models/enums/ansichten-sidebar";
import { FeatureKey } from "../feature.keys";
import { sidebarActions } from "./sidebar.actions";

export type SidebarState = {
  sidebarExpanded: boolean;
  showKonkurrenzEvents: boolean;
  showNotizen: boolean;
  activeTab: SidebarTabEnum;
};

const initialState: SidebarState = {
  sidebarExpanded: false,
  // E&Ks sollen per default immer angezeigt werden
  showKonkurrenzEvents: true,
  // Notizen sollen per default immer ausgeblendet werden
  showNotizen: false,
  activeTab: SidebarTabEnum.Weiteres,
};

export const sidebarFeature = createFeature({
  name: FeatureKey.Sidebar,
  reducer: createReducer(
    initialState,
    on(sidebarActions.expandSidebar, (currentState, action): SidebarState => {
      //Setze oder toggle den Wert
      return {
        ...currentState,
        sidebarExpanded: action.expanded ? action.expanded : !currentState.sidebarExpanded,
      };
    }),
    on(sidebarActions.toggleKonkurrenzEvents, (currentState): SidebarState => {
      return { ...currentState, showKonkurrenzEvents: !currentState.showKonkurrenzEvents };
    }),
    on(sidebarActions.toggleNotizen, (currentState): SidebarState => {
      return { ...currentState, showNotizen: !currentState.showNotizen };
    }),
    on(sidebarActions.resetSidebarToDefaultTab, (currentState): SidebarState => {
      return { ...currentState, sidebarExpanded: false, activeTab: SidebarTabEnum.Weiteres };
    }),
    on(sidebarActions.setActiveSidebarTab, (currentState, action): SidebarState => {
      return { ...currentState, activeTab: action.newTab };
    }),
    on(sidebarActions.openMehrfachauswahlTab, (currentState): SidebarState => {
      return { ...currentState, sidebarExpanded: true, activeTab: SidebarTabEnum.Mehrfachauswahl };
    }),
  ),
});
