import { AuthConfig } from "angular-oauth2-oidc";
import { FrontendAuthConfiguration } from "./models/openapi/model/frontend-auth-configuration";

export const getAuthCodeFlowConfig = (configuration: FrontendAuthConfiguration): AuthConfig => ({
  issuer: configuration.authority,
  redirectUri: configuration.redirectUri,
  clientId: configuration.clientId,
  responseType: "code",
  scope: configuration.apiScope,
  showDebugInformation: false,
  timeoutFactor: 0.75,
  checkOrigin: false,
  // Wegen Azure AD B2C
  // https://dev.to/yuriburger/azure-active-directory-b2c-with-pkce-for-your-angular-app-1dcg
  strictDiscoveryDocumentValidation: false,
  skipIssuerCheck: true,
  // Damit wir nach dem Login wieder auf der gleichen Seite landen
  // Sonst funktioniert der Öffnen neuer Tabs nicht mehr
  preserveRequestedRoute: true,
});
