<div class="search-form-container">
  <form autocomplete="off" [formGroup]="searchForm">
    <div class="filter-row">
      <!-- https://www.telerik.com/kendo-angular-ui/components/dateinputs/daterange/ -->
      <!-- https://www.telerik.com/kendo-angular-ui/components/dateinputs/daterange/selection-range/ -->
      <kendo-daterange class="u-w-auto">
        <app-form-field label="Zeitraum von">
          <kendo-dateinput
            autoCorrectOn="blur"
            data-testid="recherche-suchen-von"
            formControlName="sendetagVon"
            kendoDateRangeStartInput
          ></kendo-dateinput>
        </app-form-field>
        <app-form-field label="bis">
          <kendo-dateinput
            autoCorrectOn="blur"
            data-testid="recherche-suchen-bis"
            formControlName="sendetagBis"
            kendoDateRangeEndInput
          ></kendo-dateinput>
        </app-form-field>
        <!-- Für "Heute" ansonsten "today" angezeigt,
          obwohl das Control an allen anderen Stellen korrekt lokalisiert ist -->
        <!-- https://www.telerik.com/kendo-angular-ui/components/dateinputs/globalization/ -->
        <kendo-daterange-popup>
          <ng-template kendoDateRangePopupTemplate>
            <kendo-multiviewcalendar kendoDateRangeSelection>
              <kendo-multiviewcalendar-messages today="Heute"></kendo-multiviewcalendar-messages>
            </kendo-multiviewcalendar>
          </ng-template>
        </kendo-daterange-popup>
      </kendo-daterange>

      <app-multiselect
        class="u-w-full"
        data-testid="recherche-ausspielweg"
        formControlName="kanaele"
        label="Ausspielweg"
        [options]="KanalOptions"
        [summaryTagMaxItems]="0"
      ></app-multiselect>

      <app-multiselect
        class="u-w-full"
        data-testid="recherche-redaktion"
        formControlName="redaktionen"
        label="planende Redaktion"
        [options]="RedaktionOptions"
        [summaryTagMaxItems]="0"
      >
      </app-multiselect>

      <div class="publit-checkbox checkbox">
        <input
          #highlightsOnly
          data-testid="recherche-highlight"
          formControlName="highlightsOnly"
          kendoCheckBox
          type="checkbox"
        />
        <kendo-label
          class="u-text-white"
          text="Nur Highlights"
          [for]="highlightsOnly"
        ></kendo-label>
      </div>

      <!-- Weitere Filter auswählen -->

      <app-additional-filters
        [selectedFilters]="activeAdditionalFilters"
        (selectionChange)="onFilterSelectionChange($event)"
      ></app-additional-filters>

      <button
        class="primary-button"
        data-testid="recherche-suchen-button"
        kendoButton
        themeColor="primary"
        type="button"
        [disabled]="!searchForm.valid"
        (click)="onClickSearch()"
      >
        Suchen
      </button>
    </div>

    <!-- Weitere Filter -->
    <div class="filter-row">
      <app-additional-filter-wrapper
        *ngIf="activeAdditionalFiltersRecord.TITEL"
        data-testid="recherche-titel-filter"
        (removeFilter)="onRemoveFilter(FilterEnum.TITEL)"
      >
        <app-form-field class="u-w-full" label="Titel">
          <kendo-textbox class="u-w-full" formControlName="titel"></kendo-textbox>
        </app-form-field>
      </app-additional-filter-wrapper>
      <app-additional-filter-wrapper
        *ngIf="activeAdditionalFiltersRecord.GENRE"
        data-testid="recherche-genre-filter"
        (removeFilter)="onRemoveFilter(FilterEnum.GENRE)"
      >
        <app-multiselect
          class="u-w-full"
          formControlName="genres"
          label="PLP Genres"
          [options]="GenreOptions"
          [summaryTagMaxItems]="0"
        >
        </app-multiselect>
      </app-additional-filter-wrapper>
      <app-additional-filter-wrapper
        *ngIf="activeAdditionalFiltersRecord.PLANUNGSKONTEXT"
        data-testid="recherche-planungskontext-filter"
        (removeFilter)="onRemoveFilter(FilterEnum.PLANUNGSKONTEXT)"
      >
        <app-multiselect
          class="u-w-full"
          formControlName="planungskontext"
          label="Planungskontext"
          [options]="PlanungskontextOptions"
          [summaryTagMaxItems]="0"
        >
        </app-multiselect>
      </app-additional-filter-wrapper>
      <app-additional-filter-wrapper
        *ngIf="activeAdditionalFiltersRecord.CONTENT_COMMUNITY"
        data-testid="recherche-contentCommunities-filter"
        (removeFilter)="onRemoveFilter(FilterEnum.CONTENT_COMMUNITY)"
      >
        <app-multiselect
          class="u-w-full"
          formControlName="contentCommunities"
          label="Zielgruppe"
          [options]="ContentCommunityOptions"
          [summaryTagMaxItems]="0"
        >
        </app-multiselect>
      </app-additional-filter-wrapper>
    </div>
  </form>

  <div class="vertical-spacer"></div>

  <app-filter-chip-list
    [searchForm]="searchForm"
    (filterRemoved)="onRemoveQueryProperty($event)"
  ></app-filter-chip-list>

  <div class="vertical-spacer"></div>

  <span *ngIf="!isFirstSearch && isSearchFormDirty" class="u-text-white text-trigger-search"
    >Filter haben sich geändert. "SUCHEN" drücken, um anhand dieser Kriterien zu filtern.</span
  >
</div>
