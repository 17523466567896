import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { ContextMenuTargetContainerDirective } from "@progress/kendo-angular-menu";
import { Observable, of } from "rxjs";
import { mehrfachauswahlFeature } from "src/app/core/stores/mehrfachauswahl/mehrfachauswahl.reducer";
import mehrfachauswahlSelectors from "src/app/core/stores/mehrfachauswahl/mehrfachauswahl.selectors";
import planungsobjektSelectors from "src/app/core/stores/planungsobjekt/planungsobjekt.selectors";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SchemaplatzDto } from "src/app/models/openapi/model/schemaplatz-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { DebugService } from "src/app/services/debug.service";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";

@Component({
  selector: "app-planungsobjekt-vorgeplant-list",
  templateUrl: "./planungsobjekt-vorgeplant-list.component.html",
  styleUrls: ["./planungsobjekt-vorgeplant-list.component.scss"],
})
export class PlanungsobjektVorgeplantListComponent implements OnChanges {
  @Input() planungsobjekteInMehrfachauswahl: PlanungsobjektDto[];
  @Input() sendeplatz: SendeplatzDto;
  @Input() schemaplatz: SchemaplatzDto;
  @Input() ansichtViewModel: AnsichtViewModel;
  @Input() target: ContextMenuTargetContainerDirective;

  // Werden nur an planungsobjekt.component weitergeleitet
  @Input() isMengengeruestErlaubt: boolean;

  planungsobjekte$: Observable<PlanungsobjektDto[]>;
  isMultipleDragging$ = this.store.select(mehrfachauswahlSelectors.selectIsDraggingMultiple);
  mehrfachauswahlActive$ = this.store.select(mehrfachauswahlFeature.selectMehrfachauswahlActive);

  SendeplatzUtils = SendeplatzUtils;

  constructor(
    private store: Store,
    protected debugService: DebugService,
  ) {}

  ngOnChanges(_changes: SimpleChanges): void {
    const sendeplatzKey = SendeplatzUtils.getSendeplatzKey(this.sendeplatz);
    if (sendeplatzKey) {
      this.planungsobjekte$ = this.store.select(
        planungsobjektSelectors.selectPlanungsobjekteVorgeplantForSendeplatz(sendeplatzKey),
      );
    } else {
      this.planungsobjekte$ = of([]);
    }
  }
}
