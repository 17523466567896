import { createSelector } from "@ngrx/store";
import { RechercheMapper } from "src/app/models/mapper/recherche.mapper";
import {
  ActiveGridColumnProperties,
  defaultRechercheGridColumnOrder,
  RechercheGridFormattingOptionsVM,
  RechercheGridResultColumn,
} from "./recherche.model";
import { rechercheFeature } from "./recherche.reducer";
import {
  getQueryFromState,
  rechercheCheckDisabledConditions,
  rechercheHiddenConditions,
  sortColumnsByFieldOrder,
} from "./recherche.utils";

const selectSearchQueryVM = createSelector(rechercheFeature.selectRechercheState, (state) =>
  getQueryFromState(state),
);

const selectShownGridColumnsSorted = createSelector(
  rechercheFeature.selectShownColumns,
  rechercheFeature.selectKanaeleSelected,
  rechercheFeature.selectColumnProperties,
  (shownColumns, kanaeleSelected, columnProperties) => {
    let shownGridColumns: ActiveGridColumnProperties[] = [];
    const correctOrder = shownColumns.length ? shownColumns : defaultRechercheGridColumnOrder;
    const sortedShownColumns = sortColumnsByFieldOrder(columnProperties, correctOrder);
    shownGridColumns = sortedShownColumns.map(
      (column) =>
        ({
          ...column,
          hidden: rechercheHiddenConditions(shownColumns, column.field, kanaeleSelected),
          disabled: rechercheCheckDisabledConditions(column.field, kanaeleSelected),
        }) as ActiveGridColumnProperties,
    );
    return shownGridColumns;
  },
);

const selectRechercheResultColumns = createSelector(
  selectShownGridColumnsSorted,
  (shownColumns) => {
    const acc: Partial<Record<RechercheGridResultColumn, ActiveGridColumnProperties>> = {};
    shownColumns.forEach((column) => {
      acc[column.field] = column;
    });
    return acc;
  },
);

const selectSearchQueryAndFormattingParams = createSelector(
  selectSearchQueryVM,
  rechercheFeature.selectShownColumns,
  (query, shownColumns) => {
    const formattingOptions: RechercheGridFormattingOptionsVM = {
      shownColumns,
    };
    return { query, formattingOptions };
  },
);

const selectSearchResults = createSelector(
  rechercheFeature.selectExpandedIds,
  rechercheFeature.selectIdsWithVarianten,
  rechercheFeature.selectResults,
  rechercheFeature.selectResultIds,
  (expandedIds, idsWithVarianten, planungsobjekte, resultIds) => {
    // PlanungsobjekteDto umwandeln in RechercheSearchResultVM
    return RechercheMapper.mapPlanungsobjekteToSearchResults(
      planungsobjekte,
      idsWithVarianten,
      expandedIds,
      resultIds,
    );
  },
);

const selectResultIds = createSelector(rechercheFeature.selectResults, (results) =>
  results?.linear
    .map((result) => result.id)
    .concat(results.onDemand.map((ondemand) => ondemand.id)),
);

export default {
  selectRechercheResultColumns,
  selectSearchQueryVM,
  selectSearchQueryAndFormattingParams,
  selectShownGridColumnsSorted,
  selectSearchResults,
  selectResultIds,
};
