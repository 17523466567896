/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type Kanal = "Unbekannt" | "ZDF" | "ZDFInfo" | "ZDFNeo" | "DreiSat" | "ZDFMediathek";

export const Kanal = {
  UNBEKANNT: "Unbekannt" as Kanal,
  ZDF: "ZDF" as Kanal,
  ZDF_INFO: "ZDFInfo" as Kanal,
  ZDF_NEO: "ZDFNeo" as Kanal,
  DREI_SAT: "DreiSat" as Kanal,
  ZDF_MEDIATHEK: "ZDFMediathek" as Kanal,
};
