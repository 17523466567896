import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { BeziehungDto } from "src/app/models/openapi/model/beziehung-dto";
import { beziehungActions } from "./beziehung.actions";

export interface BeziehungState extends EntityState<BeziehungDto> {
  loading: boolean;
}

export const beziehungAdapter: EntityAdapter<BeziehungDto> = createEntityAdapter<BeziehungDto>({
  selectId: (beziehung) => beziehung.planungsobjektBeziehungId,
});

export const initialBeziehungState: BeziehungState = beziehungAdapter.getInitialState({
  loading: false,
});

export const beziehungFeature = createFeature({
  name: "Beziehung",
  reducer: createReducer(
    initialBeziehungState,
    on(
      beziehungActions.getBeziehungenForPlanungsobjekt,
      (currentState): BeziehungState => ({
        ...currentState,
        loading: true,
      }),
    ),

    on(
      beziehungActions.removeBeziehung,
      (currentState): BeziehungState => ({
        ...currentState,
        loading: true,
      }),
    ),

    on(beziehungActions.removeBeziehungSuccess, (currentState, { beziehung }) =>
      beziehungAdapter.removeOne(beziehung.planungsobjektBeziehungId, {
        ...currentState,
        loading: false,
      }),
    ),
    on(
      beziehungActions.getBeziehungenForPlanungsobjektSuccess,
      (currentState, { beziehungen }): BeziehungState => {
        const currentIds = currentState.ids as string[];
        const newIds = beziehungen.map((b) => b.planungsobjektBeziehungId);
        const idsToRemove = currentIds.filter((id) => !newIds.includes(id));
        // Das funktioniert anstatt setAll, da wir an der Stelle immer alle Beziehungen neu laden.
        let newState = beziehungAdapter.removeMany(idsToRemove, currentState);
        newState = beziehungAdapter.upsertMany(beziehungen, newState);
        return { ...newState, loading: false };
      },
    ),
  ),
  extraSelectors: ({ selectBeziehungState }) => ({
    ...beziehungAdapter.getSelectors(selectBeziehungState),
  }),
});
