import { Role } from "src/app/models/openapi/model/role";

export const MOCK_USERNAME_KEY = "MOCK_USERNAME";

/**
 * Diese Mock Nutzer existieren unter gleichem Namen auch im Backend:
 * Zdf.Publit.Vorplanung.Infrastructure.Identity.Mock.E2ETestUser
 * Dort sind auch die Permissions definiert, die ihnen zugeordnet sind.
 */
export type MockUser =
  | "Bob"
  | "Peter"
  | "Rainer"
  | "Alexandria"
  | "Tammy"
  | "Norbert"
  | "Otto"
  | "Conny"
  | "Neo"
  | "Norman";

export const MockUser = {
  /**
   * Power User
   */
  Bob: "Bob",

  /**
   * Peter (Planner) hat folgende Berechtigungen:
   * - {@link Role.PLANUNGSOBJEKT_ZDF_LESEN } => alle
   * - {@link Role.PLANUNGSOBJEKT_BEITRAGEN }
   * - {@link Role.PLANUNGSOBJEKT_ZDF_PLANEN } => alle
   * - {@link Role.PLANUNGSHINWEIS_EDITIEREN } => alle
   * - {@link Role.EVENT_KONKURRENZPROGRAMM_EDITIEREN }
   */
  Peter: "Peter",

  /**
   * Read Only
   */
  Rainer: "Rainer",

  /**
   * Pharos Transfer
   */
  Alexandria: "Alexandria",

  /**
   * Technischer Administrator
   *
   * Wird vor allem vom TestDataHelper verwendet, um Daten zu erstellen.
   */
  Tammy: "Tammy",

  /**
   * Keine Permissions
   */
  Norbert: "Norbert",

  /**
   * Otto (OnDemand) hat folgende Berechtigungen:
   * - {@link Role.PLANUNGSOBJEKT_ZDF_LESEN } => alle
   * - {@link Role.PLANUNGSOBJEKT_ZDF_MEDIATHEK_PLANEN }
   * - {@link Role.PLANUNGSOBJEKT_ZDF_MEDIATHEK_LESEN } => alle
   */
  Otto: "Otto",

  /**
   * Conny hat folgende Berechtigungen:
   * - {@link Role.PLANUNGSOBJEKT_ZDF_LESEN } => 2022,2023
   * - {@link Role.PLANUNGSOBJEKT_BEITRAGEN }
   */
  Conny: "Conny",

  /**
   * Neo hat folgende Berechtigungen:
   * - {@link Role.PLANUNGSOBJEKT_ZDF_NEO_LESEN } => 2024,2025
   */
  Neo: "Neo",

  /**
   * Norman hat folgende Berechtigungen:
   * - {@link Role.PLANUNGSOBJEKT_ZDF_NEO_LESEN } => alle
   * - {@link Role.PLANUNGSOBJEKT_ZDF_NEO_PLANEN } => alle
   */
  Norman: "Norman",
} satisfies Record<MockUser, MockUser>;
