import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs";
import { planungsobjektWindowActions } from "../core/stores/planungsobjekt-window/planungsobjekt-window.actions";
import { Aktion } from "../models/enums/aktion";
import { PlanungsobjektWindowComponent } from "../shared/windows/planungsobjekt-window/planungsobjekt-window.component";
import { PlanungsobjektWindowInputWithPlanungsobjekt } from "../shared/windows/planungsobjekt-window/planungsobjekt-window.model";
import { MediumWindow } from "../shared/windows/window.templates";
import { CustomDialogService } from "./custom-dialog.service";
import { CustomWindowService } from "./custom-window.service";

@Injectable({
  providedIn: "root",
})
export class PlanungsobjektWindowService {
  constructor(
    private store: Store,
    private customWindowService: CustomWindowService,
    private customDialogService: CustomDialogService,
  ) {}

  openDeletePlanungsobjektDialog(titel: string) {
    const dialogRef = this.customDialogService.openDeleteDialog(
      titel,
      Aktion.ENTFERNE_PLANUNGSOBJEKT,
      [
        {
          text: "Ausplanen",
          action: Aktion.ENTFERNE_PLANUNGSOBJEKT,
          themeColor: "primary",
          fillMode: "solid",
        },
        { text: "Auf Merkliste", action: Aktion.PLANUNGSOBJEKT_AUF_MERKLISTE },
      ],
      undefined,
      "delete-planungsobjekt-dialog",
    );

    return dialogRef;
  }

  openPlanungsobjektWindow(input: PlanungsobjektWindowInputWithPlanungsobjekt) {
    const windowRef = this.customWindowService.open<PlanungsobjektWindowComponent>({
      content: PlanungsobjektWindowComponent,
      title: "Planungsdetails",
      ...MediumWindow({ dataTestId: "planungsobjekt-details-window" }),
      // initiale Höhe auf 80% des Viewports setzen
      height: window.innerHeight * 0.8,
      // setze das window in die vertikale Mitte des Viewports
      top: window.innerHeight * 0.1,
      // keine maximale Höhe setzen
      cssClass: "flex-window-content",
      preventClose: () => true,
    });

    windowRef.window.instance.close
      .pipe(takeUntil(windowRef.result))
      .subscribe(() =>
        this.store.dispatch(planungsobjektWindowActions.closePlanungsobjektWindow()),
      );

    windowRef.content.instance.input = input;
    return windowRef;
  }
}
