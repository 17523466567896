import { CdkDrag, CdkDropList } from "@angular/cdk/drag-drop";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "cdkDropListEnterPredicateWrapper",
})
export class CdkDropListEnterPredicateWrapperPipe implements PipeTransform {
  transform(value: boolean): (drag: CdkDrag<any>, drop: CdkDropList<any>) => boolean {
    return (_drag: CdkDrag<any>, _drop: CdkDropList<any>): boolean => {
      return value;
    };
  }
}
