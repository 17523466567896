import { createActionGroup, props } from "@ngrx/store";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { MultiAnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

export const eventActions = createActionGroup({
  source: "Events API",
  events: {
    loadEventsByAnsichten: props<{
      multiAnsichtViewModel: MultiAnsichtViewModel;
    }>(),
    loadEventsByAnsichtenSuccess: props<{
      events: EventDto[];
    }>(),
    loadEventsByYearSuccess: props<{
      events: EventDto[];
    }>(),
    loadEventsByAnsichtenError: props<{
      error: unknown;
    }>(),
    createEvent: props<{
      event: EventDto;
    }>(),
    createEventSuccess: props<{
      event: EventDto;
    }>(),
    updateEvent: props<{
      event: EventDto;
    }>(),
    updateEventSuccess: props<{
      event: EventDto;
    }>(),
    deleteEvent: props<{
      event: EventDto;
    }>(),
    deleteEventSuccess: props<{
      eventId: string;
    }>(),
    copyEvent: props<{
      event: EventDto;
      copyPattern: CopyPattern;
    }>(),
    copyEventSuccess: props<{
      event: EventDto;
    }>(),
    convertKonkurrenzprogrammToEvent: props<{
      event: EventDto;
      convertedGUID: string;
    }>(),
    convertKonkurrenzprogrammToEventSuccess: props<{
      event: EventDto;
      convertedGUID: string;
    }>(),
    upsertEventsForBlockansicht: props<{ events: EventDto[] }>(),
    setAllEvents: props<{
      events: EventDto[];
    }>(),
  },
});
