export class TechnicalError extends Error {
  constructor(
    message: string,
    public readonly props?: Record<string, unknown>,
  ) {
    super(message);
  }
}

export class DeveloperError extends TechnicalError {
  readonly name = "DeveloperError";
  constructor(message: string, props?: Record<string, unknown>) {
    super(message, props);
  }
}
