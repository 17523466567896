import { ForcedSubject, MatchConditions } from "@casl/ability";
import { CaslActions } from "./casl-actions";
import { CaslSubjects } from "./casl-subjects";

export const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;

/**
 * Utility Type, der sicherstellt, dass sowohl Action als auch Subject den definierten Typen entsprechen.
 */
export type ActionWithSubject<T extends CaslActions, S extends CaslSubjects> = [
  T,
  S | ForcedSubject<S>,
];

/**
 * Die Subject Detection von CASL funktioniert in unserem Fall nicht, da wir TypeScript
 * Interfaces statt JS/TS Klassen nutzen. Normal würden an diesen die CASL Subject Type
 * Informationen hinterlegt werden.
 */
export function asSubject<T extends CaslSubjects>(
  subjectType: T,
  object: object | null | undefined,
): ForcedSubject<T> {
  // console.log("asSubject", subjectType, object);
  if (object === null || object === undefined) {
    subjectType = "Unknown" as T;
  }

  return { ...object, __caslSubjectType__: subjectType };
}
