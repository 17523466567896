import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { AnsichtenTitel } from "src/app/models/enums/ansichten-titel";
import { Kanal } from "src/app/models/openapi/model/kanal";

@Pipe({
  name: "ansichtViewmodelForDashboard",
})
export class AnsichtViewmodelForDashboardPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(titel: AnsichtenTitel, year: number, kanal: Kanal) {
    return this.store.select(
      ansichtSelectors.selectAnsichtViewModelForTitelAndYearAndKanal(titel, year, kanal),
    );
  }
}
