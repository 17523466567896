import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const mehrfachauswahlActions = createActionGroup({
  source: "Mehrfachauswahl",
  events: {
    setPlanungsobjekteInMehrfachauswahl: props<{
      planungsobjektIds: string[];
    }>(),
    selectPlanungsobjektToMehrfachauswahl: props<{ planungsobjektId: string }>(),
    deselectPlanungsobjektFromMehrfachauswahl: props<{ planungsobjektId: string }>(),
    updatePlanungsobjekteInMehrfachauswahl: props<{
      planungsobjekteToAdd: string[];
      planungsobjekteToRemove: string[];
    }>(),
    removePlanungsobjektFromMehrfachauswahl: props<{
      planungsobjektId: string;
    }>(),
    removeAllPlanungsobjekteFromMehrfachauswahl: emptyProps(),
    deactivateMehrfachauswahl: emptyProps(),
    toggleMehrfachauswahl: props<{
      active: boolean;
    }>(),
    startMehrfachauswahlSelection: emptyProps(),
    stopMehrfachauswahlSelection: props<{
      planungsobjekte: string[];
    }>(),
  },
});
