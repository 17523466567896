import { Pipe, PipeTransform } from "@angular/core";
import { PlanungsobjektFarbgebung } from "src/app/models/openapi/model/planungsobjekt-farbgebung";
import { planungsobjektFarbgebungMap } from "src/app/models/viewmodels/planungsobjekt-viewmodel";
import { ColorMapToNgStylePipe, ColorMapToReturnValue } from "./color-map-to-ng-style.pipe";

@Pipe({
  name: "planungsobjektColorMapToNgStyle",
})
export class PlanungsobjektColorMapToNgStylePipe implements PipeTransform {
  constructor(private ColorMapToNgStylePipe: ColorMapToNgStylePipe) {}

  transform(value: PlanungsobjektFarbgebung): { color?: string; background?: string } {
    return this.ColorMapToNgStylePipe.transform(
      value,
      planungsobjektFarbgebungMap,
      ColorMapToReturnValue.Style,
    );
  }
}
