import { Prettify } from "@ngrx/store/src/models";
import { LinearOnDemandDto } from "src/app/models/openapi/model/linear-on-demand-dto";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { PlanungsobjektOnDemandDto } from "src/app/models/openapi/model/planungsobjekt-on-demand-dto";
import { ControlEnablement, EnablementForKeysOf } from "src/app/utils/form-utils";
import { NullableValues } from "src/app/utils/object-utils";

export type PlanungsinhalteFormData = Pick<
  PlanungsobjektDto,
  | "titel"
  | "redaktion"
  | "genre"
  | "notiz"
  | "highlight"
  | "contentCommunities"
  | "farbgebung"
  | "fruehesteVeroeffentlichung"
  | "stofffuehrendeRedaktion"
  | "fsk"
  | "staffelnummer"
  | "folgennummer"
  | "gesamtfolgennummer"
  | "inhaltsbeschreibung"
  | "mitwirkende"
>;

export type WannBezugOnDemandFormData = Pick<
  PlanungsobjektOnDemandDto,
  "onlineAb" | "onlineAbZeit" | "onlineBis" | "planlaenge"
>;

export type WannBezugLinearFormData = Pick<
  PlanungsobjektLinearDto,
  "sendetag" | "beginnzeit" | "planlaenge" | "variante"
> & { endzeit: string | null };

export type PlanungsobjektWindowPlanungForm = Prettify<
  NullableValues<PlanungsinhalteFormData & WannBezugOnDemandFormData & WannBezugLinearFormData>
>;

/**
 * Enablement-Map für das Planung-Formular.
 */
export type PlanungsobjektWindowPlanungFormEnablement =
  | ControlEnablement
  | EnablementForKeysOf<PlanungsobjektWindowPlanungForm>;

type OnDemandFormData = Pick<
  LinearOnDemandDto,
  | "wunschOnlineAb"
  | "onlineAbZeit"
  | "wunschOnlineBis"
  | "reihenfolgeHerstellen"
  | "relationZuLinearInTagen"
  | "verweildauerInTagen"
  | "minDistanz"
  | "maxDistanz"
>;

export type PlanungsobjektWindowOnDemandForm = Prettify<NullableValues<OnDemandFormData>>;

export enum PlanungsobjektWindowTabEnum {
  PLANUNG = "Planung",
  VERLINKUNG = "Verlinkung",
  AKTIVITAETEN = "Aktivitäten",
}
