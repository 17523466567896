<!-- Platzhalter besondere Konstellationen wie Sonntag/Montag 22.15 in 2024 -->
<!-- Der erste gültige Sendeplatz (01.01) liegt dabei auf dem zweiten Schemaplatz (Montag) -->
<!-- Für den ersten Schemaplatz (31.12 im Vorjahr) muss in der Notizen Spalte dann ein leerer Platzhalter angezeigt werden -->
<div *ngIf="!sendeplatz" hidden></div>
<div
  *ngIf="
    sendeplatz && {
      planungsobjekte: planungsobjekte$ | async,
      planungshinweise: planungshinweise$ | async
    } as data
  "
  class="notizen-container"
  [attr.data-testid]="sendeplatz | testId: 'sendeplatz-notizen' : planungskontext"
  [ngClass]="{ vorschlaege: planungskontext === Planungskontext.VORGESCHLAGEN }"
>
  <p
    *ngIf="data.planungsobjekte"
    class="single-line-notes"
    [title]="data.planungsobjekte | singleLineNotes"
  >
    {{ data.planungsobjekte | singleLineNotes }}
  </p>
  <p
    *ngIf="showSendeplatzNotizen && data.planungshinweise"
    class="single-line-notes u-font-style-italic"
  >
    {{ data.planungshinweise | planungshinweisSingleLineNotes }}
  </p>
</div>
