import { createFeature, createReducer, on } from "@ngrx/store";
import { PublitPermissionDto } from "src/app/models/openapi/model/publit-permission-dto";
import { FeatureKey } from "../feature.keys";
import { permissionActions } from "./permissions.actions";

export type PermissionState = {
  permissions: PublitPermissionDto[] | undefined;
};

const initialState: PermissionState = {
  permissions: undefined,
};

export const permissionFeature = createFeature({
  name: FeatureKey.Permission,
  reducer: createReducer(
    initialState,
    on(permissionActions.loadPermissionsSuccess, (currentState, action): PermissionState => {
      return { ...currentState, permissions: action.permissions };
    }),
    on(permissionActions.loadPermissionsFailure, (currentState): PermissionState => {
      return { ...currentState, permissions: [] };
    }),
  ),
});
