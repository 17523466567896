import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { DialogCloseResult, WindowRef } from "@progress/kendo-angular-dialog";
import { Observable, Subject, take, takeUntil, tap } from "rxjs";
import { mengengeruestActions } from "src/app/core/stores/mengengeruest/mengengeruest.actions";
import { mengengeruestEintragFeature } from "src/app/core/stores/mengengeruest/mengengeruest.reducer";
import mengengeruestSelectors from "src/app/core/stores/mengengeruest/mengengeruest.selectors";
import { mengengeruestWindowActions } from "src/app/core/stores/mengengeruest/mengengeruest.window.actions";
import { GenreRecord } from "src/app/models/enums/genre";
import { Icons } from "src/app/models/icons";
import { Layout } from "src/app/models/openapi/model/layout";
import { MengengeruesteintragDto } from "src/app/models/openapi/model/mengengeruesteintrag-dto";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SchemaplatzDto } from "src/app/models/openapi/model/schemaplatz-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";
import { MengengeruestViewModel } from "src/app/models/viewmodels/mengengeruest-viewmodel";
import { MengengeruestService } from "src/app/services/mengengeruest.service";

export enum MengengeruestWindowUseCase {
  MAIN_WINDOW,
  ZUWEISUNG_SENDEPLATZ,
  ZUWEISUNG_BLOCKANSICHT,
}

export type MengengeruestWindowInput =
  | {
      type: MengengeruestWindowUseCase.MAIN_WINDOW;
      openWindows$?: Observable<WindowRef[]>;
      ansichtViewModel: AnsichtViewModel;
    }
  | {
      type: MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ;
      ansichtViewModel: AnsichtViewModel;
      planungsobjekt: PlanungsobjektDto;
      sendeplatz: SendeplatzDto;
      schemaplatz: SchemaplatzDto;
    }
  | {
      type: MengengeruestWindowUseCase.ZUWEISUNG_BLOCKANSICHT;
      ansichtViewModel: AnsichtViewModel;
      planungsobjekt: PlanungsobjektDto;
      schemaplatz: SchemaplatzDto;
    };

export type MengengeruestWindowComponentResult =
  | {
      type: MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ;
      sendeplatz: SendeplatzDto;
      previousMengengeruestId: string | null;
      zugewiesenePlanungsobjekt: PlanungsobjektDto;
    }
  | {
      type: MengengeruestWindowUseCase.ZUWEISUNG_BLOCKANSICHT;
      previousMengengeruestId: string | null;
      zugewiesenePlanungsobjekt: PlanungsobjektDto;
    };

@Component({
  selector: "app-mengengeruest-window",
  templateUrl: "./mengengeruest-window.component.html",
  styleUrls: ["./mengengeruest-window.component.scss"],
})
export class MengengeruestWindowComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() input: MengengeruestWindowInput;

  @ViewChildren("mengengeruest") mengengerueste: QueryList<ElementRef<HTMLDivElement>>;

  private destroy$ = new Subject<void>();
  isMengengeruestZuweisung = false;
  mengengeruestTables$: Observable<MengengeruestViewModel[]>;
  mengengeruesteintraege$ = this.store.select(mengengeruestEintragFeature.selectAll);
  selectedMengengeruesteintrag: string | null;
  activeMengengeruesteintragId: string | null;
  initialMengengeruesteintragId: string;

  windowCount = 0;
  GenreRecord = GenreRecord;
  Icons = Icons;

  MengengeruestWindowUseCase = MengengeruestWindowUseCase;

  isBlockansicht = false;

  constructor(
    private windowRef: WindowRef,
    private store: Store,
    private mengengeruestService: MengengeruestService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.isBlockansicht = this.input.ansichtViewModel.ansichtsdefinition.layout === Layout.BLOCK;

    if (this.input.type === MengengeruestWindowUseCase.MAIN_WINDOW && this.input.openWindows$)
      this.input.openWindows$
        .pipe(takeUntil(this.destroy$))
        .subscribe((openWindows) => (this.windowCount = openWindows.length));
    if (this.input.type !== MengengeruestWindowUseCase.MAIN_WINDOW) {
      this.newInitializeActiveFields();
    }

    this.mengengeruestTables$ = this.isBlockansicht
      ? this.store.select(
          mengengeruestSelectors.selectMengengeruestViewModelsByBeginnzeit(
            this.input.ansichtViewModel,
          ),
        )
      : this.store.select(
          mengengeruestSelectors.selectMengengeruestViewModels(this.input.ansichtViewModel),
        );
  }

  ngAfterViewInit(): void {
    // Scrollt zum Mengengerüst des Schemaplatzes
    // mit Hilfe des Sendeplatzes
    if (
      this.input.type === MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ &&
      this.input.sendeplatz
    ) {
      this.mengengeruestService
        .getMengengeruesteintraegeBySendeplatzId$(
          this.input.ansichtViewModel.id,
          this.input.sendeplatz.id,
        )
        .pipe(take(1))
        .subscribe((mengengeruestGroup) => {
          const mengengeruestElement = this.mengengerueste.find(
            (el) => el.nativeElement.id === mengengeruestGroup.schemaplatz.id,
          );

          if (mengengeruestElement) {
            mengengeruestElement.nativeElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        });
    }
  }

  private newInitializeActiveFields() {
    this.isMengengeruestZuweisung = true;
    if (
      this.input.type !== MengengeruestWindowUseCase.MAIN_WINDOW &&
      this.input.planungsobjekt.mengengeruesteintragId
    ) {
      this.initialMengengeruesteintragId = this.input.planungsobjekt.mengengeruesteintragId;
      this.activeMengengeruesteintragId = this.input.planungsobjekt.mengengeruesteintragId;
    }
  }

  onCreate(mengengeruestViewModel: MengengeruestViewModel): void {
    if (!mengengeruestViewModel.schemaplatz) {
      throw Error(
        "Es kann kein Mengengerüsteintrag erstellt werden, wenn kein Schemaplatz existiert.",
      );
    }

    // Nur wenn alle einzigartigen Beginnzeiten identisch sind setzen wir einen Preset.
    const commonBeginnzeit = [
      ...new Set(
        mengengeruestViewModel.mengengeruesteintraege.map((eintrag) => eintrag.beginnzeit ?? null),
      ),
    ];
    const presetBeginnzeit = commonBeginnzeit.length <= 1 ? commonBeginnzeit[0] : null;

    this.store.dispatch(
      mengengeruestWindowActions.openCreateMengengeruestEintragWindow({
        ansichtId: this.input.ansichtViewModel.id,
        schemaplatzId: mengengeruestViewModel.schemaplatz.id,
        presetBeginnzeit,
      }),
    );
  }

  onEdit(oldMengengeruesteintrag: MengengeruesteintragDto): void {
    this.store.dispatch(
      mengengeruestWindowActions.openEditMengengeruestEintragWindow({
        oldMengengeruesteintrag,
      }),
    );
  }

  onDelete(mengengeruesteintrag: MengengeruesteintragDto): void {
    this.store.dispatch(
      mengengeruestWindowActions.openDeleteMengengeruesteintragDialog({
        mengengeruesteintrag,
      }),
    );
  }

  onClose(): void {
    this.windowRef.close();
  }

  onSaveZuweisung(mengengeruesteintraege: MengengeruesteintragDto[]) {
    // Nutzung von "==" erlaubt auch null == undefined => true
    // Wenn keine Änderung durchgeführt wurde schließe den Dialog ohne Aktion
    if (this.activeMengengeruesteintragId == this.initialMengengeruesteintragId) {
      this.windowRef.close();
      return;
    }

    if (this.input.type !== MengengeruestWindowUseCase.MAIN_WINDOW && this.input.planungsobjekt) {
      // Wenn die Zuweisung aufgehoben wird, dann ist this.selectedMengengeruesteintrag.id nicht gesetzt
      const mengengeruesteintragId = this.selectedMengengeruesteintrag ?? null;

      const newPlanungsobjekt: PlanungsobjektDto = {
        ...this.input.planungsobjekt,
        mengengeruesteintragId,
      };

      const mengengeruestWindowResult: MengengeruestWindowComponentResult =
        this.input.type === MengengeruestWindowUseCase.ZUWEISUNG_SENDEPLATZ
          ? {
              zugewiesenePlanungsobjekt: newPlanungsobjekt,
              previousMengengeruestId: this.initialMengengeruesteintragId,
              type: this.input.type,
              sendeplatz: this.input.sendeplatz,
            }
          : {
              zugewiesenePlanungsobjekt: newPlanungsobjekt,
              previousMengengeruestId: this.initialMengengeruesteintragId,
              type: this.input.type,
            };

      // Validierung auf den korrekten Schemaplatz bei der Zuweisung (siehe PUBLIT-1066)
      if (
        this.mengengeruestValidationConditional(
          mengengeruesteintraege,
          mengengeruesteintragId,
          this.input.schemaplatz.id,
        )
      ) {
        // Wenn der Schemaplatz des Sendeplatzes/Planungsobjekt nicht dem Schemaplatz des Mengengerüstes entspricht, dann öffne das Validierungsfenster
        this.mengengeruestService
          .openMengeneruestZuweisungValidationWindow()
          .result.pipe(
            tap((windowResult) => {
              if (windowResult instanceof DialogCloseResult) return;
              this.windowRef.close(mengengeruestWindowResult);
            }),
            take(1),
          )
          .subscribe();
      } else {
        this.windowRef.close(mengengeruestWindowResult);
      }
    }
  }

  updateSendeplanCounter(
    eintrag: MengengeruesteintragDto,
    allMengengeruestEintraege: MengengeruesteintragDto[],
  ) {
    // Nutzer klickt auf ein bereits selektieren MG-Eintrag
    if (this.activeMengengeruesteintragId === eintrag.id) {
      this.selectedMengengeruesteintrag = null;
      this.activeMengengeruesteintragId = null;
      this.changeSendplan(eintrag, eintrag.sendeplan - 1);
    } else {
      // Es existiert kein selektierter MG-Eintrag oder ein anderer MG-Eintrag wurde selektiert
      if (!this.activeMengengeruesteintragId || this.activeMengengeruesteintragId !== eintrag.id) {
        this.changeSendplan(eintrag, eintrag.sendeplan + 1);
      }

      // Es existierte ein selektierter MG-Eintrag, dieser wird wieder dekrementiert (ein anderer wurde selektiert)
      if (this.activeMengengeruesteintragId) {
        const lastSelectedMengengeruesteintrag = allMengengeruestEintraege.find(
          (eintrag) => eintrag.id === this.activeMengengeruesteintragId,
        );
        if (lastSelectedMengengeruesteintrag) {
          this.changeSendplan(
            lastSelectedMengengeruesteintrag,
            lastSelectedMengengeruesteintrag.sendeplan - 1,
          );
        }
      }

      this.selectedMengengeruesteintrag = eintrag.id;
      this.activeMengengeruesteintragId = eintrag.id;
    }
  }

  private changeSendplan(mengengeruestEintrag: MengengeruesteintragDto, sendeplan: number) {
    this.store.dispatch(
      mengengeruestActions.updateMengengeruestEintragSuccess({
        mengengeruestEintrag: { ...mengengeruestEintrag, sendeplan },
      }),
    );
  }

  mengengeruestValidationConditional = (
    mengengeruesteintraege: MengengeruesteintragDto[],
    mengengeruesteintragId: string,
    schemaplatzId: string,
  ) => {
    return (
      mengengeruesteintragId &&
      mengengeruesteintraege.find((eintrag) => eintrag.id === mengengeruesteintragId)
        .schemaplatzId !== schemaplatzId
    );
  };
}
