import { Pipe, PipeTransform } from "@angular/core";

/**
 * Basierend auf:
 * https://stackoverflow.com/a/71582751/1010496
 */
@Pipe({
  name: "orderBy",
})
export class OrderByPipe implements PipeTransform {
  transform<T, K extends keyof T>(input: T[], compareBy?: K | ((a: T, b: T) => number)): T[] {
    if (!compareBy) {
      return [...input].sort();
    }
    if (typeof compareBy === "function") {
      return [...input].sort((a, b) => compareBy(a, b));
    }

    return [...input].sort((a, b) => {
      const aValue = a && typeof a === "object" ? a[compareBy] : a;
      const bValue = b && typeof b === "object" ? b[compareBy] : b;

      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      }

      return 0;
    });
  }
}
