import { inject } from "@angular/core";
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
  RouterEvent,
} from "@angular/router";
import { ofType } from "@ngrx/effects";
import { routerNavigatedAction } from "@ngrx/router-store";
import { Action } from "@ngrx/store";
import { Observable, filter, map } from "rxjs";

export const isOnUrl = (segment: string) => window.location.href.includes(segment);

export const filterByUrl = (segment: string) => {
  return <T>(source$: Observable<T>): Observable<T> => {
    return source$.pipe(filter(() => isOnUrl(segment)));
  };
};

/**
 * Filtert die Navigationsereignisse basierend auf dem URL-Segment.
 *
 * @param segment - Das URL-Segment, nach dem gefiltert werden soll.
 * @returns Eine Funktion, die die Navigationsereignisse basierend auf dem URL-Segment filtert.
 */
export const filterByUrlFromNavigationEvent = (segment: string) => {
  return (source$: Observable<NavigationStart>) => {
    return source$.pipe(filter((s) => s.url.includes(segment)));
  };
};

/**
 * Filtert die Navigationsereignisse basierend auf Navigation zu URL.
 * Wenn wir bereits auf der Seite sind wird nicht emitted.
 *
 * Wir müssen auf das Navigated Event Listenen, da ggf. Module Asynchron geladen werden und somit die Effekte beim NavigationStart Event nicht greifen.
 *
 * @param segment - Das URL-Segment, nach dem gefiltert werden soll.
 * @returns Eine Funktion, die die Navigationsereignisse basierend auf dem URL-Segment filtert.
 */
export const filterNavigatedToPage = (segment: string, currentRoute = inject(ActivatedRoute)) => {
  return (source$: Observable<Action>) => {
    return source$.pipe(
      ofType(routerNavigatedAction),
      map((event) => event.payload.event),
      filter(
        (event) =>
          event.url.includes(segment) && !currentRoute.snapshot.url.join("/").includes(segment),
      ),
    );
  };
};

export function isNavigationStart(event: Event): event is NavigationStart {
  return event instanceof NavigationStart;
}

export function isNavigationEnd(event: Event): event is NavigationEnd {
  return event instanceof NavigationEnd;
}

/**
 * Gibt `true` zurück, wenn es sich um die erste Navigation (Nutzer hat die Seite aufgerufen) oder
 * um eine Navigation handelt, die durch den Browser-Back-Button oder -Forward-Button ausgelöst wurde.
 */
export function isBrowserNavigationEvent(event: RouterEvent) {
  const isFirstNavigation = event.id === 1;
  const isPopstateNavigation =
    event instanceof NavigationStart && event.navigationTrigger === "popstate";
  return isFirstNavigation || isPopstateNavigation;
}
