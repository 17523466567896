<ng-container *ngIf="initComplete$ | async; then app; else loading"></ng-container>
<ng-template #app>
  <ng-container
    *ngIf="{
      isDrawerItemExpandedFn: isDrawerItemExpanded$ | async
    } as vm"
  >
    <kendo-drawer-container
      [ngClass]="{
        'k-pointer-events-none k-overlay': (customWindowService.openWindows$ | async).length > 0
      }"
    >
      <img class="zdf-publish-it-logo" [src]="logoUrl" />
      <kendo-drawer
        #drawer
        mode="push"
        [autoCollapse]="false"
        [expanded]="isExpanded"
        [isItemExpanded]="vm.isDrawerItemExpandedFn"
        [items]="navigationItems$ | async"
        [mini]="true"
        (select)="onSelect($event)"
      >
        <ng-template kendoDrawerItemTemplate let-item>
          <a
            class="nostyle drawer-item"
            [attr.data-testid]="item.testId"
            [queryParams]="item.queryParams"
            [routerLink]="item.path"
          >
            <kendo-svg-icon
              *ngIf="item.svgIcon"
              class="drawer-icon-svg"
              [icon]="item.svgIcon"
              [title]="item.text"
            ></kendo-svg-icon>
            <span *ngIf="isExpanded">{{ item.text }}</span>
            <ng-container *ngIf="item.hasChildren">
              <div class="k-flex-1"><!-- flex spacer --></div>
              <kendo-svg-icon
                *ngIf="vm.isDrawerItemExpandedFn"
                [attr.data-testid]="'expand-' + item.testId"
                [icon]="vm.isDrawerItemExpandedFn(item) ? Icons.expanded : Icons.collapsed"
              ></kendo-svg-icon>
            </ng-container>
          </a>
        </ng-template>
      </kendo-drawer>
      <kendo-drawer-content>
        <router-outlet></router-outlet>
      </kendo-drawer-content>
    </kendo-drawer-container>

    <kendo-svg-icon
      class="menu-icon"
      size="large"
      title="Menü ein/-aufklappen"
      [icon]="Icons.menu"
      (click)="drawer.toggle(); isExpanded = !isExpanded"
    ></kendo-svg-icon>
  </ng-container>
</ng-template>
<ng-template #loading>
  <app-loading-dialog title="Bitte warten..."></app-loading-dialog>
</ng-template>
<div kendoWindowContainer></div>
<div kendoDialogContainer></div>
