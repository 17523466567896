import { createSelector } from "@ngrx/store";
import { shellFeature } from "./shell.reducer";

const isLoading = createSelector(
  shellFeature.selectPendingRequests,
  (pendingRequests) => pendingRequests > 0,
);

export default {
  isLoading,
};
