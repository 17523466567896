import { ChangeDetectorRef, Directive, inject, Input, OnChanges } from "@angular/core";
import {
  AbstractControl,
  FormArrayName,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
} from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { setEnablementForControl } from "src/app/utils/form-utils";

@Directive({
  selector: "[appDisableFormGroup],[appDisableFormControl]",
})
export class DisableFormGroupDirective implements OnChanges {
  @Input() appDisableFormGroup?: boolean | undefined | null;
  @Input() appDisableFormControl?: boolean | undefined | null;

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly logger = inject(NGXLogger);

  private readonly formGroup = inject(FormGroupDirective, { optional: true, self: true });
  private readonly formControl = inject(FormControlDirective, { optional: true, self: true });
  private readonly formControlName = inject(FormControlName, { optional: true, self: true });
  private readonly formArrayName = inject(FormArrayName, { optional: true, self: true });

  private get control(): AbstractControl | null {
    return (
      this.formGroup?.form ??
      this.formControl?.control ??
      this.formControlName?.control ??
      this.formArrayName?.control ??
      null
    );
  }

  private get disable(): boolean | null {
    if (this.appDisableFormGroup === undefined && this.appDisableFormControl === undefined) {
      this.logger.warn(
        "DisableFormGroupDirective: appDisableFormControl and appDisableFormGroup are undefined",
      );
    }
    // disable() returned null, wenn wir disablen/enablen den Kindskomponenten überlassen wollen
    if (this.appDisableFormGroup === null) return null;
    return this.appDisableFormGroup ?? this.appDisableFormControl ?? true;
  }

  constructor() {
    if (!this.formGroup && !this.formControl && !this.formControlName && !this.formArrayName) {
      this.logger.error(
        "DisableFormGroupDirective requires formGroup or formControl or formControlName or formArrayName",
      );
    }
  }

  ngOnChanges(): void {
    const control = this.control;
    if (!control) return;

    setEnablementForControl(control, this.disable ? !this.disable : null);

    // notwendig, damit Directive auch beim initialen Setzen des Wertes funktioniert
    this.cdr.markForCheck();
  }
}
