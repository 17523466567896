import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { VersionInformation } from "src/app/models/openapi/model/version-information";

const { loadVersionInformation, loadVersionInformationFailure, loadVersionInformationSuccess } =
  createActionGroup({
    source: "Metadaten",
    events: {
      "Load Version Information": emptyProps(),
      "Load Version Information Success": props<{ versionInformation: VersionInformation }>(),
      "Load Version Information Failure": emptyProps(),
    },
  });

export default {
  loadVersionInformation,
  loadVersionInformationFailure,
  loadVersionInformationSuccess,
};
