/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Bei Änderungen unbedingt auch Zdf.Publit.Vorplanung.Domain.Comparer.EventKategorieComparer anpassen.
 */
export type EventKategorie =
  | "KeineKategorie"
  | "Feiertag"
  | "Sportveranstaltung"
  | "PolitischesEreignis"
  | "Gedenktag"
  | "GesellschaftlichesEreignis"
  | "ThementagWoche";

export const EventKategorie = {
  KEINE_KATEGORIE: "KeineKategorie" as EventKategorie,
  FEIERTAG: "Feiertag" as EventKategorie,
  SPORTVERANSTALTUNG: "Sportveranstaltung" as EventKategorie,
  POLITISCHES_EREIGNIS: "PolitischesEreignis" as EventKategorie,
  GEDENKTAG: "Gedenktag" as EventKategorie,
  GESELLSCHAFTLICHES_EREIGNIS: "GesellschaftlichesEreignis" as EventKategorie,
  THEMENTAG_WOCHE: "ThementagWoche" as EventKategorie,
};
