import { createSelector } from "@ngrx/store";
import { areIntervalsOverlapping } from "date-fns";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { planungshinweisFeature } from "./planungshinweis.reducer";

const selectPlanungshinweiseForAnsicht = (ansichtId: string) => {
  return createSelector(planungshinweisFeature.selectAll, (planungshinweise) => {
    // TODO @jlo: Irgendwie eingrenzen
    return planungshinweise;
  });
};

const selectPlanungshinweiseByTimeRange = (von: Date, bis: Date, kanal: Kanal) =>
  createSelector(planungshinweisFeature.selectAll, (planungshinweise) => {
    return planungshinweise.filter((planungshinweis) => {
      const planungshinweisVon = new Date(planungshinweis.von);
      const planungshinweisBis = new Date(planungshinweis.bis);
      return (
        planungshinweis.kanal === kanal &&
        areIntervalsOverlapping(
          { start: planungshinweisVon, end: planungshinweisBis },
          { start: von, end: bis },
        )
      );
    });
  });

export default {
  selectPlanungshinweiseForAnsicht,
  selectPlanungshinweiseByTimeRange,
};
