import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Icons } from "src/app/models/icons";
import { Layout } from "src/app/models/openapi/model/layout";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent {
  @Input({ required: true }) layout: Layout;
  @Input({ required: true }) showNotizen: boolean;
  @Input({ required: true }) showKonkurrenzEvents: boolean;

  @Output() toggleShowKonkurrenzEvents = new EventEmitter<void>();
  @Output() toggleShowNotizen = new EventEmitter<void>();

  Icons = Icons;

  Layout = Layout;

  emitToggleShowKonkurrenzEvents() {
    this.toggleShowKonkurrenzEvents.emit();
  }

  emitToggleShowNotizen() {
    this.toggleShowNotizen.emit();
  }
}
