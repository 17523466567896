import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { WindowRef } from "@progress/kendo-angular-dialog";
import { AnsichtType } from "src/app/models/enums/ansicht-type";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { DateFnsService } from "src/app/services/date-fns.service";
import { CustomValidators } from "src/app/shared/form-inputs/custom-validators";

export interface SerienWindowInput {
  context: AnsichtType;
  planungsobjekt: PlanungsobjektDto;
}

export interface SerienWindowResult {
  context: AnsichtType;
  params: {
    folgennummerAb: number;
    folgennummerBis: number;
    staffelnummer: number | null;
    anzahlFolgen: number | null;
    reihenfolgeVerlinken: boolean;
    planungsobjekt: PlanungsobjektDto;
    auszulassendeDaten: string[];
  };
}

@Component({
  selector: "app-serien-window",
  templateUrl: "./serien-window.component.html",
  styleUrls: ["./serien-window.component.scss"],
})
export class SerienWindowComponent implements OnInit {
  @Input() public serienWindowInput: SerienWindowInput;

  formGroup = this.initializeForm();

  enableAuszulassendeDaten = false;
  focusedDate: Date;

  constructor(
    private formBuilder: FormBuilder,
    private windowRef: WindowRef,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.initializeForm();
    const sendeTag = this.serienWindowInput.planungsobjekt.publikationsplanung?.sendetag;
    this.focusedDate = sendeTag ? DateFnsService.parseStringToDate(sendeTag) : new Date();
  }

  onEnableAuszulassendeDatenChange(isEnabled: boolean) {
    if (!isEnabled) {
      this.formGroup.get("auszulassendeDaten")?.setValue([]);
    }
  }

  private initializeForm() {
    /**
     * Es wurde hier nicht das "disabled" Attribut gesetzt, da "disabled" Felder nicht validiert werden können
     * Man könnte "globale" Validatoren auf die FormGroup setzen
     */
    return this.formBuilder.group({
      folgennummerAb: [
        1,
        [
          Validators.required,
          Validators.min(1),
          CustomValidators.valueBefore("folgennummerBis", "Folgennummer bis"),
        ],
      ],
      folgennummerBis: [
        null as number | null,
        [
          Validators.required,
          Validators.min(1),
          CustomValidators.valueAfter("folgennummerAb", "Folgennummer ab"),
          CustomValidators.valueBefore("anzahlFolgen", "Folgen in Staffel/Serie"),
        ],
      ],
      staffelNummer: [null as number | null, [Validators.min(1)]],
      anzahlFolgen: [
        null as number | null,
        [Validators.min(1), CustomValidators.valueAfter("folgennummerBis", "Folgennummer bis")],
      ],
      reihenfolgeVerlinken: [false],
      auszulassendeDaten: [[] as Date[]],
    });
  }

  onClose() {
    this.windowRef.close();
  }

  onSave() {
    const result: SerienWindowResult = {
      context: this.serienWindowInput.context,
      params: {
        folgennummerAb: this.formGroup.value.folgennummerAb
          ? this.formGroup.value.folgennummerAb
          : 1,
        folgennummerBis: this.formGroup.value.folgennummerBis
          ? this.formGroup.value.folgennummerBis
          : 1,
        staffelnummer: this.formGroup.value.staffelNummer
          ? this.formGroup.value.staffelNummer
          : null,
        anzahlFolgen: this.formGroup.value.anzahlFolgen ? this.formGroup.value.anzahlFolgen : null,
        reihenfolgeVerlinken: this.formGroup.value.reihenfolgeVerlinken
          ? this.formGroup.value.reihenfolgeVerlinken
          : false,
        planungsobjekt: this.serienWindowInput.planungsobjekt,
        auszulassendeDaten: (this.formGroup.value.auszulassendeDaten ?? []).map((date) =>
          DateFnsService.formatDateAsString(date),
        ),
      },
    };

    this.windowRef.close(result);
  }
}
