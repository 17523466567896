import { createSelector } from "@ngrx/store";
import { sortBenachrichtigungenByTimestamp } from "src/app/utils/sort-utils";
import ansichtSelectors from "../ansicht/ansicht.selectors";
import { multiAnsichtFeature } from "../multiansicht/multiansicht.reducer";
import { isBenachrichtigungInAnsichtLinear } from "./benachrichtigung.filters";
import { BenachrichtigungComponentVm, benachrichtigungKategorien } from "./benachrichtigung.model";
import { benachrichtigungFeature } from "./benachrichtigung.reducer";

const selectBenachrichtigungenForAnsichten = createSelector(
  benachrichtigungFeature.selectAll,
  multiAnsichtFeature.selectMultiAnsichtViewModel,
  ansichtSelectors.selectSendeplatzIntervalsForCurrentAnsichten,
  (benachrichtigungen, multiAnsichtVm, intervals) => {
    const ausspielweg = multiAnsichtVm?.ansichtViewModels[0]?.ansichtViewModel?.kanal;
    // Wir können den Ausspielweg der linearen Ansicht nicht feststellen
    if (!ausspielweg) {
      return [];
    }

    if (!intervals.length) {
      return [];
    }

    return benachrichtigungen.filter((benachrichtigung) =>
      isBenachrichtigungInAnsichtLinear(benachrichtigung, ausspielweg, intervals),
    );
  },
);

const selectBenachrichtigungenForPrioritaeten = createSelector(
  selectBenachrichtigungenForAnsichten,
  (benachrichtigungenForAnsichten): BenachrichtigungComponentVm[] => {
    benachrichtigungenForAnsichten ??= [];
    return benachrichtigungKategorien.map(
      (componentData): BenachrichtigungComponentVm => ({
        ...componentData,
        benachrichtigungen: benachrichtigungenForAnsichten
          .filter((benachrichtigung) => componentData.prioritaet === benachrichtigung.prioritaet)
          .sort(sortBenachrichtigungenByTimestamp),
      }),
    );
  },
);

const selectBenachrichtigungenAnyNotConfirmed = createSelector(
  selectBenachrichtigungenForAnsichten,
  (benachrichtigungen) =>
    benachrichtigungen.some((benachrichtigung) => !benachrichtigung.confirmed),
);

export default {
  selectBenachrichtigungenForAnsichten,
  selectBenachrichtigungenAnyNotConfirmed,
  selectBenachrichtigungenForPrioritaeten,
};
