import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { ContextMenuEvent } from "src/app/models/context-menu";
import { EventDto } from "src/app/models/openapi/model/event-dto";
import { KonkurrenzprogrammDto } from "src/app/models/openapi/model/konkurrenzprogramm-dto";
import {
  EKWindowInput,
  EKWindowResult,
} from "src/app/shared/windows/ek-window/ek-window.component";

export const ekWindowActions = createActionGroup({
  source: "Ek Window",
  events: {
    openEkWindow: props<{ input: EKWindowInput }>(),
    handleEkFromContextMenu: props<{
      viewModel: KonkurrenzprogrammDto | EventDto;
      event: ContextMenuEvent;
      isEvent: boolean;
      isZdfProgramm?: boolean;
    }>(),
    handleEkWindowResult: props<{
      result: EKWindowResult | WindowCloseResult;
    }>(),
    handleEventWindowResult: props<{
      result: EKWindowResult;
    }>(),
    handleKonkurrenzprogrammWindowResult: props<{
      result: EKWindowResult;
    }>(),
    closeWindow: emptyProps(),
  },
});
