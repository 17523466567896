import { Pipe, PipeTransform } from "@angular/core";
import { EnumMetadata } from "src/app/models/enums/enum-base";

@Pipe({
  name: "enumBeschreibung",
})
export class EnumBeschreibungPipe implements PipeTransform {
  transform(
    enumValue: string,
    enumMetadata: Record<string, EnumMetadata>,
    preferShort: boolean,
  ): string {
    if (!enumValue) return "";
    const metadata = enumMetadata[enumValue];

    if (metadata.bedeutung.includes("Kein")) return "";

    if (metadata) {
      if (preferShort && metadata.short) {
        return metadata.short;
      }
      return metadata.bedeutung;
    }

    return enumValue;
  }
}
