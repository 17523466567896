import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { take } from "rxjs";
import { Role } from "src/app/models/openapi/model/role";
import { DebugService } from "src/app/services/debug.service";

@Component({
  templateUrl: "./debug-window.component.html",
  styleUrls: ["./debug-window.component.scss"],
})
export class DebugWindowComponent {
  constructor(
    protected debugService: DebugService,
    private httpClient: HttpClient,
  ) {}

  Role = Role;

  notExist() {
    this.httpClient.get("api/foo/bar").pipe(take(1)).subscribe();
  }

  baseException() {
    this.httpClient.get("api/debug/baseexception").pipe(take(1)).subscribe();
  }

  validationException() {
    this.httpClient.get("api/debug/validationexception").pipe(take(1)).subscribe();
  }

  frontendError() {
    let foo: any;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    foo.crash();
  }
}
