import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { TimePickerComponent } from "@progress/kendo-angular-dateinputs";
import { Icons } from "src/app/models/icons";
import { OnChangeFn, OnTouchedFn } from "src/app/utils/form-utils";
import { noop } from "src/app/utils/function-utils";
import { roundSecondsInDateToNextMinute } from "src/app/utils/time-utils";

type TimePickerValue = Date | number | null;

@Component({
  selector: "app-rounded-timepicker",
  templateUrl: "./rounded-timepicker.component.html",
  styleUrls: ["./rounded-timepicker.component.scss"],
})
export class RoundedTimepickerComponent implements OnChanges, ControlValueAccessor {
  @Input() label: string;
  @Input() dataTestId: string;
  @Input() readOnly = false;
  @Input({ transform: coerceBooleanProperty }) clearable = false;

  initialValue: Date | null = null;
  viewValue: Date | null = null;
  changed: OnChangeFn<TimePickerValue> = noop;
  touched: OnTouchedFn = noop;
  isDisabled = false;

  @ViewChild(TimePickerComponent, { static: true }) container: TimePickerComponent;

  readonly Icons = Icons;

  constructor(
    private cdr: ChangeDetectorRef,
    @Self() @Optional() protected readonly ngControl: NgControl | null,
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readOnly) this.setDisabledState(this.readOnly);
  }

  //#region ControlValueAccessor interface methods
  writeValue(value: Date): void {
    if (!value) return;
    this.initialValue = value;
    this.viewValue = roundSecondsInDateToNextMinute(value);
    // Wird benötigt, damit das Endzeit FormControl aktualisiert wird, wenn die Länge verändert wird.
    this.cdr.detectChanges();
    if (this.readOnly) this.setDisabledState(this.readOnly);
  }

  registerOnChange(fn: OnChangeFn<TimePickerValue>): void {
    this.changed = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this.touched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
  }
  //#endregion ControlValueAccessor interface methods

  onChange(value: Date): void {
    this.viewValue = value;
    const seconds = this.initialValue?.getSeconds();
    if (seconds && value) value.setSeconds(seconds);
    this.changed(value);
    this.touched();
  }

  onClear() {
    this.viewValue = null;
    this.changed(null);
    this.touched();
  }
}
