import { ChangeDetectionStrategy, Component, inject, input } from "@angular/core";
import { Store } from "@ngrx/store";
import shellSelectors from "src/app/core/stores/shell/shell.selectors";

/**
 * Komponente, die ein Overlay anzeigt, wenn die Anwendung lädt.
 * Verwendet hierfür die `IsLoadingPipe`.
 *
 * Zeigt das Overlay erst nach einer bestimmten Zeit an, um ein Flackern zu vermeiden.
 */
@Component({
  selector: "app-loading-overlay",
  templateUrl: "./loading-overlay.component.html",
  styleUrl: "./loading-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOverlayComponent {
  private readonly store = inject(Store);

  /**
   * Zeit, nach der der Lade-Overlay angezeigt wird.
   */
  readonly showAfterMs = input(300);

  readonly isLoading$ = this.store.select(shellSelectors.isLoading);
}
