import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import merklisteSelectors from "src/app/core/stores/merkliste/merkliste.selectors";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { DateFnsService } from "src/app/services/date-fns.service";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";

@Pipe({
  name: "mehrfachAuswahlTooltip",
})
export class MehrfachauswahlTooltipPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(planungsobjekt: PlanungsobjektDto): Observable<string> {
    let tooltip = "Position des Programms: ";
    if (PlanungsobjektUtils.isOnListeOrKalenderansicht(planungsobjekt)) {
      const sendeDatum = DateFnsService.parseStringToGermanDateString(
        planungsobjekt.publikationsplanung.sendetag,
      );
      const sendeBeginnzeit =
        planungsobjekt.publikationsplanung.sendeplatzAbweichendeBeginnzeit ??
        planungsobjekt.publikationsplanung.beginnzeit;
      tooltip += `${sendeDatum} um ${sendeBeginnzeit}`;
    } else if (PlanungsobjektUtils.isOnMerkliste(planungsobjekt)) {
      return this.store.select(
        merklisteSelectors.selectMerklisteTitleForMerklisteId(planungsobjekt.merklisteId),
      );
    } else if (PlanungsobjektUtils.isOnBlockansicht(planungsobjekt)) {
      const sendeDatum = DateFnsService.parseStringToGermanDateString(
        planungsobjekt.publikationsplanung.sendetag,
      );
      const sendeBeginnzeit = planungsobjekt.publikationsplanung.beginnzeit;
      tooltip += `${sendeDatum} um ${sendeBeginnzeit}`;
    } else {
      tooltip += "unbekannt";
      console.warn(
        "Weder Sendeplatz noch Merkliste gegeben für Erstellung des Tooltips in Mehrfachauswahl",
      );
    }
    return of(tooltip);
  }
}
