import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { ContextMenuEvent } from "@progress/kendo-angular-menu";
import { take } from "rxjs";
import ansichtSelectors from "src/app/core/stores/ansicht/ansicht.selectors";
import { multiAnsichtFeature } from "src/app/core/stores/multiansicht/multiansicht.reducer";
import { AktionEnum } from "src/app/models/enums/aktion";
import { CopyPattern } from "src/app/models/openapi/model/copy-pattern";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzDroplistData } from "src/app/models/viewmodels/sendeplatz-viewmodel";
import { droppedInNewContainer } from "src/app/utils/planungsobjekt-interaktion-utils";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import AnsichtenFacade from "../ansichten.facade";

@Injectable()
export default class KalenderansichtFacade {
  private readonly store = inject(Store);
  private readonly ansichtenFacade = inject(AnsichtenFacade);

  public readonly showKonkurrenzEvents$ = this.ansichtenFacade.showKonkurrenzEvents$;
  public readonly isMengengeruestErlaubt$ = this.ansichtenFacade.isMengengeruestErlaubt$;

  public readonly kalenderAnsicht$ = this.store.select(ansichtSelectors.selectKalenderansicht);
  public readonly kalenderAnsichtHeader$ = this.store.select(
    ansichtSelectors.selectMultiKalenderansichtHeader,
  );
  public readonly multiAnsichtViewModel$ = this.store.select(
    multiAnsichtFeature.selectMultiAnsichtViewModel,
  );
  public readonly sendeplatze$ = this.store.select(
    ansichtSelectors.selectKalenderansichtSendeplaetze,
  );

  public readonly hasPreviousAnsicht$ = this.ansichtenFacade.hasPreviousAnsicht$;

  public readonly hasNextAnsicht$ = this.ansichtenFacade.hasNextAnsicht$;

  openGetitWindow(sendeplatz: SendeplatzDto, isVorschlag: boolean = false) {
    const sendeplatzKey = SendeplatzUtils.getSendeplatzKey(sendeplatz);
    this.ansichtenFacade.openGetitWindow({
      type: "Sendeplatz",
      kanal: sendeplatzKey.kanal,
      kalendertag: sendeplatzKey.kalendertag,
      beginnzeit: sendeplatzKey.beginnzeit,
      laenge: sendeplatzKey.laenge,
      isVorschlag: isVorschlag,
    });
  }

  openSendeplatzWindow(sendeplatz: SendeplatzDto) {
    this.ansichtenFacade.openSendeplatzWindow(sendeplatz);
  }

  openPlanungshinweiseWindow(sendeplatz: SendeplatzDto) {
    this.ansichtenFacade.openPlanungshinweiseWindow({
      kanal: sendeplatz.kanal,
      start: new Date(sendeplatz.effektivVon),
      end: new Date(sendeplatz.effektivBis),
    });
  }

  loadPreviousAnsicht() {
    this.ansichtenFacade.loadPreviousAnsicht();
  }
  loadNextAnsicht() {
    this.ansichtenFacade.loadNextAnsicht();
  }

  sendeplatzContextMenuSelect(e: ContextMenuEvent, sendeplatz: SendeplatzDto): void {
    const {
      data: { action: actionEnum },
    } = e.item as {
      data: {
        action: AktionEnum;
        copyPattern: CopyPattern;
      };
    };
    switch (actionEnum) {
      case AktionEnum.NACH_PHAROS_UEBERTRAGEN:
        this.ansichtenFacade.openNachPharosUebertragenWindow(sendeplatz);
        break;
      case AktionEnum.SENDEPLATZ_DETAILS:
        this.openSendeplatzWindow(sendeplatz);
        break;
    }
  }

  movePlanungsobjektInAnsicht(
    event: CdkDragDrop<SendeplatzDto, SendeplatzDroplistData, PlanungsobjektDto>,
    newSendeplatz: SendeplatzDto,
  ) {
    const oldSendeplatz = SendeplatzUtils.isDraggedFromSendeplatzNotMerkliste(
      event.previousContainer.data,
    );
    const isNewDropList = droppedInNewContainer(event);
    if (isNewDropList) {
      this.multiAnsichtViewModel$.pipe(take(1)).subscribe((multiAnsichtViewModel) => {
        this.ansichtenFacade.movePlanungsobjektInAnsicht(
          {
            planungsobjekt: event.item.data,
            oldSendeplatz,
            newSendeplatz,
          },
          multiAnsichtViewModel,
        );
      });
    }
  }
}
