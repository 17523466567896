import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { DateFnsService } from "src/app/services/date-fns.service";
import { AbstractControlValueAccessor } from "../abstract-control-value-accessor";

type InternalValue = Date | null;

@Component({
  selector: "app-iso-datetimepicker",
  templateUrl: "./iso-datetimepicker.component.html",
  styleUrls: ["./iso-datetimepicker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IsoDateTimePickerComponent),
      multi: true,
    },
  ],
})
export class IsoDateTimePickerComponent extends AbstractControlValueAccessor<string> {
  @Input() focusedDate: Date | number | string | null | undefined;
  @Input() maxDate: Date | number | string | null | undefined;
  @Input() minDate: Date | number | string | null | undefined;

  internalDate: InternalValue = null;

  writeValue(dateString: string): void {
    this.internalDate = dateString ? new Date(dateString) : null;
  }

  onValueChange(event: InternalValue): void {
    if (event) {
      this._onChange(this.toPublitIsoString(event));
    } else {
      this._onChange(null);
    }
  }

  /**
   * Konvertiert ein Datum in die von der publish.it API erwartete ISO-String-Formatierung
   * ohne Zeitzoneninformationen (z.B. 2022-01-04T21:45:00)
   */
  private toPublitIsoString(date: Date): string {
    return DateFnsService.toPublitIsoString(date);
  }
}
