<!-- Chip-Template: Als Template angelegt, damit er beim cdkDragPreview zusätzlich verwendet werden kann -->
<ng-template #chipTemplate let-planungsobjekt="planungsobjekt">
  <app-planungsobjekt-vorgeplant
    [ansichtViewModel]="ansichtViewModel"
    [isMehrfachauswahlEnabled]="mehrfachauswahlActive$ | async"
    [isMengengeruestErlaubt]="isMengengeruestErlaubt"
    [planungsobjekt]="planungsobjekt"
    [planungsobjekteInMehrfachauswahlCount]="planungsobjekteInMehrfachauswahl.length"
    [schemaplatz]="schemaplatz"
    [sendeplatz]="sendeplatz"
    [target]="target"
  >
  </app-planungsobjekt-vorgeplant>
</ng-template>

<!-- Alle Chips -->
<ng-container *ngFor="let planungsobjekt of planungsobjekte$ | async">
  <ng-container
    [ngTemplateOutlet]="chipTemplate"
    [ngTemplateOutletContext]="{ planungsobjekt: planungsobjekt }"
  ></ng-container>

  @if (debugService.showDebugData) {
    <pre>{{ planungsobjekt | json }}</pre>
  }
</ng-container>

@if (debugService.showDebugData) {
  <pre>{{ SendeplatzUtils.getSendeplatzKey(this.sendeplatz) | json }}</pre>
}
