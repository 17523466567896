import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { Observable, map } from "rxjs";
import { Icons } from "src/app/models/icons";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { Redaktion } from "src/app/models/openapi/model/redaktion";
import { PlanungsobjektStylePipe } from "src/app/shared/pipes/planungsobjekt-style.pipe";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";

@Component({
  selector: "app-planungsobjekt-on-blockansicht-chip",
  templateUrl: "./planungsobjekt-on-blockansicht-chip.component.html",
  styleUrls: ["./planungsobjekt-on-blockansicht-chip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanungsobjektOnBlockansichtChipComponent implements OnChanges {
  @Input() planungsobjekt: PlanungsobjektDto;
  @Input() borderRight = {};
  @Input() borderLeft = {};
  @Input() width = "100%";
  @Input() isMengengeruestErlaubt: boolean;
  Icons = Icons;

  Redaktion = Redaktion;

  styles$: Observable<{ [klass: string]: string }>;

  constructor(private planungsobjektStylePipe: PlanungsobjektStylePipe) {}

  ngOnChanges(): void {
    this.styles$ = this.planungsobjektStylePipe.transform(this.planungsobjekt.id).pipe(
      map((styles) => ({
        width: this.width,
        ...styles,
        ...this.borderRight,
        ...this.borderLeft,
      })),
    );
  }

  istMitProduktdatenbankVerknuepft = PlanungsobjektUtils.isConnectedWithProductDb;
}
