import { Component, Input, OnChanges, Optional, Self, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { DropDownFillMode, ItemDisabledFn } from "@progress/kendo-angular-dropdowns";
import { SelectOption } from "src/app/models/enums/enum-base";
import { FarbgebungObject } from "src/app/models/viewmodels";
import { MessageService } from "src/app/services/message.service";
import { OnChangeFn, OnTouchedFn } from "src/app/utils/form-utils";
import { noop } from "src/app/utils/function-utils";
import { ColorMapToReturnValue } from "../../pipes/color-map-to-ng-style/color-map-to-ng-style.pipe";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent<T extends string | number> implements ControlValueAccessor, OnChanges {
  @Input() label = "";
  @Input() options: SelectOption<T | null>[] = [];
  @Input() fillMode: DropDownFillMode = "solid";
  @Input() farbgebungEnumStyleMap?: Record<string, FarbgebungObject<string>>;
  @Input() isSenderPlattform = false;
  @Input() disabled = false;
  /**
   * optionales Default-Item, welches als erstes Element in der Liste angezeigt wird.
   *
   * Kann z.B. verwendet werden, um ein "Bitte wählen" oder "leeres" Element anzuzeigen.
   * Wert des Default-Items kann z.B. `null` oder ein Enum-Wert für "keine" sein.
   */
  @Input() defaultItem: SelectOption<T | null> | undefined = undefined;
  /**
   * Wenn true, wird am Label ein Ladeindikator angezeigt.
   * Kann verwendet werden, wenn {@link options} asynchron geladen werden.
   */
  @Input() loading = false;

  @Input() noDataText = this.messageService.get("kendo.dropdownlist.noDataText");

  value: T | null;
  changed: OnChangeFn<T | null> = noop;
  touched: OnTouchedFn = noop;

  isDisabledInternal?: boolean;
  dropdownListDefaultElementHeight = 36;
  dropdownListDefaultMaxHeight = 432; // Entspricht 12 Elementen
  dropdownListHeight = 200; // 200px ist der Kendo Default
  ColorMapToReturnValue = ColorMapToReturnValue;

  isItemDisabled: ItemDisabledFn = (option): boolean => {
    const selectOption = option.dataItem as SelectOption<T>;
    return selectOption.disabled ?? this.isDisabledInternal ?? false;
  };

  constructor(
    protected messageService: MessageService,
    /**
     * Möglichkeit um in einem {@link ControlValueAccessor} die {@link NgControl} zu injizieren.
     * Dabei darf nicht `provide: NG_VALUE_ACCESSOR` im {@link Component}-Decorator verwendet werden,
     * da es sonst zu einer Cyclic Dependency kommt. Stattdessen muss der {@link NgControl.valueAccessor}
     * im Konstruktor gesetzt werden.
     *
     * Es muss zusätzlich {@link Self} verwendet werden, um das korrekte Control zu injizieren und
     * nicht das Control eines Parents.
     *
     * Siehe auch:
     * {@link https://stackoverflow.com/a/54758750/2664568}
     */
    @Self() @Optional() protected ngControl: NgControl | null,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.isDisabledInternal = this.disabled;
    }
    if (changes.options?.currentValue) {
      const calculatedMaxHeight = this.dropdownListDefaultElementHeight * this.options.length;
      this.dropdownListHeight =
        calculatedMaxHeight > this.dropdownListDefaultMaxHeight
          ? this.dropdownListDefaultMaxHeight
          : calculatedMaxHeight;
    }
  }

  writeValue(value: T | null): void {
    this.value = value;
  }

  registerOnChange(fn: OnChangeFn<T | null>): void {
    this.changed = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this.touched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabledInternal = isDisabled;
  }

  onInput(value: T | null) {
    this.value = value;
    this.changed(value);
    this.touched();
  }

  onBlur() {
    this.touched();
  }
}
