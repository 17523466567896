import { createSelector } from "@ngrx/store";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { MerklisteKategorie } from "src/app/models/openapi/model/merkliste-kategorie";
import { OnDemandFilter } from "src/app/models/openapi/model/on-demand-filter";
import { sortMerklisten } from "src/app/utils/sort-utils";
import ansichtSelectors from "../ansicht/ansicht.selectors";
import { merklisteFeature } from "./merkliste.reducer";

const selectMerklistenForAnsichtIdFromQueryParam = createSelector(
  merklisteFeature.selectAll,
  ansichtSelectors.selectAnsichtViewModelForAnsichtIdFromQueryParam,
  (merklisten, ansicht) => {
    if (!ansicht) {
      return [];
    }

    return merklisten
      .filter(
        (merkliste) =>
          (merkliste.kategorie === MerklisteKategorie.ANSICHT &&
            merkliste.ansichtId === ansicht.id) ||
          (merkliste.kategorie === MerklisteKategorie.AUSSPIELWEG &&
            merkliste.ausspielweg === ansicht.kanal),
      )
      .sort(sortMerklisten);
  },
);

const selectMerklisteByMerklisteId = (merklisteId: string) => {
  return createSelector(
    merklisteFeature.selectAll,
    (merklistenEntities): MerklisteDto | null =>
      merklistenEntities.find((merkliste) => merkliste.id === merklisteId) ?? null,
  );
};

const selectMerklisteTitleForMerklisteId = (merklisteId: string) => {
  return createSelector(
    selectMerklisteByMerklisteId(merklisteId),
    ansichtSelectors.selectAnsichtViewModelForAnsichtIdFromQueryParam,
    (merkliste, ansichtViewModel) => {
      if (!merkliste || !ansichtViewModel) return "";
      const merklistenTitle = merkliste?.name ?? `Merkliste ${merkliste?.nummer}`;
      return `Merkliste "${merklistenTitle}" ${ansichtViewModel?.titel} ${ansichtViewModel?.year}`;
    },
  );
};

const selectMerklistenByOnDemandFilter = (filter: OnDemandFilter) => {
  return createSelector(merklisteFeature.selectAll, (merklistenEntities) => {
    return merklistenEntities
      .filter(
        (merkliste) =>
          (merkliste.kategorie === MerklisteKategorie.ON_DEMAND &&
            merkliste.onDemandFilter === filter) ||
          (merkliste.kategorie === MerklisteKategorie.AUSSPIELWEG &&
            merkliste.ausspielweg === Kanal.ZDF_MEDIATHEK),
      )
      .sort(sortMerklisten);
  });
};

export default {
  selectMerklistenForAnsichtIdFromQueryParam,
  selectMerklisteTitleForMerklisteId,
  selectMerklisteByMerklisteId,
  selectMerklistenByOnDemandFilter,
};
