import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { WindowCloseResult } from "@progress/kendo-angular-dialog";
import { GetitWindowInput } from "src/app/core/stores/getit-window/getit-model";
import { GetItImportWindowResult as GetitImportWindowResult } from "src/app/shared/windows/get-it-import-window/get-it-import-window.component";

/**
 * Zusammengefasste Actions rund um mehrere, einzelne und fachlich-unterschiedliche Windows
 */
export const getitWindowActions = createActionGroup({
  source: "Getit Window",
  events: {
    openGetitWindow: props<{
      windowInputs: GetitWindowInput;
    }>(),
    handleGetitWindowResult: props<{
      result: GetitImportWindowResult | WindowCloseResult;
    }>(),
    closeWindow: emptyProps(),
  },
});
