import { getRouterSelectors } from "@ngrx/router-store";
import { createSelector } from "@ngrx/store";
import { getDefaultYear } from "src/app/utils/date-utils";

const { selectQueryParam, selectUrl } = getRouterSelectors();

const selectAnsichtIdQueryParam = createSelector(
  selectQueryParam("ansichtId"),
  (ansichtId) => ansichtId,
);

const selectKanalQueryParam = createSelector(selectQueryParam("kanal"), (kanal) => kanal);

const selectEkAnsichtFromUrlPath = createSelector(selectUrl, (url) => url.includes("ek-ansicht"));

const selectIsOnDemandFromUrlPath = createSelector(selectUrl, (url) => url.includes("vod-ansicht"));

const selectYearQueryParam = createSelector(selectQueryParam("year"), (year) => Number(year));

const selectYearFromQueryParamOrDefault = createSelector(selectYearQueryParam, (year) => {
  return year || getDefaultYear();
});

/**
 * Wählt das Jahr aus den On-Demand-Abfrageparametern aus und fällt auf das Jahr-Abfrageparameter zurück, wenn die On-Demand-Abfrageparameter nicht festgelegt sind.
 * Damit sollten alle spezifischen Routen zu Jahren auf dem Drawer abgefangen werden. Vielleicht bietet es sich perspektivisch an,
 * die Jahre in einem eigenen Store zu speichern, um die Jahre für die Drawer-Links zu verwenden.
 */
const selectYearFromAnyQueryParam = createSelector(
  selectQueryParam("von"),
  selectQueryParam("bis"),
  selectYearFromQueryParamOrDefault,
  (von, bis, yearParam) =>
    von && bis && new Date(von).getFullYear() === new Date(bis).getFullYear()
      ? new Date(von).getFullYear()
      : yearParam,
);

export default {
  selectQueryParam,
  selectUrl,
  selectAnsichtIdQueryParam,
  selectKanalQueryParam,
  selectYearQueryParam,
  selectYearFromQueryParamOrDefault,
  selectEkAnsichtFromUrlPath,
  selectIsOnDemandFromUrlPath,
  selectYearFromAnyQueryParam,
};
