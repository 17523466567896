/**
 * Zdf.Publit.Vorplanung.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type GetitProduktStatus =
  | "Abgenommen"
  | "Ausgestrahlt"
  | "GenehmigtProduktionsbewilligung"
  | "GenehmigtStoffzulassung"
  | "Inaktiv"
  | "InProduktion"
  | "Offen";

export const GetitProduktStatus = {
  ABGENOMMEN: "Abgenommen" as GetitProduktStatus,
  AUSGESTRAHLT: "Ausgestrahlt" as GetitProduktStatus,
  GENEHMIGT_PRODUKTIONSBEWILLIGUNG: "GenehmigtProduktionsbewilligung" as GetitProduktStatus,
  GENEHMIGT_STOFFZULASSUNG: "GenehmigtStoffzulassung" as GetitProduktStatus,
  INAKTIV: "Inaktiv" as GetitProduktStatus,
  IN_PRODUKTION: "InProduktion" as GetitProduktStatus,
  OFFEN: "Offen" as GetitProduktStatus,
};
