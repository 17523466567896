import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs";
import { BenutzerApiService } from "src/app/api/benutzer/benutzer.api.service";
import { permissionActions } from "./permissions.actions";

@Injectable()
export class PermissionEffects {
  loadPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(permissionActions.loadPermissions),
      switchMap(() => {
        return this.apiService
          .getBenutzerPermissions$()
          .pipe(map((permissions) => permissionActions.loadPermissionsSuccess({ permissions })));
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private apiService: BenutzerApiService,
  ) {}
}
