import { Component, Input } from "@angular/core";
import { SVGIcon, checkIcon, questionCircleIcon } from "@progress/kendo-svg-icons";
import { Icons } from "src/app/models/icons";
import { Kanal } from "src/app/models/openapi/model/kanal";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { Publikationsart } from "../../planungsobjekt-window.model";

@Component({
  selector: "app-planungsbezug",
  templateUrl: "./planungsbezug.component.html",
  styleUrls: ["./planungsbezug.component.scss"],
})
export class PlanungsbezugComponent {
  @Input({ required: true }) kanal: Kanal;
  @Input({ required: true }) planungskontext: Planungskontext;
  @Input({ required: true }) publikationsart: Publikationsart;

  UnbekanntIcon = questionCircleIcon;

  readonly planungskontextIcons: Record<Planungskontext, SVGIcon> = {
    Unbekannt: questionCircleIcon,
    Vorgeplant: checkIcon,
    Vorgemerkt: Icons.merklisteIcon,
    Vorgeschlagen: Icons.buttonIcon,
  };

  readonly publikationsartIcons: Record<Publikationsart, SVGIcon> = {
    linear: Icons.linear,
    ondemand: Icons.onDemand,
  };
}
