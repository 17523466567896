import { ControlValueAccessor as NgControlValueAccessor } from "@angular/forms";
import { OnChangeFn, OnTouchedFn } from "src/app/utils/form-utils";
import { noop } from "src/app/utils/function-utils";

/**
 * Abstrakte Klasse, die die Implementierung von ControlValueAccessor erleichtert.
 */
export abstract class AbstractControlValueAccessor<T = any> implements NgControlValueAccessor {
  protected isDisabled = false;

  abstract writeValue(value: T | null): void;

  _onChange: OnChangeFn<T | null> = noop;
  _onTouched: OnTouchedFn = noop;

  registerOnChange(fn: OnChangeFn<T | null>): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: OnTouchedFn): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
