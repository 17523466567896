import { Component, Input } from "@angular/core";
import { SVGIcon } from "@progress/kendo-svg-icons";
import { Icons } from "src/app/models/icons";
import { SenderPlattform } from "src/app/models/openapi/model/sender-plattform";

export interface KonkurrenzIconWithWidth {
  icon: SVGIcon;
  width: number;
}

@Component({
  selector: "app-konkurrenzprogramm-icons",
  templateUrl: "./konkurrenzprogramm-icons.component.html",
  styleUrls: ["./konkurrenzprogramm-icons.component.scss"],
})
export class KonkurrenzprogrammIconsComponent {
  @Input({ required: true }) sender: SenderPlattform;

  Icons = Icons.konkurrenzprogrammIcons;
  DEFAULT_WIDTH = 15;

  protected readonly iconsBySender: Record<SenderPlattform, KonkurrenzIconWithWidth> = {
    AmazonPrime: { icon: this.Icons.AmazonPrime, width: 12 },
    ARD: { icon: this.Icons.ARD, width: 10 },
    DAZN: { icon: this.Icons.DAZN, width: 10 },
    DisneyPlus: { icon: this.Icons.DisneyPlus, width: this.DEFAULT_WIDTH },
    Eurosport: { icon: this.Icons.Eurosport, width: this.DEFAULT_WIDTH },
    Joyn: { icon: this.Icons.Joyn, width: 25 },
    Kabel1: { icon: this.Icons.Kabel1, width: this.DEFAULT_WIDTH },
    MagentaTV: { icon: this.Icons.MagentaTV, width: 12 },
    Netflix: { icon: this.Icons.Netflix, width: 6 },
    Pro7: { icon: this.Icons.Pro7, width: 10 },
    RTL: { icon: this.Icons.RTL, width: 27 },
    RTL2: { icon: this.Icons.RTL2, width: 10 },
    RTLPlus: { icon: this.Icons.RTLPlus, width: 30 },
    Sat1: { icon: this.Icons.Sat1, width: 10 },
    Sky: { icon: this.Icons.Sky, width: this.DEFAULT_WIDTH },
    Vox: { icon: this.Icons.Vox, width: 23 },
    Sonstiges: { icon: this.Icons.Sonstiges, width: this.DEFAULT_WIDTH },
  };
}
