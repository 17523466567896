import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createFeature, createReducer, on } from "@ngrx/store";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { FeatureKey } from "../feature.keys";
import { onDemandActions } from "../on-demand/on-demand.actions";
import { planungsobjektActions } from "../planungsobjekt/planungsobjekt.actions";
import { merklisteActions } from "./merkliste.actions";

export interface MerklisteState extends EntityState<MerklisteDto> {
  loading: boolean;
}

export const createMerklisteKey = (merkliste: MerklisteDto) =>
  [merkliste.kategorie, merkliste.nummer, merkliste.ansichtId, merkliste.ausspielweg].join("-");

export const merklisteAdapter: EntityAdapter<MerklisteDto> = createEntityAdapter<MerklisteDto>({
  selectId: createMerklisteKey,
});

export const initialMerklisteState: MerklisteState = merklisteAdapter.getInitialState({
  loading: false,
});

export const merklisteFeature = createFeature({
  name: FeatureKey.Merkliste,
  reducer: createReducer(
    initialMerklisteState,
    on(
      merklisteActions.loadMerklistenAnsicht,
      merklisteActions.loadMerklistenOnDemand,
      (currentState): MerklisteState => {
        return { ...currentState, loading: true };
      },
    ),
    on(merklisteActions.loadMerklistenSuccess, (currentState, { merklisten }) =>
      merklisteAdapter.upsertMany(merklisten, { ...currentState, loading: false }),
    ),
    /**
     * Wenn wir erstmalig einen Eintrag auf der Linear Merkliste anlegen, wird auch die Merkliste angelegt.
     * In diesem Fall müssen wir die Platzhalter Id der Merkliste im Store mit der jetzt neu erstellten, echten Id ersetzen.
     */
    on(
      planungsobjektActions.createPlanungsobjektOnMerklisteSuccess,
      (currentState, { planungsobjekt: planungsobjektLinear, merkliste }) => {
        return merklisteAdapter.updateOne(
          {
            id: createMerklisteKey(merkliste),
            // wurde ein Eintrag auf einer Merkliste erstellt, besitzt dieser immer eine MerklisteId
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            changes: { id: planungsobjektLinear.merklisteId! },
          },
          currentState,
        );
      },
    ),
    /**
     * Wenn wir erstmalig einen Eintrag auf eine Linear Merkliste verschoben, wird auch die Merkliste angelegt.
     * In diesem Fall müssen wir die Platzhalter Id der Merkliste im Store mit der jetzt neu erstellten, echten Id ersetzen.
     */
    on(
      planungsobjektActions.movePlanungsobjektToMerklisteSuccess,
      (currentState, { planungsobjekt: response, merkliste }) => {
        return merklisteAdapter.updateOne(
          {
            id: createMerklisteKey(merkliste),
            // wurde ein Eintrag auf einer Merkliste erstellt, besitzt dieser immer eine MerklisteId
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            changes: { id: response.result.merklisteId! },
          },
          currentState,
        );
      },
    ),
    /**
     * Wenn wir erstmalig einen Eintrag auf der OnDemand Merkliste anlegen, wird auch die Merkliste angelegt.
     * In diesem Fall müssen wir die Platzhalter Id der Merkliste im Store mit der jetzt neu erstellten, echten Id ersetzen.
     */
    on(
      onDemandActions.createPlanungsobjektOnDemandVorgemerktSuccess,
      (currentState, { planungsobjektOnDemand, merkliste }) => {
        return merklisteAdapter.updateOne(
          {
            id: createMerklisteKey(merkliste),
            // wurde ein Eintrag auf einer Merkliste erstellt, besitzt dieser immer eine MerklisteId
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            changes: { id: planungsobjektOnDemand.merklisteId! },
          },
          currentState,
        );
      },
    ),
    /**
     * Wenn wir erstmalig einen Eintrag auf eine OnDemand Merkliste verschoben, wird auch die Merkliste angelegt.
     * In diesem Fall müssen wir die Platzhalter Id der Merkliste im Store mit der jetzt neu erstellten, echten Id ersetzen.
     */
    on(
      onDemandActions.verschiebePlanungsobjektOnDemandZuVorgemerktSuccess,
      (currentState, { planungsobjektOnDemand, merkliste }) => {
        return merklisteAdapter.updateOne(
          {
            id: createMerklisteKey(merkliste),
            // wurde ein Eintrag auf einer Merkliste erstellt, besitzt dieser immer eine MerklisteId
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            changes: { id: planungsobjektOnDemand.merklisteId! },
          },
          currentState,
        );
      },
    ),
    /**
     * Auch wenn es zuvor noch keine Interaktion mit einer Merkliste gegeben hat und sie einfach nur umbenannt wird,
     * wird sie in diesem Moment erstellt und erhält eine technische Id.
     * In diesem Fall müssen wir die Platzhalter Id der Merkliste im Store mit der jetzt neu erstellten, echten Id ersetzen.
     */
    on(merklisteActions.renameMerklisteSuccess, (currentState, { merkliste }) => {
      return merklisteAdapter.updateOne(
        {
          id: createMerklisteKey(merkliste),
          changes: { id: merkliste.id, name: merkliste.name },
        },
        currentState,
      );
    }),
  ),
  extraSelectors: ({ selectMerklisteState }) => ({
    ...merklisteAdapter.getSelectors(selectMerklisteState),
  }),
});
