import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BenachrichtigungComponentVm } from "src/app/core/stores/benachrichtigung/benachrichtigung.model";
import { createMerklisteKey } from "src/app/core/stores/merkliste/merkliste.reducer";
import { SidebarTabEnum, SidebarTabType } from "src/app/models/enums/ansichten-sidebar";
import { Icons } from "src/app/models/icons";
import { Layout } from "src/app/models/openapi/model/layout";
import { MerklisteDto } from "src/app/models/openapi/model/merkliste-dto";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { ZoomLevel } from "src/app/models/openapi/model/zoom-level";
import { AnsichtViewModel } from "src/app/models/viewmodels/ansicht-viewmodel";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {
  @Input({ required: true }) sidebarExpanded: boolean;
  @Input({ required: true }) showNotizen: boolean;
  @Input({ required: true }) showKonkurrenzEvents: boolean;
  @Input({ required: true }) activeSidebarTab: SidebarTabType;
  @Input({ required: true }) planungsobjekteInMehrfachauswahl: PlanungsobjektDto[];
  @Input({ required: true }) selectedAnsichtViewModel: AnsichtViewModel;
  @Input({ required: true }) unreadNotificationForCurrentAnsicht: boolean;
  @Input({ required: true }) merklisten: MerklisteDto[];
  @Input({ required: true }) zoomLevel: ZoomLevel;
  @Input({ required: true }) benachrichtigungenComponentData: BenachrichtigungComponentVm[];

  @Output() setActiveSidebarTab = new EventEmitter<SidebarTabEnum>();
  @Output() toggleShowKonkurrenzEvents = new EventEmitter<void>();
  @Output() toggleShowNotizen = new EventEmitter<void>();
  @Output() triggerReportDownload = new EventEmitter<string>();
  @Output() selectedZoomLevelChanged = new EventEmitter<ZoomLevel>();
  @Output() openMengengeruesteWindow = new EventEmitter<void>();
  @Output() openCreateEkWindow = new EventEmitter<void>();

  SidebarTabEnum = SidebarTabEnum;
  Icons = Icons;

  Layout = Layout;

  emitSetActiveSidebarTab(tabTitle: SidebarTabEnum) {
    if (!Object.values(SidebarTabEnum).includes(tabTitle)) {
      throw new Error(`Unbekannter Tab Titel: ${tabTitle}`);
    }

    this.setActiveSidebarTab.emit(tabTitle);
  }

  emitToggleShowKonkurrenzEvents() {
    this.toggleShowKonkurrenzEvents.emit();
  }

  emitToggleShowNotizen() {
    this.toggleShowNotizen.emit();
  }

  emitTriggerReportDownload(ansichtId: string) {
    this.triggerReportDownload.emit(ansichtId);
  }

  emitSelectedZoomLevelChanged(zoomLevel: ZoomLevel) {
    this.selectedZoomLevelChanged.emit(zoomLevel);
  }

  emitOpenMengengeruesteWindow() {
    this.openMengengeruesteWindow.emit();
  }

  emitOpenEkWindow() {
    this.openCreateEkWindow.emit();
  }

  trackMerkliste(_index: number, merkliste: MerklisteDto) {
    // id darf hier nicht verwendet werden, da die Merklisten on-the-fly
    // generiert werden und initial einer 0er GUID haben.
    return createMerklisteKey(merkliste);
  }
}
