import { Injectable } from "@angular/core";
import { take } from "rxjs";
import { PharosService } from "../api/pharos/pharos.service";
import { SendeplatzKeyDto } from "../models/openapi/model/sendeplatz-key-dto";
import {
  PharosExportWindowComponent,
  PharosWindowInput,
} from "../shared/dialogs/pharos-export-window/pharos-export-window.component";
import { BigWindow } from "../shared/windows/window.templates";
import { CustomWindowService } from "./custom-window.service";

@Injectable({
  providedIn: "root",
})
export class PharosWindowService {
  constructor(
    private windowService: CustomWindowService,
    private pharosService: PharosService,
  ) {}

  // TODO PUBLIT-1381 nach NgRx migrieren
  onOpenNachPharosUebertragenWindow(sendeplatzKey: SendeplatzKeyDto) {
    // Daten für Pharos-Tabelle holen
    this.pharosService
      .getPharosTransferTable$({
        sendeplatzKey,
      })
      .pipe(take(1))
      .subscribe({
        next: (pharosContent) => {
          // Window aufbauen
          const windowRef = this.windowService.open<PharosExportWindowComponent>({
            content: PharosExportWindowComponent,
            title: "Nach Pharos übertragen",
            ...BigWindow({ dataTestId: "pharos-export-window" }),
          });
          // Daten für Window zusammenstellen
          const data: PharosWindowInput = {
            prepareTransferToPharosQuery: { sendeplatzKey },
            pharosContent,
          };
          windowRef.content.instance.data = data;
        },
        error: (error: unknown) => {
          // damit wir noch zum Global Error Handler kommen und die Notification anzeigen
          throw error;
        },
      });
  }
}
