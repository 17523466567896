<form
  [appDisableFormGroup]="('planen' | able: (sendeplatz | asSubject: 'Sendeplatz')) === false"
  [formGroup]="formGroup"
>
  <div class="flex-container">
    <div class="flex-item">Sendeplatz Datum: {{ sendeplatz.sendetag | parseDate }}</div>
  </div>
  <div class="flex-container">
    <div class="flex-item">Schemaplatz Beginnzeit: {{ sendeplatz.beginnzeit }}</div>
    <div class="flex-item">Schemaplatz Länge (min): {{ sendeplatz.laenge / 60 }}</div>
  </div>
  <div class="flex-container">
    <app-rounded-timepicker
      class="flex-item"
      data-testid="abweichendeBeginnzeit"
      formControlName="abweichendeBeginnzeit"
      label="Abweichende Beginnzeit"
    ></app-rounded-timepicker>

    <app-masked-input
      class="flex-item"
      data-testid="abweichendeLaenge"
      formControlName="abweichendeLaenge"
      label="Abweichende Länge (min:sek)"
    ></app-masked-input>
  </div>
  <kendo-label class="flex-item notiz" text="Notiz">
    <kendo-textarea
      class="k-disabled"
      data-testid="notiz"
      formControlName="notiz"
      resizable="auto"
      [readonly]="true"
    ></kendo-textarea>
  </kendo-label>
</form>
<div class="window-action-buttons">
  <button
    class="primary-button"
    kendoButton
    themeColor="primary"
    type="button"
    [disabled]="!formGroup.valid || formGroup.pristine"
    (click)="onConfirmAction()"
  >
    Speichern
  </button>
  <button fillMode="outline" kendoButton type="button" (click)="onCancelAction()">Abbrechen</button>
</div>
