import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AnsichtDto } from "src/app/models/openapi/model/ansicht-dto";
import { PublitFrontendSettings } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AnsichtenApiService {
  private apiUrl = this.settings.apiUrl;

  constructor(
    private http: HttpClient,
    private settings: PublitFrontendSettings,
  ) {}

  getAllAnsichten$(): Observable<AnsichtDto[]> {
    return this.http.get<AnsichtDto[]>(this.apiUrl + "Ansicht");
  }
}
