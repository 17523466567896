<table class="kalenderansicht-table">
  <tr class="kalenderansicht-header dts-select-exclude">
    @for (header of facade.kalenderAnsichtHeader$ | async; track $index) {
      <th>
        <span class="kalenderansicht-header-wochentag">{{ header.wochentag }}</span
        ><span>{{ header.beginnzeiten.join(" | ") }}</span>
      </th>
    }
  </tr>

  @for (
    sendeplaetzeYear of facade.sendeplatze$ | async | keyvalue;
    track sendeplaetzeYear.key;
    let firstYear = $first
  ) {
    <app-kalenderansicht-sendeplatz-jahr
      [firstYear]="firstYear"
      [sendeplaetzeYear]="sendeplaetzeYear"
    ></app-kalenderansicht-sendeplatz-jahr>
  }
</table>
<div class="next-year-button-divider">
  <button
    class="k-px-3"
    data-testid="next-year-arrow-button"
    fillMode="flat"
    kendoButton
    size="none"
    title="Lade Ansicht für nächstes Jahr"
    [disabled]="(facade.hasNextAnsicht$ | async) === false"
    [svgIcon]="Icons.arrowDownIcon"
    (click)="facade.loadNextAnsicht()"
  ></button>
</div>
