import { Pipe, PipeTransform } from "@angular/core";
import { PlanungsobjektDto } from "src/app/models/openapi/model/planungsobjekt-dto";
import { comparePlanungsobjekteByPublikationsplanung } from "src/app/utils/sort-utils";

@Pipe({
  name: "singleLineNotes",
})
export class SingleLineNotesPipe implements PipeTransform {
  transform(planungsobjektFassungen: PlanungsobjektDto[]): string {
    const planungsobjektfWithNotizen = planungsobjektFassungen.filter(
      (planungsobjekt) => !!planungsobjekt.notiz,
    );

    // Keine VPF hat eine Notiz, dann nichts anzeigen
    // Keine VPF, dann nichts anzeigen (indirekt mitgeprüft)
    if (planungsobjektfWithNotizen.length == 0) return "";

    // In der Listenansichten ist diese Sortierung keinen Effekt,
    // da alle Planungsobjekte auf dem gleichen Sendeplatz liegen.
    // Dadurch wird die ursprüngliche Sortierung der Eingabewerte
    // beibehalten, es muss also bereits im Selectors (oder anderer Quelle)
    // korrekt sortiert werden.
    planungsobjektFassungen = planungsobjektFassungen.sort(
      comparePlanungsobjekteByPublikationsplanung,
    );

    // "||" nur am Anfang oder am Ende, wenn die erste VPF oder die letzte VPF eine leere Notiz hat
    return planungsobjektFassungen.reduce(
      (previousValue, planungsobjekt, i) =>
        previousValue +
        // Drei Leerzeichen für VPF mit leeren Notizen
        ` ${planungsobjekt.notiz ? planungsobjekt.notiz : "\xa0\xa0\xa0"} ${
          i < planungsobjektFassungen.length - 1 ||
          (i == planungsobjektFassungen.length - 1 && !planungsobjekt.notiz)
            ? "||"
            : ""
        }`,
      planungsobjektFassungen[0].notiz ? "" : "||",
    );
  }
}
