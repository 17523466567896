import { Injectable } from "@angular/core";
import { MessageService as KendoMessageService } from "@progress/kendo-angular-l10n";

const customMessages = {
  "kendo.numerictextbox.increment": "",
  "kendo.numerictextbox.decrement": "",
  "kendo.datepicker.today": "Heute",
  "kendo.datepicker.toggle": "Kalender-Auswahl",
  "kendo.timepicker.toggle": "Zeit-Auswahl",
  "kendo.timepicker.accept": "Ok",
  "kendo.timepicker.acceptLabel": "",
  "kendo.timepicker.cancelLabel": "",
  "kendo.notification.closeTitle": "",
} satisfies Record<string, string>;

const defaultMessages = {
  "kendo.avatar.avatarAlt": "Avatar",
  "kendo.calendar.today": "Heute",
  "kendo.calendar.prevButtonTitle": "Vorheriger Zeitraum",
  "kendo.calendar.nextButtonTitle": "Nächster Zeitraum",
  "kendo.calendar.parentViewButtonTitle": "Auf übergeordnete Seite navigieren",
  "kendo.multiviewcalendar.today": "Heute",
  "kendo.multiviewcalendar.prevButtonTitle": "Vorheriger Zeitraum",
  "kendo.multiviewcalendar.nextButtonTitle": "Nächster Zeitraum",
  "kendo.multiviewcalendar.parentViewButtonTitle": "Auf Hauptansicht navigieren",
  "kendo.chat.messagePlaceholder": "Nachricht eintippen...",
  "kendo.chat.send": "Senden...",
  "kendo.chat.messageListLabel": "Nachrichtenliste",
  "kendo.chat.messageBoxInputLabel": "Nachricht",
  "kendo.chat.messageAttachmentLeftArrow": "Vorheriges Element",
  "kendo.chat.messageAttachmentRightArrow": "Nächstes Element",
  "kendo.chat.messageAvatarAlt": "Avatar",
  "kendo.colorgradient.colorGradientNoColor": "Farbverlauf keine Farbe ausgewählt",
  "kendo.colorgradient.colorGradientHandle": "Farbe auswählen",
  "kendo.colorgradient.clearButton": "Wert leeren",
  "kendo.colorgradient.hueSliderHandle": "Farbton setzen",
  "kendo.colorgradient.opacitySliderHandle": "Deckkraft setzen",
  "kendo.colorgradient.hexInputPlaceholder": "HEX Farbe",
  "kendo.colorgradient.redInputPlaceholder": "Rot",
  "kendo.colorgradient.greenInputPlaceholder": "Grün",
  "kendo.colorgradient.blueInputPlaceholder": "Blau",
  "kendo.colorgradient.alphaInputPlaceholder": "Alpha",
  "kendo.colorgradient.redChannelLabel": "Roter Kanal",
  "kendo.colorgradient.greenChannelLabel": "Grüner Kanal",
  "kendo.colorgradient.blueChannelLabel": "Blauer Kanal",
  "kendo.colorgradient.alphaChannelLabel": "Alpha Kanal",
  "kendo.colorgradient.passContrast": "Erfolgreich",
  "kendo.colorgradient.contrastRatio": "Kontrastverhältnis",
  "kendo.colorgradient.failContrast": "Fehlgeschlagen",
  "kendo.colorgradient.formatButton": "Farbformat ändern",
  "kendo.colorpalette.colorPaletteNoColor": "Farbverlauf keine Farbe ausgewählt",
  "kendo.colorpicker.colorGradientNoColor": "Farbverlauf keine Farbe ausgewählt",
  "kendo.colorpicker.colorPaletteNoColor": "Farben Palette kein Farbe ausgewählt",
  "kendo.colorpicker.colorPickerNoColor": "Farbauswahl kein Farbe ausgewählt",
  "kendo.colorpicker.flatColorPickerNoColor": "Farbauswahl kein Farbe ausgewählt",
  "kendo.colorpicker.colorGradientHandle": "Farbe auswählen",
  "kendo.colorpicker.clearButton": "Wert leeren",
  "kendo.colorpicker.hueSliderHandle": "Farbton setzen",
  "kendo.colorpicker.opacitySliderHandle": "Deckkraft setzen",
  "kendo.colorpicker.contrastRatio": "Kontrastverhältnis",
  "kendo.colorpicker.hexInputPlaceholder": "HEX Farbe",
  "kendo.colorpicker.redInputPlaceholder": "Rot",
  "kendo.colorpicker.greenInputPlaceholder": "Grün",
  "kendo.colorpicker.blueInputPlaceholder": "Blau",
  "kendo.colorpicker.alphaInputPlaceholder": "Alpha",
  "kendo.colorpicker.revertSelection": "Auswahl zurücksetzen",
  "kendo.colorpicker.previewColor": "Farbvorschau",
  "kendo.colorpicker.gradientView": "Farbverlauf Ansicht",
  "kendo.colorpicker.paletteView": "Paletten Ansicht",
  "kendo.colorpicker.formatButton": "Farbformat ändern",
  "kendo.colorpicker.applyButton": "Anwenden",
  "kendo.colorpicker.cancelButton": "Abbrechen",
  "kendo.colorpicker.redChannelLabel": "Roter Kanal",
  "kendo.colorpicker.greenChannelLabel": "Grüner Kanal",
  "kendo.colorpicker.blueChannelLabel": "Blauer Kanal",
  "kendo.colorpicker.alphaChannelLabel": "Alpha Kanal",
  "kendo.colorpicker.failContrast": "Fehlgeschlagen",
  "kendo.colorpicker.passContrast": "Erfolgreich",
  "kendo.dateinput.increment": "Wert erhöhen",
  "kendo.dateinput.decrement": "Wert verringern",
  "kendo.datepicker.today": "Heute",
  "kendo.datepicker.toggle": "Kalender umschalten",
  "kendo.datepicker.prevButtonTitle": "Auf vorherige Seite navigieren",
  "kendo.datepicker.nextButtonTitle": "Auf nächste Seite navigieren",
  "kendo.datepicker.parentViewButtonTitle": "Auf Hauptansicht navigieren",
  "kendo.daterangepopup.accept": "Übernehmen",
  "kendo.daterangepopup.acceptLabel": "Übernehmen",
  "kendo.daterangepopup.cancel": "Abbrechen",
  "kendo.daterangepopup.cancelLabel": "Abbrechen",
  "kendo.datetimepicker.dateTab": "Datum",
  "kendo.datetimepicker.dateTabLabel": "Datum",
  "kendo.datetimepicker.timeTab": "Zeit",
  "kendo.datetimepicker.timeTabLabel": "Zeit",
  "kendo.datetimepicker.toggle": "Umschalten",
  "kendo.datetimepicker.accept": "Übernehmen",
  "kendo.datetimepicker.acceptLabel": "Übernehmen",
  "kendo.datetimepicker.cancel": "Abbrechen",
  "kendo.datetimepicker.cancelLabel": "Abbrechen",
  "kendo.datetimepicker.now": "JETZT",
  "kendo.datetimepicker.nowLabel": "Jetzt",
  "kendo.datetimepicker.today": "HEUTE",
  "kendo.datetimepicker.prevButtonTitle": "Auf vorherige Seite navigieren",
  "kendo.datetimepicker.nextButtonTitle": "Auf nächste Seite navigieren",
  "kendo.datetimepicker.hour": "Stunde",
  "kendo.datetimepicker.minute": "Minute",
  "kendo.datetimepicker.second": "Sekunde",
  "kendo.datetimepicker.millisecond": "Millisekunde",
  "kendo.datetimepicker.dayperiod": "Zeitraum",
  "kendo.datetimepicker.parentViewButtonTitle": "Auf Hauptansicht navigieren",
  "kendo.dialog.closeTitle": "Schließen",
  "kendo.autocomplete.noDataText": "Keine Daten gefunden",
  "kendo.autocomplete.clearTitle": "Leeren",
  "kendo.autocomplete.popupLabel": "Optionsliste",
  "kendo.combobox.noDataText": "Keine Daten gefunden",
  "kendo.combobox.clearTitle": "Leeren",
  "kendo.combobox.selectButtonText": "Auswählen",
  "kendo.combobox.popupLabel": "Optionsliste",
  "kendo.dropdownlist.noDataText": "Keine Daten gefunden",
  "kendo.dropdownlist.clearTitle": "Leeren",
  "kendo.dropdownlist.selectButtonText": "Auswählen",
  "kendo.dropdownlist.popupLabel": "Optionsliste",
  "kendo.dropdownlist.filterInputLabel": "Filter",
  "kendo.dropdowntree.noDataText": "Keine Daten gefunden",
  "kendo.dropdowntree.clearTitle": "Leeren",
  "kendo.dropdowntree.selectButtonText": "Auswählen",
  "kendo.dropdowntree.popupLabel": "Optionsliste",
  "kendo.dropdowntree.filterInputLabel": "Filter",
  "kendo.multiselecttree.noDataText": "Keine Daten gefunden",
  "kendo.multiselecttree.clearTitle": "Leeren",
  "kendo.multiselecttree.selectButtonText": "Auswählen",
  "kendo.multiselecttree.popupLabel": "Optionsliste",
  "kendo.multiselecttree.filterInputLabel": "Filter",
  "kendo.multiselecttree.applyButton": "Anwenden",
  "kendo.multiselecttree.cancelButton": "Abbrechen",
  "kendo.multiselecttree.checkAllText": "Alle auswählen",
  "kendo.multicolumncombobox.noDataText": "Keine Daten gefunden",
  "kendo.multicolumncombobox.clearTitle": "Leeren",
  "kendo.multicolumncombobox.popupLabel": "Optionsliste",
  "kendo.multiselect.noDataText": "Keine Daten gefunden",
  "kendo.multiselect.clearTitle": "Leeren",
  "kendo.multiselect.popupLabel": "Optionsliste",
  "kendo.multiselect.applyButton": "Anwenden",
  "kendo.multiselect.cancelButton": "Abbrechen",
  "kendo.editor.alignCenter": "Text zentrieren",
  "kendo.editor.alignJustify": "Ausrichten",
  "kendo.editor.alignLeft": "Text links ausrichten",
  "kendo.editor.alignRight": "Text rechts ausrichten",
  "kendo.editor.backColor": "Hintergrundfarbe",
  "kendo.editor.bold": "Fett",
  "kendo.editor.cleanFormatting": "Sauber formatieren",
  "kendo.editor.createLink": "Link einfügen",
  "kendo.editor.dialogApply": "Anwenden",
  "kendo.editor.dialogCancel": "Abbrechen",
  "kendo.editor.dialogInsert": "Einfügen",
  "kendo.editor.dialogUpdate": "Aktualisieren",
  "kendo.editor.fileText": "Text",
  "kendo.editor.fileTitle": "Titel",
  "kendo.editor.fileWebAddress": "Webadresse",
  "kendo.editor.fontFamily": "Schriftfamilie auswählen",
  "kendo.editor.fontSize": "Schriftgröße auswählen",
  "kendo.editor.foreColor": "Farbe",
  "kendo.editor.format": "Format",
  "kendo.editor.imageAltText": "Alternativtext",
  "kendo.editor.imageHeight": "Höhe (px)",
  "kendo.editor.imageWebAddress": "Webadresse",
  "kendo.editor.imageWidth": "Breite (px)",
  "kendo.editor.indent": "Einrücken",
  "kendo.editor.insertFile": "Datei einfügen",
  "kendo.editor.insertImage": "Bild einfügen",
  "kendo.editor.insertOrderedList": "Sortierte Liste einfügen",
  "kendo.editor.insertUnorderedList": "Unsortierte Liste einfügen",
  "kendo.editor.italic": "Kursiv",
  "kendo.editor.linkOpenInNewWindow": "Link in neuem Fenster öffnen",
  "kendo.editor.linkText": "Text",
  "kendo.editor.linkTitle": "Titel",
  "kendo.editor.linkWebAddress": "Webadresse",
  "kendo.editor.outdent": "Ausrücken",
  "kendo.editor.redo": "Wiederholen",
  "kendo.editor.strikethrough": "Durchstreichen",
  "kendo.editor.subscript": "Tiefgestellt",
  "kendo.editor.superscript": "Hochgestellt",
  "kendo.editor.underline": "Unterstreichen",
  "kendo.editor.unlink": "Link entfernen",
  "kendo.editor.undo": "Rückgängig",
  "kendo.editor.viewSource": "Quelltext anzeigen",
  "kendo.editor.insertTable": "Tabelle einfügen",
  "kendo.editor.addColumnBefore": "Spalte davor einfügen",
  "kendo.editor.addColumnAfter": "Spalte danach einfügen",
  "kendo.editor.addRowBefore": "Zeile davor einfügen",
  "kendo.editor.addRowAfter": "Zeile danach einfügen",
  "kendo.editor.deleteColumn": "Spalte löschen",
  "kendo.editor.deleteRow": "Zeile löschen",
  "kendo.editor.deleteTable": "Tabelle löschen",
  "kendo.editor.selectAll": "Alles auswählen",
  "kendo.editor.print": "Drucken",
  "kendo.editor.mergeCells": "Zellen zusammenfügen",
  "kendo.editor.splitCell": "Zellen separieren",
  "kendo.fileselect.dropFilesHere": "Dateien zum Hochladen hierhin ziehen",
  "kendo.fileselect.invalidFileExtension": "Dateityp nicht erlaubt.",
  "kendo.fileselect.invalidMaxFileSize": "Datei ist zu groß.",
  "kendo.fileselect.invalidMinFileSize": "Datei ist zu klein.",
  "kendo.fileselect.remove": "Entfernen",
  "kendo.fileselect.select": "Datei(en) auswählen...",
  "kendo.filter.addExpression": "Ausdruck hinzufügen",
  "kendo.filter.addFilter": "Filter hinzufügen",
  "kendo.filter.addGroup": "Gruppe hinzufügen",
  "kendo.filter.remove": "Entfernen",
  "kendo.filter.editorDateTodayText": "Heute",
  "kendo.filter.editorDateToggleText": "Kalender umschalten",
  "kendo.filter.editorNumericDecrement": "Wert verringern",
  "kendo.filter.editorNumericIncrement": "Wert erhöhen",
  "kendo.filter.filterAfterOperator": "ist später als",
  "kendo.filter.filterAfterOrEqualOperator": "ist später als oder gleich",
  "kendo.filter.filterAndLogic": "und",
  "kendo.filter.filterBeforeOperator": "ist früher als",
  "kendo.filter.filterBeforeOrEqualOperator": "ist früher als oder gleich",
  "kendo.filter.filterBooleanAll": "(Alle)",
  "kendo.filter.filterContainsOperator": "beinhaltet",
  "kendo.filter.filterEndsWithOperator": "endet mit",
  "kendo.filter.filterEqOperator": "ist gleich",
  "kendo.filter.filterExpressionFilters": "Filter",
  "kendo.filter.filterExpressionOperators": "Operatoren",
  "kendo.filter.filterGtOperator": "ist größer als",
  "kendo.filter.filterGteOperator": "ist größer als oder gleich",
  "kendo.filter.filterIsEmptyOperator": "ist leer",
  "kendo.filter.filterIsFalse": "ist falsch",
  "kendo.filter.filterIsTrue": "ist richtig",
  "kendo.filter.filterIsNotEmptyOperator": "ist nicht leer",
  "kendo.filter.filterIsNotNullOperator": "ist nicht Null",
  "kendo.filter.filterIsNullOperator": "ist Null",
  "kendo.filter.filterLtOperator": "ist kleiner als",
  "kendo.filter.filterLteOperator": "ist kleiner oder gleich als",
  "kendo.filter.filterNotContainsOperator": "beinhaltet nicht",
  "kendo.filter.filterNotEqOperator": "ist nicht gleich",
  "kendo.filter.filterOrLogic": "oder",
  "kendo.filter.filterStartsWithOperator": "beginnt mit",
  "kendo.filter.filterFieldAriaLabel": "Feld",
  "kendo.filter.filterOperatorAriaLabel": "Operator",
  "kendo.filter.filterValueAriaLabel": "Wert",
  "kendo.filter.filterAriaLabel": "Filter",
  "kendo.filter.filterToolbarAriaLabel": "Filter Einstellungen",
  "kendo.flatcolorpicker.flatColorPickerNoColor": "Farbauswahl keine Farbe ausgewählt",
  "kendo.flatcolorpicker.colorGradientNoColor": "Farbverlauf keine Farbe ausgewählt",
  "kendo.flatcolorpicker.colorPaletteNoColor": "Farben Palette keine Farbe ausgewählt",
  "kendo.flatcolorpicker.colorGradientHandle": "Farbe auswählen",
  "kendo.flatcolorpicker.clearButton": "Wert leeren",
  "kendo.flatcolorpicker.hueSliderHandle": "Farbton setzen",
  "kendo.flatcolorpicker.opacitySliderHandle": "Deckkraft setzen",
  "kendo.flatcolorpicker.contrastRatio": "Kontrastverhältnis",
  "kendo.flatcolorpicker.previewColor": "Farbvorschau",
  "kendo.flatcolorpicker.revertSelection": "Auswahl zurücksetzen",
  "kendo.flatcolorpicker.gradientView": "Farbverlauf Ansicht",
  "kendo.flatcolorpicker.paletteView": "Paletten Ansicht",
  "kendo.flatcolorpicker.formatButton": "Farbformat ändern",
  "kendo.flatcolorpicker.applyButton": "Anwenden",
  "kendo.flatcolorpicker.cancelButton": "Abbrechen",
  "kendo.flatcolorpicker.redChannelLabel": "Roter Kanal",
  "kendo.flatcolorpicker.greenChannelLabel": "Grüner Kanal",
  "kendo.flatcolorpicker.blueChannelLabel": "Blauer Kanal",
  "kendo.flatcolorpicker.alphaChannelLabel": "Alpha Kanal",
  "kendo.gantt.taskEditingGeneralTabTitle": "Generell",
  "kendo.gantt.taskEditingPredecessorsTabTitle": "Vorgänger",
  "kendo.gantt.taskEditingSuccessorsTabTitle": "Nachfolger",
  "kendo.gantt.taskEditingDependenciesAddButtonText": "Hinzufügen",
  "kendo.gantt.taskEditingDependenciesRemoveButtonText": "Entfernen",
  "kendo.gantt.taskEditingDependenciesGridNameColumnTitle": "Aufgabe Titel",
  "kendo.gantt.taskEditingDependenciesGridTypeColumnTitle": "Typ",
  "kendo.gantt.deleteButtonText": "Löschen",
  "kendo.gantt.taskDeleteLabel": "Löschen",
  "kendo.gantt.taskEditingDialogTitle": "Aufgabe bearbeiten",
  "kendo.gantt.taskEditingDialogCloseTitle": "Schließen",
  "kendo.gantt.confirmationDialogCloseTitle": "Schließen",
  "kendo.gantt.confirmationDialogTitle": "Aufgabe löschen",
  "kendo.gantt.addTaskText": "Aufgabe hinzufügen",
  "kendo.gantt.addChildText": "Kind hinzufügen",
  "kendo.gantt.addAboveText": "darüber hinzufügen",
  "kendo.gantt.addBelowText": "darunter hinzufügen",
  "kendo.gantt.cancelButtonText": "Abbrechen",
  "kendo.gantt.saveButtonText": "Speichern",
  "kendo.gantt.titleFieldInputLabel": "Titel",
  "kendo.gantt.startFieldInputLabel": "Beginn",
  "kendo.gantt.endFieldInputLabel": "Ende",
  "kendo.gantt.completionRatioFieldInputLabel": "Fortschritt",
  "kendo.gantt.confirmationDialogContent": "Möchten Sie diese Aufgabe wirklich löschen?",
  "kendo.gantt.dayViewText": "Tag",
  "kendo.gantt.weekViewText": "Woche",
  "kendo.gantt.monthViewText": "Monat",
  "kendo.gantt.yearViewText": "Jahr",
  "kendo.gantt.noRecords": "Keine Datensätze verfügbar.",
  "kendo.gantt.filter": "Filter",
  "kendo.gantt.filterEqOperator": "ist gleich",
  "kendo.gantt.filterNotEqOperator": "ist nicht gleich",
  "kendo.gantt.filterIsNullOperator": "ist Null",
  "kendo.gantt.filterIsNotNullOperator": "ist nicht Null",
  "kendo.gantt.filterIsEmptyOperator": "ist leer",
  "kendo.gantt.filterIsNotEmptyOperator": "ist nicht leer",
  "kendo.gantt.filterStartsWithOperator": "beginnt mit",
  "kendo.gantt.filterContainsOperator": "beinhaltet",
  "kendo.gantt.filterNotContainsOperator": "beinhaltet nicht",
  "kendo.gantt.filterEndsWithOperator": "endet mit",
  "kendo.gantt.filterGteOperator": "ist größer als oder gleich",
  "kendo.gantt.filterGtOperator": "ist größer als",
  "kendo.gantt.filterLteOperator": "ist kleiner als oder gleich",
  "kendo.gantt.filterLtOperator": "ist kleiner als",
  "kendo.gantt.filterIsTrue": "ist richtig",
  "kendo.gantt.filterIsFalse": "ist falsch",
  "kendo.gantt.filterBooleanAll": "(Alle)",
  "kendo.gantt.filterAfterOrEqualOperator": "ist später als oder gleich",
  "kendo.gantt.filterAfterOperator": "ist später als",
  "kendo.gantt.filterBeforeOperator": "ist früher als",
  "kendo.gantt.filterBeforeOrEqualOperator": "ist früher als oder gleich",
  "kendo.gantt.filterFilterButton": "Filtern",
  "kendo.gantt.filterClearButton": "Löschen",
  "kendo.gantt.filterAndLogic": "und",
  "kendo.gantt.filterOrLogic": "oder",
  "kendo.gantt.loading": "Laden",
  "kendo.gantt.columnMenu": "Spaltenmenü",
  "kendo.gantt.columns": "Spalten",
  "kendo.gantt.lock": "Sperren",
  "kendo.gantt.unlock": "Entsperren",
  "kendo.gantt.sortAscending": "Aufsteigend sortieren",
  "kendo.gantt.sortDescending": "Absteigend sortieren",
  "kendo.gantt.columnsApply": "Anwenden",
  "kendo.gantt.columnsReset": "Zurücksetzen",
  "kendo.gantt.sortable": "Sortable",
  "kendo.gantt.sortedAscending": "Aufsteigend sortieren",
  "kendo.gantt.sortedDescending": "Absteigend sortieren",
  "kendo.gantt.sortedDefault": "Nicht sortiert",
  "kendo.grid.groupPanelEmpty":
    "Ziehen Sie eine Spaltenüberschrift hierher, um nach dieser Spalte zu gruppieren",
  "kendo.grid.noRecords": "Keine Datensätze verfügbar.",
  "kendo.grid.pagerFirstPage": "Zur ersten Seite",
  "kendo.grid.pagerPreviousPage": "Zur vorherigen Seite",
  "kendo.grid.pagerNextPage": "Zur nächsten Seite",
  "kendo.grid.pagerLastPage": "Zur letzten Seite",
  "kendo.grid.pagerPage": "Seite",
  "kendo.grid.pagerOf": "von",
  "kendo.grid.pagerItems": "Elemente",
  "kendo.grid.pagerItemsPerPage": "Elemente pro Seite",
  "kendo.grid.pagerPageNumberInputTitle": "Seitennummer",
  "kendo.grid.filter": "Filter",
  "kendo.grid.filterEqOperator": "ist gleich",
  "kendo.grid.filterNotEqOperator": "ist nicht gleich",
  "kendo.grid.filterIsNullOperator": "ist Null",
  "kendo.grid.filterIsNotNullOperator": "ist nicht Null",
  "kendo.grid.filterIsEmptyOperator": "ist leer",
  "kendo.grid.filterIsNotEmptyOperator": "ist nicht leer",
  "kendo.grid.filterStartsWithOperator": "beginnt mit",
  "kendo.grid.filterContainsOperator": "beinhaltet",
  "kendo.grid.filterNotContainsOperator": "beinhaltet nicht",
  "kendo.grid.filterEndsWithOperator": "endet mit",
  "kendo.grid.filterGteOperator": "ist größer als oder gleich",
  "kendo.grid.filterGtOperator": "ist größer als",
  "kendo.grid.filterLteOperator": "ist kleiner als oder gleich",
  "kendo.grid.filterLtOperator": "ist kleiner als",
  "kendo.grid.filterIsTrue": "ist richtig",
  "kendo.grid.filterIsFalse": "ist falsch",
  "kendo.grid.filterBooleanAll": "(Alle)",
  "kendo.grid.filterAfterOrEqualOperator": "ist später als oder gleich",
  "kendo.grid.filterAfterOperator": "ist später als",
  "kendo.grid.filterBeforeOperator": "ist früher als",
  "kendo.grid.filterBeforeOrEqualOperator": "ist früher als oder gleich",
  "kendo.grid.filterFilterButton": "Filtern",
  "kendo.grid.filterClearButton": "Löschen",
  "kendo.grid.filterAndLogic": "und",
  "kendo.grid.filterOrLogic": "oder",
  "kendo.grid.filterDateToggle": "Kalender umschalten",
  "kendo.grid.filterDateToday": "Heute",
  "kendo.grid.filterNumericDecrement": "Wert verringern",
  "kendo.grid.filterNumericIncrement": "Wert erhöhen",
  "kendo.grid.loading": "Laden",
  "kendo.grid.columns": "Spalten",
  "kendo.grid.lock": "Sperren",
  "kendo.grid.unlock": "Entsperren",
  "kendo.grid.stick": "Anheften",
  "kendo.grid.unstick": "Loslösen",
  "kendo.grid.setColumnPosition": "Spaltenposition setzen",
  "kendo.grid.sortAscending": "Aufsteigend sortieren",
  "kendo.grid.sortDescending": "Absteigend sortieren",
  "kendo.grid.columnsApply": "Übernehmen",
  "kendo.grid.columnsReset": "Zurücksetzen",
  "kendo.grid.sortable": "Sortierbar",
  "kendo.grid.sortedAscending": "Aufsteigend sortiert",
  "kendo.grid.sortedDescending": "Absteigend sortiert",
  "kendo.grid.sortedDefault": "Nicht sortiert",
  "kendo.grid.filterInputLabel": "{columnName} Filter",
  "kendo.grid.filterMenuTitle": "{columnName} Filter Menü",
  "kendo.grid.filterMenuOperatorsDropDownLabel": "{columnName} Filter Operatoren",
  "kendo.grid.filterMenuLogicDropDownLabel": "{columnName} Filter Logik",
  "kendo.grid.columnMenu": "{columnName} Spaltenmenü",
  "kendo.grid.selectionCheckboxLabel": "Zeile auswählen",
  "kendo.grid.selectAllCheckboxLabel": "Alle Zeilen auswählen",
  "kendo.grid.pagerLabel": "Zeilennavigation, Seite {currentPage} von {totalPages}",
  "kendo.grid.gridLabel": "Datentabelle",
  "kendo.grid.groupCollapse": "Gruppe verbergen",
  "kendo.grid.groupExpand": "Gruppe anzeigen",
  "kendo.grid.detailCollapse": "Details verbergen",
  "kendo.grid.detailExpand": "Details anzeigen",
  "kendo.grid.autosizeThisColumn": "Autogröße für diese Spalte",
  "kendo.grid.autosizeAllColumns": "Autogröße für alle Spalten",
  "kendo.grid.selectPage": "Seite auswählen",
  "kendo.grid.topToolbarLabel": "Obere Toolbar",
  "kendo.grid.bottomToolbarLabel": "Untere Toolbar",
  "kendo.grid.groupPanelLabel": "Gruppenpanel",
  "kendo.grid.dragRowHandleLabel": "Zeile ziehen",
  "kendo.grid.selectRowCheckboxLabel": "Zeile auswählen",
  "kendo.grid.selectAllRowsCheckboxLabel": "Alle Zeilen auswählen",
  "kendo.grid.filterCellOperatorLabel": "Filterzelle Operatoren für {columnName}",
  "kendo.grid.booleanFilterCellLabel": "Boolesche Filterzelle für {columnName}",
  "kendo.grid.columnMenuFilterTabTitle": "Filter",
  "kendo.grid.columnMenuGeneralTabTitle": "Generell",
  "kendo.grid.columnMenuColumnsTabTitle": "Spalten",
  "kendo.grid.groupChipMenuPrevious": "Move as previous",
  "kendo.grid.groupChipMenuNext": "Move as next",
  "kendo.label.optional": "Optional",
  "kendo.floatinglabel.optional": "Optional",
  "kendo.listbox.moveUp": "Hinaufziehen",
  "kendo.listbox.moveDown": "Hinunterziehen",
  "kendo.listbox.transferTo": "Übertragen nach",
  "kendo.listbox.transferFrom": "Übertragen von",
  "kendo.listbox.transferAllTo": "Alle Übertragen nach",
  "kendo.listbox.transferAllFrom": "Alle Übertragen von",
  "kendo.listbox.remove": "Entfernen",
  "kendo.notification.closeTitle": "Schließen",
  "kendo.numerictextbox.increment": "Wert erhöhen",
  "kendo.numerictextbox.decrement": "Wert verringern",
  "kendo.pager.firstPage": "Zur ersten Seite",
  "kendo.pager.previousPage": "Zur vorherigen Seite",
  "kendo.pager.nextPage": "Zur nächsten Seite",
  "kendo.pager.lastPage": "Zur letzten Seite",
  "kendo.pager.page": "Seite",
  "kendo.pager.of": "von",
  "kendo.pager.items": "Elementen",
  "kendo.pager.itemsPerPage": "Elemente pro Seite",
  "kendo.pager.pageNumberInputTitle": "Seitennummer",
  "kendo.pager.selectPage": "Seite auswählen",
  "kendo.pager.inputLabel": "Seitennummer eingeben",
  "kendo.pdfviewer.pagerFirstPage": "Zur ersten Seite",
  "kendo.pdfviewer.pagerPreviousPage": "Zur vorherigen Seite",
  "kendo.pdfviewer.pagerNextPage": "Zur nächsten Seite",
  "kendo.pdfviewer.pagerLastPage": "Zur letzten Seite",
  "kendo.pdfviewer.pagerPage": "Seite",
  "kendo.pdfviewer.pagerOf": "von",
  "kendo.pdfviewer.pagerInputTitle": "Seitennummer",
  "kendo.pdfviewer.pagerInputLabel": "Seitennummer eingeben",
  "kendo.pdfviewer.zoomInTitle": "Ansicht vergrößern",
  "kendo.pdfviewer.zoomOutTitle": "Ansicht verkleinern",
  "kendo.pdfviewer.selectionTitle": "Enable selection",
  "kendo.pdfviewer.panningTitle": "Enable panning",
  "kendo.pdfviewer.searchTitle": "Suchen",
  "kendo.pdfviewer.searchInputPlaceholder": "Suchen",
  "kendo.pdfviewer.searchMatchesOf": "von",
  "kendo.pdfviewer.searchPreviousMatchTitle": "Vorherige Übereinstimmung",
  "kendo.pdfviewer.searchNextMatchTitle": "Nächste Übereinstimmung",
  "kendo.pdfviewer.searchCloseTitle": "Schließen",
  "kendo.pdfviewer.openTitle": "Öffnen",
  "kendo.pdfviewer.downloadTitle": "Herunterladen",
  "kendo.pdfviewer.printTitle": "Drucken",
  "kendo.pdfviewer.fitToPage": "Fit to page",
  "kendo.pdfviewer.fitToWidth": "Fit to width",
  "kendo.pdfviewer.searchMatchCaseTitle": "Match case",
  "kendo.pdfviewer.zoomInputPlaceholder": "Choose zoom level",
  "kendo.pivotgrid.emptyCellLabel": "PivotGrid leere Zelle",
  "kendo.pivotgrid.fieldMenuFilterItemLabel": "Filter",
  "kendo.pivotgrid.filterInputLabel": "{fields} Filter",
  "kendo.pivotgrid.filterOperatorsDropDownLabel": "{fields} Filter Operatoren",
  "kendo.pivotgrid.filterEqOperator": "ist gleich",
  "kendo.pivotgrid.filterNotEqOperator": "ist nicht gleich",
  "kendo.pivotgrid.filterIsNullOperator": "ist Null",
  "kendo.pivotgrid.filterIsNotNullOperator": "ist nicht Null",
  "kendo.pivotgrid.filterIsEmptyOperator": "ist leer",
  "kendo.pivotgrid.filterIsNotEmptyOperator": "ist nicht leer",
  "kendo.pivotgrid.filterStartsWithOperator": "beginnt mit",
  "kendo.pivotgrid.filterContainsOperator": "beinhaltet",
  "kendo.pivotgrid.filterNotContainsOperator": "beinhaltet nicht",
  "kendo.pivotgrid.filterEndsWithOperator": "endet mit",
  "kendo.pivotgrid.filterFilterButton": "Filtern",
  "kendo.pivotgrid.filterClearButton": "Löschen",
  "kendo.pivotgrid.fieldMenuSortAscendingItemLabel": "Aufsteigend sortieren",
  "kendo.pivotgrid.fieldMenuSortDescendingItemLabel": "Absteigend sortieren",
  "kendo.pivotgrid.configuratorButtonText": "Einstellungen ändern",
  "kendo.pivotgrid.configuratorHeaderText": "Einstellungen",
  "kendo.pivotgrid.configuratorFieldsText": "Felder",
  "kendo.pivotgrid.configuratorColumnsText": "Spalten",
  "kendo.pivotgrid.configuratorRowsText": "Zeilen",
  "kendo.pivotgrid.configuratorCancelButtonText": "Abbrechen",
  "kendo.pivotgrid.configuratorApplyButtonText": "Übernehmen",
  "kendo.pivotgrid.loading": "Laden",
  "kendo.pivotgrid.configuratorValuesText": "Werte",
  "kendo.pivotgrid.configuratorEmptyRowsText":
    "Wählen Sie einige Felder aus um die Einrichtung zu beginnen",
  "kendo.pivotgrid.configuratorEmptyColumnsText":
    "Wählen Sie einige Felder aus um die Einrichtung zu beginnen",
  "kendo.pivotgrid.configuratorEmptyMeasuresText":
    "Wählen Sie einige Felder aus um die Einrichtung zu beginnen",
  "kendo.pivotgrid.fieldMenuMoveToColumnsItem": "Move to Columns",
  "kendo.pivotgrid.fieldMenuMoveToRowsItem": "Move to Rows",
  "kendo.pivotgrid.fieldMenuMovePreviousItem": "Move as previous",
  "kendo.pivotgrid.fieldMenuMoveNextItem": "Move as next",
  "kendo.progressbar.progressBarLabel": "Fortschrittsanzeige",
  "kendo.chunkprogressbar.progressBarLabel": "Abgestufte Fortschrittsanzeige",
  "kendo.circularprogressbar.progressBarLabel": "Runde Fortschrittsanzeige",
  "kendo.rangeslider.dragHandleEnd": "Ziehen",
  "kendo.rangeslider.dragHandleStart": "Ziehen",
  "kendo.recurrenceeditor.dailyInterval": "Tag(e)",
  "kendo.recurrenceeditor.dailyRepeatEvery": "Wiederholen an jedem:",
  "kendo.recurrenceeditor.endAfter": "Nach",
  "kendo.recurrenceeditor.endLabel": "Beenden:",
  "kendo.recurrenceeditor.endNever": "Nie",
  "kendo.recurrenceeditor.endOccurrence": "Anzahl Wiederholungen",
  "kendo.recurrenceeditor.endOn": "Am",
  "kendo.recurrenceeditor.frequenciesDaily": "Täglich",
  "kendo.recurrenceeditor.frequenciesMonthly": "Monatlich",
  "kendo.recurrenceeditor.frequenciesNever": "Nie",
  "kendo.recurrenceeditor.frequenciesWeekly": "Wöchentlich",
  "kendo.recurrenceeditor.frequenciesYearly": "Jährlich",
  "kendo.recurrenceeditor.monthlyDay": "Tag",
  "kendo.recurrenceeditor.monthlyInterval": "Monat(e)",
  "kendo.recurrenceeditor.monthlyRepeatEvery": "Wiederholen an jedem:",
  "kendo.recurrenceeditor.monthlyRepeatOn": "Wiederholen am:",
  "kendo.recurrenceeditor.offsetPositionsFourth": "vierten",
  "kendo.recurrenceeditor.offsetPositionsLast": "letzten",
  "kendo.recurrenceeditor.offsetPositionsSecond": "zweiten",
  "kendo.recurrenceeditor.offsetPositionsThird": "dritten",
  "kendo.recurrenceeditor.repeat": "Wiederholen",
  "kendo.recurrenceeditor.weekdaysDay": "Tag",
  "kendo.recurrenceeditor.weekdaysWeekday": "Wochentag",
  "kendo.recurrenceeditor.weekdaysWeekendday": "Tag am Wochenende",
  "kendo.recurrenceeditor.weeklyInterval": "Woche(n)",
  "kendo.recurrenceeditor.weeklyRepeatEvery": "Wiederholen an jedem:",
  "kendo.recurrenceeditor.weeklyRepeatOn": "Wiederholen am:",
  "kendo.recurrenceeditor.yearlyInterval": "Jahr(e)",
  "kendo.recurrenceeditor.yearlyOf": "von",
  "kendo.recurrenceeditor.yearlyRepeatEvery": "Wiederholen an jedem:",
  "kendo.recurrenceeditor.yearlyRepeatOn": "Wiederholen am:",
  "kendo.scheduler.agendaViewTitle": "Agenda",
  "kendo.scheduler.allDay": "Ganzer Tag",
  "kendo.scheduler.allEvents": "All events",
  "kendo.scheduler.calendarToday": "HEUTE",
  "kendo.scheduler.cancel": "Abbrechen",
  "kendo.scheduler.save": "Speichern",
  "kendo.scheduler.editorEventTitle": "Titel",
  "kendo.scheduler.editorEventStart": "Beginn",
  "kendo.scheduler.editorEventStartTimeZone": "Zeitzone Beginn",
  "kendo.scheduler.editorEventEnd": "Ende",
  "kendo.scheduler.editorEventEndTimeZone": "Zeitzone Ende",
  "kendo.scheduler.dateHeader": "Datum",
  "kendo.scheduler.dayViewTitle": "Titel",
  "kendo.scheduler.deleteConfirmation": "Möchten Sie diesen Termin wirklich löschen?",
  "kendo.scheduler.deleteDialogTitle": "Termin löschen",
  "kendo.scheduler.deleteOccurrence": "Aktuelles Ereignis löschen",
  "kendo.scheduler.deleteRecurringConfirmation":
    "Möchten Sie nur dieses Ereignis oder alle Ereignisse löschen?",
  "kendo.scheduler.deleteRecurringDialogTitle":
    "Möchten Sie nur diesen Termin oder alle Wiederholungen löschen?",
  "kendo.scheduler.deleteSeries": "Alle Wiederholungen des Termins löschen",
  "kendo.scheduler.deleteTitle": "Termin löschen",
  "kendo.scheduler.destroy": "Löschen",
  "kendo.scheduler.editOccurrence": "Aktuelles Ereignis bearbeiten",
  "kendo.scheduler.editorEventAllDay": "Ganztägiger Termin",
  "kendo.scheduler.editorEventDescription": "Beschreibung",
  "kendo.scheduler.editorEventSeparateTimeZones":
    "Unterschiedliche Beginn- und Endzeitzonen benutzen",
  "kendo.scheduler.editorEventTimeZone": "Zeitzone Ende",
  "kendo.scheduler.editorTitle": "Titel",
  "kendo.scheduler.editRecurringConfirmation":
    "Möchten Sie nur diesen Termin oder alle Wiederholungen bearbeiten?",
  "kendo.scheduler.editRecurringDialogTitle": "Wiederholungseintrag bearbeiten",
  "kendo.scheduler.editSeries": "Serie bearbeiten",
  "kendo.scheduler.eventHeader": "Termin",
  "kendo.scheduler.monthViewTitle": "Monat",
  "kendo.scheduler.multiDayViewTitle": "Mehrtägig",
  "kendo.scheduler.nextTitle": "Nächste",
  "kendo.scheduler.previousTitle": "Vorherige",
  "kendo.scheduler.recurrenceEditorDailyInterval": "Tag(e)",
  "kendo.scheduler.recurrenceEditorDailyRepeatEvery": "Wiederholen an jedem:",
  "kendo.scheduler.recurrenceEditorEndAfter": "Danach",
  "kendo.scheduler.recurrenceEditorEndLabel": "Ende",
  "kendo.scheduler.recurrenceEditorEndNever": "Nie",
  "kendo.scheduler.recurrenceEditorEndOccurrence": "Anzahl Wiederholungen",
  "kendo.scheduler.recurrenceEditorEndOn": "On",
  "kendo.scheduler.recurrenceEditorFrequenciesDaily": "Täglich",
  "kendo.scheduler.recurrenceEditorFrequenciesMonthly": "Monatlich",
  "kendo.scheduler.recurrenceEditorFrequenciesNever": "Nie",
  "kendo.scheduler.recurrenceEditorFrequenciesWeekly": "Wöchentlich",
  "kendo.scheduler.recurrenceEditorFrequenciesYearly": "Jährlich",
  "kendo.scheduler.recurrenceEditorMonthlyDay": "Tag",
  "kendo.scheduler.recurrenceEditorMonthlyInterval": "Monat(e)",
  "kendo.scheduler.recurrenceEditorMonthlyRepeatEvery": "Wiederholen an jedem:",
  "kendo.scheduler.recurrenceEditorMonthlyRepeatOn": "Wiederholen am:",
  "kendo.scheduler.recurrenceEditorOffsetPositionsFirst": "ersten",
  "kendo.scheduler.recurrenceEditorOffsetPositionsFourth": "vierten",
  "kendo.scheduler.recurrenceEditorOffsetPositionsLast": "letzten",
  "kendo.scheduler.recurrenceEditorOffsetPositionsSecond": "zweiten",
  "kendo.scheduler.recurrenceEditorOffsetPositionsThird": "dritten",
  "kendo.scheduler.recurrenceEditorRepeat": "Wiederholen",
  "kendo.scheduler.recurrenceEditorWeekdaysDay": "Tag",
  "kendo.scheduler.recurrenceEditorWeekdaysWeekday": "Wochentag",
  "kendo.scheduler.recurrenceEditorWeekdaysWeekendday": "Weekend Day",
  "kendo.scheduler.recurrenceEditorWeeklyInterval": "Woche(n)",
  "kendo.scheduler.recurrenceEditorWeeklyRepeatEvery": "Wiederholen an jedem:",
  "kendo.scheduler.recurrenceEditorWeeklyRepeatOn": "Wiederholen am:",
  "kendo.scheduler.recurrenceEditorYearlyInterval": "Jahr(e)",
  "kendo.scheduler.recurrenceEditorYearlyOf": "von",
  "kendo.scheduler.recurrenceEditorYearlyRepeatEvery": "Wiederholen an jedem:",
  "kendo.scheduler.recurrenceEditorYearlyRepeatOn": "Wiederholen am:",
  "kendo.scheduler.showFullDay": "Ganzen Tag anzeigen",
  "kendo.scheduler.showWorkDay": "Geschäftszeiten anzeigen",
  "kendo.scheduler.timeHeader": "Zeit",
  "kendo.scheduler.timelineMonthViewTitle": "Zeitplan Monat",
  "kendo.scheduler.timelineViewTitle": "Zeitplan",
  "kendo.scheduler.timelineWeekViewTitle": "Zeitplan Woche",
  "kendo.scheduler.today": "Heute",
  "kendo.scheduler.weekViewTitle": "Woche",
  "kendo.scheduler.workWeekViewTitle": "Arbeitswoche",
  "kendo.scheduler.selectView": "Ansicht auswählen",
  "kendo.scrollview.pagerButtonLabel": "Element {itemIndex}",
  "kendo.signature.clear": "Leeren",
  "kendo.signature.maximize": "Maximieren",
  "kendo.signature.minimize": "Minimieren",
  "kendo.signature.canvasLabel": "Signaturfeld",
  "kendo.slider.increment": "Wert erhöhen",
  "kendo.slider.decrement": "Wert verringern",
  "kendo.slider.dragHandle": "Ziehen",
  "kendo.splitbutton.splitButtonLabel": "{buttonText} geteilter Button",
  "kendo.spreadsheet.background": "Background color",
  "kendo.spreadsheet.color": "Font color",
  "kendo.spreadsheet.bold": "Bold",
  "kendo.spreadsheet.italic": "Italic",
  "kendo.spreadsheet.underline": "Underline",
  "kendo.spreadsheet.saveFile": "Export...",
  "kendo.spreadsheet.loadFile": "Open...",
  "kendo.spreadsheet.format": "Custom format...",
  "kendo.spreadsheet.fontFamily": "Font",
  "kendo.spreadsheet.fontSize": "Font size",
  "kendo.spreadsheet.home": "Home",
  "kendo.spreadsheet.file": "File",
  "kendo.spreadsheet.insert": "Insert",
  "kendo.spreadsheet.undo": "Undo",
  "kendo.spreadsheet.redo": "Redo",
  "kendo.spreadsheet.gridLines": "Toggle grid lines",
  "kendo.spreadsheet.addColumnLeft": "Add column left",
  "kendo.spreadsheet.addColumnRight": "Add column right",
  "kendo.spreadsheet.addRowBelow": "Add row below",
  "kendo.spreadsheet.addRowAbove": "Add row above",
  "kendo.spreadsheet.deleteColumn": "Delete column",
  "kendo.spreadsheet.deleteRow": "Delete row",
  "kendo.spreadsheet.wrap": "Text wrap",
  "kendo.spreadsheet.align": "Align",
  "kendo.spreadsheet.dialogApply": "Apply",
  "kendo.spreadsheet.dialogCancel": "Cancel",
  "kendo.spreadsheet.dialogDelete": "Delete",
  "kendo.spreadsheet.dialogRename": "Rename",
  "kendo.spreadsheet.rename": "Rename Sheet",
  "kendo.spreadsheet.delete": "Delete Sheet",
  "kendo.spreadsheet.nameBox": "Name Box",
  "kendo.spreadsheet.addSheet": "Add New Sheet",
  "kendo.spreadsheet.sheetsMenu": "All Sheets",
  "kendo.stepper.optional": "Optional",
  "kendo.switch.on": "Ein",
  "kendo.switch.off": "Aus",
  "kendo.tabstrip.previousTabButton": "vorheriger Reiter",
  "kendo.tabstrip.nextTabButton": "nächster Reiter",
  "kendo.tabstrip.closeTitle": "Schließen",
  "kendo.textbox.clear": "Löschen",
  "kendo.timepicker.accept": "Übernehmen",
  "kendo.timepicker.acceptLabel": "Übernehmen",
  "kendo.timepicker.cancel": "Abbrechen",
  "kendo.timepicker.cancelLabel": "Abbrechen",
  "kendo.timepicker.now": "Jetzt",
  "kendo.timepicker.nowLabel": "Jetzt",
  "kendo.timepicker.toggle": "Zeitleiste umschalten",
  "kendo.timepicker.hour": "Stunde",
  "kendo.timepicker.minute": "Minute",
  "kendo.timepicker.second": "Sekunde",
  "kendo.timepicker.millisecond": "Millisekunde",
  "kendo.timepicker.dayperiod": "Zeitraum",
  "kendo.toolbar.moreToolsTitle": "Mehr Werkzeuge",
  "kendo.tooltip.closeTitle": "Schließen",
  "kendo.treelist.groupPanelEmpty":
    "Ziehen Sie eine Spaltenüberschrift hierher, um nach dieser Spalte zu gruppieren",
  "kendo.treelist.noRecords": "Keine Datensätze verfügbar.",
  "kendo.treelist.pagerFirstPage": "Zur ersten Seite",
  "kendo.treelist.pagerPreviousPage": "Zur vorherigen Seite",
  "kendo.treelist.pagerNextPage": "Zur nächsten Seite",
  "kendo.treelist.pagerLastPage": "Zur letzten Seite",
  "kendo.treelist.pagerPage": "Seite",
  "kendo.treelist.pagerOf": "von",
  "kendo.treelist.pagerItemsTotal": "Elementen",
  "kendo.treelist.pagerItemsPerPage": "Elemente pro Seite",
  "kendo.treelist.pagerPageNumberInputTitle": "Seitennummer",
  "kendo.treelist.filter": "Filter",
  "kendo.treelist.filterEqOperator": "ist gleich",
  "kendo.treelist.filterNotEqOperator": "ist nicht gleich",
  "kendo.treelist.filterIsNullOperator": "ist Null",
  "kendo.treelist.filterIsNotNullOperator": "ist nicht Null",
  "kendo.treelist.filterIsEmptyOperator": "ist leer",
  "kendo.treelist.filterIsNotEmptyOperator": "ist nicht leer",
  "kendo.treelist.filterStartsWithOperator": "beginnt mit",
  "kendo.treelist.filterContainsOperator": "beinhaltet",
  "kendo.treelist.filterNotContainsOperator": "beinhaltet nicht",
  "kendo.treelist.filterEndsWithOperator": "endet mit",
  "kendo.treelist.filterGteOperator": "ist größer als oder gleich",
  "kendo.treelist.filterGtOperator": "ist größer als",
  "kendo.treelist.filterLteOperator": "ist kleiner oder gleich als",
  "kendo.treelist.filterLtOperator": "ist kleiner als",
  "kendo.treelist.filterIsTrue": "ist richtig",
  "kendo.treelist.filterIsFalse": "ist falsch",
  "kendo.treelist.filterBooleanAll": "(Alle)",
  "kendo.treelist.filterAfterOrEqualOperator": "ist später als oder gleich",
  "kendo.treelist.filterAfterOperator": "ist später als",
  "kendo.treelist.filterBeforeOperator": "ist früher als",
  "kendo.treelist.filterBeforeOrEqualOperator": "ist früher als oder gleich",
  "kendo.treelist.filterFilterButton": "Filtern",
  "kendo.treelist.filterClearButton": "Löschen",
  "kendo.treelist.filterAndLogic": "und",
  "kendo.treelist.filterOrLogic": "oder",
  "kendo.treelist.loading": "Laden",
  "kendo.treelist.sort": "Sortieren",
  "kendo.treelist.columnMenu": "Spaltenmenü",
  "kendo.treelist.columns": "Spalten",
  "kendo.treelist.lock": "Sperren",
  "kendo.treelist.unlock": "Entsperren",
  "kendo.treelist.sortAscending": "Aufsteigend sortieren",
  "kendo.treelist.sortDescending": "Absteigend sortieren",
  "kendo.treelist.columnsApply": "Übernehmen",
  "kendo.treelist.columnsReset": "Zurücksetzen",
  "kendo.treelist.sortable": "Sortierbar",
  "kendo.treelist.sortedAscending": "Aufsteigend sortiert",
  "kendo.treelist.sortedDescending": "Absteigend sortiert",
  "kendo.treelist.sortedDefault": "Nicht sortiert",
  "kendo.treelist.selectPage": "Seite auswählen",
  "kendo.treelist.dragRowHandleLabel": "Zeile ziehen",
  "kendo.treelist.pagerLabel": "Zeilennavigation, Seite {currentPage} von {totalPages}",
  "kendo.treelist.topToolbarLabel": "Obere Toolbar",
  "kendo.treelist.bottomToolbarLabel": "Untere Toolbar",
  "kendo.treelist.selectRowCheckboxLabel": "Zeile auswählen",
  "kendo.treelist.selectAllRowsCheckboxLabel": "Alle Zeilen auswählen",
  "kendo.treelist.filterCellOperatorLabel": "Filterzelle Operatoren für {columnName}",
  "kendo.treelist.booleanFilterCellLabel": "Boolesche Filterzelle für {columnName}",
  "kendo.treeview.loadMore": "Load more...",
  "kendo.upload.cancel": "Beenden",
  "kendo.upload.clearSelectedFiles": "Leeren",
  "kendo.upload.dropFilesHere": "Dateien zum Hochladen hierhin ziehen",
  "kendo.upload.headerStatusUploaded": "Hochgeladen",
  "kendo.upload.headerStatusUploading": "Hochladen...",
  "kendo.upload.invalidFileExtension": "Dateityp nicht erlaubt.",
  "kendo.upload.invalidMaxFileSize": "Datei ist zu groß.",
  "kendo.upload.invalidMinFileSize": "Datei ist zu klein.",
  "kendo.upload.remove": "Löschen",
  "kendo.upload.retry": "Wiederholen",
  "kendo.upload.select": "Datei(en) auswählen...",
  "kendo.upload.uploadSelectedFiles": "Datei(en) hochladen",
  "kendo.upload.externalDropFilesHere": "Dateien zum Hochladen hierhin ziehen",
  "kendo.upload.filesBatchStatus": "Dateien",
  "kendo.upload.filesBatchStatusFailed": "Hochladen der Dateien fehlgeschlagen.",
  "kendo.upload.filesBatchStatusUploaded": "Dateien erfolgreich hochgeladen.",
  "kendo.upload.fileStatusFailed": "Hochladen der Datei fehlgeschlagen.",
  "kendo.upload.fileStatusUploaded": "Datei erfolgreich hochgeladen.",
  "kendo.upload.headerStatusPaused": "Pausiert",
  "kendo.window.closeTitle": "Schließen",
  "kendo.window.restoreTitle": "Wiederherstellen",
  "kendo.window.maximizeTitle": "Maximieren",
  "kendo.window.minimizeTitle": "Minimieren",
} satisfies Record<string, string>;

type MessageKey = keyof typeof customMessages | keyof typeof defaultMessages;

/**
 * Überschreibt die Default Titel/Tooltips für Kendo Komponenten. Der Service wird
 * über die kendo.module.ts global für die Anwendung eingebunden. Die Variablen im messagesRecord wurden aus den
 * kendo-angular-messages https://github.com/telerik/kendo-angular-messages/tree/develop/messages übernommen.
 */
@Injectable({
  providedIn: "root",
})
export class MessageService extends KendoMessageService {
  public get(key: MessageKey): string {
    let message = (customMessages as Record<string, string>)[key];
    if (message === undefined) {
      message = defaultMessages[key];
    }
    if (message === undefined) {
      console.error(`MessageService: Message for key ${key} not found!`);
    }
    return message;
  }
}
