import { Dictionary } from "@ngrx/entity";
import { createSelector } from "@ngrx/store";
import { Planungskontext } from "src/app/models/openapi/model/planungskontext";
import { PlanungsobjektLinearDto } from "src/app/models/openapi/model/planungsobjekt-linear-dto";
import { SendeplatzDto } from "src/app/models/openapi/model/sendeplatz-dto";
import { SendeplatzKeyDto } from "src/app/models/openapi/model/sendeplatz-key-dto";
import { planungsobjektFarbgebungMap } from "src/app/models/viewmodels";
import {
  ColorMapToNgStylePipe,
  ColorMapToReturnValue,
} from "src/app/shared/pipes/color-map-to-ng-style/color-map-to-ng-style.pipe";
import { PlanungsobjektUtils } from "src/app/utils/planungsobjekt.utils";
import { SendeplatzUtils } from "src/app/utils/sendeplatz.utils";
import { sortPlanungsobjektOnMerkliste } from "src/app/utils/sort-utils";
import mehrfachauswahlSelectors from "../mehrfachauswahl/mehrfachauswahl.selectors";
import { OnDemandMapper } from "../on-demand/mapper/on-demand-mapper";
import routerSelectors from "../router/router.selectors";
import { PlanungsobjektLinearVm } from "./planungsobjekt-linear.model";
import { planungsobjektComparer, planungsobjektFeature } from "./planungsobjekt.reducer";

const selectPlanungsobjektById = (planungsobjektId: string) => {
  return createSelector(
    planungsobjektFeature.selectEntities,
    (planungsobjekte) => planungsobjekte[planungsobjektId],
  );
};

const selectPlanungsobjekteByMengengeruesteintragId = (mengengeruesteintragId: string) => {
  return createSelector(planungsobjektFeature.selectAll, (planungsobjekte) =>
    planungsobjekte.filter(
      (planungsobjektf) => planungsobjektf.mengengeruesteintragId === mengengeruesteintragId,
    ),
  );
};

const selectSelectedPlanungsobjekt = createSelector(
  planungsobjektFeature.selectEntities,
  planungsobjektFeature.selectSelectedPlanungsobjektId,
  (planungsobjekte, selectedPlanungsobjektId) =>
    selectedPlanungsobjektId ? planungsobjekte[selectedPlanungsobjektId] : selectedPlanungsobjektId,
);

const selectPlanungsobjekteLinear = createSelector(
  planungsobjektFeature.selectAll,
  (planungsobjekte) => {
    return planungsobjekte.filter(
      (planungsobjekt): planungsobjekt is PlanungsobjektLinearDto =>
        planungsobjekt.discriminator === "PlanungsobjektLinear",
    );
  },
);

const selectPlanungsobjekteOnBlockansicht = createSelector(
  selectPlanungsobjekteLinear,
  routerSelectors.selectKanalQueryParam,
  (planungsobjekte, kanal) => {
    return planungsobjekte
      .filter((linear) => PlanungsobjektUtils.isOnBlockansicht(linear))
      .filter((linear) => linear.kanal === kanal);
  },
);

const selectPlanungsobjekteForMerklisteId = (merklisteId: string) => {
  return createSelector(planungsobjektFeature.selectAll, (planungsobjekte) => {
    return planungsobjekte
      .filter((planungsobjekt) => planungsobjekt.merklisteId === merklisteId)
      .sort(sortPlanungsobjektOnMerkliste);
  });
};

const selectPlanungsobjekteVorgeplantForSendeplatz = (sendeplatz: SendeplatzKeyDto) => {
  return createSelector(planungsobjektFeature.selectAll, (planungsobjekte) =>
    planungsobjekte
      .filter(
        (planungsobjekt) =>
          planungsobjekt.planungskontext === Planungskontext.VORGEPLANT &&
          planungsobjekt.publikationsplanung?.kanal === sendeplatz.kanal &&
          planungsobjekt.publikationsplanung.kalendertag === sendeplatz.kalendertag &&
          planungsobjekt.publikationsplanung.sendeplatzBeginnzeit === sendeplatz.beginnzeit &&
          planungsobjekt.publikationsplanung.sendeplatzLaenge === sendeplatz.laenge,
      )
      .sort(planungsobjektComparer),
  );
};

const selectPlanungsobjekteForSendeplatzKeyPlanungskontext = (
  sendeplatzKey: SendeplatzKeyDto,
  planungskontext: Planungskontext,
) => {
  return createSelector(planungsobjektFeature.selectAll, (planungsobjekte) => {
    return planungsobjekte
      .filter(
        (planungsobjekt) =>
          planungsobjekt.planungskontext === planungskontext &&
          planungsobjekt.publikationsplanung?.kanal === sendeplatzKey.kanal &&
          planungsobjekt.publikationsplanung.kalendertag === sendeplatzKey.kalendertag &&
          planungsobjekt.publikationsplanung.sendeplatzBeginnzeit === sendeplatzKey.beginnzeit &&
          planungsobjekt.publikationsplanung.sendeplatzLaenge === sendeplatzKey.laenge,
      )
      .sort(planungsobjektComparer);
  });
};

const selectAnyPlanungsobjekteInVorschlagspalteForSendeplatz = (sendeplatz: SendeplatzDto) => {
  return createSelector(
    selectPlanungsobjekteForSendeplatzKeyPlanungskontext(
      SendeplatzUtils.getSendeplatzKey(sendeplatz),
      Planungskontext.VORGESCHLAGEN,
    ),
    (planungsobjekte) => {
      return planungsobjekte !== undefined && planungsobjekte.length > 0;
    },
  );
};

const selectPlanungsobjektStyles = (planungsobjektId: string) => {
  return createSelector(
    mehrfachauswahlSelectors.selectPlanungsobjekteIdsInMehrfachauswahl,
    planungsobjektFeature.selectSelectedPlanungsobjektId,
    planungsobjektFeature.selectEntities,
    (
      planungsobjekteInMehrfachauswahl,
      selectedPlanungsobjektId,
      planungsobjekteEntities,
    ): { [klass: string]: string | undefined } => {
      const planungsobjekt = planungsobjekteEntities[planungsobjektId];
      if (!planungsobjekt) return {};

      const { background, color, borderColor } = ColorMapToNgStylePipe.prototype.transform(
        planungsobjekt.farbgebung,
        planungsobjektFarbgebungMap,
        ColorMapToReturnValue.Style,
      );

      const defaultVpStyle = { background, color, "z-index": "1", position: "relative" };
      if (planungsobjekteInMehrfachauswahl.has(planungsobjektId)) {
        // Selektierte Chips in der Mehrfachauswahl
        return {
          ...defaultVpStyle,
          "box-shadow": "white 0px 0px 3px 3px",
        };
      }
      const selectedPlanungsobjekt = selectedPlanungsobjektId
        ? planungsobjekteEntities[selectedPlanungsobjektId]
        : undefined;

      if (selectedPlanungsobjekt && borderColor) {
        // Angeklickter/Fokussierter Chip
        if (selectedPlanungsobjekt.id === planungsobjektId) {
          return {
            ...defaultVpStyle,
            "box-shadow": `${borderColor} 0px 0px 3px 3px`,
            "border-color": "transparent",
          };
        }

        const hasBeziehung =
          selectedPlanungsobjekt.vorgaenger.some((v) => v === planungsobjektId) ||
          selectedPlanungsobjekt.nachfolger.some((v) => v === planungsobjektId) ||
          selectedPlanungsobjekt.abhaengigkeiten.some((v) => v === planungsobjektId);

        if (hasBeziehung) {
          // Verlinkter Chip
          return {
            ...defaultVpStyle,
            border: `2px solid ${borderColor}`,
          };
        }

        if (!hasBeziehung && PlanungsobjektUtils.hasBeziehungen(selectedPlanungsobjekt)) {
          // Wenn VPF mit Beziehung ausgewählt, dann alle anderen Chips transparent
          return { ...defaultVpStyle, opacity: "0.5" };
        }
      }
      return defaultVpStyle;
    },
  );
};

/**
 * Selector, der alle PlanungsobjektDto aus dem Core Store holt und in ein Dictionary mit PlanungsobjektLinearVMs umwandelt.
 */
const selectPlanungsobjektLinearVMEntities = createSelector(
  planungsobjektFeature.selectEntities,
  (planungsobjekte): Dictionary<PlanungsobjektLinearVm> => {
    if (!planungsobjekte) return {};
    const vmDictionary: Dictionary<PlanungsobjektLinearVm> = {};
    for (const [id, planungsobjekt] of Object.entries(planungsobjekte)) {
      if (planungsobjekt && !PlanungsobjektUtils.isOnMerkliste(planungsobjekt)) {
        vmDictionary[id] = OnDemandMapper.mapPlanungsobjektLinearToVm(planungsobjekt);
      }
    }
    return vmDictionary;
  },
);

export default {
  selectSelectedPlanungsobjekt,
  selectPlanungsobjekteByMengengeruesteintragId,
  selectPlanungsobjekteOnBlockansicht,
  selectPlanungsobjekteForMerklisteId,
  selectPlanungsobjekteVorgeplantForSendeplatz,
  selectAnyPlanungsobjekteInVorschlagspalteForSendeplatz,
  selectPlanungsobjektById,
  selectPlanungsobjektStyles,
  selectPlanungsobjekteForSendeplatzKeyPlanungskontext,
  selectPlanungsobjektLinearVMEntities,
  selectPlanungsobjekteLinear,
};
