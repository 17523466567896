<div
  class="k-d-flex k-align-items-start"
  [ngClass]="
    planungskontext === 'Vorgemerkt' && publikationsart === 'linear'
      ? 'k-justify-content-end'
      : 'k-justify-content-between'
  "
>
  <app-wann-bezug
    [planungskontext]="planungskontext"
    [publikationsart]="publikationsart"
    [usecase]="usecase"
  ></app-wann-bezug>
  <app-planungsbezug
    class="planungsbezug k-ml-2"
    [kanal]="kanal"
    [planungskontext]="planungskontext"
    [publikationsart]="publikationsart"
  ></app-planungsbezug>
</div>

<app-planungsinhalte></app-planungsinhalte>

<app-produktinformationen
  class="k-mt-4 k-p-0.5 k-border-2 k-border-dark k-border-solid"
  [planungsobjekt]="planungsobjekt"
  [usecase]="usecase"
></app-produktinformationen>
